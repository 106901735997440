import { put, takeEvery, call } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_USERS_INIT_ACTION,
  FETCH_USERS_SUCCESS_ACTION,
  FETCH_USERS_FAIL_ACTION,
  ADD_USERS_INIT_ACTION,
  ADD_USERS_SUCCESS_ACTION,
  ADD_USERS_FAIL_ACTION,
  EDIT_USERS_INIT_ACTION,
  EDIT_USERS_SUCCESS_ACTION,
  EDIT_USERS_FAIL_ACTION,
  FETCH_COUNTRY_INIT_ACTION,
  FETCH_COUNTRY_SUCCESS_ACTION,
  FETCH_COUNTRY_FAIL_ACTION,
  FETCH_USERS_REGION_INIT_ACTION,
  FETCH_USERS_REGION_SUCCESS_ACTION,
  FETCH_USERS_REGION_FAIL_ACTION,
  FETCH_USERS_ZONE_INIT_ACTION,
  FETCH_USERS_ZONE_SUCCESS_ACTION,
  FETCH_USERS_ZONE_FAIL_ACTION,
  FETCH_USERS_AREA_INIT_ACTION,
  FETCH_USERS_AREA_SUCCESS_ACTION,
  FETCH_USERS_AREA_FAIL_ACTION,
  CHANGE_USER_STATUS_INIT_ACTION,
  CHANGE_USER_STATUS_SUCCESS_ACTION,
  CHANGE_USER_STATUS_FAIL_ACTION,
  DELETE_USER_INIT_ACTION,
  DELETE_USER_SUCCESS_ACTION,
  DELETE_USER_FAIL_ACTION,
  EXPORT_USER_INIT_ACTION,
  EXPORT_USER_SUCCESS_ACTION,
  EXPORT_USER_FAIL_ACTION,
  RESET_INIT_ACTION,
  RESET_SUCCESS_ACTION,
  RESET_FAIL_ACTION,
  // FILTER_SEARCH_USER_INIT_ACTION,
  // FILTER_SEARCH_USER_SUCCESS_ACTION,
  // FILTER_SEARCH_USER_FAIL_ACTION
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
var dateFormat = require("dateformat");

function* fetchCountry() {
  try {
    const token = localStorage.getItem("sStraitToken");

    const res = yield fetch(`${appConfig.ip}/country?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_COUNTRY_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_COUNTRY_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchUsers(value) {
  let pageno = value.value.page === undefined ? 1 : value.value.page;
  const token = localStorage.getItem("sStraitToken");

  const role = localStorage.getItem("sStraitUserRole");
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? ""
      : value.value.searchVal;
  let filterVal =
    !value || !value.value || !value.value.filterVal
      ? ""
      : value.value.filterVal;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;

  let getDataUrl = [
    {
      role: "licensee",
      url:
        "&licenseeId=" +
        userDtls.licenseeId +
        "&role=regionalManager,zonalManager,areaManager,salesExcecutive,licenseeAccountant",
      url_search: "&licenseeId=" + userDtls.licenseeId,
    },
    {
      role: "licenseeAccountant",
      url:
        "&licenseeId=" +
        userDtls.licenseeId +
        "&role=regionalManager,zonalManager,areaManager,salesExcecutive,licenseeAccountant",
      url_search: "&licenseeId=" + userDtls.licenseeId,
    },
    {
      role: "regionalManager",
      url:
        "&licenseeId=" +
        userDtls.licenseeId +
        "&region=" +
        userDtls.region +
        "&role=zonalManager,areaManager,salesExcecutive",
      url_search:
        "&licenseeId=" + userDtls.licenseeId + "&region=" + userDtls.region,
    },
    {
      role: "zonalManager",
      url:
        "&licenseeId=" +
        userDtls.licenseeId +
        "&zone=" +
        userDtls.zone +
        "&role=areaManager,salesExcecutive",
      url_search:
        "&licenseeId=" + userDtls.licenseeId + "&zone=" + userDtls.zone,
    },
    {
      role: "areaManager",
      url:
        "&licenseeId=" +
        userDtls.licenseeId +
        "&area=" +
        userDtls.area +
        "&role=salesExcecutive",
      url_search:
        "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
    {
      role: "salesExcecutive",
      url: "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
      url_search:
        "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
    {
      role: "distributor",
      url:
        "&licenseeId=" +
        userDtls.licenseeId +
        "&role=regionalManager,zonalManager,areaManager,salesExcecutive,licenseeAccountant",
      url_search: "&licenseeId=" + userDtls.licenseeId,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  let _url =
    `${appConfig.ip}/users?limit=10&page=` +
    pageno +
    apiUrl.url +
    `&status=created,active,suspended&dummy=false`;

  if (filterVal) {
    // _url = `${_url}&role=${filterVal}`;
    _url =
      `${appConfig.ip}/users?limit=10&page=` +
      pageno +
      apiUrl.url_search +
      `&status=created,active,suspended&dummy=false&role=${filterVal}`;
  }
  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }
  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  }
  if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  }
  if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_USERS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_USERS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* AddUser(value) {
  const token = localStorage.getItem("sStraitToken");

  let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

  let region, zone, area;
  let role = value.value.role[0].value;

  if (value && value.value && value.value.Region) {
    region =
      !value || !value.value || !value.value.Region
        ? ""
        : value.value.Region[0].id;
    zone = "";
    area = "";
  } else if (value && value.value && value.value.Zone) {
    zone =
      !value || !value.value || !value.value.Zone ? "" : value.value.Zone[0].id;
    region = value.value.Zone[0].region.id;
    area = "";
  } else if (value && value.value && value.value.Area) {
    area =
      !value || !value.value || !value.value.Area ? "" : value.value.Area[0].id;
    region = value.value.Area[0].region.id;
    zone = value.value.Area[0].zone.id;
  }

  const data = {
    name: value.value.name,
    lName: value.value.lName,
    mobileNo: value.value.mobileNo,
    email: value.value.email,
    role: role,
    region: region,
    zone: zone,
    area: area,
    password: value.value.password,
    country: userDetails.country,
    fullName: value.value.name + " " + value.value.lName,
    licenseeId: userDetails.licenseeId,
    orgId: userDetails.orgId,
    author: userDetails.id,
    partner: licData.channelPartner ? licData.channelPartner.id : null,
  };

  let userCount = 0;

  if (role !== "salesExcecutive" && role !== "licenseeAccountant") {
    let userDtls = yield call(checkAttachment, {
      role: role,
      network:
        (role === "regionalManager" && region && region) ||
        (role === "zonalManager" && zone && zone) ||
        (role === "areaManager" && area && area),
    });
    userCount = userDtls.count;
  }
  if (role === "licenseeAccountant") {
    let userDtls = yield call(licenseeAcntVald);
    userCount = userDtls.count;
  }
  if (userCount > 0) {
    yield toast.warning(
      (role === "regionalManager" && "Region already attached") ||
        (role === "zonalManager" && "Zone already attached") ||
        (role === "areaManager" && "Area already attached") ||
        (role === "licenseeAccountant" && "Licensee Accountant already exist"),
      {
        autoClose: 3000,
      }
    );
  } else {
    try {
      const res = yield fetch(`${appConfig.ip}/users`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) {
        let errJSON = {};
        try {
          errJSON = yield res.json();
          if (errJSON.message === "User already registered") {
            yield toast.error("User already exist", {
              autoClose: 3000,
            });
          }
        } catch {
          throw Object.assign(res, errJSON);
        }
      } else {
        const resJSON = yield res.json();
        yield call(networkUpdate, {
          role: role,
          id:
            (role === "regionalManager" && region && region) ||
            (role === "zonalManager" && zone && zone) ||
            (role === "areaManager" && area && area),
          userId: resJSON.user.id,
        });

        const userData = {
          userId: resJSON.user.id,
          name: value.value.name,
          lName: value.value.lName,
          mobileNo: value.value.mobileNo,
          email: value.value.email,
          fullName: value.value.name + " " + value.value.lName,
          country: userDetails.country,
          licenseeId: userDetails.licenseeId,
        };

        const userRes = yield fetch(`${appConfig.ip}/userProf`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(userData),
        });

        if (!userRes) {
        } else {
          const useresJSON = yield userRes.json();

          yield put({
            type: ADD_USERS_SUCCESS_ACTION,
            payload: resJSON,
          });
          yield toast.success("User added successfully", {
            autoClose: 3000,
          });
          yield put({
            type: FETCH_USERS_INIT_ACTION,
            value: { page: 1 },
          });
        }
      }
    } catch (err) {
      if (err.status === 409) {
        yield toast.error("User already exist", {
          autoClose: 3000,
        });
      }

      yield put({
        type: ADD_USERS_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* EditUser(value) {
  const token = localStorage.getItem("sStraitToken");
  let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let role = value.value.role[0].value;
  let id = value.value.id;
  let region, zone, area;

  if (value && value.value && value.value.Region) {
    region =
      !value || !value.value || !value.value.Region
        ? ""
        : value.value.Region[0].id;
    zone = "";
    area = "";
  } else if (value && value.value && value.value.Zone) {
    zone =
      !value || !value.value || !value.value.Zone ? "" : value.value.Zone[0].id;
    region =
      value.value.Zone[0] &&
      value.value.Zone[0].region &&
      value.value.Zone[0].region.id;
    area = "";
  } else if (value && value.value && value.value.Area) {
    area =
      !value || !value.value || !value.value.Area ? "" : value.value.Area[0].id;
    region =
      value.value.Area[0] &&
      value.value.Area[0].region &&
      value.value.Area[0].region.id;
    zone =
      value.value.Area[0] &&
      value.value.Area[0].zone &&
      value.value.Area[0].zone.id;
  }

  const data = {
    name: value.value.name,
    lName: value.value.lName,
    // mobileNo: value.value.mobileNo,
    email: value.value.email,
    role: role,
    region: region,
    zone: zone,
    area: area,
    // password: value.value.password,
    country: userDetails.country,
    fullName: value.value.name + " " + value.value.lName,
    licenseeId: userDetails.licenseeId,
    orgId: userDetails.orgId,
    author: userDetails.id,
  };

  let userCount = 0;
  var user;

  if (role !== "salesExcecutive" && role !== "licenseeAccountant") {
    // console.log('--=-=1')
    let userDtls = yield call(checkAttachment, {
      role: role,
      network:
        (role === "regionalManager" && region && region) ||
        (role === "zonalManager" && zone && zone) ||
        (role === "areaManager" && area && area),
    });
    userCount = userDtls.count;
    user = userDtls.rows && userDtls.rows.length > 0 && userDtls.rows[0];
  }
  if (role === "licenseeAccountant") {
    // console.log('--=-=2')
    let userDtls = yield call(licenseeAcntVald);
    userCount = userDtls.count;
    user = userDtls.rows && userDtls.rows.length > 0 && userDtls.rows[0];
  }
  if (userCount > 0 && user && user.id !== id) {
    // console.log('--=-=3')
    yield toast.warning(
      (role === "regionalManager" && "Region already attached") ||
        (role === "zonalManager" && "Zone already attached") ||
        (role === "areaManager" && "Area already attached") ||
        (role === "licenseeAccountant" && "Licensee Accountant already exist"),
      {
        autoClose: 3000,
      }
    );
  } else if (
    role === "salesExcecutive" ||
    (user && user.id === id) ||
    userCount === 0
  ) {
    // console.log('--=-=4')
    try {
      const userByIdDtls = yield call(userById, {
        id: id,
      });

      let getuserDtlsUrl = [
        { role: "regionalManager", url: "userByIdDtls.region.id" },
        { role: "zonalManager", url: "userByIdDtls.zone.id" },
        { role: "areaManager", url: "userByIdDtls.area.id" },
      ];

      let apiuserDtlUrl = getuserDtlsUrl.find(
        (e) => e.role === userByIdDtls.role
      );

      const res = yield fetch(`${appConfig.ip}/users/` + id, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      if (!res.ok) {
        let errJSON = {};
        try {
          errJSON = yield res.json();
        } catch {}
        throw Object.assign(res, errJSON);
      } else {
        const resJSON = yield res.json();

        if (role !== "salesExcecutive" && role !== "licenseeAccountant") {
          yield call(networkUpdate, {
            role: userByIdDtls.role,
            id: eval(apiuserDtlUrl.url),
            userId: "",
          });

          yield call(networkUpdate, {
            role: role,
            id:
              (role === "regionalManager" && region && region) ||
              (role === "zonalManager" && zone && zone) ||
              (role === "areaManager" && area && area),
            userId: resJSON.id,
          });
        }

        //for reducer update
        const userByIdData = yield call(userById, { id: resJSON.id });

        yield toast.success("User updated successfully", {
          autoClose: 3000,
        });

        yield put({
          type: EDIT_USERS_SUCCESS_ACTION,
          payload: userByIdData,
        });
        // yield put({
        //   type: FETCH_USERS_INIT_ACTION,
        // });
      }
    } catch (err) {
      if (err.ok === false) {
        yield put({ type: EDIT_USERS_FAIL_ACTION, error: err });
      } else {
      }
    }
  }
}

function* fetchUsersRegion(value) {
  const token = localStorage.getItem("sStraitToken");
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licenseeId = userDetails.licenseeId;

  let searchval =
    !value || !value.value || !value.value.searchval
      ? ""
      : value.value.searchval;

  let _url = `${appConfig.ip}/region?licenseeId=${licenseeId}&with_manager=false&isActive=true&dummy=false`;

  if (searchval) {
    _url = `${_url}&search=${searchval}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_USERS_REGION_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_USERS_REGION_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchUsersZone(value) {
  let region = !value || !value.value || !value.value.id ? "" : value.value.id;
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licenseeId = userDetails.licenseeId;
  const token = localStorage.getItem("sStraitToken");

  let searchval =
    !value || !value.value || !value.value.searchval
      ? ""
      : value.value.searchval;

  let _url = `${appConfig.ip}/zone?region=${region}&licenseeId=${licenseeId}&with_manager=false&isActive=true&dummy=false`;

  if (searchval) {
    _url = `${_url}&search=${searchval}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_USERS_ZONE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_USERS_ZONE_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchUsersArea(value) {
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licenseeId = userDetails.licenseeId;
  const token = localStorage.getItem("sStraitToken");

  let zone = !value || !value.value || !value.value.id ? "" : value.value.id;

  let manager =
    !value || !value.value || !value.value.manager ? "" : value.value.manager;

  let withManager = "";
  if (manager) {
    withManager = "&with_manager=false";
  }

  let searchval =
    !value || !value.value || !value.value.searchval
      ? ""
      : value.value.searchval;

  let _url = `${appConfig.ip}/area?zone=${zone}&licenseeId=${licenseeId}${withManager}&isActive=true&dummy=false`;

  if (searchval) {
    _url = `${_url}&search=${searchval}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_USERS_AREA_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_USERS_AREA_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* checkAttachment(value) {
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let role = value.role;

  let getDataUrl = [
    {
      role: "regionalManager",
      url: "&licenseeId=" + userDetails.licenseeId + "&region=" + value.network,
    },
    {
      role: "zonalManager",
      url: "&licenseeId=" + userDetails.licenseeId + "&zone=" + value.network,
    },
    {
      role: "areaManager",
      url: "&licenseeId=" + userDetails.licenseeId + "&area=" + value.network,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/users?role=${role}${apiUrl.url}&isActive=true&dummy=false`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_USERS_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* networkUpdate(value) {
  let role = value.role;
  let id = value.id;
  let userId = value.userId ? value.userId : "";

  let getDataUrl = [
    { role: "regionalManager", url: "region" },
    { role: "zonalManager", url: "zone" },
    { role: "areaManager", url: "area" },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  const token = localStorage.getItem("sStraitToken");
  var data = {};
  if (userId !== "") {
    data = {
      manager: userId,
    };
  } else {
    data = {
      cleanManager: true,
    };
  }

  try {
    const res = yield fetch(`${appConfig.ip}/${apiUrl.url}/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_USERS_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* userById(value) {
  let id = value.id;
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/users/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: EDIT_USERS_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* licenseeAcntVald(value) {
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licenseeId = userDetails.licenseeId;
  let role = "licenseeAccountant";
  let status = "created,active";
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/users?role=${role}&licenseeId=${licenseeId}&status=${status}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: EDIT_USERS_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

// Change user status

function* changeUserStatus(value) {
  let datas;

  value.value.status === "created" || value.value.status === "suspended"
    ? (datas = { status: "active" })
    : (datas = { status: "suspended" });

  const id = value.value.id;

  const token = localStorage.getItem("sStraitToken");

  try {
    if (datas.status === "suspended") {
      const userByIdDtls = yield call(userById, {
        id: id,
      });
      if (
        userByIdDtls.role !== "licenseeAccountant" &&
        userByIdDtls.role !== "salesExcecutive"
      ) {
        let getuserDtlsUrl = [
          { role: "regionalManager", url: "userByIdDtls.region.id" },
          { role: "zonalManager", url: "userByIdDtls.zone.id" },
          { role: "areaManager", url: "userByIdDtls.area.id" },
        ];

        let apiuserDtlUrl = getuserDtlsUrl.find(
          (e) => e.role === userByIdDtls.role
        );

        yield call(networkUpdate, {
          role: userByIdDtls.role,
          id: eval(apiuserDtlUrl.url),
          userId: "",
        });
      }
    }

    const res = yield fetch(`${appConfig.ip}/users/changeStatus/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(datas),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield toast.success("Status changed successfully", {
        autoClose: 3000,
      });
      yield put({
        type: CHANGE_USER_STATUS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: CHANGE_USER_STATUS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* DeleteValue(value) {
  const id = value.value.id;
  const token = localStorage.getItem("sStraitToken");

  try {
    const userByIdDtls = yield call(userById, {
      id: id,
    });
    if (
      userByIdDtls.role !== "licenseeAccountant" &&
      userByIdDtls.role !== "salesExcecutive"
    ) {
      let getuserDtlsUrl = [
        { role: "regionalManager", url: "userByIdDtls.region.id" },
        { role: "zonalManager", url: "userByIdDtls.zone.id" },
        { role: "areaManager", url: "userByIdDtls.area.id" },
      ];

      let apiuserDtlUrl = getuserDtlsUrl.find(
        (e) => e.role === userByIdDtls.role
      );

      yield call(networkUpdate, {
        role: userByIdDtls.role,
        id: eval(apiuserDtlUrl.url),
        userId: "",
      });
    }

    const res = yield fetch(`${appConfig.ip}/users/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      // const resJSON = yield res.json();
      yield toast.success("Successfully deleted", {
        autoClose: 3000,
      });
      yield put({
        type: DELETE_USER_SUCCESS_ACTION,
        payload: id,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_USER_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* exprtFullData(value) {
  let fileName = "User Report";
  const token = localStorage.getItem("sStraitToken");

  const role = localStorage.getItem("sStraitUserRole");
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let pageno = value.value.page === undefined ? 1 : value.value.page;

  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? ""
      : value.value.searchVal;
  let filterVal =
    !value || !value.value || !value.value.filterVal
      ? ""
      : value.value.filterVal;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;

  let getDataUrl = [
    {
      role: "licensee",
      url:
        "&licenseeId=" +
        userDtls.licenseeId +
        "&role=regionalManager,zonalManager,areaManager,salesExcecutive,licenseeAccountant",
      url_search: "&licenseeId=" + userDtls.licenseeId,
    },
    {
      role: "distributor",
      url:
        "&licenseeId=" +
        userDtls.licenseeId +
        "&role=regionalManager,zonalManager,areaManager,salesExcecutive,licenseeAccountant",
      url_search: "&licenseeId=" + userDtls.licenseeId,
    },
    {
      role: "licenseeAccountant",
      url:
        "&licenseeId=" +
        userDtls.licenseeId +
        "&role=regionalManager,zonalManager,areaManager,salesExcecutive,licenseeAccountant",
      url_search: "&licenseeId=" + userDtls.licenseeId,
    },
    {
      role: "regionalManager",
      url:
        "&licenseeId=" +
        userDtls.licenseeId +
        "&region=" +
        userDtls.region +
        "&role=zonalManager,areaManager,salesExcecutive",
      url_search:
        "&licenseeId=" + userDtls.licenseeId + "&region=" + userDtls.region,
    },
    {
      role: "zonalManager",
      url:
        "&licenseeId=" +
        userDtls.licenseeId +
        "&zone=" +
        userDtls.zone +
        "&role=areaManager,salesExcecutive",
      url_search:
        "&licenseeId=" + userDtls.licenseeId + "&zone=" + userDtls.zone,
    },
    {
      role: "areaManager",
      url:
        "&licenseeId=" +
        userDtls.licenseeId +
        "&area=" +
        userDtls.area +
        "&role=salesExcecutive",
      url_search:
        "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
    {
      role: "salesExcecutive",
      url: "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
      url_search:
        "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  let _url = `${appConfig.ip}/users?limit=100&status=created,active,suspended&dummy=false${apiUrl.url}`;

  if (filterVal) {
    // _url = `${_url}&role=${filterVal}`;
    _url =
      `${appConfig.ip}/users?limit=10&page=` +
      pageno +
      apiUrl.url_search +
      `&status=created,active,suspended&dummy=false&role=${filterVal}`;
  }

  if (filterVal) {
    _url = `${_url}&role=${filterVal}`;
  }
  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }
  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  }
  if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  }
  if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let csvData = [];

      resJSON.rows.map((item) => {
        csvData.push({
          NAME: item.fullName ? item.fullName : "NIL",
          MOBILE: item.mobileNo ? item.mobileNo : "NIL",
          EMAIL: item.email ? item.email : "NIL",
          ROLE: item.role ? item.role : "NIL",
          REGION: item.region != null ? item.region.name : "NIL",
          ZONE: item.zone != null ? item.zone.name : "NIL",
          AREA: item.area != null ? item.area.name : "NIL",
          LICENSEE_NAME:
            item.licenseeId != null ? item.licenseeId.orgName : "NIL",
          STATUS: item.status,
          CREATED_DATE: dateFormat(item.createdAt, "mediumDate"),
        });
      });

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      yield put({
        type: EXPORT_USER_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EXPORT_USER_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* reset(value) {
  const mobno = JSON.parse(localStorage.getItem("sStraitUserDtls")).mobileNo;

  const id = value.value.id;
  const token = localStorage.getItem("sStraitToken");
  const data = { password: value.value.newpwd };

  try {
    const res = yield fetch(`${appConfig.ip}/users/${id}/password`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa(mobno + ":" + value.value.oldpwd),
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield toast.success("User Password Reset Successfully", {
        autoClose: 3000,
      });
      yield put({
        type: RESET_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.status === 401) {
      yield toast.error("Invalid Licensee Password", {
        autoClose: 3000,
      });
    }
    if (err.ok === false) {
      yield put({ type: RESET_FAIL_ACTION, error: err });
    } else {
    }
  }
}

// function* filterSearchUser(value) {

//   let pageno = !value || !value.value || !value.value.pageno ? 1 : value.value.pageno
//   let searchVal = !value || !value.value || !value.value.searchVal ? '' : value.value.searchVal
//   let filterVal = !value || !value.value || !value.value.filterVal ? '' : value.value.filterVal
//   const token = localStorage.getItem("sStraitToken");

//   const role = localStorage.getItem("sStraitUserRole");
//   let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));

//   let getDataUrl = [
//     { role: "licensee", url: '&licenseeId=' + userDtls.licenseeId + '&role=regionalManager,zonalManager,areaManager,salesExcecutive,licenseeAccountant' },
//     { role: "licenseeAccountant", url: '&licenseeId=' + userDtls.licenseeId + '&role=regionalManager,zonalManager,areaManager,salesExcecutive,licenseeAccountant' },
//     { role: "regionalManager", url: '&licenseeId=' + userDtls.licenseeId + '&region=' + userDtls.region + '&role=zonalManager,areaManager,salesExcecutive' },
//     { role: "zonalManager", url: '&licenseeId=' + userDtls.licenseeId + '&zone=' + userDtls.zone + '&role=areaManager,salesExcecutive' },
//     { role: "areaManager", url: '&licenseeId=' + userDtls.licenseeId + '&area=' + userDtls.area + '&role=salesExcecutive' },
//     { role: "salesExcecutive", url: '&licenseeId=' + userDtls.licenseeId + '&area=' + userDtls.area },
//   ];

//   let apiUrl = getDataUrl.find(
//     (e) => e.role === role
//   );

//   try {
//     const res = yield fetch(`${appConfig.ip}/users?limit=10&page=${pageno}&search=${searchVal}` + apiUrl.url + `&status=created,active,suspended&role=${filterVal}`, {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     if (!res.ok) {
//       let errJSON = {};
//       try {
//         errJSON = yield res.json();
//       } catch { }
//       throw Object.assign(res, errJSON);
//     } else {
//       const resJSON = yield res.json();

//       yield put({
//         type: FILTER_SEARCH_USER_SUCCESS_ACTION,
//         payload: resJSON,
//       });
//     }
//   } catch (err) {
//     if (err.ok === false) {
//       yield put({ type: FILTER_SEARCH_USER_FAIL_ACTION, error: err });
//     } else {
//     }
//   }
// }

export function* UsersActionWatcher() {
  yield takeEvery(FETCH_USERS_INIT_ACTION, fetchUsers);
  yield takeEvery(FETCH_COUNTRY_INIT_ACTION, fetchCountry);
  yield takeEvery(ADD_USERS_INIT_ACTION, AddUser);
  yield takeEvery(EDIT_USERS_INIT_ACTION, EditUser);
  yield takeEvery(FETCH_USERS_REGION_INIT_ACTION, fetchUsersRegion);
  yield takeEvery(FETCH_USERS_ZONE_INIT_ACTION, fetchUsersZone);
  yield takeEvery(FETCH_USERS_AREA_INIT_ACTION, fetchUsersArea);
  yield takeEvery(CHANGE_USER_STATUS_INIT_ACTION, changeUserStatus);
  yield takeEvery(DELETE_USER_INIT_ACTION, DeleteValue);
  yield takeEvery(EXPORT_USER_INIT_ACTION, exprtFullData);
  yield takeEvery(RESET_INIT_ACTION, reset);

  // yield takeEvery(FILTER_SEARCH_USER_INIT_ACTION, filterSearchUser);
}
