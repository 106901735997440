import { put, takeEvery, call } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  FETCH_LEADS_INIT_ACTION,
  FETCH_LEADS_SUCCESS_ACTION,
  FETCH_LEADS_FAIL_ACTION,
  FETCH_CUSTOMERS_BY_SALESEXECUTIVE_INIT_ACTION,
  FETCH_CUSTOMERS_BY_SALESEXECUTIVE_SUCCESS_ACTION,
  FETCH_CUSTOMERS_BY_SALESEXECUTIVE_FAIL_ACTION,
  FETCH_PRODUCTS_BY_SALESEXECUTIVE_INIT_ACTION,
  FETCH_PRODUCTS_BY_SALESEXECUTIVE_SUCCESS_ACTION,
  FETCH_PRODUCTS_BY_SALESEXECUTIVE_FAIL_ACTION,
  ADD_LEADS_INIT_ACTION,
  ADD_LEADS_SUCCESS_ACTION,
  ADD_LEADS_FAIL_ACTION,
  DELETE_LEADS_INIT_ACTION,
  DELETE_LEADS_SUCCESS_ACTION,
  DELETE_LEADS_FAIL_ACTION,
  EDIT_LEADS_INIT_ACTION,
  EDIT_LEADS_SUCCESS_ACTION,
  EDIT_LEADS_FAIL_ACTION,
  // FETCH_LEADS_CLEAR_ACTION,
  FETCH_LEADS_DETAIL_INIT_ACTION,
  FETCH_LEADS_DETAIL_SUCCESS_ACTION,
  FETCH_LEADS_DETAIL_FAIL_ACTION,
  FETCH_USER_DETAIL_INIT_ACTION,
  FETCH_USER_DETAIL_SUCCESS_ACTION,
  FETCH_USER_DETAIL_FAIL_ACTION,
  FILTER_SEARCH_LEADS_INIT_ACTION,
  FILTER_SEARCH_LEADS_SUCCESS_ACTION,
  FILTER_SEARCH_LEADS_FAIL_ACTION,
  EXPORT_LEADS_INIT_ACTION,
  EXPORT_LEADS_SUCCESS_ACTION,
  EXPORT_LEADS_FAIL_ACTION,
  ADD_FOLLOWUP_INIT_ACTION,
  ADD_FOLLOWUP_SUCCESS_ACTION,
  ADD_FOLLOWUP_FAIL_ACTION,
  FETCH_FOLLOWUP_INIT_ACTION,
  FETCH_SALESEXCTV_INIT_ACTION,
  FETCH_SALESEXCTV_SUCCESS_ACTION,
  FETCH_SALESEXCTV_FAIL_ACTION,
  ADD_DISLEADS_INIT_ACTION,
  ADD_DISLEADS_SUCCESS_ACTION,
  ADD_DISLEADS_FAIL_ACTION,
  EDIT_DISLEADS_INIT_ACTION,
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var dateFormat = require("dateformat");

function* getRoleApiUrl() {
  const role = localStorage.getItem("sStraitUserRole");
  const userId = localStorage.getItem("sStraitUserId");
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  let getDataUrl = [
    { role: "licensee", url: "&licenseeId=" + userDetails.licenseeId },
    { role: "distributor", url: "&licenseeId=" + userDetails.licenseeId },
    {
      role: "licenseeAccountant",
      url: "&licenseeId=" + userDetails.licenseeId,
    },
    {
      role: "regionalManager",
      url:
        "&licenseeId=" +
        userDetails.licenseeId +
        "&region=" +
        userDetails.region,
    },
    {
      role: "zonalManager",
      url:
        "&licenseeId=" + userDetails.licenseeId + "&zone=" + userDetails.zone,
    },
    {
      role: "areaManager",
      url:
        "&licenseeId=" + userDetails.licenseeId + "&area=" + userDetails.area,
    },
    {
      role: "salesExcecutive",
      url:
        "&licenseeId=" + userDetails.licenseeId + "&salesExecutive=" + userId,
    },
    {
      role: "agent",
      url:
        "&licenseeId=" + userDetails.licenseeId + "&salesExecutive=" + userId,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);
  return apiUrl;
}

function* fetchLeads(value) {
  let pageno =
    !value || !value.value || !value.value.page ? "" : value.value.page;
  const token = localStorage.getItem("sStraitToken");

  const apiUrl = yield call(getRoleApiUrl);
  let filterVal =
    !value || !value.value || !value.value.filterVal
      ? ""
      : value.value.filterVal;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? ""
      : value.value.searchVal;
  let customerId =
    !value || !value.value || !value.value.customerId
      ? ""
      : value.value.customerId;
  let filterValDate =
    !value || !value.value || !value.value.filterValDate
      ? null
      : value.value.filterValDate;

  let _url = `${appConfig.ip}/lead?limit=10&customer=${customerId}&isActive=true&search=${searchVal}${filterVal}&page=${pageno}${apiUrl.url}`;

  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  }
  if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  }
  if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }
  if (filterValDate) {
    _url = `${_url}${filterValDate}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_LEADS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_LEADS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchCusBySExec(value) {
  const token = localStorage.getItem("sStraitToken");
  const userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const role = localStorage.getItem("sStraitUserRole");
  let searchval =
    !value || !value.value || !value.value.searchval
      ? ""
      : value.value.searchval;

  let routeVal = (value.value && value.value.route) || "";

  const userRoutes = yield call(fetchUserDetails);

  let getDataUrl = [
    { role: "licensee", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "distributor", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "licenseeAccountant", url: "&licenseeId=" + userDtls.licenseeId },
    {
      role: "regionalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&region=" + userDtls.region,
    },
    {
      role: "zonalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&zone=" + userDtls.zone,
    },
    {
      role: "areaManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
    {
      role: "salesExcecutive",
      url: "&licenseeId=" + userDtls.licenseeId + "&author=" + userDtls.id,
    },
    {
      role: "agent",
      url: "&licenseeId=" + userDtls.licenseeId + "&author=" + userDtls.id,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  let url = apiUrl.url;
  let userDtlsroute = userRoutes && userRoutes.toString();

  if (role === "agent" && userRoutes.length) {
    url = "&licenseeId=" + userDtls.licenseeId + "&route=" + userDtlsroute;
  }

  let _url = `${appConfig.ip}/customer?isActive=true&${url}`;

  if (searchval) {
    _url = `${_url}&search=${searchval}`;
  }
  if (routeVal) {
    _url = `${_url}&route=${routeVal}`;
  }
  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_CUSTOMERS_BY_SALESEXECUTIVE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_CUSTOMERS_BY_SALESEXECUTIVE_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* fetchUserDetails() {
  const id = localStorage.getItem("sStraitUserId");
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/users/` + id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      let routeArr =
        resJSON.routes && resJSON.routes ? resJSON.routes.map((e) => e.id) : [];
      return routeArr;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* fetchPrdtBySExec(value) {
  const token = localStorage.getItem("sStraitToken");
  const userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const role = localStorage.getItem("sStraitUserRole");
  // let licenseeId = userDetails.licenseeId;
  // let area = !userDetails || !userDetails.area ? "" : userDetails.area;

  let searchval =
    !value || !value.value || !value.value.searchval
      ? ""
      : value.value.searchval;
  let agencyVal =
    !value || !value.value || !value.value.agency ? "" : value.value.agency;

  let getDataUrl = [
    { role: "licensee", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "distributor", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "agent", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "licenseeAccountant", url: "&licenseeId=" + userDtls.licenseeId },
    {
      role: "regionalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&region=" + userDtls.region,
    },
    {
      role: "zonalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&zone=" + userDtls.zone,
    },
    {
      role: "areaManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
    {
      role: "salesExcecutive",
      url: "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  let _url = `${appConfig.ip}/product?status=active&${apiUrl.url}`;

  if (searchval) {
    _url = `${_url}&search=${searchval}`;
  }
  if (agencyVal) {
    _url = `${_url}&agency=${agencyVal}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_PRODUCTS_BY_SALESEXECUTIVE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_PRODUCTS_BY_SALESEXECUTIVE_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

// function* addLeads(value) {
//   try {
//     const token = localStorage.getItem("sStraitToken");

//     const product = value.value.productId;
//     const description = value.value.description;
//     const customer = value.value.customer[0].id;
//     const intrestedIn = value.value.intrestedIn;
//     const conversionPotential = value.value.conversionPotential;
//     const userId = localStorage.getItem("sStraitUserId");
//     const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
//     let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

//     const data = {
//       customer: customer,
//       product: product,
//       potential: {
//         intrestedIn: intrestedIn,
//         conversionPotential: conversionPotential,
//       },
//       salesExecutive: userId,
//       licenseeId: userDetails.licenseeId,
//       region: userDetails.region,
//       zone: userDetails.zone,
//       area: userDetails.area,
//       description: description,
//       channelPartner: licData.channelPartner ? licData.channelPartner.id : null,
//     };

//     const res = yield fetch(`${appConfig.ip}/lead`, {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(data),
//     });

//     if (!res.ok) {
//       let errJSON = {};
//       try {
//         errJSON = yield res.json();
//       } catch {
//         throw Object.assign(res, errJSON);
//       }
//     } else {
//       const resJSON = yield res.json();

//       yield put({
//         type: ADD_LEADS_SUCCESS_ACTION,
//         payload: resJSON,
//       });
//       yield toast.success("Leads added successfully", {
//         autoClose: 3000,
//       });

//       yield put({
//         type: FETCH_LEADS_INIT_ACTION,
//         value: { page: 1 },
//       });
//     }
//   } catch (err) {
//     if (err.ok === false) {
//       yield put({
//         type: ADD_LEADS_FAIL_ACTION,
//         error: err,
//       });
//     }
//   }
// }

function* deleteLeads(value) {
  let id = value.value.id;

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/lead/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield put({
        type: FETCH_LEADS_INIT_ACTION,
      });

      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_LEADS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* editLeads(value) {
  let id = value.value.id;
  const token = localStorage.getItem("sStraitToken");
  // const product = value.value.product[0].id
  // const customer = value.value.customer[0].id
  // const description = value.value.description;

  let data;
  if (value.value.assign) {
    data = {
      region: value.value.region[0].id,
      zone: value.value.zone != "" ? value.value.zone[0].id : null,
      area: value.value.area != "" ? value.value.area[0].id : null,
      salesExecutive:
        value.value.salesExecutive != ""
          ? value.value.salesExecutive[0].id
          : null,
    };
  } else {
    const description = value.value.description;
    const conversionPotential = value.value.conversionPotential;
    const status = value.value.status[0].value;
    data = {
      // customer: customer,
      // products: product,
      potential: { intrestedIn: "", conversionPotential: conversionPotential },
      status: status,
      description: description,
    };
  }

  try {
    const res = yield fetch(`${appConfig.ip}/lead/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      // yield put({
      //   type: FETCH_LEADS_INIT_ACTION,
      // });
      const leadIdData = yield call(fetchLeadsDetail, {
        value: { id: resJSON.id },
      });

      yield put({
        type: EDIT_LEADS_SUCCESS_ACTION,
        payload: leadIdData,
      });
      if (value.value.assign) {
        yield call(updateCustomer, { id: value.value.customerId, data: data });
        yield toast.success("Leads assigned successfully", {
          autoClose: 3000,
        });
      } else {
        yield toast.success("Leads updated successfully", {
          autoClose: 3000,
        });
      }
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EDIT_LEADS_FAIL_ACTION, error: err });
    } else {
    }
  }
}
//Customer update while updating lead
function* updateCustomer(value) {
  const token = localStorage.getItem("sStraitToken");
  const role = localStorage.getItem("sStraitUserRole");

  if (role === "areaManager") {
    value.data = {
      ...value.data,
      author: value.data.salesExecutive,
    };
  }

  try {
    const res = yield fetch(`${appConfig.ip}/customer/${value.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(value.data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* fetchLeadsDetail(value) {
  const token = localStorage.getItem("sStraitToken");
  let id = !value || !value.value || !value.value.id ? "" : value.value.id;

  try {
    const res = yield fetch(`${appConfig.ip}/lead/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_LEADS_DETAIL_SUCCESS_ACTION,
        payload: resJSON,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_LEADS_DETAIL_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchUserDetail(value) {
  const token = localStorage.getItem("sStraitToken");
  let id = !value || !value.value || !value.value.id ? "" : value.value.id;
  try {
    const res = yield fetch(`${appConfig.ip}/users/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_USER_DETAIL_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_USER_DETAIL_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* filterSearchLeads(value) {
  let pageno =
    !value || !value.value || !value.value.pageno ? "" : value.value.pageno;
  let limit =
    !value || !value.value || !value.value.limit ? 10 : value.value.limit;
  let customerId =
    !value || !value.value || !value.value.customerId
      ? ""
      : value.value.customerId;
  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? ""
      : value.value.searchVal;

  const role = localStorage.getItem("sStraitUserRole");
  const userId = localStorage.getItem("sStraitUserId");
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const token = localStorage.getItem("sStraitToken");

  let getDataUrl = [
    { role: "licensee", url: "&licenseeId=" + userDetails.licenseeId },
    { role: "agent", url: "&licenseeId=" + userDetails.licenseeId },
    {
      role: "licenseeAccountant",
      url: "&licenseeId=" + userDetails.licenseeId,
    },
    {
      role: "regionalManager",
      url:
        "&licenseeId=" +
        userDetails.licenseeId +
        "&region=" +
        userDetails.region,
    },
    {
      role: "zonalManager",
      url:
        "&licenseeId=" + userDetails.licenseeId + "&zone=" + userDetails.zone,
    },
    {
      role: "areaManager",
      url:
        "&licenseeId=" + userDetails.licenseeId + "&area=" + userDetails.area,
    },
    {
      role: "salesExcecutive",
      url:
        "&licenseeId=" + userDetails.licenseeId + "&salesExecutive=" + userId,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  try {
    const res = yield fetch(
      `${appConfig.ip}/lead?limit=${limit}&page=${pageno}&search=${searchVal}&customer=${customerId}&isActive=true${apiUrl.url}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FILTER_SEARCH_LEADS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FILTER_SEARCH_LEADS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* exprtFullData(value) {
  let fileName = "Leads Report";
  const token = localStorage.getItem("sStraitToken");
  const apiUrl = yield call(getRoleApiUrl);

  let filter =
    !value || !value.value || !value.value.filter ? null : value.value.filter;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let filterValDate =
    !value || !value.value || !value.value.filterValDate
      ? null
      : value.value.filterValDate;

  let _url = `${appConfig.ip}/lead?limit=100${apiUrl.url}`;

  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  } else if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  } else if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let csvData = [];

      resJSON.rows.map((item) => {
        csvData.push({
          REF_ID: item.refId,

          CUSTOMER_ID: item.customer != null ? item.customer.refId : "NIL",
          CUSTOMER_NAME: item.customer != null ? item.customer.name : "NIL",
          // PRODUCT_ID: item.product != null ? item.product.refId : "NIL",
          INTRESTED_IN: item.product != null ? item.product.name : "NIL",
          CONVERSION_POTENTIAL:
            item.potential != null ? item.potential.conversionPotential : "NIL",
          LICENSEE_NAME:
            item.licenseeId != null ? item.licenseeId.orgName : "NIL",
          REGION: item.region != null ? item.region.name : "NIL",
          ZONE: item.zone != null ? item.zone.name : "NIL",
          AREA: item.area != null ? item.area.name : "NIL",
          SALES_EXECUTIVE:
            item.salesExecutive != null ? item.salesExecutive.name : "NIL",
          STATUS: item.status,
          LEADS_DATE: dateFormat(item.createdAt, "mediumDate"),
        });
      });

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      yield put({
        type: EXPORT_LEADS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EXPORT_LEADS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* addFollowup(value) {
  let id = value.value.id;
  const userId = localStorage.getItem("sStraitUserId");

  const token = localStorage.getItem("sStraitToken");
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

  const followupData = {
    date: value.value.date ? value.value.date : null,
    time: value.value.time,
    note: value.value.note,
    lead: value.value.id,
    creator: userId,
    type: value.value.type,
    assignedTo: userId,
    licenseeId: userDetails.licenseeId,
    region: userDetails.region,
    zone: userDetails.zone,
    area: userDetails.area,
    // notify: role !== "licensee" && [
    //   { user: licData && licData.userId && licData.userId.id },
    // ],
  };

  try {
    const res = yield fetch(`${appConfig.ip}/followUp`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(followupData),
    });

    if (!res.ok) {
      let errJSON1 = {};
      try {
        errJSON1 = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON1);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: ADD_FOLLOWUP_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Follow Up added successfully", {
        autoClose: 3000,
      });
      yield put({ type: FETCH_FOLLOWUP_INIT_ACTION, value: { page: 1 } });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_FOLLOWUP_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchSalesExctv(value) {
  const token = localStorage.getItem("sStraitToken");
  let id = !value || !value.value || !value.value.id ? "" : value.value.id;
  try {
    const res = yield fetch(
      `${appConfig.ip}/users?role=salesExcecutive&area=${id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_SALESEXCTV_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_SALESEXCTV_FAIL_ACTION, error: err });
    } else {
    }
  }
}

// Add distributor type lead
function* addDisLeads(value) {
  let products = [];
  value.value.products.map((item) => {
    products.push({
      id: item.id,
      refId: item.refId,
      name: item.name,
      licenseeId: item.licenseeId.id,
      ctgryId: item.ctgryId.id,
      subCtgryId: item.subCtgryId.id,

      unit: item.unit,
      backOrder: item.backOrder,
      buyingPrice: item.buyingPrice,
      sellingPrice: item.sellingPrice,
      currency: item.currency.id,
      gstSlab: item.gstSlab.id,

      PrdtType: item.PrdtType.id,
      agency: item.agency.id,
      commission: item.commission,
      description: item.description,
      image: item.image,
      channelPartner: item.channelPartner ? item.channelPartner : null,
      quantity: item.quantity,
      potential: {
        intrestedIn: value.value.product.find((e) => e.id === item.id).id,
        conversionPotential: value.value.product.find((e) => e.id === item.id)
          .value,
      },
    });
  });

  try {
    const token = localStorage.getItem("sStraitToken");

    const description = value.value.description;
    const customer = value.value.customer[0].id;
    const userId = localStorage.getItem("sStraitUserId");
    const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
    let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
    let role = userDetails.role;

    const data = {
      customer: customer,
      salesExecutive: userId,
      licenseeId: userDetails.licenseeId,
      region: userDetails.region,
      zone: userDetails.zone,
      area: userDetails.area,
      description: description,
      channelPartner: licData.channelPartner ? licData.channelPartner.id : null,
      products: products,

      notify: role !== "licensee" && [
        { user: licData && licData.userId && licData.userId.id },
      ],
    };

    const res = yield fetch(`${appConfig.ip}/lead/distributor`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: ADD_DISLEADS_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Leads added successfully", {
        autoClose: 3000,
      });

      yield put({
        type: FETCH_LEADS_INIT_ACTION,
        value: { page: 1 },
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_DISLEADS_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* editDisLeads(value) {
  let id = value.value.id;
  const token = localStorage.getItem("sStraitToken");

  let data;
  if (value.value.assign) {
    data = {
      region: value.value.region[0].id,
      zone: value.value.zone != "" ? value.value.zone[0].id : null,
      area: value.value.area != "" ? value.value.area[0].id : null,
      salesExecutive:
        value.value.salesExecutive != ""
          ? value.value.salesExecutive[0].id
          : null,
    };
  } else {
    const description = value.value.description;
    const status = value.value.status[0].value;
    data = {
      products: value.arr,
      status: status,
      description: description,
    };
  }

  try {
    const res = yield fetch(`${appConfig.ip}/lead/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      const leadIdData = yield call(fetchLeadsDetail, {
        value: { id: resJSON.id },
      });

      yield put({
        type: EDIT_LEADS_SUCCESS_ACTION,
        payload: leadIdData,
      });
      if (value.value.assign) {
        yield call(updateCustomer, { id: value.value.customerId, data: data });
        yield toast.success("Leads assigned successfully", {
          autoClose: 3000,
        });
      } else {
        yield toast.success("Leads updated successfully", {
          autoClose: 3000,
        });
      }
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EDIT_LEADS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* addLeads(value) {
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let role = userDetails.role;
  const product = value.value.productId;
  const description = value.value.description;
  const customer = value.value.customer[0].id;
  const intrestedIn = value.value.intrestedIn;
  const conversionPotential = value.value.conversionPotential;
  const userId = localStorage.getItem("sStraitUserId");

  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  const data = {
    licenseeId: userDetails.licenseeId,
    salesExecutive: userId,
    customer: customer,
    product: product,
    description: description,
    potential: {
      intrestedIn: intrestedIn,
      conversionPotential: conversionPotential,
    },
    notify: role !== "licensee" && [
      { user: licData && licData.userId && licData.userId.id },
    ],
  };

  if (role === "licensee") {
    const dummyIds = yield call(getDummyData, userDetails);

    yield call(AddLeadData, {
      ...data,
      region: dummyIds && dummyIds.region && dummyIds.region.id,
      zone: dummyIds && dummyIds.zone && dummyIds.zone.id,
      area: dummyIds && dummyIds.area && dummyIds.area.id,
    });
  } else if (role === "regionalManager") {
    const dummyIds = yield call(getDummyData, userDetails);
    yield call(AddLeadData, {
      ...data,
      region: userDetails.region,
      zone: dummyIds && dummyIds.zone && dummyIds.zone.id,
      area: dummyIds && dummyIds.area && dummyIds.area.id,
    });
  } else if (role === "zonalManager") {
    const dummyIds = yield call(getDummyData, userDetails);
    yield call(AddLeadData, {
      ...data,
      region: userDetails.region,
      zone: userDetails.zone,
      area: dummyIds && dummyIds.area && dummyIds.area.id,
    });
  } else {
    yield call(AddLeadData, {
      ...data,
      salesExecutive: userId,
      licenseeId: userDetails.licenseeId,
      region: userDetails.region,
      zone: userDetails.zone,
      area: userDetails.area,
      description: description,
      channelPartner: licData.channelPartner ? licData.channelPartner.id : null,
    });
  }
}

function* AddLeadData(value) {
  try {
    const token = localStorage.getItem("sStraitToken");

    const res = yield fetch(`${appConfig.ip}/lead`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(value),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: ADD_LEADS_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Lead added successfully", {
        autoClose: 3000,
      });
      yield put({
        type: FETCH_LEADS_INIT_ACTION,
        value: { page: 1 },
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_LEADS_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* getDummyData(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/users?licenseeId=${value.licenseeId}&dummy=true&role=areaManager`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      return resJSON.rows[0];
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

export function* LeadsActionWatcher() {
  yield takeEvery(FETCH_LEADS_INIT_ACTION, fetchLeads);
  yield takeEvery(
    FETCH_CUSTOMERS_BY_SALESEXECUTIVE_INIT_ACTION,
    fetchCusBySExec
  );
  yield takeEvery(
    FETCH_PRODUCTS_BY_SALESEXECUTIVE_INIT_ACTION,
    fetchPrdtBySExec
  );
  yield takeEvery(ADD_LEADS_INIT_ACTION, addLeads);
  yield takeEvery(DELETE_LEADS_INIT_ACTION, deleteLeads);
  yield takeEvery(EDIT_LEADS_INIT_ACTION, editLeads);
  yield takeEvery(FETCH_LEADS_DETAIL_INIT_ACTION, fetchLeadsDetail);
  yield takeEvery(FETCH_USER_DETAIL_INIT_ACTION, fetchUserDetail);
  yield takeEvery(FILTER_SEARCH_LEADS_INIT_ACTION, filterSearchLeads);
  yield takeEvery(EXPORT_LEADS_INIT_ACTION, exprtFullData);
  yield takeEvery(ADD_FOLLOWUP_INIT_ACTION, addFollowup);
  yield takeEvery(FETCH_SALESEXCTV_INIT_ACTION, fetchSalesExctv);
  yield takeEvery(ADD_DISLEADS_INIT_ACTION, addDisLeads);
  yield takeEvery(EDIT_DISLEADS_INIT_ACTION, editDisLeads);
}
