import {
  FETCH_ZONE_SUCCESS_ACTION,
  FETCH_DISTRICT_SUCCESS_ACTION,
  FETCH_ZONE_CLEAR_ACTION,
  FETCH_ZONE_DETAIL_SUCCESS_ACTION,
  FETCH_ZONE_DETAIL_INIT_ACTION,
  DELETE_ZONE_SUCCESS_ACTION,
  EDIT_ZONE_SUCCESS_ACTION,
  // FILTER_SEARCH_ZONE_SUCCESS_ACTION
} from "./action";

const initialState = {
  processing: true,
  error: false,
};

export default function ZoneReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    case FETCH_ZONE_SUCCESS_ACTION:
      return {
        ...state,
        zoneList: state.zoneList
          ? state.zoneList.concat(action.payload.rows)
          : action.payload.rows,
        zoneDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };

    // case FILTER_SEARCH_ZONE_SUCCESS_ACTION:
    //     return {
    //         ...state,
    //         zoneList: action.payload.rows,
    //         zoneDtlsCount: action.payload.count,
    //         processing: false,
    //         error: false,
    //     };

    case FETCH_DISTRICT_SUCCESS_ACTION:
      return {
        ...state,
        districtList: action.payload.rows,
      };
    case FETCH_ZONE_CLEAR_ACTION:
      return {
        ...state,
        zoneList: [],
        zoneDtlsCount: "",
      };

    case FETCH_ZONE_DETAIL_INIT_ACTION:
      return {
        ...state,
        processing: true,
        loading: true,
        error: false,
        zone: "",
      };

    case FETCH_ZONE_DETAIL_SUCCESS_ACTION:
      return {
        ...state,
        processing: false,
        loading: false,
        error: false,
        zone: action.payload,
      };
    case DELETE_ZONE_SUCCESS_ACTION:
      return {
        ...state,
        zoneList: state.zoneList.filter((item) => item.id !== action.payload),
        zoneDtlsCount: state.zoneDtlsCount - 1,
      };
    case EDIT_ZONE_SUCCESS_ACTION:
      return {
        ...state,
        zoneList: state.zoneList.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };

    default:
      return {
        ...state,
      };
  }
}
