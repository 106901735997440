import * as actionTypes from "./constants";

const initialState = {
  processing: false,
  error: false,
};

export default function googleFormReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_SOCIALLEAD_SUCCESS_ACTION:
      return {
        ...state,
        socialLeadList: action.payload.rows,
        socialLeadCount: action.payload.count,
      };

    case actionTypes.FETCH_AGENT_SUCCESS_ACTION:
      return {
        ...state,
        agentList: action.payload.rows,
      };
    case actionTypes.UPDATE_SOCIALSTATUS_SUCCESS_ACTION:
      return {
        ...state,
        socialLeadList: state.socialLeadList.map((item) =>
          item.id === action.payload.id
            ? { ...item, status: action.payload.status }
            : item
        ),
      };

    default:
      return {
        ...state,
      };
  }
}
