import { put, takeEvery, call, all } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_WDREQUEST_INIT_ACTION,
  FETCH_WDREQUEST_SUCCESS_ACTION,
  FETCH_WDREQUEST_FAIL_ACTION,
  ADD_WDREQUEST_INIT_ACTION,
  ADD_WDREQUEST_SUCCESS_ACTION,
  ADD_WDREQUEST_FAIL_ACTION,
  FETCH_WDREQUESTBYID_INIT_ACTION,
  FETCH_WDREQUESTBYID_SUCCESS_ACTION,
  FETCH_WDREQUESTBYID_FAIL_ACTION,
  EDIT_WDREQUEST_INIT_ACTION,
  EDIT_WDREQUEST_SUCCESS_ACTION,
  EDIT_WDREQUEST_FAIL_ACTION,
  FETCH_TOTALEARNINGS_INIT_ACTION,
  FETCH_TOTALEARNINGS_SUCCESS_ACTION,
  FETCH_TOTALEARNINGS_FAIL_ACTION,
  FETCH_CREATEDEARNINGS_SUCCESS_ACTION,
  FETCH_CREATEDEARNINGS_FAIL_ACTION,
  FETCH_CREATEDEARNINGS_INIT_ACTION,
  FETCH_WDLIMIT_INIT_ACTION,
  FETCH_WDLIMIT_SUCCESS_ACTION,
  FETCH_WDLIMIT_FAIL_ACTION,
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Get wdrequest

function* fetchWdRequest(value) {
  let pageno = value.value.page === undefined ? 1 : value.value.page;

  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? ""
      : value.value.searchVal;
  let filterVal =
    !value || !value.value || !value.value.filterVal
      ? ""
      : value.value.filterVal;

  const token = localStorage.getItem("sStraitToken");

  const role = localStorage.getItem("sStraitUserRole");
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  let getDataUrl = [
    { role: "licensee", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "distributor", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "licenseeAccountant", url: "&licenseeId=" + userDtls.licenseeId },
    {
      role: "regionalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&user=" + userDtls.id,
    },
    {
      role: "zonalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&user=" + userDtls.id,
    },
    {
      role: "areaManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&user=" + userDtls.id,
    },
    {
      role: "salesExcecutive",
      url: "&licenseeId=" + userDtls.licenseeId + "&user=" + userDtls.id,
    },
    {
      role: "agent",
      url: "&licenseeId=" + userDtls.licenseeId + "&user=" + userDtls.id,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  let _url = `${appConfig.ip}/wdrlReq?limit=10&page=` + pageno + apiUrl.url;

  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }
  if (filterVal) {
    _url = `${_url}${filterVal}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_WDREQUEST_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_WDREQUEST_FAIL_ACTION, error: err });
    } else {
    }
  }
}

//Get wdrequest by Id

function* getWdRequestById(value) {
  const token = localStorage.getItem("sStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/wdrlReq/` + value.value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_WDREQUESTBYID_SUCCESS_ACTION,
        payload: resJSON,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_WDREQUESTBYID_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchTotalEarnings(value) {
  const token = localStorage.getItem("sStraitToken");
  const role = localStorage.getItem("sStraitUserRole");
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  let getDataUrl = [
    { role: "licensee", url: "receiver=" + userDtls.id },
    { role: "licenseeAccountant", url: "receiver=" + userDtls.id },
    { role: "regionalManager", url: "receiver=" + userDtls.id },
    { role: "zonalManager", url: "receiver=" + userDtls.id },
    { role: "areaManager", url: "receiver=" + userDtls.id },
    { role: "salesExcecutive", url: "receiver=" + userDtls.id },
    { role: "distributor", url: "receiver=" + userDtls.id },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);
  if (value.value) {
    apiUrl = { role: value.value.role, url: "receiver=" + value.value.id };
  }

  try {
    const res = yield fetch(
      `${appConfig.ip}/earnings/totalClaims?` + apiUrl.url,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_TOTALEARNINGS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_TOTALEARNINGS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

//Get created earnings

function* fetchCreatedEarnings(value) {
  const token = localStorage.getItem("sStraitToken");
  const role = localStorage.getItem("sStraitUserRole");
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  let getDataUrl = [
    { role: "licensee", url: "receiver=" + userDtls.id },
    { role: "licenseeAccountant", url: "receiver=" + userDtls.id },
    { role: "regionalManager", url: "receiver=" + userDtls.id },
    { role: "zonalManager", url: "receiver=" + userDtls.id },
    { role: "areaManager", url: "receiver=" + userDtls.id },
    { role: "salesExcecutive", url: "receiver=" + userDtls.id },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  let status = "created";

  try {
    const res = yield fetch(
      `${appConfig.ip}/earnings?limit=100&${apiUrl.url}&status=${status}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_CREATEDEARNINGS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_CREATEDEARNINGS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* AddWdRequest(value) {
  try {
    const token = localStorage.getItem("sStraitToken");
    const userId = localStorage.getItem("sStraitUserId");
    const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
    const earnings =
      value && value.value && value.value.earningsList.map((x) => x.id);
    const currency = value && value.value && value.value.currency;
    const amount = value && value.value && value.value.totalUnclaimedAmount;
    let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
    const data = {
      user: userId,
      licenseeId: userDetails.licenseeId,
      earnings: earnings,
      currency: currency,
      totalAmount: amount,
      notify: [{ user: licData && licData.userId && licData.userId.id }],
    };

    const res = yield fetch(`${appConfig.ip}/wdrlReq`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();
      yield all(
        earnings.map((x) =>
          call(earningUpdate, { id: x, status: "processing" })
        )
      );
      yield put({
        type: FETCH_WDREQUEST_INIT_ACTION,
        value: { page: 1 },
      });
      yield put({
        type: ADD_WDREQUEST_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Withdrawal request added successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_WDREQUEST_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* EditWdRequest(value) {
  let id = value && value.value && value.value.id;
  let status = value && value.value && value.value.status;

  try {
    const token = localStorage.getItem("sStraitToken");
    const earnings =
      value && value.value && value.value.earningsList.map((x) => x.id);
    const data = {
      status: status,
    };
    const res = yield fetch(`${appConfig.ip}/wdrlReq/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      const resById = yield call(getWdRequestById, { value: resJSON.id });

      yield all(
        earnings.map((x) =>
          call(earningUpdate, {
            id: x,
            status: status === "accepted" ? "claimed" : "created",
          })
        )
      );
      if (status === "accepted") {
        yield call(acceptedWdReqstNotification, { value: value.value });
      }
      yield put({
        type: EDIT_WDREQUEST_SUCCESS_ACTION,
        payload: resById,
      });
      yield toast.success("Withdrawal request updated successfully", {
        autoClose: 3000,
      });

      // yield put({
      //   type: FETCH_WDREQUEST_INIT_ACTION,
      // });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: EDIT_WDREQUEST_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* acceptedWdReqstNotification(value) {
  let acceptedUser = value.value.earningsList.length
    ? value.value.earningsList[0].receiver.id
    : "";
  try {
    let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
    let orgName = licData && licData.userId && licData.userId.orgName;
    const data = {
      user: acceptedUser,
      event: "wdrlReqAccepted",
      subject: "Withdrawal Request Accepted",
      message: `Withdrawal request accepted by ${orgName}`,
      streams: ["pusher"],
    };

    const token = localStorage.getItem("sStraitToken");

    const res = yield fetch(`${appConfig.ip}/notfctn`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();
    }
  } catch (err) {}
}

function* earningUpdate(value) {
  let id = value.id;
  let status = value.status;

  const token = localStorage.getItem("sStraitToken");

  const data = {
    status: status,
  };

  try {
    const res = yield fetch(`${appConfig.ip}/earnings/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_WDREQUEST_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchWdLimit(value) {
  // let pageno = value.value === undefined ? 1 : value.value;

  const token = localStorage.getItem("sStraitToken");

  const role = localStorage.getItem("sStraitUserRole");
  const licenseeId = JSON.parse(
    localStorage.getItem("sStraitUserDtls")
  ).licenseeId;
  try {
    const res = yield fetch(
      `${appConfig.ip}/wdrlLimit?search=${role}&isActive=true&licenseeId=${licenseeId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_WDLIMIT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_WDLIMIT_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* WdRequestActionWatcher() {
  yield takeEvery(FETCH_WDREQUEST_INIT_ACTION, fetchWdRequest);
  yield takeEvery(ADD_WDREQUEST_INIT_ACTION, AddWdRequest);
  yield takeEvery(FETCH_WDREQUESTBYID_INIT_ACTION, getWdRequestById);
  yield takeEvery(EDIT_WDREQUEST_INIT_ACTION, EditWdRequest);
  yield takeEvery(FETCH_TOTALEARNINGS_INIT_ACTION, fetchTotalEarnings);
  yield takeEvery(FETCH_CREATEDEARNINGS_INIT_ACTION, fetchCreatedEarnings);

  yield takeEvery(FETCH_WDLIMIT_INIT_ACTION, fetchWdLimit);
}
