import React, { useEffect, useState } from "react";
import { Row, Col, Form as forms } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import Switch from "react-switch";
import * as Yup from "yup";

const BusinessType = ({ clickHandler, ...props }) => {
  const [showType, setShowType] = useState("");
  const [showNetWork, setShowNetWork] = useState(false);

  const validate = Yup.object({
    type: Yup.string().required("Choose distributor or direct seller"),
  });

  useEffect(() => {
    if (props.item && props.item.type) {
      setShowType(props.item.type);
      setShowNetWork(props.item.networkHierarchy);
    }
  }, []);

  // const handleChange = (checked) => {

  //   setShowNetWork(checked);
  // };

  return (
    <div>
      <Formik
        initialValues={{
          type: (props.item && props.item.type) || "",
          networkHierarchy:
            (props.item && props.item.networkHierarchy) || false,
        }}
        validationSchema={validate}
        onSubmit={(value) => {
          clickHandler(1, { ...value });
        }}
      >
        {(formik) => (
          <div>
            <div className="signup-page-div">
              <Form>
                <Row style={{ paddingTop: "25px" }}>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="mb-2" style={{ marginLeft: "15px" }}>
                      <label className="businessType-label">
                        Are you a distributor or direct seller?
                        <span className="asterik">*</span>
                      </label>
                      <div style={{ display: "flex", marginTop: "10px" }}>
                        <div className="divBorderAlign">
                          <span className="radio-btn-align">
                            <input
                              type="radio"
                              autoComplete="off"
                              className="radio-inpt"
                              checked={formik.values.type === "distributor"}
                              name="type"
                              onChange={() => {
                                formik.values.type = "distributor";
                                formik.errors.type && delete formik.errors.type;
                                setShowType("distributor");
                              }}
                            />
                            <label className="businessType-radio-label">
                              {" "}
                              Distributor
                            </label>
                          </span>
                        </div>
                        <div
                          className="divBorderAlign"
                          style={{ marginLeft: "20px" }}
                        >
                          <span className="radio-btn-align">
                            <input
                              type="radio"
                              autoComplete="off"
                              className="radio-inpt"
                              checked={formik.values.type === "licensee"}
                              name="type"
                              onChange={() => {
                                formik.values.type = "licensee";
                                formik.errors.type && delete formik.errors.type;
                                setShowType("licensee");
                              }}
                            />
                            <label className="businessType-radio-label">
                              Direct Seller
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="errStyle">{formik.errors.type}</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  {showType === "distributor" && (
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <div
                        className="mb-2"
                        style={{
                          marginLeft: "15px",
                          marginTop: "25px",
                          display: "flex",
                        }}
                      >
                        <label className="businessType-label">
                          Are you following the Region, Zone, and Area
                          hierarchy?
                        </label>
                        <div style={{ marginTop: "-2px", marginLeft: "10px" }}>
                          <Switch
                            onChange={(checked) => {
                              formik.values.networkHierarchy = checked;
                              setShowNetWork(checked);
                            }}
                            onColor="#023E8A"
                            checked={showNetWork}
                          />
                        </div>
                        {/* <div>
                          <span className="radio-btn-align">
                            <input
                              type="radio"
                              autoComplete="off"
                              className="radio-inpt"
                              checked={formik.values.networkHierarchy}
                              name="networkHierarchy"
                              onChange={() => {
                                formik.values.networkHierarchy = true;
                                setShowNetWork(true);
                              }}
                            />
                            <label className="businessType-radio-label">
                              Yes
                            </label>
                            <br />
                          </span>
                          <span className="radio-btn-align">
                            <input
                              type="radio"
                              autoComplete="off"
                              className="radio-inpt"
                              checked={!formik.values.networkHierarchy}
                              name="networkHierarchy"
                              onChange={() => {
                                formik.values.networkHierarchy = false;
                                setShowNetWork(false);
                              }}
                            />
                            <label className="businessType-radio-label">
                              No
                            </label>
                          </span>
                        </div> */}
                      </div>
                    </Col>
                  )}
                </Row>

                <div className="row buttons-step-div">
                  <Col lg={8} md={12} sm={12} xs={12}>
                    <div className="back-to-login">
                      Already have an account?
                      <span className="loginaccounts">
                        <span
                          className="loginaccounts"
                          style={{ marginLeft: "12px" }}
                        >
                          <Link to="/login" className="loginaccounts">
                            Login
                          </Link>
                        </span>
                      </span>
                    </div>
                  </Col>
                  <Col lg={4} md={12} sm={12} xs={12}>
                    <button className=" next-btn">Next</button>
                  </Col>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};
export default BusinessType;
