import {
 
  FETCH_SUPPORT_SUCCESS_ACTION,
  DELETE_SUPPORT_SUCCESS_ACTION,
  EDIT_SUPPORT_SUCCESS_ACTION,
  FETCH_SUPPORTTYPE_SUCCESS_ACTION,
  FETCH_SUPPORTBYID_INIT_ACTION

  // FETCH_SUPPORT_FAIL_ACTION
} from "./action";

const initialState = {
  processing: false,
  error: false
};

export default function SupportReducer(state: any = initialState, action: Function)
 {
  switch (action.type) {
    case FETCH_SUPPORT_SUCCESS_ACTION:
      return {
        ...state,
        supportList: action.payload.rows,
        supportCount: action.payload.count
      };
      case FETCH_SUPPORTTYPE_SUCCESS_ACTION:
        return {
          ...state,
          supportTypelist: action.payload.rows,
        };
      case EDIT_SUPPORT_SUCCESS_ACTION:
    
        return {          
          ...state,
          supportList: state.supportList.map((support) =>
          support.id === action.payload.id ? action.payload : support
          ),
          
        };       
      case DELETE_SUPPORT_SUCCESS_ACTION:
  
        return {
          ...state,
          supportList: state.supportList.filter(
            (item) => item.id !== action.payload
          ),
          supportCount: state.supportCount - 1
        };
        case FETCH_SUPPORTBYID_INIT_ACTION:
          return {
              ...state,
              processing: true,
              error: false,
              loading: true

          };

    default:
      return {
        ...state
      };
  }
}


