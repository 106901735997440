import * as actionTypes from "./constants";

const initialState = {
  processing: false,
  error: false,
  isPostFb: false,
  fieldList: [],
  fbFormList: [],
};

export default function facebookReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_PAGELIST_SUCCESS_ACTION:
      return {
        ...state,
        fbPageList: action.payload.data,
      };
    case actionTypes.FETCH_FORMLIST_SUCCESS_ACTION:
      return {
        ...state,
        fbFormList: action.payload.data,
      };
    case actionTypes.FETCH_EXTENDEDTOKN_SUCCESS_ACTION:
      return {
        ...state,
        extndedTokn: action.payload,
      };
    case actionTypes.POST_FACEBOOKDATA_INIT_ACTION:
      return {
        ...state,
        isPostFb: false,
      };
    case actionTypes.POST_FACEBOOKDATA_SUCCESS_ACTION:
      return {
        ...state,
        isPostFb: true,
      };
    case actionTypes.CLOSE_MODEL_INIT_ACTION:
      return {
        ...state,
        isPostFb: false,
      };
    case actionTypes.FETCH_FACEBOOKLIST_SUCCESS_ACTION:
      return {
        ...state,
        facebookList: action.payload.rows,
        facebookCount: action.payload.count,
      };
    case actionTypes.DELETE_FACEBOOKDATA_SUCCESS_ACTION:
      return {
        ...state,
        facebookList: state.facebookList.filter(
          (item) => item.id !== action.payload
        ),
        facebookCount: state.facebookCount - 1,
      };
    case actionTypes.FETCH_FORMFIELDS_SUCCESS_ACTION:
      return {
        ...state,
        fieldList: action.payload,
      };
    // case actionTypes.POST_FACEBOOKDATA_INIT_ACTION:
    //   return {
    //     ...state,
    //     fieldList: [],
    //     fbFormList: [],
    //   };
    case actionTypes.CLEAR_FORMDATA_INIT_ACTION:
      return {
        ...state,
        fieldList: [],
        fbFormList: [],
      };
    case actionTypes.UPDATE_FACEBOOK_SUCCESS_ACTION:
      return {
        ...state,
        facebookList: state.facebookList.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };

    default:
      return {
        ...state,
      };
  }
}
