import * as actionTypes from "./constants";

const initialState = {
  processing: false,
  error: false,
  isModel: false,
};

export default function IndiaMartReducer(state = initialState, action) {
  switch (action.type) {
    // case actionTypes.FETCH_FORMDATA_SUCCESS_ACTION:
    //   return {
    //     ...state,
    //     formList: action.payload.rows,
    //     formCount: action.payload.count,
    //     isModel: false,
    //   };

    default:
      return {
        ...state,
      };
  }
}
