export const FETCH_PRODUCTS_INIT_ACTION = "FETCH_PRODUCTS_INIT_ACTION";
export const FETCH_PRODUCTS_SUCCESS_ACTION =
  FETCH_PRODUCTS_INIT_ACTION + "_SUCCESS";
export const FETCH_PRODUCTS_FAIL_ACTION = FETCH_PRODUCTS_INIT_ACTION + "_ERROR";

export const FETCH_PRODUCTTYPE_INIT_ACTION = "FETCH_PRODUCTTYPE_INIT_ACTION";
export const FETCH_PRODUCTTYPE_SUCCESS_ACTION =
  FETCH_PRODUCTTYPE_INIT_ACTION + "_SUCCESS";
export const FETCH_PRODUCTTYPE_FAIL_ACTION =
  FETCH_PRODUCTTYPE_INIT_ACTION + "_ERROR";

export const FETCH_CATEGORY_INIT_ACTION = "FETCH_CATEGORY_INIT_ACTION";
export const FETCH_CATEGORY_SUCCESS_ACTION =
  FETCH_CATEGORY_INIT_ACTION + "_SUCCESS";
export const FETCH_CATEGORY_FAIL_ACTION = FETCH_CATEGORY_INIT_ACTION + "_ERROR";

export const FETCH_SUBCATEGORY_INIT_ACTION = "FETCH_SUBCATEGORY_INIT_ACTION";
export const FETCH_SUBCATEGORY_SUCCESS_ACTION =
  FETCH_SUBCATEGORY_INIT_ACTION + "_SUCCESS";
export const FETCH_SUBCATEGORY_FAIL_ACTION =
  FETCH_SUBCATEGORY_INIT_ACTION + "_ERROR";

export const FETCH_GST_INIT_ACTION = "FETCH_GST_INIT_ACTION";
export const FETCH_GST_SUCCESS_ACTION = FETCH_GST_INIT_ACTION + "_SUCCESS";
export const FETCH_GST_FAIL_ACTION = FETCH_GST_INIT_ACTION + "_ERROR";

export const ADD_PRODUCTS_INIT_ACTION = "ADD_PRODUCTS_INIT_ACTION";
export const ADD_PRODUCTS_SUCCESS_ACTION =
  ADD_PRODUCTS_INIT_ACTION + "_SUCCESS";
export const ADD_PRODUCTS_FAIL_ACTION = ADD_PRODUCTS_INIT_ACTION + "_ERROR";

export const FETCH_AREATYPE_INIT_ACTION = "FETCH_AREATYPE_INIT_ACTION";
export const FETCH_AREATYPE_SUCCESS_ACTION =
  FETCH_AREATYPE_INIT_ACTION + "_SUCCESS";
export const FETCH_AREATYPE_FAIL_ACTION = FETCH_AREATYPE_INIT_ACTION + "_ERROR";

export const FETCH_PRODUCTBYID_INIT_ACTION = "FETCH_PRODUCTBYID_INIT_ACTION";
export const FETCH_PRODUCTBYID_SUCCESS_ACTION =
  FETCH_PRODUCTBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_PRODUCTBYID_FAIL_ACTION =
  FETCH_PRODUCTBYID_INIT_ACTION + "_ERROR";

export const EDIT_PRODUCTS_INIT_ACTION = "EDIT_PRODUCTS_INIT_ACTION";
export const EDIT_PRODUCTS_SUCCESS_ACTION =
  EDIT_PRODUCTS_INIT_ACTION + "_SUCCESS";
export const EDIT_PRODUCTS_FAIL_ACTION = EDIT_PRODUCTS_INIT_ACTION + "_ERROR";

export const FETCH_UNITS_INIT_ACTION = "FETCH_UNITS_INIT_ACTION";
export const FETCH_UNITS_SUCCESS_ACTION = FETCH_UNITS_INIT_ACTION + "_SUCCESS";
export const FETCH_UNITS_FAIL_ACTION = FETCH_UNITS_INIT_ACTION + "_ERROR";

export const CHANGE_PRODUCT_STATUS_INIT_ACTION =
  "CHANGE_PRODUCT_STATUS_INIT_ACTION";
export const CHANGE_PRODUCT_STATUS_SUCCESS_ACTION =
  CHANGE_PRODUCT_STATUS_INIT_ACTION + "_SUCCESS";
export const CHANGE_PRODUCT_STATUS_FAIL_ACTION =
  CHANGE_PRODUCT_STATUS_INIT_ACTION + "_ERROR";

export const DELETE_PRODUCT_INIT_ACTION = "DELETE_PRODUCT_INIT_ACTION";
export const DELETE_PRODUCT_SUCCESS_ACTION =
  DELETE_PRODUCT_INIT_ACTION + "_SUCCESS";
export const DELETE_PRODUCT_FAIL_ACTION = DELETE_PRODUCT_INIT_ACTION + "_ERROR";

export const FETCH_AGENCYLIST_INIT_ACTION = "FETCH_AGENCYLIST_INIT_ACTION";
export const FETCH_AGENCYLIST_SUCCESS_ACTION =
  FETCH_AGENCYLIST_INIT_ACTION + "_SUCCESS";
export const FETCH_AGENCYLIST_FAIL_ACTION =
  FETCH_AGENCYLIST_INIT_ACTION + "_ERROR";

export const IMPORT_PRODUCT_INIT_ACTION = "IMPORT_PRODUCT_INIT_ACTION";
export const IMPORT_PRODUCT_SUCCESS_ACTION =
  IMPORT_PRODUCT_INIT_ACTION + "_SUCCESS";
export const IMPORT_PRODUCT_FAIL_ACTION = IMPORT_PRODUCT_INIT_ACTION + "_ERROR";
// export const FILTERSEARCH_PRODUCT_INIT_ACTION = "FILTERSEARCH_PRODUCT_INIT_ACTION";
// export const FILTERSEARCH_PRODUCT_SUCCESS_ACTION = FILTERSEARCH_PRODUCT_INIT_ACTION + "_SUCCESS";
// export const FILTERSEARCH_PRODUCT_FAIL_ACTION = FILTERSEARCH_PRODUCT_INIT_ACTION + "_ERROR";
export const ADD_VARIANT_INIT_ACTION = "ADD_VARIANT_INIT_ACTION";
export const CLEAR_VARIANT_INIT_ACTION = "CLEAR_VARIANT_INIT_ACTION";

export function fetchProducts(value) {
  return {
    type: FETCH_PRODUCTS_INIT_ACTION,
    value: value,
  };
}

export function fetchProductType() {
  return {
    type: FETCH_PRODUCTTYPE_INIT_ACTION,
  };
}

export function fetchCategory() {
  return {
    type: FETCH_CATEGORY_INIT_ACTION,
  };
}

export function fetchSubctgry(value) {
  return {
    type: FETCH_SUBCATEGORY_INIT_ACTION,
    value: value,
  };
}

export function fetchGst() {
  return {
    type: FETCH_GST_INIT_ACTION,
  };
}

export function AddProduct(value) {
  return {
    type: ADD_PRODUCTS_INIT_ACTION,
    value: value,
  };
}

export function fetchAreaType(value) {
  return {
    type: FETCH_AREATYPE_INIT_ACTION,
    value: value,
  };
}

export function getProductById(value) {
  return {
    type: FETCH_PRODUCTBYID_INIT_ACTION,
    value: value,
  };
}

export function EditProduct(value) {
  return {
    type: EDIT_PRODUCTS_INIT_ACTION,
    value: value,
  };
}

export function prdctSlectedPage(value) {
  return {
    type: FETCH_PRODUCTS_INIT_ACTION,
    value: value,
  };
}

export function fetchUnit() {
  return {
    type: FETCH_UNITS_INIT_ACTION,
  };
}

export function changeStatus(value) {
  return {
    type: CHANGE_PRODUCT_STATUS_INIT_ACTION,
    value: value,
  };
}

export function DeleteValue(value) {
  return {
    type: DELETE_PRODUCT_INIT_ACTION,
    value: value,
  };
}

export function fetchAgencyList(value) {
  return {
    type: FETCH_AGENCYLIST_INIT_ACTION,
    value: value,
  };
}

export function importData(value) {
  return {
    type: IMPORT_PRODUCT_INIT_ACTION,
    value: value,
  };
}
// export function filterSearchProduct(value) {
//   return {
//     type: FILTERSEARCH_PRODUCT_INIT_ACTION,
//     value:value
//   };
// }

export function addVariants(value) {
  return {
    type: ADD_VARIANT_INIT_ACTION,
    value: value,
  };
}

export function clearVariantList() {
  return {
    type: CLEAR_VARIANT_INIT_ACTION,
  };
}
