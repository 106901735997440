export const FETCH_DEBTORS_REPORT_INIT_ACTION =
  "FETCH_DEBTORS_REPORT_INIT_ACTION";
export const FETCH_DEBTORS_REPORT_SUCCESS_ACTION =
  FETCH_DEBTORS_REPORT_INIT_ACTION + "_SUCCESS";
export const FETCH_DEBTORS_REPORT_FAIL_ACTION =
  FETCH_DEBTORS_REPORT_INIT_ACTION + "_ERROR";

export const UPDATE_DEBTORS_PAY_AMOUNT_INIT_ACTION =
  "UPDATE_DEBTORS_PAY_AMOUNT_INIT_ACTION";
export const UPDATE_DEBTORS_PAY_AMOUNT_SUCCESS_ACTION =
  UPDATE_DEBTORS_PAY_AMOUNT_INIT_ACTION + "_SUCCESS";
export const UPDATE_DEBTORS_PAY_AMOUNT_FAIL_ACTION =
  UPDATE_DEBTORS_PAY_AMOUNT_INIT_ACTION + "_ERROR";

export const FETCH_DEBTORS_REPORT_BY_ID_INIT_ACTION =
  "FETCH_DEBTORS_REPORT_BY_ID_INIT_ACTION";
export const FETCH_DEBTORS_REPORT_BY_ID_SUCCESS_ACTION =
  FETCH_DEBTORS_REPORT_BY_ID_INIT_ACTION + "_SUCCESS";
export const FETCH_DEBTORS_REPORT_BY_ID_FAIL_ACTION =
  FETCH_DEBTORS_REPORT_BY_ID_INIT_ACTION + "_ERROR";

export const EXPORT_DEBTORS_REPORT_INIT_ACTION =
  "EXPORT_DEBTORS_REPORT_INIT_ACTION";
export const EXPORT_DEBTORS_REPORT_SUCCESS_ACTION =
  EXPORT_DEBTORS_REPORT_INIT_ACTION + "_SUCCESS";
export const EXPORT_DEBTORS_REPORT_FAIL_ACTION =
  EXPORT_DEBTORS_REPORT_INIT_ACTION + "_ERROR";
