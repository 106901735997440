import { put, takeEvery, call, all } from "redux-saga/effects";
import * as actionTypes from "./constants";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* getRoleApiUrl() {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const role = localStorage.getItem("sStraitUserRole");
  let getDataUrl = [
    { role: "distributor", url: "&licenseeId=" + userDtls.licenseeId },
    {
      role: "regionalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&region=" + userDtls.region,
    },
    {
      role: "zonalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&zone=" + userDtls.zone,
    },
    {
      role: "areaManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
    {
      role: "salesExcecutive",
      url: "&licenseeId=" + userDtls.licenseeId + "&initiator=" + userDtls.id,
    },
    {
      role: "agent",
      url: "&licenseeId=" + userDtls.licenseeId + "&initiator=" + userDtls.id,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);
  return apiUrl;
}

function* fetchSalesReturn(params) {
  let page = (params.params && params.params.page) || 1;
  const apiUrl = yield call(getRoleApiUrl);
  // let currentUser = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  // let licensee = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  // let role = localStorage.getItem("sStraitUserRole");

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/salesReturn?isActive=true&limit=10&page=${page}${apiUrl.url}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_SALES_RETURN_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_SALES_RETURN_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* fetchSalesReturnById(param) {
  let id = param && param.id;

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/salesReturn/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_SALES_RETURN_BY_ID_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_SALES_RETURN_BY_ID_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* commonFunction(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: `${value.failAction}`, error: err });
    } else {
    }
  }
}

function* updateProductQty(value) {
  const data = {
    quantity: value.quantity,
  };
  try {
    let params = {
      api: `${appConfig.ip}/product/updateQuantity/${value.id}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
    return res;
  } catch (e) {}
}

//update product variant
function* updateProductVariant(value) {
  let itemData = value.item;

  if (itemData.hasVariant) {
    const variantId = itemData.variants[0].variantId;
    const data = {
      variantId: variantId,
      quantity: itemData.quantity,
    };

    try {
      let params = {
        api: `${appConfig.ip}/product/variant/${itemData.id}`,
        method: "PUT",
        sucessAction: null,
        failAction: null,
        body: JSON.stringify(data),
      };
      const res = yield call(commonFunction, params);
      return res;
    } catch (e) {}
  }
}

function* approveSalesReturn(params) {
  const token = localStorage.getItem("sStraitToken");
  const userId = localStorage.getItem("sStraitUserId");
  let items = params && params.params && params.params.item;
  let values = params.params;
  let id = (params && params.params && params.params.id) || null;

  let selectedKeys = params.params.selectedRow || [];

  let products =
    (params &&
      params.params &&
      params.params.item &&
      params.params.item.products) ||
    [];

  let updatedProducts = products.map((p, i) => {
    if (selectedKeys.includes(p.id)) {
      p.withGst = true;
    } else {
      p.withGst = false;
    }
    return p;
  });

  let order =
    params && params.params && params.params.item && params.params.item.order;

  let orderId =
    params &&
    params.params &&
    params.params.item &&
    params.params.item.order &&
    params.params.item.order.refId;

  const data = {
    amount: (params && params.params && params.params.amount) || "null",
    verifier: userId || null,
    status: "accepted",
    products: updatedProducts || [],
  };

  try {
    const res = yield fetch(`${appConfig.ip}/salesReturn/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON1 = {};
      try {
        errJSON1 = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON1);
    } else {
      const resJSON = yield res.json();

      //Filter by same id total product quantity
      const productTotalFltr = Array.from(
        resJSON.products.reduce(
          (m, { id, quantity }) => m.set(id, (m.get(id) || 0) + quantity),
          new Map()
        ),
        ([id, quantity]) => ({ id, quantity })
      );

      //Updated product quantity
      yield all(
        productTotalFltr.map((x) =>
          call(updateProductQty, { id: x.id, quantity: x.quantity })
        )
      );

      //Updated product variants
      yield all(products.map((x) => call(updateProductVariant, { item: x })));

      //Updated product commission
      // yield all(
      //   products.map((x) => call(updateCommission, { item: x, data: items }))
      // );
      yield call(commissionSplit, { products, items });

      yield all(
        products.map((x) => call(getInventory, { item: x, orderId: orderId }))
      );

      yield call(createCreditNote, values);
      yield call(updateOrderStatus, { id: order.id, type: items.type });

      // yield put({ type: "FETCH_SALES_RETURN_INIT_ACTION" });

      yield put({
        type: actionTypes.APPROVE_SALES_RETURN_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Sales return approved  successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.APPROVE_SALES_RETURN_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* commissionSplit(value) {
  let licProfData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

  const comsnAmtArr = value.products.map((x) =>
    call(updateCommission, { item: x, data: value.items })
  );

  const comsnArr = yield all(comsnAmtArr);

  const sumComsnArr = getUniqueCommission(comsnArr);

  if (licProfData.type === "distributor" && licProfData.networkHierarchy) {
    let userIdArr = [];
    const [adminId, userIds] = yield all([
      call(fetchAdminDtls),
      call(fetchUserDtls, value.items),
      // call(fetchCommissionAmnt, Val),
    ]);

    adminId.count !== 0 &&
      adminId.rows.map((row) => {
        userIdArr.push({ role: "platform", userId: row.id });
      });

    userIdArr.push(
      { role: "salesExcecutive", userId: userIds.salesExecutive.id },
      { role: "company", userId: userIds.licenseeId.userId.id },
      { role: "regionalManager", userId: userIds.region.manager.id },
      { role: "zonalManager", userId: userIds.zone.manager.id },
      { role: "areaManager", userId: userIds.area.manager.id }
    );

    // Merge array

    let mergedArray = userIdArr.map((item) => {
      let cmmsnData = sumComsnArr.find((element) => element.role === item.role);
      return { ...item, ...cmmsnData };
    });
    let fltrArr = mergedArray.filter((item) => item.Amnt !== 0);

    let request = [];

    request.push(
      call(updateIncentiveBckt, {
        iBucketAmount: sumComsnArr.find((element) => element.role === "iBucket")
          .Amnt,
        value: value.items,
      })
    );

    fltrArr.forEach((el) => {
      request.push(
        call(updateMyEarnings, {
          userId: el.userId,
          amount: el.Amnt,
          value: value.items,
        })
      );
    });

    let incentvRes = yield all(request);
  } else {
    yield call(updateMyEarnings, {
      userId: licProfData.userId.id,
      amount: sumComsnArr.find((element) => element.role === "company").Amnt,
      value: value.items,
    });
    yield call(updateIncentiveBckt, {
      iBucketAmount: sumComsnArr.find((element) => element.role === "iBucket")
        .Amnt,
      value: value.items,
    });
  }
}

// Get Order User Details function

function* fetchUserDtls(value) {
  const apiUrl = "licenseeId=" + value.licenseeId.id;
  let id = value.order.id;
  try {
    let params = {
      api: `${appConfig.ip}/order/userDetails/${id}?${apiUrl}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const Res = yield call(commonFunction, params);

    return Res;
  } catch (e) {}
}

//Get Admin Id function

function* fetchAdminDtls() {
  try {
    let params = {
      api: `${appConfig.ip}/users?role=admin`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const Res = yield call(commonFunction, params);

    return Res;
  } catch (e) {}
}

// Addtion of commission array
function getUniqueCommission(data) {
  var result = [];
  data.map((el) => {
    el.forEach(function (a) {
      result.push({ role: a.role, Amnt: a.Amnt });
    });
  });
  const newResult = Object.values(
    result.reduce((acc, item) => {
      acc[item.role] = acc[item.role]
        ? { ...item, Amnt: item.Amnt + acc[item.role].Amnt }
        : item;
      return acc;
    }, {})
  );

  return newResult;
}

function* getInventory(value) {
  let Val = value.item;

  let _url = `${appConfig.ip}/inventory?product=${Val.id}`;
  if (Val.hasVariant) {
    _url = `${_url}&variantId=${Val.variants[0].variantId}`;
  }

  try {
    let params = {
      api: _url,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const Res = yield call(commonFunction, params);

    if (Res) {
      // Updated product inventory quantity
      yield call(updateInventoryQty, {
        id: Res.rows[0].id,
        quantity: Res.rows[0].quantity + Val.quantity,
      });
      // Create inventory log
      yield call(createInventoryLog, {
        item: {
          ...Val,
          inventory: Res.rows[0],
          orderId: value.orderId,
        },
      });
    }

    return Res;
  } catch (e) {}
}

//create Inventory Log/////////////////////////////////
function* createInventoryLog(value) {
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let itemData = value.item;

  let data = {
    inventory: itemData.inventory.id,
    product: itemData.id,
    user: userDetails.id,
    quantity: itemData.quantity,
    type: "addition",
    note:
      userDetails.name +
      " return " +
      itemData.quantity +
      " items of " +
      itemData.name +
      " with order Id " +
      itemData.orderId,
  };

  try {
    let params = {
      api: `${appConfig.ip}/inventoryLog`,
      method: "POST",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

//update Inventory quantity/////////////////////////////////
function* updateInventoryQty(value) {
  const data = {
    quantity: value.quantity,
  };

  try {
    let params = {
      api: `${appConfig.ip}/inventory/${value.id}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
  } catch (e) {}
}

//create Credit note/////////////////////////////////
function* createCreditNote(value) {
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let ordeId =
    (value && value.item && value.item.order && value.item.order.id) || "";

  const licDetails = yield call(getLicenseeProf);
  const customerData = yield call(getCustomerById, value.item.customer);
  const creditConfig = yield call(getQuoteConfig);
  let creditConfigData = creditConfig.rows;

  let data = {
    amnt: value && value.amount,
    customer: (value && value.item && value.item.customer) || null,
    licenseeId: userDetails.licenseeId || null,
    returnId: value && value.id,
    order: ordeId,
    customerInfo: {
      name: customerData.name,
      address: customerData.address,
      mobNo: customerData.mobileNo,
      email: customerData.email,
      gstin: customerData.gstin,
    },
    licensee: {
      orgName: licDetails.orgName,
      address: licDetails.addr1 ? licDetails.addr1 : "",
      contactNo: licDetails.contactNo ? licDetails.contactNo : "",
      contactEmail: licDetails.contactEmail ? licDetails.contactEmail : "",
      gstin: licDetails.gstin ? licDetails.gstin : "",
    },
    config: {
      remarks: creditConfigData && creditConfigData[0].remark,
      logo: creditConfigData && creditConfigData[0].imgUrl,
      addtCustInfo: creditConfigData && creditConfigData[0].addtCustInfo,
    },
  };

  try {
    let params = {
      api: `${appConfig.ip}/creditNote`,
      method: "POST",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);

    if (res) {
      let customerId = res && res.customer && res.customer.id;
      let amount = res && res.amnt;

      try {
        let params = {
          api: `${appConfig.ip}/customer/${customerId}`,
          method: "GET",
          sucessAction: null,
          failAction: null,
          body: null,
        };
        const custRes = yield call(commonFunction, params);

        yield call(updateCustomer, { custData: custRes, amount: amount });
      } catch (e) {}
    }
    return res;
  } catch (e) {}
}
//get customer by id
function* getCustomerById(value) {
  let id = value;
  try {
    let params = {
      api: `${appConfig.ip}/customer/${id}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const res = yield call(commonFunction, params);
    return res;
  } catch (e) {}
}
//get lienseeprof data
function* getLicenseeProf() {
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

  let userId = licData.userId.id;
  try {
    let params = {
      api: `${appConfig.ip}/licenseeprof/${userId}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

//get creditnote config
function* getQuoteConfig() {
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  let liceId = licData.id;
  try {
    let params = {
      api: `${appConfig.ip}/creditNoteConfig?licenseeId=${liceId}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const res = yield call(commonFunction, params);
    return res;
  } catch (e) {}
}

//update customer credit sum quantity////
function* updateCustomer(value) {
  let id = value && value.custData && value.custData.id;
  let currentCreditSum =
    value && value.custData && value.custData.creditNoteSum;
  let totalCreditSum = currentCreditSum + value.amount;
  let data = {
    creditNoteSum: totalCreditSum,
  };

  try {
    let params = {
      api: `${appConfig.ip}/customer/${id}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
  } catch (e) {}
}

//update commission -calculate function ////
function* updateCommission(value) {
  let data = value && value.item;
  let values = value && value.data;

  let buyingPrice = data.buyingPrice;
  let sellingPrice = data.sellingPrice;
  // let returnAmount = data.total;
  // let returnAmount =
  //   (data.total - data.gstAmount - data.discAmount) / data.quantity;
  let returnAmount;
  if (data.withGst) {
    returnAmount = (data.total - data.gstAmount) / data.quantity;
  } else {
    returnAmount = data.total / data.quantity;
  }

  // let commpanyPerctage = data.commission && data.commission.company;
  // let iBucketPerctage = data.commission && data.commission.incentivesbucket;
  let commsn = data.commission && data.commission;

  const calculateCommission = (sellingPrice, buingPrice) => {
    return ((sellingPrice - buingPrice) * 100) / sellingPrice;
  };
  const calculateCommissionAmount = (percentage, amount) => {
    return (amount * percentage) / 100;
  };

  let currentPercentage = calculateCommission(sellingPrice, buyingPrice);

  let commissionAmnt = calculateCommissionAmount(
    currentPercentage,
    returnAmount
  );

  let companyCommision =
    calculateCommissionAmount(commsn.company, commissionAmnt) * data.quantity;

  let iBucketCommision =
    calculateCommissionAmount(commsn.incentivesbucket, commissionAmnt) *
    data.quantity;
  let regionMangerCmsn =
    calculateCommissionAmount(commsn.regionManger, commissionAmnt) *
    data.quantity;
  let zoneMangerCmsn =
    calculateCommissionAmount(commsn.zoneManger, commissionAmnt) *
    data.quantity;
  let areaMangerCmsn =
    calculateCommissionAmount(commsn.areaManger, commissionAmnt) *
    data.quantity;
  let salesExecutiveCmsn =
    calculateCommissionAmount(commsn.salesExecutive, commissionAmnt) *
    data.quantity;
  let platformCmsn =
    calculateCommissionAmount(commsn.platform, commissionAmnt) * data.quantity;

  let companyAmount = companyCommision.toFixed(2);
  let iBucketAmount = iBucketCommision.toFixed(2);
  let regionMangerAmount = regionMangerCmsn.toFixed(2);
  let zoneMangerAmount = zoneMangerCmsn.toFixed(2);
  let areaMangerAmount = areaMangerCmsn.toFixed(2);
  let salesExecutiveAmount = salesExecutiveCmsn.toFixed(2);
  let platformAmount = platformCmsn.toFixed(2);

  let comsnAmtArr = [];
  comsnAmtArr.push(
    { role: "company", Amnt: parseFloat(companyAmount) },
    { role: "iBucket", Amnt: parseFloat(iBucketAmount) },
    { role: "platform", Amnt: parseFloat(platformAmount) },
    { role: "regionalManager", Amnt: parseFloat(regionMangerAmount) },
    { role: "zonalManager", Amnt: parseFloat(zoneMangerAmount) },
    { role: "areaManager", Amnt: parseFloat(areaMangerAmount) },
    { role: "salesExcecutive", Amnt: parseFloat(salesExecutiveAmount) }
  );
  return comsnAmtArr;
  // yield call(updateMyEarnings, {
  //   companyAmount: -companyAmount,
  //   value: values,
  // });
  // yield call(updateIncentiveBckt, {
  //   iBucketAmount: -iBucketAmount,
  //   value: values,
  // });
}

//create earnings ////
function* updateMyEarnings(value) {
  let params = value && value.value;

  let currencyId = JSON.parse(localStorage.getItem("sStraitCurrency"))
    .currencyId.id;

  let data = {
    receiver: value.userId,
    licenseeId: params.licenseeId,
    order: params.order.id,
    salesExecutive: params.initiator.id,
    currency: currencyId,
    amount: -value.amount,
    type: "salesReturn",
  };

  try {
    let params = {
      api: `${appConfig.ip}/earnings`,
      method: "POST",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
  } catch (e) {}
}

//create IncentiveBckt ////
function* updateIncentiveBckt(value) {
  let params = value && value.value;
  let userId = JSON.parse(localStorage.getItem("sStraitLicProfDtls")).userId.id;
  let currencyId = JSON.parse(localStorage.getItem("sStraitCurrency"))
    .currencyId.id;

  let data = {
    receiver: userId,
    licenseeId: params.licenseeId,
    order: params.order.id,
    salesExecutive: params.initiator.id,
    currency: currencyId,
    amount: -value.iBucketAmount,
  };

  try {
    let params = {
      api: `${appConfig.ip}/incentiveBucket`,
      method: "POST",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
  } catch (e) {}
}

//update Order Status/////////////////////////////////
function* updateOrderStatus(value) {
  let type = value && value.type;
  const data = {
    status: type === "full" ? "fullreturn" : "partialreturn",
  };

  try {
    let params = {
      api: `${appConfig.ip}/order/${value.id}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
  } catch (e) {}
}

export function* salesReturnActionWatcher() {
  yield takeEvery(actionTypes.FETCH_SALES_RETURN_INIT_ACTION, fetchSalesReturn);
  yield takeEvery(
    actionTypes.FETCH_SALES_RETURN_BY_ID_INIT_ACTION,
    fetchSalesReturnById
  );
  yield takeEvery(
    actionTypes.APPROVE_SALES_RETURN_INIT_ACTION,
    approveSalesReturn
  );
}
