import { put, takeEvery, select, call, all } from "redux-saga/effects";
// import { createBrowserHistory } from "history";

import { appConfig } from "../../config";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import * as actionTypes from "./constants";

//common function
function* commonFunction(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: `${value.failAction}`, error: err });
    } else {
    }
  }
}

function* AddTradeIndia(value) {
  let id = JSON.parse(localStorage.getItem("sStraitUserDtls")).id;
  const data = {
    tradeIndia: {
      name: value.value.name,
      userId: value.value.userid,
      profileId: value.value.profid,
      key: value.value.key,
    },
  };

  try {
    let params = {
      api: `${appConfig.ip}/licenseeProf/${id}`,
      method: "PUT",
      sucessAction: actionTypes.ADD_TRADEINDIA_SUCCESS_ACTION,
      failAction: actionTypes.ADD_TRADEINDIA_FAIL_ACTION,
      body: JSON.stringify(data),
      toastMsg: "Added Successfully",
    };
    const res = yield call(commonFunction, params);
    if (res) {
      let profile = {
        ...JSON.parse(localStorage.getItem("sStraitLicProfDtls")),
        ...data,
      };
      localStorage.setItem("sStraitLicProfDtls", JSON.stringify(profile));
    }
  } catch (e) {}
}

export function* TradeIndActionWatcher() {
  yield takeEvery(actionTypes.ADD_TRADEINDIA_INIT_ACTION, AddTradeIndia);
}
