import {
  FETCH_INCENTIVEBUCKET_SUCCESS_ACTION,
  FETCH_INCENTIVEBUCKET_INIT_ACTION,
  FETCH_INCENTIVEBUCKETBYID_INIT_ACTION,
  FETCH_INCENTIVEBUCKETBYID_SUCCESS_ACTION,
  FETCH_INCENTIVEBUCKETBYID_FAIL_ACTION,
  FETCH_AREAS_DROPDOWN_SUCCESS_ACTION,
  FETCH_TOTAL_SUCCESS_ACTION,
  FETCH_TOTAL_INIT_ACTION,
} from "./action";

const initialState = {
  processing: true,
  error: false,
  loading: true,
};

export default function incentiveBucketReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    case FETCH_INCENTIVEBUCKET_INIT_ACTION:
      return {
        ...state,
        processing: true,
        error: false,
      };
    case FETCH_INCENTIVEBUCKET_SUCCESS_ACTION:
      return {
        ...state,
        incentiveBucketList: action.payload.rows,
        incentiveBucketCount: action.payload.count,
        processing: false,
        error: false,
      };

    case FETCH_INCENTIVEBUCKETBYID_SUCCESS_ACTION:
      return {
        ...state,
        incentiveBucketDtls: action.payload,
        processing: false,
        error: false,
        loading: false,
      };

    case FETCH_AREAS_DROPDOWN_SUCCESS_ACTION:
      return {
        ...state,
        areaDropdownList: action.payload.rows,
      };
    case FETCH_TOTAL_INIT_ACTION:
      return {
        ...state,
        TotalEarningsList: "",
        processing: false,
        error: false,
      };

    case FETCH_TOTAL_SUCCESS_ACTION:
      return {
        ...state,
        TotalEarningsList: action.payload,
        processing: false,
        error: false,
      };

    default:
      return {
        ...state,
      };
  }
}
