import React from "react";
import { connect } from "react-redux";
import Signup from "../../screens/Signup";
import Actions from "./action";

const SignupContainer = (props) => {
  return (
    <Signup
      addSignupData={props.addSignupData}
      fetchCountryInSignup={props.fetchCountryInSignup}
      countryList={props.countryList}
      fetchStateInSignup={props.fetchStateInSignup}
      stateList={props.stateList}
      fetchDistrictInSignup={props.fetchDistrictInSignup}
      districtList={props.districtList}
      fetchBankInSignup={props.fetchBankInSignup}
      bankList={props.bankList}
    />
  );
};
const mapStateToprops = (state) => {
  return {
    ...state.signUpReducer,
  };
};

export default connect(mapStateToprops, { ...Actions })(SignupContainer);
