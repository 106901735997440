import { put, takeEvery, call } from "redux-saga/effects";
// import { createBrowserHistory } from "history";
import {
  FETCH_LICENSEEPROFILE_INIT_ACTION,
  FETCH_LICENSEEPROFILE_SUCCESS_ACTION,
  FETCH_LICENSEEPROFILE_FAIL_ACTION,
  FETCH_USERDATA_INIT_ACTION,
  FETCH_USERDATA_SUCCESS_ACTION,
  FETCH_USERDATA_FAIL_ACTION,
  FETCH_LICPROFDATA_INIT_ACTION,
  FETCH_LICPROFDATA_SUCCESS_ACTION,
  FETCH_LICPROFDATA_FAIL_ACTION,
} from "./action";

import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// var history = createBrowserHistory();

function* fetchLicenseeProfile(value) {
  const token = localStorage.getItem("sStraitToken");
  const userId = localStorage.getItem("sStraitUserId");
  const role = localStorage.getItem("sStraitUserRole");

  let getDataUrl = [
    { role: "licensee", url: "licenseeprof/" },
    { role: "distributor", url: "licenseeprof/" },
    { role: "licenseeAccountant", url: "userprof/" },
    { role: "regionalManager", url: "userprof/" },
    { role: "zonalManager", url: "userprof/" },
    { role: "areaManager", url: "userprof/" },
    { role: "salesExcecutive", url: "userprof/" },
    { role: "agent", url: "userprof/" },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  try {
    const res = yield fetch(`${appConfig.ip}/` + apiUrl.url + userId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_LICENSEEPROFILE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_LICENSEEPROFILE_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchUserData(value) {
  const token = localStorage.getItem("sStraitToken");
  const id = localStorage.getItem("sStraitUserId");

  try {
    const res = yield fetch(`${appConfig.ip}/users/` + id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_USERDATA_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_USERDATA_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchLicProfileData() {
  const token = localStorage.getItem("sStraitToken");
  let licenseeId = JSON.parse(localStorage.getItem("sStraitUserDtls"))
    .licenseeId;

  try {
    const res = yield fetch(`${appConfig.ip}/licenseeProf/show/${licenseeId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      localStorage.setItem("sStraitLicProfDtls", JSON.stringify(resJSON));
      const currencyData = yield call(fetchCntryCrncy, resJSON.country.id);

      localStorage.setItem("sStraitCurrency", JSON.stringify(currencyData));
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* fetchCntryCrncy(countryId) {
  const token = localStorage.getItem("sStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/countryCrncy?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      let resData = resJSON.rows.find((e) => e.countryId.id === countryId);
      // let crncyId = resData.currencyId.id
      let crncyId = resData;

      return crncyId;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

export function* MyProfileActionWatcher() {
  yield takeEvery(FETCH_LICENSEEPROFILE_INIT_ACTION, fetchLicenseeProfile);
  yield takeEvery(FETCH_USERDATA_INIT_ACTION, fetchUserData);
  yield takeEvery(FETCH_LICPROFDATA_INIT_ACTION, fetchLicProfileData);
}
