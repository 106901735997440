export const FETCH_LICENSEEPROFILEEDIT_INIT_ACTION =
  "FETCH_LICENSEEPROFILEEDIT_INIT_ACTION";
export const FETCH_LICENSEEPROFILEEDIT_SUCCESS_ACTION =
  FETCH_LICENSEEPROFILEEDIT_INIT_ACTION + "_SUCCESS";
export const FETCH_LICENSEEPROFILEEDIT_FAIL_ACTION =
  FETCH_LICENSEEPROFILEEDIT_INIT_ACTION + "_ERROR";

  export const EDIT_LICENSEEPROF_INIT_ACTION =
  "EDIT_LICENSEEPROF_INIT_ACTION";
export const EDIT_LICENSEEPROF_SUCCESS_ACTION =
  EDIT_LICENSEEPROF_INIT_ACTION + "_SUCCESS";
export const EDIT_LICENSEEPROF_FAIL_ACTION =
  EDIT_LICENSEEPROF_INIT_ACTION + "_ERROR";

  export const FETCH_BANK_INIT_ACTION = "FETCH_BANK_INIT_ACTION";
  export const FETCH_BANK_SUCCESS_ACTION = FETCH_BANK_INIT_ACTION + "_SUCCESS";
  export const FETCH_BANK_FAIL_ACTION = FETCH_BANK_INIT_ACTION + "_ERROR";

  export function fetchLicenseeProfileEdit(value) {
    return {
      type: FETCH_LICENSEEPROFILEEDIT_INIT_ACTION,
      value: value
    };
  }

  export function EditLicenseeprof(value) {
    return {
      type: EDIT_LICENSEEPROF_INIT_ACTION,
      value: value
    };
  }



  export function fetchBank() {
 
    return {
      type: FETCH_BANK_INIT_ACTION
    };
  }
  