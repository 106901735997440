import { put, takeEvery, call, all } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_REGION_INIT_ACTION,
  FETCH_REGION_SUCCESS_ACTION,
  FETCH_REGION_FAIL_ACTION,
  FETCH_STATELIST_INIT_ACTION,
  FETCH_STATELIST_SUCCESS_ACTION,
  FETCH_STATELIST_FAIL_ACTION,
  ADD_REGION_INIT_ACTION,
  ADD_REGION_SUCCESS_ACTION,
  ADD_REGION_FAIL_ACTION,
  DELETE_REGION_INIT_ACTION,
  DELETE_REGION_SUCCESS_ACTION,
  DELETE_REGION_FAIL_ACTION,
  EDIT_REGION_INIT_ACTION,
  EDIT_REGION_SUCCESS_ACTION,
  EDIT_REGION_FAIL_ACTION,
  FETCH_REGION_CLEAR_ACTION,
  FETCH_REGION_DETAIL_INIT_ACTION,
  FETCH_REGION_DETAIL_SUCCESS_ACTION,
  FETCH_REGION_DETAIL_FAIL_ACTION,
  // SEARCH_REGION_INIT_ACTION,
  // SEARCH_REGION_SUCCESS_ACTION,
  // SEARCH_REGION_FAIL_ACTION
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* fetchRegion(value) {
  // let pageno = value.value === undefined ? 1 : value.value;
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licenseeId = userDetails.licenseeId;
  const token = localStorage.getItem("sStraitToken");

  let pageno =
    !value || !value.value || !value.value.page ? 1 : value.value.page;
  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? ""
      : value.value.searchVal;

  let _url = `${appConfig.ip}/region?limit=12&page=${pageno}&licenseeId=${licenseeId}&isActive=true&dummy=false`;

  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_REGION_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_REGION_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchStateList(value) {
  //let pageno = value.value === undefined ? 1 : value.value;
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

  let countryId = licData.country.id;
  let searchVal =
    !value || !value.value || !value.value.searchval
      ? ""
      : value.value.searchval;
  const token = localStorage.getItem("sStraitToken");

  try {
    const res = yield fetch(
      `${appConfig.ip}/state?search=${searchVal}&isActive=true&countryId=${countryId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_STATELIST_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_STATELIST_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchRegionExist(value) {
  const token = localStorage.getItem("sStraitToken");
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licenseeId = userDetails.licenseeId;

  try {
    const res = yield fetch(
      `${appConfig.ip}/region/exist?name=${value}&licenseeId=${licenseeId}&isActive=true`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* addRegion(value) {
  let regionName = value.value.name;
  const checkName = yield call(fetchRegionExist, regionName);

  if (checkName) {
    yield toast.error("Region Already existed", {
      autoClose: 3000,
    });
  } else {
    try {
      const token = localStorage.getItem("sStraitToken");
      const states = value.value.states.map((x) => x.value);
      const userId = localStorage.getItem("sStraitUserId");

      const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
      const data = {
        licenseeId: userDetails.licenseeId,
        user: userId,
        ref: states.length === 1 ? "state" : "states",
        states: states,
        name: value.value.name.toLowerCase(),
      };

      const res = yield fetch(`${appConfig.ip}/region`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) {
        let errJSON = {};
        try {
          errJSON = yield res.json();
        } catch {
          throw Object.assign(res, errJSON);
        }
      } else {
        const resJSON = yield res.json();

        yield put({
          type: ADD_REGION_SUCCESS_ACTION,
          payload: resJSON,
        });

        yield toast.success("Region added successfully", {
          autoClose: 3000,
        });

        yield put({
          type: FETCH_REGION_CLEAR_ACTION,
        });
        yield put({
          type: FETCH_REGION_INIT_ACTION,
          value: { page: 1 },
        });
      }
    } catch (err) {
      if (err.ok === false) {
        yield put({
          type: ADD_REGION_FAIL_ACTION,
          error: err,
        });
      }
    }
  }
}

function* deleteRegion(value) {
  let id = value.value.id;

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/region/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield call(bulkDeleteZone, id);
      yield call(bulkDeleteArea, id);
      // yield put({
      //   type: FETCH_REGION_CLEAR_ACTION,
      // });
      // yield put({
      //   type: FETCH_REGION_INIT_ACTION,
      //   value: { page: 1 },
      // });
      yield put({
        type: DELETE_REGION_SUCCESS_ACTION,
        payload: id,
      });
      yield toast.success("Region deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_REGION_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* editRegion(value) {
  const states = value.value.states.map((x) => x.value);
  let id = value.value.id;
  const userId = localStorage.getItem("sStraitUserId");
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  const data = {
    licenseeId: userDetails.licenseeId,
    user: userId,
    ref: states.length === 1 ? "state" : "states",
    states: states,
    name: value.value.name.toLowerCase(),
  };

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/region/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      // yield put({
      //   type: FETCH_REGION_CLEAR_ACTION,
      // });
      // yield put({
      //   type: FETCH_REGION_INIT_ACTION,
      //   value: { page: 1 },
      // });
      yield toast.success("Region updated successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();

      const regionRes = yield call(fetchRegionDetail, {
        value: { id: resJSON.id },
      });

      yield put({
        type: EDIT_REGION_SUCCESS_ACTION,
        payload: regionRes,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EDIT_REGION_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchRegionDetail(value) {
  const token = localStorage.getItem("sStraitToken");
  let id = !value || !value.value || !value.value.id ? "" : value.value.id;
  try {
    const res = yield fetch(`${appConfig.ip}/region/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      // if (resJSON.region && resJSON.region.ref === 'state') {
      //   yield call(fetchDistrict, { value: { id: resJSON.region.states[0].id } })
      // }
      yield put({
        type: FETCH_REGION_DETAIL_SUCCESS_ACTION,
        payload: resJSON,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_REGION_DETAIL_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* bulkDeleteZone(id) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/zone/byregion/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: DELETE_REGION_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* bulkDeleteArea(id) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/area/byregion/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: DELETE_REGION_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

export function* RegionActionWatcher() {
  yield takeEvery(FETCH_REGION_INIT_ACTION, fetchRegion);
  yield takeEvery(FETCH_STATELIST_INIT_ACTION, fetchStateList);
  yield takeEvery(ADD_REGION_INIT_ACTION, addRegion);
  yield takeEvery(DELETE_REGION_INIT_ACTION, deleteRegion);
  yield takeEvery(EDIT_REGION_INIT_ACTION, editRegion);
  yield takeEvery(FETCH_REGION_DETAIL_INIT_ACTION, fetchRegionDetail);
  // yield takeEvery(SEARCH_REGION_INIT_ACTION, searchRegion);
}
