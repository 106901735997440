import * as actionTypes from "./constants";
import { sortData } from "../../../screens/Common/sortData";

const initialState = {
  processing: false,
  error: false,
  creditnoteReportList: [],
  creditnoteReportCount: 0,
  creditnoteReportByIdList: {},

};

export default function creditnoteReportReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_CREDITNOTE_REPORT_SUCCESS_ACTION:
      return {
        ...state,
        creditnoteReportList: action.payload.rows,
        creditnoteReportCount: action.payload.count,
      };
    case actionTypes.FETCH_CREDITNOTE_REPORT_BY_ID_SUCCESS_ACTION:
      return {
        ...state,
        creditnoteReportByIdList: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
}
