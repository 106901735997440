export const FETCH_QUOTATIONS_INIT_ACTION = "FETCH_QUOTATIONS_INIT_ACTION";
export const FETCH_QUOTATIONS_SUCCESS_ACTION =
  FETCH_QUOTATIONS_INIT_ACTION + "_SUCCESS";
export const FETCH_QUOTATIONS_FAIL_ACTION =
  FETCH_QUOTATIONS_INIT_ACTION + "_ERROR";

export const FETCH_QUOTATIONSBYID_INIT_ACTION =
  "FETCH_QUOTATIONSBYID_INIT_ACTION";
export const FETCH_QUOTATIONSBYID_SUCCESS_ACTION =
  FETCH_QUOTATIONSBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_QUOTATIONSBYID_FAIL_ACTION =
  FETCH_QUOTATIONSBYID_INIT_ACTION + "_ERROR";

export const ADD_QUOTATION_INIT_ACTION = "ADD_QUOTATION_INIT_ACTION";
export const ADD_QUOTATION_SUCCESS_ACTION =
  ADD_QUOTATION_INIT_ACTION + "_SUCCESS";
export const ADD_QUOTATION_FAIL_ACTION = ADD_QUOTATION_INIT_ACTION + "_ERROR";

export const PDF_QUOTATION_REPORT_INIT_ACTION =
  "PDF_QUOTATION_REPORT_INIT_ACTION";
export const PDF_QUOTATION_REPORT_SUCCESS_ACTION =
  PDF_QUOTATION_REPORT_INIT_ACTION + "_SUCCESS";
export const PDF_QUOTATION_REPORT_FAIL_ACTION =
  PDF_QUOTATION_REPORT_INIT_ACTION + "_ERROR";

export const EDIT_QUOTATION_INIT_ACTION = "EDIT_QUOTATION_INIT_ACTION";
export const EDIT_QUOTATION_SUCCESS_ACTION =
  EDIT_QUOTATION_INIT_ACTION + "_SUCCESS";
export const EDIT_QUOTATION_FAIL_ACTION = EDIT_QUOTATION_INIT_ACTION + "_ERROR";

export const CHANGE_QUOTATION_STATUS_INIT_ACTION =
  "CHANGE_QUOTATION_STATUS_INIT_ACTION";
export const CHANGE_QUOTATION_STATUS_SUCCESS_ACTION =
  CHANGE_QUOTATION_STATUS_INIT_ACTION + "_SUCCESS";
export const CHANGE_QUOTATION_STATUS_FAIL_ACTION =
  CHANGE_QUOTATION_STATUS_INIT_ACTION + "_ERROR";

export const ADD_QUOTATION_FIELD_SUGGESTION_INIT_ACTION =
  "ADD_QUOTATION_FIELD_SUGGESTION_INIT_ACTION";
export const ADD_QUOTATION_FIELD_SUGGESTION_SUCCESS_ACTION =
  ADD_QUOTATION_FIELD_SUGGESTION_INIT_ACTION + "_SUCCESS";
export const ADD_QUOTATION_FIELD_SUGGESTION_FAIL_ACTION =
  ADD_QUOTATION_FIELD_SUGGESTION_INIT_ACTION + "_ERROR";

export const FETCH_QUOTATION_FIELD_SUGGESTION_INIT_ACTION =
  "FETCH_QUOTATION_FIELD_SUGGESTION_INIT_ACTION";
export const FETCH_QUOTATION_FIELD_SUGGESTION_SUCCESS_ACTION =
  FETCH_QUOTATION_FIELD_SUGGESTION_INIT_ACTION + "_SUCCESS";
export const FETCH_QUOTATION_FIELD_SUGGESTION_FAIL_ACTION =
  FETCH_QUOTATION_FIELD_SUGGESTION_INIT_ACTION + "_ERROR";

// export const CHANGE_ORDERTOQUOTESTATUS_SUCCESS_ACTION =
//   "CHANGE_ORDERTOQUOTESTATUS_SUCCESS_ACTION";

export function fetchQuotations(value) {
  return {
    type: FETCH_QUOTATIONS_INIT_ACTION,
    value: value,
  };
}

export function getQuotationsById(value, isImageView = false) {
  return {
    type: FETCH_QUOTATIONSBYID_INIT_ACTION,
    value: value,
    isImageView: isImageView,
  };
}

export function AddQuotation(value) {
  return {
    type: ADD_QUOTATION_INIT_ACTION,
    value: value,
  };
}

export function pdfQuotationData(value) {
  return {
    type: PDF_QUOTATION_REPORT_INIT_ACTION,
    value: value,
  };
}
export function EditQuotation(value) {
  return {
    type: EDIT_QUOTATION_INIT_ACTION,
    value: value,
  };
}
export function changeStatus(value) {
  return {
    type: CHANGE_QUOTATION_STATUS_INIT_ACTION,
    value: value,
  };
}

export function updateQuoteStatus(value) {
  return {
    type: CHANGE_QUOTATION_STATUS_SUCCESS_ACTION,
    value: value,
  };
}

export function addQuotationFieldSuggestion(value) {
  return {
    type: ADD_QUOTATION_FIELD_SUGGESTION_INIT_ACTION,
    value: value,
  };
}

export function fetchQuotationFieldSuggestion(value) {
  return {
    type: FETCH_QUOTATION_FIELD_SUGGESTION_INIT_ACTION,
    value: value,
  };
}
