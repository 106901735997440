import {
    FETCH_FOLLOWUP_SUCCESS_ACTION,
    FETCH_FOLLOWUPBYID_SUCCESS_ACTION,
    CHANGESTATUS_FOLLOWUP_SUCCESS_ACTION,
    EDIT_RESCHEDULE_SUCCESS_ACTION

}

    from "./action";

const initialState = {
    processing: true,
    error: false,
    custLoading: true,
};

export default function FollowupReducer(
    state: any = initialState,
    action: Function
) {
    switch (action.type) {
        case FETCH_FOLLOWUP_SUCCESS_ACTION:

            return {
                ...state,
                followupList: action.payload.rows,
                followupCount: action.payload.count,
                processing: false,
                error: false,

            };

        case FETCH_FOLLOWUPBYID_SUCCESS_ACTION:
            return {
                ...state,
                followupDtls: action.payload,
                custLoading: false,
            };

        case CHANGESTATUS_FOLLOWUP_SUCCESS_ACTION:
            return {
                ...state,
                followupList: state.followupList.map((followup) =>
                    followup.id === action.payload.id
                        ? { ...followup, status: action.payload.status }
                        : followup
                ),
            };
        case EDIT_RESCHEDULE_SUCCESS_ACTION:
            return {
                ...state,
                followupList: state.followupList.map((followup) =>
                    followup.id === action.payload.id ? action.payload : followup
                ),
            };

        default:
            return {
                ...state,
            };
    }
}
