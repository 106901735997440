import * as actionTypes from "./contstants";

const addSignupData = (params) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_SIGNUP_DATA_INIT_ACTION,
      params: params,
    });
  };
};

const fetchCountryInSignup = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_COUNTRY_IN_SIGNUP_INIT_ACTION,
    });
  };
};

const fetchStateInSignup = (id) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_STATE_IN_SIGNUP_INIT_ACTION,
      param: id,
    });
  };
};

const fetchDistrictInSignup = (id) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_DISTRICT_IN_SIGNUP_INIT_ACTION,
      param: id,
    });
  };
};

const fetchBankInSignup = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_BANK_IN_SIGNUP_INIT_ACTION,
    });
  };
};

export default {
  addSignupData,
  fetchCountryInSignup,
  fetchStateInSignup,
  fetchDistrictInSignup,
  fetchBankInSignup,
};
