export const FETCH_ZONE_INIT_ACTION = "FETCH_ZONE_INIT_ACTION";
export const FETCH_ZONE_SUCCESS_ACTION =
  FETCH_ZONE_INIT_ACTION + "_SUCCESS";
export const FETCH_ZONE_FAIL_ACTION = FETCH_ZONE_INIT_ACTION + "_ERROR";


export const ADD_ZONE_INIT_ACTION = "ADD_ZONE_INIT_ACTION";
export const ADD_ZONE_SUCCESS_ACTION =
  ADD_ZONE_INIT_ACTION + "_SUCCESS";
export const ADD_ZONE_FAIL_ACTION = ADD_ZONE_INIT_ACTION + "_ERROR";


export const EDIT_ZONE_INIT_ACTION = "EDIT_ZONE_INIT_ACTION";
export const EDIT_ZONE_SUCCESS_ACTION =
  EDIT_ZONE_INIT_ACTION + "_SUCCESS";
export const EDIT_ZONE_FAIL_ACTION = EDIT_ZONE_INIT_ACTION + "_ERROR";



export const FETCH_DISTRICT_INIT_ACTION =
  "FETCH_DISTRICT_INIT_ACTION";
export const FETCH_DISTRICT_SUCCESS_ACTION =
  FETCH_DISTRICT_INIT_ACTION + "_SUCCESS";
export const FETCH_DISTRICT_FAIL_ACTION =
  FETCH_DISTRICT_INIT_ACTION + "_ERROR";


export const DELETE_ZONE_INIT_ACTION = "DELETE_ZONE_INIT_ACTION";
export const DELETE_ZONE_SUCCESS_ACTION =
  DELETE_ZONE_INIT_ACTION + "_SUCCESS";
export const DELETE_ZONE_FAIL_ACTION =
  DELETE_ZONE_INIT_ACTION + "_ERROR";

export const FETCH_ZONE_DETAIL_INIT_ACTION = "FETCH_ZONE_DETAIL_INIT_ACTION";
export const FETCH_ZONE_DETAIL_SUCCESS_ACTION =
  FETCH_ZONE_DETAIL_INIT_ACTION + "_SUCCESS";
export const FETCH_ZONE_DETAIL_FAIL_ACTION = FETCH_ZONE_DETAIL_INIT_ACTION + "_ERROR";


// export const FILTER_SEARCH_ZONE_INIT_ACTION = "FILTER_SEARCH_ZONE_INIT_ACTION";
// export const FILTER_SEARCH_ZONE_SUCCESS_ACTION =
//   FILTER_SEARCH_ZONE_INIT_ACTION + "_SUCCESS";
// export const FILTER_SEARCH_ZONE_FAIL_ACTION = FILTER_SEARCH_ZONE_INIT_ACTION + "_ERROR";




export const FETCH_ZONE_CLEAR_ACTION = "FETCH_ZONE_CLEAR_ACTION";



export function fetchZone(value) {
  return {
    type: FETCH_ZONE_INIT_ACTION,
    value:value
  };
}


export function fetchDistrict(value) {
  return {
    type: FETCH_DISTRICT_INIT_ACTION,
    value: value,
  };
}



export function addZone(value) {
  return {
    type: ADD_ZONE_INIT_ACTION,
    value: value
  };
}


export function editZone(value) {
  return {
    type: EDIT_ZONE_INIT_ACTION,
    value: value
  };
}



export function deleteZone(value) {
  return {
    type: DELETE_ZONE_INIT_ACTION,
    value: value,
  };
}


// export function zonePageSelected(value) {
//   return {
//     type: FETCH_ZONE_INIT_ACTION,
//     value: value
//   }
// }


export function clearZone() {
  return {
    type: FETCH_ZONE_CLEAR_ACTION,
  };
}


export function fetchZoneDetail(value) {
  return {
    type: FETCH_ZONE_DETAIL_INIT_ACTION,
    value: value
  };
}


// export function filterSearchZone(value) {
//   return {
//     type: FILTER_SEARCH_ZONE_INIT_ACTION,
//     value: value
//   };
// }



