export const FETCH_AREA_INIT_ACTION = "FETCH_AREA_INIT_ACTION";
export const FETCH_AREA_SUCCESS_ACTION = FETCH_AREA_INIT_ACTION + "_SUCCESS";
export const FETCH_AREA_FAIL_ACTION = FETCH_AREA_INIT_ACTION + "_ERROR";

export const ADD_AREA_INIT_ACTION = "ADD_AREA_INIT_ACTION";
export const ADD_AREA_SUCCESS_ACTION = ADD_AREA_INIT_ACTION + "_SUCCESS";
export const ADD_AREA_FAIL_ACTION = ADD_AREA_INIT_ACTION + "_ERROR";

export const EDIT_AREA_INIT_ACTION = "EDIT_AREA_INIT_ACTION";
export const EDIT_AREA_SUCCESS_ACTION = EDIT_AREA_INIT_ACTION + "_SUCCESS";
export const EDIT_AREA_FAIL_ACTION = EDIT_AREA_INIT_ACTION + "_ERROR";

export const FETCH_ZONE_DROPDOWN_INIT_ACTION =
  "FETCH_ZONE_DROPDOWN_INIT_ACTION";
export const FETCH_ZONE_DROPDOWN_SUCCESS_ACTION =
  FETCH_ZONE_DROPDOWN_INIT_ACTION + "_SUCCESS";
export const FETCH_ZONE_DROPDOWN_FAIL_ACTION =
  FETCH_ZONE_DROPDOWN_INIT_ACTION + "_ERROR";

export const FETCH_DISTRICT_DROPDOWN_INIT_ACTION =
  "FETCH_DISTRICT_DROPDOWN_INIT_ACTION";
export const FETCH_DISTRICT_DROPDOWN_SUCCESS_ACTION =
  FETCH_DISTRICT_DROPDOWN_INIT_ACTION + "_SUCCESS";
export const FETCH_DISTRICT_DROPDOWN_FAIL_ACTION =
  FETCH_DISTRICT_DROPDOWN_INIT_ACTION + "_ERROR";

export const FETCH_PINCODE_INIT_ACTION = "FETCH_PINCODE_INIT_ACTION";
export const FETCH_PINCODE_SUCCESS_ACTION =
  FETCH_PINCODE_INIT_ACTION + "_SUCCESS";
export const FETCH_PINCODE_FAIL_ACTION = FETCH_PINCODE_INIT_ACTION + "_ERROR";

export const DELETE_AREA_INIT_ACTION = "DELETE_AREA_INIT_ACTION";
export const DELETE_AREA_SUCCESS_ACTION = DELETE_AREA_INIT_ACTION + "_SUCCESS";
export const DELETE_AREA_FAIL_ACTION = DELETE_AREA_INIT_ACTION + "_ERROR";

export const FETCH_AREA_DETAIL_INIT_ACTION = "FETCH_AREA_DETAIL_INIT_ACTION";
export const FETCH_AREA_DETAIL_SUCCESS_ACTION =
  FETCH_AREA_DETAIL_INIT_ACTION + "_SUCCESS";
export const FETCH_AREA_DETAIL_FAIL_ACTION =
  FETCH_AREA_DETAIL_INIT_ACTION + "_ERROR";

// export const FILTER_SEARCH_AREA_INIT_ACTION = "FILTER_SEARCH_AREA_INIT_ACTION";
// export const FILTER_SEARCH_AREA_SUCCESS_ACTION =
//   FILTER_SEARCH_AREA_INIT_ACTION + "_SUCCESS";
// export const FILTER_SEARCH_AREA_FAIL_ACTION = FILTER_SEARCH_AREA_INIT_ACTION + "_ERROR";

export const FETCH_AREA_CLEAR_ACTION = "FETCH_AREA_CLEAR_ACTION";

export const CLEAR_ZONE_DROPDOWN_INIT_ACTION =
  "CLEAR_ZONE_DROPDOWN_INIT_ACTION";

export const FETCH_AREA_LOCALITY_INIT_ACTION =
  "FETCH_AREA_LOCALITY_INIT_ACTION";
export const FETCH_AREA_LOCALITY_SUCCESS_ACTION =
  FETCH_AREA_LOCALITY_INIT_ACTION + "_SUCCESS";
export const FETCH_AREA_LOCALITY_FAIL_ACTION =
  FETCH_AREA_LOCALITY_INIT_ACTION + "_ERROR";

export function fetchArea(value) {
  return {
    type: FETCH_AREA_INIT_ACTION,
    value: value,
  };
}

export function fetchZoneDropdown(value) {
  return {
    type: FETCH_ZONE_DROPDOWN_INIT_ACTION,
    value: value,
  };
}

export function fetchPincode(value) {
  return {
    type: FETCH_PINCODE_INIT_ACTION,
    value: value,
  };
}

export function addArea(value) {
  return {
    type: ADD_AREA_INIT_ACTION,
    value: value,
  };
}

export function editArea(value) {
  return {
    type: EDIT_AREA_INIT_ACTION,
    value: value,
  };
}

export function deleteArea(value) {
  return {
    type: DELETE_AREA_INIT_ACTION,
    value: value,
  };
}

// export function areaPageSelected(value) {
//   return {
//     type: FETCH_AREA_INIT_ACTION,
//     value: value
//   }
// }

export function clearArea() {
  return {
    type: FETCH_AREA_CLEAR_ACTION,
  };
}

export function fetchAreaDetail(value) {
  return {
    type: FETCH_AREA_DETAIL_INIT_ACTION,
    value: value,
  };
}

export function fetchDistrictDropdown(value) {
  return {
    type: FETCH_DISTRICT_DROPDOWN_INIT_ACTION,
    value: value,
  };
}

export function clearZoneDropdown() {
  return {
    type: CLEAR_ZONE_DROPDOWN_INIT_ACTION,
  };
}

// export function filterSearchArea(value) {
//   return {
//     type: FILTER_SEARCH_AREA_INIT_ACTION,
//     value: value
//   };
// }

export function fetchLocality(value) {
  return {
    type: FETCH_AREA_LOCALITY_INIT_ACTION,
    value: value,
  };
}
