import React, { useState, useEffect } from "react";
import { Card, Row, Container, Col, Image } from "react-bootstrap";
import Stepper from "react-stepper-horizontal";
import BasicDetails from "./Components/BasicDetails";
import OrganizationDetails from "./Components/OrganizationDetails";
import BankDetail from "./Components/BankDetails";
import BusinessType from "./Components/BusinessType";
import "./style.css";

const Index = ({ ...props }) => {
  const [steps, setSteps] = useState([
    { title: "Business Type" },
    { title: "Basic Details" },
    { title: "Organization Details" },
    { title: "Bank Details" },
  ]);
  const [currentstep, setCurrentStep] = useState(0);
  const [formParams, setFormParams] = useState({});
  const [width, setWindowWidth] = useState(0);

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  const clickHandler = (tab, value) => {
    setFormParams({ ...formParams, ...value });

    setCurrentStep(tab);
  };

  const submitForm = (values) => {
    let params = { ...formParams, ...values };

    props.addSignupData(params);
  };

  const renderSection = () => {
    // eslint-disable-next-line default-case
    switch (currentstep) {
      case 0:
        return <BusinessType clickHandler={clickHandler} item={formParams} />;
      case 1:
        return <BasicDetails clickHandler={clickHandler} item={formParams} />;
      case 2:
        return (
          <OrganizationDetails
            clickHandler={clickHandler}
            fetchCountryInSignup={props.fetchCountryInSignup}
            countryList={props.countryList}
            fetchStateInSignup={props.fetchStateInSignup}
            stateList={props.stateList}
            item={formParams}
            fetchDistrictInSignup={props.fetchDistrictInSignup}
            districtList={props.districtList}
          />
        );
      case 3:
        return (
          <BankDetail
            clickHandler={clickHandler}
            fetchBankInSignup={props.fetchBankInSignup}
            bankList={props.bankList}
            item={formParams}
            submitForm={submitForm}
          />
        );
    }
  };

  return (
    <div>
      <div id="businessdiv" className="updateprofilediv">
        <Card className="updateprofilecard">
          <Card.Body>
            <Row>
              <Col xs={12} md={4} sm={12} lg={4}>
                <div className="logoImg">
                  <img
                    alt=""
                    className=" "
                    style={{
                      marginBottom: "25%",
                    }}
                    src={require("../../assets/img/Logo-full-bg.png")}
                  />
                </div>
                <div>
                  <Image
                    alt="loginimage"
                    className="signup-image "
                    style={{ height: "100%", width: "100%" }}
                    src={require("../../assets/img/LoginImage.png")}
                  />
                </div>
              </Col>
              <Col xs={12} md={8} sm={12} lg={8}>
                <Container className="main-container">
                  {/* <Row> */}{" "}
                  <div className="step-cont">
                    <Stepper
                      className="step-head"
                      steps={steps}
                      activeStep={currentstep}
                      activeColor="#023E8A"
                      completeColor="rgb(66, 186, 150)"
                      completeTitleColor="rgb(66, 186, 150)"
                      completeBarColor="rgb(66, 186, 150)"
                      titleFontSize={width < 470 ? 14 : 22}
                      activeTitleColor="#023E8A"
                    />
                  </div>
                  {/* </Row> */}
                  <hr />
                  <div>{renderSection()}</div>
                </Container>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};
export default Index;
