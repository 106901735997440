export const FETCH_CREDIT_NOTE_CONFIGURATION_INIT_ACTION =
  "FETCH_CREDIT_NOTE_CONFIGURATION_INIT_ACTION";
export const FETCH_CREDIT_NOTE_CONFIGURATION_SUCCESS_ACTION =
  FETCH_CREDIT_NOTE_CONFIGURATION_INIT_ACTION + "_SUCCESS";
export const FETCH_CREDIT_NOTE_CONFIGURATION_FAIL_ACTION =
  FETCH_CREDIT_NOTE_CONFIGURATION_INIT_ACTION + "_ERROR";

export const EDIT_CREDIT_NOTE_CONFIGURATION_INIT_ACTION =
  "EDIT_CREDIT_NOTE_CONFIGURATION_INIT_ACTION";
export const EDIT_CREDIT_NOTE_CONFIGURATION_SUCCESS_ACTION =
  EDIT_CREDIT_NOTE_CONFIGURATION_INIT_ACTION + "_SUCCESS";
export const EDIT_CREDIT_NOTE_CONFIGURATION_FAIL_ACTION =
  EDIT_CREDIT_NOTE_CONFIGURATION_INIT_ACTION + "_ERROR";
