
import _ from "lodash";

const sortData = (data = []) => {
    let tempData = _.orderBy(data, [(item) => item.name.toUpperCase()]);
    let result = tempData.map((dat) => {
      dat.name = dat.name.charAt(0).toUpperCase() + dat.name.slice(1);
      return dat;
    });
    return result;
  };

export {sortData}   