import * as actionTypes from "./constants";

const fetchnotifications = (params) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_NOTIFICATIONS_INIT_ACTION,
      params: params,
    });
  };
};

const updateNotification = (params) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_NOTIFICATIONS_INIT_ACTION,
      params: params,
    });
  };
};

export function unreadnotifications(value) {
  return {
    type: actionTypes.FETCH_UNREADNOTIFICATIONS_INIT_ACTION,
    value: value,
  };
}

export default {
  fetchnotifications,
  updateNotification,
};
