import * as actionTypes from "./constants";

const initialState = {
  processing: false,
  error: false,
};

export default function rewardsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_REWARDS_USERS_SUCCESS_ACTION:
      return {
        ...state,
        rwrdUserList: action.payload.rows,
      };
    case actionTypes.FETCH_REWARDS_SUCCESS_ACTION:
      return {
        ...state,
        rewardList: action.payload.rows,
        rewardCount: action.payload.count,
      };

    default:
      return {
        ...state,
      };
  }
}
