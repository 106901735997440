import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { connectRouter } from "connected-react-router";
import storage from "redux-persist/es/storage";
// import StoreReducer from '../containers/StoreContainer/reducer'
import RegionReducer from "../containers/RegionContainer/reducer";
import MyProfileReducer from "../containers/MyProfileContainer/reducer";
import ZoneReducer from "../containers/ZoneContainer/reducer";
import AreaReducer from "../containers/AreaContainer/reducer";
import userReducer from "../containers/UsersContainer/reducer";
import EditProfileReducer from "../containers/EditProfileContainer/reducer";
import productReducer from "../containers/ProductContainer/reducer";
// import UserProfileReducer from "../containers/UserProfileContainer/reducer";
import EditReducer from "../containers/EditUserProfileContainer/reducer";
import DashboardReducer from "../containers/DefaultLayout/reducer";
import CustomersReducer from "../containers/CustomerContainer/reducer";
import LeadsReducer from "../containers/LeadsContainer/reducer";
import OrderReducer from "../containers/OrderContainer/reducer";
import paymentsReducer from "../containers/PaymentsContainer/reducer";
import invoicesReducer from "../containers/InvoicesContainer/reducer";
import quotationsReducer from "../containers/QuotationsContainer/reducer";
import earningsReducer from "../containers/EarningsContainer/reducer";
import WdRequestReducer from "../containers/WithdrawalRequestContainer/reducer";
import incentiveBucketReducer from "../containers/IncentiveBucketContainer/reducer";
import agencyReducer from "../containers/AgencyContainer/reducer";
import ForgotPasswordReducer from "../containers/ForgotPasswordContainer/reducer";

import SupportReducer from "../containers/SupportContainer/reducer";
import PaymentInfoReducer from "../containers/PaymentInfoContainer/reducer";
import productInventoryReducer from "../containers/ProductInventoryContainer/reducer";

import WithdrawalLimitReducer from "../containers/WithdrawalLimitContainer/reducer";

import ImportProdReducer from "../containers/ImportProdContainer/reducer";
import FollowupReducer from "../containers/FollowupContainer/reducer";
import googleFormReducer from "../containers/GformContainer/reducer";
import socialLeadReducer from "../containers/SocialLeadContainer/reducer";
import facebookReducer from "../containers/FacebookContainer/reducer";
import IndiaMartReducer from "../containers/IndiaMartContainer/reducer";
import TradeIndiaReducer from "../containers/TradeIndiaContainer/reducer";
import rewardsReducer from "../containers/RewardContainer/reducer";
import signUpReducer from "../containers/SignupContainer/reducer";
import expenseReducer from "../containers/DistributorContainer/ExpenseConatiner/reducer";
import usersReducer from "../containers/DistributorContainer/UserContainer/reducer";
import DisOrderReducer from "../containers/DistributorContainer/OrderContainer/reducer";
import invoiceConfigurationReducer from "../containers/BrandingContainer/InvoiceConfigurationContainer/reducer";
import quoteConfigurationReducer from "../containers/BrandingContainer/QuoteConfigurationContainer/reducer";
import notificationReducer from "../containers/NotificationContainer/reducer";
import ImportDisProdReducer from "../containers/DistributorContainer/ImportDistPrdctConatainer/reducer";
import salesRetunReducer from "../containers/SalesReturnContainer/reducer";
import creditNoteConfigurationReducer from "../containers/BrandingContainer/CreditNoteConfigContainer/reducer";
import debtorsReportReducer from "../containers/ReportsContainer/DeptorsReportContainer/reducer";
import creditnoteReportReducer from "../containers/ReportsContainer/CreditNoteContainer/reducer";
import customerRouteReducer from "../containers/DistributorContainer/CustomerRouteContainer/reducer";
import stockMovementReducer from "../containers/DistributorContainer/StockMovementContainer/reducer";
import agentReportReducer from "../containers/ReportsContainer/UserReportContainer/reducer";
import productReplaceReducer from "../containers/ProductReplaceContainer/reducer";

import store, { history } from "../boot/configureStore";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    RegionReducer,
    MyProfileReducer,
    ZoneReducer,
    AreaReducer,
    userReducer,
    EditProfileReducer,
    productReducer,
    EditReducer,
    // UserProfileReducer,
    DashboardReducer,
    CustomersReducer,
    LeadsReducer,
    OrderReducer,
    paymentsReducer,
    invoicesReducer,
    quotationsReducer,
    earningsReducer,
    WdRequestReducer,
    incentiveBucketReducer,
    agencyReducer,
    ForgotPasswordReducer,
    SupportReducer,
    PaymentInfoReducer,
    productInventoryReducer,
    WithdrawalLimitReducer,
    ImportProdReducer,
    FollowupReducer,
    googleFormReducer,
    socialLeadReducer,
    facebookReducer,
    IndiaMartReducer,
    TradeIndiaReducer,
    rewardsReducer,
    signUpReducer,
    expenseReducer,
    usersReducer,
    DisOrderReducer,
    invoiceConfigurationReducer,
    quoteConfigurationReducer,
    notificationReducer,
    ImportDisProdReducer,
    salesRetunReducer,
    creditNoteConfigurationReducer,
    debtorsReportReducer,
    creditnoteReportReducer,
    customerRouteReducer,
    stockMovementReducer,
    agentReportReducer,
    productReplaceReducer,
  });

export default createRootReducer;
