import * as actionTypes from "./constants";

const initialState = {
  processing: false,
  error: false,
  stockList: [],
  stockCount: 0,
  routeList: [],
  stockByIdList: {},
  loading: true,
  stockByPersonList: [],
  availablePrdctQty: {},
  availableVariantQty: {},
  variantOutStandingQty: {},
};

export default function stockMovementReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_STOCK_MOVEMENT_SUCCESS_ACTION:
      return {
        ...state,
        stockList: action.payload.rows,
        stockCount: action.payload.count,
      };
    case actionTypes.FETCH_ROUTE_BY_USERID_SUCCESS_ACTION:
      return {
        ...state,
        routeList: action.payload,
      };
    case actionTypes.FETCH_STOCK_MOVEMENT_BY_ID_INIT_ACTION:
      return {
        ...state,
        loading: true,
        stockByIdList: {},
      };
    case actionTypes.FETCH_STOCK_MOVEMENT_BY_ID_SUCCESS_ACTION:
      return {
        ...state,
        loading: false,
        stockByIdList: action.payload,
      };
    case actionTypes.FETCH_STOCK_MOVEMENT_BY_PESRON_SUCCESS_ACTION:
      return {
        ...state,
        loading: false,
        stockByPersonList: action.payload.rows,
      };
    case actionTypes.FETCH_AVAILABLE_PRODUCT_QTY_SUCCESS_ACTION:
      return {
        ...state,
        loading: false,
        availablePrdctQty: action.payload,
      };
    case actionTypes.FETCH_OUTSTANDING_QTY_SUCCESS_ACTION:
      return {
        ...state,
        loading: false,
        outStandingQty: action.payload,
      };
    case actionTypes.FETCH_AVAILABLE_VARIANT_QTY_SUCCESS_ACTION:
      return {
        ...state,
        loading: false,
        availableVariantQty: action.payload,
      };
    case actionTypes.FETCH_VARIANT_OUTSTANDING_QTY_SUCCESS_ACTION:
      return {
        ...state,
        loading: false,
        variantOutStandingQty: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
