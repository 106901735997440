import { put, takeEvery } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
 FETCH_PAYMENTINFO_INIT_ACTION,
 FETCH_PAYMENTINFO_SUCCESS_ACTION,
 FETCH_PAYMENTINFO_FAIL_ACTION
} from "./action";

import { appConfig } from "../../config";




function* fetchPaymentInfo(value) {
  
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licenseeId = userDetails.licenseeId

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/licenseeProf/show/${licenseeId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch { }
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_PAYMENTINFO_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_PAYMENTINFO_FAIL_ACTION, error: err });
    } else {
    }
  }
}





export function* PaymentInfoActionWatcher() {
  yield takeEvery(FETCH_PAYMENTINFO_INIT_ACTION, fetchPaymentInfo);
}
