import {
    FETCH_EDIT_SUCCESS_ACTION,
    FETCH_COUNTRY_DROPDOWN_IN_LICENSE_SUCCESS_ACTION,
  FETCH_DISTRICT_DROPDOWN_IN_LICENSE_SUCCESS_ACTION,
  FETCH_LICENSE_SUCCESS_ACTION,
  FETCH_STATE_DROPDOWN_IN_LICENSE_SUCCESS_ACTION,

   
  } from "./action";
  
  const initialState = {
    processing: false,
    error: false,
  };
  
  export default function EditReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_EDIT_SUCCESS_ACTION:
        return {
          ...state,
          profileDtls: action.payload,
        };
      
        case FETCH_COUNTRY_DROPDOWN_IN_LICENSE_SUCCESS_ACTION:
       

          return {
            ...state,
            cntrycrncyList: action.payload.rows,
            cntrycrncyListCount: action.payload.count,
          };
    
        case FETCH_STATE_DROPDOWN_IN_LICENSE_SUCCESS_ACTION:
       

          return {
            ...state,
            statesList: action.payload.rows,
          };
    
        
        case FETCH_DISTRICT_DROPDOWN_IN_LICENSE_SUCCESS_ACTION:
          return {
            ...state,
            districtById: action.payload.rows,
          };
  
      default:
        return {
          ...state,
        };
    }
  }
  