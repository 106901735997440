import {
  FETCH_DIS_SUBCATEGORYLIST_SUCCESS_ACTION,
  APPROVE_DIS_PRODUCT_SUCCESS_ACTION,
} from "./action";
import { sortData } from "../../../screens/Common/sortData";

const initialState = {
  processing: true,
  error: false,
  loading: true,
};

export default function ImportDisProdReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    case FETCH_DIS_SUBCATEGORYLIST_SUCCESS_ACTION:
      return {
        ...state,
        subcategorysList: sortData(action.payload.rows),
      };

    default:
      return {
        ...state,
      };
  }
}
