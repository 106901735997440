import * as actionTypes from "./constants";
import { sortData } from "../../../screens/Common/sortData";

const initialState = {
  processing: false,
  error: false,
  invoiceConfigurationList: [],
  invoiceConfigurationCount: 0,
  invoiceConfigurationTypeList: [],
  bankList: [],
};

export default function invoiceConfigurationReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_INVOICE_CONFIGURATION_SUCCESS_ACTION:
      return {
        ...state,
        invoiceConfigurationList: action.payload.rows,
        invoiceConfigurationCount: action.payload.count,
      };
    case actionTypes.FETCH_BANK_IN_INVOICE_CONFIGURATION_SUCCESS_ACTION:
      return {
        ...state,
        bankList: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
