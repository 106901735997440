export const FETCH_PRODUCT_REPLACE_INIT_ACTION =
  "FETCH_PRODUCT_REPLACE_INIT_ACTION";
export const FETCH_PRODUCT_REPLACE_SUCCESS_ACTION =
  FETCH_PRODUCT_REPLACE_INIT_ACTION + "_SUCCESS";
export const FETCH_PRODUCT_REPLACE_FAIL_ACTION =
  FETCH_PRODUCT_REPLACE_INIT_ACTION + "_ERROR";

export const FETCH_PRODUCT_REPLACE_BY_ID_INIT_ACTION =
  "FETCH_PRODUCT_REPLACE_BY_ID_INIT_ACTION";
export const FETCH_PRODUCT_REPLACE_BY_ID_SUCCESS_ACTION =
  FETCH_PRODUCT_REPLACE_BY_ID_INIT_ACTION + "_SUCCESS";
export const FETCH_PRODUCT_REPLACE_BY_ID_FAIL_ACTION =
  FETCH_PRODUCT_REPLACE_BY_ID_INIT_ACTION + "_ERROR";

export const FETCH_REPLACE_BY_ID_INIT_ACTION =
  "FETCH_REPLACE_BY_ID_INIT_ACTION";
export const FETCH_REPLACE_BY_ID_SUCCESS_ACTION =
  FETCH_REPLACE_BY_ID_INIT_ACTION + "_SUCCESS";
export const FETCH_REPLACE_BY_ID_FAIL_ACTION =
  FETCH_REPLACE_BY_ID_INIT_ACTION + "_ERROR";

// export const APPROVE_PRODUCT_REPLACE_INIT_ACTION =
//   "APPROVE_PRODUCT_REPLACE_INIT_ACTION";
// export const APPROVE_PRODUCT_REPLACE_SUCCESS_ACTION =
//   APPROVE_PRODUCT_REPLACE_INIT_ACTION + "_SUCCESS";
// export const APPROVE_PRODUCT_REPLACE_FAIL_ACTION =
//   APPROVE_PRODUCT_REPLACE_INIT_ACTION + "_ERROR";
