import {
  FETCH_ORDERS_SUCCESS_ACTION,
  FETCH_ORDERBYID_INIT_ACTION,
  FETCH_ORDERBYID_SUCCESS_ACTION,
  FETCH_PAYMENTMODE_SUCCESS_ACTION,
  CHANGE_ORDERSTATUS_SUCCESS_ACTION,
  EDIT_ORDER_SUCCESS_ACTION,
  PAYAMOUNT_ORDER_SUCCESS_ACTION,
  FETCH_TOTAL_AMOUNT_BY_ORDER_LICENSEE_SUCCESS_ACTION,
  // FILTER_SEARCH_ORDER_SUCCESS_ACTION

  // LOADING_INIT_ACTION
} from "./action";
import { sortData } from "../../screens/Common/sortData";

const initialState = {
  processing: true,
  error: false,
  loading: true,
};

export default function CustomersReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    case FETCH_ORDERS_SUCCESS_ACTION:
      return {
        ...state,
        orderList: action.payload.rows,
        orderDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };
    case FETCH_ORDERBYID_INIT_ACTION:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ORDERBYID_SUCCESS_ACTION:
      return {
        ...state,
        orderDtls: action.payload,
        loading: false,
      };
    case FETCH_PAYMENTMODE_SUCCESS_ACTION:
      return {
        ...state,
        paymntModeList: sortData(action.payload.rows),
      };

    case FETCH_TOTAL_AMOUNT_BY_ORDER_LICENSEE_SUCCESS_ACTION:
      return {
        ...state,
        totalAmountByOrder: action.payload.total,
      };

    case CHANGE_ORDERSTATUS_SUCCESS_ACTION:
      return {
        ...state,
        orderList: state.orderList.map((order) =>
          order.id === action.payload.id
            ? { ...order, status: action.payload.status }
            : order
        ),
      };
    case EDIT_ORDER_SUCCESS_ACTION:
      return {
        ...state,
        orderList: state.orderList.map((order) =>
          order.id === action.payload.id ? action.payload : order
        ),
      };
    case PAYAMOUNT_ORDER_SUCCESS_ACTION:
      return {
        ...state,
        orderList: state.orderList.map((order) =>
          order.id === action.payload.id ? action.payload : order
        ),
      };

    // case FILTER_SEARCH_ORDER_SUCCESS_ACTION:
    //     return {
    //         ...state,
    //         orderList: action.payload.rows,
    //         orderDtlsCount: action.payload.count,
    //         processing: false,
    //         error: false,
    //     };
    // case LOADING_INIT_ACTION:

    //     return {
    //         ...state,

    //         loading: false
    //     };

    default:
      return {
        ...state,
      };
  }
}
