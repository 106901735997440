import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as router from "react-router-dom";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { logOut } from "../../containers/LoginContainer/actions";
import { pusher } from "../../service/pusher";
// import { fetchFollowup as fetchfollowup } from "../FollowupContainer/action";
import { unreadnotifications } from "../../containers/NotificationContainer/action";
import { fetchCustomers as fetchcustomers } from "../CustomerContainer/action";

import { fetchLeads as fetchleads } from "../LeadsContainer/action";
import { fetchPayments as fetchpayments } from "../PaymentsContainer/action";
import { fetchWdRequest as fetchwdrequest } from "../WithdrawalRequestContainer/action";
import { fetchOrders as fetchorders } from "../OrderContainer/action";
import { fetchSocialLead as fetchscoiallead } from "../SocialLeadContainer/action";
import { fetchEarnings as fetchearnings } from "../EarningsContainer/action";
import { fetchDisOrder as fetchdisorder } from "../DistributorContainer/OrderContainer/action";

import expenseActions from "../DistributorContainer/ExpenseConatiner/action";

import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppBreadcrumb2 as AppBreadcrumb,
  AppSidebarNav2 as AppSidebarNav,
} from "@coreui/react";
// sidebar nav config
// import navigation from '../../_nav';
import {
  navLicensee,
  navLicenseeAccountant,
  navRegionalMngr,
  navZonalMngr,
  navAreaMngr,
  navSalesExctv,
  navDistributor,
  navDistributorNW,
  navDistrbtrAgent,
  navDistrbtrSalesExctv,
  navDistrbtrLiceAccount,
  navDistrbtrNwLiceAccount,
  navDistrbtrRegionalMngr,
  navDistrbtrZonalMngr,
  navDistrbtrAreaMngr,
} from "../../_nav";
// routes config
import routes from "../../routes";

const { fetchExpenses } = expenseActions;
const fetchexpenses = fetchExpenses;
const DefaultAside = React.lazy(() => import("./DefaultAside"));
const DefaultFooter = React.lazy(() => import("./DefaultFooter"));
const DefaultHeader = React.lazy(() => import("./DefaultHeader"));

class DefaultLayout extends Component {
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  signOut(e, pusher) {
    let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
    // pusher.unsubscribe(userDetails.id);
    e.preventDefault();
    this.props.logoutaction();
    this.props.history.push("/login");
  }

  componentDidMount = () => {
    let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
    let licType = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

    const props = this.props;
    if (userDetails) {
      const pusherchannel = pusher.subscribe(userDetails && userDetails.id);

      pusherchannel.bind("userEarningCreation", function (data) {
        props.fetchearnings({ page: 1 });
        props.unreadnotifications({ fromPusher: true });
      });

      pusherchannel.bind("leadCreation", function (data) {
        props.fetchleads({ page: 1 });
        props.unreadnotifications({ fromPusher: true });
      });

      pusherchannel.bind("customerCreation", function (data) {
        props.fetchcustomers({ page: 1 });
        props.unreadnotifications({ fromPusher: true });
      });
      pusherchannel.bind("paymentCreation", function (data) {
        props.fetchpayments({ page: 1 });
        props.unreadnotifications({ fromPusher: true });
      });
      pusherchannel.bind("wdrlReqCreation", function (data) {
        props.fetchwdrequest({ page: 1 });
        props.unreadnotifications({ fromPusher: true });
      });
      pusherchannel.bind("orderCreation", function (data) {
        licType && licType.type === "distributor"
          ? props.fetchdisorder({ page: 1 })
          : props.fetchorders({ page: 1 });
        props.unreadnotifications({ fromPusher: true });
      });
      pusherchannel.bind("socialLeadCreation", function (data) {
        props.fetchscoiallead({ page: 1 });
        props.unreadnotifications({ fromPusher: true });
      });
      pusherchannel.bind("expenseCreation", function (data) {
        props.fetchexpenses({ page: 1 });
        props.unreadnotifications({ fromPusher: true });
      });
    }
  };

  componentWillUnmount() {
    let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
    if (userDetails) {
      pusher.unsubscribe(userDetails.id);
    }
  }

  render() {
    let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
    const role = localStorage.getItem("sStraitUserRole");

    let sideMenuList = [
      { role: "licensee", menu: navLicensee },
      { role: "licenseeAccountant", menu: navLicenseeAccountant },
      {
        role: "regionalManager",
        menu:
          licData != null &&
          licData.type === "distributor" &&
          licData.networkHierarchy
            ? navDistrbtrRegionalMngr
            : navRegionalMngr,
      },
      {
        role: "zonalManager",
        menu:
          licData != null &&
          licData.type === "distributor" &&
          licData.networkHierarchy
            ? navDistrbtrZonalMngr
            : navZonalMngr,
      },
      {
        role: "areaManager",
        menu:
          licData != null &&
          licData.type === "distributor" &&
          licData.networkHierarchy
            ? navDistrbtrAreaMngr
            : navAreaMngr,
      },
      {
        role: "salesExcecutive",
        menu:
          licData != null &&
          licData.type === "distributor" &&
          licData.networkHierarchy
            ? navDistrbtrSalesExctv
            : navSalesExctv,
      },
      {
        role: "distributor",
        menu:
          licData != null &&
          licData.networkHierarchy &&
          licData.networkHierarchy
            ? navDistributorNW
            : navDistributor,
      },
      { role: "agent", menu: navDistrbtrAgent },
    ];

    let sideMenu = sideMenuList.find((e) => e.role === role);

    // if (sideMenu && sideMenu.role === "distributor") {
    //   if (licData.networkHierarchy) {
    //     sideMenu = { role: "distributor", menu: navDistributorNW };
    //   }
    // }

    // if (sideMenu && sideMenu.role === "salesExcecutive") {
    //   if (licData.type === "distributor" && licData.networkHierarchy) {
    //     sideMenu = { role: "salesExcecutive", menu: navDistrbtrSalesExctv };
    //   }
    // }

    if (sideMenu && sideMenu.role === "licenseeAccountant") {
      if (
        licData &&
        licData.type === "distributor" &&
        licData.networkHierarchy === true
      ) {
        sideMenu = {
          role: "licenseeAccountant",
          menu: navDistrbtrNwLiceAccount,
        };
      } else {
        sideMenu = {
          role: "licenseeAccountant",
          menu: navDistrbtrLiceAccount,
        };
      }
    }

    // if (sideMenu && sideMenu.role === "licenseeAccountant") {
    //   if (
    //     licData &&
    //     licData.type === "distributor" &&
    //     licData.networkHierarchy === false
    //   ) {
    //     sideMenu = {
    //       role: "licenseeAccountant",
    //       menu: navDistrbtrLiceAccount,
    //     };
    //   }
    // }

    return (
      <div className="app">
        <AppHeader fixed>
          <Suspense fallback={this.loading()}>
            <DefaultHeader onLogout={(e) => this.signOut(e)} />
          </Suspense>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
              {role !== null || licData != null ? (
                <AppSidebarNav
                  navConfig={sideMenu && sideMenu.menu}
                  // navConfig={navSalesExctv}
                  {...this.props}
                  router={router}
                />
              ) : (
                <Redirect from="/" to="/login" />
              )}
            </Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes} router={router} />
            <Container fluid>
              <Suspense fallback={this.loading()}>
                <Switch>
                  {routes.map((route, idx) => {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        // render={props => (
                        //   <route.component {...props} />
                        // )}
                        render={(props) =>
                          localStorage.getItem("sStraitToken") != null ? (
                            <route.component {...props} />
                          ) : (
                            <Redirect from="/" to="/login" />
                          )
                        }
                      />
                    ) : null;
                  })}
                  <Redirect from="/" to="/dashboard" />
                </Switch>
              </Suspense>
            </Container>
          </main>
          <AppAside fixed>
            <Suspense fallback={this.loading()}>
              <DefaultAside />
            </Suspense>
          </AppAside>
        </div>
        <AppFooter>
          <Suspense fallback={this.loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter>
      </div>
    );
  }
}

function bindAction(dispatch) {
  return {
    logoutaction: () => {
      dispatch(logOut());
    },

    fetchcustomers: (params) => {
      dispatch(fetchcustomers(params));
    },

    unreadnotifications: () => {
      dispatch(unreadnotifications());
    },

    // fetchfollowup: (params) => {
    //   dispatch(fetchfollowup(params));
    // },

    fetchleads: (params) => {
      dispatch(fetchleads(params));
    },
    fetchpayments: (params) => {
      dispatch(fetchpayments(params));
    },
    fetchwdrequest: (params) => {
      dispatch(fetchwdrequest(params));
    },
    fetchorders: (params) => {
      dispatch(fetchorders(params));
    },
    fetchscoiallead: (params) => {
      dispatch(fetchscoiallead(params));
    },
    fetchearnings: (params) => {
      dispatch(fetchearnings(params));
    },

    fetchdisorder: (params) => {
      dispatch(fetchdisorder(params));
    },
    fetchexpenses,
  };
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, bindAction)(DefaultLayout);
