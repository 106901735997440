export const FETCH_SOCIALLEAD_INIT_ACTION = "FETCH_SOCIALLEAD_INIT_ACTION";
export const FETCH_SOCIALLEAD_SUCCESS_ACTION =
  FETCH_SOCIALLEAD_INIT_ACTION + "_SUCCESS";
export const FETCH_SOCIALLEAD_FAIL_ACTION =
  FETCH_SOCIALLEAD_INIT_ACTION + "_ERROR";

export const ADD_SOCIALLEAD_INIT_ACTION = "ADD_SOCIALLEAD_INIT_ACTION";
export const ADD_SOCIALLEAD_SUCCESS_ACTION =
  ADD_SOCIALLEAD_INIT_ACTION + "_SUCCESS";
export const ADD_SOCIALLEAD_FAIL_ACTION = ADD_SOCIALLEAD_INIT_ACTION + "_ERROR";

export const FETCH_AGENT_INIT_ACTION = "FETCH_AGENT_INIT_ACTION";
export const FETCH_AGENT_SUCCESS_ACTION = FETCH_AGENT_INIT_ACTION + "_SUCCESS";
export const FETCH_AGENT_FAIL_ACTION = FETCH_AGENT_INIT_ACTION + "_ERROR";

export const UPDATE_SOCIALSTATUS_SUCCESS_ACTION =
  "UPDATE_SOCIALSTATUS_SUCCESS_ACTION";

export const ADD_DIS_SOCIALLEAD_INIT_ACTION = "ADD_DIS_SOCIALLEAD_INIT_ACTION";
export const ADD_DIS_SOCIALLEAD_SUCCESS_ACTION =
  ADD_DIS_SOCIALLEAD_INIT_ACTION + "_SUCCESS";
export const ADD_DIS_SOCIALLEAD_FAIL_ACTION =
  ADD_DIS_SOCIALLEAD_INIT_ACTION + "_ERROR";
