import { put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "./constants";

import { appConfig } from "../../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* fetchCreditNoteConfiguration(params) {
  let page = (params.params && params.params.page) || 1;
  let userData = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let userParam = userData.licenseeId;

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/creditNoteConfig?isActive=true&limit=10&page=${page}&licenseeId=${userParam}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_CREDIT_NOTE_CONFIGURATION_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_CREDIT_NOTE_CONFIGURATION_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* editCreditNoteConfiguration(value) {
  let userData = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  let id = value.id || "";

  let data = {
    user: (userData && userData.id) || null,
    licenseeId: (userData && userData.licenseeId) || null,

    remark: (value.value && value.value.remark) || "",
    startNo: (value.value && value.value.startNo) || "",
  };

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/creditNoteConfig/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let userId = resJSON.id;
      if (typeof value.value.imgUrl !== "string") {
        const formData = new FormData();

        formData.append(userId, value.value.imgUrl);

        const resFile = yield fetch(`${appConfig.ip}/CreditNoteConfig/image`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        if (!resFile.ok) {
          throw resFile;
        } else {
          const resJSON1 = yield resFile.json();
        }
      }

      yield toast.success("CreditNote configuration  edited successfully", {
        autoClose: 3000,
      });

      yield put({
        type: actionTypes.FETCH_CREDIT_NOTE_CONFIGURATION_INIT_ACTION,
      });
      yield put({
        type: actionTypes.EDIT_CREDIT_NOTE_CONFIGURATION_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.EDIT_CREDIT_NOTE_CONFIGURATION_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

export function* creditNoteConfigurationActionWatcher() {
  yield takeEvery(
    actionTypes.FETCH_CREDIT_NOTE_CONFIGURATION_INIT_ACTION,
    fetchCreditNoteConfiguration
  );

  yield takeEvery(
    actionTypes.EDIT_CREDIT_NOTE_CONFIGURATION_INIT_ACTION,
    editCreditNoteConfiguration
  );
}
