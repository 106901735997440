export const FETCH_USER_REPORT_INIT_ACTION = "  FETCH_USER_REPORT_INIT_ACTION";
export const FETCH_USER_REPORT_REPORT_SUCCESS_ACTION =
  FETCH_USER_REPORT_INIT_ACTION + "_SUCCESS";
export const FETCH_USER_REPORT_REPORT_FAIL_ACTION =
  FETCH_USER_REPORT_INIT_ACTION + "_ERROR";

export const FETCH_USER_REPORT_TOTAL_INIT_ACTION =
  "  FETCH_USER_REPORT_TOTAL_INIT_ACTION";
export const FETCH_USER_REPORT_TOTAL_SUCCESS_ACTION =
  FETCH_USER_REPORT_TOTAL_INIT_ACTION + "_SUCCESS";
export const FETCH_USER_REPORT_TOTAL_FAIL_ACTION =
  FETCH_USER_REPORT_TOTAL_INIT_ACTION + "_ERROR";

export const EXPORT_USER_REPORT_INIT_ACTION =
  "  EXPORT_USER_REPORT_INIT_ACTION";
export const EXPORT_USER_REPORT_SUCCESS_ACTION =
  EXPORT_USER_REPORT_INIT_ACTION + "_SUCCESS";
export const EXPORT_USER_REPORT_FAIL_ACTION =
  EXPORT_USER_REPORT_INIT_ACTION + "_ERROR";
