import { put, takeEvery, select, call, all } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_INCENTIVEBUCKET_INIT_ACTION,
  FETCH_INCENTIVEBUCKET_SUCCESS_ACTION,
  FETCH_INCENTIVEBUCKET_FAIL_ACTION,
  FETCH_INCENTIVEBUCKETBYID_INIT_ACTION,
  FETCH_INCENTIVEBUCKETBYID_SUCCESS_ACTION,
  FETCH_INCENTIVEBUCKETBYID_FAIL_ACTION,
  FETCH_AREAS_DROPDOWN_INIT_ACTION,
  FETCH_AREAS_DROPDOWN_SUCCESS_ACTION,
  FETCH_AREAS_DROPDOWN_FAIL_ACTION,
  FETCH_TOTAL_INIT_ACTION,
  FETCH_TOTAL_SUCCESS_ACTION,
  FETCH_TOTAL_FAIL_ACTION,
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as selectors from "../../service/selectors";
import { shallow } from "enzyme";

function* getRoleApiUrl() {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const role = localStorage.getItem("sStraitUserRole");
  const userId = localStorage.getItem("sStraitUserId");

  let getDataUrl = [
    {
      role: "licensee",
      url: "&licenseeId=" + userDtls.licenseeId + "&receiver" + userId,
    },
    {
      role: "distributor",
      url: "&licenseeId=" + userDtls.licenseeId + "&receiver" + userId,
    },
    { role: "licenseeAccountant", url: "&licenseeId=" + userDtls.licenseeId },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);
  return apiUrl;
}

function* fetchIncentiveBucket(value) {
  let pageno = value.value.page === undefined ? 1 : value.value.page;
  const token = localStorage.getItem("sStraitToken");
  const userId = localStorage.getItem("sStraitUserId");
  const apiUrl = yield call(getRoleApiUrl);
  let filterVal =
    !value || !value.value || !value.value.filterVal
      ? ""
      : value.value.filterVal;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? ""
      : value.value.searchVal;

  // let _url = `${appConfig.ip}/incentiveBucket?user=${userId}&receiver=${userId}&limit=10&search=${searchVal}${filterVal}&page=${pageno}${apiUrl.url}`;
  let _url = `${appConfig.ip}/incentiveBucket?limit=10&search=${searchVal}${filterVal}&page=${pageno}${apiUrl.url}`;

  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  } else if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  } else if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_INCENTIVEBUCKET_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_INCENTIVEBUCKET_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* getIncentiveBucketById(value) {
  const token = localStorage.getItem("sStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/incentiveBucket/` + value.value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_INCENTIVEBUCKETBYID_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_INCENTIVEBUCKETBYID_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchAreasDropdown(value) {
  let zone = !value || !value.value.id ? "" : value.value.id;
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/area?zone=${zone}&isActive=true&dummy=false`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_AREAS_DROPDOWN_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_AREAS_DROPDOWN_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchTotal(value) {
  const token = localStorage.getItem("sStraitToken");

  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const role = localStorage.getItem("sStraitUserRole");

  let getDataUrl = [
    { role: "licensee", url: "receiver=" + userDtls.id },
    { role: "licenseeAccountant", url: "receiver=" + userDtls.id },
    { role: "regionalManager", url: "receiver=" + userDtls.id },
    { role: "zonalManager", url: "receiver=" + userDtls.id },
    { role: "areaManager", url: "receiver=" + userDtls.id },
    { role: "salesExcecutive", url: "receiver=" + userDtls.id },
    { role: "distributor", url: "receiver=" + userDtls.id },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  try {
    const res = yield fetch(
      `${appConfig.ip}/incentiveBucket/totalAmount?` + apiUrl.url,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_TOTAL_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_TOTAL_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* IncentiveBucketActionWatcher() {
  yield takeEvery(FETCH_INCENTIVEBUCKET_INIT_ACTION, fetchIncentiveBucket);
  yield takeEvery(
    FETCH_INCENTIVEBUCKETBYID_INIT_ACTION,
    getIncentiveBucketById
  );
  yield takeEvery(FETCH_AREAS_DROPDOWN_INIT_ACTION, fetchAreasDropdown);
  yield takeEvery(FETCH_TOTAL_INIT_ACTION, fetchTotal);
}
