export const FETCH_LEADS_INIT_ACTION = "FETCH_LEADS_INIT_ACTION";
export const FETCH_LEADS_SUCCESS_ACTION = FETCH_LEADS_INIT_ACTION + "_SUCCESS";
export const FETCH_LEADS_FAIL_ACTION = FETCH_LEADS_INIT_ACTION + "_ERROR";

export const FETCH_CUSTOMERS_BY_SALESEXECUTIVE_INIT_ACTION =
  "FETCH_CUSTOMERS_BY_SALESEXECUTIVE_INIT_ACTION";
export const FETCH_CUSTOMERS_BY_SALESEXECUTIVE_SUCCESS_ACTION =
  FETCH_CUSTOMERS_BY_SALESEXECUTIVE_INIT_ACTION + "_SUCCESS";
export const FETCH_CUSTOMERS_BY_SALESEXECUTIVE_FAIL_ACTION =
  FETCH_CUSTOMERS_BY_SALESEXECUTIVE_INIT_ACTION + "_ERROR";

export const FETCH_PRODUCTS_BY_SALESEXECUTIVE_INIT_ACTION =
  "FETCH_PRODUCTS_BY_SALESEXECUTIVE_INIT_ACTION";
export const FETCH_PRODUCTS_BY_SALESEXECUTIVE_SUCCESS_ACTION =
  FETCH_PRODUCTS_BY_SALESEXECUTIVE_INIT_ACTION + "_SUCCESS";
export const FETCH_PRODUCTS_BY_SALESEXECUTIVE_FAIL_ACTION =
  FETCH_PRODUCTS_BY_SALESEXECUTIVE_INIT_ACTION + "_ERROR";

export const ADD_LEADS_INIT_ACTION = "ADD_LEADS_INIT_ACTION";
export const ADD_LEADS_SUCCESS_ACTION = ADD_LEADS_INIT_ACTION + "_SUCCESS";
export const ADD_LEADS_FAIL_ACTION = ADD_LEADS_INIT_ACTION + "_ERROR";

export const EDIT_LEADS_INIT_ACTION = "EDIT_LEADS_INIT_ACTION";
export const EDIT_LEADS_SUCCESS_ACTION = EDIT_LEADS_INIT_ACTION + "_SUCCESS";
export const EDIT_LEADS_FAIL_ACTION = EDIT_LEADS_INIT_ACTION + "_ERROR";

export const DELETE_LEADS_INIT_ACTION = "DELETE_LEADS_INIT_ACTION";
export const DELETE_LEADS_SUCCESS_ACTION =
  DELETE_LEADS_INIT_ACTION + "_SUCCESS";
export const DELETE_LEADS_FAIL_ACTION = DELETE_LEADS_INIT_ACTION + "_ERROR";

export const EXPORT_LEADS_INIT_ACTION = "EXPORT_LEADS_INIT_ACTION";
export const EXPORT_LEADS_SUCCESS_ACTION =
  EXPORT_LEADS_INIT_ACTION + "_SUCCESS";
export const EXPORT_LEADS_FAIL_ACTION = EXPORT_LEADS_INIT_ACTION + "_ERROR";

export const FETCH_LEADS_DETAIL_INIT_ACTION = "FETCH_LEADS_DETAIL_INIT_ACTION";
export const FETCH_LEADS_DETAIL_SUCCESS_ACTION =
  FETCH_LEADS_DETAIL_INIT_ACTION + "_SUCCESS";
export const FETCH_LEADS_DETAIL_FAIL_ACTION =
  FETCH_LEADS_DETAIL_INIT_ACTION + "_ERROR";

export const FETCH_USER_DETAIL_INIT_ACTION = "FETCH_USER_DETAIL_INIT_ACTION";
export const FETCH_USER_DETAIL_SUCCESS_ACTION =
  FETCH_USER_DETAIL_INIT_ACTION + "_SUCCESS";
export const FETCH_USER_DETAIL_FAIL_ACTION =
  FETCH_USER_DETAIL_INIT_ACTION + "_ERROR";

export const FILTER_SEARCH_LEADS_INIT_ACTION =
  "FILTER_SEARCH_LEADS_INIT_ACTION";
export const FILTER_SEARCH_LEADS_SUCCESS_ACTION =
  FILTER_SEARCH_LEADS_INIT_ACTION + "_SUCCESS";
export const FILTER_SEARCH_LEADS_FAIL_ACTION =
  FILTER_SEARCH_LEADS_INIT_ACTION + "_ERROR";

export const ADD_FOLLOWUP_INIT_ACTION = "ADD_FOLLOWUP_INIT_ACTION";
export const ADD_FOLLOWUP_SUCCESS_ACTION =
  ADD_FOLLOWUP_INIT_ACTION + "_SUCCESS";
export const ADD_FOLLOWUP_FAIL_ACTION = ADD_FOLLOWUP_INIT_ACTION + "_ERROR";

export const FETCH_FOLLOWUP_INIT_ACTION = "FETCH_FOLLOWUP_INIT_ACTION";

// export const FETCH_LEADS_CLEAR_ACTION = "FETCH_LEADS_CLEAR_ACTION";
export const CHANGE_ORDERTOLEADSTATUS_SUCCESS_ACTION =
  "CHANGE_ORDERTOLEADSTATUS_SUCCESS_ACTION";

export const FETCH_SALESEXCTV_INIT_ACTION = "FETCH_SALESEXCTV_INIT_ACTION";
export const FETCH_SALESEXCTV_SUCCESS_ACTION =
  FETCH_SALESEXCTV_INIT_ACTION + "_SUCCESS";
export const FETCH_SALESEXCTV_FAIL_ACTION =
  FETCH_SALESEXCTV_INIT_ACTION + "_ERROR";

export const REMOVE_PRODUCT_INIT_ACTION = "REMOVE_PRODUCT_INIT_ACTION";
export const ADDREDUCER_PRODUCT_INIT_ACTION = "ADDREDUCER_PRODUCT_INIT_ACTION";

export const ADD_DISLEADS_INIT_ACTION = "ADD_DISLEADS_INIT_ACTION";
export const ADD_DISLEADS_SUCCESS_ACTION =
  ADD_DISLEADS_INIT_ACTION + "_SUCCESS";
export const ADD_DISLEADS_FAIL_ACTION = ADD_DISLEADS_INIT_ACTION + "_ERROR";

export const EDIT_DISLEADS_INIT_ACTION = "EDIT_DISLEADS_INIT_ACTION";
export const EDIT_DISLEADS_SUCCESS_ACTION =
  EDIT_DISLEADS_INIT_ACTION + "_SUCCESS";
export const EDIT_DISLEADS_FAIL_ACTION = EDIT_DISLEADS_INIT_ACTION + "_ERROR";

export function fetchLeads(value) {
  return {
    type: FETCH_LEADS_INIT_ACTION,
    value: value,
  };
}

export function fetchCusBySExec(value) {
  return {
    type: FETCH_CUSTOMERS_BY_SALESEXECUTIVE_INIT_ACTION,
    value: value,
  };
}

export function fetchPrdtBySExec(value) {
  return {
    type: FETCH_PRODUCTS_BY_SALESEXECUTIVE_INIT_ACTION,
    value: value,
  };
}

export function addLeads(value) {
  return {
    type: ADD_LEADS_INIT_ACTION,
    value: value,
  };
}

export function addFollowup(value) {
  return {
    type: ADD_FOLLOWUP_INIT_ACTION,
    value: value,
  };
}

export function editLeads(value) {
  return {
    type: EDIT_LEADS_INIT_ACTION,
    value: value,
  };
}

export function deleteLeads(value) {
  return {
    type: DELETE_LEADS_INIT_ACTION,
    value: value,
  };
}

export function leadsPageSelected(value) {
  return {
    type: FETCH_LEADS_INIT_ACTION,
    value: value,
  };
}

// export function clearLeads() {
//   return {
//     type:FETCH_LEADS_CLEAR_ACTION ,
//   };
// }

export function fetchLeadsDetail(value) {
  return {
    type: FETCH_LEADS_DETAIL_INIT_ACTION,
    value: value,
  };
}

export function fetchUserDetail(value) {
  return {
    type: FETCH_USER_DETAIL_INIT_ACTION,
    value: value,
  };
}

export function filterSearchLeads(value) {
  return {
    type: FILTER_SEARCH_LEADS_INIT_ACTION,
    value: value,
  };
}
export function exprtFullData(value) {
  return {
    type: EXPORT_LEADS_INIT_ACTION,
    value: value,
  };
}

export function updateLeadStatus(value) {
  return {
    type: CHANGE_ORDERTOLEADSTATUS_SUCCESS_ACTION,
    value: value,
  };
}
export function fetchSalesExctv(value) {
  return {
    type: FETCH_SALESEXCTV_INIT_ACTION,
    value: value,
  };
}

export function removeProduct(value) {
  return {
    type: REMOVE_PRODUCT_INIT_ACTION,
    value: value,
  };
}

export function addReducerProduct(value) {
  return {
    type: ADDREDUCER_PRODUCT_INIT_ACTION,
    value: value,
  };
}
export function addDisLeads(value) {
  return {
    type: ADD_DISLEADS_INIT_ACTION,
    value: value,
  };
}
export function editDisLeads(value, arr) {
  return {
    type: EDIT_DISLEADS_INIT_ACTION,
    value: value,
    arr: arr,
  };
}
