import { checkPermission } from "./screens/Config/utils";
//DirectSeller side rolewise navbar

let user = JSON.parse(localStorage.getItem("sStraitUserDtls"));

export const navLicensee = {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
    },
    {
      name: "My Sales Network",
      url: "/mysalesnetwork",
      icon: "fa fa-wrench",
      children: [
        {
          name: "Region",
          url: "/mysalesnetwork/region",
          icon: "fa fa-flag",
        },
        {
          name: "Zone",
          url: "/mysalesnetwork/zone",
          icon: "icon-location-pin",
        },
        {
          name: "Area",
          url: "/mysalesnetwork/area",
          icon: "icon-location-pin",
        },
        {
          name: "Users",
          url: "/mysalesnetwork/users",
          icon: "fa fa-users",
        },
      ],
    },
    {
      name: "Agency",
      url: "/agency",
      icon: "fa fa-address-book",
    },
    {
      name: "Products",
      url: "/products",
      icon: "fa fa-product-hunt",
    },
    // {
    //   url: '/ImportProd',
    // },
    {
      name: "Inventory",
      url: "/productInventory",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Customers",
      url: "/customers",
      icon: "fa fa-users",
    },
    {
      name: "Orders",
      url: "/orders",
      icon: "fa fa-truck",
    },
    {
      name: "Leads",
      url: "/leads",
      icon: "fa fa-circle-o-notch",
    },
    {
      name: "Follow up",
      url: "/Followup",
      icon: "fa fa-arrow-up",
    },
    {
      name: "Quotations",
      url: "/Quotations",
      icon: "fa fa-clipboard",
    },

    {
      name: "Payments",
      url: "/payments",
      icon: "fa fa-book",
    },
    {
      name: "Invoices",
      url: "/invoices",
      icon: "fa fa-file",
    },

    {
      name: "My Earnings",
      url: "/myearnings",
      icon: "fa fa-credit-card",
    },
    {
      name: "Withdrawal Limit",
      url: "/WithdrawalLimit",
      icon: "fa fa-thumb-tack",
    },
    {
      name: "Withdrawal Requests",
      url: "/withdrawalrequest",
      icon: "cui-calculator ",
    },

    {
      name: "Incentive Bucket",
      url: "/incentivebucket",
      icon: "fa fa-bitbucket",
    },
    // {
    //   name: 'Reports',
    //   url: '/reports',
    //   icon: ' fa fa-file',
    // },

    // {
    //   title: true,
    //   name: 'Components',
    //   wrapper: {
    //     element: '',
    //     attributes: {},
    //   },
    // },
    {
      name: "Rewards",
      url: "/reward",
      icon: "fa fa-trophy",
    },
    // {
    //   name: "Sales Return",
    //   url: "/salesreturn",
    //   icon: "fa fa-trophy",
    // },
    {
      name: "Marketing Automation",
      url: "/marketingautomation",
      icon: "fa fa-cogs",
      children: [
        {
          name: "Forms",
          url: "/marketingautomation/forms",
          icon: "fa fa-wpforms",
          children: [
            {
              name: "Google Forms",
              url: "/marketingautomation/forms/googleforms",
              icon: "fa fa-google",
            },
          ],
        },
        {
          name: "MarketPlace",
          url: "/marketingautomation/marketplace",
          icon: "fa fa-bullhorn",
          children: [
            {
              name: "Just Dial",
              url: "/marketingautomation/marketplace/justdial",
              icon: "fa fa-flag",
            },
            {
              name: "IndiaMart",
              url: "/marketingautomation/marketplace/indiamart",
              icon: "fa fa-road",
            },

            {
              name: "Sulekha",
              url: "/marketingautomation/marketplace/sulekha",
              icon: "fa fa-flag",
            },
            {
              name: "TradeIndia",
              url: "/marketingautomation/marketplace/tradeindia",
              icon: "fa fa-road",
            },
          ],
        },
        {
          name: "Social Media",
          url: "/marketingautomation/smedia",
          icon: "fa fa-user-plus",
          children: [
            {
              name: "Facebook",
              url: "/marketingautomation/smedia/facebook",
              icon: "fa fa-facebook-square",
            },
            // {
            //   name: "Instagram",
            //   url: "/marketingautomation/smedia/instagram",
            //   icon: "fa fa-instagram",
            // },
            // {
            //   name: "Linked In",
            //   url: "/marketingautomation/smedia/linkedin",
            //   icon: "fa fa-flag",
            // },
          ],
        },
        // {
        //   name: "Communication",
        //   url: "/marketingautomation/comnctn",
        //   icon: "fa fa-comments",
        //   children: [
        //     {
        //       name: "Whatsapp",
        //       url: "/marketingautomation/comnctn/whatsapp",
        //       icon: "fa fa-whatsapp",
        //     },
        //     {
        //       name: "SMS",
        //       url: "/marketingautomation/comnctn/sms",
        //       icon: "fa fa-envelope",
        //     },
        //   ],
        // },
        {
          name: "Social Lead",
          url: "/marketingautomation/sociallead",
          icon: "fa fa-wpforms",
        },
      ],
    },

    {
      name: "Branding",
      url: "/branding",
      icon: "	fa fa-btc",

      children: [
        {
          name: "Invoice Configuration",
          url: "/branding/invoiceConfiguration",
          icon: "fa fa-file",
        },
        {
          name: "Quote Configuration",
          url: "/branding/quotationConfiguration",
          icon: "fa fa-file",
        },
      ],
    },
    {
      name: "Support",
      url: "/support",
      icon: "fa fa-user-circle",
    },
  ],
};

export const navLicenseeAccountant = {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
    },
    {
      name: "My Sales Network",
      url: "/mysalesnetwork",
      icon: "fa fa-wrench",
      children: [
        {
          name: "Region",
          url: "/mysalesnetwork/region",
          icon: "fa fa-flag",
        },
        {
          name: "Zone",
          url: "/mysalesnetwork/zone",
          icon: "icon-location-pin",
        },
        {
          name: "Area",
          url: "/mysalesnetwork/area",
          icon: "icon-location-pin",
        },
        {
          name: "Users",
          url: "/mysalesnetwork/users",
          icon: "fa fa-users",
        },
      ],
    },
    {
      name: "Products",
      url: "/products",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Inventory",
      url: "/productInventory",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Customers",
      url: "/customers",
      icon: "fa fa-users",
    },
    {
      name: "Orders",
      url: "/orders",
      icon: "fa fa-truck",
    },
    {
      name: "Leads",
      url: "/leads",
      icon: "fa fa-circle-o-notch",
    },
    {
      name: "Follow up",
      url: "/Followup",
      icon: "fa fa-arrow-up",
    },
    {
      name: "Quotations",
      url: "/Quotations",
      icon: "fa fa-clipboard",
    },

    {
      name: "Payments",
      url: "/payments",
      icon: "fa fa-money",
    },
    {
      name: "Invoices",
      url: "/Invoices",
      icon: "fa fa-file",
    },

    {
      name: "My Earnings",
      url: "/myearnings",
      icon: "nav-icon fa fa-credit-card",
    },
    // {
    //   name: "Withdrawal Limit",
    //   url: "/WithdrawalLimit",
    //   icon: "fa fa-thumb-tack",
    // },
    {
      name: "Withdrawal Requests",
      url: "/withdrawalrequest",
      icon: "cui-calculator ",
    },

    {
      name: "Incentive Bucket",
      url: "/incentivebucket",
      icon: "fa fa-bitbucket",
    },
    {
      name: "Support",
      url: "/support",
      icon: "fa fa-user-circle",
    },
    // {
    //   name: 'Reports',
    //   url: '/reports',
    //   icon: ' fa fa-file',
    // },
  ],
};

export const navRegionalMngr = {
  items: [
    // {
    //   name: 'Dashboard',
    //   url: '/regionalMngr/dashboard',
    //   icon: 'icon-speedometer',
    // },
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
    },
    {
      name: "My Sales Network",
      url: "/mysalesnetwork",
      icon: "fa fa-wrench",
      children: [
        // {
        //   name: "Region",
        //   url: "/mysalesnetwork/region",
        //   icon: "fa fa-flag",
        // },
        {
          name: "Zone",
          url: "/mysalesnetwork/zone",
          icon: "icon-location-pin",
        },
        {
          name: "Area",
          url: "/mysalesnetwork/area",
          icon: "icon-location-pin",
        },
        {
          name: "Users",
          url: "/mysalesnetwork/users",
          icon: "fa fa-users",
        },
      ],
    },
    {
      name: "Products",
      url: "/products",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Customers",
      url: "/customers",
      icon: "fa fa-users",
    },
    {
      name: "Orders",
      url: "/orders",
      icon: "fa fa-truck",
    },
    {
      name: "Leads",
      url: "/leads",
      icon: "fa fa-circle-o-notch",
    },
    {
      name: "Follow up",
      url: "/Followup",
      icon: "fa fa-arrow-up",
    },
    {
      name: "Quotations",
      url: "/Quotations",
      icon: "fa fa-clipboard",
    },

    {
      name: "Payments",
      url: "/payments",
      icon: "fa fa-money",
    },

    {
      name: "My Earnings",
      url: "/myearnings",
      icon: "fa fa-credit-card",
    },
    {
      name: "Withdrawal Request",
      url: "/withdrawalrequest",
      icon: "cui-calculator ",
    },
    {
      name: "Support",
      url: "/support",
      icon: "fa fa-user-circle",
    },
  ],
};

export const navZonalMngr = {
  items: [
    // {
    //   name: 'Dashboard',
    //   url: '/zonalMngr/dashboard',
    //   icon: 'icon-speedometer',
    // },
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
    },
    {
      name: "My Sales Network",
      url: "/mysalesnetwork",
      icon: "fa fa-wrench",
      children: [
        {
          name: "Area",
          url: "/mysalesnetwork/area",
          icon: "icon-location-pin",
        },
        {
          name: "Users",
          url: "/mysalesnetwork/users",
          icon: "fa fa-users",
        },
      ],
    },
    {
      name: "Products",
      url: "/products",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Customers",
      url: "/customers",
      icon: "fa fa-users",
    },
    {
      name: "Orders",
      url: "/orders",
      icon: "fa fa-truck",
    },
    {
      name: "Leads",
      url: "/leads",
      icon: "fa fa-circle-o-notch",
    },
    {
      name: "Follow up",
      url: "/Followup",
      icon: "fa fa-arrow-up",
    },
    {
      name: "Quotations",
      url: "/Quotations",
      icon: "fa fa-clipboard",
    },

    {
      name: "Payments",
      url: "/payments",
      icon: "fa fa-money",
    },

    {
      name: "My Earnings",
      url: "/myearnings",
      icon: "fa fa-credit-card",
    },
    {
      name: "Withdrawal Request",
      url: "/withdrawalrequest",
      icon: "cui-calculator ",
    },
    {
      name: "Support",
      url: "/support",
      icon: "fa fa-user-circle",
    },
  ],
};

export const navAreaMngr = {
  items: [
    // {
    //   name: 'Dashboard',
    //   url: '/areaMngr/dashboard',
    //   icon: 'icon-speedometer',

    // },
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
    },
    {
      name: "My Sales Network",
      url: "/mysalesnetwork",
      icon: "fa fa-wrench",
      children: [
        {
          name: "Users",
          url: "/mysalesnetwork/users",
          icon: "fa fa-users",
        },
      ],
    },
    {
      name: "Products",
      url: "/products",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Customers",
      url: "/customers",
      icon: "fa fa-users",
    },
    {
      name: "Orders",
      url: "/orders",
      icon: "fa fa-truck",
    },
    {
      name: "Leads",
      url: "/leads",
      icon: "fa fa-circle-o-notch",
    },
    {
      name: "Follow up",
      url: "/Followup",
      icon: "fa fa-arrow-up",
    },

    {
      name: "Quotations",
      url: "/Quotations",
      icon: "fa fa-clipboard",
    },

    {
      name: "Payments",
      url: "/payments",
      icon: "fa fa-book",
    },

    {
      name: "My Earnings",
      url: "/myearnings",
      icon: "nav-icon fa fa-credit-card",
    },
    {
      name: "Withdrawal Request",
      url: "/withdrawalrequest",
      icon: "cui-calculator ",
    },
    {
      name: "Support",
      url: "/support",
      icon: "fa fa-user-circle",
    },
  ],
};

export const navSalesExctv = {
  items: [
    // {
    //   name: 'Dashboard',
    //   url: '/saleExctv/dashboard',
    //   icon: 'icon-speedometer',

    // },
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
    },
    {
      name: "Products",
      url: "/products",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Customers",
      url: "/customers",
      icon: "fa fa-users",
    },

    {
      name: "Orders",
      url: "/orders",
      icon: "fa fa-truck",
    },
    {
      name: "Leads",
      url: "/leads",
      icon: "fa fa-circle-o-notch",
    },
    {
      name: "Follow up",
      url: "/Followup",
      icon: "fa fa-arrow-up",
    },
    {
      name: "Quotations",
      url: "/Quotations",
      icon: "fa fa-clipboard",
    },
    {
      name: "Payments",
      url: "/payments",
      icon: "fa fa-book",
    },

    {
      name: "My Earnings",
      url: "/myearnings",
      icon: "nav-icon fa fa-credit-card",
    },

    {
      name: "Withdrawal Request",
      url: "/withdrawalrequest",
      icon: "cui-calculator ",
    },

    {
      name: "Payment Info",
      url: "/paymentinfo",
      icon: "fa fa-money",
    },
    {
      name: "Support",
      url: "/support",
      icon: "fa fa-user-circle",
    },
  ],
};

//Distributor side rolewise navbar

export const navDistributor = {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
      show: checkPermission("menu", "dashboard", "navDistributor"),
    },
    {
      name: "Users",
      url: "/user",
      icon: "fa fa-users",
      show: checkPermission("menu", "user", "navDistributor"),
    },
    {
      name: "Suppliers",
      url: "/agency",
      icon: "fa fa-address-book",
      show: checkPermission("menu", "agency", "navDistributor"),
    },
    {
      name: "Products",
      url: "/product",
      icon: "fa fa-product-hunt",
      show: checkPermission("menu", "product", "navDistributor"),
    },
    {
      name: "Inventory",
      url: "/disproductInventory",
      icon: "fa fa-product-hunt",
      show: checkPermission("menu", "disproductInventory", "navDistributor"),
    },
    {
      name: "Customers",
      url: "/customers",
      icon: "fa fa-users",
      show: checkPermission("menu", "customers", "navDistributor"),
    },
    {
      name: "Orders",
      url: "/order",
      icon: "fa fa-truck",
      show: checkPermission("menu", "order", "navDistributor"),
    },
    {
      name: "Sales Return",
      url: "/salesreturn",
      icon: "fa fa-trophy",
      show: checkPermission("menu", "salesreturn", "navDistributor"),
    },
    {
      name: "Product Replace",
      url: "/productReplace",
      icon: "fa fa-exchange fa-lg",
      // show: checkPermission("menu", "salesreturn", "navDistributor"),
    },
    {
      name: "Leads",
      url: "/lead",
      icon: "fa fa-circle-o-notch",
      show: checkPermission("menu", "lead", "navDistributor"),
    },
    {
      name: "Follow up",
      url: "/Followup",
      icon: "fa fa-arrow-up",
      show: checkPermission("menu", "Followup", "navDistributor"),
    },
    {
      name: "Quotations",
      url: "/Quotation",
      icon: "fa fa-clipboard",
      show: checkPermission("menu", "Quotation", "navDistributor"),
    },
    {
      name: "Expenses",
      url: "/expense",
      icon: "fa fa-book",
      show: checkPermission("menu", "expense", "navDistributor"),
    },

    {
      name: "Payments",
      url: "/payments",
      icon: "fa fa-book",
      show: checkPermission("menu", "payments", "navDistributor"),
    },
    {
      name: "Invoices",
      url: "/invoices",
      icon: "fa fa-file",
      show: checkPermission("menu", "invoices", "navDistributor"),
    },

    {
      name: "My Earnings",
      url: "/myearnings",
      icon: "fa fa-credit-card",
      show: checkPermission("menu", "myearnings", "navDistributor"),
    },
    {
      name: "Incentive Bucket",
      url: "/incentivebucket",
      icon: "fa fa-bitbucket",
    },
    {
      name: "Reports",
      url: "/report",
      icon: "fa fa-registered",

      children: [
        {
          name: "Debtors List",
          url: "/report/debtorsReport",
          icon: "fa fa-file",
        },
        {
          name: "Credit Report List",
          url: "/report/creditnoteReport",
          icon: "fa fa-file",
        },
        {
          name: "Agent Report",
          url: "/report/userlistReport",
          icon: "fa fa-user",
        },
      ],
    },
    {
      name: "Routes",
      url: "/customerRoute",
      icon: "fa fa-road",
    },
    {
      name: "Stock Movement",
      url: "/stockMovemnet",
      icon: "fa fa-file-archive-o",
    },
    // {
    //   name: "Withdrawal Requests",
    //   url: "/withdrawalrequest",
    //   icon: "cui-calculator ",
    // },

    {
      name: "Marketing Automation",
      url: "/marketingautomation",
      icon: "fa fa-cogs",
      children: [
        {
          name: "Forms",
          url: "/marketingautomation/forms",
          icon: "fa fa-wpforms",
          children: [
            {
              name: "Google Forms",
              url: "/marketingautomation/forms/googleforms",
              icon: "fa fa-google",
            },
          ],
        },
        {
          name: "MarketPlace",
          url: "/marketingautomation/marketplace",
          icon: "fa fa-bullhorn",
          children: [
            {
              name: "Just Dial",
              url: "/marketingautomation/marketplace/justdial",
              icon: "fa fa-flag",
            },
            {
              name: "IndiaMart",
              url: "/marketingautomation/marketplace/indiamart",
              icon: "fa fa-road",
            },

            {
              name: "Sulekha",
              url: "/marketingautomation/marketplace/sulekha",
              icon: "fa fa-flag",
            },
            {
              name: "TradeIndia",
              url: "/marketingautomation/marketplace/tradeindia",
              icon: "fa fa-road",
            },
          ],
        },
        {
          name: "Social Media",
          url: "/marketingautomation/smedia",
          icon: "fa fa-user-plus",
          children: [
            {
              name: "Facebook",
              url: "/marketingautomation/smedia/facebook",
              icon: "fa fa-facebook-square",
            },
          ],
        },

        {
          name: "Social Lead",
          url: "/marketingautomation/sociallead",
          icon: "fa fa-wpforms",
        },
      ],
    },
    {
      name: "Branding",
      url: "/branding",
      icon: "	fa fa-btc",

      children: [
        {
          name: "Invoice Configuration",
          url: "/branding/invoiceConfiguration",
          icon: "fa fa-file",
        },
        {
          name: "Quote Configuration",
          url: "/branding/quotationConfiguration",
          icon: "fa fa-file",
        },
        {
          name: "Credit Note Config",
          url: "/branding/creditNoteConfiguration",
          icon: "fa fa-file",
        },
      ],
    },

    {
      name: "Support",
      url: "/support",
      icon: "fa fa-user-circle",
    },
  ],
};

export const navDistributorNW = {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
    },
    {
      name: "My Sales Network",
      url: "/mysalesnetwork",
      icon: "fa fa-wrench",
      children: [
        {
          name: "Region",
          url: "/mysalesnetwork/region",
          icon: "fa fa-flag",
        },
        {
          name: "Zone",
          url: "/mysalesnetwork/zone",
          icon: "icon-location-pin",
        },
        {
          name: "Area",
          url: "/mysalesnetwork/area",
          icon: "icon-location-pin",
        },
        {
          name: "Users",
          url: "/mysalesnetwork/users",
          icon: "fa fa-users",
        },
      ],
    },
    {
      name: "Suppliers",
      url: "/agency",
      icon: "fa fa-address-book",
    },
    {
      name: "Products",
      url: "/product",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Inventory",
      url: "/disproductInventory",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Customers",
      url: "/customers",
      icon: "fa fa-users",
    },
    {
      name: "Orders",
      url: "/order",
      icon: "fa fa-truck",
    },
    // {
    //   name: "Sales Return",
    //   url: "/salesreturn",
    //   icon: "fa fa-trophy",
    // },
    {
      name: "Leads",
      url: "/lead",
      icon: "fa fa-circle-o-notch",
    },
    {
      name: "Follow up",
      url: "/Followup",
      icon: "fa fa-arrow-up",
    },
    {
      name: "Quotations",
      url: "/Quotation",
      icon: "fa fa-clipboard",
    },
    {
      name: "Expenses",
      url: "/expense",
      icon: "fa fa-book",
    },
    {
      name: "Payments",
      url: "/payments",
      icon: "fa fa-book",
    },
    {
      name: "Invoices",
      url: "/invoices",
      icon: "fa fa-file",
    },

    {
      name: "My Earnings",
      url: "/myearnings",
      icon: "fa fa-credit-card",
    },
    {
      name: "Withdrawal Limit",
      url: "/WithdrawalLimit",
      icon: "fa fa-thumb-tack",
      show: checkPermission("menu", "WithdrawalLimit", "navDistributorNW"),
    },
    {
      name: "Withdrawal Requests",
      url: "/withdrawalrequest",
      icon: "cui-calculator ",
    },

    {
      name: "Rewards",
      url: "/reward",
      icon: "fa fa-trophy",
    },
    {
      name: "Incentive Bucket",
      url: "/incentivebucket",
      icon: "fa fa-bitbucket",
    },

    {
      name: "Marketing Automation",
      url: "/marketingautomation",
      icon: "fa fa-cogs",
      children: [
        {
          name: "Forms",
          url: "/marketingautomation/forms",
          icon: "fa fa-wpforms",
          children: [
            {
              name: "Google Forms",
              url: "/marketingautomation/forms/googleforms",
              icon: "fa fa-google",
            },
          ],
        },
        {
          name: "MarketPlace",
          url: "/marketingautomation/marketplace",
          icon: "fa fa-bullhorn",
          children: [
            {
              name: "Just Dial",
              url: "/marketingautomation/marketplace/justdial",
              icon: "fa fa-flag",
            },
            {
              name: "IndiaMart",
              url: "/marketingautomation/marketplace/indiamart",
              icon: "fa fa-road",
            },

            {
              name: "Sulekha",
              url: "/marketingautomation/marketplace/sulekha",
              icon: "fa fa-flag",
            },
            {
              name: "TradeIndia",
              url: "/marketingautomation/marketplace/tradeindia",
              icon: "fa fa-road",
            },
          ],
        },
        {
          name: "Social Media",
          url: "/marketingautomation/smedia",
          icon: "fa fa-user-plus",
          children: [
            {
              name: "Facebook",
              url: "/marketingautomation/smedia/facebook",
              icon: "fa fa-facebook-square",
            },
          ],
        },

        {
          name: "Social Lead",
          url: "/marketingautomation/sociallead",
          icon: "fa fa-wpforms",
        },
      ],
    },
    {
      name: "Reports",
      url: "/report",
      icon: "fa fa-registered",

      children: [
        {
          name: "Debtors List",
          url: "/report/debtorsReport",
          icon: "fa fa-file",
        },
        // {
        //   name: "Credit Report List",
        //   url: "/report/creditnoteReport",
        //   icon: "fa fa-file",
        // },
      ],
    },
    {
      name: "Branding",
      url: "/branding",
      icon: "	fa fa-btc",

      children: [
        {
          name: "Invoice Configuration",
          url: "/branding/invoiceConfiguration",
          icon: "fa fa-file",
        },
        {
          name: "Quote Configuration",
          url: "/branding/quotationConfiguration",
          icon: "fa fa-file",
        },
        // {
        //   name: "Credit Note Config",
        //   url: "/branding/creditNoteConfiguration",
        //   icon: "fa fa-file",
        // },
      ],
    },
    {
      name: "Support",
      url: "/support",
      icon: "fa fa-user-circle",
    },
  ],
};

export const navDistrbtrAgent = {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
    },
    {
      name: "Products",
      url: "/product",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Customers",
      url: "/customers",
      icon: "fa fa-users",
    },
    {
      name: "Orders",
      url: "/order",
      icon: "fa fa-truck",
    },
    {
      name: "Sales Return",
      url: "/salesreturn",
      icon: "fa fa-trophy",
    },
    {
      name: "Product Replace",
      url: "/productReplace",
      icon: "fa fa-exchange fa-lg",
      // show: checkPermission("menu", "salesreturn", "navDistributor"),
    },
    {
      name: "Leads",
      url: "/lead",
      icon: "fa fa-circle-o-notch",
    },
    {
      name: "Follow up",
      url: "/Followup",
      icon: "fa fa-arrow-up",
    },
    {
      name: "Quotations",
      url: "/Quotation",
      icon: "fa fa-clipboard",
    },
    {
      name: "Expenses",
      url: "/expense",
      icon: "fa fa-book",
    },
    {
      name: "Payments",
      url: "/payments",
      icon: "fa fa-money",
    },
    {
      name: "Invoices",
      url: "/invoices",
      icon: "fa fa-file",
    },
    {
      name: "Reports",
      url: "/report",
      icon: "fa fa-registered",

      children: [
        {
          name: "Debtors List",
          url: "/report/debtorsReport",
          icon: "fa fa-file",
        },
        {
          name: "Credit Report List",
          url: "/report/creditnoteReport",
          icon: "fa fa-file",
        },
        {
          name: "Agent Reports",
          url: `/report/agentReport`,
          icon: "fa fa-user",
        },
      ],
    },

    // {
    //   name: "My Earnings",
    //   url: "/myearnings",
    //   icon: "nav-icon fa fa-credit-card",
    // },

    // {
    //   name: "Withdrawal Request",
    //   url: "/withdrawalrequest",
    //   icon: "cui-calculator ",
    // },
    {
      name: "Stock Movement",
      url: "/stockMovemnet",
      icon: "fa fa-file-archive-o",
    },

    {
      name: "Payment Info",
      url: "/paymentinfo",
      icon: "fa fa-money",
    },

    {
      name: "Support",
      url: "/support",
      icon: "fa fa-user-circle",
    },
  ],
};

export const navDistrbtrSalesExctv = {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
    },
    {
      name: "Products",
      url: "/product",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Customers",
      url: "/customers",
      icon: "fa fa-users",
    },

    {
      name: "Orders",
      url: "/order",
      icon: "fa fa-truck",
    },
    // {
    //   name: "Sales Return",
    //   url: "/salesreturn",
    //   icon: "fa fa-trophy",
    // },
    {
      name: "Leads",
      url: "/lead",
      icon: "fa fa-circle-o-notch",
    },
    {
      name: "Follow up",
      url: "/Followup",
      icon: "fa fa-arrow-up",
    },
    {
      name: "Quotations",
      url: "/Quotation",
      icon: "fa fa-clipboard",
    },
    {
      name: "Expenses",
      url: "/expense",
      icon: "fa fa-book",
    },
    {
      name: "Payments",
      url: "/payments",
      icon: "fa fa-book",
    },
    {
      name: "Invoices",
      url: "/invoices",
      icon: "fa fa-file",
    },
    {
      name: "My Earnings",
      url: "/myearnings",
      icon: "nav-icon fa fa-credit-card",
    },

    {
      name: "Withdrawal Request",
      url: "/withdrawalrequest",
      icon: "cui-calculator ",
    },

    {
      name: "Payment Info",
      url: "/paymentinfo",
      icon: "fa fa-money",
    },
    {
      name: "Support",
      url: "/support",
      icon: "fa fa-user-circle",
    },
  ],
};

export const navDistrbtrLiceAccount = {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
    },
    {
      name: "Users",
      url: "/user",
      icon: "fa fa-users",
    },

    {
      name: "Products",
      url: "/product",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Inventory",
      url: "/disproductInventory",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Customers",
      url: "/customers",
      icon: "fa fa-users",
    },
    {
      name: "Orders",
      url: "/order",
      icon: "fa fa-truck",
    },
    {
      name: "Leads",
      url: "/lead",
      icon: "fa fa-circle-o-notch",
    },
    {
      name: "Follow up",
      url: "/Followup",
      icon: "fa fa-arrow-up",
    },
    {
      name: "Quotations",
      url: "/Quotation",
      icon: "fa fa-clipboard",
    },
    {
      name: "Expenses",
      url: "/expense",
      icon: "fa fa-book",
    },
    {
      name: "Payments",
      url: "/payments",
      icon: "fa fa-book",
    },
    {
      name: "Invoices",
      url: "/invoices",
      icon: "fa fa-file",
    },
    {
      name: "My Earnings",
      url: "/myearnings",
      icon: "fa fa-credit-card",
    },
    {
      name: "Incentive Bucket",
      url: "/incentivebucket",
      icon: "fa fa-bitbucket",
    },

    {
      name: "Support",
      url: "/support",
      icon: "fa fa-user-circle",
    },
  ],
};

export const navDistrbtrNwLiceAccount = {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
    },

    {
      name: "My Sales Network",
      url: "/mysalesnetwork",
      icon: "fa fa-wrench",
      children: [
        {
          name: "Region",
          url: "/mysalesnetwork/region",
          icon: "fa fa-flag",
        },
        {
          name: "Zone",
          url: "/mysalesnetwork/zone",
          icon: "icon-location-pin",
        },
        {
          name: "Area",
          url: "/mysalesnetwork/area",
          icon: "icon-location-pin",
        },
        {
          name: "Users",
          url: "/mysalesnetwork/users",
          icon: "fa fa-users",
        },
      ],
    },

    {
      name: "Products",
      url: "/product",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Inventory",
      url: "/disproductInventory",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Customers",
      url: "/customers",
      icon: "fa fa-users",
    },
    {
      name: "Orders",
      url: "/order",
      icon: "fa fa-truck",
    },
    {
      name: "Leads",
      url: "/lead",
      icon: "fa fa-circle-o-notch",
    },
    {
      name: "Follow up",
      url: "/Followup",
      icon: "fa fa-arrow-up",
    },
    {
      name: "Quotations",
      url: "/Quotation",
      icon: "fa fa-clipboard",
    },
    {
      name: "Expenses",
      url: "/expense",
      icon: "fa fa-book",
    },
    {
      name: "Payments",
      url: "/payments",
      icon: "fa fa-book",
    },
    {
      name: "Invoices",
      url: "/invoices",
      icon: "fa fa-file",
    },
    {
      name: "My Earnings",
      url: "/myearnings",
      icon: "fa fa-credit-card",
    },
    {
      name: "Withdrawal Limit",
      url: "/WithdrawalLimit",
      icon: "fa fa-thumb-tack",
    },
    {
      name: "Incentive Bucket",
      url: "/incentivebucket",
      icon: "fa fa-bitbucket",
    },
    {
      name: "Support",
      url: "/support",
      icon: "fa fa-user-circle",
    },
  ],
};

export const navDistrbtrRegionalMngr = {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
    },
    {
      name: "My Sales Network",
      url: "/mysalesnetwork",
      icon: "fa fa-wrench",
      children: [
        {
          name: "Zone",
          url: "/mysalesnetwork/zone",
          icon: "icon-location-pin",
        },
        {
          name: "Area",
          url: "/mysalesnetwork/area",
          icon: "icon-location-pin",
        },
        {
          name: "Users",
          url: "/mysalesnetwork/users",
          icon: "fa fa-users",
        },
      ],
    },
    {
      name: "Products",
      url: "/product",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Customers",
      url: "/customers",
      icon: "fa fa-users",
    },
    {
      name: "Orders",
      url: "/order",
      icon: "fa fa-truck",
    },
    // {
    //   name: "Sales Return",
    //   url: "/salesreturn",
    //   icon: "fa fa-trophy",
    // },
    {
      name: "Leads",
      url: "/lead",
      icon: "fa fa-circle-o-notch",
    },
    {
      name: "Follow up",
      url: "/Followup",
      icon: "fa fa-arrow-up",
    },
    {
      name: "Quotations",
      url: "/Quotation",
      icon: "fa fa-clipboard",
    },

    {
      name: "Payments",
      url: "/payments",
      icon: "fa fa-book",
    },

    {
      name: "My Earnings",
      url: "/myearnings",
      icon: "fa fa-credit-card",
    },
    {
      name: "Withdrawal Request",
      url: "/withdrawalrequest",
      icon: "cui-calculator ",
    },
    {
      name: "Support",
      url: "/support",
      icon: "fa fa-user-circle",
    },
  ],
};

export const navDistrbtrZonalMngr = {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
    },
    {
      name: "My Sales Network",
      url: "/mysalesnetwork",
      icon: "fa fa-wrench",
      children: [
        {
          name: "Area",
          url: "/mysalesnetwork/area",
          icon: "icon-location-pin",
        },
        {
          name: "Users",
          url: "/mysalesnetwork/users",
          icon: "fa fa-users",
        },
      ],
    },
    {
      name: "Products",
      url: "/product",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Customers",
      url: "/customers",
      icon: "fa fa-users",
    },
    {
      name: "Orders",
      url: "/order",
      icon: "fa fa-truck",
    },
    // {
    //   name: "Sales Return",
    //   url: "/salesreturn",
    //   icon: "fa fa-trophy",
    // },
    {
      name: "Leads",
      url: "/lead",
      icon: "fa fa-circle-o-notch",
    },
    {
      name: "Follow up",
      url: "/Followup",
      icon: "fa fa-arrow-up",
    },
    {
      name: "Quotations",
      url: "/Quotation",
      icon: "fa fa-clipboard",
    },

    {
      name: "Payments",
      url: "/payments",
      icon: "fa fa-book",
    },

    {
      name: "My Earnings",
      url: "/myearnings",
      icon: "fa fa-credit-card",
    },
    {
      name: "Withdrawal Request",
      url: "/withdrawalrequest",
      icon: "cui-calculator ",
    },
    {
      name: "Support",
      url: "/support",
      icon: "fa fa-user-circle",
    },
  ],
};

export const navDistrbtrAreaMngr = {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
    },
    {
      name: "My Sales Network",
      url: "/mysalesnetwork",
      icon: "fa fa-wrench",
      children: [
        {
          name: "Users",
          url: "/mysalesnetwork/users",
          icon: "fa fa-users",
        },
      ],
    },
    {
      name: "Products",
      url: "/product",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Customers",
      url: "/customers",
      icon: "fa fa-users",
    },
    {
      name: "Orders",
      url: "/order",
      icon: "fa fa-truck",
    },
    // {
    //   name: "Sales Return",
    //   url: "/salesreturn",
    //   icon: "fa fa-trophy",
    // },
    {
      name: "Leads",
      url: "/lead",
      icon: "fa fa-circle-o-notch",
    },
    {
      name: "Follow up",
      url: "/Followup",
      icon: "fa fa-arrow-up",
    },

    {
      name: "Quotations",
      url: "/Quotation",
      icon: "fa fa-clipboard",
    },

    {
      name: "Payments",
      url: "/payments",
      icon: "fa fa-book",
    },

    {
      name: "My Earnings",
      url: "/myearnings",
      icon: "nav-icon fa fa-credit-card",
    },
    {
      name: "Withdrawal Request",
      url: "/withdrawalrequest",
      icon: "cui-calculator ",
    },
    {
      name: "Support",
      url: "/support",
      icon: "fa fa-user-circle",
    },
  ],
};
