import {
  FETCH_QUOTATIONS_SUCCESS_ACTION,
  FETCH_QUOTATIONS_INIT_ACTION,
  FETCH_QUOTATIONSBYID_INIT_ACTION,
  FETCH_QUOTATIONSBYID_SUCCESS_ACTION,
  FETCH_QUOTATIONSBYID_FAIL_ACTION,
  // FETCH_AREAS__DROPDOWN_SUCCESS_ACTION
  EDIT_QUOTATION_SUCCESS_ACTION,
  CHANGE_QUOTATION_STATUS_SUCCESS_ACTION,
  FETCH_QUOTATION_FIELD_SUGGESTION_SUCCESS_ACTION,
} from "./action";

const initialState = {
  processing: true,
  error: false,
  loading: true,
};

export default function QuotationsReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    case FETCH_QUOTATIONS_INIT_ACTION:
      return {
        ...state,
        processing: true,
        error: false,
      };
    case FETCH_QUOTATIONS_SUCCESS_ACTION:
      return {
        ...state,
        QuotationsList: action.payload.rows,
        QuotationsCount: action.payload.count,
        processing: false,
        error: false,
      };

    case FETCH_QUOTATIONSBYID_INIT_ACTION:
      return {
        ...state,
        loading: true,
      };
    case FETCH_QUOTATIONSBYID_SUCCESS_ACTION:
      return {
        ...state,
        QuotationsDtls: action.payload,
        loading: false,
        imageData: action.imageData,
      };
    case EDIT_QUOTATION_SUCCESS_ACTION:
      return {
        ...state,
        QuotationsList: state.QuotationsList.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case CHANGE_QUOTATION_STATUS_SUCCESS_ACTION:
      return {
        ...state,
        QuotationsList: state.QuotationsList.map((item) =>
          item.id === action.payload.id
            ? { ...item, status: action.payload.status }
            : item
        ),
      };
    case FETCH_QUOTATION_FIELD_SUGGESTION_SUCCESS_ACTION:
      return {
        ...state,
        fieldSuggestionList: action.fieldSuggestions,
      };

    default:
      return {
        ...state,
      };
  }
}
