import { put, takeEvery, select, call, all } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_SUBCATEGORYLIST_INIT_ACTION,
  FETCH_SUBCATEGORYLIST_SUCCESS_ACTION,
  FETCH_SUBCATEGORYLIST_FAIL_ACTION,
  APPROVE_PRODUCT_INIT_ACTION,
  APPROVE_PRODUCT_SUCCESS_ACTION,
  APPROVE_PRODUCT_FAIL_ACTION,
  ADD_PRODUCTS_INIT_ACTION,
  ADD_PRODUCTS_SUCCESS_ACTION,
  ADD_PRODUCTS_FAIL_ACTION,
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as selectors from "../../service/selectors";
import { shallow } from "enzyme";

function* fetchSubCatgry(value) {
  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/subCtgry?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_SUBCATEGORYLIST_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_SUBCATEGORYLIST_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchCntryCrncy() {
  const token = localStorage.getItem("sStraitToken");
  let countryId = JSON.parse(localStorage.getItem("sStraitUserDtls")).country;
  try {
    const res = yield fetch(`${appConfig.ip}/countryCrncy?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let resData = resJSON.rows.find((e) => e.countryId.id === countryId);
      let crncyId = resData.currencyId.id;

      return crncyId;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* fetchRegion() {
  const token = localStorage.getItem("sStraitToken");
  const licenseeId = JSON.parse(
    localStorage.getItem("sStraitUserDtls")
  ).licenseeId;
  try {
    const res = yield fetch(
      `${appConfig.ip}/region?limit=100&isActive=true&dummy=false&licenseeId=${licenseeId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let regionId = resJSON.rows.map((x) => x.id);

      return regionId;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* ApproveProduct(value) {
  // let value = value.data.value;

  const token = localStorage.getItem("sStraitToken");
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const [crncyId] = yield all([call(fetchCntryCrncy)]);
  const [regionsId] = yield all([call(fetchRegion)]);
  const [zoneIds, areaIds] = yield all([
    call(fetchZoneIds, value),
    call(fetchAreaIds, value),
  ]);
  // console.log("values are", value);

  const productImpData = {
    licenseeId: userDetails.licenseeId,
    name: value.value.name,
    ctgryId: value.value.ctgryId,
    subCtgryId: value.value.subcategory,
    PrdtType: value.value.PrdtType,
    gstSlab: value.value.gstSlab,
    buyingPrice: value.value.buyingPrice,
    sellingPrice: value.value.sellingPrice,
    unit: value.value.unit,
    quantity: value.value.quantity,
    status: "active",
    agency: value.value.agency,
    commission: {
      platform: value.value.platform,
      regionManger: value.value.regionManger,
      zoneManger: value.value.zoneManger,
      areaManger: value.value.areaManger,
      salesExecutive: value.value.salesExecutive,
      company: value.value.company,
      incentivesbucket: value.value.incentivesbucket,
      agent: 0,
    },
    backOrder: value.value.backOrder,
    currency: crncyId,
    // areaRef: value.value.ref,
    region: regionsId,
    zone: zoneIds,
    area: areaIds,
  };

  try {
    const res = yield fetch(`${appConfig.ip}/product`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(productImpData),
    });

    if (!res.ok) {
      let errJSON1 = {};
      try {
        errJSON1 = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON1);
    } else {
      const resJSON = yield res.json();

      yield put({ type: "FETCH_PRODUCTS_INIT_ACTION", value: { page: 1 } });

      yield put({
        type: APPROVE_PRODUCT_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Product added successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: APPROVE_PRODUCT_FAIL_ACTION,
        error: err,
      });
    }
  }
}

// Common functions

function* fetchZoneIds(value) {
  const token = localStorage.getItem("sStraitToken");
  let licId = JSON.parse(localStorage.getItem("sStraitUserDtls")).licenseeId;

  try {
    const res = yield fetch(
      `${appConfig.ip}/zone?limit=100&isActive=true&dummy=false` +
        "&licenseeId=" +
        licId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let zoneRes =
        value.ref === "region"
          ? resJSON.rows.map((x) => x.id)
          : resJSON.rows.map((x) => x.region.id);

      return zoneRes;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* fetchAreaIds(value) {
  let Val = value.value.value;

  const token = localStorage.getItem("sStraitToken");
  let licId = JSON.parse(localStorage.getItem("sStraitUserDtls")).licenseeId;

  try {
    const res = yield fetch(
      `${appConfig.ip}/area?limit=100&isActive=true&dummy=false` +
        "&licenseeId=" +
        licId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let AreaRes =
        value.ref === "area" ? resJSON : resJSON.rows.map((x) => x.id);
      return AreaRes;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

export function* ImportProdActionWatcher() {
  yield takeEvery(FETCH_SUBCATEGORYLIST_INIT_ACTION, fetchSubCatgry);
  yield takeEvery(APPROVE_PRODUCT_INIT_ACTION, ApproveProduct);
}
