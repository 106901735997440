import {
    FETCH_PAYMENTS_SUCCESS_ACTION,
    FETCH_PAYMENTS_INIT_ACTION,
    FETCH_PAYMENTSBYID_INIT_ACTION,
    FETCH_PAYMENTSBYID_SUCCESS_ACTION,
    FETCH_PAYMENTSBYID_FAIL_ACTION,
    // FETCH_AREAS__DROPDOWN_SUCCESS_ACTION

} from "./action";

const initialState = {
    processing: true,
    error: false,
    loading: true
};

export default function PaymentsReducer(
    state: any = initialState,
    action: Function
) {
    switch (action.type) {
        case FETCH_PAYMENTS_INIT_ACTION:
            return {
                ...state,
                processing: true,
                error: false,
            };
        case FETCH_PAYMENTS_SUCCESS_ACTION:
            return {
                ...state,
                paymentsList: action.payload.rows,
                paymentsCount: action.payload.count,
                processing: false,
                error: false,
            };

            case FETCH_PAYMENTSBYID_SUCCESS_ACTION:
                return {
                    ...state,
                    paymentsDtls: action.payload,
                    processing: false,
                    error: false,
                    loading: false
                };
               
                    // case FETCH_AREAS__DROPDOWN_SUCCESS_ACTION:
                    //     return {
                    //         ...state,
                    //         areasDropdownList: action.payload.rows,
                    //     };

        default:
            return {
                ...state,
            };
    }
}
