

export const IMPORT_PRODUCT_INIT_ACTION = "IMPORT_PRODUCT_INIT_ACTION";
export const IMPORT_PRODUCT_SUCCESS_ACTION = IMPORT_PRODUCT_INIT_ACTION + "_SUCCESS";
export const IMPORT_PRODUCT_FAIL_ACTION = IMPORT_PRODUCT_INIT_ACTION + "_ERROR";


export const FETCH_SUBCATEGORYLIST_INIT_ACTION = "FETCH_SUBCATEGORYLIST_INIT_ACTION";
export const FETCH_SUBCATEGORYLIST_SUCCESS_ACTION =
    FETCH_SUBCATEGORYLIST_INIT_ACTION + "_SUCCESS";
export const FETCH_SUBCATEGORYLIST_FAIL_ACTION = FETCH_SUBCATEGORYLIST_INIT_ACTION + "_ERROR";

export const APPROVE_PRODUCT_INIT_ACTION = "APPROVE_PRODUCT_INIT_ACTION";
export const APPROVE_PRODUCT_SUCCESS_ACTION = APPROVE_PRODUCT_INIT_ACTION + "_SUCCESS";
export const APPROVE_PRODUCT_FAIL_ACTION = APPROVE_PRODUCT_INIT_ACTION + "_ERROR";




export function importData(value) {
    return {
        type: IMPORT_PRODUCT_INIT_ACTION,
        value: value
    };
}



export function fetchSubctgryList(value) {
    return {
        type: FETCH_SUBCATEGORYLIST_INIT_ACTION,
        value: value
    };
}

export function ApproveProduct(value) {
    return {
        type: APPROVE_PRODUCT_INIT_ACTION,
        value: value
    };
}
