export const FETCH_INVENTORY_INIT_ACTION = "FETCH_INVENTORY_INIT_ACTION";
export const FETCH_INVENTORY_SUCCESS_ACTION =
  FETCH_INVENTORY_INIT_ACTION + "_SUCCESS";
export const FETCH_INVENTORY_FAIL_ACTION =
  FETCH_INVENTORY_INIT_ACTION + "_ERROR";

// export const FETCH_PRODUCTS_INV_INIT_ACTION = "FETCH_PRODUCTS_INV_INIT_ACTION";
// export const FETCH_PRODUCTS_INV_SUCCESS_ACTION = FETCH_PRODUCTS_INV_INIT_ACTION + "_SUCCESS";
// export const FETCH_PRODUCTS_INV_FAIL_ACTION = FETCH_PRODUCTS_INV_INIT_ACTION + "_ERROR";

// export const ADD_PRODUCTS_INV_INIT_ACTION = "ADD_PRODUCTS_INV_INIT_ACTION";
// export const ADD_PRODUCTS_INV_SUCCESS_ACTION =
//   ADD_PRODUCTS_INV_INIT_ACTION + "_SUCCESS";
// export const ADD_PRODUCTS_INV_FAIL_ACTION =
//   ADD_PRODUCTS_INV_INIT_ACTION + "_ERROR";

// export const EDIT_PRODUCTS_INV_INIT_ACTION = "EDIT_PRODUCTS_INV_INIT_ACTION";
// export const EDIT_PRODUCTS_INV_SUCCESS_ACTION =
//   EDIT_PRODUCTS_INV_INIT_ACTION + "_SUCCESS";
// export const EDIT_PRODUCTS_INV_FAIL_ACTION =
//   EDIT_PRODUCTS_INV_INIT_ACTION + "_ERROR";

export function fetchProductInventory(value) {
  return {
    type: FETCH_INVENTORY_INIT_ACTION,
    value: value,
  };
}

// export function fetchProductInv(value) {

//   return {
//     type: FETCH_PRODUCTS_INV_INIT_ACTION,
//     value: value

//   };
// }

// export function AddProductInv(value) {
//   return {
//     type: ADD_PRODUCTS_INV_INIT_ACTION,
//     value: value,
//   };
// }

// export function EditProductInv(value) {
//   return {
//     type: EDIT_PRODUCTS_INV_INIT_ACTION,
//     value: value,
//   };
// }
