import { put, takeEvery, call } from "redux-saga/effects";
import * as actionTypes from "./constants";

import { appConfig } from "../../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
var dateFormat = require("dateformat");

// common role wise url get
function* getRoleApiUrl() {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const role = localStorage.getItem("sStraitUserRole");
  let getDataUrl = [
    { role: "licensee", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "distributor", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "licenseeAccountant", url: "&licenseeId=" + userDtls.licenseeId },
    {
      role: "regionalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&region=" + userDtls.region,
    },
    {
      role: "zonalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&zone=" + userDtls.zone,
    },
    {
      role: "areaManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
    {
      role: "salesExcecutive",
      url:
        "&licenseeId=" + userDtls.licenseeId + "&salesExecutive=" + userDtls.id,
    },
    {
      role: "agent",
      url:
        "&licenseeId=" + userDtls.licenseeId + "&salesExecutive=" + userDtls.id,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);
  return apiUrl;
}

function* commonFunction(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: `${value.failAction}`, error: err });
    } else {
    }
  }
}

function* fetchCreditnoteReport(value) {
  let page = (value && value.value && value.value.page) || 1;

  const apiUrl = yield call(getRoleApiUrl);
  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? null
      : value.value.searchVal;
  let productFilter =
    !value || !value.value || !value.value.productFilter
      ? null
      : value.value.productFilter;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let filterValDate =
    !value || !value.value || !value.value.dateFilter
      ? null
      : value.value.dateFilter;

  let custFilter =
    !value || !value.value || !value.value.custFilter
      ? null
      : value.value.custFilter;

  let _url = `${appConfig.ip}/creditNote?limit=10&page=${page}${apiUrl.url}`;

  if (searchVal) {
    _url = `${_url}&&search=${searchVal}`;
  }
  if (productFilter) {
    _url = `${_url}&products=${productFilter}`;
  }
  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  }
  if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  }
  if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }
  if (filterValDate) {
    _url = `${_url}${filterValDate}`;
  }
  if (custFilter) {
    _url = `${_url}&customer=${custFilter}`;
  }
  try {
    let params = {
      api: _url,
      method: "GET",
      sucessAction: actionTypes.FETCH_CREDITNOTE_REPORT_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_CREDITNOTE_REPORT_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);

  } catch (e) { }
}
function* fetchCreditNoteReportById(value) {

  try {
    let params = {
      api: `${appConfig.ip}/creditNote/` + value.id,
      method: "GET",
      sucessAction: actionTypes.FETCH_CREDITNOTE_REPORT_BY_ID_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_CREDITNOTE_REPORT_BY_ID_FAIL_ACTION,
      body: null,
    };
    const Res = yield call(commonFunction, params);
    return Res;
  } catch (e) { }
}



export function* creditnoteReportActionWatcher() {
  yield takeEvery(
    actionTypes.FETCH_CREDITNOTE_REPORT_INIT_ACTION,
    fetchCreditnoteReport
  );
  yield takeEvery(
    actionTypes.FETCH_CREDITNOTE_REPORT_BY_ID_INIT_ACTION,
    fetchCreditNoteReportById
  );
}
