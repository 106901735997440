import React from "react";
import { ErrorMessage, useField } from "formik";
import "./cmnStyle.css";

export const TextField = ({ label, isRequired, ...props }) => {
  const [field, meta] = useField(props);
  // console.log("##############",field)
  // console.log("##############",meta)
  return (
    <div className="mb-2">
      <label htmlFor={field.name} className="label-inpt">
        {label} {isRequired && <span className="astrk">*</span>}
      </label>

      <input
        className={`form-control shadow-none `}
        {...field}
        {...props}
        autoComplete="off"
      />
      {/* {!meta.touched || meta.error?(
      <ErrorMessage component="div" name={field.name} className="error" />
        ):null} */}
      <ErrorMessage component="div" name={field.name} className="errStyle" />
    </div>
  );
};
