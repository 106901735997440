export const FETCH_FOLLOWUP_INIT_ACTION = "FETCH_FOLLOWUP_INIT_ACTION";
export const FETCH_FOLLOWUP_SUCCESS_ACTION =
    FETCH_FOLLOWUP_INIT_ACTION + "_SUCCESS";
export const FETCH_FOLLOWUP_FAIL_ACTION = FETCH_FOLLOWUP_INIT_ACTION + "_ERROR";

export const FETCH_FOLLOWUPBYID_INIT_ACTION = "FETCH_FOLLOWUPBYID_INIT_ACTION";
export const FETCH_FOLLOWUPBYID_SUCCESS_ACTION = FETCH_FOLLOWUPBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_FOLLOWUPBYID_FAIL_ACTION = FETCH_FOLLOWUPBYID_INIT_ACTION + "_ERROR";

export const CHANGESTATUS_FOLLOWUP_INIT_ACTION =
    "CHANGESTATUS_FOLLOWUP_INIT_ACTION";
export const CHANGESTATUS_FOLLOWUP_SUCCESS_ACTION =
    CHANGESTATUS_FOLLOWUP_INIT_ACTION + "_SUCCESS";
export const CHANGESTATUS_FOLLOWUP_FAIL_ACTION =
    CHANGESTATUS_FOLLOWUP_INIT_ACTION + "_ERROR";

export const EDIT_RESCHEDULE_INIT_ACTION = "EDIT_RESCHEDULE_INIT_ACTION";
export const EDIT_RESCHEDULE_SUCCESS_ACTION =
    EDIT_RESCHEDULE_INIT_ACTION + "_SUCCESS";
export const EDIT_RESCHEDULE_FAIL_ACTION = EDIT_RESCHEDULE_INIT_ACTION + "_ERROR";


export function fetchFollowup(value) {
    return {
        type: FETCH_FOLLOWUP_INIT_ACTION,
        value: value,

    };
}

export function getFollowupById(value) {
    return {
        type: FETCH_FOLLOWUPBYID_INIT_ACTION,
        value: value
    };
}

export function changeStatusFollowup(value) {
    return {
        type: CHANGESTATUS_FOLLOWUP_INIT_ACTION,
        value: value
    };
}

export function EditReschedule(value) {
    return {
        type: EDIT_RESCHEDULE_INIT_ACTION,
        value: value
    };
}