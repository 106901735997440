import { put, takeEvery, select, call, all } from "redux-saga/effects";
// import { createBrowserHistory } from "history";

import { appConfig } from "../../config";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import * as actionTypes from "./constants";

//common function
function* commonFunction(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: `${value.failAction}`, error: err });
    } else {
    }
  }
}

// function* fetchFormData(value) {
//   let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
//   let pageno = value.value === undefined ? 1 : value.value;

//   try {
//     let params = {
//       api: `${appConfig.ip}/googleForm?licenseeId=${userDtls.licenseeId}&limit=10&page=${pageno}`,
//       method: "GET",
//       sucessAction: actionTypes.FETCH_FORMDATA_SUCCESS_ACTION,
//       failAction: actionTypes.FETCH_FORMDATA_FAIL_ACTION,
//       body: null,
//     };
//     yield call(commonFunction, params);
//   } catch (e) { }
// }

function* AddIndiaMart(value) {
  let userId = JSON.parse(localStorage.getItem("sStraitUserDtls")).id;
  const data = {
    indiamart: {
      crmKey: value.value.crmKey,
      name: value.value.name,
      mobileNo: value.value.mobileNo,
    },
  };

  try {
    let params = {
      api: `${appConfig.ip}/licenseeProf/${userId}`,
      method: "PUT",
      sucessAction: actionTypes.ADD_INDIAMART_SUCCESS_ACTION,
      failAction: actionTypes.ADD_INDIAMART_FAIL_ACTION,
      body: JSON.stringify(data),
      toastMsg: "Added Successfully",
    };
    const res = yield call(commonFunction, params);
    if (res) {
      let profile = {
        ...JSON.parse(localStorage.getItem("sStraitLicProfDtls")),
        ...data,
      };
      localStorage.setItem("sStraitLicProfDtls", JSON.stringify(profile));
    }
  } catch (e) {}
}

export function* IndMartActionWatcher() {
  yield takeEvery(actionTypes.ADD_INDIAMART_INIT_ACTION, AddIndiaMart);
}
