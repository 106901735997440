import * as actionTypes from "./constants";
import { sortData } from "../../../screens/Common/sortData";

const initialState = {
  processing: false,
  error: false,
  quoteConfigurationList: [],
  quoteConfigurationCount: 0,
  quoteConfigurationTypeList: [],

};

export default function quoteConfigurationReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_QUOTE_CONFIGURATION_SUCCESS_ACTION:
      return {
        ...state,
        quoteConfigurationList: action.payload.rows,
        quoteConfigurationCount: action.payload.count,
      };

    default:
      return {
        ...state,
      };
  }
}
