import { put, takeEvery } from "redux-saga/effects";
// import { createBrowserHistory } from "history";
import {
  FETCH_EDIT_INIT_ACTION,
  FETCH_EDIT_SUCCESS_ACTION,
  FETCH_EDIT_FAIL_ACTION,

  EDIT_PROF_INIT_ACTION,
  EDIT_PROF_SUCCESS_ACTION,
  EDIT_PROF_FAIL_ACTION,

  FETCH_COUNTRY_DROPDOWN_IN_LICENSE_INIT_ACTION,
  FETCH_COUNTRY_DROPDOWN_IN_LICENSE_SUCCESS_ACTION,
  FETCH_COUNTRY_DROPDOWN_IN_LICENSE__FAIL_ACTION,
  FETCH_STATE_DROPDOWN_IN_LICENSE_INIT_ACTION,
  FETCH_STATE_DROPDOWN_IN_LICENSE_SUCCESS_ACTION,
  FETCH_STATE_DROPDOWN_IN_LICENSE_FAIL_ACTION,
  FETCH_DISTRICT_DROPDOWN_IN_LICENSE_INIT_ACTION,
  FETCH_DISTRICT_DROPDOWN_IN_LICENSE_SUCCESS_ACTION,
  FETCH_DISTRICT_DROPDOWN_IN_LICENSE_FAIL_ACTION,

} from "./action";

import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// var history = createBrowserHistory();

function* fetchEdit(value) {
  const token = localStorage.getItem("sStraitToken");
  const userId = localStorage.getItem("sStraitUserId");
  try {
    const res = yield fetch(`${appConfig.ip}/userProf/` + userId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_EDIT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_EDIT_FAIL_ACTION, error: err });
    } else {
    }
  }
}
function* Editprof(value) {
  try {
    const token = localStorage.getItem("sStraitToken");
    const userId = localStorage.getItem("sStraitUserId");

    if (value.value.fileimgvalue) {

      const formData = new FormData();

      formData.append(userId, value.value.picture);




      const resFile = yield fetch(`${appConfig.ip}/users/image`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!resFile.ok) {

        throw resFile;
      } else {
        const resJSON = yield resFile.json();

        const LicenseesValue = {
          // email: value.value.email,
          // contactMobile: value.value.contactMobile,

          fName: value.value.fName,
          lName: value.value.lName,

          // orgName: value.value.orgName,

          // country: value.value.country,
          state: value.value.state,
          district: value.value.district,
          city: value.value.city,
          // geo: value.value.geo,
          addr1: value.value.addr1,
          addr2: value.value.addr2,
          picture: value.value.picture,

          picture: resJSON.files,

          zip: value.value.zip,
          // gstin: value.value.gstin,
          // website: value.value.website,



        };
        const res = yield fetch(`${appConfig.ip}/userProf/${userId}`, {

          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(LicenseesValue),
        }
        );

        if (!res.ok) {
          let errJSON = {};
          try {
            errJSON = yield res.json();
          } catch {
            throw Object.assign(res, errJSON);
          }
        } else {
          const resJSON = yield res.json();

          yield put({
            type: FETCH_EDIT_INIT_ACTION,
          });
          yield put(push("/userprofile"));
          yield toast.success("Profile updated successfully", {
            autoClose: 3000,
            transition: Flip

          });

          yield put({
            type: EDIT_PROF_SUCCESS_ACTION,

            payload: resJSON,
          });
        }
      }
    } else {
      const data = {
        email: value.value.email,
        // mobileNo: value.value.mobileNo,

        fullName: value.value.fullName,
        lName: value.value.lName,
        contactPerson: value.value.contactPerson,
        contactEmail: value.value.contactEmail,
        contactMob: value.value.contactMob,
        // orgName: value.value.orgName,

        // country: value.value.country,
        state: value.value.state,
        district: value.value.district,
        city: value.value.city,
        addr1: value.value.addr1,
        addr2: value.value.addr2,
        picture: value.value.picture,
        zip: value.value.zip,
        // gstin: value.value.gstin,
        // website: value.value.website,



      };

      const res = yield fetch(`${appConfig.ip}/userProf/${userId}`, {

        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
      );

      if (!res.ok) {
      } else {
        const resJSON = yield res.json();

        yield put({ type: FETCH_EDIT_INIT_ACTION });
        yield put({
          type: EDIT_PROF_SUCCESS_ACTION,
          payload: resJSON,
        });

        yield put(push("/userprofile"));
        yield toast.success("Profile updated successfully", {
          autoClose: 3000,
          transition: Flip

        });
      }
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: EDIT_PROF_FAIL_ACTION,
        error: err,
      });
    }
  }
}


function* fetchDistrictsDropDown(value) {
  // let stateId = data.stateId || "";
  let stateId = JSON.parse(localStorage.getItem("sStraitLicProfDtls")).state.id;
  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/districts?stateId=${stateId}&isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_DISTRICT_DROPDOWN_IN_LICENSE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_DISTRICT_DROPDOWN_IN_LICENSE_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchCntryCrnvy(value) {
  try {
    const token = localStorage.getItem("sStraitToken");

    const res = yield fetch(`${appConfig.ip}/country?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_COUNTRY_DROPDOWN_IN_LICENSE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_COUNTRY_DROPDOWN_IN_LICENSE__FAIL_ACTION,
        error: err,
      });
    }
  }
}
function* fetchStates(value) {

  let countryId = JSON.parse(localStorage.getItem("sStraitLicProfDtls")).country.id;
  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/state?countryId=${countryId}&isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_STATE_DROPDOWN_IN_LICENSE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_STATE_DROPDOWN_IN_LICENSE_FAIL_ACTION,
        error: err,
      });
    }
  }
}


export function* EditActionWatcher() {
  yield takeEvery(FETCH_EDIT_INIT_ACTION, fetchEdit);
  yield takeEvery(EDIT_PROF_INIT_ACTION, Editprof);
  yield takeEvery(FETCH_COUNTRY_DROPDOWN_IN_LICENSE_INIT_ACTION, fetchCntryCrnvy);
  yield takeEvery(FETCH_STATE_DROPDOWN_IN_LICENSE_INIT_ACTION, fetchStates);
  yield takeEvery(FETCH_DISTRICT_DROPDOWN_IN_LICENSE_INIT_ACTION, fetchDistrictsDropDown);

}
