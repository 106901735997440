import {
  FETCH_PRODUCTTYPE_SUCCESS_ACTION,
  FETCH_PRODUCTS_SUCCESS_ACTION,
  FETCH_PRODUCTS_INIT_ACTION,
  FETCH_CATEGORY_SUCCESS_ACTION,
  FETCH_SUBCATEGORY_SUCCESS_ACTION,
  FETCH_GST_SUCCESS_ACTION,
  FETCH_AREATYPE_SUCCESS_ACTION,
  FETCH_PRODUCTBYID_INIT_ACTION,
  FETCH_PRODUCTBYID_SUCCESS_ACTION,
  FETCH_PRODUCTBYID_FAIL_ACTION,
  EDIT_PRODUCTS_SUCCESS_ACTION,
  FETCH_UNITS_SUCCESS_ACTION,
  CHANGE_PRODUCT_STATUS_SUCCESS_ACTION,
  DELETE_PRODUCT_SUCCESS_ACTION,
  FETCH_AGENCYLIST_SUCCESS_ACTION,
  ADD_VARIANT_INIT_ACTION,
  CLEAR_VARIANT_INIT_ACTION,
  // FILTERSEARCH_PRODUCT_SUCCESS_ACTION
} from "./action";
import { sortData } from "../../screens/Common/sortData";

const initialState = {
  processing: true,
  error: false,
  loading: true,
  // agency1: [{ id: "", name: "All" }],
  variantList: [],
};

export default function RegionReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    case FETCH_PRODUCTS_INIT_ACTION:
      return {
        ...state,
        processing: true,
        error: false,
        variantList: [],
      };
    case FETCH_PRODUCTS_SUCCESS_ACTION:
      return {
        ...state,
        productsList: action.payload.rows,
        productsCount: action.payload.count,
        processing: false,
        error: false,
      };

    case FETCH_PRODUCTTYPE_SUCCESS_ACTION:
      return {
        ...state,
        productTypeList: sortData(action.payload.rows),
      };
    case FETCH_CATEGORY_SUCCESS_ACTION:
      return {
        ...state,
        categoryList: sortData(action.payload.rows),
      };
    case FETCH_SUBCATEGORY_SUCCESS_ACTION:
      return {
        ...state,
        subcategoryList: sortData(action.payload.rows),
      };
    case FETCH_GST_SUCCESS_ACTION:
      return {
        ...state,
        GstList: action.payload.rows,
      };
    case FETCH_UNITS_SUCCESS_ACTION:
      return {
        ...state,
        unitList: sortData(action.payload.rows),
      };
    case FETCH_AREATYPE_SUCCESS_ACTION:
      return {
        ...state,
        regionList: action.payload.rows,
      };
    case FETCH_PRODUCTBYID_INIT_ACTION:
      return {
        ...state,
        processing: true,
        error: false,
        loading: true,
        variantList: [],
      };
    case FETCH_PRODUCTBYID_SUCCESS_ACTION:
      return {
        ...state,
        productDtls: action.payload,
        processing: false,
        error: false,
        loading: false,
        variantList:
          action.payload.variants && action.payload.variants.length != 0
            ? action.payload.variants
            : [],
      };
    case EDIT_PRODUCTS_SUCCESS_ACTION:
      return {
        ...state,
        productsList: state.productsList.map((product) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case CHANGE_PRODUCT_STATUS_SUCCESS_ACTION:
      return {
        ...state,
        productsList: state.productsList.map((product) =>
          product.id === action.payload.id
            ? { ...product, status: action.payload.status }
            : product
        ),
      };
    case DELETE_PRODUCT_SUCCESS_ACTION:
      return {
        ...state,
        productsList: state.productsList.filter(
          (item) => item.id !== action.payload
        ),
        productsCount: state.productsCount - 1,
      };
    case FETCH_AGENCYLIST_SUCCESS_ACTION:
      return {
        ...state,
        AgencysList: sortData(action.payload.rows),
        // AgencysFltrList : state.agency1.concat(action.payload.rows)
      };
    // case FILTERSEARCH_PRODUCT_SUCCESS_ACTION:
    //     return {
    //         ...state,
    //         productsList: action.payload.rows,
    //         productsCount: action.payload.count,
    //         processing: false,
    //         error: false,
    //     };
    case ADD_VARIANT_INIT_ACTION:
      return {
        ...state,
        variantList: action.value,
      };
    case CLEAR_VARIANT_INIT_ACTION:
      return {
        ...state,
        variantList: [],
      };

    default:
      return {
        ...state,
      };
  }
}
