import { put, takeEvery, call } from "redux-saga/effects";
//import { createBrowserHistory } from "history";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { push } from "connected-react-router";

import {
  FETCH_DASHBOARD_INIT_ACTION,
  FETCH_DASHBOARD_SUCCESS_ACTION,
  FETCH_DASHBOARD_FAIL_ACTION,
  FETCH_USERSTATUS_INIT_ACTION,
  FETCH_USERSTATUS_SUCCESS_ACTION,
  FETCH_USERSTATUS_FAIL_ACTION,
  FETCH_LICENSEESTATUS_INIT_ACTION,
  FETCH_LICENSEESTATUS_SUCCESS_ACTION,
  FETCH_LICENSEESTATUS_FAIL_ACTION,
  LOGOUTUSER_ACTION,
} from "./action";

//var btoa = require("Base64").btoa;
//var history = createBrowserHistory();

function* fetchDashboardList() {
  const token = localStorage.getItem("sStraitToken");
  const role = localStorage.getItem("sStraitUserRole");
  // let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let liceData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  let type = liceData.type;
  let getDataUrl = [
    { role: "licensee", url: "/forLicensee" },
    {
      role: "licenseeAccountant",
      url: type === "licensee" ? "/forLicensee" : "/forDistributor",
    },
    { role: "regionalManager", url: "/forRegionalManager" },
    { role: "zonalManager", url: "/forZonalManager" },
    { role: "areaManager", url: "/forAreaManager" },
    { role: "salesExcecutive", url: "/forSalesExcecutive" },
    { role: "distributor", url: "/forDistributor" },
    { role: "agent", url: "/forAgent" },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  try {
    const res = yield fetch(`${appConfig.ip}/count` + apiUrl.url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_DASHBOARD_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_DASHBOARD_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* logOutUsers() {
  yield localStorage.removeItem("sStraitToken");
  yield localStorage.removeItem("sStraitUserRole");
  yield localStorage.removeItem("sStraitUserId");
  yield localStorage.removeItem("sStraitUserDtls");
  yield localStorage.removeItem("sStraitLicProfDtls");
  yield localStorage.removeItem("sStraitCurrency");
}

function* fetchUserStatus(value) {
  const token = localStorage.getItem("sStraitToken");
  const id = localStorage.getItem("sStraitUserId");

  try {
    const res = yield fetch(`${appConfig.ip}/users/me?id=` + id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_USERSTATUS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.status === 403) {
      yield put(push("/Expired"));
    }
    if (err.ok === false) {
      yield put({ type: FETCH_USERSTATUS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchLicenseeStatus(value) {
  const token = localStorage.getItem("sStraitToken");
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licenseeId = userDetails.licenseeId;
  try {
    const res = yield fetch(
      `${appConfig.ip}/users/licenseeStatus?licenseeId=` + licenseeId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      if (resJSON.status === "suspended") {
        yield put(push("/Expired"));
      }
      yield put({
        type: FETCH_LICENSEESTATUS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_LICENSEESTATUS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* dashboardActionWatcher() {
  yield takeEvery(FETCH_DASHBOARD_INIT_ACTION, fetchDashboardList);
  yield takeEvery(FETCH_USERSTATUS_INIT_ACTION, fetchUserStatus);
  yield takeEvery(LOGOUTUSER_ACTION, logOutUsers);
  yield takeEvery(FETCH_LICENSEESTATUS_INIT_ACTION, fetchLicenseeStatus);
}
