export const FETCH_INVOICE_CONFIGURATION_INIT_ACTION =
  "FETCH_INVOICE_CONFIGURATION_INIT_ACTION";
export const FETCH_INVOICE_CONFIGURATION_SUCCESS_ACTION =
  FETCH_INVOICE_CONFIGURATION_INIT_ACTION + "_SUCCESS";
export const FETCH_INVOICE_CONFIGURATION_FAIL_ACTION =
  FETCH_INVOICE_CONFIGURATION_INIT_ACTION + "_ERROR";

export const ADD_INVOICE_CONFIGURATION_INIT_ACTION =
  "ADD_INVOICE_CONFIGURATION_INIT_ACTION";
export const ADD_INVOICE_CONFIGURATION_SUCCESS_ACTION =
  ADD_INVOICE_CONFIGURATION_INIT_ACTION + "_SUCCESS";
export const ADD_INVOICE_CONFIGURATION_FAIL_ACTION =
  ADD_INVOICE_CONFIGURATION_INIT_ACTION + "_ERROR";

export const DELETE_INVOICE_CONFIGURATION_INIT_ACTION =
  "DELETE_INVOICE_CONFIGURATION_INIT_ACTION";
export const DELETE_INVOICE_CONFIGURATION_SUCCESS_ACTION =
  DELETE_INVOICE_CONFIGURATION_INIT_ACTION + "_SUCCESS";
export const DELETE_INVOICE_CONFIGURATION_FAIL_ACTION =
  DELETE_INVOICE_CONFIGURATION_INIT_ACTION + "_ERROR";

export const EDIT_INVOICE_CONFIGURATION_INIT_ACTION =
  "EDIT_INVOICE_CONFIGURATION_INIT_ACTION";
export const EDIT_INVOICE_CONFIGURATION_SUCCESS_ACTION =
  EDIT_INVOICE_CONFIGURATION_INIT_ACTION + "_SUCCESS";
export const EDIT_INVOICE_CONFIGURATION_FAIL_ACTION =
  EDIT_INVOICE_CONFIGURATION_INIT_ACTION + "_ERROR";

export const FETCH_BANK_IN_INVOICE_CONFIGURATION_INIT_ACTION =
  " FETCH_BANK_IN_INVOICE_CONFIGURATION_INIT_ACTION";
export const FETCH_BANK_IN_INVOICE_CONFIGURATION_SUCCESS_ACTION =
  FETCH_BANK_IN_INVOICE_CONFIGURATION_INIT_ACTION + "SUCCESS";
export const FETCH_BANK_IN_INVOICE_CONFIGURATION_FAIL_ACTION =
  FETCH_BANK_IN_INVOICE_CONFIGURATION_INIT_ACTION + "FAIL";
