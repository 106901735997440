import {
    FETCH_AGENCY_SUCCESS_ACTION,
    FETCH_AGENCY_INIT_ACTION,
    EDIT_AGENCY_SUCCESS_ACTION,
    CHANGE_AGENCY_STATUS_SUCCESS_ACTION,
    DELETE_AGENCY_SUCCESS_ACTION
} from "./action";

const initialState = {
    processing: true,
    error: false,
    loading: true
};

export default function PaymentsReducer(
    state: any = initialState,
    action: Function
) {
    switch (action.type) {
        case FETCH_AGENCY_INIT_ACTION:
            return {
                ...state,
                processing: true,
                error: false,
            };
        case FETCH_AGENCY_SUCCESS_ACTION:
            return {
                ...state,
                agencyList: action.payload.rows,
                agencyCount: action.payload.count,
                processing: false,
                error: false,
            };
        case EDIT_AGENCY_SUCCESS_ACTION:
            return {
                ...state,
                agencyList: state.agencyList.map((agency) =>
                    agency.id === action.payload.id ? action.payload : agency
                ),
            };
        case CHANGE_AGENCY_STATUS_SUCCESS_ACTION:
            return {
                ...state,
                agencyList: state.agencyList.map((agency) =>
                    agency.id === action.payload.id ? { ...agency, status: action.payload.status } : agency
                ),
            };
        case DELETE_AGENCY_SUCCESS_ACTION:
            return {
                ...state,
                agencyList: state.agencyList.filter(
                    (item) => item.id !== action.payload
                ),
                agencyCount: state.agencyCount - 1
            };

        default:
            return {
                ...state,
            };
    }
}
