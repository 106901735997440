import { put, takeEvery, call } from "redux-saga/effects";
import * as actionTypes from "./constants";

import { appConfig } from "../../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//common function
function* commonFunction(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();
      yield put({ type: `${value.initAction}` });
      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: `${value.failAction}`, error: err });
    } else {
    }
  }
}

function* fetchExpense(params) {
  let page = (params.params && params.params.page) || 1;
  let expenseTypeFilter =
    (params.params && params.params.expenseTypeFilter) || "";
  let dateFilter = (params.params && params.params.dateFilter) || "";

  let currentUser = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licensee = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  let role = localStorage.getItem("sStraitUserRole");

  let agentFilterParam = "";
  let licenseeFilterparam = "";
  let expenseTypeFilterParam = "";
  let salesExcecutiveParam = "";
  let licenseeAccountantParam = "";

  if (currentUser.role === "agent") {
    agentFilterParam = `&agent=${currentUser.id}`;
  }
  if (currentUser.role === "salesExcecutive") {
    salesExcecutiveParam = `&agent=${currentUser.id}`;
  }
  if (role === "distributor") {
    licenseeFilterparam = `&licenseeId=${licensee.id}`;
  }
  if (role === "licenseeAccountant") {
    licenseeAccountantParam = `&licenseeId=${licensee.id}`;
  }
  if (expenseTypeFilter) {
    expenseTypeFilterParam = `&expenseType=${expenseTypeFilter}`;
  }

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/expense?isActive=true&limit=10&page=${page}${agentFilterParam}${licenseeFilterparam}${expenseTypeFilterParam}${dateFilter}${salesExcecutiveParam}${licenseeAccountantParam}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_EXPENSE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_EXPENSE_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* addExpense(value) {
  let userData = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let currentCurrency = JSON.parse(localStorage.getItem("sStraitCurrency"));

  let data = {
    licenseeId: (userData && userData.licenseeId) || null,
    distributor: (userData && userData.author) || null,
    agent: (userData && userData.id) || null,
    currency:
      (currentCurrency &&
        currentCurrency.currencyId &&
        currentCurrency.currencyId.id) ||
      null,

    amount: (value.value && value.value.amount) || "",
    expenseType: (value.value && value.value.expenseType) || null,
    description: (value.value && value.value.description) || "",
  };

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/expense`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();
      yield put({
        type: actionTypes.ADD_EXPENSE_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Expense  added successfully", {
        autoClose: 3000,
      });
      yield put({
        type: actionTypes.FETCH_EXPENSE_INIT_ACTION,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.ADD_EXPENSE_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* editExpense(value) {
  let userData = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let currentCurrency = JSON.parse(localStorage.getItem("sStraitCurrency"));

  let id = value.id || "";
  let data = {
    licenseeId: (userData && userData.licenseeId) || null,
    distributor: (userData && userData.author) || null,
    agent: (userData && userData.id) || null,
    currency:
      (currentCurrency &&
        currentCurrency.currencyId &&
        currentCurrency.currencyId.id) ||
      null,

    amount: (value.value && value.value.amount) || "",
    expenseType: (value.value && value.value.expenseType) || null,
    description: (value.value && value.value.description) || "",
  };
  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/expense/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield toast.success("Expense  edited successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();
      // yield put({ type: actionTypes.FETCH_EXPENSE_INIT_ACTION });
      const resJSONById = yield call(fetchExpenseById, resJSON.id);
      // yield put({
      //   type: actionTypes.EDIT_EXPENSE_SUCCESS_ACTION,
      //   payload: resJSONById,
      // });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.EDIT_EXPENSE_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* deleteExpense(params) {
  let id = params.data.id;

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/expense/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield put({ type: actionTypes.FETCH_EXPENSE_INIT_ACTION });

      yield put({
        type: actionTypes.DELETE_EXPENSE_SUCCESS_ACTION,
        payload: id,
      });
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.DELETE_EXPENSE_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* fetchExpensesTypeInAgent() {
  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/expenseType?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_EXPENSE_TYPE_IN_AGENT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_EXPENSE_TYPE_IN_AGENT_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* changeExpenseApprove(value) {
  let Val = value.params.data;
  if (value.params.isCheckEarnings) {
    const data = {
      receiver: Val.distributor.id,
      licenseeId: Val.licenseeId.id,
      salesExecutive: Val.agent.id,
      currency: Val.currency.id,
      amount: -Val.amount,
      type: "expense",
      expense: Val.id,
    };

    try {
      let params = {
        api: `${appConfig.ip}/earnings`,
        method: "POST",
        sucessAction: actionTypes.APPROVE_EXPENSE_SUCCESS_ACTION,
        failAction: actionTypes.APPROVE_EXPENSE_FAIL_ACTION,
        initAction: null,
        toastMsg: "Expense approved successfully",
        body: JSON.stringify(data),
      };
      const res = yield call(commonFunction, params);

      if (res) {
        yield call(updateExpenseStatus, Val.id);
      }
    } catch (e) {}
  } else {
    yield call(updateExpenseStatus, Val.id);
  }
}

function* updateExpenseStatus(value) {
  const data = {
    status: "approved",
  };
  try {
    let params = {
      api: `${appConfig.ip}/expense/${value}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      initAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
    if (res) {
      const resJSONById = yield call(fetchExpenseById, res.id);
    }
  } catch (e) {}
}

//Get expense by Id
function* fetchExpenseById(value) {
  try {
    let params = {
      api: `${appConfig.ip}/expense/${value}`,
      method: "GET",
      sucessAction: actionTypes.EDIT_EXPENSE_SUCCESS_ACTION,
      failAction: actionTypes.EDIT_EXPENSE_FAIL_ACTION,
      initAction: null,
      body: null,
    };
    const res = yield call(commonFunction, params);
  } catch (e) {}
}

export function* expenseActionWatcher() {
  yield takeEvery(actionTypes.FETCH_EXPENSE_INIT_ACTION, fetchExpense);
  yield takeEvery(actionTypes.ADD_EXPENSE_INIT_ACTION, addExpense);
  yield takeEvery(actionTypes.DELETE_EXPENSE_INIT_ACTION, deleteExpense);
  yield takeEvery(actionTypes.EDIT_EXPENSE_INIT_ACTION, editExpense);
  yield takeEvery(
    actionTypes.FETCH_EXPENSE_TYPE_IN_AGENT_INIT_ACTION,
    fetchExpensesTypeInAgent
  );
  yield takeEvery(
    actionTypes.APPROVE_EXPENSE_INIT_ACTION,
    changeExpenseApprove
  );
}
