import * as actionTypes from "./constants";
import { sortData } from "../../../screens/Common/sortData";

const initialState = {
  processing: false,
  error: false,
  customerrouteList: [],
  customerrouteCount: 0,

};

export default function customerRouteReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_ROUTE_SUCCESS_ACTION:
      return {
        ...state,
        customerrouteList: action.payload.rows,
        customerrouteCount: action.payload.count,
      };
    case actionTypes.EDIT_ROUTE_SUCCESS_ACTION:

      return {
        ...state,
        customerrouteList: state.customerrouteList.map((route) =>
          route.id === action.payload.id ? action.payload : route
        ),

      };
    case actionTypes.CHANGE_STATUS_ROUTE_SUCCESS_ACTION:
      return {
        ...state,
        customerrouteList: state.customerrouteList.map((route) =>
          route.id === action.payload.id
            ? { ...route, status: action.payload.status }
            : route
        ),
      };

    default:
      return {
        ...state,
      };
  }
}
