export const FETCH_EXPENSE_INIT_ACTION = "FETCH_EXPENSE_INIT_ACTION";
export const FETCH_EXPENSE_SUCCESS_ACTION =
  FETCH_EXPENSE_INIT_ACTION + "_SUCCESS";
export const FETCH_EXPENSE_FAIL_ACTION = FETCH_EXPENSE_INIT_ACTION + "_ERROR";

export const ADD_EXPENSE_INIT_ACTION = "ADD_EXPENSE_INIT_ACTION";
export const ADD_EXPENSE_SUCCESS_ACTION = ADD_EXPENSE_INIT_ACTION + "_SUCCESS";
export const ADD_EXPENSE_FAIL_ACTION = ADD_EXPENSE_INIT_ACTION + "_ERROR";

export const DELETE_EXPENSE_INIT_ACTION = "DELETE_EXPENSE_INIT_ACTION";
export const DELETE_EXPENSE_SUCCESS_ACTION =
  DELETE_EXPENSE_INIT_ACTION + "_SUCCESS";
export const DELETE_EXPENSE_FAIL_ACTION = DELETE_EXPENSE_INIT_ACTION + "_ERROR";

export const EDIT_EXPENSE_INIT_ACTION = "EDIT_EXPENSE_INIT_ACTION";
export const EDIT_EXPENSE_SUCCESS_ACTION =
  EDIT_EXPENSE_INIT_ACTION + "_SUCCESS";
export const EDIT_EXPENSE_FAIL_ACTION = EDIT_EXPENSE_INIT_ACTION + "_ERROR";

export const FETCH_EXPENSE_TYPE_IN_AGENT_INIT_ACTION =
  "FETCH_EXPENSE_TYPE_IN_AGENT_INIT_ACTION";
export const FETCH_EXPENSE_TYPE_IN_AGENT_SUCCESS_ACTION =
  FETCH_EXPENSE_TYPE_IN_AGENT_INIT_ACTION + "_SUCCESS";
export const FETCH_EXPENSE_TYPE_IN_AGENT_FAIL_ACTION =
  FETCH_EXPENSE_TYPE_IN_AGENT_INIT_ACTION + "_ERROR";

export const APPROVE_EXPENSE_INIT_ACTION = "APPROVE_EXPENSE_INIT_ACTION";
export const APPROVE_EXPENSE_SUCCESS_ACTION =
  APPROVE_EXPENSE_INIT_ACTION + "_SUCCESS";
export const APPROVE_EXPENSE_FAIL_ACTION =
  APPROVE_EXPENSE_INIT_ACTION + "_ERROR";
