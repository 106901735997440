export const FETCH_FORMDATA_INIT_ACTION = "FETCH_FORMDATA_INIT_ACTION";
export const FETCH_FORMDATA_SUCCESS_ACTION =
  FETCH_FORMDATA_INIT_ACTION + "_SUCCESS";
export const FETCH_FORMDATA_FAIL_ACTION = FETCH_FORMDATA_INIT_ACTION + "_ERROR";

export const ADD_FORMNAME_INIT_ACTION = "ADD_FORMNAME_INIT_ACTION";
export const ADD_FORMNAME_SUCCESS_ACTION =
  ADD_FORMNAME_INIT_ACTION + "_SUCCESS";
export const ADD_FORMNAME_FAIL_ACTION = ADD_FORMNAME_INIT_ACTION + "_ERROR";

export const FETCH_INTEGRATIONGUIDE_INIT_ACTION = "FETCH_INTEGRATIONGUIDEDATA_INIT_ACTION";
export const FETCH_INTEGRATIONGUIDE_SUCCESS_ACTION =
  FETCH_INTEGRATIONGUIDE_INIT_ACTION + "_SUCCESS";
export const FETCH_INTEGRATIONGUIDE_FAIL_ACTION = FETCH_INTEGRATIONGUIDE_INIT_ACTION + "_ERROR";
