import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import { Table, Card, Row, Col, Button, Image } from "react-bootstrap";
import * as XLSX from "xlsx";
import ViewImportData from "./ViewImportData";
import EditImport from "./EditImport";
import Approve from "./Approve";
import DeleteModal from "./DeleteModal";
import { toast } from "react-toastify";

import ImportXls from "../Common/ImportXls";
import "./Import.css";

var tableRowIndex = 0;
function ImportProd(props) {
  useEffect(() => {
    props.fetchProductType();
    props.fetchCategory();
    props.fetchUnit();
    props.fetchGst();
    props.fetchSubctgryList();
    // props.fetchSubctgry();
    props.fetchAgencyList();
    props.fetchLicenseeProfile();
  }, []);

  const [items, setItems] = useState([]);
  const [showViewModel, setshowViewModel] = useState(false);
  const [ItemList, setItemList] = useState("");
  const [showEditImportModel, setShowEditImportModel] = useState(false);
  const [showDelModel, setShowDelModel] = useState(false);
  const [showAprvModel, setShowAprvModel] = useState(false);
  // const [EditedExcelDataBackup, setEditedExcelDataBackup] = useState("");

  function handleViewModel(d) {
    setshowViewModel(true);
    setItemList(d);
  }
  function closeViewModel() {
    setshowViewModel(false);
    setItemList("");
  }
  function handleEditImport(d) {
    setShowEditImportModel(true);
    // setShowEditModel(true)
    setItemList(d);
  }
  function closeEditModel() {
    setShowEditImportModel(false);
    setItemList("");
  }
  function handleDelModel(d) {
    setShowDelModel(true);
    setItemList(d);
  }
  function closeDelModel() {
    setShowDelModel(false);
    setItemList("");
  }
  function handleAproveModel(d) {
    setShowAprvModel(true);
    setItemList(d);
  }
  function closeApproveModel() {
    setShowAprvModel(false);
    setItemList("");
  }

  const EditedExcelDataBackup = "";

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);
        // exceldata = data;
        const editeddata = editExcelData(data);
        // debugger;
        // console.log(editeddata, "editeddata");

        resolve(editeddata);
      };

      fileReader.onerror = (error) => {
        // if (onerror == "Yes") {
        //     toast.error("Invalid", {
        //         autoClose: 2000,
        //         position: toast.POSITION.TOP_RIGHT
        //     });
        // }

        if (error == "No") {
        }

        reject(error);
      };
    });

    promise.then((d) => {
      setItems(d);
    });
  };

  function editExcelData(excelData) {
    const editedjson = [];
    const categoryIdList = props.categoryList;

    const SubcategoryIdList = props.subcategorysList;

    const UnitIdList = props.unitList;

    const AgencyIdList = props.AgencysList;

    const GstIdList = props.GstList;

    const TypeIdList = props.productTypeList;

    const InvalidRows = [];
    for (let index = 0; index < excelData.length; index++) {
      const element = excelData[index];
      const category = "";
      if (element.ctgryId != null) {
        category = element.ctgryId.toLowerCase();
      }
      const Agency = "";
      if (element.agency != null) {
        Agency = element.agency.toLowerCase();
      }
      const Unit = "";
      if (element.unit != null) {
        Unit = element.unit.toLowerCase();
      }
      const Type = "";
      if (element.PrdtType != null) {
        Type = element.PrdtType.toLowerCase();
      }
      const Subcategory = "";
      if (element.subCtgryId != null) {
        Subcategory = element.subCtgryId.toLowerCase();
      }

      // const Subcategory = element.subCtgryId.toLowerCase();
      const Gst = element.gstSlab;
      // const Agency = element.agency.toLowerCase();
      // const Unit = element.unit.toLowerCase();
      // const Type = element.PrdtType.toLowerCase();
      const EditedCategory = "";
      const EditedSubCategory = "";
      const EditedGst = "";
      const EditedAgency = "";
      const EditedUnit = "";
      const EditedType = "";

      //removed array from all edited items
      for (
        let categoryItemCount = 0;
        categoryItemCount < categoryIdList.length;
        categoryItemCount++
      ) {
        const currentCategoryName =
          categoryIdList[categoryItemCount].name.toLowerCase();
        if (currentCategoryName == category) {
          EditedCategory = [categoryIdList[categoryItemCount]];
        }
      }
      // if (EditedCategory == "") {
      //     InvalidRows.push(index);
      //     break;
      // }
      for (
        let SubcategoryItemCount = 0;
        SubcategoryItemCount < SubcategoryIdList.length;
        SubcategoryItemCount++
      ) {
        const currentSubCategoryName =
          SubcategoryIdList[SubcategoryItemCount].name.toLowerCase();
        if (currentSubCategoryName == Subcategory) {
          EditedSubCategory = [SubcategoryIdList[SubcategoryItemCount]];
        }
      }
      for (
        let gstItemCount = 0;
        gstItemCount < GstIdList.length;
        gstItemCount++
      ) {
        const currentGstName = GstIdList[gstItemCount].name;
        if (currentGstName == Gst) {
          EditedGst = [GstIdList[gstItemCount]];
        }
      }
      for (
        let agencyItemCount = 0;
        agencyItemCount < AgencyIdList.length;
        agencyItemCount++
      ) {
        const currentAgencyName =
          AgencyIdList[agencyItemCount].name.toLowerCase();
        if (currentAgencyName == Agency) {
          EditedAgency = [AgencyIdList[agencyItemCount]];
        }
      }
      for (
        let unitItemCount = 0;
        unitItemCount < UnitIdList.length;
        unitItemCount++
      ) {
        const currentUnitName = UnitIdList[unitItemCount].name.toLowerCase();
        if (currentUnitName == Unit) {
          EditedUnit = [UnitIdList[unitItemCount]];
        }
      }
      for (
        let TypeItemCount = 0;
        TypeItemCount < TypeIdList.length;
        TypeItemCount++
      ) {
        const currentTypeName = TypeIdList[TypeItemCount].name.toLowerCase();
        if (currentTypeName == Type) {
          EditedType = [TypeIdList[TypeItemCount]];
          // console.log(EditedType, "IdEditedType")
        }
      }

      const tempEditedjson = excelData[index];
      // console.log("EditedCategory", EditedCategory);
      // console.log("EditedSubCategory", EditedSubCategory);
      // console.log("EditedGst", EditedGst);
      // console.log("EditedAgency", EditedAgency);
      // console.log("EditedUnit", EditedUnit);
      // console.log("EditedType", EditedType);

      if (
        EditedCategory == "" ||
        EditedSubCategory == "" ||
        EditedGst == "" ||
        EditedAgency == "" ||
        EditedUnit == "" ||
        EditedType == ""
      ) {
        InvalidRows.push(index + 1);

        tempEditedjson["isRowInvalid"] = "Yes";
        tempEditedjson["RowId"] = index;

        editedjson.push(tempEditedjson);

        // break;
      } else {
        tempEditedjson["isRowInvalid"] = "No";

        tempEditedjson["ctgryId"] = EditedCategory;
        tempEditedjson["subCtgryId"] = EditedSubCategory;
        tempEditedjson["gstSlab"] = EditedGst;
        tempEditedjson["agency"] = EditedAgency;
        tempEditedjson["unit"] = EditedUnit;
        tempEditedjson["PrdtType"] = EditedType;
        tempEditedjson["RowId"] = index;
        editedjson.push(tempEditedjson);
      }
    }
    // alert("We have removed the Invalid records from the excel, THe invalid Row Numbers are" + InvalidRows.toString());

    return editedjson;
  }

  const updateItemData = (value) => {
    setItems(
      items.map((order) => (order.RowId === value.RowId ? value : order))
    );
    // props.closeEditModel();
  };
  // // Remove Table row if rows are count is more than 1
  // const deleteRow = (d) => {
  //     if (items.length > 1) {
  //         var updatedRows = [...items]
  //         var indexToRemove = updatedRows.findIndex(x => x.d == d);
  //         if (indexToRemove > -1) {
  //             updatedRows.splice(indexToRemove, 1)
  //             setRows(updatedRows);
  //         }
  //     }
  // }

  // console.log("items are", items);

  return (
    <div>
      <div className="userDiv">
        <Card>
          <div className="editprofclose">
            <Link to="/products" onClick={() => window.scrollTo(0, 0)}>
              <i className="fa fa-times fa-lg closeiconview "></i>
            </Link>
          </div>
          <Card.Header>
            <Row>
              <Col xs={12} sm={6} md={6} lg={3}>
                <input
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    readExcel(file);
                  }}
                />
              </Col>
              <br></br>
              <Col xs={12} sm={6} md={4} lg={4}></Col>

              <label id="Alert"></label>
              <Col xs={12} sm={6} md={4} lg={4}>
                <ImportXls
                  hasDwnld={true}
                  fileName="product-template"
                  xlsImport={ImportProd}
                />
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Table responsive className="cmnMainTable">
              <thead>
                <tr className="cmnMainTableHd">
                  {/* <th scope="col">Product Id</th> */}
                  <th scope="col">Product Name</th>
                  <th scope="col">AGENCY</th>
                  <th scope="col">BUYING PRICE</th>
                  <th scope="col">SELLING PRICE</th>

                  <th scope="col">AVAILABLE QTY</th>
                  <th scope="col">STATUS</th>

                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {items.map((d, i) =>
                  d.isRowInvalid === "Yes" ? (
                    <tr
                      key={i}
                      style={
                        {
                          // backgroundColor: "#FA8072"
                        }
                      }
                    >
                      <td className="cmn_Cap">{d !== null && d.name}</td>
                      <td className="cmn_Cap">{d !== null && d.agency}</td>
                      <td className="cmn_Cap">{d !== null && d.buyingPrice}</td>
                      <td className="cmn_Cap">
                        {d !== null && d.sellingPrice}
                      </td>
                      <td className="cmn_Cap">{d !== null && d.quantity}</td>
                      <td className="cmn_Cap">
                        <span className="badge badge-danger">Invalid</span>
                      </td>

                      <td className="cmnViewIcon">
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <i
                            className="fa fa-eye fa-lg "
                            onClick={() => handleViewModel({ d: d })}
                          ></i>

                          <i
                            className="fa fa-edit fa-lg "
                            style={{ paddingLeft: 10 }}
                            onClick={() => handleEditImport(d)}
                          ></i>

                          {/* <i
                                                        className="fa fa-trash fa-lg"
                                                        style={{ paddingLeft: 10, }}

                                                        onClick={() => handleDelModel(d)
                                                        }
                                                    ></i> */}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr key={i}>
                      <td className="cmn_Cap">{d !== null && d.name}</td>
                      <td className="cmn_Cap">
                        {d !== null && d.agency[0].name !== undefined
                          ? d.agency[0].name
                          : null}
                      </td>
                      <td className="cmn_Cap">{d !== null && d.buyingPrice}</td>
                      <td className="cmn_Cap">
                        {d !== null && d.sellingPrice}
                      </td>
                      <td className="cmn_Cap">{d !== null && d.quantity}</td>
                      <td className="cmn_Cap">
                        <span className="badge badge-success">Valid</span>
                      </td>
                      <td className="cmnViewIcon">
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <i
                            className="fa fa-eye fa-lg "
                            onClick={() => handleViewModel({ d: d })}
                          ></i>

                          <i
                            className="fa fa-edit fa-lg "
                            style={{ paddingLeft: 10 }}
                            onClick={() => handleEditImport(d)}
                          ></i>

                          <i
                            className="fa fa-check fa-lg "
                            style={{ paddingLeft: 10 }}
                            onClick={() => handleAproveModel(d)}
                          ></i>
                          {showAprvModel ? (
                            <Approve
                              showAprvModel={showAprvModel}
                              closeApproveModel={closeApproveModel}
                              ItemList={ItemList}
                              ApproveProduct={props.ApproveProduct}
                              LicenseeprofDtls={props.LicenseeprofDtls}

                              //   changeOrderApprove={props.changeOrderApprove}
                            />
                          ) : null}
                          {/* <i
                                                    className="fa fa-trash fa-lg"
                                                    style={{ paddingLeft: 10, }}
                                                    onClick={() => handleDelModel(d)}
                                                ></i> */}
                        </div>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
            {showEditImportModel ? (
              <EditImport
                showEditImportModel={showEditImportModel}
                closeEditModel={closeEditModel}
                ItemList={ItemList}
                // fetchProductType={props.fetchProductType}
                productTypeList={props.productTypeList}
                // fetchCategory={props.fetchCategory}
                categoryList={props.categoryList}
                // fetchSubctgryList={props.fetchSubctgryList}
                subcategorysList={props.subcategorysList}
                GstList={props.GstList}
                unitList={props.unitList}
                // fetchGst={props.fetchGst}
                // fetchUnit={props.fetchUnit}
                // fetchAgencyList={props.fetchAgencyList}
                AgencysList={props.AgencysList}
                // fetchLicenseeProfile={props.fetchLicenseeProfile}
                LicenseeprofDtls={props.LicenseeprofDtls}
                productDtls={props.productDtls}
                items={items}
                fetchSubctgry={props.fetchSubctgry}
                subcategoryList={props.subcategoryList}
                updateItemData={updateItemData}
              />
            ) : null}

            {showViewModel ? (
              <ViewImportData
                showViewModel={showViewModel}
                closeViewModel={closeViewModel}
                ItemList={ItemList}
              />
            ) : null}
            {showDelModel ? (
              <DeleteModal
                showDelModel={showDelModel}
                closeDelModel={closeDelModel}
                ItemList={ItemList}
                // deleteRow={deleteRow}
              />
            ) : null}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default ImportProd;
