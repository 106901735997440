export const FETCH_INCENTIVEBUCKET_INIT_ACTION = "FETCH_INCENTIVEBUCKET_INIT_ACTION";
export const FETCH_INCENTIVEBUCKET_SUCCESS_ACTION = FETCH_INCENTIVEBUCKET_INIT_ACTION + "_SUCCESS";
export const FETCH_INCENTIVEBUCKET_FAIL_ACTION = FETCH_INCENTIVEBUCKET_INIT_ACTION + "_ERROR";

export const FETCH_INCENTIVEBUCKETBYID_INIT_ACTION = "FETCH_INCENTIVEBUCKETBYID_INIT_ACTION";
export const FETCH_INCENTIVEBUCKETBYID_SUCCESS_ACTION =
FETCH_INCENTIVEBUCKETBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_INCENTIVEBUCKETBYID_FAIL_ACTION = FETCH_INCENTIVEBUCKETBYID_INIT_ACTION + "_ERROR";

export const FETCH_AREAS_DROPDOWN_INIT_ACTION = "FETCH_AREAS_DROPDOWN_INIT_ACTION";
export const FETCH_AREAS_DROPDOWN_SUCCESS_ACTION =FETCH_AREAS_DROPDOWN_INIT_ACTION + "_SUCCESS";
export const FETCH_AREAS_DROPDOWN_FAIL_ACTION = FETCH_AREAS_DROPDOWN_INIT_ACTION + "_ERROR";

export const FETCH_TOTAL_INIT_ACTION = "FETCH_TOTAL_INIT_ACTION";
export const FETCH_TOTAL_SUCCESS_ACTION = FETCH_TOTAL_INIT_ACTION + "_SUCCESS";
export const FETCH_TOTAL_FAIL_ACTION = FETCH_TOTAL_INIT_ACTION + "_ERROR";


export function fetchIncentiveBucket(value) {

    return {
      type: FETCH_INCENTIVEBUCKET_INIT_ACTION,
      value: value

    };
  }
  

  export function getIncentiveBucketById(value) {
    return {
      type: FETCH_INCENTIVEBUCKETBYID_INIT_ACTION,
      value:value
    };
  }


  
  export function fetchAreasDropdown(value) {
    return {
      type: FETCH_AREAS_DROPDOWN_INIT_ACTION,
      value: value
    };
  }

  export function fetchTotal(value) {
      return {
        type: FETCH_TOTAL_INIT_ACTION,
        value: value

      };
    }