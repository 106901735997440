import React, { useEffect, useState } from "react";
import { Row, Col, Container, Image } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { TextField } from "../../Common/TextField";
import { DropDown } from "../../Common/DropDown";
// import { useEffect } from "react";

const accountTypeData = [
  { id: "current", name: "Current" },
  { id: "saving", name: "Savings" },
];

// const validate = Yup.object({
//   bankName: Yup.string().required("Bank name  is required"),
//   accountName: Yup.string().required("Account holder name is required"),
//   accountType: Yup.string().required("Select your account type"),
//   accountNo: Yup.string()
//     .matches("^[0-9]+$", " Account number is not valid")
//     .required("Account number  is required"),
//   ifscCode: Yup.string().required("IFSC code is required"),
// });

const BankDetail = ({ clickHandler, ...props }) => {
  // const [captchaVal, setCaptchaVal] = useState();

  useEffect(() => {
    props.fetchBankInSignup();
  }, []);

  const validate = Yup.object({
    captchaVal: Yup.string().required(" Please complete the captcha"),
  });

  const recaptchaRef = React.createRef();
  const onCaptchaChange = (value, formik) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    formik.values.captchaVal = value;
    formik.errors.captchaVal && delete formik.errors.captchaVal;
  };

  return (
    <div>
      <Formik
        initialValues={{
          bankName: (props.item && props.item.bankName) || "",
          accountName: (props.item && props.item.accountName) || "",
          accountType: (props.item && props.item.accountType) || "",
          branchName: (props.item && props.item.branchName) || "",
          accountNo: (props.item && props.item.accountNo) || "",
          branchAddress: (props.item && props.item.branchAddress) || "",
          ifscCode: (props.item && props.item.ifscCode) || "",
          captchaVal: "",
        }}
        validationSchema={validate}
        onSubmit={(value) => {
          props.submitForm(value);
        }}
      >
        {(formik) => (
          <div>
            <div className="signup-page-div ">
              <Form>
                <Row>
                  <Col lg={4} md={12} sm={12} xs={12}>
                    <div>
                      <DropDown
                        label="Bank Name"
                        name="bankName"
                        data={props.bankList}
                        defValue=" Select Bank"
                        // isRequired={true}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={12} sm={12} xs={12}>
                    <TextField
                      label="Account Holder Name"
                      name="accountName"
                      type="text"
                      // isRequired={true}
                    />
                  </Col>

                  <Col lg={4} md={12} sm={12} xs={12}>
                    <DropDown
                      label="Account Type"
                      name="accountType"
                      data={accountTypeData}
                      defValue=" Select Account Type"
                      // isRequired={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} md={12} sm={12} xs={12}>
                    <TextField
                      label="Account Number"
                      name="accountNo"
                      type="text"
                      // isRequired={true}
                    />
                  </Col>
                  <Col lg={4} md={12} sm={12} xs={12}>
                    <TextField
                      label="IFSC Code"
                      name="ifscCode"
                      type="text"
                      // isRequired={true}
                    />
                  </Col>

                  <Col lg={4} md={12} sm={12} xs={12}>
                    <TextField
                      label="Branch Name"
                      name="branchName"
                      type="text"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} md={12} sm={12} xs={12}>
                    <TextField
                      label="Branch Address"
                      name="branchAddress"
                      type="text"
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Col>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      className="g-recaptcha"
                      sitekey="6Lfh4N4lAAAAAB0Y6nLEdiPr7Ipe0F-j7-6-08s4"
                      onChange={(value) => {
                        onCaptchaChange(value, formik);
                      }}
                      // {onCaptchaChange}
                    />
                    {formik.errors.captchaVal != "" ? (
                      <div className="errStyle">{formik.errors.captchaVal}</div>
                    ) : null}
                  </Col>
                </Row>
                <div className="row buttons-step-div">
                  <Col lg={8} md={12} sm={12} xs={12}>
                    <div className="back-to-login">
                      <button
                        className=" prev-btn"
                        type="submit"
                        onClick={() => clickHandler(2, formik.values)}
                      >
                        Back
                      </button>
                    </div>
                  </Col>
                  <Col lg={4} md={12} sm={12} xs={12}>
                    <button className="next-btn">Sign Up</button>
                  </Col>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};
export default BankDetail;
