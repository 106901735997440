export const FETCH_CREDITNOTE_REPORT_INIT_ACTION =
  "FETCH_CREDITNOTE_REPORT_INIT_ACTION";
export const FETCH_CREDITNOTE_REPORT_SUCCESS_ACTION =
  FETCH_CREDITNOTE_REPORT_INIT_ACTION + "_SUCCESS";
export const FETCH_CREDITNOTE_REPORT_FAIL_ACTION =
  FETCH_CREDITNOTE_REPORT_INIT_ACTION + "_ERROR";

export const FETCH_CREDITNOTE_REPORT_BY_ID_INIT_ACTION =
  "FETCH_CREDITNOTE_REPORT_BY_ID_INIT_ACTION";
export const FETCH_CREDITNOTE_REPORT_BY_ID_SUCCESS_ACTION =
  FETCH_CREDITNOTE_REPORT_BY_ID_INIT_ACTION + "_SUCCESS";
export const FETCH_CREDITNOTE_REPORT_BY_ID_FAIL_ACTION =
  FETCH_CREDITNOTE_REPORT_BY_ID_INIT_ACTION + "_ERROR";