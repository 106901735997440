import { put, takeEvery } from "redux-saga/effects";
// import { createBrowserHistory } from "history";
import {
  FETCH_LICENSEEPROFILEEDIT_INIT_ACTION,
  FETCH_LICENSEEPROFILEEDIT_SUCCESS_ACTION,
  FETCH_LICENSEEPROFILEEDIT_FAIL_ACTION,
  EDIT_LICENSEEPROF_INIT_ACTION,
  EDIT_LICENSEEPROF_SUCCESS_ACTION,
  EDIT_LICENSEEPROF_FAIL_ACTION,
  FETCH_BANK_INIT_ACTION,
  FETCH_BANK_SUCCESS_ACTION,
  FETCH_BANK_FAIL_ACTION,
} from "./action";

import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// var history = createBrowserHistory();

function* fetchLicenseeProfileEdit(value) {
  const token = localStorage.getItem("sStraitToken");
  const userId = localStorage.getItem("sStraitUserId");
  try {
    const res = yield fetch(`${appConfig.ip}/licenseeProf/` + userId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_LICENSEEPROFILEEDIT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_LICENSEEPROFILEEDIT_FAIL_ACTION, error: err });
    } else {
    }
  }
}
function* EditLicenseeprof(value) {
  try {
    const token = localStorage.getItem("sStraitToken");
    const userId = localStorage.getItem("sStraitUserId");

    if (value.value.fileimgvalue) {
      const formData = new FormData();

      formData.append(userId, value.value.picture);

      const resFile = yield fetch(`${appConfig.ip}/users/image`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!resFile.ok) {
        throw resFile;
      } else {
        const resJSON = yield resFile.json();

        const LicenseeValue = {
          email: value.value.email,
          mobileNo: value.value.mobileNo,

          name: value.value.name,
          lName: value.value.lName,

          orgName: value.value.orgName,

          // country: value.value.country,
          // state: value.value.state,
          // district: value.value.district,
          city: value.value.city,
          // geo: value.value.geo,
          addr1: value.value.addr1,
          addr2: value.value.addr2,
          picture: value.value.picture,
          // image: value.value.image,

          picture: resJSON.files,

          zip: value.value.zip,

          gstin: value.value.gstin,
          spocName: value.value.spocName,
          crNumber: value.value.crNumber,
          spocDsgntn: value.value.spocDsgntn,
          spocEmail: value.value.spocEmail,
          spocPhn: value.value.spocPhn,
          hqAddr: value.value.hqAddr,
          hqFax: value.value.hqFax,
          hqEmail: value.value.hqEmail,
          hqPhn: value.value.hqPhn,
          website: value.value.website,
          upiId: value.value.upiId,
          //  bankName: value.value.bankName,
          //  accountName: value.value.accountName,
          //  accountNo: value.value.accountNo,
          //  accountType: value.value.accountType,
          branchAddress: value.value.branchAddress,
          //  branchName: value.value.branchName,
          //  ifscCode: value.value.ifscCode,
          contactNo: value.value.contactNo,
          contactEmail: value.value.contactEmail,
        };
        const res = yield fetch(
          `${appConfig.ip}/licenseeProf/update/${userId}`,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(LicenseeValue),
          }
        );

        if (!res.ok) {
          let errJSON = {};
          try {
            errJSON = yield res.json();
          } catch {
            throw Object.assign(res, errJSON);
          }
        } else {
          const resJSON = yield res.json();

          let upadtedata = {
            addr1: value.value.addr1,
            gstin: value.value.gstin,
            upiId: value.value.upiId,
            contactNo: value.value.contactNo,
            contactEmail: value.value.contactEmail,
            picture: resJSON.picture,
            orgName: value.value.orgName,
          };

          let dataToLocalStoarge = {
            ...JSON.parse(localStorage.getItem("sStraitLicProfDtls")),
            ...upadtedata,
          };

          localStorage.setItem(
            "sStraitLicProfDtls",
            JSON.stringify(dataToLocalStoarge)
          );

          yield put({
            type: FETCH_LICENSEEPROFILEEDIT_INIT_ACTION,
          });
          yield put(push("/myprofile"));
          yield toast.success("Profile updated successfully", {
            autoClose: 3000,
            transition: Flip,
          });

          yield put({
            type: EDIT_LICENSEEPROF_SUCCESS_ACTION,

            payload: resJSON,
          });
        }
      }
    } else {
      const data = {
        email: value.value.email,
        // mobileNo: value.value.mobileNo,

        name: value.value.name,
        lName: value.value.lName,
        contactPerson: value.value.contactPerson,
        contactEmail: value.value.contactEmail,
        // contactMob: value.value.contactMob,
        orgName: value.value.orgName,

        // country: value.value.country,
        // state: value.value.state,
        // district: value.value.district,
        city: value.value.city,
        // geo: value.value.geo,
        addr1: value.value.addr1,
        addr2: value.value.addr2,
        picture: value.value.picture,
        zip: value.value.zip,
        crNumber: value.value.crNumber,
        // crExpDate: value.value.crExpDate,

        gstin: value.value.gstin,
        spocName: value.value.spocName,
        spocDsgntn: value.value.spocDsgntn,
        spocEmail: value.value.spocEmail,
        spocPhn: value.value.spocPhn,
        hqAddr: value.value.hqAddr,
        hqFax: value.value.hqFax,
        hqEmail: value.value.hqEmail,
        hqPhn: value.value.hqPhn,
        website: value.value.website,
        //  bankName: value.value.bankName,
        //  accountName: value.value.accountName,
        //  accountNo: value.value.accountNo,
        //  accountType: value.value.accountType,
        branchAddress: value.value.branchAddress,
        // branchName: value.value.branchName,
        // ifscCode: value.value.ifscCode,
        upiId: value.value.upiId,
        contactNo: value.value.contactNo,
        // contactEmail: value.value.contactEmail,
      };

      const res = yield fetch(`${appConfig.ip}/licenseeProf/update/${userId}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) {
      } else {
        const resJSON = yield res.json();

        let upadtedata = {
          addr1: value.value.addr1,
          gstin: value.value.gstin,
          upiId: value.value.upiId,
          contactNo: value.value.contactNo,
          contactEmail: value.value.contactEmail,
          orgName: value.value.orgName,
          picture: resJSON.picture,
        };

        let dataToLocalStoarge = {
          ...JSON.parse(localStorage.getItem("sStraitLicProfDtls")),
          ...upadtedata,
        };

        localStorage.setItem(
          "sStraitLicProfDtls",
          JSON.stringify(dataToLocalStoarge)
        );

        let upadteUserdata = {
          orgName: value.value.orgName,
          picture: resJSON.picture,
        };

        let dataToUserLocalStoarge = {
          ...JSON.parse(localStorage.getItem("sStraitUserDtls")),
          ...upadteUserdata,
        };

        localStorage.setItem(
          "sStraitUserDtls",
          JSON.stringify(dataToUserLocalStoarge)
        );

        yield put({ type: FETCH_LICENSEEPROFILEEDIT_INIT_ACTION });
        yield put({
          type: EDIT_LICENSEEPROF_SUCCESS_ACTION,
          payload: resJSON,
        });

        yield put(push("/myprofile"));
        yield toast.success("Profile updated successfully", {
          autoClose: 3000,
          transition: Flip,
        });
      }
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: EDIT_LICENSEEPROF_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchBank() {
  try {
    const token = localStorage.getItem("sStraitToken");

    const res = yield fetch(`${appConfig.ip}/bankName/all`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
    } else {
      const resJSON = yield res.json();
      yield put({
        type: FETCH_BANK_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_BANK_FAIL_ACTION,
        error: err,
      });
    }
  }
}

export function* ProfileEditActionWatcher() {
  yield takeEvery(
    FETCH_LICENSEEPROFILEEDIT_INIT_ACTION,
    fetchLicenseeProfileEdit
  );
  yield takeEvery(EDIT_LICENSEEPROF_INIT_ACTION, EditLicenseeprof);
  yield takeEvery(FETCH_BANK_INIT_ACTION, fetchBank);
}
