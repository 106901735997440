// import * as actionTypes from "./constants";

// export function fetchSocialLead(value) {
//   return (dispatch) => {
//     dispatch({
//       type: actionTypes.FETCH_SOCIALLEAD_INIT_ACTION,
//       value: value,
//     });
//   };
// }

// export default {
//   fetchSocialLead,
// };

export const FETCH_SOCIALLEAD_INIT_ACTION = "FETCH_SOCIALLEAD_INIT_ACTION";
export const FETCH_SOCIALLEAD_SUCCESS_ACTION =
  FETCH_SOCIALLEAD_INIT_ACTION + "_SUCCESS";
export const FETCH_SOCIALLEAD_FAIL_ACTION =
  FETCH_SOCIALLEAD_INIT_ACTION + "_ERROR";

export const ADD_SOCIALLEAD_INIT_ACTION = "ADD_SOCIALLEAD_INIT_ACTION";
export const ADD_SOCIALLEAD_SUCCESS_ACTION =
  ADD_SOCIALLEAD_INIT_ACTION + "_SUCCESS";
export const ADD_SOCIALLEAD_FAIL_ACTION = ADD_SOCIALLEAD_INIT_ACTION + "_ERROR";

export const ADD_DIS_SOCIALLEAD_INIT_ACTION = "ADD_DIS_SOCIALLEAD_INIT_ACTION";
export const ADD_DIS_SOCIALLEAD_SUCCESS_ACTION =
  ADD_DIS_SOCIALLEAD_INIT_ACTION + "_SUCCESS";
export const ADD_DIS_SOCIALLEAD_FAIL_ACTION =
  ADD_DIS_SOCIALLEAD_INIT_ACTION + "_ERROR";

export const FETCH_AGENT_INIT_ACTION = "FETCH_AGENT_INIT_ACTION";
export const FETCH_AGENT_SUCCESS_ACTION = FETCH_AGENT_INIT_ACTION + "_SUCCESS";
export const FETCH_AGENT_FAIL_ACTION = FETCH_AGENT_INIT_ACTION + "_ERROR";

export function fetchSocialLead(value) {
  return {
    type: FETCH_SOCIALLEAD_INIT_ACTION,
    value: value,
  };
}

export function addSocialLead(value) {
  return {
    type: ADD_SOCIALLEAD_INIT_ACTION,
    value: value,
  };
}

export function fetchAgent(value) {
  return {
    type: FETCH_AGENT_INIT_ACTION,
    value: value,
  };
}

export function addDisSocialLead(value) {
  return {
    type: ADD_DIS_SOCIALLEAD_INIT_ACTION,
    value: value,
  };
}
