import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { Button, Container, Image } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import './forgotpassword.css'
import ".././Common/cmnStyle.css";

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      forgotMobNo: "",
      forgotMobNoError: "",
      forgotOTP: "",
      forgotOTPError: "",
      forgotnewPwd: "",
      forgotnewPwdError: "",
      forgotconfirmPwd: "",
      forgotconfirmPwdError: "",
      flagStep1: true,
      flagStep2: false,
      flagStep3: false,
      flagStep4: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.otpSent &&
      props.otpSent === true &&
      props.otpVerified === undefined &&
      props.pwdreset === undefined
    ) {
      return {
        ...state,
        flagStep1: false,
        flagStep2: true,
        flagStep3: false,
        flagStep4: false,
      };
    }
    if (
      props.otpVerified &&
      props.otpVerified === true &&
      props.pwdreset === undefined
    ) {
      return {
        ...state,
        flagStep1: false,
        flagStep2: false,
        flagStep3: true,
        flagStep4: false,
      };
    }
    if (
      props.pwdreset &&
      props.pwdreset === true &&
      props.redirecttoPage === undefined
    ) {
      return {
        ...state,
        flagStep1: false,
        flagStep2: false,
        flagStep3: false,
        flagStep4: true,
      };
    }
    return null;
  }

  forgotOnChange = (ev) => {
    let name = ev.target.name;
    if (name === "forgotMobNo") {
      this.setState({
        ...this.state,
        forgotMobNo: ev.target.value,
        forgotMobNoError: "",
      });
    }
    if (name === "forgotOtp") {
      this.setState({
        ...this.state,
        forgotOTP: ev.target.value,
        forgotOTPError: "",
      });
    }
    if (name === "forgotnewpwd") {
      if (ev.target.value.length < 6) {
        this.setState({
          ...this.state,
          forgotnewPwd: ev.target.value,
          forgotnewPwdError: "Minimum 6 digits/characters needed",
        });
      } else {
        this.setState({
          ...this.state,
          forgotnewPwd: ev.target.value,
          forgotnewPwdError: "",
        });
      }
    }
    if (name === "forgotconfrmpwd") {
      this.setState({
        ...this.state,
        forgotconfirmPwd: ev.target.value,
        forgotconfirmPwdError: "",
        forgotnewPwdError: ""
      });
    }
  };

  validateNo = () => {
    let isError = false;
    let regex = /[0-9]/;

    const errmsg1 = "Please enter a valid mobile number";
    const errmsg2 = "Please enter the OTP";
    const errmsg3 = "Password mismatch";
    const errmsg4 = "Password field cannot be empty";
    const errmsg5 = "Password field should contain 6 or more characters";

    const errors = {
      forgotMobNoError: "",
      forgotOTPError: "",
      forgotnewPwdError: "",
      forgotconfirmPwdError: "",
    };

    if (
      this.state.flagStep1 &&
      (!regex.test(this.state.forgotMobNo) ||
        this.state.forgotMobNo.length < 10)
    ) {
      isError = true;
      errors.forgotMobNoError = errmsg1;
    }
    if (this.state.flagStep2 && this.state.forgotOTP.length === 0) {
      isError = true;
      errors.forgotOTPError = errmsg2;
    }
    if (
      this.state.flagStep3 &&
      this.state.forgotconfirmPwd !== this.state.forgotnewPwd
    ) {
      isError = true;
      errors.forgotconfirmPwdError = errmsg3;
    }
    if (
      this.state.flagStep3 &&
      this.state.forgotconfirmPwd.length === 0 &&
      this.state.forgotnewPwd.length === 0
    ) {
      isError = true;
      errors.forgotnewPwdError = errmsg4;
    }
    if (this.state.flagStep3 && this.state.forgotconfirmPwd.length < 6) {
      isError = true;
      errors.forgotnewPwdError = errmsg5;
    }

    this.setState({
      ...this.state,
      ...errors,
    });

    return isError;
  };

  submitMobNo = () => {
    const validateError = this.validateNo();

    if (!validateError) {
      this.props.sendOtpforResetPwd(this.state.forgotMobNo);

      this.setState({
        ...this.state,
        forgotMobNoError: "",
      });
    }
  };

  submitOTP = () => {
    const validateError = this.validateNo();
    let data = { mobileNo: this.state.forgotMobNo, otp: this.state.forgotOTP };
    if (!validateError) {
      this.props.verifyOtpforResetPwd(data);

      this.setState({
        ...this.state,
        forgotOTPError: "",
      });
    }
  };

  resendOtpforResetPwd = () => {
    this.setState({
      ...this.state,
      forgotOTPError: "",
    });
    this.props.resendOtpforResetPwd(this.state.forgotMobNo);
  };

  resetPwd = () => {
    const validateError = this.validateNo();
    let data = {
      mobileNo: this.state.forgotMobNo,
      otp: this.state.forgotOTP,
      pwd: this.state.forgotnewPwd,
    };

    if (!validateError) {
      this.props.resetPwd(data);

      this.setState({
        ...this.state,
        forgotnewPwd: "",
        forgotnewPwdError: "",
        forgotconfirmPwd: "",
        forgotconfirmPwdError: "",
      });
    }
  };

  backtoLogin = () => {
    this.setState({
      ...this.state,
      forgotMobNo: "",
      forgotMobNoError: "",
      forgotOTP: "",
      forgotOTPError: "",
      forgotnewPwd: "",
      forgotnewPwdError: "",
      forgotconfirmPwd: "",
      forgotconfirmPwdError: "",
      flagStep1: true,
      flagStep2: false,
      flagStep3: false,
      flagStep4: false,
    });
    this.props.redirectTologin();
  };

  onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  handleCancel = async () => {
    this.backtoLogin();
    window.scrollTo(0, 0);
  };
  render() {
    return (
      <div className="FgtmainContainer">
        <Navbar></Navbar>

        {/* ================================================================================== */}
        {this.state.flagStep1 === true && (
          <Container className="forgotContainer">
            <Card.Title className="forgotcardhead">
              <div>
                <h3 className="forgotpassHead">Forgot Password </h3>
              </div>
            </Card.Title>
            <div className="forgotDiv">
              <i className="fa fa-lock fa-lg frgtpanaccounticon"></i>
              <h5 className="frgtpanaccount forgotColor">
                Enter your registered mobile number and we will send an OTP to
                reset your password
              </h5>

              <Form className="forgotForm" onKeyDown={this.onKeyDown}>
                <Row>
                  <Col lg={2} md={2} sm={12} xs={12}></Col>
                  <Col lg={8} md={8} sm={12} xs={12}>
                    <Form.Group controlId="formGroupEmail">
                      <Form.Control
                        type="text"
                        name="forgotMobNo"
                        value={this.state.forgotMobNo}
                        onChange={this.forgotOnChange}
                        placeholder="Enter mobile number"
                      />
                      <div className="frgterrStyle">
                        {this.state.forgotMobNoError}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={2} sm={12} xs={12}></Col>
                </Row>

                <div className="forgotpassubtns">
                  <Row style={{ marginTop: "30px", marginBottom: "20px" }}>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Link to="/login">
                        <Button
                          onClick={this.handleCancel}
                          style={{ marginTop: "-27px", marginBottom: "18%" }}
                          variant="outline-danger"
                          className="cnclBtn"
                        >
                          Cancel
                        </Button>
                      </Link>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Button
                        style={{ marginTop: "-27px" }}
                        variant="success"
                        className="forgotpagesubbtn"
                        onClick={this.submitMobNo}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            </div>
          </Container>
        )}

        {this.state.flagStep2 === true && (
          <Container className="forgotContainer">
            <Card.Title className="forgotcardhead">
              <div>
                <h3 className="forgotpassHead">Forgot Password </h3>
              </div>
            </Card.Title>
            <div className="forgotDiv">
              <i className="fa fa-lock fa-lg frgtpanaccounticon"></i>
              <Row>
                <Col lg={2} md={2} sm={12} xs={12}></Col>
                <Col lg={8} md={8} sm={12} xs={12}>
                  <h5
                    className=" "
                    style={{ fontSize: "15px", marginTop: "15px" }}
                  >
                    Enter the OTP you received
                  </h5>
                </Col>
                <Col lg={2} md={2} sm={12} xs={12}></Col>
              </Row>
            </div>
            <div className="forgotDiv">
              <Form className="forgotForm" onKeyDown={this.onKeyDown}>
                <Row>
                  <Col lg={2} md={2} sm={12} xs={12}></Col>
                  <Col lg={8} md={8} sm={12} xs={12}>
                    <Form.Group controlId="formGroupEmail">
                      <Form.Control
                        type="password"
                        name="forgotOtp"
                        value={this.state.forgotOTP}
                        onChange={this.forgotOnChange}
                        placeholder="Enter the OTP"
                      />
                      <Button
                        className="alignStyle"
                        variant="link"
                        onClick={this.resendOtpforResetPwd}
                      >
                        Resend OTP
                      </Button>
                      <div className="frgterrStyle">
                        {this.state.forgotOTPError}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={2} sm={12} xs={12}></Col>
                </Row>
              </Form>

              <div className="forgotpassubtns">
                <Row style={{ marginTop: "30px", marginBottom: "20px" }}>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Link to="/login">
                      <Button
                        onClick={this.handleCancel}
                        style={{ marginTop: "-27px", marginBottom: "18%" }}
                        variant="outline-success"
                        className="cnclBtn"
                      >
                        Cancel
                      </Button>
                    </Link>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Button
                      style={{ marginTop: "-27px" }}
                      variant="success"
                      className="forgotpagesubbtn"
                      onClick={this.submitOTP}
                    >
                      Verify OTP
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        )}

        {this.state.flagStep3 === true && (
          <Container className="forgotContainer">
            <Card.Title className="forgotcardhead">
              <div>
                <h3 className="forgotpassHead">Forgot Password</h3>
              </div>
            </Card.Title>

            <div className="forgotDiv">
              <i className="fa fa-lock fa-lg frgtpanaccounticon"></i>
              <Row>
                <Col lg={2} md={2} sm={12} xs={12}></Col>
                <Col lg={8} md={8} sm={12} xs={12}>
                  <h5
                    className=" "
                    style={{ fontSize: "15px", marginTop: "15px" }}
                  >
                    Reset your password here
                  </h5>
                </Col>
                <Col lg={2} md={2} sm={12} xs={12}></Col>
              </Row>
            </div>
            <div className="forgotDiv">
              <Form className="forgotForm" onKeyDown={this.onKeyDown}>
                <Row>
                  <Col lg={2} md={2} sm={12} xs={12}></Col>
                  <Col lg={8} md={8} sm={12} xs={12}>
                    <Form.Group controlId="formGroupEmail">
                      <Form.Control
                        type="password"
                        name="forgotnewpwd"
                        value={this.state.forgotnewPwd}
                        onChange={this.forgotOnChange}
                        placeholder="Enter the new password"
                      />
                      <div className="errStyle alignLabel">
                        {this.state.forgotnewPwdError}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={2} sm={12} xs={12}></Col>
                </Row>
                <Row>
                  <Col lg={2} md={2} sm={12} xs={12}></Col>
                  <Col lg={8} md={8} sm={12} xs={12}>
                    <Form.Group controlId="formGroupEmail">
                      <Form.Control
                        type="password"
                        name="forgotconfrmpwd"
                        value={this.state.forgotconfirmPwd}
                        onChange={this.forgotOnChange}
                        placeholder="Re-enter the password"
                      />
                      <div className="errStyle alignLabel">
                        {this.state.forgotconfirmPwdError}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={2} sm={12} xs={12}></Col>
                </Row>
              </Form>

              <div className="forgotpassubtns">
                <Row style={{ marginTop: "30px", marginBottom: "20px" }}>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Link to="/login">
                      <Button
                        onClick={this.handleCancel}
                        style={{ marginTop: "-27px", marginBottom: "18%" }}
                        variant="outline-success"
                        className="cnclBtn"
                      >
                        Cancel
                      </Button>
                    </Link>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Button
                      style={{ marginTop: "-27px", width: "100px" }}
                      variant="success"
                      className="forgotpageresetPwdbtn"
                      onClick={this.resetPwd}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        )}

        {this.state.flagStep4 === true && (
          <Container className="forgotContainer">
            <div className="forgotDiv">
            <div>
              <Image
                 className="forgotimg"
                  alt="SuccessImg"
             src={require("../../assets/img/savesucces.png")}
             />
              </div>
              <Form className="forgotForm">
                <h2 className="forgotTitle forgotColor">
                  Password reset successfully!!
                </h2>

                <Button
                  variant="link"
                  style={{ marginBottom: "15px" }}
                  onClick={this.backtoLogin}
                >
                  Back to Login
                </Button>
              </Form>
            </div>
          </Container>
        )}
      </div>
    );
  }
}

export default ForgotPassword;
