import { permissionData } from "./permissionData";
import _ from "lodash";
const checkPermission = (tab, item, subItem) => {
  let role = localStorage.getItem("sStraitUserRole");

  if (tab === "menu") {
    let menuVisible = false;

    if (
      permissionData[role] &&
      permissionData[role][tab] &&
      permissionData[role][tab].includes(item)
    ) {
      menuVisible = true;
    }
    return menuVisible;
  }

  return true;
};

const sortData = (data = [], key = "name") => {
  let tempData = _.orderBy(data, [(item) => item[key].toUpperCase()]);
  let result = tempData.map((dat) => {
    dat.name = dat[key].charAt(0).toUpperCase() + dat[key].slice(1);
    return dat;
  });
  return result;
};

export { checkPermission, sortData };
