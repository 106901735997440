import { put, takeEvery, select, call, all } from "redux-saga/effects";

import { createBrowserHistory } from "history";
import {
  ADD_DISQUOTATION_INIT_ACTION,
  ADD_DISQUOTATION_SUCCESS_ACTION,
  ADD_DISQUOTATION_FAIL_ACTION,
} from "./action";

import { appConfig } from "../../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FETCH_QUOTATIONS_INIT_ACTION } from "../../QuotationsContainer/action";

function* commonFunction(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: `${value.failAction}`, error: err });
    } else {
    }
  }
}

function* AddDisQuotation(value) {
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const currencyId = JSON.parse(localStorage.getItem("sStraitCurrency"))
    .currencyId.id;
  let role = userDtls.role;
  const licDetails = yield call(getLicenseeProf);

  const quoteConfig = yield call(getQuoteConfig);
  let quoteConfigData = quoteConfig.rows;

  let products = [];
  value.value.productList.map((item) => {
    products.push({
      id: item.product.id,
      refId: item.product.refId,
      name: item.product.name,
      licenseeId: item.product.licenseeId.id,
      ctgryId: item.product.ctgryId.id,
      subCtgryId: item.product.subCtgryId.id,

      unit: item.product.unit,
      backOrder: item.product.backOrder,
      buyingPrice: item.product.buyingPrice,
      sellingPrice: item.product.sellingPrice,
      currency: item.product.currency.id,
      gstSlab: item.product.gstSlab.id,

      PrdtType: item.product.PrdtType.id,
      agency: item.product.agency.id,
      commission: item.product.commission,
      description: item.product.description,
      image: item.product.image,
      channelPartner: item.product.channelPartner
        ? item.product.channelPartner
        : null,
      quantity: item.qty,
      gstAmount: parseFloat(item.gstAmt),
      discount: item.discount,
      discAmount: parseFloat(item.discAmnt),
      total: parseFloat(item.total),
      hsnCode: item.product.hsnCode ? item.product.hsnCode : "",
      hasVariant: item.product.hasVariant,
      variants: item.product.hasVariant ? [item.inventory.variants] : [],
    });
  });

  const sum = products.reduce((a, object) => {
    return a + object.total;
  }, 0);

  let data = {
    initiator: role,
    orgId: userDtls.orgId,
    customer: value.value.customer[0].id,
    description: value.value.description,
    licenseeId: userDtls.licenseeId,
    currency: currencyId,
    salesExecutive: userDtls.id,
    totalPrice: Number.parseFloat(sum).toFixed(2),
    status: "active",
    additionalCharges: value.value.additionalCharges,
    products: products,
    region: role === "distributor" ? null : userDtls.region,
    zone: role === "distributor" ? null : userDtls.zone,
    area: role === "distributor" ? null : userDtls.area,

    category: value.value.customer[0].category,
    taxModel: value.value.customer[0].taxModel,

    customerInfo: {
      name: value.value.customer[0].name,
      address: value.value.customer[0].address,
      mobNo: value.value.customer[0].mobileNo,
      email: value.value.customer[0].email,
      gstin: value.value.customer[0].gstin,
    },
    licensee: {
      orgName: licDetails.orgName,
      address: licDetails.addr1 ? licDetails.addr1 : "",
      contactNo: licDetails.contactNo ? licDetails.contactNo : "",
      contactEmail: licDetails.contactEmail ? licDetails.contactEmail : "",
      gstin: licDetails.gstin ? licDetails.gstin : "",
    },
    config: {
      remarks: quoteConfigData && quoteConfigData[0].remark,
      logo: quoteConfigData && quoteConfigData[0].imgUrl,
      addtCustInfo: quoteConfigData && quoteConfigData[0].addtCustInfo,
    },
  };

  // if (role === "licensee" || role === "licenseeAccountant") {
  //   const dummyIds = yield call(getDummyData, userDtls);
  //   data = {
  //     ...data,
  //     region: dummyIds.region.id,
  //     zone: dummyIds.zone.id,
  //     area: dummyIds.area.id,
  //   };
  // } else if (role === "regionalManager") {
  //   const dummyIds = yield call(getDummyData, userDtls);
  //   data = {
  //     ...data,
  //     region: userDtls.region,
  //     zone: dummyIds.zone.id,
  //     area: dummyIds.area.id,
  //   };
  // } else if (role === "zonalManager") {
  //   const dummyIds = yield call(getDummyData, userDtls);
  //   data = {
  //     ...data,
  //     region: userDtls.region,
  //     zone: userDtls.zone,
  //     area: dummyIds.area.id,
  //   };
  // } else if (role === "areaManager" || role === "salesExcecutive") {
  //   data = {
  //     ...data,
  //     region: userDtls.region,
  //     zone: userDtls.zone,
  //     area: userDtls.area,
  //   };
  // }
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/quote/distributor`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON1 = {};
      try {
        errJSON1 = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON1);
    } else {
      const resJSON = yield res.json();

      yield put({ type: FETCH_QUOTATIONS_INIT_ACTION, value: { page: 1 } });
      yield put({
        type: ADD_DISQUOTATION_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Quotation added successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_DISQUOTATION_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* getLicenseeProf() {
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

  let userId = licData.userId.id;
  try {
    let params = {
      api: `${appConfig.ip}/licenseeprof/${userId}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

function* getQuoteConfig() {
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

  let liceId = licData.id;
  try {
    let params = {
      api: `${appConfig.ip}/quoteConfig?licenseeId=${liceId}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

//Get dummy data for creating quotation
// function* getDummyData(value) {
//   try {
//     let params = {
//       api: `${appConfig.ip}/users?licenseeId=${value.licenseeId}&dummy=true&role=areaManager`,
//       method: "GET",
//       sucessAction: null,
//       failAction: null,
//       body: null,
//     };
//     const dummyRes = yield call(commonFunction, params);
//     return dummyRes.rows[0];
//   } catch (e) {}
// }

export function* DisQuotationsActionWatcher() {
  yield takeEvery(ADD_DISQUOTATION_INIT_ACTION, AddDisQuotation);
}
