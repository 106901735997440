import {
  FETCH_LICENSEEPROFILE_SUCCESS_ACTION,
  // FETCH_USERPROFILE_SUCCESS_ACTION,
  FETCH_USERPROF_SUCCESS_ACTION,
  FETCH_USERDATA_SUCCESS_ACTION,
} from "./action";

const initialState = {
  processing: false,
  error: false
};

export default function(state: any = initialState, action: Function) {
  switch (action.type) {
    case FETCH_LICENSEEPROFILE_SUCCESS_ACTION:
      return {
        ...state,
        LicenseeprofDtls: action.payload
      };
      // case FETCH_USERPROFILE_SUCCESS_ACTION:
      // return {
      //   ...state,
      //   UserprofDtls: action.payload
      // };
    
      case FETCH_USERDATA_SUCCESS_ACTION:

        return {
          ...state,
          userDataList: action.payload
        };

    default:
      return {
        ...state
      };
  }
}
