import * as React from "react";
import { connect } from "react-redux";

import ImportProd from "../../screens/ImportProd";
import {
  importData,
  fetchSubctgryList,
  ApproveProduct,
  // filterSearchProduct
} from "./action";
import {
  fetchProductType,
  fetchCategory,
  fetchUnit,
  fetchAgencyList,
  fetchGst,
  fetchSubctgry,
} from "../ProductContainer/action";
import { fetchLicenseeProfile } from "../MyProfileContainer/action";

class ImportProdContainer extends React.Component {
  render() {
    return (
      <ImportProd
        ApproveProduct={this.props.ApproveProduct}
        importData={this.props.importData}
        fetchProductType={this.props.fetchProductType}
        productTypeList={this.props.productTypeList}
        fetchCategory={this.props.fetchCategory}
        categoryList={this.props.categoryList}
        fetchSubctgryList={this.props.fetchSubctgryList}
        subcategorysList={this.props.subcategorysList}
        fetchUnit={this.props.fetchUnit}
        unitList={this.props.unitList}
        fetchAgencyList={this.props.fetchAgencyList}
        AgencysList={this.props.AgencysList}
        fetchGst={this.props.fetchGst}
        GstList={this.props.GstList}
        fetchSubctgry={this.props.fetchSubctgry}
        subcategoryList={this.props.subcategoryList}
        fetchLicenseeProfile={this.props.fetchLicenseeProfile}
        LicenseeprofDtls={this.props.LicenseeprofDtls}
      />
    );
  }
}
function bindAction(dispatch) {
  return {
    importData: (value) => {
      dispatch(importData(value));
    },
    fetchProductType: (value) => {
      dispatch(fetchProductType(value));
    },
    fetchCategory: () => {
      dispatch(fetchCategory());
    },
    fetchSubctgryList: (value) => {
      dispatch(fetchSubctgryList(value));
    },

    fetchUnit: () => {
      dispatch(fetchUnit());
    },

    fetchAgencyList: (value) => {
      dispatch(fetchAgencyList(value));
    },
    fetchGst: () => {
      dispatch(fetchGst());
    },
    fetchGst: () => {
      dispatch(fetchGst());
    },

    fetchSubctgry: (value) => {
      dispatch(fetchSubctgry(value));
    },

    fetchLicenseeProfile: () => {
      dispatch(fetchLicenseeProfile());
    },
    ApproveProduct: (value) => {
      dispatch(ApproveProduct(value));
    },
  };
}

const mapStateToProps = (state) => {
  return {
    importData: state.productReducer.importData,
    categoryList: state.productReducer.categoryList,
    subcategorysList: state.ImportProdReducer.subcategorysList,
    GstList: state.productReducer.GstList,
    AgencysList: state.productReducer.AgencysList,
    unitList: state.productReducer.unitList,
    productTypeList: state.productReducer.productTypeList,
    subcategoryList: state.productReducer.subcategoryList,
    LicenseeprofDtls: state.MyProfileReducer.LicenseeprofDtls,
  };
};

export default connect(mapStateToProps, bindAction)(ImportProdContainer);
