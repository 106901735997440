import { put, takeEvery, select, call, all } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_FOLLOWUP_INIT_ACTION,
  FETCH_FOLLOWUP_SUCCESS_ACTION,
  FETCH_FOLLOWUP_FAIL_ACTION,
  FETCH_FOLLOWUPBYID_INIT_ACTION,
  FETCH_FOLLOWUPBYID_SUCCESS_ACTION,
  FETCH_FOLLOWUPBYID_FAIL_ACTION,
  CHANGESTATUS_FOLLOWUP_INIT_ACTION,
  CHANGESTATUS_FOLLOWUP_SUCCESS_ACTION,
  CHANGESTATUS_FOLLOWUP_FAIL_ACTION,
  EDIT_RESCHEDULE_INIT_ACTION,
  EDIT_RESCHEDULE_SUCCESS_ACTION,
  EDIT_RESCHEDULE_FAIL_ACTION,
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* getRoleApiUrl() {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const role = localStorage.getItem("sStraitUserRole");
  const userId = localStorage.getItem("sStraitUserId");

  let getDataUrl = [
    { role: "licensee", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "licenseeAccountant", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "distributor", url: "&licenseeId=" + userDtls.licenseeId },

    {
      role: "regionalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&region=" + userDtls.region,
    },
    {
      role: "zonalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&zone=" + userDtls.zone,
    },
    {
      role: "areaManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
    {
      role: "salesExcecutive",
      url: "&licenseeId=" + userDtls.licenseeId + "&creator=" + userDtls.id,
    },
    {
      role: "agent",
      url: "&licenseeId=" + userDtls.licenseeId + "&creator=" + userDtls.id,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);
  return apiUrl;
}

function* fetchFollowup(value) {
  let pageno = value.value.page === undefined ? 1 : value.value.page;
  const token = localStorage.getItem("sStraitToken");

  const apiUrl = yield call(getRoleApiUrl);
  let filterVal =
    !value || !value.value || !value.value.filterVal
      ? ""
      : value.value.filterVal;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? ""
      : value.value.searchVal;

  let _url = `${appConfig.ip}/followUp?limit=10&search=${searchVal}${filterVal}&page=${pageno}${apiUrl.url}`;

  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  } else if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  } else if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      yield put({
        type: FETCH_FOLLOWUP_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_FOLLOWUP_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* getFollowupById(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/followUp/` + value.value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_FOLLOWUPBYID_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_FOLLOWUPBYID_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* changeStatusFollowup(value) {
  try {
    let id = value.value.id;
    const userId = localStorage.getItem("sStraitUserId");

    const token = localStorage.getItem("sStraitToken");
    const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
    const followupValue = {
      status: value.value.status,
    };
    const res = yield fetch(`${appConfig.ip}/followUp/` + value.value.id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(followupValue),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      yield put({
        type: CHANGESTATUS_FOLLOWUP_INIT_ACTION,
      });
      yield put({
        type: CHANGESTATUS_FOLLOWUP_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Status updated successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: CHANGESTATUS_FOLLOWUP_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* EditReschedule(value) {
  try {
    let id = value.value.id;
    const userId = localStorage.getItem("sStraitUserId");

    const token = localStorage.getItem("sStraitToken");
    const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
    const followupData = {
      date: value.value.date ? value.value.date : null,
      note: value.value.note,

      type: value.value.type,

      time: value.value.time,
    };

    const res = yield fetch(`${appConfig.ip}/followUp/` + value.value.id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(followupData),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: EDIT_RESCHEDULE_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield put({
        type: FETCH_FOLLOWUP_INIT_ACTION,
        value: { page: 1 },
      });

      yield toast.success("Follow up updated successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: EDIT_RESCHEDULE_FAIL_ACTION,
        error: err,
      });
    }
  }
}

// function* EditReschedule(value) {

//     let id = value.value.id;
//     const userId = localStorage.getItem("sStraitUserId");

//     const token = localStorage.getItem("sStraitToken");
//     const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
//     const followupData = {
//         date: value.value.date ? value.value.date : null,
//         note: value.value.note,

//         type: value.value.type,

//     };
//     try {
//         const res = yield fetch(`${appConfig.ip}/followUp/` + id, {
//             method: "PUT",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//                 Authorization: `Bearer ${token}`
//             },
//             body: JSON.stringify(followupData)
//         });
//         if (!res.ok) {
//             let errJSON = {};
//             try {
//                 errJSON = yield res.json();
//             } catch { }
//             throw Object.assign(res, errJSON);
//         } else {
//             yield toast.success("Folllow up updated successfully", {
//                 autoClose: 3000,
//             });
//             const resJSON = yield res.json();
//             yield put({
//                 type: EDIT_RESCHEDULE_SUCCESS_ACTION,
//                 payload: resJSON
//             });
//         }
//     } catch (err) {
//         if (err.ok === false) {
//             yield put({ type: EDIT_RESCHEDULE_FAIL_ACTION, error: err });
//         } else {
//         }
//     }
// }

export function* FollowupActionWatcher() {
  yield takeEvery(FETCH_FOLLOWUP_INIT_ACTION, fetchFollowup);
  yield takeEvery(FETCH_FOLLOWUPBYID_INIT_ACTION, getFollowupById);
  yield takeEvery(CHANGESTATUS_FOLLOWUP_INIT_ACTION, changeStatusFollowup);
  yield takeEvery(EDIT_RESCHEDULE_INIT_ACTION, EditReschedule);
}
