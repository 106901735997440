import { put, takeEvery, select, call, all } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  FETCH_CUSTOMERS_INIT_ACTION,
  FETCH_CUSTOMERS_SUCCESS_ACTION,
  FETCH_CUSTOMERS_FAIL_ACTION,
  ADD_CUSTOMERS_INIT_ACTION,
  ADD_CUSTOMERS_SUCCESS_ACTION,
  ADD_CUSTOMERS_FAIL_ACTION,
  EDIT_CUSTOMERS_INIT_ACTION,
  EDIT_CUSTOMERS_SUCCESS_ACTION,
  EDIT_CUSTOMERS_FAIL_ACTION,
  FETCH_CUSTOMERBYID_INIT_ACTION,
  FETCH_CUSTOMERBYID_SUCCESS_ACTION,
  FETCH_CUSTOMERBYID_FAIL_ACTION,
  FETCH_REGIONDROPDOWN_INIT_ACTION,
  FETCH_REGIONDROPDOWN_SUCCESS_ACTION,
  FETCH_REGIONDROPDOWN_FAIL_ACTION,
  FETCH_AREADROPDOWN_INIT_ACTION,
  FETCH_AREADROPDOWN_SUCCESS_ACTION,
  FETCH_AREADROPDOWN_FAIL_ACTION,
  FETCH_PRODUCTS_BY_CUSTOMER_INIT_ACTION,
  FETCH_PRODUCTS_BY_CUSTOMER_SUCCESS_ACTION,
  FETCH_PRODUCTS_BY_CUSTOMER_FAIL_ACTION,
  ADD_CUSTOMER_DISCOUNT_INIT_ACTION,
  ADD_CUSTOMER_DISCOUNT_SUCCESS_ACTION,
  ADD_CUSTOMER_DISCOUNT_FAIL_ACTION,
  FETCH_CUSTOMER_DISCOUNT_SUCCESS_ACTION,
  FETCH_CUSTOMER_DISCOUNT_FAIL_ACTION,
  FETCH_CUSTOMER_DISCOUNT_INIT_ACTION,
  EDIT_CUSTOMER_DISCOUNT_INIT_ACTION,
  EDIT_CUSTOMER_DISCOUNT_SUCCESS_ACTION,
  EDIT_CUSTOMER_DISCOUNT_FAIL_ACTION,
  DELETE_CUSTOMER_DISCOUNT_INIT_ACTION,
  DELETE_CUSTOMER_DISCOUNT_SUCCESS_ACTION,
  DELETE_CUSTOMER_DISCOUNT_FAIL_ACTION,
  CHECK_PRODUCTS_BY_CUSTOMER_DISCOUNT_INIT_ACTION,
  CHECK_PRODUCTS_BY_CUSTOMER_DISCOUNT_SUCCESS_ACTION,
  CHECK_PRODUCTS_BY_CUSTOMER_DISCOUNT_FAIL_ACTION,
  FETCH_CUSTOMER_ORDER_INIT_ACTION,
  FETCH_CUSTOMER_ORDER_SUCCESS_ACTION,
  FETCH_CUSTOMER_ORDER_FAIL_ACTION,
  EXPORT_LEDGER_INIT_ACTION,
  EXPORT_LEDGER_SUCCESS_ACTION,
  EXPORT_LEDGER_FAIL_ACTION,
  FETCH_TOTAL_REMAINING_AMT_SUCCESS_ACTION,
  FETCH_TOTAL_REMAINING_AMT_INIT_ACTION,
  FETCH_TOTAL_REMAINING_AMT_FAIL_ACTION,
  FETCH_CUSTOMER_CREDIT_NOTES_INIT_ACTION,
  FETCH_CUSTOMER_CREDIT_NOTES_SUCCESS_ACTION,
  FETCH_CUSTOMER_CREDIT_NOTES_FAIL_ACTION,
  FETCH_CREDIT_NOTE_BY_ID_INIT_ACTION,
  FETCH_CREDIT_NOTE_BY_ID_SUCCESS_ACTION,
  FETCH_CREDIT_NOTE_BY_ID_FAIL_ACTION,
  FETCH_CREDIT_NOTE_CONFIG_SUCCESS_ACTION,
  FETCH_CREDIT_NOTE_CONFIG_FAIL_ACTION,
  FETCH_CREDIT_NOTE_CONFIG_INIT_ACTION,
  CLOSE_CUSTADD_MODEL_ACTION,
  FETCH_ROUTES_BY_CUSTOMER_INIT_ACTION,
  FETCH_ROUTES_BY_CUSTOMER_SUCCESS_ACTION,
  FETCH_ROUTES_BY_CUSTOMER_FAIL_ACTION,
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
var dateFormat = require("dateformat");

function* getRoleApiUrl() {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  const role = localStorage.getItem("sStraitUserRole");
  const userId = localStorage.getItem("sStraitUserId");

  let getDataUrl = [
    { role: "licensee", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "distributor", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "licenseeAccountant", url: "&licenseeId=" + userDtls.licenseeId },
    {
      role: "regionalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&region=" + userDtls.region,
    },
    {
      role: "zonalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&zone=" + userDtls.zone,
    },
    {
      role: "areaManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
    {
      role: "salesExcecutive",
      url: "&licenseeId=" + userDtls.licenseeId + "&author=" + userDtls.id,
    },
    {
      role: "agent",
      url: "&licenseeId=" + userDtls.licenseeId + "&author=" + userDtls.id,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);
  return apiUrl;
}

function* fetchCustomers(value) {
  let pageno = value.value.page === undefined ? 1 : value.value.page;
  const token = localStorage.getItem("sStraitToken");
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  const apiUrl = yield call(getRoleApiUrl);

  let filterVal =
    !value || !value.value || !value.value.filterVal
      ? ""
      : value.value.filterVal;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? ""
      : value.value.searchVal;
  let filter =
    !value || !value.value || !value.value.filter ? null : value.value.filter;
  let routeFilter =
    !value || !value.value || !value.value.routeFilter
      ? null
      : value.value.routeFilter;

  let loyalFilter = (value.value && value.value.loyalFilter) || "";
  let ctgryFilter = (value.value && value.value.ctgryFilter) || "";
  let taxFilter = (value.value && value.value.taxFilter) || "";
  let userDtlsroute =
    (userDtls.routes && userDtls.routes.flat().join(",")) || "";

  let routeorauthor;
  if (userDtls.routes.length > 0) {
    routeorauthor = `&route=${userDtlsroute}`;
  } else {
    routeorauthor = apiUrl.url;
  }

  let _url = `${appConfig.ip}/customer?limit=10&page=${pageno}${routeorauthor}`;

  if (filter) {
    _url = `${_url}&type=${filter}`;
  }
  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }
  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  }
  if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  }
  if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }
  if (loyalFilter) {
    _url = `${_url}&loyal=${loyalFilter}`;
  }
  if (ctgryFilter) {
    _url = `${_url}&category=${ctgryFilter}`;
  }
  if (taxFilter) {
    _url = `${_url}&taxModel=${taxFilter}`;
  }
  if (routeFilter) {
    _url = `${_url}&route=${routeFilter}`;
  }
  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_CUSTOMERS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_CUSTOMERS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

// ADD Customer

function* AddCustomer(value) {
  let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  let role = userDetails.role;

  const custData = yield call(getCustDataById, value);

  if (custData.count === 0) {
    const data = {
      initiator: userDetails.role,
      name: value.value.name,
      email: value.value.email,
      mobileNo: value.value.mobileNo,
      address: value.value.address,
      type: value.value.type,
      deviceId: value.value.deviceId,
      author: userDetails.id,
      licenseeId: userDetails.licenseeId,
      channelPartner: licData.channelPartner ? licData.channelPartner.id : null,
      loyal: value.value.loyal || false,
      notify: role !== "licensee" && [
        { user: licData && licData.userId && licData.userId.id },
      ],
      category: value.value.category,
      taxModel: value.value.taxModel,
      gstin: value.value.gstin || "",
      route: value.value.route || null,
    };
    if (role === "licensee") {
      const dummyIds = yield call(getDummyData, userDetails);
      yield call(AddCustomerData, {
        ...data,
        region: dummyIds && dummyIds.region && dummyIds.region.id,
        zone: dummyIds && dummyIds.zone && dummyIds.zone.id,
        area: dummyIds && dummyIds.area && dummyIds.area.id,
      });
    } else if (role === "regionalManager") {
      const dummyIds = yield call(getDummyData, userDetails);
      yield call(AddCustomerData, {
        ...data,
        region: userDetails.region,
        zone: dummyIds && dummyIds.zone && dummyIds.zone.id,
        area: dummyIds && dummyIds.area && dummyIds.area.id,
      });
    } else if (role === "zonalManager") {
      const dummyIds = yield call(getDummyData, userDetails);
      yield call(AddCustomerData, {
        ...data,
        region: userDetails.region,
        zone: userDetails.zone,
        area: dummyIds && dummyIds.area && dummyIds.area.id,
      });
    } else {
      yield call(AddCustomerData, {
        ...data,
        region: userDetails.region,
        zone: userDetails.zone,
        area: userDetails.area,
      });
    }
  } else {
    yield toast.error("Customer already exists", {
      autoClose: 3000,
    });
  }
}

function* getCustDataById(value) {
  let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/customer?licenseeId=${userDetails.licenseeId}&mobileNo=${value.value.mobileNo}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* AddCustomerData(value) {
  try {
    const token = localStorage.getItem("sStraitToken");

    const res = yield fetch(`${appConfig.ip}/customer`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(value),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: ADD_CUSTOMERS_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Customer added successfully", {
        autoClose: 3000,
      });
      yield put({ type: CLOSE_CUSTADD_MODEL_ACTION });
      yield put({
        type: FETCH_CUSTOMERS_INIT_ACTION,
        value: { page: 1 },
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_CUSTOMERS_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* getDummyData(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/users?licenseeId=${value.licenseeId}&dummy=true&role=areaManager`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      return resJSON.rows[0];
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* EditCustomers(value) {
  let id = value.value.id;
  const token = localStorage.getItem("sStraitToken");
  // const region = value.value.region[0].id;
  // const zone = value.value.zone[0].id;
  let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  const data = {
    name: value.value.name,
    email: value.value.email,
    mobileNo: value.value.mobileNo,
    type: value.value.type,
    address: value.value.address,
    deviceId: value.value.deviceId,
    // author: userDetails.id,
    // licenseeId: userDetails.licenseeId,
    loyal: value.value.loyal || false,
    // area: userDetails.area,
    // region: userDetails.region,
    // zone: userDetails.zone,
    category: value.value.category,
    taxModel: value.value.taxModel,
    gstin: value.value.gstin || "",
    route: value.value.route || null,
  };

  try {
    const res = yield fetch(`${appConfig.ip}/customer/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield toast.success("Customer updated successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();
      yield put({
        type: EDIT_CUSTOMERS_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield put({ type: CLOSE_CUSTADD_MODEL_ACTION });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EDIT_CUSTOMERS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* getCustomerById(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/customer/` + value.value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_CUSTOMERBYID_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_CUSTOMERBYID_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchRegionDropdown() {
  const licenseeId = JSON.parse(
    localStorage.getItem("sStraitUserDtls")
  ).licenseeId;

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/region?licenseeId=${licenseeId}&isActive=true&dummy=false`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_REGIONDROPDOWN_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_REGIONDROPDOWN_FAIL_ACTION, error: err });
    } else {
    }
  }
}

// Get area list

function* fetchAreaDropdown(value) {
  let zone = !value || !value.value.id ? "" : value.value.id;
  const token = localStorage.getItem("sStraitToken");
  const licenseeId = JSON.parse(
    localStorage.getItem("sStraitUserDtls")
  ).licenseeId;

  try {
    const res = yield fetch(
      `${appConfig.ip}/area?zone=${zone}&licenseeId=${licenseeId}&isActive=true&dummy=false`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_AREADROPDOWN_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_AREADROPDOWN_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchProductsByCustomer(params) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let search = params.value && params.value.searchVal;
  let searchFilter = "";
  if (search) {
    searchFilter = `&search=${search}`;
  }

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/product?status=active&licenseeId=${userDtls.licenseeId}${searchFilter}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_PRODUCTS_BY_CUSTOMER_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_PRODUCTS_BY_CUSTOMER_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* fetchCustomerDiscount(value) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let page = (value.value && value.value.page) || 1;
  let custId =
    typeof value.value === "object" ? value.value.custId : value.value;

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/loyalDiscount?status=active&limit=10&page=${page}&licenseeId=${userDtls.licenseeId}&customerId=${custId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_CUSTOMER_DISCOUNT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_CUSTOMER_DISCOUNT_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* addCustomerDiscount(value) {
  let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licenseeId = userDetails && userDetails.licenseeId;
  let productIds = (value.value && value.value.productId) || [];

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield all(
      productIds.map((pId) => {
        let data = {
          // productId: (value.value && value.value.productId) || null,
          productId: pId.value || null,
          discount: (value.value && value.value.discount) || "",
          licenseeId: licenseeId || null,
          customerId: (value && value.custId) || null,
        };
        return fetch(`${appConfig.ip}/loyalDiscount/upsert`, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        });
      })
    );

    yield put({
      type: ADD_CUSTOMER_DISCOUNT_SUCCESS_ACTION,
    });
    yield toast.success("Customer discount added successfully", {
      autoClose: 3000,
    });
    yield put({
      type: FETCH_CUSTOMER_DISCOUNT_INIT_ACTION,
      value: (value && value.custId) || null,
    });
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_CUSTOMER_DISCOUNT_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* editCustomerDiscount(value) {
  let id = value && value.id;
  let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licenseeId = userDetails && userDetails.licenseeId;
  let data = {
    productId: (value.value && value.value.productId.value) || null,
    discount: (value.value && value.value.discount) || "",
    licenseeId: licenseeId || null,
    customerId: (value && value.custId) || null,
  };

  try {
    const token = localStorage.getItem("sStraitToken");

    const res = yield fetch(`${appConfig.ip}/loyalDiscount/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: EDIT_CUSTOMER_DISCOUNT_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Customer discount edited successfully", {
        autoClose: 3000,
      });
      yield put({
        type: FETCH_CUSTOMER_DISCOUNT_INIT_ACTION,
        value: data.customerId,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: EDIT_CUSTOMER_DISCOUNT_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* deleteCustomerDiscount(value) {
  let id = value.value && value.value.id;

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/loyalDiscount/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield put({
        type: FETCH_CUSTOMER_DISCOUNT_INIT_ACTION,
        value: value.custId,
      });

      yield put({
        type: DELETE_CUSTOMER_DISCOUNT_SUCCESS_ACTION,
        payload: id,
      });
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: DELETE_CUSTOMER_DISCOUNT_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* checkProdoctByCustomerDiscount(value) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let page = (value.value && value.value.page) || 1;
  // let custId =
  //   typeof value.value === "object" ? value.value.custId : value.value;
  let custId = value.custId;
  let productId = value.value;
  let tempProducts = "";
  productId.map((pIds) => (tempProducts = tempProducts + "," + pIds.value));
  let productIdList = tempProducts.substring(1);

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/loyalDiscount?status=active&licenseeId=${userDtls.licenseeId}&customerId=${custId}&productId=${productIdList}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: CHECK_PRODUCTS_BY_CUSTOMER_DISCOUNT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: CHECK_PRODUCTS_BY_CUSTOMER_DISCOUNT_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* fetchCustomerOrder(value) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let page = (value.value && value.value.page) || 1;
  let custId =
    typeof value.value === "object" ? value.value.custId : value.value;

  let q = typeof value.value === "object" ? value.value.search : null;

  let paymentFilter =
    typeof value.value === "object" ? value.value.paymentFilter : null;

  let dateFilter = "";
  if (typeof value.value === "object" && value.value.dateFilter) {
    dateFilter = value.value.dateFilter;
  }

  let searchParam = "";

  if (q) {
    searchParam = `&search=${q}`;
  }
  let paymentFilterParam = "";
  if (paymentFilter) {
    paymentFilterParam = `&paymentStatus=${paymentFilter}`;
  }
  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/order?&limit=10&page=${page}&licenseeId=${userDtls.licenseeId}&customer=${custId}${searchParam}${paymentFilterParam}${dateFilter}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_CUSTOMER_ORDER_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_CUSTOMER_ORDER_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* exprtLedger(value) {
  let fileName = "Ledger Report";
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let page = (value.value && value.value.page) || 1;
  let custId =
    typeof value.value === "object" ? value.value.custId : value.value;

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/order?&licenseeId=${userDtls.licenseeId}&customer=${custId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let csvData = [];

      resJSON.rows.map((item) => {
        csvData.push({
          ORDER_ID: item && item.refId ? item.refId : "NIL",
          PRICE:
            item !== null && item.totalPrice
              ? item.currency.symbol + item.totalPrice
              : "NIL",

          PAYMENT_TYPE: item && item.paymentStatus ? item.paymentStatus : "NIL",
          REMAINING_AMOUNT:
            item && item.remainAmount ? item.remainAmount : "NIL",
          STATUS: item && item.status ? item.status : "NIL",
          CREATED_DATE: dateFormat(item.createdAt, "mediumDate"),
        });
      });

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      yield put({
        type: EXPORT_LEDGER_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: EXPORT_LEDGER_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* fetchTotalRemainAmt(value) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let page = (value.value && value.value.page) || 1;
  let custId =
    typeof value.value === "object" ? value.value.custId : value.value;
  let q = typeof value.value === "object" ? value.value.search : null;

  let paymentFilter =
    typeof value.value === "object" ? value.value.paymentFilter : null;

  let dateFilter = "";
  if (typeof value.value === "object" && value.value.dateFilter) {
    dateFilter = value.value.dateFilter;
  }

  let searchParam = "";
  if (q) {
    searchParam = `&search=${q}`;
  }

  let paymentFilterParam = "";
  if (paymentFilter) {
    paymentFilterParam = `&paymentStatus=${paymentFilter}`;
  }
  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/order/outstanding?status=created,delivered&licenseeId=${userDtls.licenseeId}&customer=${custId}${searchParam}${dateFilter}${paymentFilterParam}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_TOTAL_REMAINING_AMT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_TOTAL_REMAINING_AMT_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* fetchCustomerCreditNote(value) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let page = (value.value && value.value.page) || 1;
  let custId =
    typeof value.value === "object" ? value.value.custId : value.value;

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/creditNote?&limit=10&page=${page}&licenseeId=${userDtls.licenseeId}&customer=${custId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_CUSTOMER_CREDIT_NOTES_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_CUSTOMER_CREDIT_NOTES_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* getCreditNoteById(value) {
  let id = value && value.id;

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/creditNote/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      let order = resJSON.returnId.order.id;

      const invoiceData = yield fetch(
        `${appConfig.ip}/invoice?order=${order}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let invoiceResult = yield invoiceData.json();
      let invoiceId = invoiceResult.rows[0].invoiceId;

      let image = resJSON.config.logo;
      const resDocImage = yield fetch(
        `${appConfig.ip}/images/encodeBase64?source=${image}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let resultImage = yield resDocImage.json();

      yield put({
        type: FETCH_CREDIT_NOTE_BY_ID_SUCCESS_ACTION,
        payload: resJSON,
        invoiceId: invoiceId,
        resultImage: resultImage,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_CREDIT_NOTE_BY_ID_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* fetchCreditNoteConfig() {
  let userData = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  let role = localStorage.getItem("sStraitUserRole");
  let invoiveConfigFilter = "";
  let salesFilterparam = "";
  if (role === "distributor") {
    invoiveConfigFilter = `&licenseeId=${userData.licenseeId}`;
  }

  if (role === "agent") {
    salesFilterparam = `&licenseeId=${userData.licenseeId}`;
  }
  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/creditNoteConfig?isActive=true${salesFilterparam}${invoiveConfigFilter}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let data = resJSON.rows;
      // let image = data.length ? data[0].imgUrl : "";

      // const resDocImage = yield fetch(
      //   `${appConfig.ip}/images/encodeBase64?source=${image}`,
      //   {
      //     method: "GET",
      //     headers: {
      //       Accept: "application/json",
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      // let resultImage = yield resDocImage.json();

      yield put({
        type: FETCH_CREDIT_NOTE_CONFIG_SUCCESS_ACTION,
        // payload: resultImage,
        creditNoteConfigData: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_CREDIT_NOTE_CONFIG_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* fetchRouteByCustomer(params) {
  let page = (params.params && params.params.page) || 1;

  let currentUser = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licensee = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  let role = localStorage.getItem("sStraitUserRole");
  let id = localStorage.getItem("sStraitUserId");

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/users/` + id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_ROUTES_BY_CUSTOMER_SUCCESS_ACTION,
        payload: resJSON.routes,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_ROUTES_BY_CUSTOMER_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

export function* CustomersActionWatcher() {
  yield takeEvery(FETCH_CUSTOMERS_INIT_ACTION, fetchCustomers);
  yield takeEvery(ADD_CUSTOMERS_INIT_ACTION, AddCustomer);
  yield takeEvery(EDIT_CUSTOMERS_INIT_ACTION, EditCustomers);

  yield takeEvery(FETCH_CUSTOMERBYID_INIT_ACTION, getCustomerById);
  yield takeEvery(FETCH_REGIONDROPDOWN_INIT_ACTION, fetchRegionDropdown);
  yield takeEvery(FETCH_AREADROPDOWN_INIT_ACTION, fetchAreaDropdown);
  yield takeEvery(
    FETCH_PRODUCTS_BY_CUSTOMER_INIT_ACTION,
    fetchProductsByCustomer
  );
  yield takeEvery(ADD_CUSTOMER_DISCOUNT_INIT_ACTION, addCustomerDiscount);
  yield takeEvery(FETCH_CUSTOMER_DISCOUNT_INIT_ACTION, fetchCustomerDiscount);
  yield takeEvery(EDIT_CUSTOMER_DISCOUNT_INIT_ACTION, editCustomerDiscount);
  yield takeEvery(DELETE_CUSTOMER_DISCOUNT_INIT_ACTION, deleteCustomerDiscount);
  yield takeEvery(
    CHECK_PRODUCTS_BY_CUSTOMER_DISCOUNT_INIT_ACTION,
    checkProdoctByCustomerDiscount
  );
  yield takeEvery(FETCH_CUSTOMER_ORDER_INIT_ACTION, fetchCustomerOrder);
  yield takeEvery(EXPORT_LEDGER_INIT_ACTION, exprtLedger);
  yield takeEvery(FETCH_TOTAL_REMAINING_AMT_INIT_ACTION, fetchTotalRemainAmt);
  yield takeEvery(
    FETCH_CUSTOMER_CREDIT_NOTES_INIT_ACTION,
    fetchCustomerCreditNote
  );
  yield takeEvery(FETCH_CREDIT_NOTE_BY_ID_INIT_ACTION, getCreditNoteById);
  yield takeEvery(FETCH_CREDIT_NOTE_CONFIG_INIT_ACTION, fetchCreditNoteConfig);
  yield takeEvery(FETCH_ROUTES_BY_CUSTOMER_INIT_ACTION, fetchRouteByCustomer);
}
