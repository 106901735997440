import { put, takeEvery, select, call, all } from "redux-saga/effects";
// import { createBrowserHistory } from "history";

import { appConfig } from "../../config";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import * as actionTypes from "./constants";

//common function
function* commonFunction(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });

    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();
      yield put({ type: `${value.initAction}` });
      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: `${value.failAction}`, error: err });
    } else {
    }
  }
}

function* fetchPageList(value) {
  const token = value.value.access_token
    ? value.value
    : yield call(fetchExtendedTokn, value);

  try {
    let params = {
      // api: `${appConfig.fbip}/${value.value.userID}/accounts?access_token=${token.access_token}`,
      api: `${appConfig.ip}/facebook/accounts?fb_access_token=${token.access_token}`,
      method: "GET",
      sucessAction: actionTypes.FETCH_PAGELIST_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_PAGELIST_FAIL_ACTION,
      body: null,
    };
    const data = yield call(commonFunction, params);
  } catch (e) {}
}

function* fetchFormList(value) {
  try {
    let params = {
      // api: `${appConfig.fbip}/${value.value.id}/leadgen_forms?access_token=${value.value.access_token}`,
      api: `${appConfig.ip}/facebook/forms?fb_page_token=${value.value.access_token}&pageid=${value.value.id}`,
      method: "GET",
      sucessAction: actionTypes.FETCH_FORMLIST_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_FORMLIST_FAIL_ACTION,
      body: null,
    };
    const data = yield call(commonFunction, params);
  } catch (e) {}
}

function* fetchExtendedTokn(value) {
  try {
    let params = {
      api: `${appConfig.ip}/facebook/extendedtoken?fb_exchange_token=${value.value.accessToken}`,
      method: "GET",
      sucessAction: actionTypes.FETCH_EXTENDEDTOKN_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_EXTENDEDTOKN_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

function* postFacebookData(value) {
  var mappingResult = value.value.mappingField.map((item) => ({
    ssKey: item.ssKey,
    fbType: item.type,
    fbLabel: item.label,
    fbKey: item.key,
  }));

  const getFbData = yield call(getFaceookList, value);

  if (getFbData.count == 0) {
    let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));

    let licProfile = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

    const state = yield select();
    let extndTokn =
      state.facebookReducer.extndedTokn !== undefined
        ? state.facebookReducer.extndedTokn.access_token
        : licProfile.facebook.accessToken;

    const data = {
      name: value.value.userData.name
        ? value.value.userData.name
        : licProfile.facebook.name,
      userId: value.value.userData.userID
        ? value.value.userData.userID
        : licProfile.facebook.user,
      email: value.value.userData.email
        ? value.value.userData.email
        : licProfile.facebook.email,
      pageName: value.value.value.page[0].name,
      pageId: value.value.value.page[0].id,
      formName: value.value.value.form[0].name,
      formId: value.value.value.form[0].id,
      // _id: value.value.userData.id,
      licenseeId: userDtls.licenseeId,
      userAccessToken: extndTokn,
      pageAccessToken: value.value.value.page[0].access_token,
      projectId: licProfile.projectId,
      mapping: mappingResult,
    };

    try {
      let params = {
        api: `${appConfig.ip}/facebook`,
        method: "POST",
        sucessAction: actionTypes.POST_FACEBOOKDATA_SUCCESS_ACTION,
        failAction: actionTypes.POST_FACEBOOKDATA_FAIL_ACTION,
        body: JSON.stringify(data),
        toastMsg: "Successfully added",
        initAction: actionTypes.FETCH_FACEBOOKLIST_INIT_ACTION,
      };

      const res = yield call(commonFunction, params);
      if (res) {
        yield call(licenseeProfUpdate, data);
        yield call(fbSubscribePage, data);
      }
    } catch (e) {}
  } else {
    yield toast.error("Form already exist", {
      autoClose: 3000,
    });
  }
}

function* fbSubscribePage(value) {
  let pageId = value.pageId;
  let pageToken = value.pageAccessToken;

  try {
    let params = {
      api: `${appConfig.ip}/facebook/subscribepage?pageid=${pageId}&fb_page_token=${pageToken}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

function* licenseeProfUpdate(value) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let id = userDtls.id;

  const data = {
    facebook: {
      user: value.userId,
      accessToken: value.userAccessToken,
      name: value.name,
      email: value.email,
    },
  };
  try {
    let params = {
      api: `${appConfig.ip}/licenseeProf/${id}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
      toastMsg: null,
    };
    const res = yield call(commonFunction, params);
    let profile = {
      ...JSON.parse(localStorage.getItem("sStraitLicProfDtls")),
      ...data,
    };
    localStorage.setItem("sStraitLicProfDtls", JSON.stringify(profile));
  } catch (e) {}
}

function* getFaceookList(value) {
  let licenseeId = JSON.parse(localStorage.getItem("sStraitUserDtls"))
    .licenseeId;
  let pageId = value.value.value.page[0].id;
  let formId = value.value.value.form[0].id;

  try {
    let params = {
      api: `${appConfig.ip}/facebook?licenseeId=${licenseeId}&pageId=${pageId}&formId=${formId}&status=created`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

function* fetchFacebookList() {
  let licenseeId = JSON.parse(localStorage.getItem("sStraitUserDtls"))
    .licenseeId;

  try {
    let params = {
      api: `${appConfig.ip}/facebook?licenseeId=${licenseeId}&status=created`,
      method: "GET",
      sucessAction: actionTypes.FETCH_FACEBOOKLIST_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_FACEBOOKLIST_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

function* DeleteValue(value) {
  const token = localStorage.getItem("sStraitToken");
  let id = value.value.id;
  try {
    const res = yield fetch(`${appConfig.ip}/facebook/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield toast.success("Successfully deleted", {
        autoClose: 3000,
      });
      yield put({
        type: actionTypes.DELETE_FACEBOOKDATA_SUCCESS_ACTION,
        payload: id,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.DELETE_FACEBOOKDATA_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* fetchFormFields(value) {
  try {
    let params = {
      api: `${appConfig.ip}/facebook/formQuestions?fb_page_token=${value.value.pageTokn}&formid=${value.value.formId}`,
      method: "GET",
      sucessAction: actionTypes.FETCH_FORMFIELDS_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_FORMFIELDS_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

function* upadteForm(value) {
  const data = {
    mapping: value.value.mappingField,
  };

  try {
    let params = {
      api: `${appConfig.ip}/facebook/${value.value.id}`,
      method: "PUT",
      sucessAction: actionTypes.UPDATE_FACEBOOK_SUCCESS_ACTION,
      failAction: actionTypes.UPDATE_FACEBOOK_FAIL_ACTION,
      body: JSON.stringify(data),
      toastMsg: "Successfully updated",
      initAction: actionTypes.FETCH_FACEBOOKLIST_INIT_ACTION,
    };
    const res = yield call(commonFunction, params);
  } catch (e) {}
}

export function* facebookActionWatcher() {
  yield takeEvery(actionTypes.FETCH_PAGELIST_INIT_ACTION, fetchPageList);
  yield takeEvery(actionTypes.FETCH_FORMLIST_INIT_ACTION, fetchFormList);
  yield takeEvery(actionTypes.POST_FACEBOOKDATA_INIT_ACTION, postFacebookData);
  yield takeEvery(
    actionTypes.FETCH_EXTENDEDTOKN_INIT_ACTION,
    fetchExtendedTokn
  );
  yield takeEvery(
    actionTypes.FETCH_FACEBOOKLIST_INIT_ACTION,
    fetchFacebookList
  );
  yield takeEvery(actionTypes.DELETE_FACEBOOKDATA_INIT_ACTION, DeleteValue);
  yield takeEvery(actionTypes.FETCH_FORMFIELDS_INIT_ACTION, fetchFormFields);
  yield takeEvery(actionTypes.UPDATE_FACEBOOK_INIT_ACTION, upadteForm);
}
