import { put, takeEvery, select } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import * as actionTypes from "./constants";
import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import ToastrMsg from "../../screens/Common/ToasteMag";
// import * as selectors from "../../screens/Common/selectors";

var btoa = require("Base64").btoa;
var history = createBrowserHistory();

function* fetchnotifications(params) {
  let page = (params.params && params.params.page) || 1;
  let currentUser = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  let userId = currentUser.id;
  let events =
    "wdrlReqAccepted,orderAccepted,customerCreation,userEarningCreation,expenseCreation,leadCreation,orderCreation,paymentCreation,rewardsCreation,socialLeadCreation,wdrlReqCreation";

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/notfctn?limit=10&page=${page}&user=${userId}&event=${events}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_NOTIFICATIONS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_NOTIFICATIONS_ERROR_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* updateNotification(params) {
  let id = params.params.id;

  const data = {
    read: true,
  };

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/notfctn/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      yield put({
        type: actionTypes.UPDATE_NOTIFICATIONS_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield put({
        type: actionTypes.FETCH_NOTIFICATIONS_INIT_ACTION,
      });
      yield put({
        type: actionTypes.FETCH_UNREADNOTIFICATIONS_INIT_ACTION,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_NOTIFICATIONS_SUCCESS_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* unreadnotifications(value) {
  let fromPusher = (value.value && value.value.fromPusher) || false;
  let currentUser = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let userId = currentUser.id;

  let events =
    "wdrlReqAccepted,orderAccepted,customerCreation,userEarningCreation,expenseCreation,leadCreation,orderCreation,paymentCreation,rewardsCreation,socialLeadCreation,wdrlReqCreation";
  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/notfctn?read=false&event=${events}&user=${userId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_UNREADNOTIFICATIONS_SUCCESS_ACTION,
        payload: resJSON,
      });
      if (fromPusher) {
        yield toast.success(
          resJSON && resJSON.rows[0] && resJSON.rows[0].message,
          {
            autoClose: 3000,
          }
        );
      }
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_UNREADNOTIFICATIONS_ERROR_ACTION,
        error: err,
      });
    } else {
    }
  }
}

export function* notificationActionWatcher() {
  yield takeEvery(
    actionTypes.FETCH_NOTIFICATIONS_INIT_ACTION,
    fetchnotifications
  );
  yield takeEvery(
    actionTypes.UPDATE_NOTIFICATIONS_INIT_ACTION,
    updateNotification
  );
  yield takeEvery(
    actionTypes.FETCH_UNREADNOTIFICATIONS_INIT_ACTION,
    unreadnotifications
  );
}
