export const permissionData = {
  navDistributorNW: {
    menu: [
      "dashboard",
      "user",
      "agency",
      "product",
      "disproductInventory",
      "customers",
      "order",
      "salesreturn",
      "lead",
      "Followup",
      "Quotation",
      "expense",
      "payments",
      "invoices",
      "myearnings",
      "incentivebucket",
      "report",
      "",
    ],
  },
};
