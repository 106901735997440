import { put, takeEvery } from "redux-saga/effects";
import {
    RESETPWD_INIT_ACTION ,
    RESETPWD_SUCCESS_ACTION ,
    RESETPWD_FAIL_ACTION ,
    VERIFYPWDOTP_INIT_ACTION,
    VERIFYPWDOTP_SUCCESS_ACTION,
    VERIFYPWDOTP_FAIL_ACTION,
    RESENDPWDOTP_INIT_ACTION,
    RESENDPWDOTP_SUCCESS_ACTION,
    RESENDPWDOTP_FAIL_ACTION,
    SETNEWPWD_INIT_ACTION,
    SETNEWPWD_SUCCESS_ACTION,
    SETNEWPWD_FAIL_ACTION,
    REDIRECT_INIT_ACTION,
    REDIRECT_SUCCESS_ACTION,
} from "./action";
import { appConfig } from "../../config";
import { toast, Flip } from "react-toastify";
import { push } from "connected-react-router";
import "react-toastify/dist/ReactToastify.css";

function* sendOtpforResetPwd(value) {
  try {
      const data = {mobileNo:value.value}
    const res = yield fetch(`${appConfig.ip}/password-resets/sendotp`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();
      yield put({
        type: RESETPWD_SUCCESS_ACTION,
        payload: resJSON,
      });

      yield toast.success("An OTP has been sent to the registered mobile number", {
        autoClose: 3000,
        transition:Flip
      });
      //yield put(push("/verifyotp"));
    }
  } catch (err) {
    if (err.status === 404) {
      yield toast.error("User not found!! Try again with the registered mobile number", {
        autoClose: 3000,
        transition:Flip
      });
    }

    yield put({
      type: RESETPWD_FAIL_ACTION,
      payload: err,
    });
  }
}

function* verifyOtpforResetPwd(value) {
    try {
        const data = {mobileNo:value.value.mobileNo,pin:value.value.otp}
      const res = yield fetch(`${appConfig.ip}/password-resets/checkpin/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
  
      if (!res.ok) {
        throw res;
      } else {
        const resJSON = yield res.json();
        yield put({
          type: VERIFYPWDOTP_SUCCESS_ACTION,
          payload: resJSON,
        });
        if(resJSON.ok === true){
            yield toast.success("OTP Verified", {
              autoClose: 3000,
              transition:Flip
            });
        }else if(resJSON.ok === false&& resJSON.attemptLeft!==0){
          yield toast.error("You have entered a wrong OTP. You have "+resJSON.attemptLeft+" attempts left", {
            autoClose: 3000,
            transition:Flip
          });
        }else if(resJSON.ok === false&& resJSON.attemptLeft === 0){
          yield toast.error("Redirecting to login page, since you have used your maximum attempts", {
            autoClose: 3000,
            transition:Flip
          });

          yield put({
            type:REDIRECT_INIT_ACTION
          });
        }
        
        //yield put(push("/verifyotp"));
      }
    } catch (err) {
      if (err.status === 404) {
        yield toast.error("Redirecting to login page, since you have used your maximum attempts", {
          autoClose: 5000,
          transition:Flip
        });
      }

      yield put({
        type:REDIRECT_INIT_ACTION
      });

      yield put({
        type: VERIFYPWDOTP_FAIL_ACTION,
        payload: err,
      });
    }
  }

  function* resendOtpforResetPwd(value) {
    try {
        const data = {mobileNo:value.value}
      const res = yield fetch(`${appConfig.ip}/password-resets/resendotp`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
  
      if (!res.ok) {
        throw res;
      } else {
        const resJSON = yield res.json();
        yield put({
          type: RESENDPWDOTP_SUCCESS_ACTION,
          payload: resJSON,
        });
        yield toast.success("OTP resend to the registered mobile number", {
          autoClose: 3000,
          transition:Flip
        });
        //yield put(push("/verifyotp"));
      }
    } catch (err) {
      if (err.status === 404) {
        yield toast.error("User not found!! Try again ", {
          autoClose: 3000,
          transition:Flip
        });
      }
  
      yield put({
        type: RESENDPWDOTP_FAIL_ACTION,
        payload: err,
      });
    }
  }

  function* resetPwd(value) {
    try {
        const data = {mobileNo:value.value.mobileNo,pin:value.value.otp,password:value.value.pwd}
      const res = yield fetch(`${appConfig.ip}/password-resets/change`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
  
      if (!res.ok) {
        throw res;
      } else {
        const resJSON = yield res.json();
        yield put({
          type: SETNEWPWD_SUCCESS_ACTION,
          payload: resJSON,
        });
        /* if(resJSON.ok === true){
            yield toast.success("OTP Verified", {
                autoClose: 3000,
            });
        }else if(resJSON.ok === false){
            yield toast.error("OTP mismatch. Please try again. You have "+resJSON.attemptLeft+" attempts left", {
                autoClose: 3000,
                position: "top-center",
              });
        } */
        
        //yield put(push("/verifyotp"));
      }
    } catch (err) {
      if (err.status === 401) {
        yield toast.error("Unexpected Error", {
          autoClose: 3000,
          transition:Flip
        });
      }
  
      yield put({
        type: SETNEWPWD_FAIL_ACTION,
        payload: err,
      });
    }
  }

  function* redirectTologin() {
    yield put({
      type: REDIRECT_SUCCESS_ACTION,
    });
    yield put(push("/login"));
  }

export function* ResetPwdActionWatcher() {
  yield takeEvery(RESETPWD_INIT_ACTION, sendOtpforResetPwd);
  yield takeEvery(VERIFYPWDOTP_INIT_ACTION, verifyOtpforResetPwd);
  yield takeEvery(RESENDPWDOTP_INIT_ACTION, resendOtpforResetPwd);
  yield takeEvery(SETNEWPWD_INIT_ACTION, resetPwd);
  yield takeEvery(REDIRECT_INIT_ACTION, redirectTologin);
}
