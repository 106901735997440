export const LOGIN_INIT_ACTION = "LOGIN_INIT";
export const FETCH_LOGIN_FAIL_ACTION = LOGIN_INIT_ACTION + "_ERROR";
export const FETCH_LOGIN_SUCCESS_ACTION = LOGIN_INIT_ACTION + "_SUCCESS";
export const LOGOUT_ACTION = "LOGOUT";

export const FETCH_CHECKSTATUS_INIT_ACTION = "FETCH_CHECKSTATUS_INIT_ACTION";
export const FETCH_CHECKSTATUS_SUCCESS_ACTION = FETCH_CHECKSTATUS_INIT_ACTION + "_SUCCESS";
export const FETCH_CHECKSTATUS_FAIL_ACTION = FETCH_CHECKSTATUS_INIT_ACTION + "_ERROR";


export function login(cred) {

  return {
    type: LOGIN_INIT_ACTION,
    cred: cred,
  };
}

export function logOut() {
  
  return {
    type: LOGOUT_ACTION,
  };
}

export function fetchStatus(value) {

  return {
    type: FETCH_CHECKSTATUS_INIT_ACTION,
    value: value

  };
}