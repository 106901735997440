import { put, takeEvery, select, call, all } from "redux-saga/effects";
// import { quotationExportToPdf } from "../../screens/Quotations/quotationToPdf";

import { createBrowserHistory } from "history";
import {
  FETCH_QUOTATIONS_INIT_ACTION,
  FETCH_QUOTATIONS_SUCCESS_ACTION,
  FETCH_QUOTATIONS_FAIL_ACTION,
  FETCH_QUOTATIONSBYID_INIT_ACTION,
  FETCH_QUOTATIONSBYID_SUCCESS_ACTION,
  FETCH_QUOTATIONSBYID_FAIL_ACTION,
  PDF_QUOTATION_REPORT_INIT_ACTION,
  PDF_QUOTATION_REPORT_SUCCESS_ACTION,
  PDF_QUOTATION_REPORT_FAIL_ACTION,
  ADD_QUOTATION_INIT_ACTION,
  ADD_QUOTATION_SUCCESS_ACTION,
  ADD_QUOTATION_FAIL_ACTION,
  EDIT_QUOTATION_INIT_ACTION,
  EDIT_QUOTATION_SUCCESS_ACTION,
  EDIT_QUOTATION_FAIL_ACTION,
  CHANGE_QUOTATION_STATUS_INIT_ACTION,
  CHANGE_QUOTATION_STATUS_SUCCESS_ACTION,
  CHANGE_QUOTATION_STATUS_FAIL_ACTION,
  ADD_QUOTATION_FIELD_SUGGESTION_INIT_ACTION,
  ADD_QUOTATION_FIELD_SUGGESTION_SUCCESS_ACTION,
  ADD_QUOTATION_FIELD_SUGGESTION_FAIL_ACTION,
  FETCH_QUOTATION_FIELD_SUGGESTION_INIT_ACTION,
  FETCH_QUOTATION_FIELD_SUGGESTION_SUCCESS_ACTION,
  FETCH_QUOTATION_FIELD_SUGGESTION_FAIL_ACTION,
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable";

var dateFormat = require("dateformat");

// Common functions

function* getRoleApiUrl() {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const role = localStorage.getItem("sStraitUserRole");
  const userId = localStorage.getItem("sStraitUserId");

  let getDataUrl = [
    { role: "licensee", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "distributor", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "licenseeAccountant", url: "&licenseeId=" + userDtls.licenseeId },
    {
      role: "regionalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&region=" + userDtls.region,
    },
    {
      role: "zonalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&zone=" + userDtls.zone,
    },
    {
      role: "areaManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
    {
      role: "salesExcecutive",
      url: "&licenseeId=" + userDtls.licenseeId + "&salesExecutive=" + userId,
    },
    {
      role: "agent",
      url: "&licenseeId=" + userDtls.licenseeId + "&salesExecutive=" + userId,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);
  return apiUrl;
}

function* commonFunction(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      let resultImage = null;
      if (value.isImageView) {
        let image = resJSON && resJSON.config && resJSON.config.logo;

        const resDocImage = yield fetch(
          `${appConfig.ip}/images/encodeBase64?source=${image}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        resultImage = yield resDocImage.json();
        // quotationExportToPdf(resJSON, resultImage);
      }

      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
        imageData: resultImage,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: `${value.failAction}`, error: err });
    } else {
    }
  }
}

function* fetchQuotations(value) {
  let pageno = value.value.page === undefined ? 1 : value.value.page;
  const apiUrl = yield call(getRoleApiUrl);
  try {
    let params = {
      api: `${appConfig.ip}/quote?limit=10&page=${pageno}&isActive=true&${apiUrl.url}`,
      method: "GET",
      sucessAction: FETCH_QUOTATIONS_SUCCESS_ACTION,
      failAction: FETCH_QUOTATIONS_FAIL_ACTION,
      body: null,
    };
    const quoteRes = yield call(commonFunction, params);
  } catch (e) {}
}

function* getQuotationsById(value) {
  try {
    let params = {
      api: `${appConfig.ip}/quote/${value.value}`,
      method: "GET",
      sucessAction: FETCH_QUOTATIONSBYID_SUCCESS_ACTION,
      failAction: FETCH_QUOTATIONSBYID_FAIL_ACTION,
      body: null,
      isImageView: value.isImageView,
    };
    const quoteByIdRes = yield call(commonFunction, params);
  } catch (e) {}
}

function* AddQuotation(value) {
  const token = localStorage.getItem("sStraitToken");
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const total = value.value.totalAmnt;

  let role = userDtls.role;
  const licDetails = yield call(getLicenseeProf);
  const quoteConfig = yield call(getQuoteConfig);
  let quoteConfigData = quoteConfig.rows;

  let data = {
    initiator: role,
    customer: value.value.customer[0].id,
    description: value.value.description,
    quantity: value.value.quantity,
    product: value.value.products[0].id,
    licenseeId: userDtls.licenseeId,
    sellingPrice: value.value.sellingPrice,
    buyingPrice: value.value.buyingPrice,
    gstSlab: value.value.products[0].gstSlab.id,
    currency: value.value.currency,
    salesExecutive: userDtls.id,
    unit: value.value.unit,
    totalPrice: total,
    discount: value.value.discount ? value.value.discount : 0,
    commission: value.value.commission,
    gstAmount: value.value.gstAmt,
    status: "active",
    additionalCharges: value.value.additionalCharges,

    category: value.value.customer[0].category,
    taxModel: value.value.customer[0].taxModel,

    customerInfo: {
      name: value.value.customer[0].name,
      address: value.value.customer[0].address,
      mobNo: value.value.customer[0].mobileNo,
      email: value.value.customer[0].email,
      gstin: value.value.customer[0].gstin,
    },
    licensee: {
      orgName: licDetails.orgName,
      address: licDetails.addr1 ? licDetails.addr1 : "",
      contactNo: licDetails.contactNo ? licDetails.contactNo : "",
      contactEmail: licDetails.contactEmail ? licDetails.contactEmail : "",
      gstin: licDetails.gstin ? licDetails.gstin : "",
    },
    config: {
      remarks: quoteConfigData && quoteConfigData[0].remark,
      logo: quoteConfigData && quoteConfigData[0].imgUrl,
      addtCustInfo: quoteConfigData && quoteConfigData[0].addtCustInfo,
    },
  };

  if (role === "licensee" || role === "licenseeAccountant") {
    const dummyIds = yield call(getDummyData, userDtls);
    data = {
      ...data,
      region: dummyIds && dummyIds.region && dummyIds.region.id,
      zone: dummyIds && dummyIds.zone && dummyIds.zone.id,
      area: dummyIds && dummyIds.area && dummyIds.area.id,
    };
  } else if (role === "regionalManager") {
    const dummyIds = yield call(getDummyData, userDtls);
    data = {
      ...data,
      region: userDtls.region,
      zone: dummyIds.zone.id,
      area: dummyIds.area.id,
    };
  } else if (role === "zonalManager") {
    const dummyIds = yield call(getDummyData, userDtls);
    data = {
      ...data,
      region: userDtls.region,
      zone: userDtls.zone,
      area: dummyIds.area.id,
    };
  } else if (role === "areaManager" || role === "salesExcecutive") {
    data = {
      ...data,
      region: userDtls.region,
      zone: userDtls.zone,
      area: userDtls.area,
    };
  }

  try {
    const res = yield fetch(`${appConfig.ip}/quote`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON1 = {};
      try {
        errJSON1 = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON1);
    } else {
      const resJSON = yield res.json();

      yield put({ type: FETCH_QUOTATIONS_INIT_ACTION, value: { page: 1 } });
      yield put({
        type: ADD_QUOTATION_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Quotation added successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_QUOTATION_FAIL_ACTION,
        error: err,
      });
    }
  }
}

// Update quote
function* EditQuotation(value) {
  const token = localStorage.getItem("sStraitToken");
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const total = value.value.totalAmnt;

  let role = userDtls.role;
  const licDetails = yield call(getLicenseeProf);
  const quoteConfig = yield call(getQuoteConfig);
  let quoteConfigData = quoteConfig.rows;

  const data = {
    initiator: role,
    customer: value.value.customer[0].id,
    description: value.value.description,
    quantity: value.value.quantity,
    product: value.value.products[0].id,
    licenseeId: userDtls.licenseeId,
    sellingPrice: value.value.sellingPrice,
    buyingPrice: value.value.buyingPrice,
    gstSlab: value.value.gstSlab.id,
    currency: value.value.currency,
    salesExecutive: userDtls.id,
    region: userDtls.region,
    zone: userDtls.zone,
    area: userDtls.area,
    unit: value.value.unit,
    totalPrice: total,
    discount: value.value.discount ? value.value.discount : 0,
    commission: value.value.commission,
    gstAmount: value.value.gstAmt,

    category: value.value.customer[0].category,
    taxModel: value.value.customer[0].taxModel,
    customerInfo: {
      name: value.value.customer[0].name,
      address: value.value.customer[0].address,
      mobNo: value.value.customer[0].mobileNo,
      email: value.value.customer[0].email,
      gstin: value.value.customer[0].gstin,
    },
    licensee: {
      orgName: licDetails.orgName,
      address: licDetails.addr1 ? licDetails.addr1 : "",
      contactNo: licDetails.contactNo ? licDetails.contactNo : "",
      contactEmail: licDetails.contactEmail ? licDetails.contactEmail : "",
      gstin: licDetails.gstin ? licDetails.gstin : "",
    },
    config: {
      remarks: quoteConfigData && quoteConfigData[0].remark,
      logo: quoteConfigData && quoteConfigData[0].imgUrl,
      addtCustInfo: quoteConfigData && quoteConfigData[0].addtCustInfo,
    },
  };

  try {
    const res = yield fetch(`${appConfig.ip}/quote/${value.value.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON1 = {};
      try {
        errJSON1 = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON1);
    } else {
      const resJSON = yield res.json();
      const qouteId = yield call(getQuotationsById, { value: resJSON.id });
      yield put({
        type: EDIT_QUOTATION_SUCCESS_ACTION,
        payload: qouteId,
      });
      yield toast.success("Quotation updated successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: EDIT_QUOTATION_FAIL_ACTION,
        error: err,
      });
    }
  }
}

// Status change
function* changeStatus(value) {
  let data = {
    status: value.value.status === "expired" ? "active" : "expired",
  };
  try {
    let params = {
      api: `${appConfig.ip}/quote/${value.value.id}`,
      method: "PUT",
      sucessAction: CHANGE_QUOTATION_STATUS_SUCCESS_ACTION,
      failAction: CHANGE_QUOTATION_STATUS_FAIL_ACTION,
      body: JSON.stringify(data),
      toastMsg: "Status updated successfully",
    };
    const quoteRes = yield call(commonFunction, params);
  } catch (e) {}
}

//Get dummy data for creating quotation
function* getDummyData(value) {
  try {
    let params = {
      api: `${appConfig.ip}/users?licenseeId=${value.licenseeId}&dummy=true&role=areaManager`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const dummyRes = yield call(commonFunction, params);
    return dummyRes.rows[0];
  } catch (e) {}
}

// Pdf download
// function* fetchPdfQuotationReport(value) {
//   const token = localStorage.getItem("sStraitToken");
//   const refId = value.value.refId;
//   // const orderRefId = value.value.orderRefId;
//   const OrgName = value.value.licenseeId.orgName;

//   const CustName = value.value.customer.name;
//   const Mobile = value.value.customer.mobileNo;
//   let addr1 = JSON.parse(localStorage.getItem("sStraitLicProfDtls")).addr1;
//   let licMob = JSON.parse(localStorage.getItem("sStraitLicProfDtls")).userId
//     .mobileNo;
//   let licState = JSON.parse(localStorage.getItem("sStraitLicProfDtls")).district
//     .name;
//   let licDistrict = JSON.parse(localStorage.getItem("sStraitLicProfDtls"))
//     .district.name;
//   let licEmail = JSON.parse(localStorage.getItem("sStraitLicProfDtls")).userId
//     .email;

//   const Date = dateFormat(value.value.createdAt, "mediumDate");

//   try {
//     const res = yield fetch(`${appConfig.ip}/quote/` + value.value.id, {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     if (!res.ok) {
//       throw res;
//     } else {
//       const resJSON = yield res.json();
//       const CustAddress = resJSON.customer.address;
//       const CustMob = resJSON.customer.mobileNo;

//       const count = resJSON.count;
//       const doc = new jsPDF();
//       const tableColumn = [
//         "SL NO",
//         "PRODUCT",
//         "QTY",
//         "UNIT PRICE",
//         "DISCOUNT",
//         "GST %",
//         "TOTAL AMOUNT",
//       ];
//       const tableRows = [];

//       const ArrayConv = resJSON;
//       const reportData = [
//         1,
//         ArrayConv.product.name,
//         ArrayConv.quantity,
//         ArrayConv.product.sellingPrice,
//         ArrayConv.discount,
//         ArrayConv.gstSlab.prcntg,

//         ArrayConv.totalPrice,
//       ];

//       tableRows.push(reportData);
//       doc.autoTable(tableColumn, tableRows, {
//         theme: "grid",
//         styles: {
//           halign: "left",
//         },
//         headerStyles: {
//           fillColor: [105, 105, 105],
//           halign: "center",
//         },
//         margin: {
//           top: 125,
//         },
//       });
//       doc.setFontSize(10);
//       doc.text(`${OrgName}`, 14, 45);
//       doc.setFontSize(10);
//       doc.setFontSize(10);
//       doc.text(`${licMob}`, 14, 50);
//       doc.setFontSize(10);
//       doc.text(`${licEmail}`, 14, 55);
//       var str = doc.splitTextToSize(`${addr1}`, 50);
//       doc.text(str, 14, 60);
//       // doc.setFontSize(10);
//       // doc.text(`${licDistrict}`, 14, 60,);
//       // doc.setFontSize(10);
//       // doc.text(`${licState}`, 14, 65,);
//       doc.setFontSize(10);
//       doc.text(`${CustName}`, 14, 80);
//       // doc.setFontSize(10);
//       // doc.text(`${CustName}`, 145, 80,);
//       doc.setFontSize(10);
//       doc.text(`Quotation No : ${refId}`, 145, 45);
//       doc.setFontSize(10);
//       doc.text(`${CustMob}`, 14, 85);

//       // doc.setFontSize(10);
//       // doc.text(`${Mobile}`, 145, 85,);
//       doc.setFontSize(10);
//       doc.text(`Date : ${Date}`, 145, 50);
//       doc.setFontSize(10);
//       var str = doc.splitTextToSize(`${CustAddress}`, 50);
//       doc.text(str, 14, 90);
//       // doc.setFontSize(10);
//       // var str = doc.splitTextToSize(`${CustAddress}`, 50)
//       // doc.text(str, 145, 90);
//       // doc.setFontSize(10);
//       // doc.text(`${Email}`, 145, 75);
//       // doc.setFontSize(10);
//       // doc.text(`Order No : ${orderRefId}`, 14, 115,);
//       // doc.setFontSize(10);
//       // doc.text(`Order Date : ${OrderDate}`, 14, 120,);
//       // doc.setFontSize(11);

//       doc.setFontSize(10);
//       doc.text(`Insurance Amount`, 140, 150);
//       doc.text(`: 4562`, 170, 150);
//       doc.setFontSize(10);
//       doc.text(`Tax`, 140, 155);
//       doc.text(`: 4562`, 170, 155);
//       doc.setFontSize(10);
//       doc.text(`Registration Fee`, 140, 160);
//       doc.text(`: 4562`, 170, 160);
//       doc.setFontSize(10);
//       doc.text(`Accessories`, 140, 165);
//       doc.text(`: 4562`, 170, 165);
//       doc.setFontSize(10);
//       doc.text(`Extended Warranty`, 140, 170);
//       doc.text(`: 4562`, 170, 170);
//       doc.setFontSize(13);
//       doc.setFont("helvetica", "bold");
//       doc.text(`Grand Total`, 140, 180);
//       doc.text(` 456200`, 170, 180);

//       doc.text(`For ${OrgName}:`, 155, 155);
//       doc.setFontSize(11);
//       doc.text("Authorized Signature", 155, 170);
//       doc.setFontSize(13);

//       doc.text("Thank You for your Business", 14, 195);
//       //Bold Text
//       // doc.setFontSize(12);
//       // doc.setFont('Roboto-Regular', 'bold');
//       // doc.text(`Sold By`, 14, 40,);
//       doc.setFontSize(12);
//       doc.text(`To,`, 14, 75);
//       // doc.setFontSize(12);
//       // doc.text(`Shipping Address`, 145, 75,);
//       doc.setFontSize(18);
//       doc.text(`QUOTATION`, 85, 20);
//       doc.setFontSize(13);
//       doc.text(`${OrgName}`, 14, 200);

//       doc.save(`${refId}.pdf`);

//       yield put({
//         type: PDF_QUOTATION_REPORT_SUCCESS_ACTION,
//         payload: resJSON,
//       });
//     }
//   } catch (err) {
//     if (err.ok === false) {
//       yield put({
//         type: PDF_QUOTATION_REPORT_FAIL_ACTION,
//         error: err,
//       });
//     } else {
//     }
//   }
// }

// function* fetchPdfQuotationReport(value) {
//   const token = localStorage.getItem("sStraitToken");
//   const refId = value.value.refId;
//   // const orderRefId = value.value.orderRefId;
//   const OrgName = value.value.licenseeId.orgName;

//   const CustName = value.value.customer.name;
//   const Mobile = value.value.customer.mobileNo;
//   let addr1 = JSON.parse(localStorage.getItem("sStraitLicProfDtls")).addr1;
//   let licMob = JSON.parse(localStorage.getItem("sStraitLicProfDtls")).userId
//     .mobileNo;
//   let licState = JSON.parse(localStorage.getItem("sStraitLicProfDtls")).district
//     .name;
//   let licDistrict = JSON.parse(localStorage.getItem("sStraitLicProfDtls"))
//     .district.name;
//   let licEmail = JSON.parse(localStorage.getItem("sStraitLicProfDtls")).userId
//     .email;

//   let image = JSON.parse(localStorage.getItem("sStraitLicProfDtls")).userId
//     .picture;

//   const Date = dateFormat(value.value.createdAt, "mediumDate");

//   try {
//     const res = yield fetch(`${appConfig.ip}/quote/` + value.value.id, {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     if (!res.ok) {
//       throw res;
//     } else {
//       const resJSON = yield res.json();
//       const CustAddress = resJSON.customer.address;
//       const CustMob = resJSON.customer.mobileNo;

//       const count = resJSON.count;
//       const doc = new jsPDF();
//       const tableColumn = [
//         "SL NO",
//         "PRODUCT",
//         "QTY",
//         "UNIT PRICE",
//         "DISCOUNT",
//         "GST %",
//         "AMOUNT",
//       ];
//       const tableRows = [];

//       const ArrayConv = resJSON;
//       const reportData = [
//         1,
//         ArrayConv.product.name,
//         ArrayConv.quantity,
//         ArrayConv.product.sellingPrice,
//         ArrayConv.discount,
//         ArrayConv.gstSlab.prcntg,

//         ArrayConv.totalPrice,
//       ];

//       tableRows.push(reportData);
//       doc.autoTable(tableColumn, tableRows, {
//         theme: "grid",
//         styles: {
//           halign: "left",
//         },
//         headerStyles: {
//           fillColor: [105, 105, 105],
//           halign: "center",
//         },
//         margin: {
//           top: 125,
//         },
//       });

//       doc.setFontSize(10);
//       doc.text(`${OrgName}`, 14, 45);
//       doc.setFontSize(10);
//       doc.setFontSize(10);
//       // doc.text(`${licMob}`, 14, 50);
//       doc.text(`8714603703`, 14, 50);
//       doc.setFontSize(10);
//       doc.text(`${licEmail}`, 14, 55);
//       var str = doc.splitTextToSize(`${addr1}`, 50);
//       doc.text(str, 14, 60);
//       // doc.setFontSize(10);
//       // doc.text(`${licDistrict}`, 14, 60,);
//       // doc.setFontSize(10);
//       // doc.text(`${licState}`, 14, 65,);
//       doc.setFontSize(10);
//       doc.text(`${CustName}`, 14, 80);
//       // doc.setFontSize(10);
//       // doc.text(`${CustName}`, 145, 80,);
//       doc.setFontSize(10);
//       doc.text(`Quotation No : ${refId}`, 145, 45);
//       doc.setFontSize(10);
//       doc.text(`${CustMob}`, 14, 85);

//       // doc.setFontSize(10);
//       // doc.text(`${Mobile}`, 145, 85,);
//       doc.setFontSize(10);
//       doc.text(`Date : ${Date}`, 145, 50);
//       doc.setFontSize(10);
//       var str = doc.splitTextToSize(`${CustAddress}`, 50);
//       doc.text(str, 14, 90);
//       // doc.setFontSize(10);
//       // var str = doc.splitTextToSize(`${CustAddress}`, 50)
//       // doc.text(str, 145, 90);
//       // doc.setFontSize(10);
//       // doc.text(`${Email}`, 145, 75);
//       // doc.setFontSize(10);
//       // doc.text(`Order No : ${orderRefId}`, 14, 115,);
//       // doc.setFontSize(10);
//       // doc.text(`Order Date : ${OrderDate}`, 14, 120,);
//       // doc.setFontSize(11);
//       if (
//         value.value &&
//         value.value.additionalCharges &&
//         value.value.additionalCharges.length
//       ) {
//         let grandTotal = parseFloat(ArrayConv.totalPrice);

//         let currentAdditionalCharges = [...value.value.additionalCharges];
//         currentAdditionalCharges.map((ad, i) => {
//           let yCoordinate = 150;
//           grandTotal = grandTotal + ad.value;
//           doc.setFontSize(10);
//           doc.text(`${ad.label}`, 140, yCoordinate + i * 5);
//           doc.text(`: ${ad.value}`, 170, yCoordinate + i * 5);
//         });
//         doc.setFontSize(13);
//         doc.setFont("helvetica", "bold");
//         doc.text(`Grand Total`, 140, 180);
//         doc.text(` :${grandTotal.toFixed(2)}`, 170, 180);
//       }

//       // doc.addImage(`${image.toString()}`, "jpeg", 145, 40, 40, 40);

//       // doc.addImage(
//       //   "https://cdn.pixabay.com/photo/2022/04/11/16/29/coffee-beans-7126154__340.jpg",
//       //   "jpeg",
//       //   145,
//       //   40,
//       //   40,
//       //   40
//       // );

//       doc.setFontSize(12);
//       doc.setFont("helvetica", "normal");
//       var str1 = doc.splitTextToSize(`${OrgName}`, 50);
//       doc.text(str1, 155, 205);
//       // doc.text(`For ${OrgName}:`, 155, 210);
//       // doc.setFontSize(11);
//       doc.text("Authorized Signature", 155, 220);
//       doc.setFontSize(13);

//       doc.text("Thank You for your Business", 14, 240);
//       //Bold Text
//       // doc.setFontSize(12);
//       // doc.setFont('Roboto-Regular', 'bold');
//       // doc.text(`Sold By`, 14, 40,);
//       doc.setFontSize(12);
//       doc.text(`To,`, 14, 75);
//       // doc.setFontSize(12);
//       // doc.text(`Shipping Address`, 145, 75,);
//       doc.setFontSize(18);
//       doc.text(`QUOTATION`, 85, 20);

//       doc.setFontSize(13);
//       doc.text(`${OrgName}`, 14, 230);

//       const resDocImage = yield fetch(
//         `${appConfig.ip}/images/encodeBase64?source=${image}`,
//         {
//           method: "GET",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       let resultImage = yield resDocImage.json();

//       // doc.addImage(resultImage.base64, "jpeg", 165, 40, 40, 20);
//       doc.save(`${refId}.pdf`);

//       yield put({
//         type: PDF_QUOTATION_REPORT_SUCCESS_ACTION,
//         payload: resJSON,
//       });
//     }
//   } catch (err) {
//     if (err.ok === false) {
//       yield put({
//         type: PDF_QUOTATION_REPORT_FAIL_ACTION,
//         error: err,
//       });
//     } else {
//     }
//   }
// }

function* AddQuotationFieldSuggestion(value) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licenseeDtls = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

  let data = {
    name: value.value && value.value.label,
    type: "quotation",
    licenseeId: licenseeDtls.id,
    userId: userDtls.id,
  };

  try {
    const token = localStorage.getItem("sStraitToken");

    const res = yield fetch(`${appConfig.ip}/fieldSuggestion`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON1 = {};
      try {
        errJSON1 = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON1);
    } else {
      const resJSON = yield res.json();

      yield put({ type: FETCH_QUOTATION_FIELD_SUGGESTION_INIT_ACTION });
      yield put({
        type: ADD_QUOTATION_FIELD_SUGGESTION_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_QUOTATION_FIELD_SUGGESTION_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchQuotationFieldSuggestion(params) {
  let q = (params.params && params.params.search) || "";

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/fieldSuggestion?`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let fieldSuggestions = resJSON.rows.map((data) => {
        let suggestions = {
          // name: data.name,
          label: data.name,
        };
        return suggestions;
      });

      yield put({
        type: FETCH_QUOTATION_FIELD_SUGGESTION_SUCCESS_ACTION,
        payload: resJSON,
        fieldSuggestions: fieldSuggestions,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_QUOTATION_FIELD_SUGGESTION_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* getLicenseeProf() {
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

  let userId = licData.userId.id;
  try {
    let params = {
      api: `${appConfig.ip}/licenseeprof/${userId}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

function* getQuoteConfig() {
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

  let liceId = licData.id;
  try {
    let params = {
      api: `${appConfig.ip}/quoteConfig?licenseeId=${liceId}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

export function* QuotationsActionWatcher() {
  yield takeEvery(FETCH_QUOTATIONS_INIT_ACTION, fetchQuotations);
  yield takeEvery(FETCH_QUOTATIONSBYID_INIT_ACTION, getQuotationsById);
  yield takeEvery(ADD_QUOTATION_INIT_ACTION, AddQuotation);

  // yield takeEvery(PDF_QUOTATION_REPORT_INIT_ACTION, fetchPdfQuotationReport);
  yield takeEvery(EDIT_QUOTATION_INIT_ACTION, EditQuotation);
  yield takeEvery(CHANGE_QUOTATION_STATUS_INIT_ACTION, changeStatus);
  yield takeEvery(
    ADD_QUOTATION_FIELD_SUGGESTION_INIT_ACTION,
    AddQuotationFieldSuggestion
  );
  yield takeEvery(
    FETCH_QUOTATION_FIELD_SUGGESTION_INIT_ACTION,
    fetchQuotationFieldSuggestion
  );
}
