import {
    FETCH_LICENSEEPROFILEEDIT_SUCCESS_ACTION,
    FETCH_BANK_SUCCESS_ACTION,
    

   
  } from "./action";
  
  const initialState = {
    processing: false,
    error: false,
  };
  
  export default function EditProfileReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_LICENSEEPROFILEEDIT_SUCCESS_ACTION:
        return {
          ...state,
          LicenseeprofileDtls: action.payload,
        };
        case FETCH_BANK_SUCCESS_ACTION:
       
          return {
            ...state,
            banks: action.payload,
          };
     
  
      default:
        return {
          ...state,
        };
    }
  }
  