import { put, takeEvery, call, all, fork } from "redux-saga/effects";

import * as actionTypes from "./constants";
import { CHANGE_ORDERTOLEADSTATUS_SUCCESS_ACTION } from "../../LeadsContainer/action";
// import { CHANGE_QUOTATION_STATUS_SUCCESS_ACTION } from "../QuotationsContainer/action";

import { appConfig } from "../../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { variantText } from "../../../screens/Common/Components/variantText";
import { update } from "lodash";
var dateFormat = require("dateformat");

// import {getDataUrl} from "../../service/roleByUrl"

// common role wise url get
function* getRoleApiUrl() {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const role = localStorage.getItem("sStraitUserRole");
  let getDataUrl = [
    { role: "licensee", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "distributor", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "licenseeAccountant", url: "&licenseeId=" + userDtls.licenseeId },
    {
      role: "regionalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&region=" + userDtls.region,
    },
    {
      role: "zonalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&zone=" + userDtls.zone,
    },
    {
      role: "areaManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
    {
      role: "salesExcecutive",
      url:
        "&licenseeId=" + userDtls.licenseeId + "&salesExecutive=" + userDtls.id,
    },
    {
      role: "agent",
      url:
        "&licenseeId=" + userDtls.licenseeId + "&salesExecutive=" + userDtls.id,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);
  return apiUrl;
}

function* commonFunction(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: `${value.failAction}`, error: err });
    } else {
    }
  }
}

function* fetchDisOrder(value) {
  let pageno = value.value.page === undefined ? 1 : value.value.page;

  const apiUrl = yield call(getRoleApiUrl);
  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? null
      : value.value.searchVal;
  let filter =
    !value || !value.value || !value.value.filter ? null : value.value.filter;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let filterValDate =
    !value || !value.value || !value.value.filterValDate
      ? null
      : value.value.filterValDate;

  let custFilter = (value && value.value.custFilter) || "";
  let salesExFilter = (value && value.value.saleExFilter) || "";

  let _url = `${appConfig.ip}/order?limit=10&page=${pageno}${apiUrl.url}`;

  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }
  if (filter) {
    _url = `${_url}&products=${filter}`;
  }
  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  }
  if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  }
  if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }
  if (filterValDate) {
    _url = `${_url}${filterValDate}`;
  }
  if (custFilter) {
    _url = `${_url}&customer=${custFilter}`;
  }
  if (salesExFilter) {
    _url = `${_url}&salesExecutive=${salesExFilter}`;
  }
  try {
    let params = {
      api: _url,
      method: "GET",
      sucessAction: actionTypes.FETCH_DISORDERS_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_DISORDERS_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);
  } catch (e) {}
}

// ADD Order

function* addOrder(value) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  const currencyId = JSON.parse(localStorage.getItem("sStraitCurrency"))
    .currencyId.id;
  const payStatus = value.value.payStatus;

  let role = userDtls.role;

  let products = [];
  value.productList.map((item) => {
    products.push({
      id: item.product.id,
      refId: item.product.refId,
      name: item.product.name,
      licenseeId: item.product.licenseeId.id,
      ctgryId: item.product.ctgryId.id,
      subCtgryId: item.product.subCtgryId.id,

      unit: item.product.unit,
      backOrder: item.product.backOrder,
      buyingPrice: item.product.buyingPrice,
      sellingPrice: item.product.sellingPrice,
      currency: item.product.currency.id,
      gstSlab: item.product.gstSlab.id,

      PrdtType: item.product.PrdtType.id,
      agency: item.product.agency.id,
      commission: item.product.commission,
      description: item.product.description,
      image: item.product.image,
      channelPartner: item.product.channelPartner
        ? item.product.channelPartner
        : null,
      quantity: item.qty,
      gstAmount: parseFloat(item.gstAmt),
      discount: item.discount,
      discAmount: parseFloat(item.discAmnt),
      total: parseFloat(item.total),
      hsnCode: item.product.hsnCode ? item.product.hsnCode : "",
      hasVariant: item.product.hasVariant,
      variants: item.product.hasVariant ? [item.inventory.variants] : [],
    });
  });

  const sum = products.reduce((a, object) => {
    return a + object.total;
  }, 0);

  const totalSellPrice = products.reduce((a, object) => {
    return a + object.sellingPrice;
  }, 0);

  const totalBuyPrice = products.reduce((a, object) => {
    return a + object.buyingPrice;
  }, 0);

  let totalCreditAmt;
  if (value.value.creditNote) {
    totalCreditAmt = value.value.creditNote.reduce((a, object) => {
      return a + object.amnt;
    }, 0);
  }

  let amnt1 = value.value.payAmount;

  let productsArr = products.map((data) => {
    let newPrdt = {
      ...data,
      paidAmount:
        value.value.payStatus === "full"
          ? data.total
          : amnt1 > data.total
          ? data.total
          : amnt1,
    };

    amnt1 = amnt1 > data.total ? amnt1 - data.total : 0;

    return newPrdt;
  });

  const data = {
    orgId: userDtls.orgId,
    initiator: role,
    customer: value.value.customer[0].id,
    shippingAddress: value.value.shippingAddress,
    currency: currencyId,
    description: value.value.description,
    totalPrice: Number.parseFloat(sum).toFixed(2),
    salesExecutive: userDtls.id,
    licenseeId: userDtls.licenseeId,
    paymentMode: value.value.paymentMode[0].id,
    sellingPrice: totalSellPrice,
    buyingPrice: totalBuyPrice,
    lead: value.value.lead ? value.value.lead : null,
    quotation: value.value.quotation ? value.value.quotation : null,
    paymentStatus:
      sum == value.value.payAmount ? "full" : value.value.payStatus,
    remainAmount:
      payStatus === "partial"
        ? Number.parseFloat(sum - value.value.payAmount).toFixed(2)
        : 0,
    channelPartner: licData.channelPartner ? licData.channelPartner.id : null,
    grandTotal:
      value.value &&
      value.value.additionalCharges &&
      value.value.additionalCharges.length
        ? value.value && value.value.grandTotal
        : null,
    additionalCharges: (value.value && value.value.additionalCharges) || null,
    products: productsArr,
    region: userDtls.region,
    zone: userDtls.zone,
    area: userDtls.area,
    // status: "delivered",
    notify: [
      {
        user: licData && licData.userId && licData.userId.id,
      },
    ],
    creditNote:
      value.value.creditNote && value.value.creditNote.map((e) => e.id),
    totalCreditAmt: totalCreditAmt,
  };

  const res = yield call(AddOrderData, {
    value: data,
    orderVal: value.value,
    orderProducts: value.productList,
  });
}

function* AddOrderData(value) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  const token = localStorage.getItem("sStraitToken");
  let role = userDtls.role;
  try {
    const res = yield fetch(`${appConfig.ip}/order/distributor`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(value.value),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      //Filter by same id total product quantity
      const productTotalFltr = Array.from(
        resJSON.products.reduce(
          (m, { id, quantity }) => m.set(id, (m.get(id) || 0) + quantity),
          new Map()
        ),
        ([id, quantity]) => ({ id, quantity })
      );

      //Updated Product quantity
      yield all(
        productTotalFltr.map((x) =>
          call(updateProductQty, { id: x.id, quantity: -x.quantity })
        )
      );

      //Updated sold product&variant quantity
      yield all(
        value.orderProducts.map((x) =>
          call(updateSoldProductVariantQty, {
            item: x,

            allocatedPerson: userDtls.id,
          })
        )
      );

      //Updated product inventory
      yield all(
        value.orderProducts.map((x) =>
          call(updateInventoryQty, {
            id: x.inventory.id,
            quantity: x.inventory.quantity - x.qty,
          })
        )
      );

      //Updated product variants
      yield all(
        value.orderProducts.map((x) => call(updateProductVariant, { item: x }))
      );

      //Create Inventory Log
      yield all(
        value.orderProducts.map((x) =>
          call(createInventoryLog, { item: x, orderId: resJSON.refId })
        )
      );

      //Update credit notes
      if (value.value.creditNote) {
        yield all(
          value.value.creditNote.map((x) =>
            call(updateCreditNote, {
              id: x,
              orderId: resJSON.id,
              status: "blocked",
            })
          )
        );
      }

      const paymntData = {
        customer: value.value.customer,
        order: resJSON.id,
        salesExecutive: value.value.salesExecutive,
        licenseeId: value.value.licenseeId,
        region: value.value.region,
        zone: value.value.zone,
        area: value.value.area,
        paymentMode: value.value.paymentMode,
        currency: value.value.currency,
        amount:
          value.value.paymentStatus === "partial"
            ? value.orderVal.payAmount
            : value.value.totalPrice,
        channelPartner: value.value.channelPartner,
        notify: value.value.notify,
        // status: "completed",
      };

      //Create payment
      if (value.orderVal.paymentMode[0].name != "Credit") {
        yield call(createPayment, paymntData);
      }
      //Create Invoice
      yield call(createInvoice, { res: resJSON, orderVal: value.orderVal });

      //Customer type update
      if (value.orderVal.customer[0].type === "lead") {
        yield call(customerTypeUpdate, { id: value.value.customer });
      }

      //lead status update
      if (value.value.lead) {
        yield call(leadStatusUpdate, { id: value.value.lead });
      }

      yield put({
        type: actionTypes.ADD_ORDERS_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Order added successfully", {
        autoClose: 3000,
      });
      yield put({ type: actionTypes.FETCH_TOTAL_AMOUNT_BY_ORDER_INIT_ACTION });
      yield put({
        type: actionTypes.FETCH_DISORDERS_INIT_ACTION,
        value: { page: 1 },
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.ADD_ORDERS_FAIL_ACTION,
        error: err,
      });
    }
  }
}

//Credit note update
function* updateCreditNote(value) {
  const data = {
    status: value.status,
    claimedOrder: value.orderId,
  };
  try {
    let params = {
      api: `${appConfig.ip}/creditNote/${value.id}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params);
  } catch (e) {}
}

//Lead status update while creating an order
function* leadStatusUpdate(value) {
  const data = {
    status: "converted",
  };
  try {
    let params = {
      api: `${appConfig.ip}/lead/${value.id}`,
      method: "PUT",
      sucessAction: CHANGE_ORDERTOLEADSTATUS_SUCCESS_ACTION,
      failAction: null,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params);
  } catch (e) {}
}

//Create No network heirarchy earnings

function* createEarnings(value) {
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

  let licenseId = licData.userId.id;
  try {
    const comsnAmtArr = value.products.map((x) => call(fetchCommissionAmnt, x));

    const comsnArr = yield all(comsnAmtArr);

    const sumComsnArr = getUniqueCommission(comsnArr);

    let fltrArr = sumComsnArr.filter((item) => item.Amnt !== 0);

    if (fltrArr.length != 0) {
      fltrArr = fltrArr.map((item) => ({ ...item, userId: licenseId }));
    }
    let request = [];
    request.push(
      call(postIncentiveBckt, {
        Val: value,
        licenseId: licenseId,
        sumComsnArr: fltrArr,
      })
    );

    if (fltrArr.length != 0) {
      request.push(
        call(createMultipleEarning, {
          Arr: fltrArr.find((element) => element.role === "company"),
          Val: value,
        })
      );
    }
    let incentvRes = yield all(request);

    // if (incentvRes.length === request.length) {
    //For payment status upadate
    yield call(paymentById, {
      id: value.id,
      status: "completed",
    });
    //For status change
    yield call(changeDisOrderStatus, {
      Val: value,
      approve: true,
      url: value.url || "",
    });
    //Update credit notes
    yield all(
      value.creditNote.map((x) =>
        call(updateCreditNote, { id: x, orderId: value.id, status: "consumed" })
      )
    );
    yield call(orderAcceptNotification, { Val: value });
    // }
  } catch (err) {}
}

//Create Network heirarchy earnings
function* createNetworkEarnings(value) {
  let Val = value;

  let userIdArr = [];

  try {
    const [adminId, userIds] = yield all([
      call(fetchAdminDtls),
      call(fetchUserDtls, Val),
      // call(fetchCommissionAmnt, Val),
    ]);

    const comsnAmtArr = value.products.map((x) => call(fetchCommissionAmnt, x));

    const comsnArr = yield all(comsnAmtArr);

    const sumComsnArr = getUniqueCommission(comsnArr);

    let licenseId = userIds.licenseeId.userId.id;

    // Get user Id array

    adminId.count !== 0 &&
      adminId.rows.map((row) => {
        userIdArr.push({ role: "platform", userId: row.id });
      });

    userIdArr.push(
      { role: "salesExcecutive", userId: userIds.salesExecutive.id },
      { role: "company", userId: userIds.licenseeId.userId.id },
      { role: "regionalManager", userId: userIds.region.manager.id },
      { role: "zonalManager", userId: userIds.zone.manager.id },
      { role: "areaManager", userId: userIds.area.manager.id }
    );

    // Merge array

    let mergedArray = userIdArr.map((item) => {
      let cmmsnData = sumComsnArr.find((element) => element.role === item.role);
      return { ...item, ...cmmsnData };
    });
    let fltrArr = mergedArray.filter((item) => item.Amnt !== 0);

    let request = [];

    request.push(
      call(postIncentiveBckt, {
        Val: Val,
        licenseId: licenseId,
        sumComsnArr: sumComsnArr,
      })
    );

    fltrArr.forEach((el) => {
      request.push(call(createMultipleEarning, { Arr: el, Val: Val }));
    });

    let incentvRes = yield all(request);

    if (incentvRes.length === request.length) {
      //For payment status upadate
      yield call(paymentById, {
        id: value.id,
        status: "completed",
      });
      //For status change
      yield call(changeDisOrderStatus, {
        Val: Val,
        approve: true,
        url: value.url || "",
      });
      yield call(orderAcceptNotification, { Val: Val });

      //Update credit notes
      yield all(
        Val.creditNote.map((x) =>
          call(updateCreditNote, { id: x, orderId: Val.id, status: "consumed" })
        )
      );
    }
  } catch (err) {}
}

//Order accept notification
function* orderAcceptNotification(value) {
  let values = value.Val;

  let acceptedUser =
    values && values.salesExecutive && values.salesExecutive.id;

  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  let orgName = licData && licData.userId && licData.userId.orgName;
  const data = {
    user: acceptedUser,
    event: "orderAccepted",
    subject: "Order Accepted",
    message: `Order accepted by ${orgName}`,
    streams: ["pusher"],
  };

  try {
    let params = {
      api: `${appConfig.ip}/notfctn`,
      method: "POST",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params);
  } catch (e) {}
}

// Addtion of commission array
function getUniqueCommission(data) {
  var result = [];
  data.map((el) => {
    el.forEach(function (a) {
      result.push({ role: a.role, Amnt: a.Amnt });
    });
  });
  const newResult = Object.values(
    result.reduce((acc, item) => {
      acc[item.role] = acc[item.role]
        ? { ...item, Amnt: item.Amnt + acc[item.role].Amnt }
        : item;
      return acc;
    }, {})
  );

  return newResult;
}

//Get Admin Id function

function* fetchAdminDtls() {
  try {
    let params = {
      api: `${appConfig.ip}/users?role=admin`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const Res = yield call(commonFunction, params);
    return Res;
  } catch (e) {}
}

// Get Order User Details function

function* fetchUserDtls(value) {
  const apiUrl = "licenseeId=" + value.licenseeId.id;
  try {
    let params = {
      api: `${appConfig.ip}/order/userDetails/${value.id}?${apiUrl}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const Res = yield call(commonFunction, params);
    return Res;
  } catch (e) {}
}

//Commission calculation function

function* fetchCommissionAmnt(value) {
  let sellingPrice = value.sellingPrice;
  let buyingPrice = value.buyingPrice;

  const amnt = (sellingPrice - buyingPrice) * value.quantity;
  const discAmnt = Number.parseFloat((amnt * value.discount) / 100).toFixed(2);
  const price = amnt - discAmnt;

  let commission = value.commission;

  let platform = Number.parseFloat((price * commission.platform) / 100).toFixed(
    2
  );
  let regionManger = Number.parseFloat(
    (price * commission.regionManger) / 100
  ).toFixed(2);
  let zoneManger = Number.parseFloat(
    (price * commission.zoneManger) / 100
  ).toFixed(2);
  let areaManger = Number.parseFloat(
    (price * commission.areaManger) / 100
  ).toFixed(2);
  let salesExecutive = Number.parseFloat(
    (price * commission.salesExecutive) / 100
  ).toFixed(2);
  let company = Number.parseFloat((price * commission.company) / 100).toFixed(
    2
  );
  let incentivesbucket = Number.parseFloat(
    (price * commission.incentivesbucket) / 100
  ).toFixed(2);

  let comsnAmtArr = [];
  comsnAmtArr.push(
    { role: "platform", Amnt: parseFloat(platform) },
    { role: "regionalManager", Amnt: parseFloat(regionManger) },
    { role: "zonalManager", Amnt: parseFloat(zoneManger) },
    { role: "areaManager", Amnt: parseFloat(areaManger) },
    { role: "salesExcecutive", Amnt: parseFloat(salesExecutive) },
    { role: "company", Amnt: parseFloat(company) },
    { role: "incentivesbucket", Amnt: parseFloat(incentivesbucket) }
  );

  return comsnAmtArr;
}

function* postIncentiveBckt(value) {
  let cmmsnAmnt = value.sumComsnArr.find(
    (element) => element.role === "incentivesbucket"
  );

  if (cmmsnAmnt) {
    const data = {
      receiver: value.licenseId,
      licenseeId: value.Val.licenseeId.id,
      order: value.Val.id,
      salesExecutive: value.Val.salesExecutive.id,
      currency: value.Val.currency.id,
      amount: cmmsnAmnt.Amnt,
    };

    try {
      let params = {
        api: `${appConfig.ip}/incentiveBucket`,
        method: "POST",
        sucessAction: null,
        failAction: null,
        body: JSON.stringify(data),
      };
      const res = yield call(commonFunction, params);

      return res;
    } catch (e) {}
  }
}

function* createMultipleEarning(value) {
  if (value.Arr) {
    const data = {
      receiver: value.Arr.userId,
      licenseeId: value.Val.licenseeId.id,
      order: value.Val.id,
      salesExecutive: value.Val.salesExecutive.id,
      currency: value.Val.currency.id,
      amount: value.Arr.Amnt,
      type: "order",
    };

    try {
      let params = {
        api: `${appConfig.ip}/earnings`,
        method: "POST",
        sucessAction: null,
        failAction: null,
        body: JSON.stringify(data),
      };
      const res = yield call(commonFunction, params);
      return res;
    } catch (e) {}
  }
}

//update quantity of product
function* updateProductQty(value) {
  const data = {
    quantity: value.quantity,
  };
  try {
    let params = {
      api: `${appConfig.ip}/product/updateQuantity/${value.id}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
    return res;
  } catch (e) {}
}

//update sold product variant qty
function* updateSoldProductVariantQty(value) {
  let itemData = value.item;
  let id = itemData?.product?.id;

  if (itemData.hasVariant) {
    const variantId = itemData.inventory.variants.variantId;
    const data = {
      variantId: variantId,
      soldQty: itemData.qty,
      allocatedPerson: value.allocatedPerson,
    };

    try {
      let params = {
        api: `${appConfig.ip}/stockMovement/product/variant/${itemData.product.id}`,
        method: "PUT",
        sucessAction: null,
        failAction: null,
        body: JSON.stringify(data),
      };
      const res = yield call(commonFunction, params);
      return res;
    } catch (e) {}
  } else {
    const data1 = {
      soldQty: itemData.qty,
      allocatedPerson: value.allocatedPerson,
    };
    try {
      let params = {
        api: `${appConfig.ip}/stockMovement/product/${id}`,
        method: "PUT",
        sucessAction: null,
        failAction: null,
        body: JSON.stringify(data1),
      };
      const res = yield call(commonFunction, params);
      return res;
    } catch (e) {}
  }
}

//update sold product variant qty
function* updateSoldQtyByOrderCancel(value) {
  let itemData = value.item;
  let id = itemData?.id;

  if (itemData.hasVariant) {
    const variantId = itemData.variants[0].variantId;

    const data = {
      variantId: variantId,
      soldQty: -itemData.quantity,
      allocatedPerson: value.allocatedPerson,
    };

    try {
      let params = {
        api: `${appConfig.ip}/stockMovement/product/variant/${itemData.id}`,
        method: "PUT",
        sucessAction: null,
        failAction: null,
        body: JSON.stringify(data),
      };
      const res = yield call(commonFunction, params);

      return res;
    } catch (e) {}
  } else {
    const data1 = {
      soldQty: -itemData.quantity,
      allocatedPerson: value.allocatedPerson,
    };
    try {
      let params = {
        api: `${appConfig.ip}/stockMovement/product/${id}`,
        method: "PUT",
        sucessAction: null,
        failAction: null,
        body: JSON.stringify(data1),
      };
      const res = yield call(commonFunction, params);
      return res;
    } catch (e) {}
  }
}

//update product inventory quantity
function* updateInventoryQty(value) {
  const data = {
    quantity: value.quantity,
  };
  try {
    let params = {
      api: `${appConfig.ip}/inventory/${value.id}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
    if (res) {
      if (value.api === "SalesReplace") {
        yield call(createInventoryLog, {
          item: {
            product: value.prdt,
            inventory: value.inventory,
            orderId: value.orderId,
            qty: value.prdt.quantity,
            action: "salesReplace",
          },
        });
      }
    }
    return res;
  } catch (e) {}
}

//update product variant
function* updateProductVariant(value) {
  let itemData = value.item;

  if (itemData.hasVariant) {
    const variantId = itemData.inventory.variants.variantId;
    const data = {
      variantId: variantId,
      quantity:
        itemData.invStatus && itemData.invStatus === "cancel"
          ? itemData.quantity
          : -itemData.qty,
    };

    try {
      let params = {
        api: `${appConfig.ip}/product/variant/${itemData.product.id}`,
        method: "PUT",
        sucessAction: null,
        failAction: null,
        body: JSON.stringify(data),
      };
      const res = yield call(commonFunction, params);
      return res;
    } catch (e) {}
  }

  //*****old *********************************************
  // let itemData = value.item;

  // if (itemData.hasVariant) {
  //   try {
  //     let params = {
  //       api: `${appConfig.ip}/product/${itemData.product.id}`,
  //       method: "GET",
  //       sucessAction: null,
  //       failAction: null,
  //       body: null,
  //     };
  //     const res = yield call(commonFunction, params);

  //     if (res) {
  //       const variantId = itemData.inventory.variants.variantId;
  //       let result;
  //       if (itemData.invStatus && itemData.invStatus === "cancel") {
  //         result = res.variants.map((item) =>
  //           item.variantId === variantId
  //             ? { ...item, quantity: item.quantity + itemData.quantity }
  //             : item
  //         );
  //       } else {
  //         result = res.variants.map((item) =>
  //           item.variantId === variantId
  //             ? { ...item, quantity: item.quantity - itemData.qty }
  //             : item
  //         );
  //       }
  //       const data = {
  //         variants: result,
  //       };

  //       try {
  //         let params = {
  //           api: `${appConfig.ip}/product/${itemData.product.id}`,
  //           method: "PUT",
  //           sucessAction: null,
  //           failAction: null,
  //           body: JSON.stringify(data),
  //         };
  //         const res = yield call(commonFunction, params);

  //         return res;
  //       } catch (e) {}
  //     }
  //   } catch (e) {}
  // }
}

//create Inventory Log/////////////////////////////////
function* createInventoryLog(value) {
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let itemData = value.item;
  let data;
  if (itemData.invStatus && itemData.invStatus === "cancel") {
    data = {
      inventory: itemData.inventory.id,
      product: itemData.id,
      user: userDetails.id,
      quantity: itemData.quantity,
      type: "addition",
      note:
        userDetails.name +
        " cancelled " +
        itemData.quantity +
        " items of " +
        itemData.name +
        " with order Id " +
        itemData.orderId,
    };
  } else if (itemData.action === "salesReplace") {
    data = {
      inventory: itemData.inventory.id,
      product: itemData.product.id,
      user: userDetails.id,
      quantity: itemData.qty,
      type: "reduction",
      note:
        userDetails.name +
        " replaced " +
        itemData.qty +
        " items of " +
        itemData.product.name +
        " with order Id " +
        itemData.orderId,
    };
  } else {
    data = {
      inventory: itemData.inventory.id,
      product: itemData.product.id,
      user: userDetails.id,
      quantity: itemData.qty,
      type: "reduction",
      note:
        userDetails.name +
        " ordered " +
        itemData.qty +
        " items of " +
        itemData.product.name +
        " with order Id " +
        value.orderId,
    };
  }

  try {
    let params = {
      api: `${appConfig.ip}/inventoryLog`,
      method: "POST",
      sucessAction: "CREATE INVENTORY SUCCESS ACTION",
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

//Payment create while creating an order
function* createPayment(value) {
  try {
    let params = {
      api: `${appConfig.ip}/payment`,
      method: "POST",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(value),
    };
    yield call(commonFunction, params);
  } catch (e) {}
}

//Customer type update while creating an order
function* customerTypeUpdate(value) {
  const data = {
    type: "customer",
  };
  try {
    let params = {
      api: `${appConfig.ip}/customer/${value.id}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params);
  } catch (e) {}
}

//Create Invoice
function* createInvoice(value) {
  const totalDisc = value.res.products.reduce((a, object) => {
    return a + object.discount;
  }, 0);

  const configData = yield call(getInvoiceConfig);
  let customer = value.orderVal.customer[0];
  // let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  const licData = yield call(getLicenseeProf);

  const data = {
    creator: value.res.salesExecutive.id,
    receiver: value.res.customer.id,
    order: value.res.id,
    licenseeId: value.res.licenseeId.id,
    orderRefId: value.res.refId,
    currency: value.res.currency,
    discount: totalDisc,
    amount: value.res.totalPrice,
    subtotal: value.res.totalPrice,
    notes: configData.count != 0 ? configData.rows[0].remark : "",
    category: customer.category,
    taxModel: customer.taxModel,
    customer: {
      name: customer.name,
      address: customer.address,
      mobNo: customer.mobileNo,
      email: customer.email,
      gstin: customer.gstin,
    },
    licensee: {
      orgName: licData.orgName,
      address: licData.addr1 ? licData.addr1 : "",
      contactNo: licData.contactNo ? licData.contactNo : "",
      contactEmail: licData.contactEmail ? licData.contactEmail : "",
      gstin: licData.gstin ? licData.gstin : "",
    },
    config: {
      accountName: configData.count != 0 ? configData.rows[0].accountName : "",
      bankName: configData.count != 0 ? configData.rows[0].bankName.name : "",
      accountNumber: configData.count != 0 ? configData.rows[0].accountNo : "",
      ifscCode: configData.count != 0 ? configData.rows[0].ifscCode : "",
      branchName: configData.count != 0 ? configData.rows[0].branchName : "",
      addtCustInfo:
        configData.count != 0 ? configData.rows[0].addtCustInfo : "",
      logo: configData.count != 0 ? configData.rows[0].imgUrl : "",
    },

    // status: value.paymentStatus === "full" ? "paid" : "partial",
  };

  try {
    let params = {
      api: `${appConfig.ip}/invoice`,
      method: "POST",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params);
  } catch (e) {}
}

//Get Licensee profile
function* getLicenseeProf() {
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

  let userId = licData.userId.id;
  try {
    let params = {
      api: `${appConfig.ip}/licenseeprof/${userId}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

// Change order status
function* changeDisOrderStatus(value) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let id = value.approve ? value.Val.id : value.value.Val.id;
  let statusVal = value.approve ? "delivered" : "blocked";
  const data = {
    status: statusVal,
  };

  try {
    let params = {
      api: `${appConfig.ip}/order/${id}`,
      method: "PUT",
      sucessAction:
        value.url && value.url.includes("ledger")
          ? "UPDATE_LEDGER_TABLE"
          : actionTypes.CHANGE_DISORDERSTATUS_SUCCESS_ACTION,
      failAction: actionTypes.CHANGE_DISORDERSTATUS_FAIL_ACTION,
      body: JSON.stringify(data),
      toastMsg: "Status updated successfully",
    };
    const res = yield call(commonFunction, params);

    if (res) {
      yield call(getInvoiceByOrderId, {
        id: id,
        status:
          statusVal === "blocked" ? "cancelled" : value.approve ? "paid" : null,
      });

      if (statusVal === "blocked") {
        yield call(paymentById, {
          id: value.value.Val.id,
          status: "canceled",
        });

        //Filter by same id total product quantity
        const productTotalFltr = Array.from(
          value.value.orderItem.products.reduce(
            (m, { id, quantity }) => m.set(id, (m.get(id) || 0) + quantity),
            new Map()
          ),
          ([id, quantity]) => ({ id, quantity })
        );

        //Update total quantity of product
        yield all(
          productTotalFltr.map((x) =>
            call(updateProductQty, { id: x.id, quantity: x.quantity })
          )
        );

        //update inventory and product variant,create inventory log
        yield all(
          value.value.orderItem.products.map((x) =>
            call(OrdrCnclUpdteInventory, {
              item: x,
              order: value.value.orderItem,
            })
          )
        );
        //Update soldQty
        yield all(
          value.value.orderItem.products.map((x) =>
            call(updateSoldQtyByOrderCancel, {
              item: x,
              order: value.value.orderItem,

              allocatedPerson: userDtls.id,
            })
          )
        );

        //Update credit notes
        yield all(
          value.value.orderItem.creditNote.map((x) =>
            call(updateCreditNote, {
              id: x,
              orderId: null,
              status: "created",
            })
          )
        );
        yield put({
          type: actionTypes.FETCH_TOTAL_AMOUNT_BY_ORDER_INIT_ACTION,
        });
      }
    }
  } catch (e) {}
}

//Product inventory update after order cancel
function* OrdrCnclUpdteInventory(value) {
  let Val = value.item;
  let _url = `${appConfig.ip}/inventory?product=${Val.id}`;
  if (Val.hasVariant) {
    _url = `${_url}&variantId=${Val.variants[0].variantId}`;
  }

  try {
    let params = {
      api: _url,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const Res = yield call(commonFunction, params);

    if (Res) {
      //Updated product inventory quantity
      yield call(updateInventoryQty, {
        id: Res.rows[0].id,
        quantity: Res.rows[0].quantity + Val.quantity,
      });
      // Create inventory log
      yield call(createInventoryLog, {
        item: {
          ...Val,
          invStatus: "cancel",
          inventory: Res.rows[0],
          orderId: value.order.refId,
        },
      });

      //Updated product variants
      yield call(updateProductVariant, {
        item: {
          ...Val,
          inventory: Res.rows[0],
          invStatus: "cancel",
          product: {
            id: Val.id,
          },
        },
      });
    }

    return Res;
  } catch (e) {}
}

//Get payment by order id

function* paymentById(value) {
  try {
    let params = {
      api: `${appConfig.ip}/payment?order=${value.id}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const paymentRes = yield call(commonFunction, params);
    if (paymentRes) {
      const pymntId = paymentRes.rows.map((x) => x.id);

      yield all(
        pymntId.map((x) =>
          call(paymentStatusUpdate, { id: x, status: value.status })
        )
      );
    }
  } catch (e) {}
}

//Payment status update
function* paymentStatusUpdate(value) {
  const data = {
    status: value.status,
  };
  try {
    let params = {
      api: `${appConfig.ip}/payment/${value.id}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const paymentStatus = yield call(commonFunction, params);
  } catch (e) {}
}

// Get invoice data by order id
function* getInvoiceByOrderId(value) {
  let orderId = value.id;
  let status = value && value.status;

  try {
    let params = {
      api: `${appConfig.ip}/invoice?isActive=true&order=${orderId}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const Res = yield call(commonFunction, params);

    let invoiceId = Res.rows.length ? Res.rows[0].id : "";
    let licenseeId = Res.rows.length ? Res.rows[0].licenseeId.id : "";

    if (invoiceId) {
      let params = {
        api: `${appConfig.ip}/invoice/${invoiceId}`,
        method: "PUT",
        sucessAction: null,
        failAction: null,
        body: JSON.stringify({ status: status, licenseeId: licenseeId }),
      };
      const invoiceRes = yield call(commonFunction, params);
    }

    return Res;
  } catch (e) {}
}

//order pay amount
function* DisOrderPayAmount(value) {
  const remainAmount = Number.parseFloat(
    value.value.ItemList.remainAmount - value.value.values.amount
  ).toFixed(2);

  let amnt1 = value.value.ItemList.totalPrice - remainAmount;

  let productsArr = value.value.ItemList.products.map((data) => {
    let newPrdt = {
      ...data,
      paidAmount:
        value.value.payStatus === "full"
          ? data.total
          : amnt1 > data.total
          ? data.total
          : amnt1,
    };

    amnt1 = amnt1 > data.total ? amnt1 - data.total : 0;

    return newPrdt;
  });

  const data = {
    remainAmount: remainAmount,
    paymentStatus:
      remainAmount == 0.0 ? "full" : value.value.ItemList.paymentStatus,
    products: productsArr,
  };

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/order/${value.value.ItemList.id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      const paymntData = {
        customer: resJSON.customer.id,
        order: resJSON.id,
        salesExecutive: resJSON.salesExecutive.id,
        licenseeId: resJSON.licenseeId.id,
        region: resJSON.region ? resJSON.region.id : null,
        zone: resJSON.zone ? resJSON.zone.id : null,
        area: resJSON.area ? resJSON.area.id : null,
        // paymentMode: resJSON.paymentMode.id,
        paymentMode: value.value.values.paymentMode[0].id,
        currency: resJSON.currency.id,
        amount: value.value.values.amount,
      };
      yield call(createPayment, paymntData);
      // const orderId = yield call(getOrderById, { value: resJSON.id });

      // if (resJSON.paymentStatus === "full") {
      //   yield call(getInvoiceByOrderId, resJSON.id);
      // }

      let isFromLedger =
        (value && value.url && value.url.includes("ledger")) || false;

      yield put({
        type: isFromLedger
          ? "UPDATE_LEDGER_TABLE_BY_PAYMENT"
          : actionTypes.PAYAMOUNT_DISORDER_SUCCESS_ACTION,
        payload: resJSON,
        isFromLedger: isFromLedger,
      });

      yield toast.success("Payment updated successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.PAYAMOUNT_DISORDER_FAIL_ACTION,
        error: err,
      });
    }
  }
}
//Get invoice config data
function* getInvoiceConfig() {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  try {
    let params = {
      api: `${appConfig.ip}/invoiceConfig?isActive=true&licenseeId=${userDtls.licenseeId}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const Res = yield call(commonFunction, params);

    return Res;
  } catch (e) {}
}

function* changeDisOrderApprove(value) {
  //To check quantity of products
  const invRes = yield all(
    value.value.products.map((x) =>
      call(approveInventorybyId, {
        item: x,
      })
    )
  );

  const filterArr = invRes.filter((item) => item.quantity < 0);

  if (filterArr.length === 0) {
    let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
    licData.networkHierarchy
      ? yield call(createNetworkEarnings, { ...value.value, url: value.url })
      : yield call(createEarnings, { ...value.value, url: value.url });
  } else {
    const name = filterArr.map((x) => {
      if (x.isVariant) return x.product.name + " " + variantText(x.variants);
      else return x.product.name;
    });

    yield toast.error(
      `The approval failed because there was not enough quantity for these products - ${name}`,
      {
        autoClose: 3000,
      }
    );
  }
}

//Get inventory by product and variant id
function* approveInventorybyId(value) {
  let Val = value.item;
  let _url = `${appConfig.ip}/inventory?product=${Val.id}`;
  if (Val.hasVariant) {
    _url = `${_url}&variantId=${Val.variants[0].variantId}`;
  }
  try {
    let params = {
      api: _url,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const Res = yield call(commonFunction, params);

    return Res.rows[0];
  } catch (e) {}
}

//Get Customer discount
function* customerDiscountbyId(value) {
  let custId = value.value.customerId;
  let prodId = value.value.productId;

  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  try {
    let params = {
      api: `${appConfig.ip}/loyalDiscount?status=active&customerId=${custId}&productId=${prodId}`,
      method: "GET",
      sucessAction: actionTypes.CUSTOMER_DISCOUNTBYID_SUCCESS_ACTION,
      failAction: actionTypes.CUSTOMER_DISCOUNTBYID_FAIL_ACTION,
      body: null,
    };
    const Res = yield call(commonFunction, params);

    return Res;
  } catch (e) {}
}

function* exprtOrderData(value) {
  let fileName = "Order Report";
  const token = localStorage.getItem("sStraitToken");
  const apiUrl = yield call(getRoleApiUrl);

  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? null
      : value.value.searchVal;
  let filter =
    !value || !value.value || !value.value.filter ? null : value.value.filter;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let filterValDate =
    !value || !value.value || !value.value.filterValDate
      ? null
      : value.value.filterValDate;

  let custFilter = (value.value && value.value.custFilter) || "";

  let _url = `${appConfig.ip}/order?limit=100${apiUrl.url}`;

  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }

  if (custFilter) {
    _url = `${_url}&customer=${custFilter}`;
  }
  if (filter) {
    _url = `${_url}&product=${filter}`;
  }
  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  }
  if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  }
  if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }
  if (filterValDate) {
    _url = `${_url}${filterValDate}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let csvData = [];

      resJSON.rows.map((item) => {
        csvData.push({
          ORDER_ID: item.refId,
          ORDER_BY:
            item && item.salesExecutive ? item.salesExecutive.name : "NIL",
          CUSTOMER_NAME: item && item.customer ? item.customer.name : "NIL",

          TOTAL_PRICE:
            item && item.currency && item.currency.symbol + item.totalPrice,

          PAYMENT_TYPE: item && item.paymentStatus,
          PAYMENT_MODE: item && item.paymentMode.name,

          LICENSEE_NAME:
            item.licenseeId != null ? item.licenseeId.orgName : "NIL",

          STATUS: item.status,
          ORDER_DATE: dateFormat(item.createdAt, "mediumDate"),
        });
      });

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      yield put({
        type: actionTypes.EXPORT_ORDER_DATA_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.EXPORT_ORDER_DATA_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* fetchCustomerByOrder(value) {
  let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licenseeId = userDetails.licenseeId;
  let search = value && value.value && value && value.value.searchVal;
  let searchFilter = "";
  if (search) {
    searchFilter = `&search=${search}`;
  }
  try {
    let params = {
      api: `${appConfig.ip}/customer?licenseeId=${licenseeId}${searchFilter}`,
      method: "GET",
      sucessAction: actionTypes.FETCH_CUSTOMER_BY_ORDER_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_CUSTOMER_BY_ORDER_FAIL_ACTION,
      body: null,
    };
    const Res = yield call(commonFunction, params);
    return Res;
  } catch (e) {}
}

function* fetchIventoryByProduct(value) {
  // let data = { productdetails: value.data && value.data };

  try {
    let params = {
      api: `${appConfig.ip}/inventory?product=${value.value}`,
      method: "GET",
      sucessAction: actionTypes.FETCH_INVENTORYBYPRODUCT_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_INVENTORYBYPRODUCT_FAIL_ACTION,
      body: null,
    };
    const Res = yield call(commonFunction, params);

    return Res;
  } catch (e) {}
}

function* fetchTotalAmountByOrder(value) {
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  // let pageno = value.value.page === undefined ? 1 : value.value.page;
  const token = localStorage.getItem("sStraitToken");

  const apiUrl = yield call(getRoleApiUrl);
  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? null
      : value.value.searchVal;
  let filter =
    !value || !value.value || !value.value.filter ? null : value.value.filter;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let filterValDate =
    !value || !value.value || !value.value.filterValDate
      ? null
      : value.value.filterValDate;

  let custFilter = (value && value.value && value.value.custFilter) || "";
  let paymentFilter = (value && value.value && value.value.paymentFilter) || "";

  let _url = `${appConfig.ip}/order/sum?status=created,delivered${apiUrl.url}`;

  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }
  if (filter) {
    _url = `${_url}&products=${filter}`;
  }
  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  }
  if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  }
  if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }
  if (filterValDate) {
    _url = `${_url}${filterValDate}`;
  }
  if (custFilter) {
    _url = `${_url}&customer=${custFilter}`;
  }
  if (paymentFilter) {
    _url = `${_url}&paymentStatus=${paymentFilter}`;
  }
  try {
    let params = {
      api: _url,
      method: "GET",
      sucessAction: actionTypes.FETCH_TOTAL_AMOUNT_BY_ORDER_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_TOTAL_AMOUNT_BY_ORDER_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);
  } catch (e) {}
}

function* addProductReturn(value) {
  const token = localStorage.getItem("sStraitToken");
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  let products = (value && value.value && value.value.products) || [];
  let ordeId = (value && value.value && value.value.orderId) || null;

  let updatedProducts = products.map((p) => {
    if (p.gstSlab) {
      p.gstSlab = p.gstSlab.id;
    }
    if (p.hsnCode === "") {
      p.hsnCode = null;
    }
    delete p.returnQty;
    return p;
  });

  const data = {
    initiator: userDetails.id || null,
    licenseeId: userDetails.licenseeId || null,
    order: (value && value.value && value.value.orderId) || null,
    type: (value && value.value && value.value.type) || "",
    products: updatedProducts || [],
    customer: (value && value.value && value.value.customer) || null,
    description: (value && value.value && value.value.description) || "",
    region: userDetails.region,
    zone: userDetails.zone,
    area: userDetails.area,
  };

  try {
    const res = yield fetch(`${appConfig.ip}/salesReturn`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON1 = {};
      try {
        errJSON1 = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON1);
    } else {
      const resJSON = yield res.json();

      yield call(updateOrder, { orderId: ordeId });

      yield put({ type: "FETCH_SALES_RETURN_INIT_ACTION" });

      yield put({
        type: actionTypes.ADD_PRODUCT_RETURN_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Product return successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.ADD_PRODUCT_RETURN_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* addProductReplace(value) {
  const token = localStorage.getItem("sStraitToken");
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  let products = (value && value.value && value.value.products) || [];

  let ordeId = (value && value.value && value.value.orderId) || null;
  let orderRefId = value?.value?.orderRefId || null;

  let updatedProducts = products.map((p) => {
    if (p.gstSlab) {
      p.gstSlab = p.gstSlab.id;
    }
    if (p.hsnCode === "") {
      p.hsnCode = null;
    }
    delete p.returnQty;
    return p;
  });

  const data = {
    initiator: userDetails.id || null,
    licenseeId: userDetails.licenseeId || null,
    order: (value && value.value && value.value.orderId) || null,
    type: (value && value.value && value.value.type) || "",
    products: updatedProducts || [],
    customer: (value && value.value && value.value.customer) || null,
    description: (value && value.value && value.value.description) || "",
    region: userDetails.region,
    zone: userDetails.zone,
    area: userDetails.area,
  };

  try {
    const res = yield fetch(`${appConfig.ip}/salesReplace`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON1 = {};
      try {
        errJSON1 = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON1);
    } else {
      const resJSON = yield res.json();

      //Filter by same id total product quantity
      const productTotalFltr = Array.from(
        resJSON.products.reduce(
          (m, { id, quantity }) => m.set(id, (m.get(id) || 0) + quantity),
          new Map()
        ),
        ([id, quantity]) => ({ id, quantity })
      );

      //Updated Product quantity
      yield all(
        productTotalFltr.map((x) =>
          call(updateProductQty, { id: x.id, quantity: -x.quantity })
        )
      );

      const responseOrder = yield call(updatePrdtInOrder, {
        orderId: ordeId,
        products: updatedProducts,
      });

      yield call(updateOrderStatusForReplace, {
        orderId: ordeId,
        replaceType: value?.value?.type || null,
      });

      // fetch inventory data
      let inventoryresponse = yield all(
        updatedProducts.map((x) =>
          call(fetchIventoryByProduct, { value: x.id, data: x })
        )
      );

      let inventryRes = inventoryresponse.flatMap((item) => item.rows);

      // Updated product inventory
      let inventoryupdate = yield all(
        inventryRes.map((x) =>
          call(updateInventoryQty, {
            id: x.id,
            quantity:
              x.quantity -
              updatedProducts.find((data) => data.id === x.product.id).quantity,
            inventory: x,
            // orderId: ordeId,
            orderId: orderRefId,
            prdt: updatedProducts.find((data) => data.id === x.product.id),
            api: "SalesReplace",
            // ordeRefId: ordeRefId,
          })
        )
      );

      yield put({
        type: actionTypes.ADD_PRODUCT_REPLACE_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Product replace successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.ADD_PRODUCT_REPLACE_FAIL_ACTION,
        error: err,
      });
    }
  }
}

// function* fetchInventoryOfProduct(value) {
//   console.log("inventory of prodcu==>", value);
//   let id = value.id&&value.id;
//   const data = {
//     products: value.products,
//   };
//   try {
//     let params = {
//       api: `${appConfig.ip}/inventory/product=${value.id}`,
//       method: "GET",
//       sucessAction: null,
//       failAction: null,
//       body: JSON.stringify(data),
//     };
//     const res = yield call(commonFunction, params);
//     yield put({
//       type: actionTypes.FETCH_DISORDERS_INIT_ACTION,
//       value: { page: 1 },
//     });
//     return res;
//   } catch (e) {}
// }

// function* fetchIventoryByProduct(value) {
//   console.log("VALUE===",value);
//   try {
//     let params = {
//       api: `${appConfig.ip}/inventory?product=${value.value}`,
//       method: "GET",
//       sucessAction: actionTypes.FETCH_INVENTORYBYPRODUCT_SUCCESS_ACTION,
//       failAction: actionTypes.FETCH_INVENTORYBYPRODUCT_FAIL_ACTION,
//       body: null,
//     };
//     const Res = yield call(commonFunction, params);

//     return Res;
//   } catch (e) {}
// }

//update retun status of order
function* updatePrdtInOrder(value) {
  let id = value.orderId;
  const data = {
    products: value.products,
  };
  try {
    let params = {
      api: `${appConfig.ip}/order/updateProduct/${id}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
    yield put({
      type: actionTypes.FETCH_DISORDERS_INIT_ACTION,
      value: { page: 1 },
    });
    return res;
  } catch (e) {}
}

//update retun status of order
function* updateOrder(value) {
  let id = value.orderId;
  const data = {
    returned: true,
  };
  try {
    let params = {
      api: `${appConfig.ip}/order/${id}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
    yield put({
      type: actionTypes.FETCH_DISORDERS_INIT_ACTION,
      value: { page: 1 },
    });
    return res;
  } catch (e) {}
}

function* getReturnByOrderId(value) {
  try {
    let params = {
      api: `${appConfig.ip}/salesReturn?order=${value.id}`,
      method: "GET",
      sucessAction: actionTypes.GET_RETURN_BY_ORDER_ID_SUCCESS_ACTION,
      failAction: actionTypes.GET_RETURN_BY_ORDER_ID_FAIL_ACTION,
      body: null,
    };
    const Res = yield call(commonFunction, params);

    return Res;
  } catch (e) {}
}

function* fectCustCreditNotes(value) {
  try {
    let params = {
      api: `${appConfig.ip}/creditNote?customer=${value.value}&status=created`,
      method: "GET",
      sucessAction: actionTypes.FETCH_CUST_CREDITNOTES_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_CUST_CREDITNOTES_FAIL_ACTION,
      body: null,
    };
    const Res = yield call(commonFunction, params);

    return Res;
  } catch (e) {}
}

function* editOrder(value) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  const currencyId = JSON.parse(localStorage.getItem("sStraitCurrency"))
    .currencyId.id;
  const payStatus = value.value.payStatus;

  let role = userDtls.role;

  let products = [];
  value.productList.map((item) => {
    products.push({
      id: item.product.id,
      refId: item.product.refId,
      name: item.product.name,
      licenseeId: item.product.licenseeId.id,
      ctgryId: item.product.ctgryId.id,
      subCtgryId: item.product.subCtgryId.id,

      unit: item.product.unit,
      backOrder: item.product.backOrder,
      buyingPrice: item.product.buyingPrice,
      sellingPrice: item.product.sellingPrice,
      currency: item.product.currency.id,
      gstSlab: item.product.gstSlab.id,

      PrdtType: item.product.PrdtType.id,
      agency: item.product.agency.id,
      commission: item.product.commission,
      description: item.product.description,
      image: item.product.image,
      channelPartner: item.product.channelPartner
        ? item.product.channelPartner
        : null,
      quantity: item.qty,
      gstAmount: parseFloat(item.gstAmt),
      discount: item.discount,
      discAmount: parseFloat(item.discAmnt),
      total: parseFloat(item.total),
      hsnCode: item.product.hsnCode ? item.product.hsnCode : "",
      hasVariant: item.product.hasVariant,
      variants: item.product.hasVariant ? [item.inventory.variants] : [],
    });
  });

  const sum = products.reduce((a, object) => {
    return a + object.total;
  }, 0);

  const totalSellPrice = products.reduce((a, object) => {
    return a + object.sellingPrice;
  }, 0);

  const totalBuyPrice = products.reduce((a, object) => {
    return a + object.buyingPrice;
  }, 0);
  let totalCreditAmt;
  if (value.value.creditNote) {
    totalCreditAmt = value.value.creditNote.reduce((a, object) => {
      return a + object.amnt;
    }, 0);
  }
  const data = {
    orgId: userDtls.orgId,
    initiator: role,
    customer: value.value.customer[0].id,

    currency: currencyId,

    totalPrice: Number.parseFloat(sum).toFixed(2),
    salesExecutive: userDtls.id,
    licenseeId: userDtls.licenseeId,

    sellingPrice: totalSellPrice,
    buyingPrice: totalBuyPrice,
    products: products,
    paymentMode: value.value.paymentMode[0].id,
    remainAmount:
      payStatus === "partial"
        ? Number.parseFloat(sum - value.value.payAmount).toFixed(2)
        : 0,
  };
  const res = yield call(editOrderData, {
    value: data,
    orderVal: value.value,
    orderProducts: value.productList,
    deletedProduct: value.deletedProduct,
    addedProduct: value.addedProduct,
  });
}

function* editOrderData(value) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let orderId = value.orderVal.id;

  const token = localStorage.getItem("sStraitToken");
  let role = userDtls.role;
  try {
    const res = yield fetch(`${appConfig.ip}/order/` + orderId, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(value.value),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();
      //Updated product quantity by delete product and add
      yield call(getUpdatedProduct, {
        deletedProduct: value.deletedProduct,
        addedProduct: value.addedProduct,
      });
      //Updated product variant  quantity by delete product and add
      yield call(getUpdatedProductVariant, {
        deletedProduct: value.deletedProduct,
        addedProduct: value.addedProduct,
      });
      //update inovice
      yield call(updateInvoice, { res: resJSON, orderVal: value.orderVal });

      //Updated product inventory by delete product and add
      let currentAddedProductList = [...value.addedProduct];
      if (value.deletedProduct?.length) {
        yield all(
          value.deletedProduct.map((x) => {
            if (value.addedProduct?.length) {
              let currentAddedProduct = {};
              if (x.inventory?.variants?.variantId) {
                currentAddedProduct = value.addedProduct.find(
                  (p) =>
                    p.product.id === x.product.id &&
                    x.inventory.variants.variantId ===
                      p.inventory.variants.variantId
                );

                if (currentAddedProduct) {
                  currentAddedProductList = currentAddedProductList.filter(
                    (cP) =>
                      currentAddedProduct.inventory.variants.variantId !==
                      cP.inventory?.variants?.variantId
                  );
                }
              } else {
                currentAddedProduct = value.addedProduct.find(
                  (p) => p.product.id === x.product.id
                );
                if (currentAddedProduct) {
                  currentAddedProductList = currentAddedProductList.filter(
                    (cP) => currentAddedProduct.product.id !== cP.product.id
                  );
                }
              }

              if (currentAddedProduct) {
                return call(updateInventoryQtyByEditOrder, {
                  id: x.inventory.id,
                  quantity: x.inventory.quantity + x.qty,
                  addedPrdt: currentAddedProduct,
                  item: { ...x, invStatus: "delete", orderId: resJSON.refId },
                });
              } else {
                return call(updateInventoryQtyByEditOrder, {
                  id: x.inventory.id,
                  quantity: x.inventory.quantity + x.qty,
                  item: { ...x, invStatus: "delete", orderId: resJSON.refId },
                });
              }
            } else {
              return call(updateInventoryQtyByEditOrder, {
                id: x.inventory.id,
                quantity: x.inventory.quantity + x.qty,
                item: { ...x, invStatus: "delete", orderId: resJSON.refId },
              });
            }
          })
        );
        if (value.addedProduct?.length) {
          yield all(
            currentAddedProductList.map((x) =>
              call(updateInventoryQtyByEditOrder, {
                id: x.inventory.id,
                quantity: x.inventory.quantity - x.qty,
                item: { ...x, orderId: resJSON.refId },
              })
            )
          );
        }
      } else {
        yield all(
          value.addedProduct.map((x) =>
            call(updateInventoryQtyByEditOrder, {
              id: x.inventory.id,
              quantity: x.inventory.quantity - x.qty,
              item: { ...x, orderId: resJSON.refId },
            })
          )
        );
      }

      yield put({
        type: actionTypes.EDIT_ORDERS_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Order edited successfully", {
        autoClose: 3000,
      });
      // yield put({ type: actionTypes.FETCH_TOTAL_AMOUNT_BY_ORDER_INIT_ACTION });
      yield put({
        type: actionTypes.FETCH_DISORDERS_INIT_ACTION,
        value: { page: 1 },
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.EDIT_ORDERS_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* updateInventoryQtyByEditOrder(value) {
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let itemData = value.item;
  let status = value.item?.invStatus;

  const data = {
    quantity: value.quantity,
  };
  try {
    let params = {
      api: `${appConfig.ip}/inventory/${value.id}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
    if (value?.addedPrdt) {
      const res1 = yield call(commonFunction, {
        ...params,
        body: JSON.stringify({
          quantity: res.quantity - value.addedPrdt?.qty,
        }),
      });
      if (res1) {
        let invdata = {
          inventory: itemData.inventory.id,
          product: itemData.product.id,
          user: userDetails.id,
          quantity: value.addedPrdt?.qty,
          type: "reduction",
          note:
            userDetails.name +
            " ordered " +
            value.addedPrdt?.qty +
            " items of " +
            itemData.product.name +
            " with order Id " +
            itemData.orderId,
        };

        try {
          let params = {
            api: `${appConfig.ip}/inventoryLog`,
            method: "POST",
            sucessAction: null,
            failAction: null,
            body: JSON.stringify(invdata),
          };
          const logRes = yield call(commonFunction, params);
        } catch (e) {}
      }
    }
    if (res) {
      let invdata = {
        inventory: itemData.inventory.id,
        product: itemData.product.id,
        user: userDetails.id,
        quantity: itemData.qty,
        type: status === "delete" ? "addition" : "reduction",
        note:
          userDetails.name +
          ` ${status === "delete" ? " deleted " : " ordered "}` +
          itemData.qty +
          " items of " +
          itemData.product.name +
          " with order Id " +
          itemData.orderId,
      };
      let params = {
        api: `${appConfig.ip}/inventoryLog`,
        method: "POST",
        sucessAction: null,
        failAction: null,
        body: JSON.stringify(invdata),
      };
      const logRes1 = yield call(commonFunction, params);
    }
  } catch (e) {}
}

function* getUpdatedProduct(value) {
  let updateDeleteData = [];
  value.deletedProduct.map((p) => {
    updateDeleteData.push({
      quantity: p.qty,
      id: p.product.id,
      variants: p.inventory.variants,
    });
  });

  let updateAddedData = [];
  value.addedProduct.map((p) => {
    updateAddedData.push({
      quantity: p.qty,
      id: p.product.id,
      variants: p.inventory.variants,
    });
  });

  const deleteProductTotalFltr = Array.from(
    updateDeleteData.reduce(
      (m, { id, quantity }) => m.set(id, (m.get(id) || 0) + quantity),
      new Map()
    ),
    ([id, quantity]) => ({ id, quantity })
  );

  const addProductTotalFltr = Array.from(
    updateAddedData.reduce(
      (m, { id, quantity }) => m.set(id, (m.get(id) || 0) + quantity),
      new Map()
    ),
    ([id, quantity]) => ({ id, quantity })
  );
  let deletedProductIds = [];
  if (deleteProductTotalFltr.length) {
    yield all(
      deleteProductTotalFltr.map((x) => {
        deletedProductIds.push(x.id || "");
        if (addProductTotalFltr?.length) {
          let currentAddedProduct = addProductTotalFltr.find(
            (p) => p.id === x.id
          );

          if (currentAddedProduct) {
            return call(updateProductQtyByEdit, {
              id: x.id,
              quantity: +x.quantity,
              addedPrdt: currentAddedProduct,
            });
          } else {
            return call(updateProductQtyByEdit, {
              id: x.id,
              quantity: +x.quantity,
            });
          }
        } else {
          return call(updateProductQtyByEdit, {
            id: x.id,
            quantity: +x.quantity,
          });
        }
      })
    );

    if (addProductTotalFltr?.length) {
      let remainingAddedProducts = addProductTotalFltr.filter(
        (addP) => !deletedProductIds.includes(addP.id)
      );
      yield all(
        remainingAddedProducts.map((x) =>
          call(updateProductQtyByEdit, { id: x.id, quantity: -x.quantity })
        )
      );
    }
  } else {
    yield all(
      addProductTotalFltr.map((x) =>
        call(updateProductQtyByEdit, { id: x.id, quantity: -x.quantity })
      )
    );
  }
}

//update quantity of product by edit
function* updateProductQtyByEdit(value) {
  const data = {
    quantity: value.quantity,
  };
  try {
    let params = {
      api: `${appConfig.ip}/product/updateQuantity/${value.id}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);

    if (value?.addedPrdt) {
      const res1 = yield call(commonFunction, {
        ...params,
        body: JSON.stringify({
          quantity: -value.addedPrdt?.quantity,
        }),
      });
    }
  } catch (e) {}
}

function* getUpdatedProductVariant(value) {
  let deletedProductIds = [];
  if (value.deletedProduct?.length) {
    yield all(
      value.deletedProduct.map((x) => {
        deletedProductIds.push(x.product.id || "");
        if (value.addedProduct?.length) {
          let currentAddedProduct = value.addedProduct.find(
            (p) => p.product.id === x.product.id
          );

          if (currentAddedProduct) {
            return call(updateProductVariantByEdit, {
              item: { ...x, invStatus: "cancel" },
              addedPrdt: currentAddedProduct,
            });
          } else {
            return call(updateProductVariantByEdit, {
              item: { ...x, invStatus: "cancel" },
            });
          }
        } else {
          return call(updateProductVariantByEdit, {
            item: x,
            item: { ...x, invStatus: "cancel" },
          });
        }
      })
    );
    if (value.addedProduct?.length) {
      let remainingAddedProducts = value.addedProduct.filter(
        (addP) => !deletedProductIds.includes(addP.product.id)
      );

      yield all(
        remainingAddedProducts.map((x) =>
          call(updateProductVariantByEdit, { item: x })
        )
      );
    }
  } else {
    yield all(
      value.addedProduct.map((x) =>
        call(updateProductVariantByEdit, { item: x })
      )
    );
  }
}

//update product variant by edit
function* updateProductVariantByEdit(value) {
  let itemData = value.item;

  if (itemData.hasVariant) {
    const variantId = itemData.inventory.variants.variantId;
    const data = {
      variantId: variantId,
      quantity:
        itemData.invStatus && itemData.invStatus === "cancel"
          ? itemData.qty
          : -itemData.qty,
    };

    try {
      let params = {
        api: `${appConfig.ip}/product/variant/${itemData.product.id}`,
        method: "PUT",
        sucessAction: null,
        failAction: null,
        body: JSON.stringify(data),
      };
      const res = yield call(commonFunction, params);

      if (value?.addedPrdt) {
        const res1 = yield call(commonFunction, {
          ...params,
          body: JSON.stringify({
            quantity: -value.addedPrdt?.qty,
            variantId: variantId,
          }),
        });
      }
    } catch (e) {}
  }
}
// update invoice by edit
function* updateInvoice(value) {
  let orderId = value.res.id;

  const totalDisc = value.res.products.reduce((a, object) => {
    return a + object.discount;
  }, 0);

  try {
    let params = {
      api: `${appConfig.ip}/invoice?isActive=true&order=${orderId}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const Res = yield call(commonFunction, params);

    let invoiceId = Res.rows.length ? Res.rows[0].id : "";
    let licenseeId = Res.rows.length ? Res.rows[0].licenseeId.id : "";
    let data = {
      discount: totalDisc,
      licenseeId: licenseeId,
      amount: value.res.totalPrice,
      subtotal: value.res.totalPrice,
    };

    if (invoiceId) {
      let params = {
        api: `${appConfig.ip}/invoice/${invoiceId}`,
        method: "PUT",
        sucessAction: null,
        failAction: null,
        body: JSON.stringify(data),
      };
      const invoiceRes = yield call(commonFunction, params);
    }

    return Res;
  } catch (e) {}
}

function* fetchSalesExecutiveByOrder(value) {
  let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licenseeId = userDetails.licenseeId;
  let liceDetails = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  let network = liceDetails.networkHierarchy;
  let userRole = network === true ? "salesExcecutive" : "agent";

  try {
    let params = {
      api: `${appConfig.ip}/users?licenseeId=${licenseeId}&role=${userRole}&status=active`,
      method: "GET",
      sucessAction: actionTypes.FETCH_SALESEXECUTIVE_BY_ORDER_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_SALESEXECUTIVE_BY_ORDER_FAIL_ACTION,
      body: null,
    };
    const Res = yield call(commonFunction, params);
    return Res;
  } catch (e) {}
}

//update order status for replace
function* updateOrderStatusForReplace(value) {
  let status =
    value?.replaceType === "partial" ? "partialreplace" : "fullreplace" || null;
  let id = value?.orderId || null;

  const data = {
    status: status,
  };
  try {
    let params = {
      api: `${appConfig.ip}/order/${id}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
    yield put({
      type: actionTypes.FETCH_DISORDERS_INIT_ACTION,
      value: { page: 1 },
    });
    return res;
  } catch (e) {}
}

export function* DisOrderActionWatcher() {
  yield takeEvery(actionTypes.FETCH_DISORDERS_INIT_ACTION, fetchDisOrder);
  yield takeEvery(actionTypes.ADD_ORDERS_INIT_ACTION, addOrder);
  yield takeEvery(
    actionTypes.CHANGE_DISORDERSTATUS_INIT_ACTION,
    changeDisOrderStatus
  );
  yield takeEvery(
    actionTypes.PAYAMOUNT_DISORDER_INIT_ACTION,
    DisOrderPayAmount
  );
  yield takeEvery(
    actionTypes.CHANGE_DISORDERAPPROVE_INIT_ACTION,
    changeDisOrderApprove
  );
  yield takeEvery(
    actionTypes.CUSTOMER_DISCOUNTBYID_INIT_ACTION,
    customerDiscountbyId
  );
  yield takeEvery(actionTypes.EXPORT_ORDER_DATA_INIT_ACTION, exprtOrderData);
  yield takeEvery(
    actionTypes.FETCH_CUSTOMER_BY_ORDER_INIT_ACTION,
    fetchCustomerByOrder
  );
  yield takeEvery(
    actionTypes.FETCH_INVENTORYBYPRODUCT_INIT_ACTION,
    fetchIventoryByProduct
  );
  yield takeEvery(
    actionTypes.FETCH_TOTAL_AMOUNT_BY_ORDER_INIT_ACTION,
    fetchTotalAmountByOrder
  );
  yield takeEvery(actionTypes.ADD_PRODUCT_RETURN_INIT_ACTION, addProductReturn);
  yield takeEvery(
    actionTypes.GET_RETURN_BY_ORDER_ID_INIT_ACTION,
    getReturnByOrderId
  );
  yield takeEvery(
    actionTypes.FETCH_CUST_CREDITNOTES_INIT_ACTION,
    fectCustCreditNotes
  );

  yield takeEvery(actionTypes.EDIT_ORDERS_INIT_ACTION, editOrder);
  yield takeEvery(
    actionTypes.ADD_PRODUCT_REPLACE_INIT_ACTION,
    addProductReplace
  );
  yield takeEvery(
    actionTypes.FETCH_SALESEXECUTIVE_BY_ORDER_INIT_ACTION,
    fetchSalesExecutiveByOrder
  );
  // yield takeEvery(
  //   actionTypes.FETCH_INVENTARY_OF_PRODUCT_INIT_ACTION,
  //   fetchInventoryOfProduct
  // );
}
