import * as actionTypes from "./constants";

const fetchExpenses = (params) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_EXPENSE_INIT_ACTION,
      params: params,
    });
  };
};

const addExpense = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_EXPENSE_INIT_ACTION,
      value: value,
    });
  };
};

const deleteExpense = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_EXPENSE_INIT_ACTION,
      data: data,
    });
  };
};

const editExpense = (id, params) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_EXPENSE_INIT_ACTION,
      id: id,
      value: params,
    });
  };
};

const fetchExpensesTypeInAgent = (params) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_EXPENSE_TYPE_IN_AGENT_INIT_ACTION,
      params: params,
    });
  };
};

const changeExpenseApprove = (params) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.APPROVE_EXPENSE_INIT_ACTION,
      params: params,
    });
  };
};

export default {
  fetchExpenses,
  addExpense,
  deleteExpense,
  editExpense,
  fetchExpensesTypeInAgent,
  changeExpenseApprove,
};
