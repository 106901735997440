export const UPDATE_PRODUCTINVENTORY_INIT_ACTION =
  "UPDATE_PRODUCTINVENTORY_INIT_ACTION";
export const UPDATE_PRODUCTINVENTORY_SUCCESS_ACTION =
  UPDATE_PRODUCTINVENTORY_INIT_ACTION + "_SUCCESS";
export const UPDATE_PRODUCTINVENTORY_FAIL_ACTION =
  UPDATE_PRODUCTINVENTORY_INIT_ACTION + "_ERROR";

export const FETCH_INVENTORYLOG_INIT_ACTION = "FETCH_INVENTORYLOG_INIT_ACTION";
export const FETCH_INVENTORYLOG_SUCCESS_ACTION =
  FETCH_INVENTORYLOG_INIT_ACTION + "_SUCCESS";
export const FETCH_INVENTORYLOG_FAIL_ACTION =
  FETCH_INVENTORYLOG_INIT_ACTION + "_ERROR";

export function UpdateProductInv(value) {
  return {
    type: UPDATE_PRODUCTINVENTORY_INIT_ACTION,
    value: value,
  };
}

export function fetchInventoryLog(value) {
  return {
    type: FETCH_INVENTORYLOG_INIT_ACTION,
    value: value,
  };
}
