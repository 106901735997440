import { put, takeEvery, select, call, all } from "redux-saga/effects";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { createBrowserHistory } from "history";
import {
  FETCH_PAYMENTS_INIT_ACTION,
  FETCH_PAYMENTS_SUCCESS_ACTION,
  FETCH_PAYMENTS_FAIL_ACTION,
  FETCH_PAYMENTSBYID_INIT_ACTION,
  FETCH_PAYMENTSBYID_SUCCESS_ACTION,
  FETCH_PAYMENTSBYID_FAIL_ACTION,
  // FETCH_AREAS__DROPDOWN_INIT_ACTION,
  // FETCH_AREAS__DROPDOWN_SUCCESS_ACTION,
  // FETCH_AREAS__DROPDOWN_FAIL_ACTION
  EXPORT_PAYMENT_INIT_ACTION,
  EXPORT_PAYMENT_SUCCESS_ACTION,
  EXPORT_PAYMENT_FAIL_ACTION,
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as selectors from "../../service/selectors";
import { shallow } from "enzyme";

function* getRoleApiUrl() {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const role = localStorage.getItem("sStraitUserRole");
  const userId = localStorage.getItem("sStraitUserId");

  let getDataUrl = [
    { role: "licensee", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "distributor", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "licenseeAccountant", url: "&licenseeId=" + userDtls.licenseeId },
    {
      role: "regionalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&region=" + userDtls.region,
    },
    {
      role: "zonalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&zone=" + userDtls.zone,
    },
    {
      role: "areaManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
    {
      role: "salesExcecutive",
      url: "&licenseeId=" + userDtls.licenseeId + "&salesExecutive=" + userId,
    },
    {
      role: "agent",
      url: "&licenseeId=" + userDtls.licenseeId + "&salesExecutive=" + userId,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);
  return apiUrl;
}

function* fetchPayments(value) {
  let pageno = value.value.page === undefined ? 1 : value.value.page;
  const token = localStorage.getItem("sStraitToken");

  const apiUrl = yield call(getRoleApiUrl);
  let filterVal =
    !value || !value.value || !value.value.filterVal
      ? ""
      : value.value.filterVal;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? ""
      : value.value.searchVal;

  let _url = `${appConfig.ip}/payment?limit=10&search=${searchVal}${filterVal}&page=${pageno}${apiUrl.url}`;

  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  } else if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  } else if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_PAYMENTS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_PAYMENTS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* getPaymentsById(value) {
  const token = localStorage.getItem("sStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/payment/` + value.value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_PAYMENTSBYID_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_PAYMENTSBYID_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* exprtFullData(value) {
  let fileName = "Payment Report";
  const token = localStorage.getItem("sStraitToken");
  const apiUrl = yield call(getRoleApiUrl);

  let searchVal =
    !value || !value.value || !value.value.searchQuery
      ? ""
      : value.value.searchQuery;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let filterVal =
    !value || !value.value || !value.value.filterVal
      ? ""
      : value.value.filterVal;

  let _url = `${appConfig.ip}/payment?limit=100&search=${searchVal}${apiUrl.url}`;

  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  }
  if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  }
  if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }
  if (filterVal) {
    _url = `${_url}${filterVal}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let csvData = [];

      resJSON.rows.map((item) => {
        csvData.push({
          PAYMENT_ID: item.refId,
          ORDER_ID: item.order ? item.order.refId : "NIL",
          AMOUNT: item.currency.symbol + item.amount,
          PRODUCT_ID:
            item && item.order && item.order && item.order.product
              ? item.order.product.refId
              : "NIL",
          AMOUNT: item.currency.symbol + item.amount,
          LICENSEE_NAME:
            item.licenseeId != null ? item.licenseeId.orgName : "NIL",
          // REGION: item.region != null ? item.region.name : "NIL",
          // ZONE: item.zone != null ? item.zone.name : "NIL",
          // AREA: item.area != null ? item.area.name : "NIL",
        });
      });

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      yield put({
        type: EXPORT_PAYMENT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EXPORT_PAYMENT_FAIL_ACTION, error: err });
    } else {
    }
  }
}

// function* fetchAreaa_Dropdown(value) {

//   let zone = !value || !value.value.id ? "": value.value.id;
//   const token = localStorage.getItem("sStraitToken");
//   try {
//     const res = yield fetch(`${appConfig.ip}/area?zone=${zone}&isActive=true`, {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     if (!res.ok) {
//       let errJSON = {};
//       try {
//         errJSON = yield res.json();
//       } catch { }
//       throw Object.assign(res, errJSON);
//     } else {
//       const resJSON = yield res.json();

//       yield put({
//         type: FETCH_AREAS__DROPDOWN_SUCCESS_ACTION,
//         payload: resJSON,
//       });
//     }
//   } catch (err) {
//     if (err.ok === false) {
//       yield put({ type: FETCH_AREAS__DROPDOWN_FAIL_ACTION, error: err });
//     } else {
//     }
//   }
// }

export function* PaymentsActionWatcher() {
  yield takeEvery(FETCH_PAYMENTS_INIT_ACTION, fetchPayments);
  yield takeEvery(FETCH_PAYMENTSBYID_INIT_ACTION, getPaymentsById);
  // yield takeEvery(FETCH_AREAS__DROPDOWN_INIT_ACTION, fetchAreaa_Dropdown);
  yield takeEvery(EXPORT_PAYMENT_INIT_ACTION, exprtFullData);
}
