import * as actionTypes from "./constants";

const initialState = {
  processing: false,
  error: false,
  isModel: false,
};

export default function googleFormReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_FORMDATA_SUCCESS_ACTION:
      return {
        ...state,
        formList: action.payload.rows,
        formCount: action.payload.count,
        isModel: false,
      };
    case actionTypes.ADD_FORMNAME_SUCCESS_ACTION:
      return {
        ...state,
        formData: action.payload,
        isModel: true,
      };
    case actionTypes.FETCH_INTEGRATIONGUIDE_SUCCESS_ACTION:
      return {
        ...state,
        integrationGuideData: action.payload,
        isModel: false,
      };

    default:
      return {
        ...state,
      };
  }
}
