import React from "react";
import Select from "react-select";

export default function CusSelect(props) {
  // const CusSelect = (props.isRequired)
  const handleChange = (value) => {
    // this is going to call setFieldValue and manually update values.topcis
    props.onChange && props.onChange(props.id, value);
  };

  const handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    props.onBlur && props.onBlur(props.id, true);
  };

  return (
    <div
    //style={{ margin: '1rem 0' }}
    >
      {props.label ? (
        <label htmlFor={props.id} className="label-inpt">
          {props.label}{" "}
        </label>
      ) : null}
      {props.isRequired && <span className="astrk">*</span>}

      <Select
        isDisabled={props.disabled}
        id={props.id}
        isMulti={props.multi}
        options={props.options}
        onChange={handleChange}
        onBlur={handleBlur}
        value={props.value}
        onInputChange={props.onInputChange}
        placeholder={props.placeholder && props.placeholder}
        isClearable={props.isClearable}
        isSearchable={props.isSearchable}
      />
      {!!props.error && props.touched && (
        <div className="errStyle">{props.error}</div>
      )}
    </div>
  );
}
