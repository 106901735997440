export const RESETPWD_INIT_ACTION = "RESETPWD_INIT";
export const RESETPWD_FAIL_ACTION = RESETPWD_INIT_ACTION + "_ERROR";
export const RESETPWD_SUCCESS_ACTION = RESETPWD_INIT_ACTION + "_SUCCESS";

export const VERIFYPWDOTP_INIT_ACTION = "VERIFYPWDOTP_INIT";
export const VERIFYPWDOTP_SUCCESS_ACTION = VERIFYPWDOTP_INIT_ACTION+"_SUCCESS";
export const VERIFYPWDOTP_FAIL_ACTION = VERIFYPWDOTP_INIT_ACTION+"_ERROR";

export const RESENDPWDOTP_INIT_ACTION = "RESENDPWDOTP_INIT";
export const RESENDPWDOTP_SUCCESS_ACTION = RESENDPWDOTP_INIT_ACTION+"_SUCCESS";
export const RESENDPWDOTP_FAIL_ACTION = RESENDPWDOTP_INIT_ACTION+"_ERROR";

export const SETNEWPWD_INIT_ACTION = "SETNEWPWD_INIT";
export const SETNEWPWD_SUCCESS_ACTION = SETNEWPWD_INIT_ACTION+"_SUCCESS";
export const SETNEWPWD_FAIL_ACTION = SETNEWPWD_INIT_ACTION+"_ERROR";

export const REDIRECT_INIT_ACTION = "REDIRECT_INIT_ACTION";
export const REDIRECT_SUCCESS_ACTION = REDIRECT_INIT_ACTION+"_SUCCESS";
export const REDIRECT_FAIL_ACTION = REDIRECT_INIT_ACTION+"_ERROR";

export function sendOtpforResetPwd(value) {
  return {
    type: RESETPWD_INIT_ACTION,
    value: value,
  };
}

export function verifyOtpforResetPwd(value){
    return {
        type: VERIFYPWDOTP_INIT_ACTION,
        value:value,
    }
}

export function resendOtpforResetPwd(value) {
    return {
      type: RESENDPWDOTP_INIT_ACTION,
      value: value,
    };
  }

  export function resetPwd(value) {
    return {
      type: SETNEWPWD_INIT_ACTION,
      value: value,
    };
  }

  export function redirectTologin() {
    return {
      type: REDIRECT_INIT_ACTION,
    };
  }