import { put, takeEvery, select, call, all } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  UPDATE_PRODUCTINVENTORY_INIT_ACTION,
  UPDATE_PRODUCTINVENTORY_SUCCESS_ACTION,
  UPDATE_PRODUCTINVENTORY_FAIL_ACTION,
  FETCH_INVENTORYLOG_INIT_ACTION,
  FETCH_INVENTORYLOG_SUCCESS_ACTION,
  FETCH_INVENTORYLOG_FAIL_ACTION,
} from "./action";

import { appConfig } from "../../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* commonFunction(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: `${value.failAction}`, error: err });
    } else {
    }
  }
}

//Fetch inventory log
function* fetchInventoryLog(value) {
  let page = (value.value && value.value.page) || 1;
  let inventoryId =
    typeof value.value === "object" ? value.value.inventoryId : value.value;

  try {
    let params = {
      api: `${appConfig.ip}/inventoryLog?status=active&limit=10&page=${page}&inventory=${inventoryId}`,
      method: "GET",
      sucessAction: FETCH_INVENTORYLOG_SUCCESS_ACTION,
      failAction: FETCH_INVENTORYLOG_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

//Update inventory log
function* UpdateProductInv(value) {
  let values = value.value.value;
  let item = value.value.item;

  const qty =
    values.type == "addition"
      ? item.quantity + values.quantity
      : item.quantity - values.quantity;

  const productInvData = {
    quantity: qty,
  };
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/inventory/${values.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(productInvData),
    });

    if (!res.ok) {
      let errJSON1 = {};
      try {
        errJSON1 = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON1);
    } else {
      const resJSON = yield res.json();
      const resJSONById = yield call(getInventoryById, resJSON.id);

      //Create inventory log
      yield call(createInventoryLog, { resJSON, values });
      // yield put({ type: FETCH_INVENTORY_INIT_ACTION, value: { page: 1 } });

      // const qty = value.value.quantity + value.value.product[0].quantity;

      //product and variant update
      yield call(updateProductQty, { values, item });
      if (item.isVariant) {
        yield call(updateProductVariant, { values, item });
      }

      yield put({
        type: UPDATE_PRODUCTINVENTORY_SUCCESS_ACTION,
        payload: resJSONById,
      });
      yield toast.success("Product Inventory added successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: UPDATE_PRODUCTINVENTORY_FAIL_ACTION,
        error: err,
      });
    }
  }
}

//Update product quantity

function* updateProductQty(value) {
  let id = value.item.product.id;
  let newData = value.values;
  const data = {
    quantity:
      newData.type === "addition" ? newData.quantity : -newData.quantity,
  };

  try {
    let params = {
      api: `${appConfig.ip}/product/updateQuantity/${id}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
    return res;
  } catch (e) {}
}

//Update product variant
function* updateProductVariant(value) {
  let id = value.item.product.id;
  let newData = value.values;
  let variantId = value.item.variants.variantId;
  const data = {
    variantId: variantId,
    quantity:
      newData.type === "addition" ? newData.quantity : -newData.quantity,
  };

  try {
    let params = {
      api: `${appConfig.ip}/product/variant/${id}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
    return res;
  } catch (e) {}
}

//Get inventory by id
function* getInventoryById(value) {
  try {
    let params = {
      api: `${appConfig.ip}/inventory/${value}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const res = yield call(commonFunction, params);
    return res;
  } catch (e) {}
}

//create Inventory Log
function* createInventoryLog(value) {
  const label = value.values.type == "addition" ? "added" : "deducted";

  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const data = {
    inventory: value.resJSON.id,
    product: value.resJSON.product.id,
    user: userDetails.id,
    quantity: value.values.quantity,
    desc: value.values.desc,
    type: value.values.type,
    note:
      "In the last update of the inventory, " +
      value.values.quantity +
      " items were " +
      label +
      " by " +
      userDetails.name,
  };

  // //In the last update of the inventory, three items were added by Reahma
  // //In the inventory update, Reahma has added 3 items
  try {
    let params = {
      api: `${appConfig.ip}/inventoryLog`,
      method: "POST",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

//old variant and product update
// function* getProductById(value) {
//   let id = value.item.product.id;
//   try {
//     let params = {
//       api: `${appConfig.ip}/product/${id}`,
//       method: "GET",
//       sucessAction: null,
//       failAction: null,
//       body: null,
//     };
//     const res = yield call(commonFunction, params);

//     yield call(productUpdate, { res, value });
//     return res;
//   } catch (e) {}
// }

// function* productUpdate(value) {
//   let itemData = value.value.item;
//   let newData = value.value.values;
//   let qty =
//     newData.type === "addition"
//       ? value.res.quantity + newData.quantity
//       : value.res.quantity - newData.quantity;

//   let data;
//   if (itemData.isVariant) {
//     const variantId = itemData.variants.variantId;
//     let result;
//     if (newData.type === "addition") {
//       result = value.res.variants.map((item) =>
//         item.variantId === variantId
//           ? { ...item, quantity: item.quantity + newData.quantity }
//           : item
//       );
//     } else {
//       result = value.res.variants.map((item) =>
//         item.variantId === variantId
//           ? { ...item, quantity: item.quantity - newData.quantity }
//           : item
//       );
//     }
//     data = {
//       quantity: qty,
//       variants: result,
//     };
//   } else {
//     data = {
//       quantity: qty,
//     };
//   }

//   try {
//     let params = {
//       api: `${appConfig.ip}/product/${itemData.product.id}`,
//       method: "PUT",
//       sucessAction: null,
//       failAction: null,
//       body: JSON.stringify(data),
//     };
//     const res = yield call(commonFunction, params);
//     return res;
//   } catch (e) {}
// }

export function* DisProductInventoryActionWatcher() {
  yield takeEvery(UPDATE_PRODUCTINVENTORY_INIT_ACTION, UpdateProductInv);
  yield takeEvery(FETCH_INVENTORYLOG_INIT_ACTION, fetchInventoryLog);
}
