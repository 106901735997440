import { put, takeEvery, select, call, all } from "redux-saga/effects";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { createBrowserHistory } from "history";
import {
  FETCH_INVOICES_INIT_ACTION,
  FETCH_INVOICES_SUCCESS_ACTION,
  FETCH_INVOICES_FAIL_ACTION,
  FETCH_INVOICESBYID_INIT_ACTION,
  FETCH_INVOICESBYID_SUCCESS_ACTION,
  FETCH_INVOICESBYID_FAIL_ACTION,
  PDF_REPORT_INIT_ACTION,
  PDF_REPORT_SUCCESS_ACTION,
  PDF_REPORT_FAIL_ACTION,
  FETCH_INVOICE_CONFIG_INIT_ACTION,
  FETCH_INVOICE_CONFIG_SUCCESS_ACTION,
  FETCH_INVOICE_CONFIG_FAIL_ACTION,
  ADD_REMARK_INIT_ACTION,
  ADD_REMARK_SUCCESS_ACTION,
  ADD_REMARK_FAIL_ACTION,
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as selectors from "../../service/selectors";
import { shallow } from "enzyme";
import jsPDF from "jspdf";
import "jspdf-autotable";
var dateFormat = require("dateformat");

function* getRoleApiUrl() {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const role = localStorage.getItem("sStraitUserRole");
  const userId = localStorage.getItem("sStraitUserId");

  let getDataUrl = [
    { role: "licensee", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "distributor", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "licenseeAccountant", url: "&licenseeId=" + userDtls.licenseeId },
    {
      role: "regionalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&region=" + userDtls.region,
    },
    {
      role: "zonalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&zone=" + userDtls.zone,
    },
    {
      role: "areaManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
    {
      role: "salesExcecutive",
      url: "&licenseeId=" + userDtls.licenseeId + "&creator=" + userId,
    },
    {
      role: "agent",
      url: "&licenseeId=" + userDtls.licenseeId + "&creator=" + userId,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);
  return apiUrl;
}

function* fetchInvoices(value) {
  let pageno = value.value.page === undefined ? 1 : value.value.page;
  const token = localStorage.getItem("sStraitToken");

  const apiUrl = yield call(getRoleApiUrl);
  let filterVal =
    !value || !value.value || !value.value.filterVal
      ? ""
      : value.value.filterVal;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? ""
      : value.value.searchVal;
  let ctgryFilter = (value.value && value.value.ctgryFilter) || "";

  let _url = `${appConfig.ip}/invoice?limit=10&page=${pageno}${apiUrl.url}`;

  if (ctgryFilter) {
    _url = `${_url}&category=${ctgryFilter}`;
  }
  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }
  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  }
  if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  }
  if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_INVOICES_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_INVOICES_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* getInvoicesById(value) {
  const token = localStorage.getItem("sStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/invoice/` + value.value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let image = resJSON.config.logo ? resJSON.config.logo : "";

      const resDocImage = yield fetch(
        `${appConfig.ip}/images/encodeBase64?source=${image}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let resultImage = yield resDocImage.json();

      yield put({
        type: FETCH_INVOICESBYID_SUCCESS_ACTION,
        payload: resJSON,
        resultImage: resultImage,
      });

      // yield put({
      //   type: FETCH_INVOICESBYID_SUCCESS_ACTION,
      //   payload: resJSON,
      // });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_INVOICESBYID_FAIL_ACTION, error: err });
    } else {
    }
  }
}

// function* fetchPdfReport(value) {
//   const token = localStorage.getItem("sStraitToken");
//   const invoiceId = value.value.invoiceId;
//   const orderRefId = value.value.orderRefId;
//   const OrgName = value.value.licenseeId.orgName;

//   const CustName = value.value.receiver.name;
//   const Mobile = value.value.receiver.mobileNo;
//   const Email = value.value.receiver.email;
//   let addr1 = JSON.parse(localStorage.getItem("sStraitLicProfDtls")).addr1;
//   let licMob = JSON.parse(localStorage.getItem("sStraitLicProfDtls")).userId
//     .mobileNo;
//   let licState = JSON.parse(localStorage.getItem("sStraitLicProfDtls")).district
//     .name;
//   let licDistrict = JSON.parse(localStorage.getItem("sStraitLicProfDtls"))
//     .district.name;
//   let licEmail = JSON.parse(localStorage.getItem("sStraitLicProfDtls")).userId
//     .email;

//   const Date = dateFormat(value.value.createdAt, "mediumDate");

//   try {
//     const res = yield fetch(`${appConfig.ip}/invoice/` + value.value.id, {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     if (!res.ok) {
//       throw res;
//     } else {
//       const resJSON = yield res.json();
//       const CustAddress = resJSON.receiver.address;
//       const OrderDate = dateFormat(resJSON.order.createdAt, "mediumDate");

//       const count = resJSON.count;
//       const doc = new jsPDF();
//       const tableColumn = [
//         "SL NO",
//         "PRODUCT",
//         "UNIT PRICE",
//         "QTY",
//         "DISCOUNT",
//         "GST %",
//         "TOTAL AMOUNT",
//       ];
//       const tableRows = [];

//       const ArrayConv = resJSON;
//       const reportData = [
//         1,
//         ArrayConv.order.product.name,

//         ArrayConv.order.product.sellingPrice,
//         ArrayConv.order.quantity,
//         ArrayConv.discount,
//         ArrayConv.order.gstSlab.prcntg,
//         ArrayConv.subtotal,
//       ];

//       tableRows.push(reportData);
//       doc.autoTable(tableColumn, tableRows, {
//         theme: "grid",
//         styles: {
//           halign: "left",
//         },
//         headerStyles: {
//           fillColor: [105, 105, 105],
//           halign: "center",
//         },
//         margin: {
//           top: 130,
//         },
//       });

//       doc.setFontSize(10);
//       doc.text(`${OrgName}`, 14, 45);
//       doc.setFontSize(10);
//       doc.setFontSize(10);
//       doc.text(`${licMob}`, 14, 50);
//       doc.setFontSize(10);
//       doc.text(`${licEmail}`, 14, 55);
//       var str = doc.splitTextToSize(`${addr1}`, 50);
//       doc.text(str, 14, 60);
//       // doc.setFontSize(10);
//       // doc.text(`${licDistrict}`, 14, 60,);
//       // doc.setFontSize(10);
//       // doc.text(`${licState}`, 14, 65,);
//       doc.setFontSize(10);
//       doc.text(`${CustName}`, 14, 80);
//       doc.setFontSize(10);
//       doc.text(`${CustName}`, 145, 80);
//       doc.setFontSize(10);
//       doc.text(`Invoice No : ${invoiceId}`, 145, 45);
//       doc.setFontSize(10);
//       doc.text(`${Mobile}`, 14, 85);
//       doc.setFontSize(10);
//       doc.text(`${Mobile}`, 145, 85);
//       doc.setFontSize(10);
//       doc.text(`Invoice Date : ${Date}`, 145, 50);
//       doc.setFontSize(10);
//       var str = doc.splitTextToSize(`${CustAddress}`, 50);
//       doc.text(str, 14, 90);
//       doc.setFontSize(10);
//       var str = doc.splitTextToSize(`${CustAddress}`, 50);
//       doc.text(str, 145, 90);
//       // doc.setFontSize(10);
//       // doc.text(`${Email}`, 145, 75);
//       doc.setFontSize(10);
//       doc.text(`Order No : ${orderRefId}`, 14, 115);
//       doc.setFontSize(10);
//       doc.text(`Order Date : ${OrderDate}`, 14, 120);
//       doc.setFontSize(11);
//       doc.text(`For ${OrgName}:`, 155, 155);
//       doc.setFontSize(11);
//       doc.text("Authorized Signature", 155, 170);
//       doc.setFontSize(13);
//       doc.text("Thank You for your Business", 14, 195);
//       //Bold Text
//       doc.setFontSize(12);
//       doc.setFont("Roboto-Regular", "bold");
//       doc.text(`Sold By`, 14, 40);
//       doc.setFontSize(12);
//       doc.text(`Billing Address`, 14, 75);
//       doc.setFontSize(12);
//       doc.text(`Shipping Address`, 145, 75);
//       doc.setFontSize(18);
//       doc.text(`TAX INVOICE`, 85, 20);
//       doc.setFontSize(13);
//       doc.text(`${OrgName}`, 14, 200);

//       doc.save(`${orderRefId}- ${invoiceId}.pdf`);

//       yield put({
//         type: PDF_REPORT_SUCCESS_ACTION,
//         payload: resJSON,
//       });
//     }
//   } catch (err) {
//     if (err.ok === false) {
//       yield put({
//         type: PDF_REPORT_FAIL_ACTION,
//         error: err,
//       });
//     } else {
//     }
//   }
// }

function* fetchInvoiceConfig(params) {
  let userData = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let userParam = userData.id;
  let role = localStorage.getItem("sStraitUserRole");
  let invoiveConfigFilter = "";
  let salesFilterparam = "";
  if (role === "distributor" || role === "licensee") {
    invoiveConfigFilter = `&licenseeId=${userData.licenseeId}`;
  }

  if (role === "salesExcecutive" || role === "agent") {
    salesFilterparam = `&licenseeId=${userData.licenseeId}`;
  }
  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/invoiceConfig?isActive=true${salesFilterparam}${invoiveConfigFilter}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let data = resJSON.rows;
      let image = data.length ? data[0].imgUrl : "";

      const resDocImage = yield fetch(
        `${appConfig.ip}/images/encodeBase64?source=${image}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let resultImage = yield resDocImage.json();

      yield put({
        type: FETCH_INVOICE_CONFIG_SUCCESS_ACTION,
        payload: resultImage,
        invoiceConfigData: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_INVOICE_CONFIG_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* addRemark(value) {
  let id = (value.value && value.value.id) || "";
  let userData = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  let data = {
    notes: (value.value && value.value.notes) || "",
    licenseeId: (userData && userData.licenseeId) || "",
  };

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/invoice/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({ type: ADD_REMARK_SUCCESS_ACTION });
      // yield put({
      //   type: FETCH_INVOICESBYID_SUCCESS_ACTION,
      //   payload: resJSON,
      // });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_REMARK_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

export function* InvoicesActionWatcher() {
  yield takeEvery(FETCH_INVOICES_INIT_ACTION, fetchInvoices);
  yield takeEvery(FETCH_INVOICESBYID_INIT_ACTION, getInvoicesById);

  // yield takeEvery(PDF_REPORT_INIT_ACTION, fetchPdfReport);

  yield takeEvery(FETCH_INVOICE_CONFIG_INIT_ACTION, fetchInvoiceConfig);

  yield takeEvery(ADD_REMARK_INIT_ACTION, addRemark);
}
