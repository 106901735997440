import { put, takeEvery, call } from "redux-saga/effects";
import * as actionTypes from "./contstants";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import { push } from "connected-react-router";
import "react-toastify/dist/ReactToastify.css";

function* addSignUpData(params) {
  const signupValue = {
    name: (params.params && params.params.name) || "",
    lName: (params.params && params.params.lName) || "",
    email: (params.params && params.params.email) || "",
    mobileNo: (params.params && params.params.mobileNo) || "",
    password: (params.params && params.params.password) || "",
    country: (params.params && params.params.country) || null,
    state: (params.params && params.params.state) || null,
    district: (params.params && params.params.district) || null,
    addr1: (params.params && params.params.addr1) || "",
    gstin: (params.params && params.params.gstin) || "",
    orgName: (params.params && params.params.orgName) || "",

    bankName: (params.params && params.params.bankName) || null,
    accountName: (params.params && params.params.accountName) || "",
    accountType: (params.params && params.params.accountType) || null,
    branchName: (params.params && params.params.branchName) || null,
    accountNo: (params.params && params.params.accountNo) || "",
    branchAddress: (params.params && params.params.branchAddress) || "",
    ifscCode: (params.params && params.params.ifscCode) || "",
    networkHierarchy:
      (params.params && params.params.networkHierarchy) || false,

    type: (params.params && params.params.type) || "",
    role:
      params.params && params.params.type === "licensee"
        ? "licensee"
        : "distributor",
  };

  try {
    const res = yield fetch(`${appConfig.ip}/users/addLicensee`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(signupValue),
    });

    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();
      yield put({
        type: actionTypes.ADD_SIGNUP_DATA_SUCCESS_ACTION,
        payload: resJSON,
      });

      localStorage.setItem("user", JSON.stringify(resJSON.user));
      const currencyData = yield call(fetchCntryCrncy, resJSON.country.id);
      localStorage.setItem("sStraitCurrency", JSON.stringify(currencyData));
      yield toast.success("Signup successfully", {
        autoClose: 3000,
      });
      yield put(push("/login"));
    }
  } catch (err) {
    if (err.status === 401) {
      yield toast.error("Invalid credentials", {
        autoClose: 3000,
        position: "top-right",
      });
    }
    if (err.status === 409) {
      yield toast.error("Email already registered", {
        autoClose: 3000,
        position: "top-right",
      });
    }

    yield put({
      type: actionTypes.ADD_SIGNUP_DATA_FAIL_ACTION,
      payload: err,
    });
  }
}

function* fetchCntryCrncy(countryId) {
  const token = localStorage.getItem("sStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/countryCrncy?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      let resData = resJSON.rows.find((e) => e.countryId.id === countryId);
      // let crncyId = resData.currencyId.id
      let crncyId = resData;

      return crncyId;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* fetchCountryInSignup(value) {
  try {
    const token = localStorage.getItem("saleStraitToken");

    const res = yield fetch(`${appConfig.ip}/country?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_COUNTRY_IN_SIGNUP_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_COUNTRY_IN_SIGNUP_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchStateInSignup(param) {
  let countryId = param.param || null;
  try {
    const token = localStorage.getItem("saleStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/state?isActive=true&countryId=${countryId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_STATE_IN_SIGNUP_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_STATE_IN_SIGNUP_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchDistrictInSignup(param) {
  let stateId = param.param || null;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/districts?isActive=true&stateId=${stateId}`,

      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_DISTRICT_IN_SIGNUP_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_DISTRICT_IN_SIGNUP_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchBankInSignup() {
  try {
    const token = localStorage.getItem("saleStraitToken");

    const res = yield fetch(`${appConfig.ip}/bankName/all`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
    } else {
      const resJSON = yield res.json();
      yield put({
        type: actionTypes.FETCH_BANK_IN_SIGNUP_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_BANK_IN_SIGNUP_FAIL_ACTION,
        error: err,
      });
    }
  }
}

export function* signupActionWatcher() {
  yield takeEvery(actionTypes.ADD_SIGNUP_DATA_INIT_ACTION, addSignUpData);
  yield takeEvery(
    actionTypes.FETCH_COUNTRY_IN_SIGNUP_INIT_ACTION,
    fetchCountryInSignup
  );
  yield takeEvery(
    actionTypes.FETCH_STATE_IN_SIGNUP_INIT_ACTION,
    fetchStateInSignup
  );
  yield takeEvery(
    actionTypes.FETCH_DISTRICT_IN_SIGNUP_INIT_ACTION,
    fetchDistrictInSignup
  );
  yield takeEvery(
    actionTypes.FETCH_BANK_IN_SIGNUP_INIT_ACTION,
    fetchBankInSignup
  );
}
