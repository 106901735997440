import * as actionTypes from "./constants";

const initialState = {
  processing: false,
  error: false,
  creditNoteConfigurationList: [],
  creditNoteConfigurationCount: 0,
  creditNoteConfigurationTypeList: [],
};

export default function creditNoteConfigurationReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_CREDIT_NOTE_CONFIGURATION_SUCCESS_ACTION:
      return {
        ...state,
        creditNoteConfigurationList: action.payload.rows,
        creditNoteConfigurationCount: action.payload.count,
      };

    default:
      return {
        ...state,
      };
  }
}
