export const FETCH_ORDERS_INIT_ACTION = "FETCH_ORDERS_INIT_ACTION";
export const FETCH_ORDERS_SUCCESS_ACTION =
  FETCH_ORDERS_INIT_ACTION + "_SUCCESS";
export const FETCH_ORDERS_FAIL_ACTION = FETCH_ORDERS_INIT_ACTION + "_ERROR";

export const CREATE_ORDERS_INIT_ACTION = "CREATE_ORDERS_INIT_ACTION";
export const CREATE_ORDERS_SUCCESS_ACTION =
  CREATE_ORDERS_INIT_ACTION + "_SUCCESS";
export const CREATE_ORDERS_FAIL_ACTION = CREATE_ORDERS_INIT_ACTION + "_ERROR";

export const FETCH_ORDERBYID_INIT_ACTION = "FETCH_ORDERBYID_INIT_ACTION";
export const FETCH_ORDERBYID_SUCCESS_ACTION =
  FETCH_ORDERBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_ORDERBYID_FAIL_ACTION =
  FETCH_ORDERBYID_INIT_ACTION + "_ERROR";

export const EDIT_ORDER_INIT_ACTION = "EDIT_ORDER_INIT_ACTION";
export const EDIT_ORDER_SUCCESS_ACTION = EDIT_ORDER_INIT_ACTION + "_SUCCESS";
export const EDIT_ORDER_FAIL_ACTION = EDIT_ORDER_INIT_ACTION + "_ERROR";

export const FETCH_PAYMENTMODE_INIT_ACTION = "FETCH_PAYMENTMODE_INIT_ACTION";
export const FETCH_PAYMENTMODE_SUCCESS_ACTION =
  FETCH_PAYMENTMODE_INIT_ACTION + "_SUCCESS";
export const FETCH_PAYMENTMODE_FAIL_ACTION =
  FETCH_PAYMENTMODE_INIT_ACTION + "_ERROR";

export const CHANGE_ORDERSTATUS_INIT_ACTION = "CHANGE_ORDERSTATUS_INIT_ACTION";
export const CHANGE_ORDERSTATUS_SUCCESS_ACTION =
  CHANGE_ORDERSTATUS_INIT_ACTION + "_SUCCESS";
export const CHANGE_ORDERSTATUS_FAIL_ACTION =
  CHANGE_ORDERSTATUS_INIT_ACTION + "_ERROR";

export const CHANGE_ORDERAPPROVE_INIT_ACTION =
  "CHANGE_ORDERAPPROVE_INIT_ACTION";
export const CHANGE_ORDERAPPROVE_SUCCESS_ACTION =
  CHANGE_ORDERAPPROVE_INIT_ACTION + "_SUCCESS";
export const CHANGE_ORDERAPPROVE_FAIL_ACTION =
  CHANGE_ORDERAPPROVE_INIT_ACTION + "_ERROR";

// export const FILTER_SEARCH_ORDER_INIT_ACTION = "FILTER_SEARCH_ORDER_INIT_ACTION";
// export const FILTER_SEARCH_ORDER_SUCCESS_ACTION =FILTER_SEARCH_ORDER_INIT_ACTION + "_SUCCESS";
// export const FILTER_SEARCH_ORDER_FAIL_ACTION = FILTER_SEARCH_ORDER_INIT_ACTION + "_ERROR";

export const EXPORT_ORDER_INIT_ACTION = "EXPORT_ORDER_INIT_ACTION";
export const EXPORT_ORDER_SUCCESS_ACTION =
  EXPORT_ORDER_INIT_ACTION + "_SUCCESS";
export const EXPORT_ORDER_FAIL_ACTION = EXPORT_ORDER_INIT_ACTION + "_ERROR";
// export const LOADING_INIT_ACTION="LOADING_INIT_ACTION"
export const PAYAMOUNT_ORDER_INIT_ACTION = "PAYAMOUNT_ORDER_INIT_ACTION";
export const PAYAMOUNT_ORDER_SUCCESS_ACTION =
  PAYAMOUNT_ORDER_INIT_ACTION + "_SUCCESS";
export const PAYAMOUNT_ORDER_FAIL_ACTION =
  PAYAMOUNT_ORDER_INIT_ACTION + "_ERROR";

export const FETCH_TOTAL_AMOUNT_BY_ORDER_LICENSEE_INIT_ACTION =
  "FETCH_TOTAL_AMOUNT_BY_ORDER_LICENSEE_INIT_ACTION";
export const FETCH_TOTAL_AMOUNT_BY_ORDER_LICENSEE_SUCCESS_ACTION =
  FETCH_TOTAL_AMOUNT_BY_ORDER_LICENSEE_INIT_ACTION + "_SUCCESS";
export const FETCH_TOTAL_AMOUNT_BY_ORDER_LICENSEE_FAIL_ACTION =
  FETCH_TOTAL_AMOUNT_BY_ORDER_LICENSEE_INIT_ACTION + "_ERROR";

export function fetchOrders(value) {
  return {
    type: FETCH_ORDERS_INIT_ACTION,
    value: value,
  };
}

export function createOrder(value) {
  return {
    type: CREATE_ORDERS_INIT_ACTION,
    value: value,
  };
}

// export function orderSlectedPage(value) {
//   return {
//     type: FETCH_ORDERS_INIT_ACTION,
//     value:value
//   };
// }

export function getOrderById(value) {
  return {
    type: FETCH_ORDERBYID_INIT_ACTION,
    value: value,
  };
}

export function EditOrder(value) {
  return {
    type: EDIT_ORDER_INIT_ACTION,
    value: value,
  };
}

export function fetchPaymentMode() {
  return {
    type: FETCH_PAYMENTMODE_INIT_ACTION,
  };
}

export function changeOrderStatus(value) {
  return {
    type: CHANGE_ORDERSTATUS_INIT_ACTION,
    value: value,
  };
}

export function changeOrderApprove(value) {
  return {
    type: CHANGE_ORDERAPPROVE_INIT_ACTION,
    value: value,
  };
}

// export function filterSearchOrder(value) {
//   return {
//     type: FILTER_SEARCH_ORDER_INIT_ACTION,
//     value: value
//   };
// }

export function exprtFullData(value) {
  return {
    type: EXPORT_ORDER_INIT_ACTION,
    value: value,
  };
}
export function OrderPayAmount(value) {
  return {
    type: PAYAMOUNT_ORDER_INIT_ACTION,
    value: value,
  };
}

// export function loadinFun() {

//   return {
//     type: LOADING_INIT_ACTION,
//   };
// }

export function fetchTotalAmntByOrderLic(value) {
  return {
    type: FETCH_TOTAL_AMOUNT_BY_ORDER_LICENSEE_INIT_ACTION,
    value: value,
  };
}
