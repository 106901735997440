import { put, takeEvery } from "redux-saga/effects";
// import { createBrowserHistory } from "history";
import {
  RESETORIGINALPWD_INIT_ACTION,
  RESETORIGINALPWD_FAIL_ACTION,
  RESETORIGINALPWD_SUCCESS_ACTION,
} from "./action";
import { appConfig } from "../../config";
import { toast, Flip } from "react-toastify";
import { push } from "connected-react-router";
import "react-toastify/dist/ReactToastify.css";
var btoa = require("Base64").btoa;

function* resetOriginalPwd(value) {
  const id = JSON.parse(localStorage.getItem("sStraitUserDtls")).id;
  const mobno = JSON.parse(localStorage.getItem("sStraitUserDtls")).mobileNo;
  try {
    const data = { password: value.value.newpwd };
    const res = yield fetch(`${appConfig.ip}/users/${id}/password`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa(mobno + ":" + value.value.oldpwd),
        
      },
      body: JSON.stringify(data),
    });
  

    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();
      yield put({
        type: RESETORIGINALPWD_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success(
        "Password reset succesfully. Please login again to continue",
        {
          autoClose: 5000,
          transition: Flip,
        }
      );
      yield put(push("/dashboard"));
    }
  } catch (err) {
    if (err.status === 401 || err.status === 404 || err.status === 400) {
      yield toast.error("Sorry!! Try again with the registered password", {
        autoClose: 3000,
        transition: Flip,
      });
    } else {
      yield toast.error("Sorry!! Password not reset", {
        autoClose: 3000,
        transition: Flip,
      });
    }
    yield put({
      type: RESETORIGINALPWD_FAIL_ACTION,
      payload: err,
    });
  }
}

export function* ResetOldPwdActionWatcher() {
  yield takeEvery(RESETORIGINALPWD_INIT_ACTION, resetOriginalPwd);
}
