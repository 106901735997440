import {
  FETCH_CUSTOMERS_SUCCESS_ACTION,
  EDIT_CUSTOMERS_SUCCESS_ACTION,
  FETCH_CUSTOMERBYID_INIT_ACTION,
  FETCH_CUSTOMERBYID_SUCCESS_ACTION,
  FETCH_REGIONDROPDOWN_SUCCESS_ACTION,
  FETCH_AREADROPDOWN_SUCCESS_ACTION,
  CLEAR_AREADROPDOWN_INIT_ACTION,
  FETCH_PRODUCTS_BY_CUSTOMER_SUCCESS_ACTION,
  FETCH_CUSTOMER_DISCOUNT_SUCCESS_ACTION,
  CHECK_PRODUCTS_BY_CUSTOMER_DISCOUNT_SUCCESS_ACTION,
  TRIGGER_CUSTOMER_DISCOUNT_MODAL_SUCCESS_ACTION,
  FETCH_CUSTOMER_ORDER_SUCCESS_ACTION,
  FETCH_TOTAL_REMAINING_AMT_SUCCESS_ACTION,
  FETCH_CUSTOMER_CREDIT_NOTES_SUCCESS_ACTION,
  FETCH_CREDIT_NOTE_BY_ID_SUCCESS_ACTION,
  FETCH_CREDIT_NOTE_CONFIG_SUCCESS_ACTION,
  CLOSE_CUSTADD_MODEL_ACTION,
  FETCH_ROUTES_BY_CUSTOMER_SUCCESS_ACTION,
} from "./action";

const initialState = {
  processing: true,
  error: false,
  custLoading: true,
  modalApiStatus: "normal",
  triggerRemainingTotalAmt: false,
  closeModalTrigger: false,
};

export default function CustomersReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    case FETCH_CUSTOMERS_SUCCESS_ACTION:
      return {
        ...state,
        customersList: action.payload.rows,
        customersDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };
    case EDIT_CUSTOMERS_SUCCESS_ACTION:
      return {
        ...state,
        customersList: state.customersList.map((customer) =>
          customer.id === action.payload.id ? action.payload : customer
        ),
      };
    case FETCH_CUSTOMERBYID_INIT_ACTION:
      return {
        ...state,
        processing: true,
        error: false,
        custLoading: true,
      };
    case FETCH_CUSTOMERBYID_SUCCESS_ACTION:
      return {
        ...state,
        customerDtls: action.payload,
        custLoading: false,
      };
    case FETCH_REGIONDROPDOWN_SUCCESS_ACTION:
      return {
        ...state,
        regionDropdownList: action.payload.rows,
      };
    case FETCH_AREADROPDOWN_SUCCESS_ACTION:
      return {
        ...state,
        areaDropdownList: action.payload.rows,
      };
    case FETCH_ROUTES_BY_CUSTOMER_SUCCESS_ACTION:
      return {
        ...state,
        routeList: action.payload,
      };
    case CLEAR_AREADROPDOWN_INIT_ACTION:
      return {
        ...state,
        areaDropdownList: [],
      };
    case FETCH_PRODUCTS_BY_CUSTOMER_SUCCESS_ACTION:
      return {
        ...state,
        productsList: action.payload.rows,
      };
    case FETCH_CUSTOMER_DISCOUNT_SUCCESS_ACTION:
      return {
        ...state,
        customerDiscountList: action.payload.rows,
        customerDiscountCount: action.payload.count,
      };

    case FETCH_CUSTOMER_ORDER_SUCCESS_ACTION:
      return {
        ...state,
        customerOrderList: action.payload.rows,
        customerOrderCount: action.payload.count,
      };

    case "UPDATE_LEDGER_TABLE":
      return {
        ...state,

        customerOrderList: state.customerOrderList.map((order) =>
          order.id === action.payload.id
            ? { ...order, status: action.payload.status }
            : order
        ),
      };

    case "UPDATE_LEDGER_TABLE_BY_PAYMENT":
      return {
        ...state,
        customerOrderList: state.customerOrderList.map((order) =>
          order.id === action.payload.id ? action.payload : order
        ),
        triggerRemainingTotalAmt: action.isFromLedger || false,
      };

    case CHECK_PRODUCTS_BY_CUSTOMER_DISCOUNT_SUCCESS_ACTION:
      return {
        ...state,
        customerDiscountListByProduct: action.payload.rows,
        modalApiStatus: action.payload.rows.length ? "open" : "new",
      };
    case TRIGGER_CUSTOMER_DISCOUNT_MODAL_SUCCESS_ACTION:
      return {
        ...state,
        modalApiStatus: action.status,
      };

    case FETCH_TOTAL_REMAINING_AMT_SUCCESS_ACTION:
      return {
        ...state,
        totalRemainingAmt: action.payload.total,
        triggerRemainingTotalAmt: false,
      };

    case FETCH_CUSTOMER_CREDIT_NOTES_SUCCESS_ACTION:
      return {
        ...state,
        creditNotesList: action.payload.rows,
        creditNotesCount: action.payload.count,
        processing: false,
        error: false,
      };

    case FETCH_CREDIT_NOTE_BY_ID_SUCCESS_ACTION:
      return {
        ...state,
        creditNoteByIdList: action.payload,
        invoiceId: action.invoiceId,
        resultImage: action.resultImage.base64,
      };
    case FETCH_CREDIT_NOTE_CONFIG_SUCCESS_ACTION:
      return {
        ...state,
        creditnoteConfigDtls: action.creditNoteConfigData.rows,
        processing: false,
        error: false,
        loading: false,
      };

    case CLOSE_CUSTADD_MODEL_ACTION:
      return {
        ...state,
        closeModalTrigger: !state.closeModalTrigger,
      };

    default:
      return {
        ...state,
      };
  }
}
