export const FETCH_QUOTE_CONFIGURATION_INIT_ACTION =
  "FETCH_QUOTE_CONFIGURATION_INIT_ACTION";
export const FETCH_QUOTE_CONFIGURATION_SUCCESS_ACTION =
  FETCH_QUOTE_CONFIGURATION_INIT_ACTION + "_SUCCESS";
export const FETCH_QUOTE_CONFIGURATION_FAIL_ACTION =
  FETCH_QUOTE_CONFIGURATION_INIT_ACTION + "_ERROR";

export const ADD_QUOTE_CONFIGURATION_INIT_ACTION =
  "ADD_QUOTE_CONFIGURATION_INIT_ACTION";
export const ADD_QUOTE_CONFIGURATION_SUCCESS_ACTION =
  ADD_QUOTE_CONFIGURATION_INIT_ACTION + "_SUCCESS";
export const ADD_QUOTE_CONFIGURATION_FAIL_ACTION =
  ADD_QUOTE_CONFIGURATION_INIT_ACTION + "_ERROR";

export const DELETE_QUOTE_CONFIGURATION_INIT_ACTION =
  "DELETE_QUOTE_CONFIGURATION_INIT_ACTION";
export const DELETE_QUOTE_CONFIGURATION_SUCCESS_ACTION =
  DELETE_QUOTE_CONFIGURATION_INIT_ACTION + "_SUCCESS";
export const DELETE_QUOTE_CONFIGURATION_FAIL_ACTION =
  DELETE_QUOTE_CONFIGURATION_INIT_ACTION + "_ERROR";

export const EDIT_QUOTE_CONFIGURATION_INIT_ACTION =
  "EDIT_QUOTE_CONFIGURATION_INIT_ACTION";
export const EDIT_QUOTE_CONFIGURATION_SUCCESS_ACTION =
  EDIT_QUOTE_CONFIGURATION_INIT_ACTION + "_SUCCESS";
export const EDIT_QUOTE_CONFIGURATION_FAIL_ACTION =
  EDIT_QUOTE_CONFIGURATION_INIT_ACTION + "_ERROR";

