import React, { useState, useEffect } from "react";
import "./login.css";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Container, Row, Col, Card, Image, Button } from "react-bootstrap";
import { TextField } from "../Common/TextField";
import Navbar from "../Common/Navbar/Navbar";

import * as Yup from "yup";
import { Formik, Form } from "formik";
let regEx = /^\S/;
function Login(props) {
  const validate = Yup.object({
    username: Yup.string()
      .matches(regEx, "Email or phone number is not valid")
      .required("Email or phone number is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be atleast 6 characters long"),
  });

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
      }}
      validationSchema={validate}
      onSubmit={(values) => {
        props.logins(values);
      }}
    >
      {(formik) => (
        <div>
          {/* <Navbar></Navbar> */}

          <div className="background">
            <div className="bgcolorLogin container-fluid">
              <div>
                <div>
                  <Container>
                    <div className="cardMainlogin">
                      <Row>
                        <Col xs={12} md={6} sm={12} lg={6}>
                          <div>
                            <Image
                              alt="loginimage"
                              className="loginimage"
                              style={{ height: "100%", width: "100%" }}
                              src={require("../../assets/img/LoginImage.png")}
                            />
                          </div>
                        </Col>
                        <Col xs={12} md={1} sm={12} lg={1}></Col>
                        <Col xs={12} md={4} sm={12} lg={4}>
                          <div className="loginheaderfrst">
                            <div className="logintitle">
                              <div className="logoNav">
                                <img
                                  alt=""
                                  className="loginheader "
                                  src={require("../../assets/img/Logo-full-bg.png")}
                                />

                                {/* </Link> */}
                              </div>
                              {/* <h3 className="loginheader "><span style={{fontWeight:"normal"}}>sale</span><span style={{fontWeight:"bolder"}}>Strait</span></h3> */}
                            </div>

                            <div className="loginform">
                              <Form>
                                <div>
                                  <TextField
                                    placeholder="Email or Phone number"
                                    name="username"
                                    type="text"
                                  />
                                </div>

                                <div className="pswd-container-lgn">
                                  <TextField
                                    placeholder="Password"
                                    name="password"
                                    type={passwordType}
                                  />
                                  <span className="pswd-lgn">
                                    {passwordType === "password" ? (
                                      <i
                                        class="fa fa-eye-slash pswd-lgn-icon"
                                        aria-hidden="true"
                                        onClick={togglePassword}
                                      ></i>
                                    ) : (
                                      <i
                                        class="fa fa-eye pswd-lgn-icon"
                                        aria-hidden="true"
                                        onClick={togglePassword}
                                      ></i>
                                    )}
                                  </span>
                                </div>

                                <button
                                  className="contactpagebtn"
                                  type="submit"
                                >
                                  Login
                                </button>

                                <div className="signup-div">
                                  Don't have an account?{" "}
                                  <span className="loginaccounts">
                                    <Link
                                      to="/signup"
                                      className="loginaccounts"
                                    >
                                      Create an account
                                    </Link>
                                  </span>
                                </div>
                                <div className="forgotPswddiv">
                                  <br></br>
                                  <Link
                                    to="/forgotpassword"
                                    className="forgotPswd"
                                  >
                                    Forgot password?
                                  </Link>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default Login;
