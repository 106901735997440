export const ADD_DISPRODUCTS_INIT_ACTION = "ADD_DISPRODUCTS_INIT_ACTION";
export const ADD_DISPRODUCTS_SUCCESS_ACTION =
  ADD_DISPRODUCTS_INIT_ACTION + "_SUCCESS";
export const ADD_DISPRODUCTS_FAIL_ACTION =
  ADD_DISPRODUCTS_INIT_ACTION + "_ERROR";

export const EDIT_DISPRODUCTS_INIT_ACTION = "EDIT_DISPRODUCTS_INIT_ACTION";

export function AddDisProduct(value) {
  return {
    type: ADD_DISPRODUCTS_INIT_ACTION,
    value: value,
  };
}

export function EditDisProduct(value) {
  return {
    type: EDIT_DISPRODUCTS_INIT_ACTION,
    value: value,
  };
}
