import {
  FETCH_WDREQUEST_INIT_ACTION,
  FETCH_WDREQUEST_SUCCESS_ACTION,
  FETCH_WDREQUESTBYID_INIT_ACTION,
  FETCH_WDREQUESTBYID_SUCCESS_ACTION,
  FETCH_WDREQUESTBYID_FAIL_ACTION,
  EDIT_WDREQUEST_SUCCESS_ACTION,
  FETCH_TOTALEARNINGS_SUCCESS_ACTION,
  FETCH_CREATEDEARNINGS_INIT_ACTION,
  FETCH_CREATEDEARNINGS_SUCCESS_ACTION,
  FETCH_TOTALEARNINGS_INIT_ACTION,
  FETCH_WDLIMIT_SUCCESS_ACTION,
} from "./action";

const initialState = {
  processing: true,
  error: false,
  loading: true,
};

export default function WdRequestReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    case FETCH_WDREQUEST_INIT_ACTION:
      return {
        ...state,
        processing: true,
        error: false,
      };
    case FETCH_WDREQUEST_SUCCESS_ACTION:
      return {
        ...state,
        wdRequestList: action.payload.rows,
        wdRequestCount: action.payload.count,
        processing: false,
        error: false,
      };

    case FETCH_WDLIMIT_SUCCESS_ACTION:
      return {
        ...state,
        wdLimitList: action.payload.rows,
        wdLimitCount: action.payload.count,
        processing: false,
        error: false,
      };

    case FETCH_CREATEDEARNINGS_INIT_ACTION:
      return {
        ...state,
        createdEarningsList: "",
        createdEarningsCount: "",
      };
    case FETCH_CREATEDEARNINGS_SUCCESS_ACTION:
      return {
        ...state,
        createdEarningsList: action.payload.rows,
        createdEarningsCount: action.payload.count,
        processing: false,
        error: false,
      };

    case FETCH_WDREQUESTBYID_INIT_ACTION:
      return {
        ...state,
        processing: true,
        error: false,
        loading: true,
      };
    case FETCH_WDREQUESTBYID_SUCCESS_ACTION:
      return {
        ...state,
        wdRequestDtls: action.payload,
        processing: false,
        error: false,
        loading: false,
      };
    case EDIT_WDREQUEST_SUCCESS_ACTION:
      return {
        ...state,
        wdRequestList: state.wdRequestList.map((x) =>
          x.id === action.payload.id ? action.payload : x
        ),
      };
    case FETCH_TOTALEARNINGS_INIT_ACTION:
      return {
        ...state,
        totalEarnings: "",
        processing: false,
        error: false,
      };

    case FETCH_TOTALEARNINGS_SUCCESS_ACTION:
      return {
        ...state,
        totalEarnings: action.payload,
        processing: false,
        error: false,
      };

    default:
      return {
        ...state,
      };
  }
}
