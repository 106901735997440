export const FETCH_USERS_INIT_ACTION = "FETCH_USERS_INIT_ACTION";
export const FETCH_USERS_SUCCESS_ACTION =
  FETCH_USERS_INIT_ACTION + "_SUCCESS";
export const FETCH_USERS_FAIL_ACTION = FETCH_USERS_INIT_ACTION + "_ERROR";

export const FETCH_COUNTRY_INIT_ACTION = "FETCH_COUNTRY_INIT_ACTION";
export const FETCH_COUNTRY_SUCCESS_ACTION =
  FETCH_COUNTRY_INIT_ACTION + "_SUCCESS";
export const FETCH_COUNTRY_FAIL_ACTION = FETCH_COUNTRY_INIT_ACTION + "_ERROR";

export const ADD_USERS_INIT_ACTION = "ADD_USERS_INIT_ACTION";
export const ADD_USERS_SUCCESS_ACTION =
  ADD_USERS_INIT_ACTION + "_SUCCESS";
export const ADD_USERS_FAIL_ACTION = ADD_USERS_INIT_ACTION + "_ERROR";

export const EDIT_USERS_INIT_ACTION = "EDIT_USERS_INIT_ACTION";
export const EDIT_USERS_SUCCESS_ACTION =
  EDIT_USERS_INIT_ACTION + "_SUCCESS";
export const EDIT_USERS_FAIL_ACTION = EDIT_USERS_INIT_ACTION + "_ERROR";


export const FETCH_USERS_REGION_INIT_ACTION = "FETCH_USERS_REGION_INIT_ACTION";
export const FETCH_USERS_REGION_SUCCESS_ACTION =
  FETCH_USERS_REGION_INIT_ACTION + "_SUCCESS";
export const FETCH_USERS_REGION_FAIL_ACTION = FETCH_USERS_REGION_INIT_ACTION + "_ERROR";


export const FETCH_USERS_ZONE_INIT_ACTION = "FETCH_USERS_ZONE_INIT_ACTION";
export const FETCH_USERS_ZONE_SUCCESS_ACTION =
  FETCH_USERS_ZONE_INIT_ACTION + "_SUCCESS";
export const FETCH_USERS_ZONE_FAIL_ACTION = FETCH_USERS_ZONE_INIT_ACTION + "_ERROR";


export const FETCH_USERS_AREA_INIT_ACTION = "FETCH_USERS_AREA_INIT_ACTION";
export const FETCH_USERS_AREA_SUCCESS_ACTION =
  FETCH_USERS_AREA_INIT_ACTION + "_SUCCESS";
export const FETCH_USERS_AREA_FAIL_ACTION = FETCH_USERS_AREA_INIT_ACTION + "_ERROR";

export const CHANGE_USER_STATUS_INIT_ACTION = "CHANGE_USER_STATUS_INIT_ACTION";
export const CHANGE_USER_STATUS_SUCCESS_ACTION = CHANGE_USER_STATUS_INIT_ACTION + "_SUCCESS";
export const CHANGE_USER_STATUS_FAIL_ACTION = CHANGE_USER_STATUS_INIT_ACTION + "_ERROR";

export const DELETE_USER_INIT_ACTION = "DELETE_USER_INIT_ACTION";
export const DELETE_USER_SUCCESS_ACTION = DELETE_USER_INIT_ACTION + "_SUCCESS";
export const DELETE_USER_FAIL_ACTION = DELETE_USER_INIT_ACTION + "_ERROR";

export const EXPORT_USER_INIT_ACTION = "EXPORT_USER_INIT_ACTION";
export const EXPORT_USER_SUCCESS_ACTION =EXPORT_USER_INIT_ACTION + "_SUCCESS";
export const EXPORT_USER_FAIL_ACTION = EXPORT_USER_INIT_ACTION + "_ERROR";

export const RESET_INIT_ACTION = "RESET_INIT_ACTION";
export const RESET_SUCCESS_ACTION =RESET_INIT_ACTION + "_SUCCESS";
export const RESET_FAIL_ACTION = RESET_INIT_ACTION + "_ERROR";

// export const FILTER_SEARCH_USER_INIT_ACTION = "FILTER_SEARCH_USER_INIT_ACTION";
// export const FILTER_SEARCH_USER_SUCCESS_ACTION =
//   FILTER_SEARCH_USER_INIT_ACTION + "_SUCCESS";
// export const FILTER_SEARCH_USER_FAIL_ACTION = FILTER_SEARCH_USER_INIT_ACTION + "_ERROR";


export function fetchUsers(value) {
  return {
    type: FETCH_USERS_INIT_ACTION,
    value:value
  };
}
export function fetchCountry() {
  return {
    type: FETCH_COUNTRY_INIT_ACTION,
  };
}
export function AddUser(value) {
  return {
    type: ADD_USERS_INIT_ACTION,
    value: value
  };
}

export function EditUser(value) {
  return {
    type: EDIT_USERS_INIT_ACTION,
    value: value
  };
}
// export function userSlectedPage(value) {
//   return {
//     type: FETCH_USERS_INIT_ACTION,
//     value: value
//   };
// }


export function fetchUsersRegion(value) {
  return {
    type: FETCH_USERS_REGION_INIT_ACTION,
    value: value
  };
}


export function fetchUsersZone(value) {
  return {
    type: FETCH_USERS_ZONE_INIT_ACTION,
    value: value
  };
}


export function fetchUsersArea(value) {
  return {
    type: FETCH_USERS_AREA_INIT_ACTION,
    value: value
  };
}

export function changeUserStatus(value) {
  return {
    type: CHANGE_USER_STATUS_INIT_ACTION,
    value: value
  };
}

export function DeleteValue(value) {
  return {
    type: DELETE_USER_INIT_ACTION,
    value: value
  };
}

export function exprtFullData(value) {
  return {
    type: EXPORT_USER_INIT_ACTION,
    value: value
  };
}

export function reset(value) {
  return {
    type: RESET_INIT_ACTION,
    value: value
  };
}

// export function filterSearchUser(value) {
//   return {
//     type: FILTER_SEARCH_USER_INIT_ACTION,
//     value: value
//   };
// }

