import {
  FETCH_STATELIST_SUCCESS_ACTION,
  FETCH_STATELIST_INIT_ACTION,
  FETCH_REGION_SUCCESS_ACTION,
  FETCH_REGION_INIT_ACTION,
  FETCH_REGION_CLEAR_ACTION,
  FETCH_REGION_DETAIL_INIT_ACTION,
  FETCH_REGION_DETAIL_SUCCESS_ACTION,
  DELETE_REGION_SUCCESS_ACTION,
  EDIT_REGION_SUCCESS_ACTION,
  // SEARCH_REGION_SUCCESS_ACTION
} from "./action";

const initialState = {
  processing: true,
  error: false,
};

export default function RegionReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    case FETCH_STATELIST_INIT_ACTION:
      return {
        ...state,
        processing: true,
        error: false,
      };
    case FETCH_STATELIST_SUCCESS_ACTION:
      return {
        ...state,
        stateList: action.payload.rows,
        stateDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };
    case FETCH_REGION_CLEAR_ACTION:
      return {
        ...state,
        regionList: [],
        regionDtlsCount: "",
      };
    case FETCH_REGION_SUCCESS_ACTION:
      return {
        ...state,
        regionList: state.regionList
          ? state.regionList.concat(action.payload.rows)
          : action.payload.rows,
        // action.payload.rows,
        regionDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };
    // case SEARCH_REGION_SUCCESS_ACTION:
    //   return {
    //     ...state,
    //     regionList: action.payload.rows,
    //     regionDtlsCount: action.payload.count,
    //     processing: false,
    //     error: false,
    //   };
    case FETCH_REGION_DETAIL_INIT_ACTION:
      return {
        ...state,
        processing: true,
        loading: true,
        error: false,
        region: "",
      };

    case FETCH_REGION_DETAIL_SUCCESS_ACTION:
      return {
        ...state,
        processing: false,
        loading: false,
        error: false,
        region: action.payload,
      };
    case DELETE_REGION_SUCCESS_ACTION:
      return {
        ...state,
        regionList: state.regionList.filter(
          (item) => item.id !== action.payload
        ),
        regionDtlsCount: state.regionDtlsCount - 1,
      };
    case EDIT_REGION_SUCCESS_ACTION:
      return {
        ...state,
        regionList: state.regionList.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };

    default:
      return {
        ...state,
      };
  }
}
