export const IMPORT_DIS_PRODUCT_INIT_ACTION = "IMPORT_DIS_PRODUCT_INIT_ACTION";
export const IMPORT_DIS_PRODUCT_SUCCESS_ACTION =
  IMPORT_DIS_PRODUCT_INIT_ACTION + "_SUCCESS";
export const IMPORT_DIS_PRODUCT_FAIL_ACTION =
  IMPORT_DIS_PRODUCT_INIT_ACTION + "_ERROR";

export const FETCH_DIS_SUBCATEGORYLIST_INIT_ACTION =
  "FETCH_DIS_SUBCATEGORYLIST_INIT_ACTION";
export const FETCH_DIS_SUBCATEGORYLIST_SUCCESS_ACTION =
  FETCH_DIS_SUBCATEGORYLIST_INIT_ACTION + "_SUCCESS";
export const FETCH_DIS_SUBCATEGORYLIST_FAIL_ACTION =
  FETCH_DIS_SUBCATEGORYLIST_INIT_ACTION + "_ERROR";

export const APPROVE_DIS_PRODUCT_INIT_ACTION =
  "APPROVE_DIS_PRODUCT_INIT_ACTION";
export const APPROVE_DIS_PRODUCT_SUCCESS_ACTION =
  APPROVE_DIS_PRODUCT_INIT_ACTION + "_SUCCESS";
export const APPROVE_DIS_PRODUCT_FAIL_ACTION =
  APPROVE_DIS_PRODUCT_INIT_ACTION + "_ERROR";

export const APPROVE_NO_HIERARCHY_PRODUCT_INIT_ACTION =
  "APPROVE_NO_HIERARCHY_PRODUCT_INIT_ACTION";
export const APPROVE_NO_HIERARCHY_PRODUCT_SUCCESS_ACTION =
  APPROVE_NO_HIERARCHY_PRODUCT_INIT_ACTION + "_SUCCESS";
export const APPROVE_NO_HIERARCHY_PRODUCT_FAIL_ACTION =
  APPROVE_NO_HIERARCHY_PRODUCT_INIT_ACTION + "_ERROR";

export function importData(value) {
  return {
    type: IMPORT_DIS_PRODUCT_INIT_ACTION,
    value: value,
  };
}

export function fetchSubctgryList(value) {
  return {
    type: FETCH_DIS_SUBCATEGORYLIST_INIT_ACTION,
    value: value,
  };
}

export function ApproveDisProduct(value) {
  return {
    type: APPROVE_DIS_PRODUCT_INIT_ACTION,
    value: value,
  };
}

export function approveNoHierarchyProduct(value) {
  return {
    type: APPROVE_NO_HIERARCHY_PRODUCT_INIT_ACTION,
    value: value,
  };
}
