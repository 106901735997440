import React from "react";
import { Row, Col, Modal, Container, Button } from "react-bootstrap";
import "../Common/cmnStyle.css";
import * as Yup from "yup";
import { Formik, Form, FieldArray, Field } from "formik";
import MySelect from "../Common/CusSelect";
import "../Orders/order.css";

function Approve(props) {
  const platfrmCmmsn = props.LicenseeprofDtls.commsnPrcntg
    ? props.LicenseeprofDtls.commsnPrcntg
    : 0;

  let itemData = props.ItemList && props.ItemList ? props.ItemList : null;

  // const itemData =
  //     props.ItemList && props.ItemList.d
  //         ? props.ItemList && props.ItemList.d
  //         : null;
  return (
    <div>
      <Formik
        initialValues={{
          // id: itemData ? itemData.id : "",
          name: itemData ? itemData.name : "",
          agency:
            itemData && itemData.agency && itemData.agency.length
              ? itemData.agency[0].id
              : "",
          buyingPrice: itemData ? itemData.buyingPrice : "",
          sellingPrice: itemData ? itemData.sellingPrice : "",
          quantity: itemData ? itemData.quantity : "",
          ctgryId:
            itemData && itemData.ctgryId && itemData.ctgryId.length
              ? itemData.ctgryId[0].id
              : "",
          subcategory:
            itemData && itemData.subCtgryId && itemData.subCtgryId.length
              ? itemData.subCtgryId[0].id
              : "",
          PrdtType:
            itemData && itemData.PrdtType && itemData.PrdtType.length
              ? itemData.PrdtType[0].id
              : "",
          regionManger: itemData ? itemData.regionManger : "",
          zoneManger: itemData ? itemData.zoneManger : "",
          areaManger: itemData ? itemData.areaManger : "",
          salesExecutive: itemData ? itemData.salesExecutive : "",
          company: itemData ? itemData.company : "",
          incentivesbucket: itemData ? itemData.incentivesbucket : "",
          unit:
            itemData && itemData.unit && itemData.unit.length
              ? itemData.unit[0].id
              : "",
          gstSlab:
            itemData && itemData.gstSlab && itemData.gstSlab.length
              ? itemData.gstSlab[0].id
              : "",
          backOrder: itemData ? itemData.backOrder : false,
          platform: platfrmCmmsn,
        }}
        // validationSchema={validate}

        onSubmit={(values) => {
          props.ApproveProduct(values);
          props.closeApproveModel();
        }}
      >
        {(formik) => (
          <div>
            <Modal
              show={props.showAprvModel}
              animation={false}
              size="md"
              backdrop="static"
              className="detail-ad-Modal"
              backdrop="static"
              onHide={props.closeApproveModel}
            >
              <Modal.Header className="mastr-mdl-hd" closeButton>
                <Modal.Title>
                  <span className="modl-titl-align">Add Product</span>
                </Modal.Title>
              </Modal.Header>
              <Form>
                <Modal.Body>
                  {" "}
                  <div>
                    <h6 className="delete-msg">
                      <b>Are you sure you want to add this product?</b>
                    </h6>
                  </div>
                  <Row className="delete-btn-rw">
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Button
                        className="cmnDelCnclBtn"
                        variant="danger"
                        onClick={() => props.closeApproveModel()}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                      <button
                        className="btn btn-success cmnDelOkBtn"
                        type="submit"
                      >
                        Submit
                      </button>
                      {/* <button className="btn btn-success" className="cmnDelOkBtn" type="submit">Submit</button> */}
                    </Col>
                  </Row>
                </Modal.Body>
              </Form>
            </Modal>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default Approve;
