import {
  FETCH_INVENTORY_SUCCESS_ACTION,
  FETCH_INVENTORY_INIT_ACTION,
  // FETCH_PRODUCTS_INV_SUCCESS_ACTION,
  // EDIT_PRODUCTS_INV_SUCCESS_ACTION,
} from "./action";
import {
  UPDATE_PRODUCTINVENTORY_SUCCESS_ACTION,
  FETCH_INVENTORYLOG_SUCCESS_ACTION,
} from "../DistributorContainer/ProductInventoryContainer/action";
import { sortData } from "../../screens/Common/sortData";

const initialState = {
  processing: true,
  error: false,
  loading: true,
};

export default function productInventoryReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    case FETCH_INVENTORY_INIT_ACTION:
      return {
        ...state,
        processing: true,
        error: false,
      };
    case FETCH_INVENTORY_SUCCESS_ACTION:
      return {
        ...state,
        productInventoryList: action.payload.rows,
        productInventoryDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };
    // case FETCH_PRODUCTS_INV_SUCCESS_ACTION:
    //   return {
    //     ...state,
    //     productInvList: sortData(action.payload.rows),
    //   };
    // case EDIT_PRODUCTS_INV_SUCCESS_ACTION:
    //   return {
    //     ...state,
    //     productInventoryList: state.productInventoryList.map((product) =>
    //       product.id === action.payload.id ? action.payload : product
    //     ),
    //   };
    //Distributor inventory actions
    case UPDATE_PRODUCTINVENTORY_SUCCESS_ACTION:
      return {
        ...state,
        productInventoryList: state.productInventoryList.map((inv) =>
          inv.id === action.payload.id ? action.payload : inv
        ),
      };
    //Distributor inventory actions
    case FETCH_INVENTORYLOG_SUCCESS_ACTION:
      return {
        ...state,
        invLogList: action.payload.rows,
        invLogCount: action.payload.count,
        processing: false,
        error: false,
      };

    default:
      return {
        ...state,
      };
  }
}
