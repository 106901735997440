import { put, takeEvery, call, all } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_AGENCY_INIT_ACTION,
  FETCH_AGENCY_SUCCESS_ACTION,
  FETCH_AGENCY_FAIL_ACTION,
  ADD_AGENCY_INIT_ACTION,
  ADD_AGENCY_SUCCESS_ACTION,
  ADD_AGENCY_FAIL_ACTION,
  EDIT_AGENCY_INIT_ACTION,
  EDIT_AGENCY_SUCCESS_ACTION,
  EDIT_AGENCY_FAIL_ACTION,
  DELETE_AGENCY_INIT_ACTION,
  DELETE_AGENCY_SUCCESS_ACTION,
  DELETE_AGENCY_FAIL_ACTION,
  CHANGE_AGENCY_STATUS_INIT_ACTION,
  CHANGE_AGENCY_STATUS_SUCCESS_ACTION,
  CHANGE_AGENCY_STATUS_FAIL_ACTION,
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let userRole = localStorage.getItem("sStraitUserRole");

function* fetchAgency(value) {
  let pageno = value.value === undefined ? 1 : value.value.page;

  const token = localStorage.getItem("sStraitToken");
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  try {
    const res = yield fetch(
      `${appConfig.ip}/agency?status=active,suspended&limit=10&page=` +
        pageno +
        "&licenseeId=" +
        userDtls.licenseeId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_AGENCY_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_AGENCY_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* AddAgency(value) {
  const token = localStorage.getItem("sStraitToken");

  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  const data = {
    name: value.value.name,
    mobileNo: value.value.mobileNo,
    email: value.value.email,
    address: value.value.address,
    spocName: value.value.spocName,
    spocDsgntn: value.value.spocDsgntn,
    spocPhn: value.value.spocPhn,
    hqPhn: value.value.hqPhn,
    hqAddr: value.value.hqAddr,
    licenseeId: userDtls.licenseeId,
    status: "active",
  };

  try {
    const res = yield fetch(`${appConfig.ip}/agency`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
        if (errJSON.message === "Agency already registered") {
          yield toast.error(
            userRole === "distributor"
              ? "Supplier already exist"
              : "Agency already exist",
            {
              autoClose: 3000,
            }
          );
        }
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: ADD_AGENCY_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success(
        userRole === "distributor"
          ? "Supplier added successfully"
          : "Agency added successfully",
        {
          autoClose: 3000,
        }
      );
      yield put({
        type: FETCH_AGENCY_INIT_ACTION,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_AGENCY_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* EditAgency(value) {
  const token = localStorage.getItem("sStraitToken");
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const data = {
    name: value.value.name,
    mobileNo: value.value.mobileNo,
    email: value.value.email,
    address: value.value.address,
    spocName: value.value.spocName,
    spocDsgntn: value.value.spocDsgntn,
    spocPhn: value.value.spocPhn,
    hqPhn: value.value.hqPhn,
    hqAddr: value.value.hqAddr,
    licenseeId: userDtls.licenseeId,
  };
  try {
    const res = yield fetch(`${appConfig.ip}/agency/` + value.value.id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: EDIT_AGENCY_SUCCESS_ACTION,
        payload: resJSON,
      });

      yield toast.success(
        userRole === "distributor"
          ? "Supplier updated successfully"
          : "Agency updated successfully",
        {
          autoClose: 3000,
        }
      );
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: EDIT_AGENCY_FAIL_ACTION,
        error: err,
      });
    }
  }
}

// Change user status

function* changeStatus(value) {
  let datas;

  value.value.status === "created" || value.value.status === "suspended"
    ? (datas = { status: "active" })
    : (datas = { status: "suspended" });

  const id = value.value.id;
  const token = localStorage.getItem("sStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/agency/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(datas),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield toast.success("Status changed successfully", {
        autoClose: 3000,
      });
      yield put({
        type: CHANGE_AGENCY_STATUS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: CHANGE_AGENCY_STATUS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* DeleteValue(value) {
  const id = value.value.id;
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/agency/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      // const resJSON = yield res.json();
      yield toast.success("Successfully deleted", {
        autoClose: 3000,
      });
      yield put({
        type: DELETE_AGENCY_SUCCESS_ACTION,
        payload: id,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_AGENCY_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* AgencyActionWatcher() {
  yield takeEvery(FETCH_AGENCY_INIT_ACTION, fetchAgency);
  yield takeEvery(ADD_AGENCY_INIT_ACTION, AddAgency);
  yield takeEvery(EDIT_AGENCY_INIT_ACTION, EditAgency);
  yield takeEvery(DELETE_AGENCY_INIT_ACTION, DeleteValue);
  yield takeEvery(CHANGE_AGENCY_STATUS_INIT_ACTION, changeStatus);
}
