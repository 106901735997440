import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Container, Card } from "react-bootstrap";
import "../Common/cmnStyle.css";
import { Formik, Form } from "formik";
import SelectTest from "../Common/CusSelect";
import { TextField } from "../Common/TextField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";

function EditImport(props) {
  useEffect(() => {
    if (Array.isArray(props.ItemList && props.ItemList.ctgryId)) {
      props.fetchSubctgry(props.ItemList.ctgryId[0].id);
    }
  }, []);

  //Validation
  const validate = Yup.object({
    name: Yup.string().required("Name is required"),
    agency: Yup.array().required("Agency is required"),
    PrdtType: Yup.array().required("Type is required"),
    buyingPrice: Yup.number().required("Buying Price is required"),
    sellingPrice: Yup.number()
      .required("Selling Price is required")
      .when("buyingPrice", (buyingPrice) => {
        if (buyingPrice) {
          return Yup.number()
            .min(buyingPrice, "Must greater than Buying Price")
            .required("Selling Price is required");
        }
      }),
    quantity: Yup.number().required("Quantity is required"),
    ctgryId: Yup.array().required("Category is required"),
    subCtgryId: Yup.array().required("Subcategory is required"),
    gstSlab: Yup.array().required("GST is required"),
    unit: Yup.array().required("Unit is required"),

    regionManger: Yup.number().required("Commission is required"),
    zoneManger: Yup.number().required("Commission is required"),
    areaManger: Yup.number().required("Commission is required"),
    salesExecutive: Yup.number().required("Commission is required"),
    company: Yup.number().required("Commission is required"),
    incentivesbucket: Yup.number().required("Commission is required"),
  });

  const platfrmCmmsn = props.LicenseeprofDtls.commsnPrcntg
    ? props.LicenseeprofDtls.commsnPrcntg
    : 0;

  const commsnAmnt = (formik, value) => {
    return formik.values.sellPrice && formik.values.buyPrice
      ? "Amount: ₹" +
          ((formik.values.sellPrice - formik.values.buyPrice) * value) / 100
      : null;
  };

  return (
    <Formik
      initialValues={{
        RowId: props.ItemList ? props.ItemList.RowId : "",
        // id: props.ItemList ? props.ItemList.id : "",

        name: props.ItemList ? props.ItemList.name : "",
        // agency: props.ItemList ? props.ItemList.agency.id : "",
        agency: Array.isArray(props.ItemList && props.ItemList.agency)
          ? props.ItemList && props.ItemList.agency
            ? props.ItemList.agency.map((item, i) => {
                return { ...item, value: item.id, label: item.name };
              })
            : []
          : [],
        buyingPrice: props.ItemList ? props.ItemList.buyingPrice : "",
        sellingPrice: props.ItemList ? props.ItemList.sellingPrice : "",
        quantity: props.ItemList ? props.ItemList.quantity : "",
        // category: props.ItemList ? props.ItemList.ctgryId.id : "",
        ctgryId: Array.isArray(props.ItemList && props.ItemList.ctgryId)
          ? props.ItemList && props.ItemList.ctgryId
            ? props.ItemList.ctgryId.map((item, i) => {
                return { ...item, value: item.id, label: item.name };
              })
            : ""
          : "",
        // subcategory: props.ItemList ? props.ItemList.subCtgryId.id : "",
        subCtgryId: Array.isArray(props.ItemList && props.ItemList.subCtgryId)
          ? props.ItemList && props.ItemList.subCtgryId
            ? props.ItemList.subCtgryId.map((item, i) => {
                return { ...item, value: item.id, label: item.name };
              })
            : ""
          : "",
        // PrdtType: props.ItemList ? props.ItemList.PrdtType.id : "",
        PrdtType: Array.isArray(props.ItemList && props.ItemList.PrdtType)
          ? props.ItemList && props.ItemList.PrdtType
            ? props.ItemList.PrdtType.map((item, i) => {
                return { ...item, value: item.id, label: item.name };
              })
            : ""
          : "",
        regionManger: props.ItemList ? props.ItemList.regionManger : "",
        zoneManger: props.ItemList ? props.ItemList.zoneManger : "",
        areaManger: props.ItemList ? props.ItemList.areaManger : "",
        salesExecutive: props.ItemList ? props.ItemList.salesExecutive : "",
        company: props.ItemList ? props.ItemList.company : "",
        incentivesbucket: props.ItemList ? props.ItemList.incentivesbucket : "",
        // unit: props.ItemList ? props.ItemList.unit.id : "",
        unit: Array.isArray(props.ItemList && props.ItemList.unit)
          ? props.ItemList && props.ItemList.unit
            ? props.ItemList.unit.map((item, i) => {
                return { ...item, value: item.id, label: item.name };
              })
            : ""
          : "",
        // gstSlab: props.ItemList ? props.ItemList.gstSlab.id : "",
        gstSlab: Array.isArray(props.ItemList && props.ItemList.gstSlab)
          ? props.ItemList && props.ItemList.gstSlab
            ? props.ItemList.gstSlab.map((item, i) => {
                return { ...item, value: item.id, label: item.name };
              })
            : ""
          : "",
        backOrder: props.ItemList.backOrder ? props.ItemList.backOrder : false,
        platform: platfrmCmmsn,
      }}
      validationSchema={validate}
      onSubmit={(values) => {
        const UpdatedValues = values;

        const cal =
          values.platform +
          values.regionManger +
          values.zoneManger +
          values.areaManger +
          values.salesExecutive +
          values.company +
          values.incentivesbucket;

        if (cal > 99.99 && cal <= 100) {
          UpdatedValues["isRowInvalid"] = "No";
          props.updateItemData(UpdatedValues);
          props.closeEditModel();
        } else {
          toast.error("Total commission need to set 100%", {
            autoClose: 2000,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }}
    >
      {(formik) => (
        <div>
          <div className="userModalDiv">
            <Modal
              show={props.showEditImportModel}
              onHide={props.closeEditModel}
              animation={false}
              size="xl"
              className="detail-ad-Modal"
              backdrop="static"
            >
              <Modal.Header className="mastr-mdl-hd" closeButton>
                <Modal.Title>
                  <span className="modl-titl-align">Edit Product </span>
                </Modal.Title>
              </Modal.Header>
              <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                  <Container>
                    <Card>
                      <Card.Header>
                        {" "}
                        <h5 className="productsHead">Basic Details</h5>
                      </Card.Header>
                      <Card.Body>
                        <div>
                          <Row>
                            <Col lg={4} md={12} sm={12} xs={12}>
                              <TextField
                                label="Name"
                                name="name"
                                type="text"
                                isRequired={true}
                                onChange={formik.handleChange}
                              />
                            </Col>
                            <Col lg={4} md={12} sm={12} xs={12}>
                              <SelectTest
                                label={"Agency"}
                                id={"agency"}
                                isRequired={true}
                                options={
                                  props.AgencysList &&
                                  props.AgencysList.map((item, i) => {
                                    return {
                                      ...item,
                                      value: item.id,
                                      label: item.name,
                                    };
                                  })
                                }
                                multi={false}
                                value={formik.values.agency}
                                onChange={(field, value) => {
                                  formik.setFieldValue(field, [value]);
                                }}
                                onBlur={formik.setFieldTouched}
                                touched={formik.touched.agency}
                                error={formik.errors.agency}
                              />
                            </Col>
                            <Col lg={4} md={12} sm={12} xs={12}>
                              <TextField
                                label="Buying Price"
                                name="buyingPrice"
                                type="text"
                                isRequired={true}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4} md={12} sm={12} xs={12}>
                              <TextField
                                label="Selling Price"
                                name="sellingPrice"
                                type="text"
                                isRequired={true}
                              />
                            </Col>
                            <Col lg={4} md={12} sm={12} xs={12}>
                              <TextField
                                label="Quantity"
                                name="quantity"
                                type="text"
                                isRequired={true}
                              />
                            </Col>
                            <Col lg={4} md={12} sm={12} xs={12}>
                              <SelectTest
                                label={"Category"}
                                id={"ctgryId"}
                                isRequired={true}
                                options={
                                  props.categoryList &&
                                  props.categoryList.map((item, i) => {
                                    return {
                                      ...item,
                                      value: item.id,
                                      label: item.name,
                                    };
                                  })
                                }
                                multi={false}
                                value={formik.values.ctgryId}
                                onChange={(field, value) => {
                                  formik.setFieldValue(field, [value]);

                                  props.fetchSubctgry(value.id);
                                  formik.setFieldValue("subCtgryId", "");
                                }}
                                onBlur={formik.setFieldTouched}
                                touched={formik.touched.ctgryId}
                                error={formik.errors.ctgryId}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4} md={12} sm={12} xs={12}>
                              <div>
                                <SelectTest
                                  label={"Subcategory"}
                                  id={"subCtgryId"}
                                  isRequired={true}
                                  options={
                                    props.subcategoryList &&
                                    props.subcategoryList.map((item, i) => {
                                      return {
                                        ...item,
                                        value: item.id,
                                        label: item.name,
                                      };
                                    })
                                  }
                                  multi={false}
                                  value={formik.values.subCtgryId}
                                  onChange={(field, value) => {
                                    formik.setFieldValue(field, [value]);
                                  }}
                                  onBlur={formik.setFieldTouched}
                                  touched={formik.touched.subCtgryId}
                                  error={formik.errors.subCtgryId}
                                />
                              </div>
                            </Col>
                            <Col lg={4} md={12} sm={12} xs={12}>
                              <div>
                                <SelectTest
                                  label={"Type"}
                                  id={"PrdtType"}
                                  isRequired={true}
                                  options={
                                    props.productTypeList &&
                                    props.productTypeList.map((item, i) => {
                                      return {
                                        ...item,
                                        value: item.id,
                                        label: item.name,
                                      };
                                    })
                                  }
                                  multi={false}
                                  value={formik.values.PrdtType}
                                  onChange={(field, value) => {
                                    formik.setFieldValue(field, [value]);
                                  }}
                                  onBlur={formik.setFieldTouched}
                                  touched={formik.touched.PrdtType}
                                  error={formik.errors.PrdtType}
                                />
                              </div>
                            </Col>
                            <Col lg={4} md={12} sm={12} xs={12}>
                              <SelectTest
                                label={"GST"}
                                id={"gstSlab"}
                                isRequired={true}
                                options={
                                  props.GstList &&
                                  props.GstList.map((item, i) => {
                                    return {
                                      ...item,
                                      value: item.id,
                                      label: item.name,
                                    };
                                  })
                                }
                                multi={false}
                                value={formik.values.gstSlab}
                                onChange={(field, value) => {
                                  formik.setFieldValue(field, [value]);
                                }}
                                onBlur={formik.setFieldTouched}
                                touched={formik.touched.gstSlab}
                                error={formik.errors.gstSlab}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4} md={12} sm={12} xs={12}>
                              <SelectTest
                                label={"Unit"}
                                id={"unit"}
                                isRequired={true}
                                options={
                                  props.unitList &&
                                  props.unitList.map((item, i) => {
                                    return {
                                      ...item,
                                      value: item.id,
                                      label: item.name,
                                    };
                                  })
                                }
                                multi={false}
                                value={formik.values.unit}
                                onChange={(field, value) => {
                                  formik.setFieldValue(field, [value]);
                                }}
                                onBlur={formik.setFieldTouched}
                                touched={formik.touched.unit}
                                error={formik.errors.unit}
                              />
                            </Col>
                          </Row>
                        </div>

                        <label
                          className="checkbox-input"
                          style={{
                            marginTop: "10px",
                            fontSize: "13.5px",
                            fontWeight: "bold",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="backOrder"
                            checked={formik.values.backOrder}
                            onChange={formik.handleChange}
                            name="backOrder"
                            style={{ marginRight: "5px" }}
                          />
                          Allow back order
                        </label>
                      </Card.Body>
                    </Card>
                    <Card>
                      <Card.Header>
                        <Row>
                          <Col lg={6} md={6} sm={6} xs={6}>
                            <h5 className="productsHead">Commission</h5>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body>
                        <div>
                          <Row>
                            <Col lg={3} md={6} sm={6} xs={6}>
                              <p style={{ fontWeight: "bold" }}>
                                Platform Commission : {platfrmCmmsn + "%"}
                              </p>
                            </Col>
                            <Col lg={2} md={6} sm={6} xs={6}>
                              <p style={{ fontWeight: "bold" }}>
                                {commsnAmnt(formik, platfrmCmmsn)}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={2} md={6} sm={6} xs={6}>
                              <div>
                                <TextField
                                  label="Regional Manager (%)"
                                  name="regionManger"
                                  type="number"
                                  min="0"
                                  step="0.01"
                                  isRequired={true}
                                />
                              </div>
                              <div>
                                {commsnAmnt(formik, formik.values.regionManger)}
                              </div>
                            </Col>
                            <Col lg={2} md={6} sm={6} xs={6}>
                              <div>
                                <TextField
                                  label="Zonal Manager (%)"
                                  name="zoneManger"
                                  type="number"
                                  min="0"
                                  step="0.01"
                                  isRequired={true}
                                />
                              </div>
                              <div>
                                {commsnAmnt(formik, formik.values.zoneManger)}
                              </div>
                            </Col>
                            <Col lg={2} md={6} sm={6} xs={6}>
                              <div>
                                <TextField
                                  label="Area Manager (%)"
                                  name="areaManger"
                                  type="number"
                                  min="0"
                                  step="0.01"
                                  isRequired={true}
                                />
                              </div>
                              <div>
                                {commsnAmnt(formik, formik.values.areaManger)}
                              </div>
                            </Col>
                            <Col lg={2} md={6} sm={6} xs={6}>
                              <div>
                                <TextField
                                  label="Sales Executive (%)"
                                  name="salesExecutive"
                                  type="number"
                                  min="0"
                                  step="0.01"
                                  isRequired={true}
                                />
                              </div>
                              <div>
                                {commsnAmnt(
                                  formik,
                                  formik.values.salesExecutive
                                )}
                              </div>
                            </Col>
                            <Col lg={2} md={6} sm={6} xs={6}>
                              <div>
                                <TextField
                                  label="Company (%)"
                                  name="company"
                                  type="number"
                                  min="0"
                                  step="0.01"
                                  isRequired={true}
                                />
                              </div>
                              <div>
                                {commsnAmnt(formik, formik.values.company)}
                              </div>
                            </Col>
                            <Col lg={2} md={6} sm={6} xs={6}>
                              <div>
                                <TextField
                                  label="Incentive Bucket (%)"
                                  name="incentivesbucket"
                                  type="number"
                                  step="0.01"
                                  min="0"
                                  isRequired={true}
                                />
                              </div>
                              <div>
                                {commsnAmnt(
                                  formik,
                                  formik.values.incentivesbucket
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card.Body>
                    </Card>
                  </Container>
                </Modal.Body>

                <Modal.Footer className="mastr-mdl-ftr">
                  <button className="btn btn-danger" type="submit">
                    Submit
                  </button>
                </Modal.Footer>
              </Form>
            </Modal>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default EditImport;
