import React, { useEffect, useState } from "react";
import { Row, Col, Container, Image } from "react-bootstrap";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import "../style.css";

import { TextField } from "../../Common/TextField";

const reg =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

const BasicDetails = ({ clickHandler, ...props }) => {
  const validate = Yup.object({
    name: Yup.string().required(" First name is required"),

    email: !props.isEdit
      ? Yup.string()
          .matches(reg, " Email not valid")
          .required("Email is required")
      : null,
    mobileNo: Yup.string()
      .matches(/^\d{10}$/, "Please enter a valid mobile number")
      .required("Mobile number is required"),
    password: !props.isEdit
      ? Yup.string()
          .min(6, "Password must be atleast 6 characters long")
          .required("Password is required")
      : null,
    confirmPassword: !props.isEdit
      ? Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm password required")
      : null,
  });

  return (
    <div>
      <Formik
        initialValues={{
          mobileNo: (props.item && props.item.mobileNo) || "",
          name: (props.item && props.item.name) || "",
          lName: (props.item && props.item.lName) || "",
          email: (props.item && props.item.email) || "",
          password: (props.item && props.item.password) || "",
          confirmPassword: (props.item && props.item.confirmPassword) || "",
        }}
        validationSchema={validate}
        onSubmit={(value) => {
          clickHandler(2, { ...value });
        }}
      >
        {(formik) => (
          <div>
            <div className="signup-page-div ">
              <Form>
                <Row>
                  <Col lg={4} md={12} sm={12} xs={12}>
                    <div>
                      <TextField
                        label="First Name"
                        name="name"
                        type="text"
                        isRequired={true}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={12} sm={12} xs={12}>
                    <TextField label="Last Name" name="lName" type="text" />
                  </Col>

                  <Col lg={4} md={12} sm={12} xs={12}>
                    <div className="licenseetextbox1">
                      <TextField
                        label="Mobile"
                        name="mobileNo"
                        type="number"
                        isRequired={true}
                      />
                    </div>
                  </Col>
                </Row>

                {!props.isEdit && (
                  <Row>
                    <Col lg={4} md={12} sm={12} xs={12}>
                      <TextField
                        label="Email"
                        name="email"
                        type="text"
                        isRequired={true}
                      />
                    </Col>
                    <Col lg={4} md={12} sm={12} xs={12}>
                      <TextField
                        label="Password"
                        name="password"
                        type="password"
                        isRequired={true}
                      />
                    </Col>

                    <Col lg={4} md={12} sm={12} xs={12}>
                      <TextField
                        label="Retype Password"
                        name="confirmPassword"
                        type="password"
                        isRequired={true}
                      />
                    </Col>
                  </Row>
                )}

                <div className="row buttons-step-div">
                  <Col xl={8} lg={6} md={12} sm={12} xs={12}>
                    <div className="back-to-login">
                      <button
                        className="prev-btn"
                        type="submit"
                        onClick={() => clickHandler(0, formik.values)}
                      >
                        Back
                      </button>
                    </div>
                  </Col>
                  <Col xl={4} lg={6} md={12} sm={12} xs={12}>
                    <button className=" next-btn">Next</button>
                  </Col>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};
export default BasicDetails;
