import { put, takeEvery, select, call, all } from "redux-saga/effects";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { createBrowserHistory } from "history";
import {
  FETCH_EARNINGS_INIT_ACTION,
  FETCH_EARNINGS_SUCCESS_ACTION,
  FETCH_EARNINGS_FAIL_ACTION,
  FETCH_EARNINGSBYID_INIT_ACTION,
  FETCH_EARNINGSBYID_SUCCESS_ACTION,
  FETCH_EARNINGSBYID_FAIL_ACTION,
  FETCH_AREA_DROP_DOWN_INIT_ACTION,
  FETCH_AREA_DROP_DOWN_SUCCESS_ACTION,
  FETCH_AREA_DROP_DOWN_FAIL_ACTION,
  FETCH_EARNINGS_TOTAL_INIT_ACTION,
  FETCH_EARNINGS_TOTAL_SUCCESS_ACTION,
  FETCH_EARNINGS_TOTAL_FAIL_ACTION,
  EXPORT_EARNINGS_INIT_ACTION,
  EXPORT_EARNINGS_SUCCESS_ACTION,
  EXPORT_EARNINGS_FAIL_ACTION,
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as selectors from "../../service/selectors";
import { shallow } from "enzyme";

function* getRoleApiUrl() {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const role = localStorage.getItem("sStraitUserRole");
  const userId = localStorage.getItem("sStraitUserId");

  let getDataUrl = [
    {
      role: "licensee",
      url: "&licenseeId=" + userDtls.licenseeId + "&receiver=" + userId,
    },
    {
      role: "distributor",
      url: "&licenseeId=" + userDtls.licenseeId + "&receiver=" + userId,
    },
    {
      role: "licenseeAccountant",
      url:
        "&licenseeId=" + userDtls.licenseeId + "&receiver=" + userDtls.author,
    },

    {
      role: "regionalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&receiver=" + userId,
    },
    {
      role: "zonalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&receiver=" + userId,
    },
    {
      role: "areaManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&receiver=" + userId,
    },
    {
      role: "salesExcecutive",
      url: "&licenseeId=" + userDtls.licenseeId + "&receiver=" + userId,
    },
    {
      role: "agent",
      url: "&licenseeId=" + userDtls.licenseeId + "&receiver=" + userId,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);
  return apiUrl;
}

function* fetchEarnings(value) {
  let pageno = value.value.page === undefined ? 1 : value.value.page;
  const token = localStorage.getItem("sStraitToken");

  const apiUrl = yield call(getRoleApiUrl);
  let filterVal =
    !value || !value.value || !value.value.filterVal
      ? ""
      : value.value.filterVal;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? ""
      : value.value.searchVal;
  let typeFilterVal =
    !value || !value.value || !value.value.typeFilterVal
      ? ""
      : value.value.typeFilterVal;

  let _url = `${appConfig.ip}/earnings?limit=10&search=${searchVal}${filterVal}&page=${pageno}${apiUrl.url}`;

  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  }
  if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  }
  if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }
  if (typeFilterVal) {
    _url = `${_url}&type=${typeFilterVal}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_EARNINGS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_EARNINGS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchLicenseeById() {
  const token = localStorage.getItem("sStraitToken");
  let licenseeId = JSON.parse(localStorage.getItem("sStraitUserDtls"))
    .licenseeId;

  try {
    const res = yield fetch(`${appConfig.ip}/users?licenseeId=` + licenseeId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* getEarningsById(value) {
  const token = localStorage.getItem("sStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/earnings/` + value.value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_EARNINGSBYID_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_EARNINGSBYID_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchAreaDrop_down(value) {
  let zone = !value || !value.value.id ? "" : value.value.id;
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/area?zone=${zone}&isActive=true&dummy=false`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_AREA_DROP_DOWN_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_AREA_DROP_DOWN_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchEarningsTotal(value) {
  const token = localStorage.getItem("sStraitToken");

  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const role = localStorage.getItem("sStraitUserRole");

  // let getDataUrl = [
  //   { role: "licensee", url: "receiver=" + userDtls.id },
  //   { role: "licenseeAccountant", url: "receiver=" + userDtls.id },
  //   { role: "regionalManager", url: "receiver=" + userDtls.id },
  //   { role: "zonalManager", url: "receiver=" + userDtls.id },
  //   { role: "areaManager", url: "receiver=" + userDtls.id },
  //   { role: "salesExcecutive", url: "receiver=" + userDtls.id },
  //   { role: "distributor", url: "receiver=" + userDtls.id },
  // ];

  // let apiUrl = getDataUrl.find((e) => e.role === role);

  let filterVal =
    !value || !value.value || !value.value.filterVal
      ? ""
      : value.value.filterVal;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? ""
      : value.value.searchVal;
  let typeFilterVal =
    !value || !value.value || !value.value.typeFilterVal
      ? ""
      : value.value.typeFilterVal;

  let _url = `${appConfig.ip}/earnings/totalAmount?receiver=${userDtls.id}`;

  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }
  if (filterVal) {
    _url = `${_url}${filterVal}`;
  }
  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  }
  if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  }
  if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }
  if (typeFilterVal) {
    _url = `${_url}&type=${typeFilterVal}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_EARNINGS_TOTAL_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_EARNINGS_TOTAL_FAIL_ACTION, error: err });
    } else {
    }
  }
}
function* exprtEarningsData(value) {
  let fileName = " Report";
  const token = localStorage.getItem("sStraitToken");
  const apiUrl = yield call(getRoleApiUrl);

  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? ""
      : value.value.searchVal;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let filterVal =
    !value || !value.value || !value.value.filterVal
      ? ""
      : value.value.filterVal;
  let typeFilterVal =
    !value || !value.value || !value.value.typeFilterVal
      ? ""
      : value.value.typeFilterVal;

  let _url = `${appConfig.ip}/earnings?limit=100&search=${searchVal}${apiUrl.url}`;

  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  }
  if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  }
  if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }
  if (filterVal) {
    _url = `${_url}${filterVal}`;
  }
  if (typeFilterVal) {
    _url = `${_url}&type=${typeFilterVal}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let csvData = [];

      resJSON.rows.map((item) => {
        csvData.push({
          EARNINGS_ID: item.refId,
          ORDER_ID: item.order ? item.order.refId : "NIL",
          AMOUNT: item.currency.symbol + item.amount,
          AMOUNT: item.currency.symbol + item.amount,
          LICENSEE_NAME:
            item.licenseeId != null ? item.licenseeId.orgName : "NIL",
          // REGION: item.region != null ? item.region.name : "NIL",
          // ZONE: item.zone != null ? item.zone.name : "NIL",
          // AREA: item.area != null ? item.area.name : "NIL",
        });
      });

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      yield put({
        type: EXPORT_EARNINGS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EXPORT_EARNINGS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* EarningsActionWatcher() {
  yield takeEvery(FETCH_EARNINGS_INIT_ACTION, fetchEarnings);
  yield takeEvery(FETCH_EARNINGSBYID_INIT_ACTION, getEarningsById);
  yield takeEvery(FETCH_AREA_DROP_DOWN_INIT_ACTION, fetchAreaDrop_down);
  yield takeEvery(FETCH_EARNINGS_TOTAL_INIT_ACTION, fetchEarningsTotal);
  yield takeEvery(EXPORT_EARNINGS_INIT_ACTION, exprtEarningsData);
}
