export const FETCH_AGENCY_INIT_ACTION = "FETCH_AGENCY_INIT_ACTION";
export const FETCH_AGENCY_SUCCESS_ACTION =
  FETCH_AGENCY_INIT_ACTION + "_SUCCESS";
export const FETCH_AGENCY_FAIL_ACTION = FETCH_AGENCY_INIT_ACTION + "_ERROR";

export const ADD_AGENCY_INIT_ACTION = "ADD_AGENCY_INIT_ACTION";
export const ADD_AGENCY_SUCCESS_ACTION = ADD_AGENCY_INIT_ACTION + "_SUCCESS";
export const ADD_AGENCY_FAIL_ACTION = ADD_AGENCY_INIT_ACTION + "_ERROR";

export const EDIT_AGENCY_INIT_ACTION = "EDIT_AGENCY_INIT_ACTION";
export const EDIT_AGENCY_SUCCESS_ACTION = EDIT_AGENCY_INIT_ACTION + "_SUCCESS";
export const EDIT_AGENCY_FAIL_ACTION = EDIT_AGENCY_INIT_ACTION + "_ERROR";

export const CHANGE_AGENCY_STATUS_INIT_ACTION =
  "CHANGE_AGENCY_STATUS_INIT_ACTION";
export const CHANGE_AGENCY_STATUS_SUCCESS_ACTION =
  CHANGE_AGENCY_STATUS_INIT_ACTION + "_SUCCESS";
export const CHANGE_AGENCY_STATUS_FAIL_ACTION =
  CHANGE_AGENCY_STATUS_INIT_ACTION + "_ERROR";

export const DELETE_AGENCY_INIT_ACTION = "DELETE_AGENCY_INIT_ACTION";
export const DELETE_AGENCY_SUCCESS_ACTION =
  DELETE_AGENCY_INIT_ACTION + "_SUCCESS";
export const DELETE_AGENCY_FAIL_ACTION = DELETE_AGENCY_INIT_ACTION + "_ERROR";

export function fetchAgency(value) {
  return {
    type: FETCH_AGENCY_INIT_ACTION,
    value: value,
  };
}

export function AddAgency(value) {
  return {
    type: ADD_AGENCY_INIT_ACTION,
    value: value,
  };
}

export function EditAgency(value) {
  return {
    type: EDIT_AGENCY_INIT_ACTION,
    value: value,
  };
}

export function changeStatus(value) {
  return {
    type: CHANGE_AGENCY_STATUS_INIT_ACTION,
    value: value,
  };
}

export function DeleteValue(value) {
  return {
    type: DELETE_AGENCY_INIT_ACTION,
    value: value,
  };
}
