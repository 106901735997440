export const FETCH_PAYMENTS_INIT_ACTION = "FETCH_PAYMENTS_INIT_ACTION";
export const FETCH_PAYMENTS_SUCCESS_ACTION =
FETCH_PAYMENTS_INIT_ACTION + "_SUCCESS";
export const FETCH_PAYMENTS_FAIL_ACTION = FETCH_PAYMENTS_INIT_ACTION + "_ERROR";

export const FETCH_PAYMENTSBYID_INIT_ACTION = "FETCH_PAYMENTSBYID_INIT_ACTION";
export const FETCH_PAYMENTSBYID_SUCCESS_ACTION =
FETCH_PAYMENTSBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_PAYMENTSBYID_FAIL_ACTION = FETCH_PAYMENTSBYID_INIT_ACTION + "_ERROR";

export const EXPORT_PAYMENT_INIT_ACTION = "EXPORT_PAYMENT_INIT_ACTION";
export const EXPORT_PAYMENT_SUCCESS_ACTION =EXPORT_PAYMENT_INIT_ACTION + "_SUCCESS";
export const EXPORT_PAYMENT_FAIL_ACTION = EXPORT_PAYMENT_INIT_ACTION + "_ERROR";
// export const LOADING_INIT_ACTION="LOADING_INIT_ACTION"


export function fetchPayments(value) {
    return {
      type: FETCH_PAYMENTS_INIT_ACTION,
      value: value

    };
  }
  

  export function getPaymentsById(value) {
    return {
      type: FETCH_PAYMENTSBYID_INIT_ACTION,
      value:value
    };
  }

  export function exprtFullData(value) {
    return {
      type: EXPORT_PAYMENT_INIT_ACTION,
      value: value
    };
  }


  
  
  // export function fetchAreaa_Dropdown(value) {
  //   return {
  //     type: FETCH_AREAS__DROPDOWN_INIT_ACTION,
  //     value: value
  //   };
  // }