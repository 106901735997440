import {
  RESETPWD_SUCCESS_ACTION,
  VERIFYPWDOTP_SUCCESS_ACTION,
  SETNEWPWD_SUCCESS_ACTION,
  REDIRECT_SUCCESS_ACTION
} from "./action";

const initialState = {
  processing: false,
  error: false
};

export default function ForgotPasswordReducer(state = initialState, action) {
  switch (action.type) {
    case RESETPWD_SUCCESS_ACTION:
      return {
          ...state,
          otpSent:true,        
      };

    case VERIFYPWDOTP_SUCCESS_ACTION:
      if(action.payload.ok === true){
          return {
              ...state,
              otpVerified:true,        
          };
      }else{
        return {
          ...state
        };
      }
      
  case SETNEWPWD_SUCCESS_ACTION:
      return {
          ...state,
          pwdreset:true,        
      };

  case REDIRECT_SUCCESS_ACTION:
    return {
      ...state,
      //redirecttoPage:true,
      otpSent:undefined,
      otpVerified:undefined,
      pwdreset:undefined,
    }
      
    default:
      return {
        ...state
      };
  }
}