import { put, takeEvery, select, call, all } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_INVENTORY_INIT_ACTION,
  FETCH_INVENTORY_SUCCESS_ACTION,
  FETCH_INVENTORY_FAIL_ACTION,
  // FETCH_PRODUCTS_INV_INIT_ACTION,
  // FETCH_PRODUCTS_INV_SUCCESS_ACTION,
  // FETCH_PRODUCTS_INV_FAIL_ACTION,
  // ADD_PRODUCTS_INV_INIT_ACTION,
  // ADD_PRODUCTS_INV_SUCCESS_ACTION,
  // ADD_PRODUCTS_INV_FAIL_ACTION,
  // EDIT_PRODUCTS_INV_INIT_ACTION,
  // EDIT_PRODUCTS_INV_SUCCESS_ACTION,
  // EDIT_PRODUCTS_INV_FAIL_ACTION,
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as selectors from "../../service/selectors";
import { shallow } from "enzyme";

function* fetchProductInventory(value) {
  let pageno = value.value.page === undefined ? 1 : value.value.page;
  const token = localStorage.getItem("sStraitToken");
  const userId = localStorage.getItem("sStraitUserId");
  const licenseeId = JSON.parse(
    localStorage.getItem("sStraitUserDtls")
  ).licenseeId;

  let filter = (value && value.value && value.value.filter) || "";

  let filterParam = "";
  if (filter) {
    filterParam = `&product=${filter}`;
  }

  try {
    const res = yield fetch(
      `${appConfig.ip}/inventory?sort=-updatedAt&limit=10&page=${pageno}&isActive=true&licenseeId=${licenseeId}${filterParam}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_INVENTORY_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_INVENTORY_FAIL_ACTION, error: err });
    } else {
    }
  }
}

// function* fetchProductInv(value) {
//   const token = localStorage.getItem("sStraitToken");
//   const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
//   let licenseeId = userDetails.licenseeId;
//   let searchval =
//     !value || !value.value || !value.value.searchval
//       ? ""
//       : value.value.searchval;

//   let _url = `${appConfig.ip}/product?status=active&licenseeId=${licenseeId}`;

//   if (searchval) {
//     _url = `${_url}&search=${searchval}`;
//   }
//   try {
//     const res = yield fetch(_url, {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     if (!res.ok) {
//       let errJSON = {};
//       try {
//         errJSON = yield res.json();
//       } catch {}
//       throw Object.assign(res, errJSON);
//     } else {
//       const resJSON = yield res.json();

//       yield put({
//         type: FETCH_PRODUCTS_INV_SUCCESS_ACTION,
//         payload: resJSON,
//       });
//     }
//   } catch (err) {
//     if (err.ok === false) {
//       yield put({ type: FETCH_PRODUCTS_INV_FAIL_ACTION, error: err });
//     } else {
//     }
//   }
// }

// function* AddProductInv(value) {
//   const token = localStorage.getItem("sStraitToken");
//   const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
//   const productInvData = {
//     date: value.value.date ? value.value.date : null,
//     desc: value.value.desc,
//     quantity: value.value.quantity,
//     product: value.value.product[0].id,
//     unit: value.value.product[0].unit,
//     licenseeId: userDetails.licenseeId,
//   };

//   try {
//     const res = yield fetch(`${appConfig.ip}/inventory`, {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(productInvData),
//     });

//     if (!res.ok) {
//       let errJSON1 = {};
//       try {
//         errJSON1 = yield res.json();
//       } catch {}
//       throw Object.assign(res, errJSON1);
//     } else {
//       const resJSON = yield res.json();

//       yield put({ type: FETCH_INVENTORY_INIT_ACTION, value: { page: 1 } });

//       const qty = value.value.quantity + value.value.product[0].quantity;

//       const productUpdt = yield call(productUpdate, {
//         id: value.value.product[0].id,
//         qty: qty,
//       });
//       yield put({
//         type: ADD_PRODUCTS_INV_SUCCESS_ACTION,
//         payload: resJSON,
//       });
//       yield toast.success("Product Inventory added successfully", {
//         autoClose: 3000,
//       });
//     }
//   } catch (err) {
//     if (err.ok === false) {
//       yield put({
//         type: ADD_PRODUCTS_INV_FAIL_ACTION,
//         error: err,
//       });
//     }
//   }
// }

//Update product quantity

function* productUpdate(value) {
  const token = localStorage.getItem("sStraitToken");

  const data = {
    quantity: value.qty,
  };

  try {
    const res = yield fetch(`${appConfig.ip}/product/` + value.id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

// function* EditProductInv(value) {
//   let id = value.value.id;
//   const token = localStorage.getItem("sStraitToken");

//   let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));

//   const data = {
//     product: value.value.product,
//     date: value.value.date,
//     desc: value.value.desc,
//     quantity: value.value.quantity,
//   };

//   try {
//     const res = yield fetch(`${appConfig.ip}/inventory/` + id, {
//       method: "PUT",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(data),
//     });
//     if (!res.ok) {
//       let errJSON = {};
//       try {
//         errJSON = yield res.json();
//       } catch {}
//       throw Object.assign(res, errJSON);
//     } else {
//       yield toast.success("Product Inventory updated successfully", {
//         autoClose: 3000,
//       });
//       const resJSON = yield res.json();
//       yield put({
//         type: EDIT_PRODUCTS_INV_SUCCESS_ACTION,
//         payload: resJSON,
//       });
//     }
//   } catch (err) {
//     if (err.ok === false) {
//       yield put({ type: EDIT_PRODUCTS_INV_FAIL_ACTION, error: err });
//     } else {
//     }
//   }
// }

export function* ProductInventoryActionWatcher() {
  yield takeEvery(FETCH_INVENTORY_INIT_ACTION, fetchProductInventory);
  // yield takeEvery(FETCH_PRODUCTS_INV_INIT_ACTION, fetchProductInv);
  // yield takeEvery(ADD_PRODUCTS_INV_INIT_ACTION, AddProductInv);
  // yield takeEvery(EDIT_PRODUCTS_INV_INIT_ACTION, EditProductInv);
}
