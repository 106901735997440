import {
    FETCH_PAYMENTINFO_INIT_ACTION,
    FETCH_PAYMENTINFO_SUCCESS_ACTION

} from "./action";

const initialState = {
    processing: true,
    error: false,
    loading: true
};

export default function PaymentInfoReducer(
    state: any = initialState,
    action: Function
) {
    switch (action.type) {
        case FETCH_PAYMENTINFO_INIT_ACTION:
            return {
                ...state,
                processing: true,
                error: false,
            };
        case FETCH_PAYMENTINFO_SUCCESS_ACTION:
            return {
                ...state,
                paymentDtls: action.payload,
                processing: false,
                error: false,
            };


        default:
            return {
                ...state,
            };
    }
}
