import {
  FETCH_AREA_SUCCESS_ACTION,
  FETCH_ZONE_DROPDOWN_SUCCESS_ACTION,
  FETCH_PINCODE_SUCCESS_ACTION,
  FETCH_AREA_CLEAR_ACTION,
  FETCH_AREA_DETAIL_SUCCESS_ACTION,
  FETCH_AREA_DETAIL_INIT_ACTION,
  FETCH_DISTRICT_DROPDOWN_SUCCESS_ACTION,
  CLEAR_ZONE_DROPDOWN_INIT_ACTION,
  FETCH_AREA_LOCALITY_SUCCESS_ACTION,
  DELETE_AREA_SUCCESS_ACTION,
  EDIT_AREA_SUCCESS_ACTION,
  // FILTER_SEARCH_AREA_SUCCESS_ACTION
} from "./action";

const initialState = {
  processing: true,
  error: false,
};

export default function AreaReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    case FETCH_AREA_SUCCESS_ACTION:
      return {
        ...state,
        areaList: state.areaList
          ? state.areaList.concat(action.payload.rows)
          : action.payload.rows,
        areaDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };

    // case FILTER_SEARCH_AREA_SUCCESS_ACTION:
    //     return {
    //         ...state,
    //         areaList: action.payload.rows,
    //         areaDtlsCount: action.payload.count,
    //         processing: false,
    //         error: false,
    //     };

    case FETCH_ZONE_DROPDOWN_SUCCESS_ACTION:
      return {
        ...state,
        zoneDropdownList: action.payload.rows,
        zoneDropdownDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };

    case FETCH_DISTRICT_DROPDOWN_SUCCESS_ACTION:
      return {
        ...state,
        districtDropdownList: action.payload.rows,
        districtDropdownDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };

    case FETCH_PINCODE_SUCCESS_ACTION:
      return {
        ...state,
        pincodeList: action.payload.rows,
        pincodeDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };

    case FETCH_AREA_CLEAR_ACTION:
      return {
        ...state,
        areaList: [],
        areaDtlsCount: "",
      };
    case FETCH_AREA_DETAIL_INIT_ACTION:
      return {
        ...state,
        processing: true,
        loading: true,
        error: false,
        area: "",
      };
    case FETCH_AREA_DETAIL_SUCCESS_ACTION:
      return {
        ...state,
        processing: false,
        loading: false,
        error: false,
        area: action.payload,
      };
    case CLEAR_ZONE_DROPDOWN_INIT_ACTION:
      return {
        ...state,
        zoneDropdownList: [],
      };
    case FETCH_AREA_LOCALITY_SUCCESS_ACTION:
      return {
        ...state,
        localityList: action.payload.rows,

        processing: false,
        error: false,
      };
    case DELETE_AREA_SUCCESS_ACTION:
      return {
        ...state,
        areaList: state.areaList.filter((item) => item.id !== action.payload),
        areaDtlsCount: state.areaDtlsCount - 1,
      };
    case EDIT_AREA_SUCCESS_ACTION:
      return {
        ...state,
        areaList: state.areaList.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };

    default:
      return {
        ...state,
      };
  }
}
