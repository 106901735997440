import {
  // FETCH_STATELIST_SUCCESS_ACTION,
  // FETCH_STATELIST_INIT_ACTION,
  FETCH_LEADS_SUCCESS_ACTION,
  FETCH_LEADS_INIT_ACTION,
  FETCH_LEADS_CLEAR_ACTION,
  FETCH_LEADS_DETAIL_INIT_ACTION,
  FETCH_LEADS_DETAIL_SUCCESS_ACTION,
  FETCH_CUSTOMERS_BY_SALESEXECUTIVE_SUCCESS_ACTION,
  FETCH_PRODUCTS_BY_SALESEXECUTIVE_SUCCESS_ACTION,
  FETCH_USER_DETAIL_SUCCESS_ACTION,
  FILTER_SEARCH_LEADS_SUCCESS_ACTION,
  CHANGE_ORDERTOLEADSTATUS_SUCCESS_ACTION,
  FETCH_SALESEXCTV_SUCCESS_ACTION,
  EDIT_LEADS_SUCCESS_ACTION,
  REMOVE_PRODUCT_INIT_ACTION,
  ADDREDUCER_PRODUCT_INIT_ACTION,
} from "./action";
import { sortData } from "../../screens/Common/sortData";

const initialState = {
  processing: true,
  error: false,
  loadingLead: true,
};

export default function LeadsReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    // case FETCH_STATELIST_INIT_ACTION:
    //   return {
    //     ...state,
    //     processing: true,
    //     error: false,
    //   };
    // case FETCH_LEADS_CLEAR_ACTION:
    //   return {
    //     ...state,
    //     leadsList: [],
    //     leadsDtlsCount: '',
    //   };
    case FETCH_CUSTOMERS_BY_SALESEXECUTIVE_SUCCESS_ACTION:
      return {
        ...state,
        customersList: sortData(action.payload.rows),
        customersDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };
    case FETCH_PRODUCTS_BY_SALESEXECUTIVE_SUCCESS_ACTION:
      return {
        ...state,
        productsList: sortData(action.payload.rows),
        productsDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };
    case FETCH_LEADS_SUCCESS_ACTION:
      return {
        ...state,
        leadsList: action.payload.rows,
        leadsDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };

    case FILTER_SEARCH_LEADS_SUCCESS_ACTION:
      return {
        ...state,
        leadsList: action.payload.rows,
        leadsDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };

    case FETCH_LEADS_DETAIL_INIT_ACTION:
      return {
        ...state,
        processing: true,
        loadingLead: true,
        error: false,
        leads: "",
      };

    case FETCH_LEADS_DETAIL_SUCCESS_ACTION:
      return {
        ...state,
        processing: false,
        loadingLead: false,
        error: false,
        leads: action.payload,
      };
    case FETCH_USER_DETAIL_SUCCESS_ACTION:
      return {
        ...state,
        processing: false,
        error: false,
        user: action.payload,
      };
    case CHANGE_ORDERTOLEADSTATUS_SUCCESS_ACTION:
      return {
        ...state,
        leadsList: state.leadsList.map((lead) =>
          lead.id === action.payload.id
            ? { ...lead, status: action.payload.status }
            : lead
        ),
      };
    case FETCH_SALESEXCTV_SUCCESS_ACTION:
      return {
        ...state,
        processing: false,
        error: false,
        salesExctvList: action.payload.rows,
      };
    case EDIT_LEADS_SUCCESS_ACTION:
      return {
        ...state,
        leadsList: state.leadsList.map((lead) =>
          lead.id === action.payload.id ? action.payload : lead
        ),
      };
    case REMOVE_PRODUCT_INIT_ACTION:
      return {
        ...state,
        productsList: state.productsList.filter(
          (item) => item.id !== action.value.id
        ),
      };
    case ADDREDUCER_PRODUCT_INIT_ACTION:
      return {
        ...state,
        productsList: [...state.productsList, action.value],
      };

    default:
      return {
        ...state,
      };
  }
}
