import { put, takeEvery, call } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_AREA_INIT_ACTION,
  FETCH_AREA_SUCCESS_ACTION,
  FETCH_AREA_FAIL_ACTION,
  DELETE_AREA_INIT_ACTION,
  DELETE_AREA_SUCCESS_ACTION,
  DELETE_AREA_FAIL_ACTION,
  FETCH_ZONE_DROPDOWN_INIT_ACTION,
  FETCH_ZONE_DROPDOWN_SUCCESS_ACTION,
  FETCH_ZONE_DROPDOWN_FAIL_ACTION,
  FETCH_PINCODE_INIT_ACTION,
  FETCH_PINCODE_SUCCESS_ACTION,
  FETCH_PINCODE_FAIL_ACTION,
  ADD_AREA_INIT_ACTION,
  ADD_AREA_SUCCESS_ACTION,
  ADD_AREA_FAIL_ACTION,
  EDIT_AREA_INIT_ACTION,
  EDIT_AREA_SUCCESS_ACTION,
  EDIT_AREA_FAIL_ACTION,
  FETCH_AREA_CLEAR_ACTION,
  FETCH_AREA_DETAIL_INIT_ACTION,
  FETCH_AREA_DETAIL_SUCCESS_ACTION,
  FETCH_AREA_DETAIL_FAIL_ACTION,
  FETCH_DISTRICT_DROPDOWN_INIT_ACTION,
  FETCH_DISTRICT_DROPDOWN_SUCCESS_ACTION,
  FETCH_DISTRICT_DROPDOWN_FAIL_ACTION,
  // FILTER_SEARCH_AREA_INIT_ACTION,
  // FILTER_SEARCH_AREA_SUCCESS_ACTION,
  // FILTER_SEARCH_AREA_FAIL_ACTION,
  FETCH_AREA_LOCALITY_INIT_ACTION,
  FETCH_AREA_LOCALITY_FAIL_ACTION,
  FETCH_AREA_LOCALITY_SUCCESS_ACTION,
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* fetchArea(value) {
  const token = localStorage.getItem("sStraitToken");

  let pageno = value.value.page === undefined ? 1 : value.value.page;
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licenseeId = userDetails.licenseeId;
  let region = userDetails.region ? userDetails.region : "";
  let zone = userDetails.zone ? userDetails.zone : "";

  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? ""
      : value.value.searchVal;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? ""
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? ""
      : value.value.filterValZone;

  let _url = `${appConfig.ip}/area?limit=12&page=${pageno}&licenseeId=${licenseeId}&isActive=true&dummy=false`;

  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }

  if (filterValRegion || filterValZone) {
    if (filterValRegion && !filterValZone) {
      _url = `${_url}&region=${filterValRegion}`;
    }
    if (filterValRegion && filterValZone) {
      _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
    }
  } else {
    _url = `${_url}&region=${region}&zone=${zone}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_AREA_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_AREA_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* deleteArea(value) {
  let id = value.value.id;

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/area/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      // yield put({
      //   type: FETCH_AREA_CLEAR_ACTION,
      // });
      // yield put({
      //   type: FETCH_AREA_INIT_ACTION,
      //   value: { page: 1 },
      // });
      yield put({
        type: DELETE_AREA_SUCCESS_ACTION,
        payload: id,
      });
      yield toast.success("Area deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_AREA_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchZoneDropdown(value) {
  let region = !value || !value.value || !value.value.id ? "" : value.value.id;
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/zone?region=${region}&isActive=true&dummy=false`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_ZONE_DROPDOWN_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_ZONE_DROPDOWN_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchPincode(value) {
  let districtId =
    !value || !value.value || !value.value.id ? "" : value.value.id;

  let searchval =
    !value || !value.value || !value.value.searchval
      ? ""
      : value.value.searchval;

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/pincode?district=${districtId}&search=${searchval}&isActive=true`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_PINCODE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_PINCODE_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchAreaExist(value) {
  const token = localStorage.getItem("sStraitToken");
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licenseeId = userDetails.licenseeId;

  let name = value.name;
  let region = value.region[0].id;
  let zone = value.zone[0].id;

  try {
    const res = yield fetch(
      `${appConfig.ip}/area/exist?name=${name}&region=${region}&zone=${zone}&licenseeId=${licenseeId}&isActive=true`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* addArea(value) {
  const checkName = yield call(fetchAreaExist, value.value);

  if (checkName) {
    yield toast.error("Area Already existed", {
      autoClose: 3000,
    });
  } else {
    try {
      const token = localStorage.getItem("sStraitToken");
      const region = value.value.region[0].id;
      const zone = value.value.zone[0].id;
      const ref =
        value.value.zone[0].ref === "state"
          ? "district"
          : value.value.ref[0].value;
      // const stateOrdistrict = ref === 'state' ?
      //   value.value.districts.map(x => x.value)
      //   : ref === 'states'
      //     ? value.value.states.map(x => x.value)
      //     : []

      const userId = localStorage.getItem("sStraitUserId");

      const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
      const data = {
        licenseeId: userDetails.licenseeId,
        user: userId,
        ref: ref,
        region: region,
        zone: zone,
        pincodes:
          ref === "pincode" ? value.value.pincodes.map((x) => x.value) : [],
        districts:
          ref === "district" ? value.value.districts.map((x) => x.value) : [],
        locality:
          ref === "lsgd" ? value.value.locality.map((x) => x.value) : [],
        name: value.value.name.toLowerCase(),
      };

      const res = yield fetch(`${appConfig.ip}/area`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) {
        let errJSON = {};
        try {
          errJSON = yield res.json();
        } catch {
          throw Object.assign(res, errJSON);
        }
      } else {
        const resJSON = yield res.json();

        //Product update
        yield call(productUpdate, resJSON);
        yield put({
          type: ADD_AREA_SUCCESS_ACTION,
          payload: resJSON,
        });
        yield toast.success("Area added successfully", {
          autoClose: 3000,
        });
        yield put({
          type: FETCH_AREA_CLEAR_ACTION,
        });
        yield put({
          type: FETCH_AREA_INIT_ACTION,
          value: { page: 1 },
        });
      }
    } catch (err) {
      if (err.ok === false) {
        yield put({
          type: ADD_AREA_FAIL_ACTION,
          error: err,
        });
      }
    }
  }
}

function* productUpdate(value) {
  const data = {
    areaRef: "area",
    region: value.region.id,
    zone: value.zone.id,
    area: value.id,
  };

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/product/updateArea`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
    }
  } catch (e) {}
}

function* editArea(value) {
  let id = value.value.id;
  const token = localStorage.getItem("sStraitToken");
  const region = value.value.region[0].id;
  const zone = value.value.zone[0].id;
  const ref =
    value.value.zone[0].ref === "state" ? "district" : value.value.ref[0].value;

  const userId = localStorage.getItem("sStraitUserId");
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const data = {
    licenseeId: userDetails.licenseeId,
    user: userId,
    ref: ref,
    region: region,
    zone: zone,
    pincodes: ref === "pincode" ? value.value.pincodes.map((x) => x.value) : [],
    districts:
      ref === "district" ? value.value.districts.map((x) => x.value) : [],
    locality: ref === "lsgd" ? value.value.locality.map((x) => x.value) : [],
    name: value.value.name.toLowerCase(),
  };

  try {
    const res = yield fetch(`${appConfig.ip}/area/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      // yield put({
      //   type: FETCH_AREA_CLEAR_ACTION,
      // });
      // yield put({
      //   type: FETCH_AREA_INIT_ACTION,
      //   value: { page: 1 },
      // });
      yield toast.success("Area updated successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();

      const areaRes = yield call(fetchAreaDetail, {
        value: { id: resJSON.id, editRes: true },
      });

      yield put({
        type: EDIT_AREA_SUCCESS_ACTION,
        payload: areaRes,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EDIT_AREA_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchAreaDetail(value) {
  const token = localStorage.getItem("sStraitToken");
  let id = !value || !value.value || !value.value.id ? "" : value.value.id;
  try {
    const res = yield fetch(`${appConfig.ip}/area/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      if (!value.value.editRes) {
        if (resJSON.region) {
          yield call(fetchZoneDropdown, { value: { id: resJSON.region.id } });
        }
        if (resJSON.zone && resJSON.zone.ref === "district") {
          if (resJSON.ref === "pincode") {
            yield call(fetchPincode, {
              value: { id: resJSON.zone.districts.map((x) => x.id).toString() },
            });
          } else if (resJSON.ref === "lsgd") {
            yield call(fetchLocality, {
              value: { id: resJSON.zone.districts.map((x) => x.id).toString() },
            });
          }
        } else if (resJSON.zone && resJSON.zone.ref === "state") {
          yield call(fetchDistrictDropdown, {
            value: { id: resJSON.zone.states.map((x) => x.id).toString() },
          });
        }
      }

      yield put({
        type: FETCH_AREA_DETAIL_SUCCESS_ACTION,
        payload: resJSON,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_AREA_DETAIL_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchDistrictDropdown(value) {
  let stateId = !value || !value.value || !value.value.id ? "" : value.value.id;

  let searchval =
    !value || !value.value || !value.value.searchval
      ? ""
      : value.value.searchval;

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/districts?stateId=${stateId}&search=${searchval}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_DISTRICT_DROPDOWN_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_DISTRICT_DROPDOWN_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchLocality(value) {
  let districtId =
    !value || !value.value || !value.value.id ? "" : value.value.id;

  let searchval =
    !value || !value.value || !value.value.searchval
      ? ""
      : value.value.searchval;

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/locality?district=${districtId}&isActive=true&search=${searchval}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_AREA_LOCALITY_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_AREA_LOCALITY_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* AreaActionWatcher() {
  yield takeEvery(FETCH_AREA_INIT_ACTION, fetchArea);
  yield takeEvery(DELETE_AREA_INIT_ACTION, deleteArea);
  yield takeEvery(FETCH_ZONE_DROPDOWN_INIT_ACTION, fetchZoneDropdown);
  yield takeEvery(FETCH_PINCODE_INIT_ACTION, fetchPincode);
  yield takeEvery(ADD_AREA_INIT_ACTION, addArea);
  yield takeEvery(EDIT_AREA_INIT_ACTION, editArea);
  yield takeEvery(FETCH_AREA_DETAIL_INIT_ACTION, fetchAreaDetail);
  yield takeEvery(FETCH_DISTRICT_DROPDOWN_INIT_ACTION, fetchDistrictDropdown);
  // yield takeEvery(FILTER_SEARCH_AREA_INIT_ACTION, filterSearchArea);
  yield takeEvery(FETCH_AREA_LOCALITY_INIT_ACTION, fetchLocality);
}
