export const FETCH_WDREQUEST_INIT_ACTION = "FETCH_WDREQUEST_INIT_ACTION";
export const FETCH_WDREQUEST_SUCCESS_ACTION =
  FETCH_WDREQUEST_INIT_ACTION + "_SUCCESS";
export const FETCH_WDREQUEST_FAIL_ACTION =
  FETCH_WDREQUEST_INIT_ACTION + "_ERROR";

export const ADD_WDREQUEST_INIT_ACTION = "ADD_WDREQUEST_INIT_ACTION";
export const ADD_WDREQUEST_SUCCESS_ACTION =
  ADD_WDREQUEST_INIT_ACTION + "_SUCCESS";
export const ADD_WDREQUEST_FAIL_ACTION = ADD_WDREQUEST_INIT_ACTION + "_ERROR";

export const FETCH_WDREQUESTBYID_INIT_ACTION =
  "FETCH_WDREQUESTBYID_INIT_ACTION";
export const FETCH_WDREQUESTBYID_SUCCESS_ACTION =
  FETCH_WDREQUESTBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_WDREQUESTBYID_FAIL_ACTION =
  FETCH_WDREQUESTBYID_INIT_ACTION + "_ERROR";

export const EDIT_WDREQUEST_INIT_ACTION = "EDIT_WDREQUEST_INIT_ACTION";
export const EDIT_WDREQUEST_SUCCESS_ACTION =
  EDIT_WDREQUEST_INIT_ACTION + "_SUCCESS";
export const EDIT_WDREQUEST_FAIL_ACTION = EDIT_WDREQUEST_INIT_ACTION + "_ERROR";

export const FETCH_TOTALEARNINGS_INIT_ACTION =
  "FETCH_TOTALEARNINGS_INIT_ACTION";
export const FETCH_TOTALEARNINGS_SUCCESS_ACTION =
  FETCH_TOTALEARNINGS_INIT_ACTION + "_SUCCESS";
export const FETCH_TOTALEARNINGS_FAIL_ACTION =
  FETCH_TOTALEARNINGS_INIT_ACTION + "_ERROR";

export const FETCH_CREATEDEARNINGS_INIT_ACTION =
  "FETCH_CREATEDEARNINGS_INIT_ACTION";
export const FETCH_CREATEDEARNINGS_SUCCESS_ACTION =
  FETCH_CREATEDEARNINGS_INIT_ACTION + "_SUCCESS";
export const FETCH_CREATEDEARNINGS_FAIL_ACTION =
  FETCH_CREATEDEARNINGS_INIT_ACTION + "_ERROR";

export const FETCH_WDLIMIT_INIT_ACTION = "FETCH_WDLIMIT_INIT_ACTION";
export const FETCH_WDLIMIT_SUCCESS_ACTION =
  FETCH_WDLIMIT_INIT_ACTION + "_SUCCESS";
export const FETCH_WDLIMIT_FAIL_ACTION = FETCH_WDLIMIT_INIT_ACTION + "_ERROR";

export function fetchWdRequest(value) {
  return {
    type: FETCH_WDREQUEST_INIT_ACTION,
    value: value,
  };
}

export function fetchWdLimit(value) {
  return {
    type: FETCH_WDLIMIT_INIT_ACTION,
    value: value,
  };
}

export function AddWdRequest(value) {
  return {
    type: ADD_WDREQUEST_INIT_ACTION,
    value: value,
  };
}

export function getWdRequestById(value) {
  return {
    type: FETCH_WDREQUESTBYID_INIT_ACTION,
    value: value,
  };
}

export function EditWdRequest(value) {
  return {
    type: EDIT_WDREQUEST_INIT_ACTION,
    value: value,
  };
}

export function fetchTotalEarnings(value) {
  return {
    type: FETCH_TOTALEARNINGS_INIT_ACTION,
    value: value,
  };
}

export function fetchCreatedEarnings() {
  return {
    type: FETCH_CREATEDEARNINGS_INIT_ACTION,
  };
}
