import { put, takeEvery, call } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_WITHDRAWALLIMIT_INIT_ACTION,
  FETCH_WITHDRAWALLIMIT_SUCCESS_ACTION,
  FETCH_WITHDRAWALLIMIT_FAIL_ACTION,
  ADD_WITHDRAWALLIMIT_INIT_ACTION,
  ADD_WITHDRAWALLIMIT_SUCCESS_ACTION,
  ADD_WITHDRAWALLIMIT_FAIL_ACTION,
  EDIT_WITHDRAWALLIMIT_INIT_ACTION,
  EDIT_WITHDRAWALLIMIT_SUCCESS_ACTION,
  EDIT_WITHDRAWALLIMIT_FAIL_ACTION,
  DELETE_WITHDRAWALLIMIT_INIT_ACTION,
  DELETE_WITHDRAWALLIMIT_SUCCESS_ACTION,
  DELETE_WITHDRAWALLIMIT_FAIL_ACTION,
} from "./action";
import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var btoa = require("Base64").btoa;
var history = createBrowserHistory();

function* fetchWithdrawalLimit(value) {
  let pageno = value.value === undefined ? 1 : value.value;
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licenseeId = userDetails.licenseeId;
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/wdrlLimit?limit=10&licenseeId=${licenseeId}&page=` +
        pageno +
        "&isActive=true",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_WITHDRAWALLIMIT_SUCCESS_ACTION,
        payload: resJSON,
      });
      return resJSON.rows;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_WITHDRAWALLIMIT_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* addWithdrawalLimit(value) {
  const wdLimitList = yield call(fetchWithdrawalLimit, { value: 1 });

  const result = wdLimitList.find(({ role }) => role === value.value.role);

  if (result) {
    yield call(editWithdrawalLimit, {
      value: {
        id: result.id,
        role: value.value.role,
        limit: value.value.limit,
      },
    });
  } else {
    try {
      const token = localStorage.getItem("sStraitToken");

      let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));

      const data = {
        role: value.value.role,
        limit: value.value.limit,
        isActive: true,
        licenseeId: userDtls.licenseeId,
      };

      const res = yield fetch(`${appConfig.ip}/wdrlLimit`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) {
        let errJSON = {};
        try {
          errJSON = yield res.json();
        } catch {
          throw Object.assign(res, errJSON);
        }
      } else {
        const resJSON = yield res.json();

        yield put({
          type: ADD_WITHDRAWALLIMIT_SUCCESS_ACTION,
          payload: resJSON,
        });
        yield toast.success("Withdrawal limit added successfully", {
          autoClose: 3000,
        });
        yield put({
          type: FETCH_WITHDRAWALLIMIT_INIT_ACTION,
        });
      }
    } catch (err) {
      if (err.ok === false) {
        yield put({
          type: ADD_WITHDRAWALLIMIT_FAIL_ACTION,
          error: err,
        });
      }
    }
  }
}

function* editWithdrawalLimit(value) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let id = value.value.id;
  let data = {
    role: value.value.role,
    limit: value.value.limit,
    isActive: true,
    licenseeId: userDtls.licenseeId,
  };

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/wdrlLimit/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      // yield put({ type: FETCH_WITHDRAWALLIMIT_INIT_ACTION });
      yield toast.success("Withdrawal limit updated successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();
      yield put({
        type: EDIT_WITHDRAWALLIMIT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EDIT_WITHDRAWALLIMIT_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* deleteWithdrawalLimit(value) {
  let id = value.value.id;

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/wdrlLimit/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield put({
        type: DELETE_WITHDRAWALLIMIT_SUCCESS_ACTION,
        payload: id,
      });
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_WITHDRAWALLIMIT_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* WithdrawalLimitActionWatcher() {
  yield takeEvery(FETCH_WITHDRAWALLIMIT_INIT_ACTION, fetchWithdrawalLimit);

  yield takeEvery(ADD_WITHDRAWALLIMIT_INIT_ACTION, addWithdrawalLimit);
  yield takeEvery(EDIT_WITHDRAWALLIMIT_INIT_ACTION, editWithdrawalLimit);
  yield takeEvery(DELETE_WITHDRAWALLIMIT_INIT_ACTION, deleteWithdrawalLimit);
}
