import * as React from "react";
import { connect } from "react-redux";

import { logOut } from "../LoginContainer/actions";
import Expired from "../../screens/Expired/Expired";

class ExpiredContainer extends React.Component<Props, State> {
  signOut = (e) => {
    e.preventDefault();
    this.props.logout();
    this.props.history.push("/login");
  };

  render() {
    return (
      <Expired
        logoutaction={(e) => this.signOut(e)}
        where={
          (this.props.location &&
            this.props.location.state &&
            this.props.location.state.from) ||
          null
        }
      />
    );
  }
}
function bindAction(dispatch) {
  return {
    logout: () => {
      dispatch(logOut());
      //alert("heello");
    },
  };
}
const mapStateToProps = (state) => {
  return {
    //states:state.UpdateProfileReducer.states,
  };
};

export default connect(mapStateToProps, bindAction)(ExpiredContainer);
