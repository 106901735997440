export const FETCH_REGION_INIT_ACTION = "FETCH_REGION_INIT_ACTION";
export const FETCH_REGION_SUCCESS_ACTION =
  FETCH_REGION_INIT_ACTION + "_SUCCESS";
export const FETCH_REGION_FAIL_ACTION = FETCH_REGION_INIT_ACTION + "_ERROR";

export const ADD_REGION_INIT_ACTION = "ADD_REGION_INIT_ACTION";
export const ADD_REGION_SUCCESS_ACTION =
  ADD_REGION_INIT_ACTION + "_SUCCESS";
export const ADD_REGION_FAIL_ACTION = ADD_REGION_INIT_ACTION + "_ERROR";


export const EDIT_REGION_INIT_ACTION = "EDIT_REGION_INIT_ACTION";
export const EDIT_REGION_SUCCESS_ACTION =
  EDIT_REGION_INIT_ACTION + "_SUCCESS";
export const EDIT_REGION_FAIL_ACTION = EDIT_REGION_INIT_ACTION + "_ERROR";

export const FETCH_STATELIST_INIT_ACTION = "FETCH_STATELIST_INIT_ACTION";
export const FETCH_STATELIST_SUCCESS_ACTION =
  FETCH_STATELIST_INIT_ACTION + "_SUCCESS";
export const FETCH_STATELIST_FAIL_ACTION =
  FETCH_STATELIST_INIT_ACTION + "_ERROR";

export const DELETE_REGION_INIT_ACTION = "DELETE_REGION_INIT_ACTION";
export const DELETE_REGION_SUCCESS_ACTION =
  DELETE_REGION_INIT_ACTION + "_SUCCESS";
export const DELETE_REGION_FAIL_ACTION =
  DELETE_REGION_INIT_ACTION + "_ERROR";


export const FETCH_REGION_DETAIL_INIT_ACTION = "FETCH_REGION_DETAIL_INIT_ACTION";
export const FETCH_REGION_DETAIL_SUCCESS_ACTION =
  FETCH_REGION_DETAIL_INIT_ACTION + "_SUCCESS";
export const FETCH_REGION_DETAIL_FAIL_ACTION = FETCH_REGION_DETAIL_INIT_ACTION + "_ERROR";


// export const SEARCH_REGION_INIT_ACTION = "SEARCH_REGION_INIT_ACTION";
// export const SEARCH_REGION_SUCCESS_ACTION =
//   SEARCH_REGION_INIT_ACTION + "_SUCCESS";
// export const SEARCH_REGION_FAIL_ACTION = SEARCH_REGION_INIT_ACTION + "_ERROR";


export const FETCH_REGION_CLEAR_ACTION = "FETCH_REGION_CLEAR_ACTION";

export function fetchRegion(value) {
  return {
    type: FETCH_REGION_INIT_ACTION,
    value:value
  };
}

// export function searchRegion(value) {
//   return {
//     type: SEARCH_REGION_INIT_ACTION,
//     value: value
//   };
// }


export function addRegion(value) {
  return {
    type: ADD_REGION_INIT_ACTION,
    value: value
  };
}


export function editRegion(value) {
  return {
    type: EDIT_REGION_INIT_ACTION,
    value: value
  };
}




export function fetchStateList(value) {
  return {
    type: FETCH_STATELIST_INIT_ACTION,
    value: value
  };
}


export function deleteRegion(value) {
  return {
    type: DELETE_REGION_INIT_ACTION,
    value: value,
  };
}


// export function regionPageSelected(value) {
//   return {
//     type: FETCH_REGION_INIT_ACTION,
//     value: value
//   }
// }


export function clearRegion() {
  return {
    type: FETCH_REGION_CLEAR_ACTION,
  };
}

export function fetchRegionDetail(value) {
  return {
    type: FETCH_REGION_DETAIL_INIT_ACTION,
    value: value
  };
}
