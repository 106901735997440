export const FETCH_DISORDERS_INIT_ACTION = "FETCH_DISORDERS_INIT_ACTION";
export const FETCH_DISORDERS_SUCCESS_ACTION =
  FETCH_DISORDERS_INIT_ACTION + "_SUCCESS";
export const FETCH_DISORDERS_FAIL_ACTION =
  FETCH_DISORDERS_INIT_ACTION + "_ERROR";

export const ADD_ORDERS_INIT_ACTION = "ADD_ORDERS_INIT_ACTION";
export const ADD_ORDERS_SUCCESS_ACTION = ADD_ORDERS_INIT_ACTION + "_SUCCESS";
export const ADD_ORDERS_FAIL_ACTION = ADD_ORDERS_INIT_ACTION + "_ERROR";

export const CHANGE_DISORDERSTATUS_INIT_ACTION =
  "CHANGE_DISORDERSTATUS_INIT_ACTION";
export const CHANGE_DISORDERSTATUS_SUCCESS_ACTION =
  CHANGE_DISORDERSTATUS_INIT_ACTION + "_SUCCESS";
export const CHANGE_DISORDERSTATUS_FAIL_ACTION =
  CHANGE_DISORDERSTATUS_INIT_ACTION + "_ERROR";

export const PAYAMOUNT_DISORDER_INIT_ACTION = "PAYAMOUNT_DISORDER_INIT_ACTION";
export const PAYAMOUNT_DISORDER_SUCCESS_ACTION =
  PAYAMOUNT_DISORDER_INIT_ACTION + "_SUCCESS";
export const PAYAMOUNT_DISORDER_FAIL_ACTION =
  PAYAMOUNT_DISORDER_INIT_ACTION + "_ERROR";

export const CHANGE_DISORDERAPPROVE_INIT_ACTION =
  "CHANGE_DISORDERAPPROVE_INIT_ACTION";
export const CHANGE_DISORDERAPPROVE_SUCCESS_ACTION =
  CHANGE_DISORDERAPPROVE_INIT_ACTION + "_SUCCESS";
export const CHANGE_DISORDERAPPROVE_FAIL_ACTION =
  CHANGE_DISORDERAPPROVE_INIT_ACTION + "_ERROR";

export const CUSTOMER_DISCOUNTBYID_INIT_ACTION =
  "CUSTOMER_DISCOUNTBYID_INIT_ACTION";
export const CUSTOMER_DISCOUNTBYID_SUCCESS_ACTION =
  CUSTOMER_DISCOUNTBYID_INIT_ACTION + "_SUCCESS";
export const CUSTOMER_DISCOUNTBYID_FAIL_ACTION =
  CUSTOMER_DISCOUNTBYID_INIT_ACTION + "_ERROR";

export const EXPORT_ORDER_DATA_INIT_ACTION = "EXPORT_ORDER_DATA_INIT_ACTION";
export const EXPORT_ORDER_DATA_SUCCESS_ACTION =
  EXPORT_ORDER_DATA_INIT_ACTION + "_SUCCESS";
export const EXPORT_ORDER_DATA_FAIL_ACTION =
  EXPORT_ORDER_DATA_INIT_ACTION + "_ERROR";

export const FETCH_CUSTOMER_BY_ORDER_INIT_ACTION =
  "FETCH_CUSTOMER_BY_ORDER_INIT_ACTION";
export const FETCH_CUSTOMER_BY_ORDER_SUCCESS_ACTION =
  FETCH_CUSTOMER_BY_ORDER_INIT_ACTION + "_SUCCESS";
export const FETCH_CUSTOMER_BY_ORDER_FAIL_ACTION =
  FETCH_CUSTOMER_BY_ORDER_INIT_ACTION + "_ERROR";

export const FETCH_INVENTORYBYPRODUCT_INIT_ACTION =
  "FETCH_INVENTORYBYPRODUCT_INIT_ACTION";
export const FETCH_INVENTORYBYPRODUCT_SUCCESS_ACTION =
  FETCH_INVENTORYBYPRODUCT_INIT_ACTION + "_SUCCESS";
export const FETCH_INVENTORYBYPRODUCT_FAIL_ACTION =
  FETCH_INVENTORYBYPRODUCT_INIT_ACTION + "_ERROR";

export const FETCH_VARIANTFIELD_INIT_ACTION = "FETCH_VARIANTFIELD_INIT_ACTION";

export const FETCH_TOTAL_AMOUNT_BY_ORDER_INIT_ACTION =
  "FETCH_TOTAL_AMOUNT_BY_ORDER_INIT_ACTION";
export const FETCH_TOTAL_AMOUNT_BY_ORDER_SUCCESS_ACTION =
  FETCH_TOTAL_AMOUNT_BY_ORDER_INIT_ACTION + "_SUCCESS";
export const FETCH_TOTAL_AMOUNT_BY_ORDER_FAIL_ACTION =
  FETCH_TOTAL_AMOUNT_BY_ORDER_INIT_ACTION + "_ERROR";

export const POST_PRODUCTLIST_INIT_ACTION = "POST_PRODUCTLIST_INIT_ACTION";

export const ADD_PRODUCT_RETURN_INIT_ACTION = "ADD_PRODUCT_RETURN_INIT_ACTION";
export const ADD_PRODUCT_RETURN_SUCCESS_ACTION =
  ADD_PRODUCT_RETURN_INIT_ACTION + "_SUCCESS";
export const ADD_PRODUCT_RETURN_FAIL_ACTION =
  ADD_PRODUCT_RETURN_INIT_ACTION + "_ERROR";

export const CLEAR_QOUTEPRODUCT_INIT_ACTION = "CLEAR_QOUTEPRODUCT_INIT_ACTION";

export const GET_RETURN_BY_ORDER_ID_INIT_ACTION =
  "GET_RETURN_BY_ORDER_ID_INIT_ACTION";
export const GET_RETURN_BY_ORDER_ID_SUCCESS_ACTION =
  GET_RETURN_BY_ORDER_ID_INIT_ACTION + "_SUCCESS";
export const GET_RETURN_BY_ORDER_ID_FAIL_ACTION =
  GET_RETURN_BY_ORDER_ID_INIT_ACTION + "_ERROR";

export const FETCH_CUST_CREDITNOTES_INIT_ACTION =
  "FETCH_CUST_CREDITNOTES_INIT_ACTION";
export const FETCH_CUST_CREDITNOTES_SUCCESS_ACTION =
  FETCH_CUST_CREDITNOTES_INIT_ACTION + "_SUCCESS";
export const FETCH_CUST_CREDITNOTES_FAIL_ACTION =
  FETCH_CUST_CREDITNOTES_INIT_ACTION + "_ERROR";

export const EDIT_ORDERS_INIT_ACTION = "EDIT_ORDERS_INIT_ACTION";
export const EDIT_ORDERS_SUCCESS_ACTION = EDIT_ORDERS_INIT_ACTION + "_SUCCESS";
export const EDIT_ORDERS_FAIL_ACTION = EDIT_ORDERS_INIT_ACTION + "_ERROR";

export const FETCH_SALESEXECUTIVE_BY_ORDER_INIT_ACTION =
  "FETCH_SALESEXECUTIVE_BY_ORDER_INIT_ACTION";
export const FETCH_SALESEXECUTIVE_BY_ORDER_SUCCESS_ACTION =
  FETCH_SALESEXECUTIVE_BY_ORDER_INIT_ACTION + "_SUCCESS";
export const FETCH_SALESEXECUTIVE_BY_ORDER_FAIL_ACTION =
  FETCH_SALESEXECUTIVE_BY_ORDER_INIT_ACTION + "_ERROR";

export const ADD_PRODUCT_REPLACE_INIT_ACTION =
  "ADD_PRODUCT_REPLACE_INIT_ACTION";
export const ADD_PRODUCT_REPLACE_SUCCESS_ACTION =
  ADD_PRODUCT_REPLACE_INIT_ACTION + "_SUCCESS";
export const ADD_PRODUCT_REPLACE_FAIL_ACTION =
  ADD_PRODUCT_REPLACE_INIT_ACTION + "_ERROR";

export const FETCH_INVENTARY_OF_PRODUCT_INIT_ACTION =
  "FETCH_INVENTARY_OF_PRODUCT_INIT_ACTION";
export const FETCH_INVENTARY_OF_PRODUCT_SUCCESS_ACTION =
  FETCH_INVENTARY_OF_PRODUCT_INIT_ACTION + "_SUCCESS";
export const FETCH_INVENTARY_OF_PRODUCT_FAIL_ACTION =
  FETCH_INVENTARY_OF_PRODUCT_INIT_ACTION + "_ERROR";
