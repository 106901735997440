import { put, takeEvery, select, call, all } from "redux-saga/effects";
// import { createBrowserHistory } from "history";
import {
  FETCH_SUPPORT_INIT_ACTION,
  FETCH_SUPPORT_SUCCESS_ACTION,
  FETCH_SUPPORT_FAIL_ACTION,
  FETCH_SUPPORTTYPE_INIT_ACTION,
  FETCH_SUPPORTTYPE_SUCCESS_ACTION,
  FETCH_SUPPORTTYPE_FAIL_ACTION,
  ADD_SUPPORT_INIT_ACTION,
  ADD_SUPPORT_SUCCESS_ACTION,
  ADD_SUPPORT_FAIL_ACTION,
  EDIT_SUPPORT_INIT_ACTION,
  EDIT_SUPPORT_SUCCESS_ACTION,
  EDIT_SUPPORT_FAIL_ACTION,
  DELETE_SUPPORT_INIT_ACTION,
  DELETE_SUPPORT_SUCCESS_ACTION,
  DELETE_SUPPORT_FAIL_ACTION,
  FETCH_SUPPORTBYID_INIT_ACTION,
  FETCH_SUPPORTBYID_SUCCESS_ACTION,
  FETCH_SUPPORTBYID_FAIL_ACTION,
} from "./action";
import { appConfig } from "../../config";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

function* fetchSupport(value) {
  let pageno = value.value === undefined ? 1 : value.value;
  const token = localStorage.getItem("sStraitToken");
  const userId = localStorage.getItem("sStraitUserId");

  try {
    const res = yield fetch(
      `${appConfig.ip}/support?userId=${userId}&limit=10&page=` +
        pageno +
        "&isActive=true",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_SUPPORT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_SUPPORT_FAIL_ACTION, error: err });
    } else {
    }
  }
}
function* fetchsupportType(value) {
  let pageno = value.value === undefined ? 1 : value.value;
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/supportType?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_SUPPORTTYPE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_SUPPORTTYPE_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* AddSupport(value) {
  const token = localStorage.getItem("sStraitToken");
  const userId = localStorage.getItem("sStraitUserId");
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  try {
    const data = {
      sprtType: value.value.sprtType,
      issueDesc: value.value.issueDesc,
      isActive: value.value.isActive,
      userId: userId,
      channelPartner: licData.channelPartner ? licData.channelPartner.id : null,
    };

    const res = yield fetch(`${appConfig.ip}/support`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
    } else {
      const resJSON = yield res.json();

      yield put({ type: FETCH_SUPPORT_INIT_ACTION });
      yield put({
        type: ADD_SUPPORT_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Support Type added successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_SUPPORT_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* EditSupport(value) {
  try {
    const token = localStorage.getItem("sStraitToken");

    const data = {
      sprtType: value.value.sprtType,
      issueDesc: value.value.issueDesc,
      isActive: value.value.isActive,
    };

    const res = yield fetch(`${appConfig.ip}/support/` + value.value.id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
    } else {
      const resJSON = yield res.json();

      const [supportId] = yield all([
        call(getSupportById, { value: resJSON.id }),
      ]);

      yield put({
        type: EDIT_SUPPORT_SUCCESS_ACTION,
        payload: supportId,
      });
      yield toast.success("Support Type updated successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: EDIT_SUPPORT_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* getSupportById(value) {
  const token = localStorage.getItem("sStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/support/` + value.value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_SUPPORTBYID_SUCCESS_ACTION,
        payload: resJSON,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_SUPPORTBYID_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* DeleteSupport(value) {
  let id = value.value.id;

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/support/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      // yield put({ type: FETCH_SUPPORT_INIT_ACTION });
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
      // const resJSON = yield res.json();

      yield put({
        type: DELETE_SUPPORT_SUCCESS_ACTION,
        payload: id,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_SUPPORT_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* SupportActionWatcher() {
  yield takeEvery(FETCH_SUPPORT_INIT_ACTION, fetchSupport);
  yield takeEvery(FETCH_SUPPORTTYPE_INIT_ACTION, fetchsupportType);
  yield takeEvery(FETCH_SUPPORTBYID_INIT_ACTION, getSupportById);
  yield takeEvery(ADD_SUPPORT_INIT_ACTION, AddSupport);
  yield takeEvery(EDIT_SUPPORT_INIT_ACTION, EditSupport);
  yield takeEvery(DELETE_SUPPORT_INIT_ACTION, DeleteSupport);
}
