export const FETCH_EDIT_INIT_ACTION =
  "FETCH_EDIT_INIT_ACTION";
export const FETCH_EDIT_SUCCESS_ACTION =
  FETCH_EDIT_INIT_ACTION + "_SUCCESS";
export const FETCH_EDIT_FAIL_ACTION =
  FETCH_EDIT_INIT_ACTION + "_ERROR";

export const EDIT_PROF_INIT_ACTION =
  "EDIT_PROF_INIT_ACTION";
export const EDIT_PROF_SUCCESS_ACTION =
  EDIT_PROF_INIT_ACTION + "_SUCCESS";
export const EDIT_PROF_FAIL_ACTION =
  EDIT_PROF_INIT_ACTION + "_ERROR";

export const FETCH_COUNTRY_DROPDOWN_IN_LICENSE_INIT_ACTION =
  "FETCH_COUNTRY_DROPDOWN_IN_LICENSE_INIT_ACTION";
export const FETCH_COUNTRY_DROPDOWN_IN_LICENSE_SUCCESS_ACTION =
  FETCH_COUNTRY_DROPDOWN_IN_LICENSE_INIT_ACTION + "_SUCCESS";
export const FETCH_COUNTRY_DROPDOWN_IN_LICENSE__FAIL_ACTION =
  FETCH_COUNTRY_DROPDOWN_IN_LICENSE_INIT_ACTION + "_ERROR";

export const FETCH_STATE_DROPDOWN_IN_LICENSE_INIT_ACTION =
  "FETCH_STATE_DROPDOWN_IN_LICENSE_INIT_ACTION";
export const FETCH_STATE_DROPDOWN_IN_LICENSE_SUCCESS_ACTION =
  FETCH_STATE_DROPDOWN_IN_LICENSE_INIT_ACTION + "_SUCCESS";
export const FETCH_STATE_DROPDOWN_IN_LICENSE_FAIL_ACTION =
  FETCH_STATE_DROPDOWN_IN_LICENSE_INIT_ACTION + "_ERROR";

export const FETCH_DISTRICT_DROPDOWN_IN_LICENSE_INIT_ACTION =
  "FETCH_DISTRICT_DROPDOWN_IN_LICENSE_INIT_ACTION";
export const FETCH_DISTRICT_DROPDOWN_IN_LICENSE_SUCCESS_ACTION =
  FETCH_DISTRICT_DROPDOWN_IN_LICENSE_INIT_ACTION + "_SUCCESS";
export const FETCH_DISTRICT_DROPDOWN_IN_LICENSE_FAIL_ACTION =
  FETCH_DISTRICT_DROPDOWN_IN_LICENSE_INIT_ACTION + "_ERROR";


export function fetchEdit(value) {
  return {
    type: FETCH_EDIT_INIT_ACTION,
    value: value
  };
}

export function Editprof(value) {
  return {
    type: EDIT_PROF_INIT_ACTION,
    value: value
  };
}


export function fetchCntryCrnvy() {
  return {
    type: FETCH_COUNTRY_DROPDOWN_IN_LICENSE_INIT_ACTION,
  };
}

export function fetchStates(value) {
  return {
    type: FETCH_STATE_DROPDOWN_IN_LICENSE_INIT_ACTION,
    value: value,
  };
}

export function fetchDistrictsDropDown(value) {
  return {
    type: FETCH_DISTRICT_DROPDOWN_IN_LICENSE_INIT_ACTION,
    value: value,
  };
}
