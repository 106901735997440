import React, { Component } from "react";
import { Route, Switch, HashRouter, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";

import Login from "../containers/LoginContainer";
import ForgotPassword from "../containers/ForgotPasswordContainer";

import DefaultLayout from "../containers/DefaultLayout";
import Expired from "../containers/ExpiredContainer";
import ImportProd from "../containers/ImportProdContainer";
import Signup from "../containers/SignupContainer";
import Pending from "../containers/StatusPendingContainer";

var history = createBrowserHistory();
// const Login = React.lazy(() => import('../components/Pages/Login'));
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("sStraitToken") ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

function AppNavigator() {
  return (
    <HashRouter basename="/">
      <React.Suspense>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            path="/forgotpassword"
            name="ForgotPassword"
            component={ForgotPassword}
          />
          <Route path="/signup" name="Signup" component={Signup} />
          <Route path="/resetpassword" name="reset" component={DefaultLayout} />
          <Route path="/Expired" name="Expired" component={Expired} />
          <Route path="/Pending" name="Expired" component={Pending} />
          {/* <Route path="/ImportProd" name="ImportProd" component={ImportProd} /> */}

          <Route
            path="/"
            name="Home"
            render={(props) => <DefaultLayout {...props} />}
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}
export default AppNavigator;
