import * as actionTypes from "./constant";

const initialState = {
  processing: false,
  error: false,
};

export default function agentReportReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_USER_REPORT_INIT_ACTION:
      return {
        ...state,
        agentReportList: [],
        agentReportCount: [],
      };
    case actionTypes.FETCH_USER_REPORT_REPORT_SUCCESS_ACTION:
      return {
        ...state,
        agentReportList: action.payload.rows,
        agentReportCount: action.payload.count,
      };
    case actionTypes.FETCH_USER_REPORT_TOTAL_INIT_ACTION:
      return {
        ...state,
        userReportTotalList: [],
        userReportTotalCount: [],
      };
    case actionTypes.FETCH_USER_REPORT_TOTAL_SUCCESS_ACTION:
      return {
        ...state,
        userReportTotalList: action.payload,
        userReportTotalCount: action.payload.count,
      };

    default:
      return {
        ...state,
      };
  }
}
