import * as actionTypes from "./contstants";
// import { sortData } from "../../screens/Config/utils";
const initialState = {
  error: false,
  countryList: [],
  stateList: [],
  districtList: [],
  bankList: [],
};

export default function signUpReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_COUNTRY_IN_SIGNUP_SUCCESS_ACTION:
      return {
        ...state,
        countryList: action.payload.rows,
      };
    case actionTypes.FETCH_STATE_IN_SIGNUP_SUCCESS_ACTION:
      return {
        ...state,
        stateList: action.payload.rows,
      };
    case actionTypes.FETCH_DISTRICT_IN_SIGNUP_SUCCESS_ACTION:
      return {
        ...state,
        districtList: action.payload.rows,
      };
    case actionTypes.FETCH_BANK_IN_SIGNUP_SUCCESS_ACTION:
      return {
        ...state,
        bankList: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
