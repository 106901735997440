import { initial } from "lodash";
import * as actionTypes from "./constants";
let initialState = {
  salesRetunList: [],
  salesRetunByIdList: {},
};

export default function salesRetunReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_SALES_RETURN_SUCCESS_ACTION:
      return {
        ...state,
        salesRetunList: action.payload.rows,
        salesRetunCount: action.payload.count,
      };
    case actionTypes.FETCH_SALES_RETURN_BY_ID_SUCCESS_ACTION:
      return {
        ...state,
        salesRetunByIdList: action.payload,
      };
    case actionTypes.APPROVE_SALES_RETURN_SUCCESS_ACTION:
      return {
        ...state,
        salesRetunList: state.salesRetunList.map((item) =>
          item.id === action.payload.id
            ? { ...item, status: action.payload.status }
            : item
        ),
      };
    default:
      return {
        ...state,
      };
  }
}
