import {
  FETCH_USERS_SUCCESS_ACTION,
  FETCH_USERS_INIT_ACTION,
  EDIT_USERS_SUCCESS_ACTION,
  FETCH_COUNTRY_SUCCESS_ACTION,
  FETCH_USERS_REGION_SUCCESS_ACTION,
  FETCH_USERS_ZONE_SUCCESS_ACTION,
  FETCH_USERS_AREA_SUCCESS_ACTION,
  CHANGE_USER_STATUS_SUCCESS_ACTION,
  DELETE_USER_SUCCESS_ACTION,
  RESET_SUCCESS_ACTION
  // FILTER_SEARCH_USER_SUCCESS_ACTION
} from "./action";

const initialState = {
  processing: true,
  error: false,
};

export default function RegionReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    case FETCH_USERS_INIT_ACTION:
      return {
        ...state,
        processing: true,
        error: false,
      };
    case FETCH_USERS_SUCCESS_ACTION:
      return {
        ...state,
        userList: action.payload.rows,
        userDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };

    // case FILTER_SEARCH_USER_SUCCESS_ACTION:
    //   return {
    //     ...state,
    //     userList: action.payload.rows,
    //     userDtlsCount: action.payload.count,
    //     processing: false,
    //     error: false,
    //   };
    case EDIT_USERS_SUCCESS_ACTION:
      return {
        ...state,
        userList: state.userList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };
    case FETCH_COUNTRY_SUCCESS_ACTION:
      return {
        ...state,
        countryList: action.payload.rows,

      };

    case FETCH_USERS_REGION_SUCCESS_ACTION:
      return {
        ...state,
        usersRegionsList: action.payload.rows,
        usersRegionsDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };

    case FETCH_USERS_ZONE_SUCCESS_ACTION:
      return {
        ...state,
        usersZonesList: action.payload.rows,
        usersZonesDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };

    case FETCH_USERS_AREA_SUCCESS_ACTION:
      return {
        ...state,
        usersAreasList: action.payload.rows,
        usersAreasDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };

    case CHANGE_USER_STATUS_SUCCESS_ACTION:

      return {
        ...state,
        userList: state.userList.map((user) =>
          user.id === action.payload.id ? { ...user, status: action.payload.status } : user
        ),
      };

    case DELETE_USER_SUCCESS_ACTION:

      return {
        ...state,
        userList: state.userList.filter(
          (item) => item.id !== action.payload
        ),
        userDtlsCount: state.userDtlsCount - 1
      };
      case RESET_SUCCESS_ACTION:

        return {
          ...state,
          userList: state.userList.map((user) =>
            user.id === action.payload.id ? { ...user, password: action.payload.password } : user
          ),

        };

    default:
      return {
        ...state,
      };
  }
}
