import * as actionTypes from "./constants";

const initialState = {
  processing: false,
  error: false,
  isModel: false,
  variantFieldList: [],
  qouteProductList: [],
  salesExcutiveList: [],
};

export default function DisOrderReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_DISORDERS_SUCCESS_ACTION:
      return {
        ...state,
        dis_OrderList: action.payload.rows,
        dis_OrderCount: action.payload.count,
      };

    case actionTypes.CHANGE_DISORDERSTATUS_SUCCESS_ACTION:
      return {
        ...state,
        dis_OrderList: state.dis_OrderList.map((order) =>
          order.id === action.payload.id
            ? { ...order, status: action.payload.status }
            : order
        ),
      };
    case actionTypes.PAYAMOUNT_DISORDER_SUCCESS_ACTION:
      return {
        ...state,
        dis_OrderList: state.dis_OrderList.map((order) =>
          order.id === action.payload.id ? action.payload : order
        ),
      };
    case actionTypes.CUSTOMER_DISCOUNTBYID_SUCCESS_ACTION:
      return {
        ...state,
        custDiscountDtls: action.payload.rows,
      };
    case actionTypes.FETCH_CUSTOMER_BY_ORDER_SUCCESS_ACTION:
      return {
        ...state,
        customerList: action.payload.rows,
      };
    case actionTypes.FETCH_INVENTORYBYPRODUCT_SUCCESS_ACTION:
      return {
        ...state,
        inventoryByIdList: action.payload.rows,
        variantFieldList: [],
      };

    case actionTypes.FETCH_TOTAL_AMOUNT_BY_ORDER_SUCCESS_ACTION:
      return {
        ...state,
        TotalAmountByOrder: action.payload.total,
      };
    case actionTypes.FETCH_VARIANTFIELD_INIT_ACTION:
      return {
        ...state,
        variantFieldList: state.inventoryByIdList.filter(
          (item) =>
            item.variants[action.key] === action.value.variants[action.key]
        ),
      };
    case actionTypes.POST_PRODUCTLIST_INIT_ACTION:
      return {
        ...state,
        qouteProductList: action.value,
      };
    case actionTypes.GET_RETURN_BY_ORDER_ID_SUCCESS_ACTION:
      return {
        ...state,
        retunByOrderData: action.payload.rows,
      };

    case actionTypes.CLEAR_QOUTEPRODUCT_INIT_ACTION:
      return {
        ...state,
        qouteProductList: [],
      };
    case actionTypes.FETCH_CUST_CREDITNOTES_SUCCESS_ACTION:
      return {
        ...state,
        creditNoteList: action.payload.rows,
      };
    case actionTypes.FETCH_SALESEXECUTIVE_BY_ORDER_SUCCESS_ACTION:
      return {
        ...state,
        salesExcutiveList: action.payload.rows,
      };
    default:
      return {
        ...state,
      };
  }
}
