export const ADD_SIGNUP_DATA_INIT_ACTION = " ADD_SIGNUP_DATA_INIT_ACTION";
export const ADD_SIGNUP_DATA_SUCCESS_ACTION =
  ADD_SIGNUP_DATA_INIT_ACTION + "SUCCESS";
export const ADD_SIGNUP_DATA_FAIL_ACTION = ADD_SIGNUP_DATA_INIT_ACTION + "FAIL";

export const FETCH_COUNTRY_IN_SIGNUP_INIT_ACTION =
  " FETCH_COUNTRY_IN_SIGNUP_INIT_ACTION";
export const FETCH_COUNTRY_IN_SIGNUP_SUCCESS_ACTION =
  FETCH_COUNTRY_IN_SIGNUP_INIT_ACTION + "SUCCESS";
export const FETCH_COUNTRY_IN_SIGNUP_FAIL_ACTION =
  FETCH_COUNTRY_IN_SIGNUP_INIT_ACTION + "FAIL";

export const FETCH_STATE_IN_SIGNUP_INIT_ACTION =
  " FETCH_STATE_IN_SIGNUP_INIT_ACTION";
export const FETCH_STATE_IN_SIGNUP_SUCCESS_ACTION =
  FETCH_STATE_IN_SIGNUP_INIT_ACTION + "SUCCESS";
export const FETCH_STATE_IN_SIGNUP_FAIL_ACTION =
  FETCH_STATE_IN_SIGNUP_INIT_ACTION + "FAIL";

export const FETCH_DISTRICT_IN_SIGNUP_INIT_ACTION =
  " FETCH_DISTRICT_IN_SIGNUP_INIT_ACTION";
export const FETCH_DISTRICT_IN_SIGNUP_SUCCESS_ACTION =
  FETCH_DISTRICT_IN_SIGNUP_INIT_ACTION + "SUCCESS";
export const FETCH_DISTRICT_IN_SIGNUP_FAIL_ACTION =
  FETCH_DISTRICT_IN_SIGNUP_INIT_ACTION + "FAIL";

export const FETCH_BANK_IN_SIGNUP_INIT_ACTION =
  " FETCH_BANK_IN_SIGNUP_INIT_ACTION";
export const FETCH_BANK_IN_SIGNUP_SUCCESS_ACTION =
  FETCH_BANK_IN_SIGNUP_INIT_ACTION + "SUCCESS";
export const FETCH_BANK_IN_SIGNUP_FAIL_ACTION =
  FETCH_BANK_IN_SIGNUP_INIT_ACTION + "FAIL";
