export const FETCH_DASHBOARD_INIT_ACTION = "FETCH_DASHBOARD_INIT_ACTION"
export const FETCH_DASHBOARD_SUCCESS_ACTION = FETCH_DASHBOARD_INIT_ACTION + '_SUCCESS'
export const FETCH_DASHBOARD_FAIL_ACTION = FETCH_DASHBOARD_INIT_ACTION + '_ERROR'

export const FETCH_USERSTATUS_INIT_ACTION = "FETCH_USERSTATUS_INIT_ACTION";
export const FETCH_USERSTATUS_SUCCESS_ACTION =
  FETCH_USERSTATUS_INIT_ACTION + "_SUCCESS";
export const FETCH_USERSTATUS_FAIL_ACTION = FETCH_USERSTATUS_INIT_ACTION + "_ERROR";

export const FETCH_LICENSEESTATUS_INIT_ACTION = "FETCH_LICENSEESTATUS_INIT_ACTION";
export const FETCH_LICENSEESTATUS_SUCCESS_ACTION =
  FETCH_LICENSEESTATUS_INIT_ACTION + "_SUCCESS";
export const FETCH_LICENSEESTATUS_FAIL_ACTION = FETCH_LICENSEESTATUS_INIT_ACTION + "_ERROR";

export const LOGOUTUSER_ACTION = "LOGOUT";


export function dashboardList() {
  
    return {
        type: FETCH_DASHBOARD_INIT_ACTION
    }
}

export function fetchUserStatus(value) {
    return {
      type: FETCH_USERSTATUS_INIT_ACTION,
      value: value,
  
    };
  }

  export function fetchLicenseeStatus(value) {
    return {
      type: FETCH_LICENSEESTATUS_INIT_ACTION,
      value: value,
  
    };
  }

  export function logOutUser() {
  
    return {
      type: LOGOUTUSER_ACTION,
    };
  }