import { put, takeEvery, call } from "redux-saga/effects";
import * as actionTypes from "./constants";

import { appConfig } from "../../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//common function
function* commonFunction(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();
      yield put({ type: `${value.initAction}` });
      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: `${value.failAction}`, error: err });
    } else {
    }
  }
}

function* fetchRoute(params) {
  let page = (params.params && params.params.page) || 1;

  let currentUser = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licensee = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  let role = localStorage.getItem("sStraitUserRole");

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/route?isActive=true&limit=10&page=${page}&licenseeId=${currentUser.licenseeId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_ROUTE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_ROUTE_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* addRoute(value) {
  let userData = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  let data = {
    licenseeId: (userData && userData.licenseeId) || null,
    name: (value.value && value.value.name) || "",
    description: (value.value && value.value.description) || "",
  };

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/route`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();
      yield put({
        type: actionTypes.ADD_ROUTE_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Route added successfully", {
        autoClose: 3000,
      });
      yield put({
        type: actionTypes.FETCH_ROUTE_INIT_ACTION,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.ADD_ROUTE_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* editRoute(value) {
  let userData = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  let id = value.id || "";
  let data = {
    licenseeId: (userData && userData.licenseeId) || null,
    name: (value.value && value.value.name) || "",
    description: (value.value && value.value.description) || "",
  };
  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/route/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield toast.success("Route edited successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();
      // yield put({ type: actionTypes.FETCH_EXPENSE_INIT_ACTION });
      // const resJSONById = yield call(fetchRouteById, resJSON.id);
      yield put({
        type: actionTypes.EDIT_ROUTE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.EDIT_ROUTE_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* changeStatusRoute(value) {
  let id = value.value.id;

  let data =
    value.value.status === "active" || value.value.status === "created"
      ? { status: "blocked" }
      : { status: "active" };

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/route/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      // yield put({ type: actionTypes.FETCH_ROUTE_INIT_ACTION });

      yield put({
        type: actionTypes.CHANGE_STATUS_ROUTE_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Status changed successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.CHANGE_STATUS_ROUTE_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}
// function* fetchRouteById(value) {
//   try {
//     let params = {
//       api: `${appConfig.ip}/route/${value}`,
//       method: "GET",
//       sucessAction: actionTypes.EDIT_ROUTE_SUCCESS_ACTION,
//       failAction: actionTypes.EDIT_ROUTE_FAIL_ACTION,
//       initAction: null,
//       body: null,
//     };
//     const res = yield call(commonFunction, params);
//   } catch (e) { }
// }

export function* customerrouteActionWatcher() {
  yield takeEvery(actionTypes.FETCH_ROUTE_INIT_ACTION, fetchRoute);
  yield takeEvery(actionTypes.ADD_ROUTE_INIT_ACTION, addRoute);
  yield takeEvery(actionTypes.EDIT_ROUTE_INIT_ACTION, editRoute);
  yield takeEvery(
    actionTypes.CHANGE_STATUS_ROUTE_INIT_ACTION,
    changeStatusRoute
  );
}
