import React from "react";
import { Button } from "react-bootstrap";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import FileSaver from "file-saver";
import XLSX from "xlsx";

const ImportXls = ({ fileName, xlsImport, hasDwnld }) => {
  // ******** XLSX with new header *************

  let role = localStorage.getItem("sStraitUserRole");
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  let networkHierarchy = licData.networkHierarchy;

  let isRole =
    role === "licensee" || (role === "distributor" && networkHierarchy)
      ? true
      : false;

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  let header = [];

  let noNetWorkHeader = [
    "name",
    "agency",
    "buyingPrice",
    "sellingPrice",
    "quantity",
    "ctgryId",
    "subCtgryId",
    "PrdtType",
    "company",
    "incentivesbucket",
    "unit",
    "gstSlab",
  ];
  let normalHeader = [
    "name",
    "agency",
    "buyingPrice",
    "sellingPrice",
    "quantity",
    "ctgryId",
    "subCtgryId",
    "PrdtType",
    "regionManger",
    "zoneManger",
    "areaManger",
    "salesExecutive",
    "company",
    "incentivesbucket",
    "unit",
    "gstSlab",
  ];
  if (isRole) {
    header = [...normalHeader];
  } else {
    header = [...noNetWorkHeader];
  }

  let Heading = [];
  const normalHeading = {
    name: "name",
    agency: "agency",
    buyingPrice: "buyingPrice",
    sellingPrice: "sellingPrice",
    quantity: "quantity",
    ctgryId: "ctgryId",
    subCtgryId: "subCtgryId",
    PrdtType: "PrdtType",
    regionManger: "regionManger",
    zoneManger: "zoneManger",
    areaManger: "areaManger",
    salesExecutive: "salesExecutive",
    company: "company",
    incentivesbucket: "incentivesbucket",
    unit: "unit",
    gstSlab: "gstSlab",
  };

  const noNetworkHeading = {
    name: "name",
    agency: "agency",
    buyingPrice: "buyingPrice",
    sellingPrice: "sellingPrice",
    quantity: "quantity",
    ctgryId: "ctgryId",
    subCtgryId: "subCtgryId",
    PrdtType: "PrdtType",
    company: "company",
    incentivesbucket: "incentivesbucket",
    unit: "unit",
    gstSlab: "gstSlab",
  };
  if (isRole) {
    Heading = { ...normalHeading };
  } else {
    Heading = { ...noNetworkHeading };
  }

  let wscols = [];
  for (var propt in Heading) {
    wscols.push({ wch: Math.max(Heading[propt].length) });
  }

  function fileHandler(event) {
    let fileObj = event.target.files[0];

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        // console.log(err);
      } else {
        xlsImport(resp);
      }
    });
  }

  const exportToCSV = (fileName) => {
    const ws = XLSX.utils.json_to_sheet([Heading], {
      header: header,
      skipHeader: true,
      origin: 0, //ok
    });
    ws["!cols"] = wscols;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div>
      {hasDwnld && (
        <div>
          <Button
            className="btn_cmn"
            style={{ marginBottom: "15px", float: "right", marginLeft: "15px" }}
            onClick={(e) => exportToCSV(fileName)}
          >
            Download Template
          </Button>
        </div>
      )}
      {/* <input type="file" onChange={e => fileHandler(e)} style={{ "padding": "10px" }} /> */}
    </div>
  );
};

export default ImportXls;

// This component is a presentational component which takes the data to download and file name as props. The exportToCSV method is invoked when the export button is clicked on line 20.
