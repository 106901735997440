import * as actionTypes from "./constants";

const initialState = {
  processing: false,
  error: false,
  userList: [],
  userCount: 0,
  routeList: [],
  userByIdList: {},
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_USER_SUCCESS_ACTION:
      return {
        ...state,
        userList: action.payload.rows,
        userCount: action.payload.count,
      };
    case actionTypes.FETCH_ROUTES_BY_USER_SUCCESS_ACTION:
      return {
        ...state,
        routeList: action.payload.rows,
      };
    case actionTypes.FETCH_USER_BY_ID_SUCCESS_ACTION:
      let routes = [];
      if (action.payload?.routes?.length) {
        action.payload.routes.map((route) =>
          routes.push({ label: route.name, value: route.id })
        );
      }
      return {
        ...state,
        userByIdList: { ...action.payload, routes: routes },
      };

    default:
      return {
        ...state,
      };
  }
}
