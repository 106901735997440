export const FETCH_USER_INIT_ACTION = "FETCH_USER_INIT_ACTION";
export const FETCH_USER_SUCCESS_ACTION = FETCH_USER_INIT_ACTION + "_SUCCESS";
export const FETCH_USER_FAIL_ACTION = FETCH_USER_INIT_ACTION + "_ERROR";

export const ADD_USER_INIT_ACTION = "ADD_USER_INIT_ACTION";
export const ADD_USER_SUCCESS_ACTION = ADD_USER_INIT_ACTION + "_SUCCESS";
export const ADD_USER_FAIL_ACTION = ADD_USER_INIT_ACTION + "_ERROR";

export const CHANGE_STATUS_USER_INIT_ACTION = "CHANGE_STATUS_USER_INIT_ACTION";
export const CHANGE_STATUS_USER_SUCCESS_ACTION =
  CHANGE_STATUS_USER_INIT_ACTION + "_SUCCESS";
export const CHANGE_STATUS_USER_FAIL_ACTION =
  CHANGE_STATUS_USER_INIT_ACTION + "_ERROR";

export const EDIT_USER_INIT_ACTION = "EDIT_USER_INIT_ACTION";
export const EDIT_USER_SUCCESS_ACTION = EDIT_USER_INIT_ACTION + "_SUCCESS";
export const EDIT_USER_FAIL_ACTION = EDIT_USER_INIT_ACTION + "_ERROR";

export const DELETE_USERS_INIT_ACTION = "DELETE_USERS_INIT_ACTION";
export const DELETE_USERS_SUCCESS_ACTION =
  DELETE_USERS_INIT_ACTION + "_SUCCESS";
export const DELETE_USERS_FAIL_ACTION = DELETE_USERS_INIT_ACTION + "_ERROR";

export const EXPORT_USERS_INIT_ACTION = "EXPORT_USERS_INIT_ACTION";
export const EXPORT_USERS_SUCCESS_ACTION =
  EXPORT_USERS_INIT_ACTION + "_SUCCESS";
export const EXPORT_USERS_FAIL_ACTION = EXPORT_USERS_INIT_ACTION + "_ERROR";

export const USER_RESET_INIT_ACTION = "USER_RESET_INIT_ACTION";
export const USER_RESET_SUCCESS_ACTION = USER_RESET_INIT_ACTION + "_SUCCESS";
export const USER_RESET_FAIL_ACTION = USER_RESET_INIT_ACTION + "_ERROR";

export const FETCH_ROUTES_BY_USER_INIT_ACTION =
  "FETCH_ROUTES_BY_USER_INIT_ACTION";
export const FETCH_ROUTES_BY_USER_SUCCESS_ACTION =
  FETCH_ROUTES_BY_USER_INIT_ACTION + "_SUCCESS";
export const FETCH_ROUTES_BY_USER_FAIL_ACTION =
  FETCH_ROUTES_BY_USER_INIT_ACTION + "_ERROR";

export const FETCH_USER_BY_ID_INIT_ACTION = "FETCH_USER_BY_ID_INIT_ACTION";
export const FETCH_USER_BY_ID_SUCCESS_ACTION =
  FETCH_USER_BY_ID_INIT_ACTION + "_SUCCESS";
export const FETCH_USER_BY_ID_FAIL_ACTION =
  FETCH_USER_BY_ID_INIT_ACTION + "_ERROR";
