import {
  FETCH_EARNINGS_SUCCESS_ACTION,
  FETCH_EARNINGS_INIT_ACTION,
  FETCH_EARNINGSBYID_INIT_ACTION,
  FETCH_EARNINGSBYID_SUCCESS_ACTION,
  FETCH_EARNINGSBYID_FAIL_ACTION,
  FETCH_AREA_DROP_DOWN_SUCCESS_ACTION,
  FETCH_EARNINGS_TOTAL_SUCCESS_ACTION,
  FETCH_EARNINGS_TOTAL_INIT_ACTION,
} from "./action";

const initialState = {
  processing: true,
  error: false,
  loading: true,
};

export default function earningsReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    case FETCH_EARNINGS_INIT_ACTION:
      return {
        ...state,
        processing: true,
        error: false,
      };
    case FETCH_EARNINGS_SUCCESS_ACTION:
      return {
        ...state,
        earningsList: action.payload.rows,
        earningsCount: action.payload.count,
        processing: false,
        error: false,
      };

    case FETCH_EARNINGSBYID_SUCCESS_ACTION:
      return {
        ...state,
        earningsDtls: action.payload,
        processing: false,
        error: false,
        loading: false,
      };

    case FETCH_AREA_DROP_DOWN_SUCCESS_ACTION:
      return {
        ...state,
        areaDropdownList: action.payload.rows,
      };
    case FETCH_EARNINGS_TOTAL_INIT_ACTION:
      return {
        ...state,
        EarningsTotalList: "",
        processing: false,
        error: false,
      };

    case FETCH_EARNINGS_TOTAL_SUCCESS_ACTION:
      return {
        ...state,
        EarningsTotalList: action.payload,
        processing: false,
        error: false,
      };

    default:
      return {
        ...state,
      };
  }
}
