import {
  FETCH_INVOICES_SUCCESS_ACTION,
  FETCH_INVOICES_INIT_ACTION,
  FETCH_INVOICESBYID_INIT_ACTION,
  FETCH_INVOICESBYID_SUCCESS_ACTION,
  FETCH_INVOICESBYID_FAIL_ACTION,
  // FETCH_AREAS__DROPDOWN_SUCCESS_ACTION
  FETCH_INVOICE_CONFIG_SUCCESS_ACTION,
} from "./action";

const initialState = {
  processing: true,
  error: false,
  loading: true,
};

export default function InvoicesReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    case FETCH_INVOICES_INIT_ACTION:
      return {
        ...state,
        processing: true,
        error: false,
      };
    case FETCH_INVOICES_SUCCESS_ACTION:
      return {
        ...state,
        invoicesList: action.payload.rows,
        invoicesCount: action.payload.count,
        processing: false,
        error: false,
      };

    case FETCH_INVOICESBYID_SUCCESS_ACTION:
      return {
        ...state,
        invoicesDtls: action.payload,
        processing: false,
        error: false,
        loading: false,
        resultImage: action.resultImage.base64,
      };
    case FETCH_INVOICE_CONFIG_SUCCESS_ACTION:
      return {
        ...state,
        invoicesConfigDtls: action.invoiceConfigData.rows,
        processing: false,
        error: false,
        loading: false,
        // resultImage: action.payload.base64,
      };

    default:
      return {
        ...state,
      };
  }
}
