import * as actionTypes from "./constant";
import { put, takeEvery, call } from "redux-saga/effects";
import { appConfig } from "../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* commonFunction(value, toastSuccess = false, toastError = true) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });

    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();

      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });

      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
    }
  } catch (error) {
    let message;
    if (error.status === 400) {
      message = "Bad Request - Field must not be empty";
    } else if (error.status === 401) {
      message = "Undefined";
    } else if (error.status === 403) {
      message = "Forbidden";
    } else if (error.status === 404) {
      message = "Not Found";
    } else if (error.status >= 500) {
      message = "Server Error";
    } else {
      message = "Unknown Error";
    }

    yield put({ type: `${value.failAction}`, error: error });

    if (toastError) {
      yield toast.error(`Error: ${message}`, { autoClose: 3000 });
    }

    throw error;
  }
}

function* getRoleApiUrl() {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const role = localStorage.getItem("sStraitUserRole");
  let getDataUrl = [
    { role: "distributor", url: "&licenseeId=" + userDtls.licenseeId },
    {
      role: "regionalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&region=" + userDtls.region,
    },
    {
      role: "zonalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&zone=" + userDtls.zone,
    },
    {
      role: "areaManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
    {
      role: "salesExcecutive",
      url: "&licenseeId=" + userDtls.licenseeId + "&initiator=" + userDtls.id,
    },
    {
      role: "agent",
      url: "&licenseeId=" + userDtls.licenseeId + "&initiator=" + userDtls.id,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);
  return apiUrl;
}

function* fetchProductReplaceDetailsById(value) {
  try {
    let page = (value.value && value.value.page) || 1;
    let limit = (value?.value?.limit && value?.value?.limit) || 10;
    let id = value.value && value.value.id;
    let params = {
      page: page,
      api: `${appConfig.ip}/salesReplace?initiator=${id}&limit=${limit}&page=${page}&isActive=true`,
      method: "GET",
      successAction: actionTypes.FETCH_PRODUCT_REPLACE_BY_ID_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_PRODUCT_REPLACE_BY_ID_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* fetchProductReplaceDetails(value) {
  const apiUrl = yield call(getRoleApiUrl);

  try {
    let page = (value.value && value.value.page) || 1;
    let limit = (value?.value?.limit && value?.value?.limit) || 10;
    let id = value.value && value.value.id;
    let params = {
      page: page,
      api: `${appConfig.ip}/salesReplace?limit=${limit}&page=${page}&isActive=true&${apiUrl.url}`,
      method: "GET",
      successAction: actionTypes.FETCH_PRODUCT_REPLACE_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_PRODUCT_REPLACE_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* fetchReplaceDataById(param) {
  let id = param?.id || null;

  try {
    let params = {
      api: `${appConfig.ip}/salesReplace/${id}`,
      method: "GET",
      successAction: actionTypes.FETCH_REPLACE_BY_ID_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_REPLACE_BY_ID_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

export function* ProductReplaceActionWatcher() {
  yield takeEvery(
    actionTypes.FETCH_PRODUCT_REPLACE_BY_ID_INIT_ACTION,
    fetchProductReplaceDetailsById
  );
  yield takeEvery(
    actionTypes.FETCH_PRODUCT_REPLACE_INIT_ACTION,
    fetchProductReplaceDetails
  );
  yield takeEvery(
    actionTypes.FETCH_REPLACE_BY_ID_INIT_ACTION,
    fetchReplaceDataById
  );
}
