import { put, takeEvery, call, all, fork } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  FETCH_ORDERS_INIT_ACTION,
  FETCH_ORDERS_SUCCESS_ACTION,
  FETCH_ORDERS_FAIL_ACTION,
  CREATE_ORDERS_INIT_ACTION,
  CREATE_ORDERS_SUCCESS_ACTION,
  CREATE_ORDERS_FAIL_ACTION,
  FETCH_ORDERBYID_INIT_ACTION,
  FETCH_ORDERBYID_SUCCESS_ACTION,
  FETCH_ORDERBYID_FAIL_ACTION,
  EDIT_ORDER_INIT_ACTION,
  EDIT_ORDER_SUCCESS_ACTION,
  EDIT_ORDER_FAIL_ACTION,
  FETCH_PAYMENTMODE_INIT_ACTION,
  FETCH_PAYMENTMODE_SUCCESS_ACTION,
  FETCH_PAYMENTMODE_FAIL_ACTION,
  CHANGE_ORDERSTATUS_INIT_ACTION,
  CHANGE_ORDERSTATUS_SUCCESS_ACTION,
  CHANGE_ORDERSTATUS_FAIL_ACTION,
  CHANGE_ORDERAPPROVE_INIT_ACTION,
  CHANGE_ORDERAPPROVE_SUCCESS_ACTION,
  CHANGE_ORDERAPPROVE_FAIL_ACTION,
  // FILTER_SEARCH_ORDER_INIT_ACTION,
  // FILTER_SEARCH_ORDER_SUCCESS_ACTION,
  // FILTER_SEARCH_ORDER_FAIL_ACTION,
  EXPORT_ORDER_INIT_ACTION,
  EXPORT_ORDER_SUCCESS_ACTION,
  EXPORT_ORDER_FAIL_ACTION,
  PAYAMOUNT_ORDER_INIT_ACTION,
  PAYAMOUNT_ORDER_SUCCESS_ACTION,
  PAYAMOUNT_ORDER_FAIL_ACTION,
  FETCH_TOTAL_AMOUNT_BY_ORDER_LICENSEE_INIT_ACTION,
  FETCH_TOTAL_AMOUNT_BY_ORDER_LICENSEE_SUCCESS_ACTION,
  FETCH_TOTAL_AMOUNT_BY_ORDER_LICENSEE_FAIL_ACTION,
} from "./action";
import { CHANGE_ORDERTOLEADSTATUS_SUCCESS_ACTION } from "../LeadsContainer/action";
import { CHANGE_QUOTATION_STATUS_SUCCESS_ACTION } from "../QuotationsContainer/action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
var dateFormat = require("dateformat");

// import {getDataUrl} from "../../service/roleByUrl"

// common role wise url get
function* getRoleApiUrl() {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const role = localStorage.getItem("sStraitUserRole");
  let getDataUrl = [
    { role: "licensee", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "licenseeAccountant", url: "&licenseeId=" + userDtls.licenseeId },
    {
      role: "regionalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&region=" + userDtls.region,
    },
    {
      role: "zonalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&zone=" + userDtls.zone,
    },
    {
      role: "areaManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
    {
      role: "salesExcecutive",
      url:
        "&licenseeId=" + userDtls.licenseeId + "&salesExecutive=" + userDtls.id,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);
  return apiUrl;
}

function* fetchOrders(value) {
  let pageno = value.value.page === undefined ? 1 : value.value.page;
  const token = localStorage.getItem("sStraitToken");

  const apiUrl = yield call(getRoleApiUrl);
  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? null
      : value.value.searchVal;
  let filter =
    !value || !value.value || !value.value.filter ? null : value.value.filter;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let filterValDate =
    !value || !value.value || !value.value.filterValDate
      ? null
      : value.value.filterValDate;

  let _url = `${appConfig.ip}/order?limit=10&page=${pageno}${apiUrl.url}`;

  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }
  if (filter) {
    _url = `${_url}&product=${filter}`;
  }
  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  }
  if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  }
  if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }
  if (filterValDate) {
    _url = `${_url}${filterValDate}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_ORDERS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_ORDERS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

// ADD Order

function* createOrder(value) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  // const gstVal = (value.value.sellingPrice * value.value.gstSlab.prcntg) / 100;
  // const total = (value.value.sellingPrice + gstVal) * value.value.quantity;
  const gstVal = value.value.gstAmt;
  const total = value.value.totalAmnt;
  const cmsn = value.value.commission;
  const payStatus = value.value.payStatus;

  let role = userDtls.role;

  const data = {
    initiator: role,
    customer: value.value.customer[0].id,
    shippingAddress: value.value.shippingAddress,
    currency: value.value.currency,
    description: value.value.description,
    product: value.value.products[0].id,
    quantity: value.value.quantity,
    unit: value.value.unit,
    totalPrice: total,
    salesExecutive: userDtls.id,
    licenseeId: userDtls.licenseeId,
    paymentMode: value.value.paymentMode[0].id,
    sellingPrice: value.value.sellingPrice,
    buyingPrice: value.value.buyingPrice,
    // commission: value.value.commission,
    // gstSlab: value.value.products[0].gstSlab.id,
    gstSlab: value.value.gstSlab.id,
    gstAmount: gstVal,
    lead: value.value.lead ? value.value.lead : null,
    quotation: value.value.quotation ? value.value.quotation : null,
    paymentStatus:
      total == value.value.payAmount ? "full" : value.value.payStatus,
    remainAmount:
      payStatus === "partial"
        ? Number.parseFloat(total - value.value.payAmount).toFixed(2)
        : 0,
    discount: value.value.discount ? value.value.discount : 0,
    channelPartner: licData.channelPartner ? licData.channelPartner.id : null,

    grandTotal:
      value.value &&
      value.value.additionalCharges &&
      value.value.additionalCharges.length
        ? value.value && value.value.grandTotal
        : null,
    additionalCharges: (value.value && value.value.additionalCharges) || null,
    notify: [
      {
        user: licData && licData.userId && licData.userId.id,
      },
    ],
  };

  if (role === "licensee") {
    const dummyIds = yield call(getDummyData, userDtls);

    yield call(createOrderData, {
      data: {
        ...data,
        region: dummyIds && dummyIds.region && dummyIds.region.id,
        zone: dummyIds && dummyIds.zone && dummyIds.zone.id,
        area: dummyIds && dummyIds.area && dummyIds.area.id,
        commission: {
          platform: cmsn.platform,
          incentivesbucket: cmsn.incentivesbucket,
          company: Number.parseFloat(
            cmsn.company +
              cmsn.regionManger +
              cmsn.zoneManger +
              cmsn.areaManger +
              cmsn.salesExecutive
          ).toFixed(2),
          regionManger: 0,
          zoneManger: 0,
          areaManger: 0,
          salesExecutive: 0,
        },
      },
      value: value.value,
    });
  } else if (role === "regionalManager") {
    const dummyIds = yield call(getDummyData, userDtls);
    yield call(createOrderData, {
      data: {
        ...data,
        region: userDtls.region,
        zone: dummyIds && dummyIds.zone && dummyIds.zone.id,
        area: dummyIds && dummyIds.area && dummyIds.area.id,
        commission: {
          platform: cmsn.platform,
          incentivesbucket: cmsn.incentivesbucket,
          company: cmsn.company,
          regionManger: Number.parseFloat(
            cmsn.regionManger +
              cmsn.zoneManger +
              cmsn.areaManger +
              cmsn.salesExecutive
          ).toFixed(2),
          zoneManger: 0,
          areaManger: 0,
          salesExecutive: 0,
        },
      },
      value: value.value,
    });
  } else if (role === "zonalManager") {
    const dummyIds = yield call(getDummyData, userDtls);
    yield call(createOrderData, {
      data: {
        ...data,
        region: userDtls.region,
        zone: userDtls.zone,
        area: dummyIds.area.id,
        commission: {
          platform: cmsn.platform,
          incentivesbucket: cmsn.incentivesbucket,
          company: cmsn.company,
          regionManger: cmsn.regionManger,
          zoneManger: Number.parseFloat(
            cmsn.zoneManger + cmsn.areaManger + cmsn.salesExecutive
          ).toFixed(2),
          areaManger: 0,
          salesExecutive: 0,
        },
      },
      value: value.value,
    });
  } else if (role === "areaManager") {
    yield call(createOrderData, {
      data: {
        ...data,
        region: userDtls.region,
        zone: userDtls.zone,
        area: userDtls.area,
        commission: {
          platform: cmsn.platform,
          incentivesbucket: cmsn.incentivesbucket,
          company: cmsn.company,
          regionManger: cmsn.regionManger,
          zoneManger: cmsn.zoneManger,
          areaManger: Number.parseFloat(
            cmsn.areaManger + cmsn.salesExecutive
          ).toFixed(2),
          salesExecutive: 0,
        },
      },
      value: value.value,
    });
  } else if (role === "salesExcecutive") {
    yield call(createOrderData, {
      data: {
        ...data,
        region: userDtls.region,
        zone: userDtls.zone,
        area: userDtls.area,
        commission: {
          platform: cmsn.platform,
          incentivesbucket: cmsn.incentivesbucket,
          company: cmsn.company,
          regionManger: cmsn.regionManger,
          zoneManger: cmsn.zoneManger,
          areaManger: cmsn.areaManger,
          salesExecutive: cmsn.salesExecutive,
        },
      },
      value: value.value,
    });
  }
}

function* createOrderData(value) {
  const token = localStorage.getItem("sStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/order`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(value.data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      // let orderRes = {
      //   orderId: resJSON.id,
      //   data: value.data,
      // };
      const paymntData = {
        customer: value.data.customer,
        order: resJSON.id,
        salesExecutive: value.data.salesExecutive,
        licenseeId: value.data.licenseeId,
        region: value.data.region,
        zone: value.data.zone,
        area: value.data.area,
        paymentMode: value.data.paymentMode,
        currency: value.data.currency,
        // amount: value.data.totalPrice - value.data.remainAmount,
        amount:
          value.data.paymentStatus === "partial"
            ? value.value.payAmount
            : value.data.totalPrice,
        channelPartner: value.data.channelPartner,
        notify: value.data.notify,
      };

      const [] = yield all([
        call(createPayment, paymntData),
        // call(createEarnings, orderRes),
      ]);
      //Updated product inventory
      yield call(updateInventoryQty, { res: resJSON });

      //lead status update
      if (value.data.lead) {
        yield call(leadStatusUpdate, { id: value.data.lead });
      }
      //Quotation status update
      if (value.data.quotation) {
        yield call(quoteStatusUpdate, { id: value.data.quotation });
      }
      //Customer type update
      if (value.value.customer[0].type === "lead") {
        yield call(customerTypeUpdate, { id: value.data.customer });
      }

      yield put({
        type: CREATE_ORDERS_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Order added successfully", {
        autoClose: 3000,
      });
      yield put({
        type: FETCH_ORDERS_INIT_ACTION,
        value: { page: 1 },
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: CREATE_ORDERS_FAIL_ACTION,
        error: err,
      });
    }
  }
}

//update product inventory quantity
function* updateInventoryQty(value) {
  let Val = value.res;
  try {
    let params = {
      api: `${appConfig.ip}/inventory?product=${Val.product.id}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const Res = yield call(commonFunction, params);

    if (Res.count != 0) {
      yield call(createInventoryLog, { Order: Val, invData: Res.rows[0] });
      let qty =
        Val.invStatus && Val.invStatus === "cancel"
          ? Res.rows[0].quantity + Val.quantity
          : Res.rows[0].quantity - Val.quantity;
      const data = {
        quantity: qty,
      };

      try {
        let params = {
          api: `${appConfig.ip}/inventory/${Res.rows[0].id}`,
          method: "PUT",
          sucessAction: null,
          failAction: null,
          body: JSON.stringify(data),
        };
        yield call(commonFunction, params);
      } catch (e) {}
    }
  } catch (e) {}
}

//create Inventory Log/////////////////////////////////
function* createInventoryLog(value) {
  let itemData = value.invData;
  let label =
    value.Order.invStatus && value.Order.invStatus === "cancel"
      ? " Cancelled "
      : " Ordered ";
  let type =
    value.Order.invStatus && value.Order.invStatus === "cancel"
      ? "addition"
      : "reduction";
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const data = {
    inventory: itemData.id,
    product: itemData.product.id,
    user: userDetails.id,
    quantity: value.Order.quantity,
    type: type,
    note:
      userDetails.name +
      label +
      value.Order.quantity +
      " items of " +
      itemData.product.name +
      " with order Id " +
      value.Order.refId,
  };

  try {
    let params = {
      api: `${appConfig.ip}/inventoryLog`,
      method: "POST",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

//Get dummy data for creating order
function* getDummyData(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/users?licenseeId=${value.licenseeId}&dummy=true&role=areaManager`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      return resJSON.rows[0];
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

//Payment create while creating an order
function* createPayment(value) {
  try {
    let params = {
      api: `${appConfig.ip}/payment`,
      method: "POST",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(value),
    };
    yield call(commonFunction, params);
  } catch (e) {}
}

//Lead status update while creating an order
function* leadStatusUpdate(value) {
  const data = {
    status: "converted",
  };
  try {
    let params = {
      api: `${appConfig.ip}/lead/${value.id}`,
      method: "PUT",
      sucessAction: CHANGE_ORDERTOLEADSTATUS_SUCCESS_ACTION,
      failAction: null,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params);
  } catch (e) {}
}

//Quotation status update while creating an order
function* quoteStatusUpdate(value) {
  const data = {
    status: "converted",
  };
  try {
    let params = {
      api: `${appConfig.ip}/quote/${value.id}`,
      method: "PUT",
      sucessAction: CHANGE_QUOTATION_STATUS_SUCCESS_ACTION,
      failAction: null,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params);
  } catch (e) {}
}

//Customer type update while creating an order
function* customerTypeUpdate(value) {
  const token = localStorage.getItem("sStraitToken");
  const data = {
    type: "customer",
  };

  try {
    const res = yield fetch(`${appConfig.ip}/customer/` + value.id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* getOrderById(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/order/` + value.value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_ORDERBYID_SUCCESS_ACTION,
        payload: resJSON,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_ORDERBYID_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchPaymentMode() {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/paymentMode?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_PAYMENTMODE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_PAYMENTMODE_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* EditOrder(value) {
  try {
    const token = localStorage.getItem("sStraitToken");

    let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));

    const data = {
      externalRefId: value.value.externalRefId,
      licenseeId: userDtls.licenseeId,
      commission: {
        platform: value.value.platform,
        regionManger: value.value.regionManger,
        zoneManger: value.value.zoneManger,
        areaManger: value.value.areaManger,
        salesExecutive: value.value.salesExecutive,
        company: value.value.company,
        incentivesbucket: value.value.incentiveBckt,
      },
    };

    const res = yield fetch(`${appConfig.ip}/order/` + value.value.id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      const [orderId] = yield all([call(getOrderById, { value: resJSON.id })]);

      yield put({
        type: EDIT_ORDER_SUCCESS_ACTION,
        payload: orderId,
      });

      yield toast.success("Order updated successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: EDIT_ORDER_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* changeOrderStatus(value) {
  let id = value.approve ? value.Val.id : value.value.Val.id;

  let statusVal = value.approve ? "delivered" : "blocked";

  const token = localStorage.getItem("sStraitToken");
  const data = {
    status: statusVal,
  };

  try {
    const res = yield fetch(`${appConfig.ip}/order/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      if (statusVal === "blocked") {
        yield call(paymentById, {
          id: value.value.Val.id,
          status: "canceled",
        });
        yield call(productById, { value: value.value.orderItem });
        yield call(updateInventoryQty, {
          res: { ...value.value.orderItem, invStatus: "cancel" },
        });
      }

      yield put({
        type: CHANGE_ORDERSTATUS_SUCCESS_ACTION,
        payload: resJSON,
      });
      value.approve
        ? yield toast.success("Order approved successfully", {
            autoClose: 3000,
          })
        : yield toast.success("Status updated successfully", {
            autoClose: 3000,
          });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: CHANGE_ORDERSTATUS_FAIL_ACTION,
        error: err,
      });
    }
  }
}

//Get product By Id

function* productById(value) {
  const token = localStorage.getItem("sStraitToken");
  let id = value.value.product.id;

  try {
    const res = yield fetch(`${appConfig.ip}/product/` + id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let qty = resJSON.quantity + value.value.quantity;

      yield call(updateProduct, { id: id, qty: qty });
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* updateProduct(value) {
  const token = localStorage.getItem("sStraitToken");
  const data = {
    quantity: value.qty,
  };

  try {
    const res = yield fetch(`${appConfig.ip}/product/` + value.id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* changeOrderApprove(value) {
  let Val = value.value;

  if (Val.product.quantity >= 0) {
    let userIdArr = [];

    try {
      const [adminId, userIds, comsnAmtArr] = yield all([
        call(fetchAdminDtls),
        call(fetchUserDtls, Val),
        call(fetchCommissionAmnt, Val),
      ]);

      let licenseId = userIds.licenseeId.userId.id;

      // Get user Id array

      adminId.count !== 0 &&
        adminId.rows.map((row) => {
          userIdArr.push({ role: "platform", userId: row.id });
        });

      userIdArr.push(
        { role: "salesExcecutive", userId: userIds.salesExecutive.id },
        { role: "company", userId: userIds.licenseeId.userId.id },
        { role: "regionalManager", userId: userIds.region.manager.id },
        { role: "zonalManager", userId: userIds.zone.manager.id },
        { role: "areaManager", userId: userIds.area.manager.id }
      );

      // Merge array

      let mergedArray = userIdArr.map((item) => {
        let cmmsnData = comsnAmtArr.find(
          (element) => element.role === item.role
        );
        return { ...item, ...cmmsnData };
      });

      let fltrArr = mergedArray.filter((item) => item.Amnt != 0);

      let request = [];

      request.push(call(postIncentiveBckt, { Val: Val, licenseId: licenseId }));

      fltrArr.forEach((el) => {
        request.push(call(createMultipleEarning, { Arr: el, Val: Val }));
      });

      let incentvRes = yield all(
        request
        // call(postIncentiveBckt, { Val: Val, licenseId: licenseId }),
        // call(createMultipleEarning, { Arr: mergedArray[0], Val: Val }),
        // call(createMultipleEarning, { Arr: mergedArray[1], Val: Val }),
        // call(createMultipleEarning, { Arr: mergedArray[2], Val: Val }),
        // call(createMultipleEarning, { Arr: mergedArray[3], Val: Val }),
        // call(createMultipleEarning, { Arr: mergedArray[4], Val: Val }),
        // call(createMultipleEarning, { Arr: mergedArray[5], Val: Val }),
      );

      if (incentvRes.length === request.length) {
        //for create invoice
        yield call(createInvoice, Val);

        //For payment status upadate
        yield call(paymentById, {
          id: value.value.id,
          status: "completed",
        });
        //For status change
        yield call(changeOrderStatus, { Val: Val, approve: true });
        yield call(orderAcceptNotification, { Val: Val });
      } else {
        yield toast.error("Order approved failed", {
          autoClose: 3000,
        });
      }
    } catch (err) {}
  } else {
    yield toast.error("You don't have enough product quantity to approve", {
      autoClose: 3000,
    });
  }
}

//Create Invoice
function* createInvoice(value) {
  const configData = yield call(getInvoiceConfig);
  const licData = yield call(getLicenseeProf);
  let customer = value.customer;

  const data = {
    creator: value.salesExecutive.id,
    receiver: value.customer.id,
    order: value.id,
    licenseeId: value.licenseeId.id,
    orderRefId: value.refId,
    currency: value.currency.id,
    // discount: totalDisc,
    discount: value.discount,

    amount: value.totalPrice,
    subtotal: value.totalPrice,
    notes: configData.count != 0 ? configData.rows[0].remark : "",
    category: customer.category ? customer.category : "",
    taxModel: customer.taxModel ? customer.taxModel : "",
    status: "paid",
    customer: {
      name: customer.name,
      address: customer.address,
      mobNo: customer.mobileNo,
      email: customer.email,
      gstin: customer.gstin ? customer.gstin : "",
    },
    licensee: {
      orgName: licData.orgName,
      address: licData.addr1 ? licData.addr1 : "",
      contactNo: licData.contactNo ? licData.contactNo : "",
      contactEmail: licData.contactEmail ? licData.contactEmail : "",
      gstin: licData.gstin ? licData.gstin : "",
    },
    config: {
      accountName: configData.count != 0 ? configData.rows[0].accountName : "",
      bankName: configData.count != 0 ? configData.rows[0].bankName.name : "",
      accountNumber: configData.count != 0 ? configData.rows[0].accountNo : "",
      ifscCode: configData.count != 0 ? configData.rows[0].ifscCode : "",
      branchName: configData.count != 0 ? configData.rows[0].branchName : "",
      addtCustInfo:
        configData.count != 0 ? configData.rows[0].addtCustInfo : "",
      logo: configData.count != 0 ? configData.rows[0].imgUrl : "",
    },
  };

  // const data = {
  //   creator: value.salesExecutive.id,
  //   receiver: value.customer.id,
  //   order: value.id,
  //   licenseeId: value.licenseeId.id,
  //   orderRefId: value.refId,
  //   currency: value.currency.id,
  //   discount: value.discount,
  //   amount: value.totalPrice,
  //   subtotal: value.totalPrice,
  //   notes: configData.count != 0 ? configData.rows[0].remark : "",
  //   status: "paid",
  // };
  try {
    let params = {
      api: `${appConfig.ip}/invoice`,
      method: "POST",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params);
  } catch (e) {}
}
//Get Licensee profile
function* getLicenseeProf() {
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

  let userId = licData.userId.id;
  try {
    let params = {
      api: `${appConfig.ip}/licenseeprof/${userId}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

function* orderAcceptNotification(value) {
  let values = value.Val;

  let acceptedUser =
    values && values.salesExecutive && values.salesExecutive.id;

  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  let orgName = licData && licData.userId && licData.userId.orgName;
  const data = {
    user: acceptedUser,
    event: "orderAccepted",
    subject: "Order Accepted",
    message: `Order accepted by ${orgName}`,
    streams: ["pusher"],
  };

  try {
    let params = {
      api: `${appConfig.ip}/notfctn`,
      method: "POST",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params);
  } catch (e) {}
}

//Get Invoice config data
function* getInvoiceConfig() {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  try {
    let params = {
      api: `${appConfig.ip}/invoiceConfig?isActive=true&licenseeId=${userDtls.licenseeId}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const Res = yield call(commonFunction, params);

    return Res;
  } catch (e) {}
}

//Payment Status update

function* paymentById(value) {
  try {
    let params = {
      api: `${appConfig.ip}/payment?order=${value.id}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const paymentRes = yield call(commonFunction, params);

    const pymntId = paymentRes.rows.map((x) => x.id);

    yield all(
      pymntId.map((x) =>
        call(paymentStatusUpdate, { id: x, status: value.status })
      )
    );
  } catch (e) {}
}

//Payment status update
function* paymentStatusUpdate(value) {
  const data = {
    status: value.status,
  };
  try {
    let params = {
      api: `${appConfig.ip}/payment/${value.id}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const paymentStatus = yield call(commonFunction, params);
  } catch (e) {}
}

//Get Admin Id function

function* fetchAdminDtls() {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/users?role=admin`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

// Get User IDs function

function* fetchUserDtls(value) {
  const token = localStorage.getItem("sStraitToken");

  // let areaId = value.salesExecutive.area.id;
  const apiUrl = "licenseeId=" + value.licenseeId.id;

  try {
    const res = yield fetch(
      `${appConfig.ip}/order/userDetails/${value.id}?` + apiUrl,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

//Commission calculation function

function* fetchCommissionAmnt(value) {
  let sellingPrice = value.sellingPrice;

  if (!sellingPrice) {
    sellingPrice = value.product.sellingPrice;
  }

  let buyingPrice = value.buyingPrice;

  if (!buyingPrice) {
    buyingPrice = value.product.buyingPrice;
  }
  const amnt = (sellingPrice - buyingPrice) * value.quantity;
  const discAmnt = Number.parseFloat((amnt * value.discount) / 100).toFixed(2);
  const price = amnt - discAmnt;

  let commission = value.commission;

  if (!commission.platform) {
    commission = value.product.commission;
  }

  let platform = Number.parseFloat((price * commission.platform) / 100).toFixed(
    2
  );
  let regionManger = Number.parseFloat(
    (price * commission.regionManger) / 100
  ).toFixed(2);
  let zoneManger = Number.parseFloat(
    (price * commission.zoneManger) / 100
  ).toFixed(2);
  let areaManger = Number.parseFloat(
    (price * commission.areaManger) / 100
  ).toFixed(2);
  let salesExecutive = Number.parseFloat(
    (price * commission.salesExecutive) / 100
  ).toFixed(2);
  let company = Number.parseFloat((price * commission.company) / 100).toFixed(
    2
  );
  // let incentivesbucket = price * commission.incentivesbucket / 100;

  let comsnAmtArr = [];
  comsnAmtArr.push(
    { role: "platform", Amnt: parseFloat(platform) },
    { role: "regionalManager", Amnt: parseFloat(regionManger) },
    { role: "zonalManager", Amnt: parseFloat(zoneManger) },
    { role: "areaManager", Amnt: parseFloat(areaManger) },
    { role: "salesExcecutive", Amnt: parseFloat(salesExecutive) },
    { role: "company", Amnt: parseFloat(company) }
    // { role: "incentivesbucket", Amnt: incentivesbucket }
  );

  return comsnAmtArr;
}

function* postIncentiveBckt(value) {
  const token = localStorage.getItem("sStraitToken");

  let sellingPrice = value.Val.sellingPrice;

  if (!sellingPrice) {
    sellingPrice = value.Val.product.sellingPrice;
  }

  let buyingPrice = value.Val.buyingPrice;

  if (!buyingPrice) {
    buyingPrice = value.Val.product.buyingPrice;
  }

  // const price =
  //   (sellingPrice - buyingPrice) * value.Val.quantity - value.Val.discount;

  const amnt = (sellingPrice - buyingPrice) * value.Val.quantity;
  const discAmnt = Number.parseFloat((amnt * value.Val.discount) / 100).toFixed(
    2
  );
  const price = amnt - discAmnt;

  let commission = value.Val.commission;

  if (!commission.platform) {
    commission = value.Val.product.commission;
  }

  let amount = Number.parseFloat(
    (price * commission.incentivesbucket) / 100
  ).toFixed(2);

  const data = {
    receiver: value.licenseId,
    licenseeId: value.Val.licenseeId.id,
    order: value.Val.id,
    salesExecutive: value.Val.salesExecutive.id,
    currency: value.Val.currency.id,
    amount: amount,
  };
  if (amount != 0) {
    try {
      const res = yield fetch(`${appConfig.ip}/incentiveBucket`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) {
        let errJSON = {};
        try {
          errJSON = yield res.json();
        } catch {
          throw Object.assign(res, errJSON);
        }
      } else {
        const resJSON = yield res.json();
        return resJSON;
      }
    } catch (err) {
      if (err.ok === false) {
      }
    }
  }
}

function* createMultipleEarning(value) {
  const token = localStorage.getItem("sStraitToken");
  const data = {
    receiver: value.Arr.userId,
    licenseeId: value.Val.licenseeId.id,
    order: value.Val.id,
    salesExecutive: value.Val.salesExecutive.id,
    currency: value.Val.currency.id,
    amount: value.Arr.Amnt,
    type: "order",
  };
  try {
    const res = yield fetch(`${appConfig.ip}/earnings`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();
      yield put({
        type: CHANGE_ORDERAPPROVE_SUCCESS_ACTION,
        payload: resJSON,
      });
      return resJSON;
      // yield toast.success("Order approved successfully", {
      //   autoClose: 3000,
      // });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: CHANGE_ORDERAPPROVE_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* exprtFullData(value) {
  let fileName = "Order Report";
  const token = localStorage.getItem("sStraitToken");
  const apiUrl = yield call(getRoleApiUrl);

  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? null
      : value.value.searchVal;
  let filter =
    !value || !value.value || !value.value.filter ? null : value.value.filter;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let filterValDate =
    !value || !value.value || !value.value.filterValDate
      ? null
      : value.value.filterValDate;

  let _url = `${appConfig.ip}/order?limit=100${apiUrl.url}`;

  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }
  if (filter) {
    _url = `${_url}&product=${filter}`;
  }
  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  }
  if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  }
  if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }
  if (filterValDate) {
    _url = `${_url}${filterValDate}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let csvData = [];

      resJSON.rows.map((item) => {
        csvData.push({
          ORDER_ID: item.refId,
          REF_ID: item.externalRefId ? item.externalRefId : "NIL",
          CUSTOMER_ID: item.customer != null ? item.customer.refId : "NIL",
          CUSTOMER_NAME: item.customer != null ? item.customer.name : "NIL",
          PRODUCT_ID: item.product != null ? item.product.refId : "NIL",
          PRODUCT_NAME: item.product != null ? item.product.name : "NIL",
          QUANTITY: item.quantity,
          TOTAL_PRICE: item.currency.symbol + item.totalPrice,
          SHIPPING_ADDRESS: item.shippingAddress,
          DESCRIPTION: item.description ? item.description : "NIL",
          PAYMENT_TYPE: item.paymentStatus,
          LICENSEE_NAME:
            item.licenseeId != null ? item.licenseeId.orgName : "NIL",
          REGION: item.region != null ? item.region.name : "NIL",
          ZONE: item.zone != null ? item.zone.name : "NIL",
          AREA: item.area != null ? item.area.name : "NIL",
          SALES_EXECUTIVE:
            item.salesExecutive != null ? item.salesExecutive.name : "NIL",
          STATUS: item.status,
          ORDER_DATE: dateFormat(item.createdAt, "mediumDate"),
        });
      });

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      yield put({
        type: EXPORT_ORDER_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EXPORT_ORDER_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* OrderPayAmount(value) {
  const remainAmount = Number.parseFloat(
    value.value.ItemList.remainAmount - value.value.values.amount
  ).toFixed(2);
  const data = {
    remainAmount: remainAmount,
    paymentStatus:
      remainAmount == 0.0 ? "full" : value.value.ItemList.paymentStatus,
  };
  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/order/${value.value.ItemList.id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      const paymntData = {
        customer: resJSON.customer.id,
        order: resJSON.id,
        salesExecutive: resJSON.salesExecutive.id,
        licenseeId: resJSON.licenseeId.id,
        region: resJSON.region.id,
        zone: resJSON.zone.id,
        area: resJSON.area.id,
        // paymentMode: resJSON.paymentMode.id,
        paymentMode: value.value.values.paymentMode[0].id,
        currency: resJSON.currency.id,
        amount: value.value.values.amount,
      };
      yield call(createPayment, paymntData);
      const orderId = yield call(getOrderById, { value: resJSON.id });
      yield put({
        type: PAYAMOUNT_ORDER_SUCCESS_ACTION,
        payload: orderId,
      });
      yield toast.success("Payment updated successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: PAYAMOUNT_ORDER_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* commonFunction(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: `${value.failAction}`, error: err });
    } else {
    }
  }
}

function* fetchTotalAmntByOrderLic(value) {
  // let pageno = value.value.page === undefined ? 1 : value.value.page;
  const token = localStorage.getItem("sStraitToken");

  const apiUrl = yield call(getRoleApiUrl);
  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? null
      : value.value.searchVal;
  let filter =
    !value || !value.value || !value.value.filter ? null : value.value.filter;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let filterValDate =
    !value || !value.value || !value.value.filterValDate
      ? null
      : value.value.filterValDate;

  let _url = `${appConfig.ip}/order/sum?status=created,delivered${apiUrl.url}`;

  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }
  if (filter) {
    _url = `${_url}&product=${filter}`;
  }
  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  }
  if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  }
  if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }
  if (filterValDate) {
    _url = `${_url}${filterValDate}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_TOTAL_AMOUNT_BY_ORDER_LICENSEE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_TOTAL_AMOUNT_BY_ORDER_LICENSEE_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

export function* OrderActionWatcher() {
  yield takeEvery(FETCH_ORDERS_INIT_ACTION, fetchOrders);
  yield takeEvery(CREATE_ORDERS_INIT_ACTION, createOrder);
  yield takeEvery(FETCH_ORDERBYID_INIT_ACTION, getOrderById);
  yield takeEvery(EDIT_ORDER_INIT_ACTION, EditOrder);
  yield takeEvery(FETCH_PAYMENTMODE_INIT_ACTION, fetchPaymentMode);
  yield takeEvery(CHANGE_ORDERSTATUS_INIT_ACTION, changeOrderStatus);
  yield takeEvery(CHANGE_ORDERAPPROVE_INIT_ACTION, changeOrderApprove);
  // yield takeEvery(FILTER_SEARCH_ORDER_INIT_ACTION, filterSearchOrder);
  yield takeEvery(EXPORT_ORDER_INIT_ACTION, exprtFullData);
  yield takeEvery(PAYAMOUNT_ORDER_INIT_ACTION, OrderPayAmount);
  yield takeEvery(
    FETCH_TOTAL_AMOUNT_BY_ORDER_LICENSEE_INIT_ACTION,
    fetchTotalAmntByOrderLic
  );
}
