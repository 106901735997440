export const ADD_DISQUOTATION_INIT_ACTION = "ADD_DISQUOTATION_INIT_ACTION";
export const ADD_DISQUOTATION_SUCCESS_ACTION =
  ADD_DISQUOTATION_INIT_ACTION + "_SUCCESS";
export const ADD_DISQUOTATION_FAIL_ACTION =
  ADD_DISQUOTATION_INIT_ACTION + "_ERROR";

export function AddDisQuotation(value) {
  return {
    type: ADD_DISQUOTATION_INIT_ACTION,
    value: value,
  };
}
