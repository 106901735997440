/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "./Navbar.css";
// import Image from "react-bootstrap/Image";
// import Tab from "@material-ui/core/Tab";
// import Button from "react-bootstrap/Button";
// import { Link, NavLink } from "react-router-dom";
// import { styles } from "./style.js";
import { Container } from "react-bootstrap";

class Navbar2 extends Component {
  render() {
    return (
      <div className="homenavbarfirst">
        <Navbar
          style={{ backgroundColor: "rgb(255 255 255)" }}
          collapseOnSelect
          expand="lg"
          className="navbar "
          //   style={styles.nav}
        >
          {/* <Container> */}
          <Nav>
            <div className="logoNav">
              {/* <Link to="/fleetpage" onClick={() => window.scrollTo(0, 0)}> */}
              <img
                alt="logoimg"
                className="homeimage"
                //style={{height:"20px",width:"20px"}}
                src={require("../../assets/img/Logo-full-bg.png")}
              />

              {/* </Link> */}
            </div>
          </Nav>
          {/* 
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto "></Nav>

              <Nav> */}

          {/* <NavLink
                  activeClassName="navbar__link--active"
                  className="navbar__link"
                  className="mainpagenavbar"
                  to="/fleetpage"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Home
                </NavLink> */}

          {/* <NavLink to="/login" onClick={() => window.scrollTo(0, 0)}>
                  {" "}
                  <Button
                    type="submit"
                    className="navbtn"
                    // style={styles.navpgBtn}
                  >
                    Login
                  </Button>
                </NavLink> */}

          {/* </Nav>
            </Navbar.Collapse> */}
          {/* </Container> */}
        </Navbar>
      </div>
    );
  }
}

export default Navbar2;
