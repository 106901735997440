import { put, takeEvery, select, call, all } from "redux-saga/effects";
// import { createBrowserHistory } from "history";

import { appConfig } from "../../config";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import * as actionTypes from "./constants";

//common function
function* commonFunction(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: `${value.failAction}`, error: err });
    } else {
    }
  }
}

function* fetchSocialLead(value) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let pageno = value.value === undefined ? 1 : value.value.page;
  // ?licenseeId=${userDtls.licenseeId}&limit=10&page=${pageno}
  try {
    let params = {
      api: `${appConfig.ip}/socialLead?licenseeId=${userDtls.licenseeId}&limit=10&page=${pageno}`,
      method: "GET",
      sucessAction: actionTypes.FETCH_SOCIALLEAD_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_SOCIALLEAD_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);
  } catch (e) {}
}

function* addSocialLead(value) {
  const customerData = yield call(AddCustomer, value);

  let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const data = {
    customer: customerData && customerData.id,
    product: value.value.products[0].id,
    licenseeId: userDetails && userDetails.licenseeId,
    region: customerData && customerData.region && customerData.region.id,
    zone:
      customerData && customerData.zone != null ? customerData.zone.id : null,
    area:
      customerData && customerData.area != null ? customerData.area.id : null,
    type: "socialLead",
    socialRef: value && value.value && value.value.id,
  };

  try {
    let params = {
      api: `${appConfig.ip}/lead`,
      method: "POST",
      sucessAction: actionTypes.ADD_SOCIALLEAD_SUCCESS_ACTION,
      failAction: actionTypes.ADD_SOCIALLEAD_FAIL_ACTION,
      body: JSON.stringify(data),
      toastMsg: "Successfully converted to lead",
    };
    const res = yield call(commonFunction, params);

    yield call(statusChange, data.socialRef);
    return res;
  } catch (e) {}
}

function* addDiSocialLead(value) {
  let agent = value && value.value && value.value.agent;
  const customerData = agent
    ? yield call(AddDisCustomer, value)
    : yield call(AddCustomer, value);

  let products = [];
  value.value.products.map((item) => {
    products.push({
      id: item.id,
      refId: item.refId,
      name: item.name,
      licenseeId: item.licenseeId.id,
      ctgryId: item.ctgryId.id,
      subCtgryId: item.subCtgryId.id,

      unit: item.unit,
      backOrder: item.backOrder,
      buyingPrice: item.buyingPrice,
      sellingPrice: item.sellingPrice,
      currency: item.currency.id,
      gstSlab: item.gstSlab.id,

      PrdtType: item.PrdtType.id,
      agency: item.agency.id,
      commission: item.commission,
      description: item.description,
      image: item.image,
      channelPartner: item.channelPartner ? item.channelPartner : null,
      quantity: item.quantity,
    });
  });

  let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const data = {
    customer: customerData && customerData.id,
    products: products,
    licenseeId: userDetails && userDetails.licenseeId,
    region: customerData && customerData.region && customerData.region.id,
    zone:
      customerData && customerData.zone != null ? customerData.zone.id : null,
    area:
      customerData && customerData.area != null ? customerData.area.id : null,
    type: "socialLead",
    socialRef: value && value.value && value.value.id,
    salesExecutive: (value && value.value && value.value.agent) || null,
  };

  try {
    let params = {
      api: `${appConfig.ip}/lead/distributor`,
      method: "POST",
      sucessAction: actionTypes.ADD_DIS_SOCIALLEAD_SUCCESS_ACTION,
      failAction: actionTypes.ADD_DIS_SOCIALLEAD_FAIL_ACTION,
      body: JSON.stringify(data),
      toastMsg: "Successfully converted to lead",
    };
    const res = yield call(commonFunction, params);

    yield call(statusChange, data.socialRef);
    return res;
  } catch (e) {}
}

function* statusChange(value) {
  const data = {
    status: "converted",
  };
  try {
    let params = {
      api: `${appConfig.ip}/socialLead/${value}`,
      method: "PUT",
      sucessAction: actionTypes.UPDATE_SOCIALSTATUS_SUCCESS_ACTION,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

function* AddCustomer(value) {
  let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const data = {
    initiator: userDetails.role,
    name: value.value.name,
    email: value.value.email,
    mobileNo: value.value.mobileNo,
    address: value.value.address,
    type: "lead",
    author: userDetails.id,
    licenseeId: userDetails.licenseeId,
  };

  let val;
  if (value.value.ref === "region") {
    val = {
      ...data,
      region: value.value.typeIds[0].value,
    };
  } else if (value.value.ref === "zone") {
    const idData = yield call(fetchIds, value.value);
    val = {
      ...data,
      region: idData.region.id,
      zone: value.value.typeIds[0].value,
    };
  } else if (value.value.ref === "area") {
    const idData = yield call(fetchIds, value.value);
    val = {
      ...data,
      region: idData.region.id,
      zone: idData.zone.id,
      area: value.value.typeIds[0].value,
    };
  }

  try {
    let params = {
      api: `${appConfig.ip}/customer`,
      method: "POST",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(val),
    };
    const res = yield call(commonFunction, params);
    return res;
  } catch (e) {}
}

// For Distributor with out network
function* AddDisCustomer(value) {
  let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const data = {
    initiator: userDetails.role,
    name: value.value.name,
    email: value.value.email,
    mobileNo: value.value.mobileNo,
    address: value.value.address,
    type: "lead",
    author: userDetails.id,
    licenseeId: userDetails.licenseeId,
  };

  try {
    let params = {
      api: `${appConfig.ip}/customer`,
      method: "POST",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
    return res;
  } catch (e) {}
}

// Common functions

function* fetchIds(value) {
  let id = value.typeIds[0].value;
  let url = value.ref === "zone" ? `/zone/${id}` : `/area/${id}`;

  try {
    let params = {
      api: `${appConfig.ip}${url}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

function* fetchAgent(params) {
  let userRole = localStorage.getItem("sStraitUserRole");
  let licensee = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

  let licenseeFilterparam = "";
  if (userRole === "distributor" || userRole === "licenseeAccountant") {
    licenseeFilterparam = `&licenseeId=${licensee.id}`;
  }

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/users?isActive=true&status=created,active,suspended&dummy=false&role=agent${licenseeFilterparam}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_AGENT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_AGENT_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

export function* SocialLeadActionWatcher() {
  yield takeEvery(actionTypes.FETCH_SOCIALLEAD_INIT_ACTION, fetchSocialLead);
  yield takeEvery(actionTypes.ADD_SOCIALLEAD_INIT_ACTION, addSocialLead);
  yield takeEvery(actionTypes.FETCH_AGENT_INIT_ACTION, fetchAgent);
  yield takeEvery(actionTypes.ADD_DIS_SOCIALLEAD_INIT_ACTION, addDiSocialLead);
}
