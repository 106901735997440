/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "./Navbar.css";
// import Image from "react-bootstrap/Image";
// import Tab from "@material-ui/core/Tab";
// import Button from "react-bootstrap/Button";
// import { Link, NavLink } from "react-router-dom";
// import { styles } from "./style.js";
//import { Container } from "react-bootstrap";

class Navbar2 extends Component {
  render() {
    return (
      <div className="homenavbarfirst">
        <Navbar
          style={{ backgroundColor: "rgb(255 255 255)" }}
          collapseOnSelect
          expand="lg"
          className="navbar "
      
        >
          <Nav>

            <div className="logoNav">
              <img
                alt=""
                className="homeimage"
                src={require("../../../assets/img/Logo.png")}
                />

              {/* </Link> */}
            </div>

          </Nav>
        
        </Navbar>
      </div>
    );
  }
}

export default Navbar2;
