import * as actionTypes from "./constants";

const initialState = {
  processing: false,
  error: false,
  notifications: [],
  notificationCount: 0,
};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_NOTIFICATIONS_SUCCESS_ACTION:
      return {
        ...state,
        notifications: action.payload.rows,
        notificationCount: action.payload.count,
      };
    case actionTypes.FETCH_UNREADNOTIFICATIONS_SUCCESS_ACTION:
      return {
        ...state,
        unreadnotificationsdata: action.payload.rows,
        unreadnotificationCount: action.payload.count,
      };

    default:
      return {
        ...state,
      };
  }
}
