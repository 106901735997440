export const FETCH_SALES_RETURN_INIT_ACTION = "FETCH_SALES_RETURN_INIT_ACTION";
export const FETCH_SALES_RETURN_SUCCESS_ACTION =
  FETCH_SALES_RETURN_INIT_ACTION + "_SUCCESS";
export const FETCH_SALES_RETURN_FAIL_ACTION =
  FETCH_SALES_RETURN_INIT_ACTION + "_ERROR";

export const FETCH_SALES_RETURN_BY_ID_INIT_ACTION =
  "FETCH_SALES_RETURN_BY_ID_INIT_ACTION";
export const FETCH_SALES_RETURN_BY_ID_SUCCESS_ACTION =
  FETCH_SALES_RETURN_BY_ID_INIT_ACTION + "_SUCCESS";
export const FETCH_SALES_RETURN_BY_ID_FAIL_ACTION =
  FETCH_SALES_RETURN_BY_ID_INIT_ACTION + "_ERROR";

export const APPROVE_SALES_RETURN_INIT_ACTION =
  "APPROVE_SALES_RETURN_INIT_ACTION";
export const APPROVE_SALES_RETURN_SUCCESS_ACTION =
  APPROVE_SALES_RETURN_INIT_ACTION + "_SUCCESS";
export const APPROVE_SALES_RETURN_FAIL_ACTION =
  APPROVE_SALES_RETURN_INIT_ACTION + "_ERROR";
