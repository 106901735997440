import * as actionTypes from "./constant";

const initialState = {
  processing: false,
  error: false,
  replaceByIdList: {},
};

export default function productReplaceReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCT_REPLACE_BY_ID_SUCCESS_ACTION:
      return {
        ...state,
        productReplaceList: action.payload.rows,
        productReplaceCount: action.payload.count,
      };
    case actionTypes.FETCH_PRODUCT_REPLACE_SUCCESS_ACTION:
      return {
        ...state,
        productReplaceDistributorList: action.payload.rows,
        productReplaceDistributorCount: action.payload.count,
      };

    case actionTypes.FETCH_REPLACE_BY_ID_SUCCESS_ACTION:
      return {
        ...state,
        replaceByIdList: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
