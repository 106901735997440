import * as actionTypes from "./constants";
import { sortData } from "../../../screens/Common/sortData";

const initialState = {
  processing: false,
  error: false,
  debtorsReportList: [],
  debtorsReportCount: 0,
  debtorsReportByIdList: {},
};

export default function debtorsReportReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_DEBTORS_REPORT_SUCCESS_ACTION:
      return {
        ...state,
        debtorsReportList: action.payload.rows,
        debtorsReportCount: action.payload.count,
      };
    case actionTypes.FETCH_DEBTORS_REPORT_BY_ID_SUCCESS_ACTION:
      return {
        ...state,
        debtorsReportByIdList: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
}
