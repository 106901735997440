import * as actionTypes from "./constants";
import { sortData } from "../../../screens/Common/sortData";

const initialState = {
  processing: false,
  error: false,
  expenseList: [],
  expenseCount: 0,
  expenseTypeList: [],
};

export default function expenseReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_EXPENSE_SUCCESS_ACTION:
      return {
        ...state,
        expenseList: action.payload.rows,
        expenseCount: action.payload.count,
      };
    case actionTypes.FETCH_EXPENSE_TYPE_IN_AGENT_SUCCESS_ACTION:
      return {
        ...state,
        expenseTypeList: sortData(action.payload.rows),
      };
    case actionTypes.EDIT_EXPENSE_SUCCESS_ACTION:
      return {
        ...state,
        expenseList: state.expenseList.map((exp) =>
          exp.id === action.payload.id ? action.payload : exp
        ),
      };
    default:
      return {
        ...state,
      };
  }
}
