import { put, takeEvery, call } from "redux-saga/effects";
// import * as actionTypes from "./constants";
import * as actionTypes from "./constant";
import { appConfig } from "../../../config";
import { toast } from "react-toastify";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
var dateFormat = require("dateformat");

// common role wise url get

function* commonFunction(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: `${value.failAction}`, error: err });
    } else {
    }
  }
}

function* fetchUserReport(item) {
  let userDtls = yield JSON.parse(localStorage.getItem("sStraitUserDtls"));

  let page = item?.item?.page || 1;
  try {
    let params = {
      api: `${appConfig.ip}/stockMovement/getReport?licenseeId=${userDtls.licenseeId}&allocatedPerson=${item.item.allocatedPerson}&after=${item.item.after}&before=${item.item.before}&page=${page}`,
      method: "GET",
      sucessAction: actionTypes.FETCH_USER_REPORT_REPORT_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_USER_REPORT_REPORT_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

function* fetchUserReportTotals(item) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let page = item?.item?.page || 1;

  try {
    let params = {
      api: `${appConfig.ip}/order/report?licenseeId=${userDtls.licenseeId}&salesExecutive=${item.item.allocatedPerson}&after=${item.item.after}&before=${item.item.before}&page=${page}`,
      method: "GET",
      sucessAction: actionTypes.FETCH_USER_REPORT_TOTAL_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_USER_REPORT_TOTAL_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

function* exprtAgentData(item) {
  let fileName = "Agent Report";
  const token = localStorage.getItem("sStraitToken");
  let userDtls = yield JSON.parse(localStorage.getItem("sStraitUserDtls"));

  let api = `${appConfig.ip}/stockMovement/getReport?licenseeId=${userDtls.licenseeId}&allocatedPerson=${item.item.allocatedPerson}&after=${item.item.after}&before=${item.item.before}&limit=100`;
  try {
    const res = yield fetch(api, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      let csvData = [];

      resJSON.rows.map((item) => {
        csvData.push({
          PRODUCT_NAME: item.details.products.name,
          ALLOCATED_QTY: item.details.products.allocatedQty,
          SOLD_QTY: item.details.products.soldQty,
          BALANCE_QTY: item.details.products.returnQty
            ? item.details.products.returnQty
            : 0,
          OUTSTANDING_QTY: item.details.products.outstandingQty
            ? item.details.products.outstandingQty
            : 0,
          NUMBER_OF_ORDERS: item.noOfOrders,
          INVOICE_AMOUNT: item.newArray.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.products.total,
            0
          ),
          AMOUNT_COLLECTED: item.newArray.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.products.paidAmount,
            0
          ),
          BALANCE_AMOUNT: item.newArray.reduce(
            (accumulator, currentValue) =>
              accumulator +
              (currentValue.products.total - currentValue.products.paidAmount),
            0
          ),
        });
      });
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      yield put({
        type: actionTypes.EXPORT_USER_REPORT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.EXPORT_USER_REPORT_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

export function* agentReportActionWatcher() {
  yield takeEvery(actionTypes.FETCH_USER_REPORT_INIT_ACTION, fetchUserReport);
  yield takeEvery(
    actionTypes.FETCH_USER_REPORT_TOTAL_INIT_ACTION,
    fetchUserReportTotals
  );

  yield takeEvery(actionTypes.EXPORT_USER_REPORT_INIT_ACTION, exprtAgentData);
}
