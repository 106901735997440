import * as React from "react";
import { connect } from "react-redux";
import Login from "../../screens/Login";
import { login, logOut,fetchStatus } from "./actions";
export interface Props {
  logins: any;
}

export interface State { }
class LoginSection extends React.Component<Props, State> {
  render() {
    return <Login logins={this.props.logins}
    logoutaction={this.props.logoutaction} 
    fetchStatus={this.props.fetchStatus}
    />;
  }
}
function bindAction(dispatch) {
  return {
    logins: (value) => {
     
      dispatch(login(value));
    },
    fetchStatus: (value) => {
     
      dispatch(fetchStatus(value));
    },
  };
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, bindAction)(LoginSection);
