import { put, takeEvery, select, call, all } from "redux-saga/effects";

import { createBrowserHistory } from "history";
import {
  ADD_DISPRODUCTS_INIT_ACTION,
  ADD_DISPRODUCTS_SUCCESS_ACTION,
  ADD_DISPRODUCTS_FAIL_ACTION,
  EDIT_DISPRODUCTS_INIT_ACTION,
} from "./action";

import { appConfig } from "../../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  FETCH_PRODUCTS_INIT_ACTION,
  EDIT_PRODUCTS_SUCCESS_ACTION,
  EDIT_PRODUCTS_FAIL_ACTION,
} from "../../ProductContainer/action";

function* commonFunction(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: `${value.failAction}`, error: err });
    } else {
    }
  }
}

function* AddDisProduct(value) {
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

  licData.networkHierarchy
    ? yield call(fetchRegionZoneArea, value)
    : yield call(AddNoHierarchyProduct, value);
}

function* fetchRegionZoneArea(value) {
  if (value.value.value.ref === "region") {
    let reigionIds = value.value.value.typeIds.map((x) => x.value);
    const [zoneIds, areaIds] = yield all([
      call(fetchZoneIds, value),
      call(fetchAreaIds, value),
    ]);

    let newData = {
      data: value,
      reigionIds: reigionIds,
      zoneIds: zoneIds,
      areaIds: areaIds,
    };
    value.value.value.id
      ? yield call(editProductData, newData)
      : yield call(addProductData, newData);
  } else if (value.value.value.ref === "zone") {
    let zoneIds = value.value.value.typeIds.map((x) => x.value);
    const [regionIds, areaIds] = yield all([
      call(fetchZoneIds, value),
      call(fetchAreaIds, value),
    ]);

    const regionIdsFltr = regionIds.filter(
      (item, index) => regionIds.indexOf(item) == index
    );

    let newData = {
      data: value,
      reigionIds: regionIdsFltr,
      zoneIds: zoneIds,
      areaIds: areaIds,
    };

    value.value.value.id
      ? yield call(editProductData, newData)
      : yield call(addProductData, newData);
  } else {
    let areaIds = value.value.value.typeIds.map((x) => x.value);
    const [areaDtls] = yield all([call(fetchAreaIds, value)]);

    let regionIds = areaDtls.rows.map((x) => x.region.id);
    let zoneIds = areaDtls.rows.map((x) => x.zone.id);

    const regionIdsFltr = regionIds.filter(
      (item, index) => regionIds.indexOf(item) == index
    );
    const zoneIdsFltr = zoneIds.filter(
      (item, index) => zoneIds.indexOf(item) == index
    );

    let newData = {
      data: value,
      reigionIds: regionIdsFltr,
      zoneIds: zoneIdsFltr,
      areaIds: areaIds,
    };

    value.value.value.id
      ? yield call(editProductData, newData)
      : yield call(addProductData, newData);
  }
}

function* addProductData(value) {
  let Val = value.data.value;

  const token = localStorage.getItem("sStraitToken");
  let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  const crncyId = JSON.parse(localStorage.getItem("sStraitCurrency")).currencyId
    .id;

  // const [crncyId] = yield all([call(fetchCntryCrncy)]);
  let variantType =
    Val.variants.length != 0
      ? Val.variants && Val.variants[0].color
        ? "color-size"
        : "packing-weight"
      : null;

  try {
    const productData = {
      licenseeId: userDetails.licenseeId,
      name: Val.value.name,
      ctgryId: Val.value.category,
      subCtgryId: Val.value.subcategory,
      PrdtType: Val.value.type,
      gstSlab: Val.value.gst[0].id,
      buyingPrice: Val.value.buyPrice,
      sellingPrice: Val.value.sellPrice,
      unit: Val.value.unit,
      quantity: Val.value.quantity,
      status: "active",
      agency: Val.value.agency,

      commission: {
        platform: Val.value.platform,
        regionManger: Val.value.regionManger,
        zoneManger: Val.value.zoneManger,
        areaManger: Val.value.areaManger,
        salesExecutive: Val.value.salesExecutive,
        company: Val.value.company,
        incentivesbucket: Val.value.incentiveBckt,
        agent: 0,
      },
      currency: crncyId,
      areaRef: Val.value.ref,
      region: value.reigionIds,
      zone: value.zoneIds,
      area: value.areaIds,
      // image: imgResponse.files
      backOrder: Val.value.backOrder,
      description: Val.value.desc,
      channelPartner: licData.channelPartner ? licData.channelPartner.id : null,
      hasVariant: Val.value.hasVariant ? true : false,
      variantType: variantType,
      variants: Val.variants ? Val.variants : [],
      hsnCode: Val.value.hsnCode,
    };

    const resProduct = yield fetch(`${appConfig.ip}/product`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(productData),
    });

    if (!resProduct.ok) {
      let errJSON1 = {};
      try {
        errJSON1 = yield resProduct.json();
      } catch {}
      throw Object.assign(resProduct, errJSON1);
    } else {
      const resJSONproduct = yield resProduct.json();

      if (Val.image) {
        yield call(addeditImage, { prodId: resJSONproduct.id, img: Val.image });
      }
      // post inventory
      yield call(postInventory, {
        resData: resJSONproduct,
        data: productData,
        isAdd: true,
      });

      yield put({ type: FETCH_PRODUCTS_INIT_ACTION, value: { page: 1 } });
      yield put({
        type: ADD_DISPRODUCTS_SUCCESS_ACTION,
        payload: resJSONproduct,
      });
      yield toast.success("Product added successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_DISPRODUCTS_FAIL_ACTION,
        error: err,
      });
    }
  }
}

//Distributor level add product with no hierarchy

function* AddNoHierarchyProduct(value) {
  let Val = value.value.value;

  let variantType =
    value.value.variants.length != 0
      ? value.value.variants && value.value.variants[0].color
        ? "color-size"
        : "packing-weight"
      : null;

  const token = localStorage.getItem("sStraitToken");
  let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  const crncyId = JSON.parse(localStorage.getItem("sStraitCurrency")).currencyId
    .id;

  try {
    const productData = {
      licenseeId: userDetails.licenseeId,
      name: Val.name,
      ctgryId: Val.category,
      subCtgryId: Val.subcategory,
      PrdtType: Val.type,
      gstSlab: Val.gst[0].id,
      buyingPrice: Val.buyPrice,
      sellingPrice: Val.sellPrice,
      unit: Val.unit,
      quantity: Val.quantity,
      status: "active",
      agency: Val.agency,

      commission: {
        platform: 0,
        regionManger: 0,
        zoneManger: 0,
        areaManger: 0,
        salesExecutive: 0,
        company: Val.company,
        incentivesbucket: Val.incentiveBckt,
        agent: 0,
      },
      currency: crncyId,
      backOrder: Val.backOrder,
      description: Val.desc,
      channelPartner: licData.channelPartner ? licData.channelPartner.id : null,
      hasVariant: Val.hasVariant ? true : false,
      variantType: variantType,
      variants: value.value.variants ? value.value.variants : [],
      hsnCode: Val.hsnCode,
    };

    const resProduct = yield fetch(`${appConfig.ip}/product`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(productData),
    });

    if (!resProduct.ok) {
      let errJSON1 = {};
      try {
        errJSON1 = yield resProduct.json();
      } catch {}
      throw Object.assign(resProduct, errJSON1);
    } else {
      const resJSONproduct = yield resProduct.json();

      if (value.value.image) {
        yield call(addeditImage, {
          prodId: resJSONproduct.id,
          img: value.value.image,
        });
      }
      //post inventory
      yield call(postInventory, {
        resData: resJSONproduct,
        data: productData,
        isAdd: true,
      });

      yield put({ type: FETCH_PRODUCTS_INIT_ACTION, value: { page: 1 } });
      yield put({
        type: ADD_DISPRODUCTS_SUCCESS_ACTION,
        payload: resJSONproduct,
      });
      yield toast.success("Product added successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_DISPRODUCTS_FAIL_ACTION,
        error: err,
      });
    }
  }
}

//Edit product

function* EditDisProduct(value) {
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  const role = localStorage.getItem("sStraitUserRole");

  licData.networkHierarchy
    ? yield call(fetchRegionZoneArea, value)
    : yield call(UpdateNoHierarchyProduct, value);
}

function* editProductData(value) {
  // const [crncyId] = yield all([call(fetchCntryCrncy)]);

  let Val = value.data.value;

  const token = localStorage.getItem("sStraitToken");
  let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const crncyId = JSON.parse(localStorage.getItem("sStraitCurrency")).currencyId
    .id;

  try {
    const data = {
      licenseeId: userDetails.licenseeId,
      name: Val.value.name,
      ctgryId: Val.value.category,
      subCtgryId: Val.value.subcategory,
      PrdtType: Val.value.type,
      gstSlab: Val.value.gst[0].id,
      buyingPrice: Val.value.buyPrice,
      sellingPrice: Val.value.sellPrice,
      unit: Val.value.unit,
      quantity: Val.value.quantity,
      agency: Val.value.agency,

      commission: {
        platform: Val.value.platform,
        regionManger: Val.value.regionManger,
        zoneManger: Val.value.zoneManger,
        areaManger: Val.value.areaManger,
        salesExecutive: Val.value.salesExecutive,
        company: Val.value.company,
        incentivesbucket: Val.value.incentiveBckt,
        agent: 0,
      },
      currency: crncyId,
      areaRef: Val.value.ref,
      region: value.reigionIds,
      zone: value.zoneIds,
      area: value.areaIds,
      // image: imgResponse.files
      backOrder: Val.value.backOrder,
      description: Val.value.desc,
      hasVariant: Val.value.hasVariant ? true : false,
      variants: Val.variants ? Val.variants : [],
      hsnCode: Val.value.hsnCode,
    };

    const res = yield fetch(`${appConfig.ip}/product/` + Val.value.id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();
      //add_edit image
      if (Val.image && Val.image.name) {
        yield call(addeditImage, { prodId: resJSON.id, img: Val.image });
      }
      //Get Product by id
      const [productId] = yield all([
        call(getProductById, { value: resJSON.id }),
      ]);
      //Post Inventory
      yield call(postInventory, { resData: resJSON, data, isAdd: false });

      yield put({
        type: EDIT_PRODUCTS_SUCCESS_ACTION,
        payload: productId,
      });
      yield toast.success("Product updated successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: EDIT_PRODUCTS_FAIL_ACTION,
        error: err,
      });
    }
  }
}

//Update distributor no hierarchy

function* UpdateNoHierarchyProduct(value) {
  // const [crncyId] = yield all([call(fetchCntryCrncy)]);

  let Val = value.value.value;

  const token = localStorage.getItem("sStraitToken");
  let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const crncyId = JSON.parse(localStorage.getItem("sStraitCurrency")).currencyId
    .id;

  try {
    const data = {
      licenseeId: userDetails.licenseeId,
      name: Val.name,
      ctgryId: Val.category,
      subCtgryId: Val.subcategory,
      PrdtType: Val.type,
      gstSlab: Val.gst[0].id,
      buyingPrice: Val.buyPrice,
      sellingPrice: Val.sellPrice,
      unit: Val.unit,
      quantity: Val.quantity,
      agency: Val.agency,

      commission: {
        platform: 0,
        regionManger: 0,
        zoneManger: 0,
        areaManger: 0,
        salesExecutive: 0,
        company: Val.company,
        incentivesbucket: Val.incentiveBckt,
        agent: 0,
      },
      currency: crncyId,
      backOrder: Val.backOrder,
      description: Val.desc,
      hasVariant: Val.hasVariant ? true : false,
      variants: value.value.variants ? value.value.variants : [],
      hsnCode: Val.hsnCode,
    };

    const res = yield fetch(`${appConfig.ip}/product/` + Val.id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      if (value.value.image && value.value.image.name) {
        yield call(addeditImage, {
          prodId: resJSON.id,
          img: value.value.image,
        });
      }
      //get product by id
      const [productId] = yield all([
        call(getProductById, { value: resJSON.id }),
      ]);
      //post inventory
      yield call(postInventory, { resData: resJSON, data, isAdd: false });

      yield put({
        type: EDIT_PRODUCTS_SUCCESS_ACTION,
        payload: productId,
      });
      yield toast.success("Product updated successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: EDIT_PRODUCTS_FAIL_ACTION,
        error: err,
      });
    }
  }
}

// Common functions

// Add inventory function sss

function* postInventory(value) {
  if (value.resData.hasVariant) {
    const newVariants = value.data.variants.filter((item) => !item._id);

    yield all(
      newVariants.map((item) =>
        call(postIventoryData, {
          licenseeId: value.resData.licenseeId.id,
          quantity: item.quantity,
          product: value.resData.id,
          unit: value.resData.unit,
          isVariant: value.resData.hasVariant,
          variants: item,
          status: "active",
        })
      )
    );
  } else {
    if (value.isAdd) {
      const data = {
        licenseeId: value.resData.licenseeId.id,
        quantity: value.data.quantity,
        product: value.resData.id,
        unit: value.resData.unit,
        isVariant: value.resData.hasVariant,
        status: "active",
      };

      yield call(postIventoryData, data);
    }
  }
}

function* postIventoryData(data) {
  try {
    let params = {
      api: `${appConfig.ip}/inventory`,
      method: "POST",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

function* getProductById(value) {
  const token = localStorage.getItem("sStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/product/` + value.value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* fetchZoneIds(value) {
  let Val = value.value.value;
  const token = localStorage.getItem("sStraitToken");
  let licId = JSON.parse(localStorage.getItem("sStraitUserDtls")).licenseeId;
  let refIds = Val.typeIds.map((x) => x.value);
  let data = Val.ref === "region" ? "&region=" + refIds : "&zone=" + refIds;

  try {
    const res = yield fetch(
      `${appConfig.ip}/zone?limit=100&isActive=true&dummy=false` +
        data +
        "&licenseeId=" +
        licId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let zoneRes =
        Val.ref === "region"
          ? resJSON.rows.map((x) => x.id)
          : resJSON.rows.map((x) => x.region.id);

      return zoneRes;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* fetchAreaIds(value) {
  let Val = value.value.value;
  let refIds = Val.typeIds.map((x) => x.value);
  let data =
    Val.ref === "region"
      ? "&region=" + refIds
      : Val.ref === "zone"
      ? "&zone=" + refIds
      : "&area=" + refIds;

  const token = localStorage.getItem("sStraitToken");
  let licId = JSON.parse(localStorage.getItem("sStraitUserDtls")).licenseeId;

  try {
    const res = yield fetch(
      `${appConfig.ip}/area?limit=100&isActive=true&dummy=false` +
        data +
        "&licenseeId=" +
        licId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let AreaRes =
        Val.ref === "area" ? resJSON : resJSON.rows.map((x) => x.id);
      return AreaRes;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* addeditImage(value) {
  const token = localStorage.getItem("sStraitToken");

  const data = new FormData();
  // data.append('name', 'files')
  // data.append("files", value, value.name);

  data.append(value.prodId, value.img);

  const res = yield fetch(`${appConfig.ip}/product/image`, {
    headers: {
      // 'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
    method: "POST",
    body: data,
  });
  if (!res.ok) {
    let errJSON = {};
    try {
      errJSON = yield res.json();
    } catch {}
    throw Object.assign(res, errJSON);
  } else {
    const resJSON = yield res.json();

    return resJSON;
  }
}

export function* DisProductActionWatcher() {
  yield takeEvery(ADD_DISPRODUCTS_INIT_ACTION, AddDisProduct);
  yield takeEvery(EDIT_DISPRODUCTS_INIT_ACTION, EditDisProduct);
}
