import { put, takeEvery, call } from "redux-saga/effects";
import * as actionTypes from "./constants";

import { appConfig } from "../../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
var dateFormat = require("dateformat");

// common role wise url get
function* getRoleApiUrl() {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const role = localStorage.getItem("sStraitUserRole");
  let getDataUrl = [
    { role: "licensee", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "distributor", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "licenseeAccountant", url: "&licenseeId=" + userDtls.licenseeId },
    {
      role: "regionalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&region=" + userDtls.region,
    },
    {
      role: "zonalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&zone=" + userDtls.zone,
    },
    {
      role: "areaManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
    {
      role: "salesExcecutive",
      url:
        "&licenseeId=" + userDtls.licenseeId + "&salesExecutive=" + userDtls.id,
    },
    {
      role: "agent",
      url:
        "&licenseeId=" + userDtls.licenseeId + "&salesExecutive=" + userDtls.id,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);
  return apiUrl;
}

function* commonFunction(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: `${value.failAction}`, error: err });
    } else {
    }
  }
}

function* fetchDeptorsReport(value) {
  let page = (value && value.value && value.value.page) || 1;

  const apiUrl = yield call(getRoleApiUrl);
  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? null
      : value.value.searchVal;
  let productFilter =
    !value || !value.value || !value.value.productFilter
      ? null
      : value.value.productFilter;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let filterValDate =
    !value || !value.value || !value.value.dateFilter
      ? null
      : value.value.dateFilter;

  let custFilter =
    !value || !value.value || !value.value.custFilter
      ? null
      : value.value.custFilter;

  let _url = `${appConfig.ip}/order?limit=10&paymentStatus=partial&status=created&page=${page}${apiUrl.url}`;

  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }
  if (productFilter) {
    _url = `${_url}&products=${productFilter}`;
  }
  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  }
  if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  }
  if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }
  if (filterValDate) {
    _url = `${_url}${filterValDate}`;
  }
  if (custFilter) {
    _url = `${_url}&customer=${custFilter}`;
  }
  try {
    let params = {
      api: _url,
      method: "GET",
      sucessAction: actionTypes.FETCH_DEBTORS_REPORT_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_DEBTORS_REPORT_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);
  } catch (e) {}
}

//order pay amount
function* updateDebtorsPayAmount(value) {
  const remainAmount = Number.parseFloat(
    value.value.ItemList.remainAmount - value.value.values.amount
  ).toFixed(2);
  const data = {
    remainAmount: remainAmount,
    paymentStatus:
      remainAmount == 0.0 ? "full" : value.value.ItemList.paymentStatus,
  };
  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/order/${value.value.ItemList.id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      const paymntData = {
        customer: resJSON.customer.id,
        order: resJSON.id,
        salesExecutive: resJSON.salesExecutive.id,
        licenseeId: resJSON.licenseeId.id,
        region: resJSON.region ? resJSON.region.id : null,
        zone: resJSON.zone ? resJSON.zone.id : null,
        area: resJSON.area ? resJSON.area.id : null,
        // paymentMode: resJSON.paymentMode.id,
        paymentMode: value.value.values.paymentMode[0].id,
        currency: resJSON.currency.id,
        amount: value.value.values.amount,
      };
      yield call(createPayment, paymntData);

      yield put({
        type: actionTypes.UPDATE_DEBTORS_PAY_AMOUNT_SUCCESS_ACTION,
        payload: resJSON,
      });

      yield toast.success("Payment updated successfully", {
        autoClose: 3000,
      });
      yield put({
        type: actionTypes.FETCH_DEBTORS_REPORT_INIT_ACTION,
        value: { page: 1 },
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.UPDATE_DEBTORS_PAY_AMOUNT_FAIL_ACTION,
        error: err,
      });
    }
  }
}

//Payment create while creating an order
function* createPayment(value) {
  try {
    let params = {
      api: `${appConfig.ip}/payment`,
      method: "POST",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(value),
    };
    yield call(commonFunction, params);
  } catch (e) {}
}

function* fetchDeptorsReportById(value) {
  try {
    let params = {
      api: `${appConfig.ip}/order/` + value.id,
      method: "GET",
      sucessAction: actionTypes.FETCH_DEBTORS_REPORT_BY_ID_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_DEBTORS_REPORT_BY_ID_FAIL_ACTION,
      body: null,
    };
    const Res = yield call(commonFunction, params);
    return Res;
  } catch (e) {}
}

function* exprtDebtorsData(value) {
  let fileName = "Debtors Report";
  const token = localStorage.getItem("sStraitToken");
  const apiUrl = yield call(getRoleApiUrl);

  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? null
      : value.value.searchVal;
  let productFilter =
    !value || !value.value || !value.value.productFilter
      ? null
      : value.value.productFilter;
  let filterValDate =
    !value || !value.value || !value.value.dateFilter
      ? null
      : value.value.dateFilter;

  let custFilter =
    !value || !value.value || !value.value.custFilter
      ? null
      : value.value.custFilter;

  let _url = `${appConfig.ip}/order?paymentStatus=partial&status=created&limit=100${apiUrl.url}`;

  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }
  if (productFilter) {
    _url = `${_url}&products=${productFilter}`;
  }

  if (filterValDate) {
    _url = `${_url}${filterValDate}`;
  }
  if (custFilter) {
    _url = `${_url}&customer=${custFilter}`;
  }
  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let csvData = [];

      resJSON.rows.map((item) => {
        csvData.push({
          ORDER_ID: item.refId,
          CUSTOMER_NAME: item && item.customer ? item.customer.name : "NIL",

          TOTAL_PRICE:
            item && item.currency && item.currency.symbol + item.totalPrice,

          REMAINING_AMOUNT: item && item.currency.symbol + item.remainAmount,
          LICENSEE_NAME:
            item.licenseeId != null ? item.licenseeId.orgName : "NIL",

          STATUS: item.status,
          ORDER_DATE: dateFormat(item.createdAt, "mediumDate"),
        });
      });

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      yield put({
        type: actionTypes.EXPORT_DEBTORS_REPORT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.EXPORT_DEBTORS_REPORT_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

export function* debtorsReportActionWatcher() {
  yield takeEvery(
    actionTypes.FETCH_DEBTORS_REPORT_INIT_ACTION,
    fetchDeptorsReport
  );
  yield takeEvery(
    actionTypes.UPDATE_DEBTORS_PAY_AMOUNT_INIT_ACTION,
    updateDebtorsPayAmount
  );
  yield takeEvery(
    actionTypes.FETCH_DEBTORS_REPORT_BY_ID_INIT_ACTION,
    fetchDeptorsReportById
  );
  yield takeEvery(
    actionTypes.EXPORT_DEBTORS_REPORT_INIT_ACTION,
    exprtDebtorsData
  );
}
