import React, { useEffect, useState } from "react";
import { Row, Col, Form as forms } from "react-bootstrap";
import { Formik, Form } from "formik";

import * as Yup from "yup";

import { TextField } from "../../Common/TextField";

import { DropDown } from "../../Common/DropDown";

// const validate = Yup.object({
//   orgName: Yup.string().required(" Organization  is required"),
//   country: Yup.string().required("Country is required"),
//   state: Yup.string().required("State  is required"),
//   district: Yup.string().required("District  is required"),
// });

const OrganizationDetails = ({ clickHandler, ...props }) => {
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [showType, setShowType] = useState("");
  const [showNetWork, setShowNetWork] = useState(false);
  useEffect(() => {
    props.fetchCountryInSignup();
  }, []);
  const validate = Yup.object({
    orgName: Yup.string().required("Type Organization Name"),
  });
  const handleSelectCountry = (e, formik) => {
    let id = e.target.value || "";
    formik.values.country = id;
    setCountry((formik.values.country = id));

    formik.values.state = "";
    if (id) {
      formik.errors.country && delete formik.errors.country;
    }
    props.fetchStateInSignup(id);
  };

  const handleSelectState = (e, formik) => {
    let id = e.target.value || "";
    formik.values.state = id;
    setState((formik.values.state = id));
    formik.values.district = "";
    if (id) {
      formik.errors.state && delete formik.errors.state;
    }
    props.fetchDistrictInSignup(id);
  };
  useEffect(() => {
    if (props.item && props.item.type) {
      setShowType(props.item.type);
      setShowNetWork(props.item.networkHierarchy);
    }
  }, []);
  return (
    <div>
      <Formik
        initialValues={{
          orgName: (props.item && props.item.orgName) || "",
          country: (props.item && props.item.country) || "",
          state: (props.item && props.item.state && props.item.state) || "",
          district:
            (props.item && props.item.district && props.item.district) || "",
          addr1: (props.item && props.item.addr1) || "",
          gstin: (props.item && props.item.gstin) || "",
          type: (props.item && props.item.type) || "",
          networkHierarchy:
            (props.item && props.item.networkHierarchy) || false,
        }}
        validationSchema={validate}
        onSubmit={(value) => {
          clickHandler(3, { ...value });
        }}
      >
        {(formik) => (
          <div>
            <div className="signup-page-div">
              <Form>
                <Row>
                  <Col lg={4} md={12} sm={12} xs={12}>
                    <TextField
                      label="Organization Name"
                      name="orgName"
                      type="text"
                      isRequired={true}
                    />
                  </Col>

                  <Col lg={4} md={12} sm={12} xs={12}>
                    <TextField label="Address" name="addr1" type="text" />
                  </Col>
                  <Col lg={4} md={12} sm={12} xs={12}>
                    <TextField label="GSTIN" name="gstin" type="text" />
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} md={12} sm={12} xs={12}>
                    <DropDown
                      label="Country"
                      name="country"
                      // isRequired={true}
                      data={props.countryList}
                      defValue="Select Country"
                      onChange={(e) => {
                        handleSelectCountry(e, formik);
                      }}
                    />
                  </Col>
                  <Col lg={4} md={12} sm={12} xs={12}>
                    <DropDown
                      label="State"
                      name="state"
                      // isRequired={true}
                      data={props.stateList}
                      defValue="Select State"
                      onChange={(e) => {
                        handleSelectState(e, formik);
                      }}
                    />
                  </Col>
                  <Col lg={4} md={12} sm={12} xs={12}>
                    <DropDown
                      label="District"
                      name="district"
                      // isRequired={true}
                      data={props.districtList}
                      defValue="Select District"
                    />
                  </Col>
                </Row>

                <div className="row buttons-step-div">
                  <Col lg={8} md={12} sm={12} xs={12}>
                    <div className="back-to-login">
                      <button
                        className="prev-btn"
                        type="submit"
                        onClick={() => clickHandler(1, formik.values)}
                      >
                        Back
                      </button>
                    </div>
                  </Col>
                  <Col lg={4} md={12} sm={12} xs={12}>
                    <button className=" next-btn">Next</button>
                  </Col>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};
export default OrganizationDetails;
