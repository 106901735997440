export const FETCH_CUSTOMERS_INIT_ACTION = "FETCH_CUSTOMERS_INIT_ACTION";
export const FETCH_CUSTOMERS_SUCCESS_ACTION =
  FETCH_CUSTOMERS_INIT_ACTION + "_SUCCESS";
export const FETCH_CUSTOMERS_FAIL_ACTION =
  FETCH_CUSTOMERS_INIT_ACTION + "_ERROR";

export const ADD_CUSTOMERS_INIT_ACTION = "ADD_CUSTOMERS_INIT_ACTION";
export const ADD_CUSTOMERS_SUCCESS_ACTION =
  ADD_CUSTOMERS_INIT_ACTION + "_SUCCESS";
export const ADD_CUSTOMERS_FAIL_ACTION = ADD_CUSTOMERS_INIT_ACTION + "_ERROR";

export const EDIT_CUSTOMERS_INIT_ACTION = "EDIT_CUSTOMERS_INIT_ACTION";
export const EDIT_CUSTOMERS_SUCCESS_ACTION =
  EDIT_CUSTOMERS_INIT_ACTION + "_SUCCESS";
export const EDIT_CUSTOMERS_FAIL_ACTION = EDIT_CUSTOMERS_INIT_ACTION + "_ERROR";

export const FETCH_CUSTOMERBYID_INIT_ACTION = "FETCH_CUSTOMERBYID_INIT_ACTION";
export const FETCH_CUSTOMERBYID_SUCCESS_ACTION =
  FETCH_CUSTOMERBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_CUSTOMERBYID_FAIL_ACTION =
  FETCH_CUSTOMERBYID_INIT_ACTION + "_ERROR";

export const FETCH_REGIONDROPDOWN_INIT_ACTION =
  "FETCH_REGIONDROPDOWN_INIT_ACTION";
export const FETCH_REGIONDROPDOWN_SUCCESS_ACTION =
  FETCH_REGIONDROPDOWN_INIT_ACTION + "_SUCCESS";
export const FETCH_REGIONDROPDOWN_FAIL_ACTION =
  FETCH_REGIONDROPDOWN_INIT_ACTION + "_ERROR";

export const FETCH_AREADROPDOWN_INIT_ACTION = "FETCH_AREADROPDOWN_INIT_ACTION";
export const FETCH_AREADROPDOWN_SUCCESS_ACTION =
  FETCH_AREADROPDOWN_INIT_ACTION + "_SUCCESS";
export const FETCH_AREADROPDOWN_FAIL_ACTION =
  FETCH_AREADROPDOWN_INIT_ACTION + "_ERROR";

export const FETCH_PRODUCTS_BY_CUSTOMER_INIT_ACTION =
  "FETCH_PRODUCTS_BY_CUSTOMER_INIT_ACTION";
export const FETCH_PRODUCTS_BY_CUSTOMER_SUCCESS_ACTION =
  FETCH_PRODUCTS_BY_CUSTOMER_INIT_ACTION + "_SUCCESS";
export const FETCH_PRODUCTS_BY_CUSTOMER_FAIL_ACTION =
  FETCH_PRODUCTS_BY_CUSTOMER_INIT_ACTION + "_ERROR";

export const ADD_CUSTOMER_DISCOUNT_INIT_ACTION =
  "ADD_CUSTOMER_DISCOUNT_INIT_ACTION";
export const ADD_CUSTOMER_DISCOUNT_SUCCESS_ACTION =
  ADD_CUSTOMER_DISCOUNT_INIT_ACTION + "_SUCCESS";
export const ADD_CUSTOMER_DISCOUNT_FAIL_ACTION =
  ADD_CUSTOMER_DISCOUNT_INIT_ACTION + "_ERROR";

export const FETCH_CUSTOMER_DISCOUNT_INIT_ACTION =
  "FETCH_CUSTOMER_DISCOUNT_INIT_ACTION";
export const FETCH_CUSTOMER_DISCOUNT_SUCCESS_ACTION =
  FETCH_CUSTOMER_DISCOUNT_INIT_ACTION + "_SUCCESS";
export const FETCH_CUSTOMER_DISCOUNT_FAIL_ACTION =
  FETCH_CUSTOMER_DISCOUNT_INIT_ACTION + "_ERROR";

export const EDIT_CUSTOMER_DISCOUNT_INIT_ACTION =
  "EDIT_CUSTOMER_DISCOUNT_INIT_ACTION";
export const EDIT_CUSTOMER_DISCOUNT_SUCCESS_ACTION =
  EDIT_CUSTOMER_DISCOUNT_INIT_ACTION + "_SUCCESS";
export const EDIT_CUSTOMER_DISCOUNT_FAIL_ACTION =
  EDIT_CUSTOMER_DISCOUNT_INIT_ACTION + "_ERROR";

export const DELETE_CUSTOMER_DISCOUNT_INIT_ACTION =
  "DELETE_CUSTOMER_DISCOUNT_INIT_ACTION";
export const DELETE_CUSTOMER_DISCOUNT_SUCCESS_ACTION =
  DELETE_CUSTOMER_DISCOUNT_INIT_ACTION + "_SUCCESS";
export const DELETE_CUSTOMER_DISCOUNT_FAIL_ACTION =
  DELETE_CUSTOMER_DISCOUNT_INIT_ACTION + "_ERROR";

export const CHECK_PRODUCTS_BY_CUSTOMER_DISCOUNT_INIT_ACTION =
  "CHECK_PRODUCTS_BY_CUSTOMER_DISCOUNT_INIT_ACTION";
export const CHECK_PRODUCTS_BY_CUSTOMER_DISCOUNT_SUCCESS_ACTION =
  CHECK_PRODUCTS_BY_CUSTOMER_DISCOUNT_INIT_ACTION + "_SUCCESS";
export const CHECK_PRODUCTS_BY_CUSTOMER_DISCOUNT_FAIL_ACTION =
  CHECK_PRODUCTS_BY_CUSTOMER_DISCOUNT_INIT_ACTION + "_ERROR";

export const CLEAR_AREADROPDOWN_INIT_ACTION = "CLEAR_AREADROPDOWN_INIT_ACTION";

export const TRIGGER_CUSTOMER_DISCOUNT_MODAL_INIT_ACTION =
  "TRIGGER_CUSTOMER_DISCOUNT_MODAL_INIT_ACTION";
export const TRIGGER_CUSTOMER_DISCOUNT_MODAL_SUCCESS_ACTION =
  TRIGGER_CUSTOMER_DISCOUNT_MODAL_INIT_ACTION + "_SUCCESS";

export const FETCH_CUSTOMER_ORDER_INIT_ACTION =
  "FETCH_CUSTOMER_ORDER_INIT_ACTION";
export const FETCH_CUSTOMER_ORDER_SUCCESS_ACTION =
  FETCH_CUSTOMER_ORDER_INIT_ACTION + "_SUCCESS";
export const FETCH_CUSTOMER_ORDER_FAIL_ACTION =
  FETCH_CUSTOMER_ORDER_INIT_ACTION + "_ERROR";

export const FETCH_TOTAL_REMAINING_AMT_INIT_ACTION =
  "FETCH_TOTAL_REMAINING_AMT_INIT_ACTION";
export const FETCH_TOTAL_REMAINING_AMT_SUCCESS_ACTION =
  FETCH_TOTAL_REMAINING_AMT_INIT_ACTION + "_SUCCESS";
export const FETCH_TOTAL_REMAINING_AMT_FAIL_ACTION =
  FETCH_TOTAL_REMAINING_AMT_INIT_ACTION + "_ERROR";

export const EXPORT_LEDGER_INIT_ACTION = "EXPORT_LEDGER_INIT_ACTION";
export const EXPORT_LEDGER_SUCCESS_ACTION =
  EXPORT_LEDGER_INIT_ACTION + "_SUCCESS";
export const EXPORT_LEDGER_FAIL_ACTION = EXPORT_LEDGER_INIT_ACTION + "_ERROR";

export const FETCH_CUSTOMER_CREDIT_NOTES_INIT_ACTION =
  "FETCH_CUSTOMER_CREDIT_NOTES_INIT_ACTION";
export const FETCH_CUSTOMER_CREDIT_NOTES_SUCCESS_ACTION =
  FETCH_CUSTOMER_CREDIT_NOTES_INIT_ACTION + "_SUCCESS";
export const FETCH_CUSTOMER_CREDIT_NOTES_FAIL_ACTION =
  FETCH_CUSTOMER_CREDIT_NOTES_INIT_ACTION + "_ERROR";

export const FETCH_CREDIT_NOTE_BY_ID_INIT_ACTION =
  "FETCH_CREDIT_NOTE_BY_ID_INIT_ACTION";
export const FETCH_CREDIT_NOTE_BY_ID_SUCCESS_ACTION =
  FETCH_CREDIT_NOTE_BY_ID_INIT_ACTION + "_SUCCESS";
export const FETCH_CREDIT_NOTE_BY_ID_FAIL_ACTION =
  FETCH_CREDIT_NOTE_BY_ID_INIT_ACTION + "_ERROR";

export const FETCH_CREDIT_NOTE_CONFIG_INIT_ACTION =
  "FETCH_CREDIT_NOTE_CONFIG_INIT_ACTION";
export const FETCH_CREDIT_NOTE_CONFIG_SUCCESS_ACTION =
  FETCH_CREDIT_NOTE_CONFIG_INIT_ACTION + "_SUCCESS";
export const FETCH_CREDIT_NOTE_CONFIG_FAIL_ACTION =
  FETCH_CREDIT_NOTE_CONFIG_INIT_ACTION + "_ERROR";

export const FETCH_ROUTES_BY_CUSTOMER_INIT_ACTION =
  "FETCH_ROUTES_BY_CUSTOMER_INIT_ACTION";
export const FETCH_ROUTES_BY_CUSTOMER_SUCCESS_ACTION =
  FETCH_ROUTES_BY_CUSTOMER_INIT_ACTION + "_SUCCESS";
export const FETCH_ROUTES_BY_CUSTOMER_FAIL_ACTION =
  FETCH_ROUTES_BY_CUSTOMER_INIT_ACTION + "_ERROR";

export const CLOSE_CUSTADD_MODEL_ACTION = "CLOSE_CUSTADD_MODEL_ACTION";

export function fetchCustomers(value) {
  return {
    type: FETCH_CUSTOMERS_INIT_ACTION,
    value: value,
  };
}

export function AddCustomers(value) {
  return {
    type: ADD_CUSTOMERS_INIT_ACTION,
    value: value,
  };
}

export function EditCustomers(value) {
  return {
    type: EDIT_CUSTOMERS_INIT_ACTION,
    value: value,
  };
}

export function getCustomerById(value) {
  return {
    type: FETCH_CUSTOMERBYID_INIT_ACTION,
    value: value,
  };
}

export function fetchRegionDropdown() {
  return {
    type: FETCH_REGIONDROPDOWN_INIT_ACTION,
  };
}

export function fetchAreaDropdown(value) {
  return {
    type: FETCH_AREADROPDOWN_INIT_ACTION,
    value: value,
  };
}

export function clearAreaDropdown() {
  return {
    type: CLEAR_AREADROPDOWN_INIT_ACTION,
  };
}

export function fetchProductsByCustomer(value) {
  return {
    type: FETCH_PRODUCTS_BY_CUSTOMER_INIT_ACTION,
    value: value,
  };
}
export function addCustomerDiscount(value, custId) {
  return {
    type: ADD_CUSTOMER_DISCOUNT_INIT_ACTION,
    value: value,
    custId: custId,
  };
}

export function editCustomerDiscount(id, value, custId) {
  return {
    type: EDIT_CUSTOMER_DISCOUNT_INIT_ACTION,
    id: id,
    value: value,
    custId: custId,
  };
}

export function fetchCustomerDiscount(value) {
  return {
    type: FETCH_CUSTOMER_DISCOUNT_INIT_ACTION,
    value: value,
  };
}

export function deleteCustomerDiscount(value, custId) {
  return {
    type: DELETE_CUSTOMER_DISCOUNT_INIT_ACTION,
    value: value,
    custId: custId,
  };
}

export function checkProdoctByCustomerDiscount(value, custId) {
  return {
    type: CHECK_PRODUCTS_BY_CUSTOMER_DISCOUNT_INIT_ACTION,
    value: value,
    custId: custId,
  };
}
export function triggerCustomerDiscountModal(status) {
  return {
    type: TRIGGER_CUSTOMER_DISCOUNT_MODAL_SUCCESS_ACTION,
    status: status,
  };
}

export function fetchCustomerOrder(value) {
  return {
    type: FETCH_CUSTOMER_ORDER_INIT_ACTION,
    value: value,
  };
}

export function exprtLedger(value) {
  return {
    type: EXPORT_LEDGER_INIT_ACTION,
    value: value,
  };
}

export function fetchTotalRemainAmt(value) {
  return {
    type: FETCH_TOTAL_REMAINING_AMT_INIT_ACTION,
    value: value,
  };
}

export function fetchCustomerCreditNote(value) {
  return {
    type: FETCH_CUSTOMER_CREDIT_NOTES_INIT_ACTION,
    value: value,
  };
}

export function getCreditNoteById(id) {
  return {
    type: FETCH_CREDIT_NOTE_BY_ID_INIT_ACTION,
    id: id,
  };
}

export function fetchCreditNoteConfig(value) {
  return {
    type: FETCH_CREDIT_NOTE_CONFIG_INIT_ACTION,
    value: value,
  };
}

export function closeModalAction(value) {
  return {
    type: CLOSE_CUSTADD_MODEL_ACTION,
    triggerValue: value,
  };
}

export function fetchRouteByCustomer(value) {
  return {
    type: FETCH_ROUTES_BY_CUSTOMER_INIT_ACTION,
    triggerValue: value,
  };
}
