import { put, takeEvery, call } from "redux-saga/effects";
import * as actionTypes from "./constants";

import { appConfig } from "../../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* commonFunction(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: `${value.failAction}`, error: err });
    } else {
    }
  }
}

function* fetchStockMovement(params) {
  let page = (params.param && params.param.page) || 1;
  let q = (params.param && params.param.search) || "";
  let statusFilter = (params.param && params.param.statusFilter) || "";
  let dateFilter = (params.param && params.param.filterValDate) || "";

  let userRole = localStorage.getItem("sStraitUserRole");
  let userId = localStorage.getItem("sStraitUserId");
  let licensee = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

  let licenseeFilterparam = "";
  let agentFilterParam = "";
  let statusFilterParam = "";
  if (userRole === "distributor" || userRole === "licenseeAccountant") {
    licenseeFilterparam = `&licenseeId=${licensee.id}`;
  }
  if (userRole === "agent" || userRole === "salesExecutive") {
    agentFilterParam = `&allocatedPerson=${userId}`;
  }
  if (statusFilter) {
    statusFilterParam = `&status=${statusFilter}`;
  }

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/stockMovement?isActive=true&limit=10&page=${page}&search=${q}${licenseeFilterparam}${agentFilterParam}${statusFilterParam}${dateFilter}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_STOCK_MOVEMENT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_STOCK_MOVEMENT_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* addStockMovement(value) {
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  let products = [];

  value.value.productList.map((item) => {
    products.push({
      productId: item.product.id,
      name: item.product.name,
      allocatedQty: item.outStandingQty
        ? item.outStandingQty + item.qty
        : item.qty,
      variants: item.product.hasVariant ? item.inventory.variants : {},
    });
  });

  let routes = [];
  value.value.routes.map((item) => {
    routes.push(item.value);
  });

  let data = {
    description: value.value.description,
    licenseeId: licData.id,
    allocatedPerson: value.value.allocatedPerson[0].id,
    products: products,
    routes: routes,
  };

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/stockMovement`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON1 = {};
      try {
        errJSON1 = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON1);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_STOCK_MOVEMENT_INIT_ACTION,
        value: { page: 1 },
      });
      yield put({
        type: actionTypes.ADD_STOCK_MOVEMENT_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Stock added successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.ADD_STOCK_MOVEMENT_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchRouteByUserId(param) {
  let id = param.id;

  try {
    let params = {
      api: `${appConfig.ip}/users/` + id,
      method: "GET",
      sucessAction: actionTypes.FETCH_ROUTE_BY_USERID_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_ROUTE_BY_USERID_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

function* fetchStockMovementById(param) {
  let id = param.id;

  try {
    let params = {
      api: `${appConfig.ip}/stockMovement/` + id,
      method: "GET",
      sucessAction: actionTypes.FETCH_STOCK_MOVEMENT_BY_ID_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_STOCK_MOVEMENT_BY_ID_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

function* editStockMovement(value) {
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  let products = [];
  let id = value && value.value && value.value.id;

  value &&
    value.value &&
    value.value.productList.map((item) => {
      products.push({
        productId: item.product.id,
        name: item.product.name,
        allocatedQty: item.qty,
        variants:
          item.product.hasVariant || item.hasVariant
            ? item.inventory.variants
            : {},
      });
    });

  let routes = [];
  value &&
    value.value &&
    value.value.routes.map((item) => {
      routes.push(item.value);
    });

  let data = {
    description: value && value.value && value.value.description,
    licenseeId: licData.id,
    allocatedPerson: value && value.value && value.value.allocatedPerson[0].id,
    products: products,
    routes: routes,
  };

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/stockMovement/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield toast.success("Stock  edited successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();
      yield put({ type: actionTypes.FETCH_STOCK_MOVEMENT_INIT_ACTION });
      yield put({
        type: actionTypes.EDIT_STOCK_MOVEMENT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.EDIT_STOCK_MOVEMENT_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* closeStockMovement(value) {
  let products = [];
  let id = value && value.value && value.value.id;

  value &&
    value.value &&
    value.value.productList.map((item) => {
      products.push({
        productId: item.productId.id,
        name: item.productId.name,
        allocatedQty: item.allocatedQty,
        returnQty: item.returnQty,
        outstandingQty: item.outstandingQty,
        soldQty: item.soldQty ? item.soldQty : 0,
        variants: item.variants ? item.variants : {},
      });
    });

  let data = {
    products: products,
    status: "closed",
    closedAt: new Date(),
  };

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/stockMovement/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield toast.success("Stock  closed successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();
      yield put({ type: actionTypes.FETCH_STOCK_MOVEMENT_INIT_ACTION });
      yield put({
        type: actionTypes.CLOSE_STOCK_MOVEMENT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.CLOSE_STOCK_MOVEMENT_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}
function* fetchStockMovementByPesron(param) {
  let id = param && param.param;
  try {
    let params = {
      api: `${appConfig.ip}/stockMovement?allocatedPerson=${id}`,
      method: "GET",
      sucessAction: actionTypes.FETCH_STOCK_MOVEMENT_BY_PESRON_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_STOCK_MOVEMENT_BY_PERSON_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

function* approveStockMovement(value) {
  let id = value && value.value;

  let data = {
    status: "verified",
  };

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/stockMovement/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield toast.success("Stock  approved successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();
      yield put({ type: actionTypes.FETCH_STOCK_MOVEMENT_INIT_ACTION });
      yield put({
        type: actionTypes.APPROVE_STOCK_MOVEMENT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.APPROVE_STOCK_MOVEMENT_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* fetchAvailablePrdctQty(param) {
  let id = param && param.value;
  try {
    let params = {
      api: `${appConfig.ip}/stockMovement/allocatedStock/` + id,
      method: "GET",
      sucessAction: actionTypes.FETCH_AVAILABLE_PRODUCT_QTY_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_AVAILABLE_PRODUCT_QTY_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

function* fetchOutstandingQty(param) {
  let id = param && param.value && param.value.id;
  let allocatedPerson =
    (param && param.value && param.value.allocatedPerson) || "";

  try {
    let params = {
      api: `${appConfig.ip}/stockMovement/outStanding/${id}?allocatedPerson=${allocatedPerson}`,
      method: "GET",
      sucessAction: actionTypes.FETCH_OUTSTANDING_QTY_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_OUTSTANDING_QTY_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

function* fetchAvailableVariantQty(param) {
  let id = param && param.value && param.value.productId;
  let variantId = param && param.value && param.value.variantId;
  try {
    let params = {
      api: `${appConfig.ip}/stockMovement/allocatedStock/variant/${id}?variantId=${variantId}`,
      method: "GET",
      sucessAction: actionTypes.FETCH_AVAILABLE_VARIANT_QTY_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_AVAILABLE_VARIANT_QTY_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}

function* fetchVariantOutstandingQty(param) {
  let id = param && param.value && param.value.id;
  let allocatedPerson =
    (param && param.value && param.value.allocatedPerson) || "";
  let variantId = (param && param.value && param.value.variantId) || "";

  try {
    let params = {
      api: `${appConfig.ip}/stockMovement/outStanding/variant/${id}?variantId=${variantId}&allocatedPerson=${allocatedPerson}`,
      method: "GET",
      sucessAction: actionTypes.FETCH_VARIANT_OUTSTANDING_QTY_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_VARIANT_OUTSTANDING_QTY_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);

    return res;
  } catch (e) {}
}
export function* stockMovementActionWatcher() {
  yield takeEvery(
    actionTypes.FETCH_STOCK_MOVEMENT_INIT_ACTION,
    fetchStockMovement
  );
  yield takeEvery(actionTypes.ADD_STOCK_MOVEMENT_INIT_ACTION, addStockMovement);
  yield takeEvery(
    actionTypes.FETCH_ROUTE_BY_USERID_INIT_ACTION,
    fetchRouteByUserId
  );
  yield takeEvery(
    actionTypes.FETCH_STOCK_MOVEMENT_BY_ID_INIT_ACTION,
    fetchStockMovementById
  );
  yield takeEvery(
    actionTypes.EDIT_STOCK_MOVEMENT_INIT_ACTION,
    editStockMovement
  );
  yield takeEvery(
    actionTypes.CLOSE_STOCK_MOVEMENT_INIT_ACTION,
    closeStockMovement
  );
  yield takeEvery(
    actionTypes.FETCH_STOCK_MOVEMENT_BY_PERSON_INIT_ACTION,
    fetchStockMovementByPesron
  );
  yield takeEvery(
    actionTypes.APPROVE_STOCK_MOVEMENT_INIT_ACTION,
    approveStockMovement
  );
  yield takeEvery(
    actionTypes.FETCH_AVAILABLE_PRODUCT_QTY_INIT_ACTION,
    fetchAvailablePrdctQty
  );
  yield takeEvery(
    actionTypes.FETCH_OUTSTANDING_QTY_INIT_ACTION,
    fetchOutstandingQty
  );
  yield takeEvery(
    actionTypes.FETCH_AVAILABLE_VARIANT_QTY_INIT_ACTION,
    fetchAvailableVariantQty
  );
  yield takeEvery(
    actionTypes.FETCH_VARIANT_OUTSTANDING_QTY_INIT_ACTION,
    fetchVariantOutstandingQty
  );
}
