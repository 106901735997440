export const FETCH_STOCK_MOVEMENT_INIT_ACTION =
  "FETCH_STOCK_MOVEMENT_INIT_ACTION";
export const FETCH_STOCK_MOVEMENT_SUCCESS_ACTION =
  FETCH_STOCK_MOVEMENT_INIT_ACTION + "_SUCCESS";
export const FETCH_STOCK_MOVEMENT_FAIL_ACTION =
  FETCH_STOCK_MOVEMENT_INIT_ACTION + "_ERROR";

export const ADD_STOCK_MOVEMENT_INIT_ACTION = "ADD_STOCK_MOVEMENT_INIT_ACTION";
export const ADD_STOCK_MOVEMENT_SUCCESS_ACTION =
  ADD_STOCK_MOVEMENT_INIT_ACTION + "_SUCCESS";
export const ADD_STOCK_MOVEMENT_FAIL_ACTION =
  ADD_STOCK_MOVEMENT_INIT_ACTION + "_ERROR";

export const FETCH_ROUTE_BY_USERID_INIT_ACTION =
  "FETCH_ROUTE_BY_USERID_INIT_ACTION";
export const FETCH_ROUTE_BY_USERID_SUCCESS_ACTION =
  FETCH_ROUTE_BY_USERID_INIT_ACTION + "_SUCCESS";
export const FETCH_ROUTE_BY_USERID_FAIL_ACTION =
  FETCH_ROUTE_BY_USERID_INIT_ACTION + "_ERROR";

export const FETCH_STOCK_MOVEMENT_BY_ID_INIT_ACTION =
  "FETCH_STOCK_MOVEMENT_BY_ID_INIT_ACTION";
export const FETCH_STOCK_MOVEMENT_BY_ID_SUCCESS_ACTION =
  FETCH_STOCK_MOVEMENT_BY_ID_INIT_ACTION + "_SUCCESS";
export const FETCH_STOCK_MOVEMENT_BY_ID_FAIL_ACTION =
  FETCH_STOCK_MOVEMENT_BY_ID_INIT_ACTION + "_ERROR";

export const EDIT_STOCK_MOVEMENT_INIT_ACTION =
  "EDIT_STOCK_MOVEMENT_INIT_ACTION";
export const EDIT_STOCK_MOVEMENT_SUCCESS_ACTION =
  EDIT_STOCK_MOVEMENT_INIT_ACTION + "_SUCCESS";
export const EDIT_STOCK_MOVEMENT_FAIL_ACTION =
  EDIT_STOCK_MOVEMENT_INIT_ACTION + "_ERROR";

export const CLOSE_STOCK_MOVEMENT_INIT_ACTION =
  "CLOSE_STOCK_MOVEMENT_INIT_ACTION";
export const CLOSE_STOCK_MOVEMENT_SUCCESS_ACTION =
  CLOSE_STOCK_MOVEMENT_INIT_ACTION + "_SUCCESS";
export const CLOSE_STOCK_MOVEMENT_FAIL_ACTION =
  CLOSE_STOCK_MOVEMENT_INIT_ACTION + "_ERROR";

export const FETCH_STOCK_MOVEMENT_BY_PERSON_INIT_ACTION =
  "FETCH_STOCK_MOVEMENT_BY_PERSON_INIT_ACTION";
export const FETCH_STOCK_MOVEMENT_BY_PESRON_SUCCESS_ACTION =
  FETCH_STOCK_MOVEMENT_BY_PERSON_INIT_ACTION + "_SUCCESS";
export const FETCH_STOCK_MOVEMENT_BY_PERSON_FAIL_ACTION =
  FETCH_STOCK_MOVEMENT_BY_PERSON_INIT_ACTION + "_ERROR";

export const APPROVE_STOCK_MOVEMENT_INIT_ACTION =
  "APPROVE_STOCK_MOVEMENT_INIT_ACTION";
export const APPROVE_STOCK_MOVEMENT_SUCCESS_ACTION =
  APPROVE_STOCK_MOVEMENT_INIT_ACTION + "_SUCCESS";
export const APPROVE_STOCK_MOVEMENT_FAIL_ACTION =
  APPROVE_STOCK_MOVEMENT_INIT_ACTION + "_ERROR";

export const FETCH_AVAILABLE_PRODUCT_QTY_INIT_ACTION =
  "FETCH_AVAILABLE_PRODUCT_QTY_INIT_ACTION";
export const FETCH_AVAILABLE_PRODUCT_QTY_SUCCESS_ACTION =
  FETCH_AVAILABLE_PRODUCT_QTY_INIT_ACTION + "_SUCCESS";
export const FETCH_AVAILABLE_PRODUCT_QTY_FAIL_ACTION =
  FETCH_AVAILABLE_PRODUCT_QTY_INIT_ACTION + "_ERROR";

export const FETCH_OUTSTANDING_QTY_INIT_ACTION =
  "FETCH_OUTSTANDING_QTY_INIT_ACTION";
export const FETCH_OUTSTANDING_QTY_SUCCESS_ACTION =
  FETCH_OUTSTANDING_QTY_INIT_ACTION + "_SUCCESS";
export const FETCH_OUTSTANDING_QTY_FAIL_ACTION =
  FETCH_OUTSTANDING_QTY_INIT_ACTION + "_ERROR";

export const FETCH_AVAILABLE_VARIANT_QTY_INIT_ACTION =
  "FETCH_AVAILABLE_VARIANT_QTY_INIT_ACTION";
export const FETCH_AVAILABLE_VARIANT_QTY_SUCCESS_ACTION =
  FETCH_AVAILABLE_VARIANT_QTY_INIT_ACTION + "_SUCCESS";
export const FETCH_AVAILABLE_VARIANT_QTY_FAIL_ACTION =
  FETCH_AVAILABLE_VARIANT_QTY_INIT_ACTION + "_ERROR";

export const FETCH_VARIANT_OUTSTANDING_QTY_INIT_ACTION =
  "FETCH_VARIANT_OUTSTANDING_QTY_INIT_ACTION";
export const FETCH_VARIANT_OUTSTANDING_QTY_SUCCESS_ACTION =
  FETCH_VARIANT_OUTSTANDING_QTY_INIT_ACTION + "_SUCCESS";
export const FETCH_VARIANT_OUTSTANDING_QTY_FAIL_ACTION =
  FETCH_VARIANT_OUTSTANDING_QTY_INIT_ACTION + "_ERROR";
