import { put, takeEvery, call } from "redux-saga/effects";
import * as actionTypes from "./constants";

import { appConfig } from "../../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

var dateFormat = require("dateformat");

function* fetchUser(params) {
  let page = (params.params && params.params.page) || 1;
  let q = (params.params && params.params.search) || "";

  let userRole = localStorage.getItem("sStraitUserRole");
  let licensee = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

  let licenseeFilterparam = "";
  if (userRole === "distributor" || userRole === "licenseeAccountant") {
    licenseeFilterparam = `&licenseeId=${licensee.id}`;
  }

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/users?isActive=true&status=created,active,suspended&dummy=false&role=agent,licenseeAccountant&limit=10&page=${page}&search=${q}${licenseeFilterparam}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_USER_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_USER_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* addUser(value) {
  let licensee = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  let userId = localStorage.getItem("sStraitUserId");
  let routes = (value.value && value.value.routes) || [];
  let routeIds = routes.map((r) => {
    return r.value;
  });

  let data = {
    licenseeId: licensee.id || "",
    name: (value.value && value.value.name) || "",
    lName: (value.value && value.value.lName) || "",
    email: (value.value && value.value.email) || "",
    mobileNo: (value.value && value.value.mobileNo) || "",
    password: (value.value && value.value.password) || "",
    fullName: value.value.name + " " + value.value.lName,
    role: (value.value && value.value.role) || "",
    status: "active",
    partner: licensee.channelPartner ? licensee.channelPartner.id : null,
    orgName: licensee.orgName,
    author: userId,
    routes: routeIds || [],
  };
  let role = value.value && value.value.role;
  let userCount = 0;
  if (role === "licenseeAccountant") {
    let userDtls = yield call(licenseeAcntVald);
    userCount = userDtls.count;
  }
  if (userCount > 0) {
    yield toast.warning(
      role === "licenseeAccountant" && "Licensee Accountant already exist",
      {
        autoClose: 3000,
      }
    );
  } else {
    try {
      const token = localStorage.getItem("sStraitToken");
      const res = yield fetch(`${appConfig.ip}/users`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) {
        let errJSON = {};
        try {
          errJSON = yield res.json();

          if (errJSON.message === "User already registered") {
            yield toast.error("User already exist", {
              autoClose: 3000,
            });
          }
        } catch {
          throw Object.assign(res, errJSON);
        }
      } else {
        const resJSON = yield res.json();

        const userData = {
          userId: resJSON.user.id,
          licenseeId: licensee.id || "",
          name: (value.value && value.value.name) || "",
          lName: (value.value && value.value.lName) || "",
          email: (value.value && value.value.email) || "",
          mobileNo: (value.value && value.value.mobileNo) || "",
          password: (value.value && value.value.password) || "",
          fullName:
            (value.value &&
              value.value.name + " " + value.value &&
              value.value.lName) ||
            "",
          role: (value.value && value.value.role) || "",
          status: "active",
          partner: licensee.channelPartner ? licensee.channelPartner.id : null,
          author: userId,
        };

        const userRes = yield fetch(`${appConfig.ip}/userProf`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(userData),
        });

        if (!userRes) {
        } else {
          const useresJSON = yield userRes.json();

          yield put({
            type: actionTypes.ADD_USER_SUCCESS_ACTION,
            payload: resJSON,
          });
          yield toast.success("User  added successfully", {
            autoClose: 3000,
          });
          yield put({
            type: actionTypes.FETCH_USER_INIT_ACTION,
          });
        }
      }
    } catch (err) {
      if (err.ok === false) {
        yield put({
          type: actionTypes.ADD_USER_FAIL_ACTION,
          error: err,
        });
      }
    }
  }
}

function* editUser(value) {
  let licensee = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  let userId = localStorage.getItem("sStraitUserId");
  let id = value.id || "";
  let routes = (value.value && value.value.routes) || [];
  let routeIds =
    (routes.length &&
      routes.map((r) => {
        return r.value;
      })) ||
    [];
  let data = {
    licenseeId: licensee.id || "",
    name: (value.value && value.value.name) || "",
    lName: (value.value && value.value.lName) || "",
    // email: (value.value && value.value.email) || "",
    // mobileNo: (value.value && value.value.mobileNo) || "",
    // password: (value.value && value.value.password) || "",
    // role: "agent",
    // status: "active",
    // fullName: value.value.name + " " + value.value.lName || "",
    author: userId,
    routes: routeIds || [],
  };

  let role = value.value && value.value.role;
  let userCount = 0;
  if (role === "licenseeAccountant") {
    let userDtls = yield call(licenseeAcntVald);
    userCount = userDtls.count;
  } else {
    try {
      const token = localStorage.getItem("sStraitToken");
      const res = yield fetch(`${appConfig.ip}/users/` + id, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      if (!res.ok) {
        let errJSON = {};
        try {
          errJSON = yield res.json();
        } catch {}
        throw Object.assign(res, errJSON);
      } else {
        yield toast.success("User  edited successfully", {
          autoClose: 3000,
        });
        const resJSON = yield res.json();
        yield put({ type: actionTypes.FETCH_USER_INIT_ACTION });
        yield put({
          type: actionTypes.EDIT_USER_SUCCESS_ACTION,
          payload: resJSON,
        });
      }
    } catch (err) {
      if (err.ok === false) {
        yield put({
          type: actionTypes.EDIT_USER_FAIL_ACTION,
          error: err,
        });
      } else {
      }
    }
  }
}

function* changeStatusUser(value) {
  let id = value.value.id;

  let data =
    value.value.status === "active" || value.value.status === "created"
      ? { status: "suspended" }
      : { status: "active" };

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/users/changeStatus/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield put({ type: actionTypes.FETCH_USER_INIT_ACTION });

      yield put({
        type: actionTypes.CHANGE_STATUS_USER_SUCCESS_ACTION,
        payload: id,
      });
      yield toast.success("Status changed successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.CHANGE_STATUS_USER_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* deleteUser(params) {
  let id = params.data.id;

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/users/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield put({ type: actionTypes.FETCH_USER_INIT_ACTION });

      yield put({
        type: actionTypes.DELETE_USERS_SUCCESS_ACTION,
        payload: id,
      });
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.DELETE_USERS_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* exportUser(params) {
  let fileName = "User Report";

  let search = (params.params && params.params.search) || "";

  let userRole = localStorage.getItem("sStraitUserRole");
  let licensee = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));

  let licenseeFilterparam = "";
  if (userRole === "distributor" || userRole === "licenseeAccountant") {
    licenseeFilterparam = `&licenseeId=${licensee.id}`;
  }

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/users?limit=100&status=created,active,suspended&dummy=false&search=${search}&role=agent,licenseeAccountant${licenseeFilterparam}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let csvData = [];
      resJSON.rows.map((item) => {
        csvData.push({
          NAME: item && item.name ? item.name : "NIL",
          LAST_NAME: item && item.lName ? item.lName : "NIL",
          MOBILE: item && item.mobileNo ? item.mobileNo : "NIL",
          EMAIL: item && item.email ? item.email : "NIL",
          ROLE: item && item.role ? item.role : "NIL",
          STATUS: item && item.status ? item.status : "NIL",

          CREATED_DATE: item && dateFormat(item.createdAt, "mediumDate"),
        });
      });
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      yield put({
        type: actionTypes.EXPORT_USERS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: actionTypes.EXPORT_USERS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* licenseeAcntVald(value) {
  const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licenseeId = userDetails.licenseeId;
  let role = "licenseeAccountant";
  let status = "created,active";
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/users?role=${role}&licenseeId=${licenseeId}&status=${status}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.EDIT_USER_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* userResetPassword(value) {
  const mobno = JSON.parse(localStorage.getItem("sStraitUserDtls")).mobileNo;

  const id = value.value.id;
  const token = localStorage.getItem("sStraitToken");
  const data = { password: value.value.newpwd };

  try {
    const res = yield fetch(`${appConfig.ip}/users/${id}/password`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa(mobno + ":" + value.value.oldpwd),
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield toast.success("User Password Reset Successfully", {
        autoClose: 3000,
      });
      yield put({
        type: actionTypes.USER_RESET_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.status === 401) {
      yield toast.error("Invalid Licensee Password", {
        autoClose: 3000,
      });
    }
    if (err.ok === false) {
      yield put({ type: actionTypes.USER_RESET_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchRouteByUser(params) {
  let page = (params.params && params.params.page) || 1;

  let currentUser = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licensee = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  let role = localStorage.getItem("sStraitUserRole");

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/route?isActive=true&limit=10&page&licenseeId=${currentUser.licenseeId}&status=active`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_ROUTES_BY_USER_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_ROUTES_BY_USER_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* fetchUserById(param) {
  let id = param.id;

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/users/` + id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_USER_BY_ID_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_USER_BY_ID_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

export function* userActionWatcher() {
  yield takeEvery(actionTypes.FETCH_USER_INIT_ACTION, fetchUser);
  yield takeEvery(actionTypes.ADD_USER_INIT_ACTION, addUser);
  yield takeEvery(actionTypes.CHANGE_STATUS_USER_INIT_ACTION, changeStatusUser);
  yield takeEvery(actionTypes.EDIT_USER_INIT_ACTION, editUser);
  yield takeEvery(actionTypes.DELETE_USERS_INIT_ACTION, deleteUser);
  yield takeEvery(actionTypes.EXPORT_USERS_INIT_ACTION, exportUser);
  yield takeEvery(actionTypes.USER_RESET_INIT_ACTION, userResetPassword);
  yield takeEvery(
    actionTypes.FETCH_ROUTES_BY_USER_INIT_ACTION,
    fetchRouteByUser
  );
  yield takeEvery(actionTypes.FETCH_USER_BY_ID_INIT_ACTION, fetchUserById);
}
