export const FETCH_INVOICES_INIT_ACTION = "FETCH_INVOICES_INIT_ACTION";
export const FETCH_INVOICES_SUCCESS_ACTION =
  FETCH_INVOICES_INIT_ACTION + "_SUCCESS";
export const FETCH_INVOICES_FAIL_ACTION = FETCH_INVOICES_INIT_ACTION + "_ERROR";

export const FETCH_INVOICESBYID_INIT_ACTION = "FETCH_INVOICESBYID_INIT_ACTION";
export const FETCH_INVOICESBYID_SUCCESS_ACTION =
  FETCH_INVOICESBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_INVOICESBYID_FAIL_ACTION =
  FETCH_INVOICESBYID_INIT_ACTION + "_ERROR";

export const PDF_REPORT_INIT_ACTION = "PDF_REPORT_INIT_ACTION";
export const PDF_REPORT_SUCCESS_ACTION = PDF_REPORT_INIT_ACTION + "_SUCCESS";
export const PDF_REPORT_FAIL_ACTION = PDF_REPORT_INIT_ACTION + "_ERROR";

export const FETCH_INVOICE_CONFIG_INIT_ACTION =
  "FETCH_INVOICE_CONFIG_INIT_ACTION";
export const FETCH_INVOICE_CONFIG_SUCCESS_ACTION =
  FETCH_INVOICE_CONFIG_INIT_ACTION + "_SUCCESS";
export const FETCH_INVOICE_CONFIG_FAIL_ACTION =
  FETCH_INVOICE_CONFIG_INIT_ACTION + "_ERROR";

export const ADD_REMARK_INIT_ACTION = "ADD_REMARK_INIT_ACTION";
export const ADD_REMARK_SUCCESS_ACTION = ADD_REMARK_INIT_ACTION + "_SUCCESS";
export const ADD_REMARK_FAIL_ACTION = ADD_REMARK_INIT_ACTION + "_ERROR";

export function fetchInvoices(value) {
  return {
    type: FETCH_INVOICES_INIT_ACTION,
    value: value,
  };
}

export function getInvoicesById(value) {
  return {
    type: FETCH_INVOICESBYID_INIT_ACTION,
    value: value,
  };
}

export function pdfFullData(value) {
  return {
    type: PDF_REPORT_INIT_ACTION,
    value: value,
  };
}

export function fetchInvoiceConfig(value) {
  return {
    type: FETCH_INVOICE_CONFIG_INIT_ACTION,
    value: value,
  };
}

export function addRemark(value) {
  return {
    type: ADD_REMARK_INIT_ACTION,
    value: value,
  };
}
