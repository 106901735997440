import React from "react";
import { Row, Col, Modal, Tab, Tabs } from "react-bootstrap";
import "../Common/cmnStyle.css";
var dateFormat = require("dateformat");

function ViewImportData(props) {
  const data =
    props.ItemList && props.ItemList.d
      ? props.ItemList && props.ItemList.d
      : null;

  return (
    <div>
      <div>
        <Modal
          show={props.showViewModel}
          onHide={props.closeViewModel}
          animation={false}
          size="lg"
          className="detail-ad-Modal"
          backdrop="static"
        >
          <Modal.Header className="mastr-mdl-hd" closeButton>
            <Modal.Title>
              <span className="modl-titl-align">Product Details</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              defaultActiveKey="basic"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="basic" title="Basic Details">
                <div>
                  <Row>
                    <Col xs={12} sm={12} md={3} lg={4}>
                      <p className="basic-prof-hd ">Name</p>
                      <span className="cmnTextCaptital">
                        {data !== null && data.name ? data.name : "Nil"}
                      </span>
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={4}>
                      <p className="basic-prof-hd ">Agency</p>
                      <span className="cmnTextCaptital">
                        {data !== null && data.agency && data.agency.length
                          ? data.agency[0].name
                          : "Nil"}
                      </span>
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={4}>
                      <p className="basic-prof-hd ">Buying Price</p>
                      <span className="cmnTextCaptital">
                        {data !== null && data.buyingPrice
                          ? data.buyingPrice
                          : "Nil"}
                      </span>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "20px" }}>
                    <Col xs={12} sm={12} md={3} lg={4}>
                      <p className="basic-prof-hd ">Selling Price</p>
                      <span className="cmnTextCaptital">
                        {data !== null && data.sellingPrice
                          ? data.sellingPrice
                          : "Nil"}
                      </span>
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={4}>
                      <p className="basic-prof-hd ">Available Qty</p>
                      <span className="cmnTextCaptital">
                        {data !== null && data.quantity ? data.quantity : "Nil"}
                      </span>
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={4}>
                      <p className="basic-prof-hd ">Category</p>
                      <span className="cmnTextCaptital">
                        {data !== null && data.ctgryId && data.ctgryId.length
                          ? data.ctgryId[0].name
                          : "Nil"}
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "20px" }}>
                    <Col xs={12} sm={12} md={3} lg={4}>
                      <p className="basic-prof-hd ">Subcategory</p>
                      <span className="cmnTextCaptital">
                        {data !== null &&
                        data.subCtgryId &&
                        data.subCtgryId.length
                          ? data.subCtgryId[0].name
                          : "Nil"}
                      </span>
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={4}>
                      <p className="basic-prof-hd ">Product Type</p>
                      <span className="cmnTextCaptital">
                        {data !== null && data.PrdtType && data.PrdtType.length
                          ? data.PrdtType[0].name
                          : "Nil"}
                      </span>
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={4}>
                      <p className="basic-prof-hd ">Unit</p>
                      <span className="cmnTextCaptital">
                        {" "}
                        {data !== null && data.unit && data.unit.length
                          ? data.unit[0].name
                          : "Nil"}
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "20px" }}>
                    <Col xs={12} sm={12} md={3} lg={4}>
                      <p className="basic-prof-hd ">Gst</p>
                      <span className="cmnTextCaptital">
                        {" "}
                        {data !== null && data.gstSlab && data.gstSlab.length
                          ? data.gstSlab[0].name
                          : "Nil"}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="commission" title="Commission">
                <Row style={{ marginTop: "20px" }}>
                  <Col xs={12} sm={12} md={3} lg={4}>
                    <p className="basic-prof-hd ">Regional Manager</p>
                    <span className="cmnTextCaptital">
                      {" "}
                      {data !== null && data.regionManger
                        ? data.regionManger
                        : "Nil"}
                    </span>
                  </Col>

                  <Col xs={12} sm={12} md={3} lg={4}>
                    <p className="basic-prof-hd ">Zonal Manager</p>
                    <span className="cmnTextCaptital">
                      {" "}
                      {data !== null && data.zoneManger
                        ? data.zoneManger
                        : "Nil"}
                    </span>
                  </Col>
                  <Col xs={12} sm={12} md={3} lg={4}>
                    <p className="basic-prof-hd ">Area Manager</p>
                    <span className="cmnTextCaptital">
                      {" "}
                      {data !== null && data.areaManger
                        ? data.areaManger
                        : "Nil"}
                    </span>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col xs={12} sm={12} md={3} lg={4}>
                    <p className="basic-prof-hd ">Sales Executive</p>
                    <span className="cmnTextCaptital">
                      {" "}
                      {data !== null && data.salesExecutive
                        ? data.salesExecutive
                        : "Nil"}
                    </span>
                  </Col>
                  <Col xs={12} sm={12} md={3} lg={4}>
                    <p className="basic-prof-hd ">Company</p>
                    <span className="cmnTextCaptital">
                      {" "}
                      {data !== null && data.company ? data.company : "Nil"}
                    </span>
                  </Col>
                  <Col xs={12} sm={12} md={3} lg={4}>
                    <p className="basic-prof-hd ">Incentive Bucket</p>
                    <span className="cmnTextCaptital">
                      {" "}
                      {data !== null && data.incentivesbucket
                        ? data.incentivesbucket
                        : "Nil"}
                    </span>
                  </Col>
                </Row>

                <Row></Row>
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer className="mastr-mdl-ftr"></Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
export default ViewImportData;
