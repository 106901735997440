export const FETCH_REWARDS_INIT_ACTION = "FETCH_REWARDS_INIT_ACTION";
export const FETCH_REWARDS_SUCCESS_ACTION =
  FETCH_REWARDS_INIT_ACTION + "_SUCCESS";
export const FETCH_REWARDS_FAIL_ACTION = FETCH_REWARDS_INIT_ACTION + "_ERROR";

export const FETCH_REWARDS_USERS_INIT_ACTION =
  "FETCH_REWARDS_USERS_INIT_ACTION";
export const FETCH_REWARDS_USERS_SUCCESS_ACTION =
  FETCH_REWARDS_USERS_INIT_ACTION + "_SUCCESS";
export const FETCH_REWARDS_USERS_FAIL_ACTION =
  FETCH_REWARDS_USERS_INIT_ACTION + "_ERROR";

export const ADD_REWARDS_INIT_ACTION = "ADD_REWARDS_INIT_ACTION";
export const ADD_REWARDS_SUCCESS_ACTION = ADD_REWARDS_INIT_ACTION + "_SUCCESS";
export const ADD_REWARDS_FAIL_ACTION = ADD_REWARDS_INIT_ACTION + "_ERROR";
