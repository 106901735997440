import * as React from "react";
import { connect } from "react-redux";

import {sendOtpforResetPwd,verifyOtpforResetPwd, resendOtpforResetPwd, resetPwd, redirectTologin} from "./action";

import ForgotPassword from "../../screens/ForgotPassword";

class ForgotpasswordContainer extends React.Component<Props, State> {
  render() {
    return <ForgotPassword 
      sendOtpforResetPwd={this.props.sendOtpforResetPwd} 
      otpSent={this.props.otpSent}
      verifyOtpforResetPwd={this.props.verifyOtpforResetPwd}
      resendOtpforResetPwd={this.props.resendOtpforResetPwd}
      otpVerified={this.props.otpVerified}
      resetPwd={this.props.resetPwd}
      pwdreset={this.props.pwdreset}
      redirectTologin={this.props.redirectTologin}
      redirecttoPage={this.props.redirecttoPage}
    />;
  }
}
function bindAction(dispatch) {
  return {
    sendOtpforResetPwd: (value) => {
      dispatch(sendOtpforResetPwd(value));
    },
    verifyOtpforResetPwd:(value) =>{
      dispatch(verifyOtpforResetPwd(value));
    },
    resendOtpforResetPwd:(value)=>{
      dispatch(resendOtpforResetPwd(value));
    },
    resetPwd:(value)=>{
      dispatch(resetPwd(value));
    },
    redirectTologin:()=>{
      dispatch(redirectTologin());
    }
  };
}
const mapStateToProps = (state) => {
  return {
    otpSent: state.ForgotPasswordReducer.otpSent,
    otpVerified:state.ForgotPasswordReducer.otpVerified,
    pwdreset:state.ForgotPasswordReducer.pwdreset,
    redirecttoPage:state.ForgotPasswordReducer.redirecttoPage,
  };
};

export default connect(mapStateToProps, bindAction)(ForgotpasswordContainer);
