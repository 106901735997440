import { put, takeEvery, select, call, all } from "redux-saga/effects";
// import { createBrowserHistory } from "history";

import { appConfig } from "../../config";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import * as actionTypes from "./constants";

//common function
function* commonFunction(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();
      yield put({ type: `${value.initAction}` });
      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: `${value.failAction}`, error: err });
    } else {
    }
  }
}

function* fetchRewards(value) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let pageno = value.value === undefined ? 1 : value.value.page;

  try {
    let params = {
      api: `${appConfig.ip}/rewards?licenseeId=${userDtls.licenseeId}&limit=10&page=${pageno}`,
      method: "GET",
      sucessAction: actionTypes.FETCH_REWARDS_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_REWARDS_FAIL_ACTION,
      body: null,
    };
    yield call(commonFunction, params);
  } catch (e) {}
}

function* fetchRewrdUsers(value) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? ""
      : value.value.searchVal;

  let _url = `${appConfig.ip}/users?licenseeId=${userDtls.licenseeId}&role=regionalManager,zonalManager,areaManager,salesExcecutive&status=active&dummy=false`;
  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }
  try {
    let params = {
      api: _url,
      method: "GET",
      sucessAction: actionTypes.FETCH_REWARDS_USERS_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_REWARDS_USERS_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);
  } catch (e) {}
}

function* AddReward(value) {
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const data = {
    receiver: value.value.user[0].id,
    amount: value.value.amount,
    licenseeId: userDtls.licenseeId,
    description: value.value.desc,
  };

  try {
    let params = {
      api: `${appConfig.ip}/rewards`,
      method: "POST",
      sucessAction: actionTypes.ADD_REWARDS_SUCCESS_ACTION,
      failAction: actionTypes.ADD_REWARDS_FAIL_ACTION,
      initAction: actionTypes.FETCH_REWARDS_INIT_ACTION,
      toastMsg: "Reward added successfully",
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);

    yield call(createEarnings, res);
  } catch (e) {}
}

function* createEarnings(value) {
  const currencyId = JSON.parse(localStorage.getItem("sStraitCurrency"))
    .currencyId.id;
  const data = {
    receiver: value.receiver.id,
    amount: value.amount,
    licenseeId: value.licenseeId.id,
    description: value.description,
    currency: currencyId,
    type: "reward",
    rewards: value.id,
  };

  try {
    let params = {
      api: `${appConfig.ip}/earnings`,
      method: "POST",
      sucessAction: null,
      failAction: null,
      initAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
  } catch (e) {}
}

export function* RewardsActionWatcher() {
  yield takeEvery(actionTypes.FETCH_REWARDS_INIT_ACTION, fetchRewards);
  yield takeEvery(actionTypes.FETCH_REWARDS_USERS_INIT_ACTION, fetchRewrdUsers);
  yield takeEvery(actionTypes.ADD_REWARDS_INIT_ACTION, AddReward);
}
