import { put, takeEvery, call } from "redux-saga/effects";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  LOGIN_INIT_ACTION,
  FETCH_LOGIN_FAIL_ACTION,
  FETCH_LOGIN_SUCCESS_ACTION,
  LOGOUT_ACTION,
  FETCH_CHECKSTATUS_INIT_ACTION,
  FETCH_CHECKSTATUS_SUCCESS_ACTION,
  FETCH_CHECKSTATUS_FAIL_ACTION,
} from "./actions";
import { appConfig } from "../../config";
import { push } from "connected-react-router";
var btoa = require("Base64").btoa;

function* login(cred) {
  try {
    const loginData = {
      username: cred.cred.username,
      password: cred.cred.password,
    };

    const res = yield fetch(appConfig.ip + "/auth", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " + btoa(loginData.username + ":" + loginData.password),
      },
    });

    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();
      const resJSONUser = resJSON.user;
      if (resJSONUser.role != "admin" && resJSONUser.role != "channelPartner") {
        if (resJSONUser.status != "pending") {
          if (resJSON.user.status === "suspended") {
            yield put(push("/Expired"));
          } else if (
            resJSON.user.role !== "licensee" &&
            resJSON.user.role !== "distributor"
          ) {
            yield put({
              type: FETCH_CHECKSTATUS_INIT_ACTION,
              value: resJSON,
            });
          } else {
            yield put({
              type: FETCH_LOGIN_SUCCESS_ACTION,
              payload: resJSON,
            });
            localStorage.setItem("sStraitToken", resJSON.token);
            localStorage.setItem("sStraitUserRole", resJSONUser.role);
            localStorage.setItem("sStraitUserId", resJSONUser.id);
            localStorage.setItem(
              "sStraitUserDtls",
              JSON.stringify(resJSONUser)
            );
            yield call(fetchLicProfileData);

            if (resJSON.user.status === "created") {
              yield call(userUpdate, resJSONUser.id);
            }
            yield put(push("/dashboard"));
            yield toast.success("Login successfully", {
              autoClose: 3000,
            });
          }
        } else {
          yield toast.error(
            "Your account needs to be approved by the administrator",
            {
              autoClose: 3000,
            }
          );
        }
      } else {
        yield toast.error("You are an invalid user", {
          autoClose: 3000,
        });
      }
    }
  } catch (err) {
    if (err.status === 401) {
      yield toast.error("Invalid credential", {
        autoClose: 3000,
      });
    } else if (err.status === 403) {
      yield put(push("/Expired"));
    } else if (err.status === 404) {
      yield toast.error("Your account has been deleted by admin", {
        autoClose: 3000,
      });
    }
    yield put({
      type: FETCH_LOGIN_FAIL_ACTION,
      payload: err,
    });
  }
}

function* userUpdate(value) {
  const token = localStorage.getItem("sStraitToken");
  const data = {
    status: "active",
  };

  try {
    const res = yield fetch(`${appConfig.ip}/users/` + value, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
    }
  } catch (err) {}
}

function* fetchLicProfileData() {
  const token = localStorage.getItem("sStraitToken");
  let licenseeId = JSON.parse(localStorage.getItem("sStraitUserDtls"))
    .licenseeId;

  try {
    const res = yield fetch(`${appConfig.ip}/licenseeProf/show/${licenseeId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      localStorage.setItem("sStraitLicProfDtls", JSON.stringify(resJSON));
      const currencyData = yield call(fetchCntryCrncy, resJSON.country.id);

      localStorage.setItem("sStraitCurrency", JSON.stringify(currencyData));
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* fetchCntryCrncy(countryId) {
  const token = localStorage.getItem("sStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/countryCrncy?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      let resData = resJSON.rows.find((e) => e.countryId.id === countryId);
      // let crncyId = resData.currencyId.id
      let crncyId = resData;

      return crncyId;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* fetchStatus(value) {
  // const token = localStorage.getItem("sStraitToken");
  // const userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  // let licenseeId = userDetails.licenseeId;
  let userVal = value.value.user;
  const token = value.value.token;
  const licenseeId = userVal.licenseeId;
  try {
    const res = yield fetch(
      `${appConfig.ip}/users/licenseeStatus?licenseeId=${licenseeId}&isActive=true`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      if (resJSON.status === "suspended") {
        yield put(push("/Expired", { from: "login" }));
      } else {
        localStorage.setItem("sStraitToken", value.value.token);
        localStorage.setItem("sStraitUserRole", userVal.role);
        localStorage.setItem("sStraitUserId", userVal.id);
        localStorage.setItem("sStraitUserDtls", JSON.stringify(userVal));
        yield call(fetchLicProfileData);

        if (userVal.status === "created") {
          yield call(userUpdate, userVal.id);
        }
        yield put(push("/dashboard"));
        yield toast.success("Login successfully", {
          autoClose: 3000,
        });
      }
      yield put({
        type: FETCH_CHECKSTATUS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_CHECKSTATUS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* logOut() {
  localStorage.removeItem("sStraitToken");
  localStorage.removeItem("sStraitUserRole");
  localStorage.removeItem("sStraitUserId");
  localStorage.removeItem("sStraitUserDtls");
  localStorage.removeItem("sStraitLicProfDtls");
  localStorage.removeItem("sStraitCurrency");
}

// function* login(cred) {
//   try {
//     const loginData = {
//       username: cred.cred.username,
//       password: cred.cred.password,
//     };

//     const res = yield fetch(appConfig.ip + "/auth", {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization:
//           "Basic " + btoa(loginData.username + ":" + loginData.password),
//       },
//     });

//     if (!res.ok) {
//       throw res;
//     } else {
//       const resJSON = yield res.json();
//       if (
//         resJSON.user.role != "admin" &&
//         resJSON.user.role != "channelPartner"
//       ) {
//         yield put({
//           type: FETCH_LOGIN_SUCCESS_ACTION,
//           payload: resJSON,
//         });

//         let userID = resJSON.user.id;

//         localStorage.setItem("sStraitToken", resJSON.token);
//         localStorage.setItem("sStraitUserRole", resJSON.user.role);
//         localStorage.setItem("sStraitUserId", userID);
//         localStorage.setItem("sStraitUserDtls", JSON.stringify(resJSON.user));

//         yield call(fetchLicProfileData);

//         if (resJSON.user.status === "created") {
//           yield call(userUpdate, userID);
//         }

//         if (resJSON.user.status === "suspended") {
//           yield put(push("/Expired"));
//         } else if (
//           resJSON.user.role !== "licensee" &&
//           resJSON.user.role !== "admin" &&
//           resJSON.user.role !== "distributor"
//         ) {
//           yield put({
//             type: FETCH_CHECKSTATUS_INIT_ACTION,
//           });
//         } else {
//           yield put(push("/dashboard"));
//           yield toast.success("Login successfully", {
//             autoClose: 3000,
//           });
//         }
//       } else {
//         yield toast.error("Invalid user", {
//           autoClose: 3000,
//         });
//       }
//     }
//   } catch (err) {
//     if (err.status === 401) {
//       yield toast.error("Invalid credential", {
//         autoClose: 3000,
//       });
//     } else if (err.status === 403) {
//       yield put(push("/Expired"));
//     } else if (err.status === 404) {
//       yield toast.error("Your account has been deleted by admin", {
//         autoClose: 3000,
//       });
//     }
//     yield put({
//       type: FETCH_LOGIN_FAIL_ACTION,
//       payload: err,
//     });
//   }
// }

export function* LoginActionWatcher() {
  yield takeEvery(LOGIN_INIT_ACTION, login);
  yield takeEvery(LOGOUT_ACTION, logOut);
  yield takeEvery(FETCH_CHECKSTATUS_INIT_ACTION, fetchStatus);
}
