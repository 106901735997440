import { call, all } from "redux-saga/effects";

import { LoginActionWatcher } from "../containers/LoginContainer/saga";
import { RegionActionWatcher } from "../containers/RegionContainer/saga";
import { ZoneActionWatcher } from "../containers/ZoneContainer/saga";
import { AreaActionWatcher } from "../containers/AreaContainer/saga";
import { UsersActionWatcher } from "../containers/UsersContainer/saga";
import { ResetOldPwdActionWatcher } from "../containers/ResetPwdContainer/saga";
import { MyProfileActionWatcher } from "../containers/MyProfileContainer/saga";
// import { UserProfileActionWatcher } from "../containers/UserProfileContainer/saga";
import { ProfileEditActionWatcher } from "../containers/EditProfileContainer/saga";
import { ProductActionWatcher } from "../containers/ProductContainer/saga";
import { EditActionWatcher } from "../containers/EditUserProfileContainer/saga";
import { dashboardActionWatcher } from "../containers/DefaultLayout/saga";
import { CustomersActionWatcher } from "../containers/CustomerContainer/saga";
import { LeadsActionWatcher } from "../containers/LeadsContainer/saga";
import { OrderActionWatcher } from "../containers/OrderContainer/saga";

import { PaymentsActionWatcher } from "../containers/PaymentsContainer/saga";
import { InvoicesActionWatcher } from "../containers/InvoicesContainer/saga";
import { QuotationsActionWatcher } from "../containers/QuotationsContainer/saga";
import { EarningsActionWatcher } from "../containers/EarningsContainer/saga";
import { WdRequestActionWatcher } from "../containers/WithdrawalRequestContainer/saga";
import { IncentiveBucketActionWatcher } from "../containers/IncentiveBucketContainer/saga";
import { AgencyActionWatcher } from "../containers/AgencyContainer/saga";
import { SupportActionWatcher } from "../containers/SupportContainer/saga";

import { ResetPwdActionWatcher } from "../containers/ForgotPasswordContainer/saga";
import { PaymentInfoActionWatcher } from "../containers/PaymentInfoContainer/saga";

import { ProductInventoryActionWatcher } from "../containers/ProductInventoryContainer/saga";
import { WithdrawalLimitActionWatcher } from "../containers/WithdrawalLimitContainer/saga";
import { ImportProdActionWatcher } from "../containers/ImportProdContainer/saga.js";

import { FollowupActionWatcher } from "../containers/FollowupContainer/saga.js";
import { GformActionWatcher } from "../containers/GformContainer/saga";
import { SocialLeadActionWatcher } from "../containers/SocialLeadContainer/saga";
import { facebookActionWatcher } from "../containers/FacebookContainer/saga";
import { IndMartActionWatcher } from "../containers/IndiaMartContainer/saga";
import { TradeIndActionWatcher } from "../containers/TradeIndiaContainer/saga";
import { signupActionWatcher } from "../containers/SignupContainer/saga";
import { RewardsActionWatcher } from "../containers/RewardContainer/saga";
import { expenseActionWatcher } from "../containers/DistributorContainer/ExpenseConatiner/saga";
import { userActionWatcher } from "../containers/DistributorContainer/UserContainer/saga";
import { DisOrderActionWatcher } from "../containers/DistributorContainer/OrderContainer/saga";

import { invoiceConfigurationActionWatcher } from "../containers/BrandingContainer/InvoiceConfigurationContainer/saga";
import { quoteConfigurationActionWatcher } from "../containers/BrandingContainer/QuoteConfigurationContainer/saga";
import { notificationActionWatcher } from "../containers/NotificationContainer/saga";
import { DisQuotationsActionWatcher } from "../containers/DistributorContainer/QuotationContainer/saga";
import { DisProductActionWatcher } from "../containers/DistributorContainer/ProductContainer/saga";
import { DisProductInventoryActionWatcher } from "../containers/DistributorContainer/ProductInventoryContainer/saga";
import { ImportDisProdActionWatcher } from "../containers/DistributorContainer/ImportDistPrdctConatainer/saga";
import { salesReturnActionWatcher } from "../containers/SalesReturnContainer/saga";
import { creditNoteConfigurationActionWatcher } from "../containers/BrandingContainer/CreditNoteConfigContainer/saga";
import { debtorsReportActionWatcher } from "../containers/ReportsContainer/DeptorsReportContainer/saga";
import { creditnoteReportActionWatcher } from "../containers/ReportsContainer/CreditNoteContainer/saga";
import { customerrouteActionWatcher } from "../containers/DistributorContainer/CustomerRouteContainer/saga";
import { stockMovementActionWatcher } from "../containers/DistributorContainer/StockMovementContainer/saga";
import { agentReportActionWatcher } from "../containers/ReportsContainer/UserReportContainer/saga";
import { ProductReplaceActionWatcher } from "../containers/ProductReplaceContainer/saga";
function* rootSaga() {
  yield all([
    call(LoginActionWatcher),
    call(RegionActionWatcher),
    call(ZoneActionWatcher),
    call(AreaActionWatcher),
    call(UsersActionWatcher),
    call(ResetOldPwdActionWatcher),
    call(MyProfileActionWatcher),
    call(ProfileEditActionWatcher),
    call(ProductActionWatcher),
    call(EditActionWatcher),
    call(dashboardActionWatcher),
    call(CustomersActionWatcher),
    call(LeadsActionWatcher),
    call(OrderActionWatcher),
    call(agentReportActionWatcher),

    // call(UserProfileActionWatcher),
    call(PaymentsActionWatcher),
    call(InvoicesActionWatcher),
    call(QuotationsActionWatcher),
    call(EarningsActionWatcher),
    call(WdRequestActionWatcher),
    call(IncentiveBucketActionWatcher),
    call(AgencyActionWatcher),
    call(ResetPwdActionWatcher),
    call(SupportActionWatcher),
    call(PaymentInfoActionWatcher),
    call(ProductInventoryActionWatcher),
    call(WithdrawalLimitActionWatcher),
    call(ImportProdActionWatcher),

    call(FollowupActionWatcher),
    call(GformActionWatcher),
    call(SocialLeadActionWatcher),
    call(facebookActionWatcher),
    call(IndMartActionWatcher),
    call(TradeIndActionWatcher),
    call(signupActionWatcher),
    call(RewardsActionWatcher),
    call(expenseActionWatcher),
    call(userActionWatcher),
    call(DisOrderActionWatcher),
    call(invoiceConfigurationActionWatcher),
    call(quoteConfigurationActionWatcher),
    call(notificationActionWatcher),
    call(DisQuotationsActionWatcher),
    call(DisProductActionWatcher),
    call(DisProductInventoryActionWatcher),
    call(ImportDisProdActionWatcher),
    call(salesReturnActionWatcher),
    call(creditNoteConfigurationActionWatcher),
    call(debtorsReportActionWatcher),
    call(creditnoteReportActionWatcher),
    call(customerrouteActionWatcher),
    call(stockMovementActionWatcher),
    call(ProductReplaceActionWatcher),
  ]);
}

export default rootSaga;
