import {

    FETCH_SUBCATEGORYLIST_SUCCESS_ACTION,
    APPROVE_PRODUCT_SUCCESS_ACTION

} from "./action";
import { sortData } from "../../screens/Common/sortData";

const initialState = {
    processing: true,
    error: false,
    loading: true,
};

export default function ImportProdReducer(
    state: any = initialState,
    action: Function
) {
    switch (action.type) {

        case FETCH_SUBCATEGORYLIST_SUCCESS_ACTION:
            return {
                ...state,
                subcategorysList: sortData(action.payload.rows),
            };


        default:
            return {
                ...state,
            };
    }
}
