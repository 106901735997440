export const FETCH_WITHDRAWALLIMIT_INIT_ACTION =
  "FETCH_WITHDRAWALLIMIT_INIT_ACTION";
export const FETCH_WITHDRAWALLIMIT_SUCCESS_ACTION =
  FETCH_WITHDRAWALLIMIT_INIT_ACTION + "_SUCCESS";
export const FETCH_WITHDRAWALLIMIT_FAIL_ACTION =
  FETCH_WITHDRAWALLIMIT_INIT_ACTION + "_ERROR";

export const ADD_WITHDRAWALLIMIT_INIT_ACTION =
  "ADD_WITHDRAWALLIMIT_INIT_ACTION";
export const ADD_WITHDRAWALLIMIT_SUCCESS_ACTION =
  ADD_WITHDRAWALLIMIT_INIT_ACTION + "_SUCCESS";
export const ADD_WITHDRAWALLIMIT_FAIL_ACTION =
  ADD_WITHDRAWALLIMIT_INIT_ACTION + "_ERROR";

export const EDIT_WITHDRAWALLIMIT_INIT_ACTION =
  "EDIT_WITHDRAWALLIMIT_INIT_ACTION";
export const EDIT_WITHDRAWALLIMIT_SUCCESS_ACTION =
  EDIT_WITHDRAWALLIMIT_INIT_ACTION + "_SUCCESS";
export const EDIT_WITHDRAWALLIMIT_FAIL_ACTION =
  EDIT_WITHDRAWALLIMIT_INIT_ACTION + "_ERROR";

export const DELETE_WITHDRAWALLIMIT_INIT_ACTION =
  "DELETE_WITHDRAWALLIMIT_INIT_ACTION";
export const DELETE_WITHDRAWALLIMIT_SUCCESS_ACTION =
  DELETE_WITHDRAWALLIMIT_INIT_ACTION + "_SUCCESS";
export const DELETE_WITHDRAWALLIMIT_FAIL_ACTION =
  DELETE_WITHDRAWALLIMIT_INIT_ACTION + "_ERROR";

export function fetchWithdrawalLimit(value) {
  return {
    type: FETCH_WITHDRAWALLIMIT_INIT_ACTION,
    value: value,
  };
}

export function AddWithdrawalLimit(value) {
  return {
    type: ADD_WITHDRAWALLIMIT_INIT_ACTION,
    value: value,
  };
}

export function EditWithdrawalLimit(value) {
  return {
    type: EDIT_WITHDRAWALLIMIT_INIT_ACTION,
    value: value,
  };
}

export function DeleteValue(value) {
  return {
    type: DELETE_WITHDRAWALLIMIT_INIT_ACTION,
    value: value,
  };
}

export function withdrawalLimitSlectedPage(value) {
  return {
    type: FETCH_WITHDRAWALLIMIT_INIT_ACTION,
    value: value,
  };
}
