import { put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "./constants";

import { appConfig } from "../../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* fetchInvoiceConfiguration(params) {
  let page = (params.params && params.params.page) || 1;
  let userData = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let userParam = userData.licenseeId;

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/invoiceConfig?isActive=true&limit=10&page=${page}&licenseeId=${userParam}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_INVOICE_CONFIGURATION_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_INVOICE_CONFIGURATION_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* addInvoiceConfiguration(value) {
  let userData = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  let data = {
    user: (userData && userData.id) || null,
    licenseeId: (userData && userData.licenseeId) || null,

    remark: (value.value && value.value.remark) || "",
    invoiceStartNo: (value.value && value.value.invoiceStartNo) || "",
  };

  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/invoiceConfig`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      let userId = resJSON.id;

      const formData = new FormData();

      formData.append(userId, value.value.imgUrl);

      const resFile = yield fetch(`${appConfig.ip}/invoiceConfig/image`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      if (!resFile.ok) {
        throw resFile;
      } else {
        const resJSON1 = yield resFile.json();
      }

      yield put({
        type: actionTypes.ADD_INVOICE_CONFIGURATION_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Invoice configuration  added successfully", {
        autoClose: 3000,
      });
      yield put({
        type: actionTypes.FETCH_INVOICE_CONFIGURATION_INIT_ACTION,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.ADD_INVOICE_CONFIGURATION_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* editInvoiceConfiguration(value) {
  let userData = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  let id = value.id || "";

  let data = {
    user: (userData && userData.id) || null,
    licenseeId: (userData && userData.licenseeId) || null,

    remark: (value.value && value.value.remark) || "",
    invoiceStartNo: (value.value && value.value.invoiceStartNo) || "",
    bankName: (value.value && value.value.bankName) || "",
    accountName: (value.value && value.value.accountName) || "",

    branchName: (value.value && value.value.branchName) || "",
    accountNo: (value.value && value.value.accountNo) || "",

    ifscCode: (value.value && value.value.ifscCode) || "",
    entrprzInvStartNo: (value.value && value.value.entrprzInvStartNo) || "",
    addtCustInfo: (value.value && value.value.addtCustInfo) || "",
  };
  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(`${appConfig.ip}/invoiceConfig/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let userId = resJSON.id;
      if (typeof value.value.imgUrl !== "string") {
        const formData = new FormData();

        formData.append(userId, value.value.imgUrl);

        const resFile = yield fetch(`${appConfig.ip}/invoiceConfig/image`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        if (!resFile.ok) {
          throw resFile;
        } else {
          const resJSON1 = yield resFile.json();
        }
      }

      yield toast.success("Invoice configuration  edited successfully", {
        autoClose: 3000,
      });

      yield put({ type: actionTypes.FETCH_INVOICE_CONFIGURATION_INIT_ACTION });
      yield put({
        type: actionTypes.EDIT_INVOICE_CONFIGURATION_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.EDIT_INVOICE_CONFIGURATION_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* deleteInvoiceConfiguration(params) {
  let id = params.data.id;

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/invoiceConfig/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield put({ type: actionTypes.FETCH_INVOICE_CONFIGURATION_INIT_ACTION });

      yield put({
        type: actionTypes.DELETE_INVOICE_CONFIGURATION_SUCCESS_ACTION,
        payload: id,
      });
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.DELETE_INVOICE_CONFIGURATION_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* fetchBankInvoiceConfig() {
  try {
    const token = localStorage.getItem("sStraitToken");

    const res = yield fetch(`${appConfig.ip}/bankName/all`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
    } else {
      const resJSON = yield res.json();
      yield put({
        type: actionTypes.FETCH_BANK_IN_INVOICE_CONFIGURATION_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_BANK_IN_INVOICE_CONFIGURATION_FAIL_ACTION,
        error: err,
      });
    }
  }
}

export function* invoiceConfigurationActionWatcher() {
  yield takeEvery(
    actionTypes.FETCH_INVOICE_CONFIGURATION_INIT_ACTION,
    fetchInvoiceConfiguration
  );
  yield takeEvery(
    actionTypes.ADD_INVOICE_CONFIGURATION_INIT_ACTION,
    addInvoiceConfiguration
  );
  yield takeEvery(
    actionTypes.DELETE_INVOICE_CONFIGURATION_INIT_ACTION,
    deleteInvoiceConfiguration
  );
  yield takeEvery(
    actionTypes.EDIT_INVOICE_CONFIGURATION_INIT_ACTION,
    editInvoiceConfiguration
  );
  yield takeEvery(
    actionTypes.FETCH_BANK_IN_INVOICE_CONFIGURATION_INIT_ACTION,
    fetchBankInvoiceConfig
  );
}
