import React, { Component } from "react";
import { Modal, Button,Image,Container, } from "react-bootstrap";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './Expired.css'

import { appConfig } from "../../config";
import { node } from "prop-types";

class Expired extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
  }

  render() {
    return (
      <div className="salesreturndiv mg-adjst">
      <div>
        <Modal
          show={this.state.showModal}
          size="lg"
          backdrop="static"
          className="Modal-expired"
          centered
        >
          <Modal.Body style={{ textAlign: "center" }} className="Modalbox">
            <Container >
                 <div className="Maindiv">
                      <div className="SuspendImg">
                        <Image
                          className="resetimg"
                          src={require("../../assets/img/suspended.png")}
                        />
                      </div>
                      <h4 className="Msg"  style={{fontWeight:"bolder",float:"center",color:"black" }}>
                   Sorry, This account has been temporarily suspended{" "}
                  </h4>
                  <br></br>

            <Button className="Button"
            style={{backgroundColor: "#0077B6", float:"center" }}
              onClick={(e) => this.props.logoutaction(e)}
            >
              {this.props.where ? "Back to login" : "Log out"}
            </Button>
            </div>

            </Container>

          </Modal.Body>
        </Modal>
      </div>
      </div>
    );
  }
}

export default Expired;
