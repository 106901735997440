import { put, takeEvery, select, call, all } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_PRODUCTS_INIT_ACTION,
  FETCH_PRODUCTS_SUCCESS_ACTION,
  FETCH_PRODUCTS_FAIL_ACTION,
  FETCH_PRODUCTTYPE_INIT_ACTION,
  FETCH_PRODUCTTYPE_SUCCESS_ACTION,
  FETCH_PRODUCTTYPE_FAIL_ACTION,
  FETCH_CATEGORY_INIT_ACTION,
  FETCH_CATEGORY_SUCCESS_ACTION,
  FETCH_CATEGORY_FAIL_ACTION,
  FETCH_SUBCATEGORY_INIT_ACTION,
  FETCH_SUBCATEGORY_SUCCESS_ACTION,
  FETCH_SUBCATEGORY_FAIL_ACTION,
  FETCH_GST_INIT_ACTION,
  FETCH_GST_SUCCESS_ACTION,
  FETCH_GST_FAIL_ACTION,
  ADD_PRODUCTS_INIT_ACTION,
  ADD_PRODUCTS_SUCCESS_ACTION,
  ADD_PRODUCTS_FAIL_ACTION,
  FETCH_AREATYPE_INIT_ACTION,
  FETCH_AREATYPE_SUCCESS_ACTION,
  FETCH_AREATYPE_FAIL_ACTION,
  FETCH_PRODUCTBYID_INIT_ACTION,
  FETCH_PRODUCTBYID_SUCCESS_ACTION,
  FETCH_PRODUCTBYID_FAIL_ACTION,
  EDIT_PRODUCTS_INIT_ACTION,
  EDIT_PRODUCTS_SUCCESS_ACTION,
  EDIT_PRODUCTS_FAIL_ACTION,
  FETCH_UNITS_INIT_ACTION,
  FETCH_UNITS_SUCCESS_ACTION,
  FETCH_UNITS_FAIL_ACTION,
  CHANGE_PRODUCT_STATUS_INIT_ACTION,
  CHANGE_PRODUCT_STATUS_SUCCESS_ACTION,
  CHANGE_PRODUCT_STATUS_FAIL_ACTION,
  DELETE_PRODUCT_INIT_ACTION,
  DELETE_PRODUCT_SUCCESS_ACTION,
  DELETE_PRODUCT_FAIL_ACTION,
  FETCH_AGENCYLIST_INIT_ACTION,
  FETCH_AGENCYLIST_SUCCESS_ACTION,
  FETCH_AGENCYLIST_FAIL_ACTION,
  IMPORT_PRODUCT_INIT_ACTION,
  IMPORT_PRODUCT_SUCCESS_ACTION,
  IMPORT_PRODUCT_FAIL_ACTION,
  // FILTERSEARCH_PRODUCT_INIT_ACTION,
  // FILTERSEARCH_PRODUCT_SUCCESS_ACTION,
  // FILTERSEARCH_PRODUCT_FAIL_ACTION
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as selectors from "../../service/selectors";

function* fetchProductType() {
  // let productReducer = yield select(selectors.productReducer);

  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/prdtType?&isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_PRODUCTTYPE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_PRODUCTTYPE_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchCategory() {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/category?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_CATEGORY_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_CATEGORY_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchSubCatgry(value) {
  try {
    const token = localStorage.getItem("sStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/subCtgry?isActive=true&ctgryId=` + value.value,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_SUBCATEGORY_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_SUBCATEGORY_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchGst() {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/gstSlab?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_GST_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_GST_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchUnit() {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/Unit?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_UNITS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_UNITS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

// get Region or Zone or area by type

function* fetchAreaType(value) {
  const token = localStorage.getItem("sStraitToken");
  let licId = JSON.parse(localStorage.getItem("sStraitUserDtls")).licenseeId;

  let apiUrl =
    value.value === "region"
      ? "/region?isActive=true&dummy=false"
      : value.value === "zone"
      ? "/zone?isActive=true&dummy=false"
      : "/area?isActive=true&dummy=false";

  try {
    const res = yield fetch(
      `${appConfig.ip}` + apiUrl + "&licenseeId=" + licId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_AREATYPE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_AREATYPE_FAIL_ACTION, error: err });
    } else {
    }
  }
}
//Get products

function* fetchProducts(value) {
  let pageno = value.value.page === undefined ? 1 : value.value.page;

  const token = localStorage.getItem("sStraitToken");

  const role = localStorage.getItem("sStraitUserRole");
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));

  let getDataUrl = [
    { role: "licensee", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "distributor", url: "&licenseeId=" + userDtls.licenseeId },
    { role: "licenseeAccountant", url: "&licenseeId=" + userDtls.licenseeId },
    {
      role: "regionalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&region=" + userDtls.region,
    },
    {
      role: "zonalManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&zone=" + userDtls.zone,
    },
    {
      role: "areaManager",
      url: "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
    {
      role: "salesExcecutive",
      url: "&licenseeId=" + userDtls.licenseeId + "&area=" + userDtls.area,
    },
    {
      role: "agent",
      url: "&licenseeId=" + userDtls.licenseeId,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  let filter =
    !value || !value.value || !value.value.filter ? null : value.value.filter;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? null
      : value.value.searchVal;

  let _url = `${appConfig.ip}/product?status=active,inactive&limit=10&page=${pageno}${apiUrl.url}`;

  if (filter) {
    _url = `${_url}&agency=${filter}`;
  }
  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  }
  if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  }
  if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }
  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_PRODUCTS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_PRODUCTS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

//Get product by Id

function* getProductById(value) {
  const token = localStorage.getItem("sStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/product/` + value.value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_PRODUCTBYID_SUCCESS_ACTION,
        payload: resJSON,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_PRODUCTBYID_FAIL_ACTION, error: err });
    } else {
    }
  }
}

// ADD product

function* AddProduct(value) {
  // let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  // const role = localStorage.getItem("sStraitUserRole");

  yield call(fetchRegionZoneArea, value);

  // role != "distributor"
  //   ? yield call(fetchRegionZoneArea, value)
  //   : licData.networkHierarchy
  //   ? yield call(fetchRegionZoneArea, value)
  //   : yield call(AddNoHierarchyProduct, value);

  // if (value.value.value.ref === "region") {
  //   let reigionIds = value.value.value.typeIds.map((x) => x.value);
  //   const [zoneIds, areaIds] = yield all([
  //     call(fetchZoneIds, value),
  //     call(fetchAreaIds, value),
  //   ]);

  //   let newData = {
  //     data: value,
  //     reigionIds: reigionIds,
  //     zoneIds: zoneIds,
  //     areaIds: areaIds,
  //   };
  //   yield call(addProductData, newData);
  // } else if (value.value.value.ref === "zone") {
  //   let zoneIds = value.value.value.typeIds.map((x) => x.value);
  //   const [regionIds, areaIds] = yield all([
  //     call(fetchZoneIds, value),
  //     call(fetchAreaIds, value),
  //   ]);

  //   const regionIdsFltr = regionIds.filter(
  //     (item, index) => regionIds.indexOf(item) == index
  //   );

  //   let newData = {
  //     data: value,
  //     reigionIds: regionIdsFltr,
  //     zoneIds: zoneIds,
  //     areaIds: areaIds,
  //   };

  //   yield call(addProductData, newData);
  // } else {
  //   let areaIds = value.value.value.typeIds.map((x) => x.value);
  //   const [areaDtls] = yield all([call(fetchAreaIds, value)]);

  //   let regionIds = areaDtls.rows.map((x) => x.region.id);
  //   let zoneIds = areaDtls.rows.map((x) => x.zone.id);

  //   const regionIdsFltr = regionIds.filter(
  //     (item, index) => regionIds.indexOf(item) == index
  //   );
  //   const zoneIdsFltr = zoneIds.filter(
  //     (item, index) => zoneIds.indexOf(item) == index
  //   );

  //   let newData = {
  //     data: value,
  //     reigionIds: regionIdsFltr,
  //     zoneIds: zoneIdsFltr,
  //     areaIds: areaIds,
  //   };

  //   yield call(addProductData, newData);
  // }
}

function* fetchRegionZoneArea(value) {
  if (value.value.value.ref === "region") {
    let reigionIds = value.value.value.typeIds.map((x) => x.value);
    const [zoneIds, areaIds] = yield all([
      call(fetchZoneIds, value),
      call(fetchAreaIds, value),
    ]);

    let newData = {
      data: value,
      reigionIds: reigionIds,
      zoneIds: zoneIds,
      areaIds: areaIds,
    };
    value.value.value.id
      ? yield call(editProductData, newData)
      : yield call(addProductData, newData);
  } else if (value.value.value.ref === "zone") {
    let zoneIds = value.value.value.typeIds.map((x) => x.value);
    const [regionIds, areaIds] = yield all([
      call(fetchZoneIds, value),
      call(fetchAreaIds, value),
    ]);

    const regionIdsFltr = regionIds.filter(
      (item, index) => regionIds.indexOf(item) == index
    );

    let newData = {
      data: value,
      reigionIds: regionIdsFltr,
      zoneIds: zoneIds,
      areaIds: areaIds,
    };

    value.value.value.id
      ? yield call(editProductData, newData)
      : yield call(addProductData, newData);
  } else {
    let areaIds = value.value.value.typeIds.map((x) => x.value);
    const [areaDtls] = yield all([call(fetchAreaIds, value)]);

    let regionIds = areaDtls.rows.map((x) => x.region.id);
    let zoneIds = areaDtls.rows.map((x) => x.zone.id);

    const regionIdsFltr = regionIds.filter(
      (item, index) => regionIds.indexOf(item) == index
    );
    const zoneIdsFltr = zoneIds.filter(
      (item, index) => zoneIds.indexOf(item) == index
    );

    let newData = {
      data: value,
      reigionIds: regionIdsFltr,
      zoneIds: zoneIdsFltr,
      areaIds: areaIds,
    };

    value.value.value.id
      ? yield call(editProductData, newData)
      : yield call(addProductData, newData);
  }
}

function* addProductData(value) {
  let Val = value.data.value;

  const token = localStorage.getItem("sStraitToken");
  let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  const crncyId = JSON.parse(localStorage.getItem("sStraitCurrency")).currencyId
    .id;

  // const [crncyId] = yield all([call(fetchCntryCrncy)]);

  try {
    const productData = {
      licenseeId: userDetails.licenseeId,
      name: Val.value.name,
      ctgryId: Val.value.category,
      subCtgryId: Val.value.subcategory,
      PrdtType: Val.value.type,
      gstSlab: Val.value.gst,
      buyingPrice: Val.value.buyPrice,
      sellingPrice: Val.value.sellPrice,
      unit: Val.value.unit,
      quantity: Val.value.quantity,
      status: "active",
      agency: Val.value.agency,
      hsnCode: Val.value.hsnCode,

      commission: {
        platform: Val.value.platform,
        regionManger: Val.value.regionManger,
        zoneManger: Val.value.zoneManger,
        areaManger: Val.value.areaManger,
        salesExecutive: Val.value.salesExecutive,
        company: Val.value.company,
        incentivesbucket: Val.value.incentiveBckt,
        agent: 0,
      },
      currency: crncyId,
      areaRef: Val.value.ref,
      region: value.reigionIds,
      zone: value.zoneIds,
      area: value.areaIds,
      // image: imgResponse.files
      backOrder: Val.value.backOrder,
      description: Val.value.desc,
      channelPartner: licData.channelPartner ? licData.channelPartner.id : null,
      hasVariant: Val.value.hasVariant ? true : false,
      variant: Val.variants ? Val.variants : [],
    };

    const resProduct = yield fetch(`${appConfig.ip}/product`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(productData),
    });

    if (!resProduct.ok) {
      let errJSON1 = {};
      try {
        errJSON1 = yield resProduct.json();
      } catch {}
      throw Object.assign(resProduct, errJSON1);
    } else {
      const resJSONproduct = yield resProduct.json();

      if (Val.image) {
        yield call(addeditImage, { prodId: resJSONproduct.id, img: Val.image });
      }

      // post inventory
      yield call(postInventory, { resData: resJSONproduct, data: productData });

      yield put({ type: FETCH_PRODUCTS_INIT_ACTION, value: { page: 1 } });
      yield put({
        type: ADD_PRODUCTS_SUCCESS_ACTION,
        payload: resJSONproduct,
      });
      yield toast.success("Product added successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_PRODUCTS_FAIL_ACTION,
        error: err,
      });
    }
  }
}

// Add inventory function

function* postInventory(value) {
  const data = {
    licenseeId: value.resData.licenseeId.id,
    quantity: value.data.quantity,
    product: value.resData.id,
    unit: value.resData.unit,
    status: "active",
    // isVariant: value.resData.hasVariant,
    // variants: item,
  };
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/inventory`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

//Distributor level add product with no hierarchy

// function* AddNoHierarchyProduct(value) {
//   let Val = value.value.value;

//   const token = localStorage.getItem("sStraitToken");
//   let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
//   let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
//   const crncyId = JSON.parse(localStorage.getItem("sStraitCurrency")).currencyId
//     .id;

//   try {
//     const productData = {
//       licenseeId: userDetails.licenseeId,
//       name: Val.name,
//       ctgryId: Val.category,
//       subCtgryId: Val.subcategory,
//       PrdtType: Val.type,
//       gstSlab: Val.gst,
//       buyingPrice: Val.buyPrice,
//       sellingPrice: Val.sellPrice,
//       unit: Val.unit,
//       quantity: Val.quantity,
//       status: "active",
//       agency: Val.agency,

//       commission: {
//         platform: 0,
//         regionManger: 0,
//         zoneManger: 0,
//         areaManger: 0,
//         salesExecutive: 0,
//         company: Val.company,
//         incentivesbucket: Val.incentiveBckt,
//         agent: 0,
//       },
//       currency: crncyId,
//       backOrder: Val.backOrder,
//       description: Val.desc,
//       channelPartner: licData.channelPartner ? licData.channelPartner.id : null,
//       hasVariant: Val.hasVariant ? true : false,
//       variant: value.value.variants ? value.value.variants : [],
//     };

//     const resProduct = yield fetch(`${appConfig.ip}/product`, {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(productData),
//     });

//     if (!resProduct.ok) {
//       let errJSON1 = {};
//       try {
//         errJSON1 = yield resProduct.json();
//       } catch {}
//       throw Object.assign(resProduct, errJSON1);
//     } else {
//       const resJSONproduct = yield resProduct.json();

//       if (value.value.image) {
//         yield call(addeditImage, {
//           prodId: resJSONproduct.id,
//           img: value.value.image,
//         });
//       }

//       yield put({ type: FETCH_PRODUCTS_INIT_ACTION, value: { page: 1 } });
//       yield put({
//         type: ADD_PRODUCTS_SUCCESS_ACTION,
//         payload: resJSONproduct,
//       });
//       yield toast.success("Product added successfully", {
//         autoClose: 3000,
//       });
//     }
//   } catch (err) {
//     if (err.ok === false) {
//       yield put({
//         type: ADD_PRODUCTS_FAIL_ACTION,
//         error: err,
//       });
//     }
//   }
// }

//Edit product

function* EditProduct(value) {
  // let licData = JSON.parse(localStorage.getItem("sStraitLicProfDtls"));
  // const role = localStorage.getItem("sStraitUserRole");

  yield call(fetchRegionZoneArea, value);

  // if (role != "distributor") {
  //   yield call(fetchRegionZoneArea, value);
  // } else {
  //   licData.networkHierarchy
  //     ? yield call(fetchRegionZoneArea, value)
  //     : yield call(UpdateNoHierarchyProduct, value);
  // }

  // if (value.value.value.ref === "region") {
  //   let reigionIds = value.value.value.typeIds.map((x) => x.value);
  //   const [zoneIds, areaIds] = yield all([
  //     call(fetchZoneIds, value),
  //     call(fetchAreaIds, value),
  //   ]);

  //   let newData = {
  //     data: value,
  //     reigionIds: reigionIds,
  //     zoneIds: zoneIds,
  //     areaIds: areaIds,
  //   };
  //   yield call(editProductData, newData);
  // } else if (value.value.value.ref === "zone") {
  //   let zoneIds = value.value.value.typeIds.map((x) => x.value);
  //   const [regionIds, areaIds] = yield all([
  //     call(fetchZoneIds, value),
  //     call(fetchAreaIds, value),
  //   ]);

  //   const regionIdsFltr = regionIds.filter(
  //     (item, index) => regionIds.indexOf(item) == index
  //   );

  //   let newData = {
  //     data: value,
  //     reigionIds: regionIdsFltr,
  //     zoneIds: zoneIds,
  //     areaIds: areaIds,
  //   };

  //   yield call(editProductData, newData);
  // } else {
  //   let areaIds = value.value.value.typeIds.map((x) => x.value);
  //   const [areaDtls] = yield all([call(fetchAreaIds, value)]);

  //   let regionIds = areaDtls.rows.map((x) => x.region.id);
  //   let zoneIds = areaDtls.rows.map((x) => x.zone.id);

  //   const regionIdsFltr = regionIds.filter(
  //     (item, index) => regionIds.indexOf(item) == index
  //   );
  //   const zoneIdsFltr = zoneIds.filter(
  //     (item, index) => zoneIds.indexOf(item) == index
  //   );

  //   let newData = {
  //     data: value,
  //     reigionIds: regionIdsFltr,
  //     zoneIds: zoneIdsFltr,
  //     areaIds: areaIds,
  //   };
  //   yield call(editProductData, newData);
  // }
}

function* editProductData(value) {
  // const [crncyId] = yield all([call(fetchCntryCrncy)]);

  let Val = value.data.value;

  const token = localStorage.getItem("sStraitToken");
  let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  const crncyId = JSON.parse(localStorage.getItem("sStraitCurrency")).currencyId
    .id;

  try {
    const data = {
      licenseeId: userDetails.licenseeId,
      name: Val.value.name,
      ctgryId: Val.value.category,
      subCtgryId: Val.value.subcategory,
      PrdtType: Val.value.type,
      gstSlab: Val.value.gst,
      buyingPrice: Val.value.buyPrice,
      sellingPrice: Val.value.sellPrice,
      unit: Val.value.unit,
      quantity: Val.value.quantity,
      agency: Val.value.agency,
      hsnCode: Val.value.hsnCode,

      commission: {
        platform: Val.value.platform,
        regionManger: Val.value.regionManger,
        zoneManger: Val.value.zoneManger,
        areaManger: Val.value.areaManger,
        salesExecutive: Val.value.salesExecutive,
        company: Val.value.company,
        incentivesbucket: Val.value.incentiveBckt,
        agent: 0,
      },
      currency: crncyId,
      areaRef: Val.value.ref,
      region: value.reigionIds,
      zone: value.zoneIds,
      area: value.areaIds,
      // image: imgResponse.files
      backOrder: Val.value.backOrder,
      description: Val.value.desc,
      hasVariant: Val.value.hasVariant ? true : false,
      variant: Val.variants ? Val.variants : [],
    };

    const res = yield fetch(`${appConfig.ip}/product/` + Val.value.id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      if (Val.image && Val.image.name) {
        yield call(addeditImage, { prodId: resJSON.id, img: Val.image });
      }

      const [productId] = yield all([
        call(getProductById, { value: resJSON.id }),
      ]);

      yield put({
        type: EDIT_PRODUCTS_SUCCESS_ACTION,
        payload: productId,
      });
      yield toast.success("Product updated successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: EDIT_PRODUCTS_FAIL_ACTION,
        error: err,
      });
    }
  }
}

//Update distributor no hierarchy

// function* UpdateNoHierarchyProduct(value) {

//   let Val = value.value.value;

//   const token = localStorage.getItem("sStraitToken");
//   let userDetails = JSON.parse(localStorage.getItem("sStraitUserDtls"));
//   const crncyId = JSON.parse(localStorage.getItem("sStraitCurrency")).currencyId
//     .id;

//   try {
//     const data = {
//       licenseeId: userDetails.licenseeId,
//       name: Val.name,
//       ctgryId: Val.category,
//       subCtgryId: Val.subcategory,
//       PrdtType: Val.type,
//       gstSlab: Val.gst,
//       buyingPrice: Val.buyPrice,
//       sellingPrice: Val.sellPrice,
//       unit: Val.unit,
//       quantity: Val.quantity,
//       agency: Val.agency,

//       commission: {
//         platform: 0,
//         regionManger: 0,
//         zoneManger: 0,
//         areaManger: 0,
//         salesExecutive: 0,
//         company: Val.company,
//         incentivesbucket: Val.incentiveBckt,
//         agent: 0,
//       },
//       currency: crncyId,
//       backOrder: Val.backOrder,
//       description: Val.desc,
//       hasVariant: Val.hasVariant ? true : false,
//       variant: value.value.variants ? value.value.variants : [],
//     };

//     const res = yield fetch(`${appConfig.ip}/product/` + Val.id, {
//       method: "PUT",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(data),
//     });

//     if (!res.ok) {
//       let errJSON = {};
//       try {
//         errJSON = yield res.json();
//       } catch {
//         throw Object.assign(res, errJSON);
//       }
//     } else {
//       const resJSON = yield res.json();

//       if (value.value.image && value.value.image.name) {
//         yield call(addeditImage, {
//           prodId: resJSON.id,
//           img: value.value.image,
//         });
//       }

//       const [productId] = yield all([
//         call(getProductById, { value: resJSON.id }),
//       ]);

//       yield put({
//         type: EDIT_PRODUCTS_SUCCESS_ACTION,
//         payload: productId,
//       });
//       yield toast.success("Product updated successfully", {
//         autoClose: 3000,
//       });
//     }
//   } catch (err) {
//     if (err.ok === false) {
//       yield put({
//         type: EDIT_PRODUCTS_FAIL_ACTION,
//         error: err,
//       });
//     }
//   }
// }

// Common functions

function* fetchZoneIds(value) {
  let Val = value.value.value;
  const token = localStorage.getItem("sStraitToken");
  let licId = JSON.parse(localStorage.getItem("sStraitUserDtls")).licenseeId;
  let refIds = Val.typeIds.map((x) => x.value);
  let data = Val.ref === "region" ? "&region=" + refIds : "&zone=" + refIds;

  try {
    const res = yield fetch(
      `${appConfig.ip}/zone?limit=100&isActive=true&dummy=false` +
        data +
        "&licenseeId=" +
        licId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let zoneRes =
        Val.ref === "region"
          ? resJSON.rows.map((x) => x.id)
          : resJSON.rows.map((x) => x.region.id);

      return zoneRes;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

function* fetchAreaIds(value) {
  let Val = value.value.value;
  let refIds = Val.typeIds.map((x) => x.value);
  let data =
    Val.ref === "region"
      ? "&region=" + refIds
      : Val.ref === "zone"
      ? "&zone=" + refIds
      : "&area=" + refIds;

  const token = localStorage.getItem("sStraitToken");
  let licId = JSON.parse(localStorage.getItem("sStraitUserDtls")).licenseeId;

  try {
    const res = yield fetch(
      `${appConfig.ip}/area?limit=100&isActive=true&dummy=false` +
        data +
        "&licenseeId=" +
        licId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      let AreaRes =
        Val.ref === "area" ? resJSON : resJSON.rows.map((x) => x.id);
      return AreaRes;
    }
  } catch (err) {
    if (err.ok === false) {
    } else {
    }
  }
}

// function* fetchCntryCrncy() {
//   const token = localStorage.getItem("sStraitToken");
//   let countryId = JSON.parse(localStorage.getItem("sStraitUserDtls")).country;
//   try {
//     const res = yield fetch(`${appConfig.ip}/countryCrncy?isActive=true`, {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     if (!res.ok) {
//       let errJSON = {};
//       try {
//         errJSON = yield res.json();
//       } catch {}
//       throw Object.assign(res, errJSON);
//     } else {
//       const resJSON = yield res.json();
//       let resData = resJSON.rows.find((e) => e.countryId.id === countryId);
//       let crncyId = resData.currencyId.id;

//       return crncyId;
//     }
//   } catch (err) {
//     if (err.ok === false) {
//     } else {
//     }
//   }
// }

// ADD or EDIT Image

function* addeditImage(value) {
  const token = localStorage.getItem("sStraitToken");

  const data = new FormData();
  // data.append('name', 'files')
  // data.append("files", value, value.name);

  data.append(value.prodId, value.img);

  const res = yield fetch(`${appConfig.ip}/product/image`, {
    headers: {
      // 'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
    method: "POST",
    body: data,
  });
  if (!res.ok) {
    let errJSON = {};
    try {
      errJSON = yield res.json();
    } catch {}
    throw Object.assign(res, errJSON);
  } else {
    const resJSON = yield res.json();

    return resJSON;
  }
}

// Change user status

function* changeStatus(value) {
  let datas;

  value.value.status === "created" || value.value.status === "inactive"
    ? (datas = { status: "active" })
    : (datas = { status: "inactive" });

  const id = value.value.id;
  const token = localStorage.getItem("sStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/product/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(datas),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      yield call(changeInventoryStatus, { datas, id });
      yield toast.success("Status changed successfully", {
        autoClose: 3000,
      });
      yield put({
        type: CHANGE_PRODUCT_STATUS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: CHANGE_PRODUCT_STATUS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* changeInventoryStatus(value) {
  const id = value.id;
  try {
    let params = {
      api: `${appConfig.ip}/inventory?product=${id}`,
      method: "GET",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const res = yield call(commonFunction, params);
    if (res) {
      if (value.datas) {
        yield all(
          res.rows.map((x) =>
            call(inventoryUpdate, { id: x.id, status: value.datas.status })
          )
        );
      } else {
        yield all(res.rows.map((x) => call(inventoryDeleted, { id: x.id })));
      }
    }
  } catch (e) {}
}

function* inventoryUpdate(value) {
  const data = { status: value.status };

  try {
    let params = {
      api: `${appConfig.ip}/inventory/${value.id}`,
      method: "PUT",
      sucessAction: null,
      failAction: null,
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
  } catch (e) {}
}

function* inventoryDeleted(value) {
  try {
    let params = {
      api: `${appConfig.ip}/inventory/${value.id}`,
      method: "DELETE",
      sucessAction: null,
      failAction: null,
      body: null,
    };
    const res = yield call(commonFunction, params);
  } catch (e) {}
}

function* DeleteValue(value) {
  const token = localStorage.getItem("sStraitToken");
  const id = value.value.id;

  try {
    const res = yield fetch(`${appConfig.ip}/product/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      // const resJSON = yield res.json();
      yield call(changeInventoryStatus, { id });
      yield toast.success("Successfully deleted", {
        autoClose: 3000,
      });
      yield put({
        type: DELETE_PRODUCT_SUCCESS_ACTION,
        payload: id,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_PRODUCT_FAIL_ACTION, error: err });
    } else {
    }
  }
}

//Get Agency list
function* fetchAgencyList() {
  const token = localStorage.getItem("sStraitToken");
  let userDtls = JSON.parse(localStorage.getItem("sStraitUserDtls"));
  try {
    const res = yield fetch(
      `${appConfig.ip}/agency?status=active&licenseeId=${userDtls.licenseeId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_AGENCYLIST_SUCCESS_ACTION,
        payload: resJSON,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_AGENCYLIST_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* commonFunction(value) {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: `${value.failAction}`, error: err });
    } else {
    }
  }
}

export function* ProductActionWatcher() {
  yield takeEvery(FETCH_PRODUCTS_INIT_ACTION, fetchProducts);
  yield takeEvery(FETCH_PRODUCTTYPE_INIT_ACTION, fetchProductType);
  yield takeEvery(FETCH_CATEGORY_INIT_ACTION, fetchCategory);
  yield takeEvery(FETCH_SUBCATEGORY_INIT_ACTION, fetchSubCatgry);
  yield takeEvery(FETCH_GST_INIT_ACTION, fetchGst);
  yield takeEvery(ADD_PRODUCTS_INIT_ACTION, AddProduct);
  yield takeEvery(FETCH_AREATYPE_INIT_ACTION, fetchAreaType);
  yield takeEvery(FETCH_PRODUCTBYID_INIT_ACTION, getProductById);
  yield takeEvery(EDIT_PRODUCTS_INIT_ACTION, EditProduct);
  yield takeEvery(FETCH_UNITS_INIT_ACTION, fetchUnit);
  yield takeEvery(CHANGE_PRODUCT_STATUS_INIT_ACTION, changeStatus);
  yield takeEvery(DELETE_PRODUCT_INIT_ACTION, DeleteValue);
  yield takeEvery(FETCH_AGENCYLIST_INIT_ACTION, fetchAgencyList);
  // yield takeEvery(IMPORT_PRODUCT_INIT_ACTION, importData);
  // yield takeEvery(FILTERSEARCH_PRODUCT_INIT_ACTION, filterSearchProduct);
}
