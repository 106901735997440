export const FETCH_ROUTE_INIT_ACTION = "FETCH_ROUTE_INIT_ACTION";
export const FETCH_ROUTE_SUCCESS_ACTION =
  FETCH_ROUTE_INIT_ACTION + "_SUCCESS";
export const FETCH_ROUTE_FAIL_ACTION = FETCH_ROUTE_INIT_ACTION + "_ERROR";

export const ADD_ROUTE_INIT_ACTION = "ADD_ROUTE_INIT_ACTION";
export const ADD_ROUTE_SUCCESS_ACTION = ADD_ROUTE_INIT_ACTION + "_SUCCESS";
export const ADD_ROUTE_FAIL_ACTION = ADD_ROUTE_INIT_ACTION + "_ERROR";

export const DELETE_ROUTE_INIT_ACTION = "DELETE_ROUTE_INIT_ACTION";
export const DELETE_ROUTE_SUCCESS_ACTION =
  DELETE_ROUTE_INIT_ACTION + "_SUCCESS";
export const DELETE_ROUTE_FAIL_ACTION = DELETE_ROUTE_INIT_ACTION + "_ERROR";

export const EDIT_ROUTE_INIT_ACTION = "EDIT_ROUTE_INIT_ACTION";
export const EDIT_ROUTE_SUCCESS_ACTION =
  EDIT_ROUTE_INIT_ACTION + "_SUCCESS";
export const EDIT_ROUTE_FAIL_ACTION = EDIT_ROUTE_INIT_ACTION + "_ERROR";

export const CHANGE_STATUS_ROUTE_INIT_ACTION = "CHANGE_STATUS_ROUTE_INIT_ACTION";
export const CHANGE_STATUS_ROUTE_SUCCESS_ACTION =
  CHANGE_STATUS_ROUTE_INIT_ACTION + "_SUCCESS";
export const CHANGE_STATUS_ROUTE_FAIL_ACTION =
  CHANGE_STATUS_ROUTE_INIT_ACTION + "_ERROR";

