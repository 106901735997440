export const FETCH_SUPPORT_INIT_ACTION = "FETCH_SUPPORT_INIT_ACTION";
export const FETCH_SUPPORT_SUCCESS_ACTION =
  FETCH_SUPPORT_INIT_ACTION + "_SUCCESS";
export const FETCH_SUPPORT_FAIL_ACTION =
  FETCH_SUPPORT_INIT_ACTION + "_ERROR";

  export const FETCH_SUPPORTTYPE_INIT_ACTION = "FETCH_SUPPORTTYPE_INIT_ACTION";
export const FETCH_SUPPORTTYPE_SUCCESS_ACTION =
  FETCH_SUPPORTTYPE_INIT_ACTION + "_SUCCESS";
export const FETCH_SUPPORTTYPE_FAIL_ACTION =
  FETCH_SUPPORTTYPE_INIT_ACTION + "_ERROR";


export const ADD_SUPPORT_INIT_ACTION = "ADD_SUPPORT_INIT_ACTION";
export const ADD_SUPPORT_SUCCESS_ACTION =
  ADD_SUPPORT_INIT_ACTION + "_SUCCESS";
export const ADD_SUPPORT_FAIL_ACTION =
  ADD_SUPPORT_INIT_ACTION + "_ERROR";

export const EDIT_SUPPORT_INIT_ACTION = "EDIT_SUPPORT_INIT_ACTION";
export const EDIT_SUPPORT_SUCCESS_ACTION =
  EDIT_SUPPORT_INIT_ACTION + "_SUCCESS";
export const EDIT_SUPPORT_FAIL_ACTION =
  EDIT_SUPPORT_INIT_ACTION + "_ERROR";

export const DELETE_SUPPORT_INIT_ACTION = "DELETE_SUPPORT_INIT_ACTION";
export const DELETE_SUPPORT_SUCCESS_ACTION =
  DELETE_SUPPORT_INIT_ACTION + "_SUCCESS";
export const DELETE_SUPPORT_FAIL_ACTION =
  DELETE_SUPPORT_INIT_ACTION + "_ERROR";

  export const FETCH_SUPPORTBYID_INIT_ACTION = "FETCH_SUPPORTBYID_INIT_ACTION";
export const FETCH_SUPPORTBYID_SUCCESS_ACTION =
FETCH_SUPPORTBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_SUPPORTBYID_FAIL_ACTION = FETCH_SUPPORTBYID_INIT_ACTION + "_ERROR";

export function fetchSupport() {
  return {
    type: FETCH_SUPPORT_INIT_ACTION
  };
}

export function fetchsupportType(value) {
  return {
    type: FETCH_SUPPORTTYPE_INIT_ACTION,
    value:value

  };
}

export function AddSupport(value) {
  return {
    type: ADD_SUPPORT_INIT_ACTION,
    value: value
  };
}
export function EditSupport(value) {
  return {
    type: EDIT_SUPPORT_INIT_ACTION,
    value: value
  };
}
export function DeleteValue(value) {
 
  return {
    type: DELETE_SUPPORT_INIT_ACTION,
    value: value
  };
}
export function SupportSlectedPage(value) {
  return {
    type: FETCH_SUPPORT_INIT_ACTION,
    value: value
  };
}
export function getSupportById(value) {
  return {
    type: FETCH_SUPPORTBYID_INIT_ACTION,
    value:value
  };
}