import { exact } from "prop-types";
import React from "react";
const role = localStorage.getItem("sStraitUserRole");

const Dashboard = React.lazy(() => import("./containers/DashboardContainer"));
const Login = React.lazy(() => import("./containers/LoginContainer"));
const Region = React.lazy(() => import("./containers/RegionContainer"));
const Zone = React.lazy(() => import("./containers/ZoneContainer"));
const Area = React.lazy(() => import("./containers/AreaContainer"));
const Users = React.lazy(() => import("./containers/UsersContainer"));
const Agency = React.lazy(() => import("./containers/AgencyContainer"));
const Products = React.lazy(() =>
  import("./containers/ProductContainer/index")
);
const Leads = React.lazy(() => import("./containers/LeadsContainer"));
const Orders = React.lazy(() => import("./containers/OrderContainer/index"));
const Customers = React.lazy(() => import("./containers/CustomerContainer"));
const Followup = React.lazy(() => import("./containers/FollowupContainer"));

const Payments = React.lazy(() => import("./containers/PaymentsContainer"));
const Invoices = React.lazy(() => import("./containers/InvoicesContainer"));
const Quotations = React.lazy(() => import("./containers/QuotationsContainer"));
const WithdrawalRequest = React.lazy(() =>
  import("./containers/WithdrawalRequestContainer")
);
const MyEarnings = React.lazy(() => import("./containers/EarningsContainer"));
const IncentiveBucket = React.lazy(() =>
  import("./containers/IncentiveBucketContainer")
);

const ResetPassword = React.lazy(() =>
  import("./containers/ResetPwdContainer")
);
const MyProfile = React.lazy(() =>
  import("./containers/MyProfileContainer/indexMyProfile")
);
const UserProfile = React.lazy(() =>
  import("./containers/MyProfileContainer/indexUserProfile")
);

const EditProfile = React.lazy(() =>
  import("./containers/EditProfileContainer")
);
const EditUserProfile = React.lazy(() =>
  import("./containers/EditUserProfileContainer")
);
const ForgotPassword = React.lazy(() =>
  import("./containers/ForgotPasswordContainer")
);

const Support = React.lazy(() => import("./containers/SupportContainer"));
const PaymentInfo = React.lazy(() =>
  import("./containers/PaymentInfoContainer")
);

const ProductInventory = React.lazy(() =>
  import("./containers/ProductInventoryContainer")
);
const ExpiredContainer = React.lazy(() =>
  import("./containers/ExpiredContainer")
);
const ImportProd = React.lazy(() => import("./containers/ImportProdContainer"));

const WithdrawalLimit = React.lazy(() =>
  import("./containers/WithdrawalLimitContainer")
);
const GoogleForms = React.lazy(() => import("./containers/GformContainer"));
const JustDial = React.lazy(() => import("./containers/JustDialContainer"));
const TradeIndia = React.lazy(() => import("./containers/TradeIndiaContainer"));
const Sulekha = React.lazy(() => import("./containers/SulekhaContainer"));
const IndiaMart = React.lazy(() => import("./containers/IndiaMartContainer"));
const Facebook = React.lazy(() => import("./containers/FacebookContainer"));
const Instagram = React.lazy(() =>
  import("./screens/MarketingAutomation/SocialMedia/Instagram")
);
const Whatsapp = React.lazy(() =>
  import("./screens/MarketingAutomation/Communication/Whatsapp")
);
const SMS = React.lazy(() =>
  import("./screens/MarketingAutomation/Communication/SMS")
);
const SocialLead = React.lazy(() => import("./containers/SocialLeadContainer"));
const Rewards = React.lazy(() => import("./containers/RewardContainer"));
const SalesReturn = React.lazy(() =>
  import("./containers/SalesReturnContainer")
);

const Expense = React.lazy(() =>
  import("./containers/DistributorContainer/ExpenseConatiner")
);

const User = React.lazy(() =>
  import("./containers/DistributorContainer/UserContainer")
);
const DisProduct = React.lazy(() =>
  import("./containers/DistributorContainer/ProductContainer")
);
const DisOrder = React.lazy(() =>
  import("./containers/DistributorContainer/OrderContainer")
);
const DisQuotation = React.lazy(() =>
  import("./containers/DistributorContainer/QuotationContainer")
);
const DisLead = React.lazy(() =>
  import("./containers/LeadsContainer/indexDis")
);
const DisProductInventory = React.lazy(() =>
  import("./containers/DistributorContainer/ProductInventoryContainer")
);
//Regional Manager
// const RegionalDashboard = React.lazy(() => import('./containers/DashboardContainer/indexRegional'));

//Zonal Manager
// const ZonalDashboard = React.lazy(() => import('./containers/DashboardContainer/indexZonal'));

//Zonal Manager
// const AreaDashboard = React.lazy(() => import('./containers/DashboardContainer/indexArea'));

//Sales Executive
// const SalesDashboard = React.lazy(() => import('./containers/DashboardContainer/indexSales'));

const InvoiceConfiguration = React.lazy(() =>
  import("./containers/BrandingContainer/InvoiceConfigurationContainer")
);
const QuoteConfiguration = React.lazy(() =>
  import("./containers/BrandingContainer/QuoteConfigurationContainer")
);
const Notification = React.lazy(() =>
  import("./containers/NotificationContainer")
);
const Help = React.lazy(() => import("./screens/help"));
const ImportDisProduct = React.lazy(() =>
  import("./containers/DistributorContainer/ImportDistPrdctConatainer")
);
const CreditNoteConfig = React.lazy(() =>
  import("./containers/BrandingContainer/CreditNoteConfigContainer")
);

const DebtorsReport = React.lazy(() =>
  import("./containers/ReportsContainer/DeptorsReportContainer")
);

const CreditNoteReport = React.lazy(() =>
  import("./containers/ReportsContainer/CreditNoteContainer")
);
const UserReport = React.lazy(() =>
  import("./containers/ReportsContainer/UserReportContainer")
);

const AgentReport = React.lazy(() =>
  import("./containers/ReportsContainer/AgentReportContainer")
);

const CustomerRoute = React.lazy(() =>
  import("./containers/DistributorContainer/CustomerRouteContainer")
);
const StockMovement = React.lazy(() =>
  import("./containers/DistributorContainer/StockMovementContainer")
);

// const CustomerCredit = React.lazy(() =>
//   import("./screens/Customers/Components/customerCredit")
// );

const ProductReplace = React.lazy(() =>
  import("./containers/ProductReplaceContainer")
);
const routes = [
  // { path: '/', exact: true, name: 'Home' },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/login", name: "Login", component: Login },
  { path: "/mysalesnetwork/region", name: "Region", component: Region },
  { path: "/mysalesnetwork/zone", name: "Zone", component: Zone },
  { path: "/mysalesnetwork/area", name: "Area", component: Area },
  { path: "/mysalesnetwork/users", name: "Users", component: Users },
  {
    path: "/agency",
    name: role === "distributor" ? "Suppliers" : "Agency",
    component: Agency,
  },
  { path: "/products", name: "Products", component: Products },
  { path: "/orders", name: "Orders", component: Orders },
  { path: "/leads", name: "Leads", component: Leads },
  { path: "/customers", name: "Customers", component: Customers, exact: true },
  {
    path: "/customers/:id/discount",
    name: "Customer Discount",
    component: Customers,
    exact: true,
  },
  {
    path: "/customers/:id/ledger",
    name: "Customer Ledger",
    component: Customers,
    exact: true,
  },
  {
    path: "/customers/:id/creditNote",
    name: "Credit Notes",
    component: Customers,
    exact: true,
  },
  {
    path: "/customers/:id/creditNote/pdfView",
    name: "Pdf view",
    component: Customers,
    exact: true,
  },
  {
    path: "/customers/creditCustomers",
    // name: "Customer Discount",
    component: Customers,
    exact: true,
  },

  { path: "/Followup", name: "Follow up", component: Followup },

  { path: "/payments", name: "Payments", component: Payments },
  { path: "/invoices", name: "Invoices", component: Invoices, exact: true },
  {
    path: "/invoices/:id",
    name: "Export to PDF",
    component: Invoices,
    exact: true,
  },
  {
    path: "/quotations",
    name: "Quotations",
    component: Quotations,
    exact: true,
  },
  {
    path: "/quotations/:id",
    name: "Export to PDF",
    component: Quotations,
    exact: true,
  },

  {
    path: "/withdrawalrequest",
    name: "Withdrawal Request",
    component: WithdrawalRequest,
  },
  { path: "/myearnings", name: "My Earnings", component: MyEarnings },
  {
    path: "/incentivebucket",
    name: "Incentive Bucket",
    component: IncentiveBucket,
  },
  {
    path: "/debtorsReport/:id/ledger",
    name: "Customer Ledger",
    component: DebtorsReport,
    exact: true,
  },
  {
    path: "/report/debtorsReport",
    name: "Debtors List",
    component: DebtorsReport,
  },
  {
    path: "/report/creditnoteReport",
    name: "Credit Note List",
    component: CreditNoteReport,
  },
  {
    path: "/report/userlistReport",
    name: "Agent Report List",
    component: UserReport,
  },
  {
    path: "/userlistReport/:id",
    name: "",
    component: UserReport,
  },
  {
    path: "/report/agentReport",
    name: "Agent Report List",
    component: AgentReport,
  },
  { path: "/resetpassword", name: "", component: ResetPassword },
  { path: "/myprofile", name: "", component: MyProfile },
  { path: "/editprofile", name: "", component: EditProfile },
  { path: "/userprofile", name: "", component: UserProfile },
  { path: "/edituserprofile", name: "", component: EditUserProfile },
  { path: "/support", name: "Support", component: Support },
  { path: "/paymentinfo", name: "Payment Info", component: PaymentInfo },
  {
    path: "/WithdrawalLimit",
    name: "Withdrawal Limit",
    component: WithdrawalLimit,
  },

  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/ProductInventory",
    name: "Inventory",
    component: ProductInventory,
  },
  { path: "/ImportProd", name: "Products", component: ImportProd },
  { path: "/ImportDisProd", name: "Product", component: ImportDisProduct },
  {
    path: "/marketingautomation/forms/googleforms",
    name: "Google Forms",
    component: GoogleForms,
  },
  {
    path: "/marketingautomation/marketplace/justdial",
    name: "Just Dial",
    component: JustDial,
  },
  {
    path: "/marketingautomation/marketplace/tradeindia",
    name: "Trade India",
    component: TradeIndia,
  },
  {
    path: "/marketingautomation/marketplace/sulekha",
    name: "Sulekha",
    component: Sulekha,
  },
  {
    path: "/marketingautomation/marketplace/indiamart",
    name: "IndiaMart",
    component: IndiaMart,
  },
  {
    path: "/marketingautomation/smedia/facebook",
    name: "Facebook",
    component: Facebook,
  },
  {
    path: "/marketingautomation/smedia/instagram",
    name: "Instagram",
    component: Instagram,
  },
  {
    path: "/marketingautomation/comnctn/whatsapp",
    name: "Whatsapp",
    component: Whatsapp,
  },
  {
    path: "/marketingautomation/comnctn/sms",
    name: "SMS",
    component: SMS,
  },
  {
    path: "/marketingautomation/sociallead",
    name: "Social Lead",
    component: SocialLead,
  },
  {
    path: "/reward",
    name: "Rewards",
    component: Rewards,
  },
  {
    path: "/salesreturn",
    name: "Sales Return",
    component: SalesReturn,
  },
  {
    path: "/productReplace",
    name: "Product Replace",
    component: ProductReplace,
  },
  {
    path: "/expense",
    name: "Expense",
    component: Expense,
  },
  {
    path: "/user",
    name: "Users",
    component: User,
  },
  {
    path: "/product",
    name: "Products",
    component: DisProduct,
  },
  {
    path: "/order",
    name: "Orders",
    component: DisOrder,
  },
  {
    path: "/Quotation",
    name: "Quotations",
    component: DisQuotation,
    exact: true,
  },
  {
    path: "/Quotation/:id",
    name: "Export to PDF",
    component: DisQuotation,
    exact: true,
  },
  {
    path: "/lead",
    name: "Leads",
    component: DisLead,
  },
  {
    path: "/disproductInventory",
    name: "Inventory",
    component: DisProductInventory,
  },
  {
    path: "/inventoryLog/:id",
    name: "Inventory Log",
    component: DisProductInventory,
    exact: true,
  },
  //Regional Manager
  // { path: "/regionalMngr/dashboard", name: "Dashboard", component: RegionalDashboard },

  //Regional Manager
  // { path: "/zonalMngr/dashboard", name: "Dashboard", component: ZonalDashboard },

  //Zonal Manager
  // { path: "/areaMngr/dashboard", name: "Dashboard", component: AreaDashboard },

  //Sales Executive
  // { path: "/saleExctv/dashboard", name: "Dashboard", component: SalesDashboard },

  {
    path: "/branding/invoiceConfiguration",
    name: "Invoice Configuration",
    component: InvoiceConfiguration,
  },
  {
    path: "/branding/quotationConfiguration",
    name: "Quote Configuration",
    component: QuoteConfiguration,
  },
  {
    path: "/branding/creditNoteConfiguration",
    name: "Credit Note Configuration",
    component: CreditNoteConfig,
  },
  {
    path: "/notifications",
    // name: "Notification",
    component: Notification,
  },
  {
    path: "/help",
    // name: "Notification",
    component: Help,
  },
  // {
  //   path: "/customerCredit",
  //   // name: "Notification",
  //   component: CustomerCredit,
  // },

  {
    path: "/customerroute",
    name: "Customer Route",
    component: CustomerRoute,
  },
  {
    path: "/stockMovemnet",
    name: "Stock Movement",
    component: StockMovement,
  },
];

export default routes;
