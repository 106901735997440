export const FETCH_PAGELIST_INIT_ACTION = "FETCH_PAGELIST_INIT_ACTION";
export const FETCH_PAGELIST_FAIL_ACTION = FETCH_PAGELIST_INIT_ACTION + "_ERROR";
export const FETCH_PAGELIST_SUCCESS_ACTION =
  FETCH_PAGELIST_INIT_ACTION + "_SUCCESS";

export const FETCH_FORMLIST_INIT_ACTION = "FETCH_FORMLIST_INIT_ACTION";
export const FETCH_FORMLIST_FAIL_ACTION = FETCH_FORMLIST_INIT_ACTION + "_ERROR";
export const FETCH_FORMLIST_SUCCESS_ACTION =
  FETCH_FORMLIST_INIT_ACTION + "_SUCCESS";

export const POST_FACEBOOKDATA_INIT_ACTION = "POST_FACEBOOKDATA_INIT_ACTION";
export const POST_FACEBOOKDATA_FAIL_ACTION =
  POST_FACEBOOKDATA_INIT_ACTION + "_ERROR";
export const POST_FACEBOOKDATA_SUCCESS_ACTION =
  POST_FACEBOOKDATA_INIT_ACTION + "_SUCCESS";

export const FETCH_EXTENDEDTOKN_INIT_ACTION = "FETCH_EXTENDEDTOKN_INIT_ACTION";
export const FETCH_EXTENDEDTOKN_FAIL_ACTION =
  FETCH_EXTENDEDTOKN_INIT_ACTION + "_ERROR";
export const FETCH_EXTENDEDTOKN_SUCCESS_ACTION =
  FETCH_EXTENDEDTOKN_INIT_ACTION + "_SUCCESS";

export const CLOSE_MODEL_INIT_ACTION = "CLOSE_MODEL_INIT_ACTION";

export const FETCH_FACEBOOKLIST_INIT_ACTION = "FETCH_FACEBOOKLIST_INIT_ACTION";
export const FETCH_FACEBOOKLIST_FAIL_ACTION =
  FETCH_FACEBOOKLIST_INIT_ACTION + "_ERROR";
export const FETCH_FACEBOOKLIST_SUCCESS_ACTION =
  FETCH_FACEBOOKLIST_INIT_ACTION + "_SUCCESS";

export const DELETE_FACEBOOKDATA_INIT_ACTION =
  "DELETE_FACEBOOKDATA_INIT_ACTION";
export const DELETE_FACEBOOKDATA_FAIL_ACTION =
  DELETE_FACEBOOKDATA_INIT_ACTION + "_ERROR";
export const DELETE_FACEBOOKDATA_SUCCESS_ACTION =
  DELETE_FACEBOOKDATA_INIT_ACTION + "_SUCCESS";

export const FETCH_FORMFIELDS_INIT_ACTION = "FETCH_FORMFIELDS_INIT_ACTION";
export const FETCH_FORMFIELDS_FAIL_ACTION =
  FETCH_FORMFIELDS_INIT_ACTION + "_ERROR";
export const FETCH_FORMFIELDS_SUCCESS_ACTION =
  FETCH_FORMFIELDS_INIT_ACTION + "_SUCCESS";

export const CLEAR_FORMDATA_INIT_ACTION = "CLEAR_FORMDATA_INIT_ACTION";

export const UPDATE_FACEBOOK_INIT_ACTION = "UPDATE_FACEBOOK_INIT_ACTION";
export const UPDATE_FACEBOOK_FAIL_ACTION =
  UPDATE_FACEBOOK_INIT_ACTION + "_ERROR";
export const UPDATE_FACEBOOK_SUCCESS_ACTION =
  UPDATE_FACEBOOK_INIT_ACTION + "_SUCCESS";
