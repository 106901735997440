export const FETCH_PAYMENTINFO_INIT_ACTION = "FETCH_PAYMENTINFO_INIT_ACTION";
export const FETCH_PAYMENTINFO_SUCCESS_ACTION = FETCH_PAYMENTINFO_INIT_ACTION + "_SUCCESS";
export const FETCH_PAYMENTINFO_FAIL_ACTION = FETCH_PAYMENTINFO_INIT_ACTION + "_ERROR";


export function fetchPaymentInfo(value) {

  return {
    type: FETCH_PAYMENTINFO_INIT_ACTION,
    value: value
  };
}