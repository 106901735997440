export const FETCH_LICENSEEPROFILE_INIT_ACTION =
  "FETCH_LICENSEEPROFILE_INIT_ACTION";
export const FETCH_LICENSEEPROFILE_SUCCESS_ACTION =
  FETCH_LICENSEEPROFILE_INIT_ACTION + "_SUCCESS";
export const FETCH_LICENSEEPROFILE_FAIL_ACTION =
  FETCH_LICENSEEPROFILE_INIT_ACTION + "_ERROR";


export const EDIT_LICENSEEPROF_INIT_ACTION =
  "EDIT_LICENSEEPROF_INIT_ACTION";
export const EDIT_LICENSEEPROF_SUCCESS_ACTION =
  EDIT_LICENSEEPROF_INIT_ACTION + "_SUCCESS";
export const EDIT_LICENSEEPROF_FAIL_ACTION =
  EDIT_LICENSEEPROF_INIT_ACTION + "_ERROR";


  export const FETCH_USERPROFILE_INIT_ACTION =
  "FETCH_USERPROFILE_INIT_ACTION";
export const FETCH_USERPROFILE_SUCCESS_ACTION =
  FETCH_USERPROFILE_INIT_ACTION + "_SUCCESS";
export const FETCH_USERPROFILE_FAIL_ACTION =
  FETCH_USERPROFILE_INIT_ACTION + "_ERROR";


export const EDIT_USERPROF_INIT_ACTION =
  "EDIT_USERPROF_INIT_ACTION";
export const EDIT_USERPROF_SUCCESS_ACTION =
  EDIT_USERPROF_INIT_ACTION + "_SUCCESS";
export const EDIT_USERPROF_FAIL_ACTION =
  EDIT_USERPROF_INIT_ACTION + "_ERROR";


  export const FETCH_USERDATA_INIT_ACTION =
  "FETCH_USERDATA_INIT_ACTION";
export const FETCH_USERDATA_SUCCESS_ACTION =
  FETCH_USERDATA_INIT_ACTION + "_SUCCESS";
export const FETCH_USERDATA_FAIL_ACTION =
  FETCH_USERDATA_INIT_ACTION + "_ERROR";

  export const FETCH_LICPROFDATA_INIT_ACTION ="FETCH_LICPROFDATA_INIT_ACTION";
export const FETCH_LICPROFDATA_SUCCESS_ACTION = FETCH_LICPROFDATA_INIT_ACTION + "_SUCCESS";
export const FETCH_LICPROFDATA_FAIL_ACTION = FETCH_LICPROFDATA_INIT_ACTION + "_ERROR";


export function fetchLicenseeProfile(value) {
  return {
    type: FETCH_LICENSEEPROFILE_INIT_ACTION,
    value: value
  };
}


export function EditLicenseeprof(value) {
  return {
    type: EDIT_LICENSEEPROF_INIT_ACTION,
    value: value
  };
}

// export function fetchUserProfile(value) {
//   return {
//     type: FETCH_USERPROFILE_INIT_ACTION,
//     value: value
//   };
// }


// export function EditUserprof(value) {
//   return {
//     type: EDIT_USERPROF_INIT_ACTION,
//     value: value
//   };
// }

export function fetchUserData(value) {
  return {
    type: FETCH_USERDATA_INIT_ACTION,
    value: value
  };
}

export function fetchLicProfileData() {
  return {
    type: FETCH_LICPROFDATA_INIT_ACTION,
  };
}