import * as actionTypes from "./constants";

export function fetchDisOrder(value) {
  return {
    type: actionTypes.FETCH_DISORDERS_INIT_ACTION,
    value: value,
  };
}

export function addOrder(value, productList) {
  return {
    type: actionTypes.ADD_ORDERS_INIT_ACTION,
    value: value,
    productList: productList,
  };
}

export function changeDisOrderStatus(value) {
  return {
    type: actionTypes.CHANGE_DISORDERSTATUS_INIT_ACTION,
    value: value,
  };
}

export function DisOrderPayAmount(value, url) {
  return {
    type: actionTypes.PAYAMOUNT_DISORDER_INIT_ACTION,
    value: value,
    url: url,
  };
}

export function changeDisOrderApprove(value, url) {
  return {
    type: actionTypes.CHANGE_DISORDERAPPROVE_INIT_ACTION,
    value: value,
    url: url,
  };
}
export function customerDiscountbyId(value) {
  return {
    type: actionTypes.CUSTOMER_DISCOUNTBYID_INIT_ACTION,
    value: value,
  };
}
export function exprtOrderData(value) {
  return {
    type: actionTypes.EXPORT_ORDER_DATA_INIT_ACTION,
    value: value,
  };
}

export function fetchCustomerByOrder(value) {
  return {
    type: actionTypes.FETCH_CUSTOMER_BY_ORDER_INIT_ACTION,
    value: value,
  };
}
export function fetchIventoryByProduct(value) {
  return {
    type: actionTypes.FETCH_INVENTORYBYPRODUCT_INIT_ACTION,
    value: value,
  };
}
export function fetchVariantFileld(value, key) {
  return {
    type: actionTypes.FETCH_VARIANTFIELD_INIT_ACTION,
    value: value,
    key: key,
  };
}

export function fetchTotalAmountByOrder(value) {
  return {
    type: actionTypes.FETCH_TOTAL_AMOUNT_BY_ORDER_INIT_ACTION,
    value: value,
  };
}

export function postProductList(value) {
  return {
    type: actionTypes.POST_PRODUCTLIST_INIT_ACTION,
    value: value,
  };
}

export function addProductReturn(value) {
  return {
    type: actionTypes.ADD_PRODUCT_RETURN_INIT_ACTION,
    value: value,
  };
}

export function addProductReplace(value) {
  console.log("value=----=----=", value);
  return {
    type: actionTypes.ADD_PRODUCT_REPLACE_INIT_ACTION,
    value: value,
  };
}

export function clearQouteProductList() {
  return {
    type: actionTypes.CLEAR_QOUTEPRODUCT_INIT_ACTION,
  };
}

export function getReturnByOrderId(id) {
  return {
    type: actionTypes.GET_RETURN_BY_ORDER_ID_INIT_ACTION,
    id: id,
  };
}

export function fectCustCreditNotes(value) {
  return {
    type: actionTypes.FETCH_CUST_CREDITNOTES_INIT_ACTION,
    value: value,
  };
}

export function editOrder(value, productList, deletedProduct, addedProduct) {
  return {
    type: actionTypes.EDIT_ORDERS_INIT_ACTION,
    value: value,
    productList: productList,
    deletedProduct: deletedProduct,
    addedProduct: addedProduct,
  };
}
export function fetchSalesExecutiveByOrder(value) {
  return {
    type: actionTypes.FETCH_SALESEXECUTIVE_BY_ORDER_INIT_ACTION,
    value: value,
  };
}
